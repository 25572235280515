import gql from 'graphql-tag';

export default gql`
  query organisations {
    organisations {
      _id
      name
      created
      admin {
        Owner {
          _id
          picture
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
      Teams {
        _id
        name
      }
      settings {
        exclude_participants_from_teams
      }
      integrations {
        hubspot_company_id
      }
    }
  }
`;
