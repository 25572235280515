import classNames from 'classnames';

import type { FunctionalComponentWithChildren } from 'types/types';

type Props = {
  className?: string;
};

export const Text: FunctionalComponentWithChildren<Props> = ({ children, className }) => {
  const classes = classNames('tw-truncate', className);

  return <div className={classes}>{children}</div>;
};
