import { Button, FormGroup, H3, InputGroup, Toaster, Position } from '@blueprintjs/core';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import { GenerateShortLinkMutation } from './data/GenerateShortLink.mutation';

interface FormValues {
  url: string;
  parameters: {
    key: string;
    value: string;
  }[];
}

const toaster = Toaster.create({
  position: Position.TOP,
});

export const ShortLinkContainer = () => {
  const [{ data, fetching }, mutate] = useMutation(GenerateShortLinkMutation);
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      url: '',
      parameters: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parameters',
  });

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toaster.show({
      message: 'Link copied to clipboard!',
      intent: 'success',
    });
  };

  const onSubmit = async (data: FormValues) => {
    await mutate({
      input: {
        url: data.url,
        payload: data.parameters,
      },
    });
  };

  return (
    <div className="tw-p-6 tw-max-w-2xl">
      <H3>Short link generator</H3>
      {data?.generateShortUrl && (
        <div className="tw-py-6">
          <code
            onClick={() => handleCopy(data.generateShortUrl)}
            className="tw-text-text-muted tw-bg-white tw-text-black tw-py-2 tw-px-4 tw-rounded-sm tw-cursor-pointer hover:tw-bg-gray-100 tw-select-none"
          >
            {data.generateShortUrl}
          </code>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-4">
        <FormGroup label="URL" labelFor="url">
          <Controller
            control={control}
            name="url"
            render={({ field }) => <InputGroup id="url" placeholder="Enter URL to shorten" {...field} />}
          />
        </FormGroup>

        <div className="tw-space-y-3">
          {fields.map((field, index) => (
            <div key={field.id} className="tw-flex tw-gap-2">
              <Controller
                control={control}
                name={`parameters.${index}.key`}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputGroup placeholder="Key" onChange={onChange} onBlur={onBlur} value={value} />
                )}
              />
              <Controller
                control={control}
                name={`parameters.${index}.value`}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputGroup placeholder="Value" onChange={onChange} onBlur={onBlur} value={value} />
                )}
              />
              <Button icon="trash" minimal onClick={() => remove(index)} />
            </div>
          ))}
        </div>

        <div className="tw-flex tw-gap-2">
          <Button icon="plus" disabled={fetching} onClick={() => append({ key: '', value: '' })} text="Add Parameter" />
          <Button type="submit" intent="primary" disabled={fetching} loading={fetching} text="Generate Link" />
        </div>
      </form>
    </div>
  );
};
