import { Button } from '@blueprintjs/core';
import classNames from 'classnames';
import { Children, useState } from 'react';

import type { FunctionalComponentWithChildren } from 'types/types';

type StackLayoutProps = {
  padding?: string;
};

export const StackLayout: FunctionalComponentWithChildren<StackLayoutProps> = ({ children, padding }) => {
  const [hideLeftColumn, setHideLeftColumn] = useState(false);
  const [hideRightColumn, setHideRightColumn] = useState(false);

  const collapseClasses = '!tw-w-0 tw-p-5';
  const innerCollapseClasses = '!tw-w-0 tw-invisible';

  const totalCount = Children.count(children);

  if (totalCount < 2 || totalCount > 3) {
    throw new Error('StackLayout requires two or three top level children');
  }

  const result = Children.map(children, child => child);
  const [leftColumn, center, rightColumn] = Array.isArray(result) ? result : [];

  return (
    <div className="tw-w-full tw-flex tw-h-full">
      <div
        className={classNames('tw-w-[25rem] tw-relative tw-space-y-3 tw-border-r tw-border-r-gray-500', padding, {
          [collapseClasses]: hideLeftColumn,
        })}
      >
        <div className="tw-absolute tw-top-1 tw-z-10 tw-right-1">
          <Button
            minimal
            onClick={() => setHideLeftColumn(s => !s)}
            icon={hideLeftColumn ? 'chevron-right' : 'chevron-left'}
            className="tw-rounded-full"
          />
        </div>
        <div className={classNames({ [innerCollapseClasses]: hideLeftColumn })}>{leftColumn}</div>
      </div>

      <div className="tw-flex tw-w-full">{center}</div>

      {rightColumn && (
        <div
          className={classNames('tw-w-[26rem] tw-relative tw-h-full  tw-border-l tw-border-gray-500', padding, {
            [collapseClasses]: hideRightColumn,
          })}
        >
          <div className="tw-absolute tw-top-1 tw-z-10 tw-left-1">
            <Button
              minimal
              onClick={() => setHideRightColumn(s => !s)}
              icon={hideRightColumn ? 'chevron-left' : 'chevron-right'}
              className="tw-rounded-full"
            />
          </div>
          <div className={classNames({ [innerCollapseClasses]: hideRightColumn })}>{rightColumn}</div>
        </div>
      )}
    </div>
  );
};
