/* eslint-disable max-lines */
import { Subscription, Mutation } from '@apollo/client/react/components';
import { Classes, H2, H6, HTMLTable, Divider, InputGroup, Button, HTMLSelect } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
import { Component, Fragment } from 'react';
import Sound from 'react-sound';

class SoundWrapper extends Component {
  delayTimeout: any;

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      finished: false,
    };
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.offset) {
      this.setState({ delayed: true });
      this.delayTimeout = setTimeout(() => {
        this.setState({ delayed: false });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
      }, this.props.offset);
    }
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    // console.log('shouldComponentUpdate', {
    //     'props.url': [this.props.url, nextProps.url, this.props.url === nextProps.url],
    //     'props.offset': [this.props.offset, nextProps.offset, this.props.offset === nextProps.offset],
    //     'state.loaded': [this.state.loaded, nextState.loaded, this.state.loaded === nextState.loaded],
    //     'state.delayed': [this.state.delayed, nextState.delayed, this.state.delayed === nextState.delayed],
    //     'state.finished': [this.state.finished, nextState.finished, this.state.finished === nextState.finished],
    // });

    const diffs = [];
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'url' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
    if (this.props.url !== nextProps.url) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'url' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
      diffs.push(['props.url', this.props.url, nextProps.url]);
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.offset !== nextProps.offset) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'offset' does not exist on type 'Readonly... Remove this comment to see the full error message
      diffs.push(['props.offset', this.props.offset, nextProps.offset]);
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loaded' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.state.loaded !== nextState.loaded) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loaded' does not exist on type 'Readonly... Remove this comment to see the full error message
      diffs.push(['state.loaded', this.state.loaded, nextState.loaded]);
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'delayed' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.state.delayed !== nextState.delayed) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'delayed' does not exist on type 'Readonl... Remove this comment to see the full error message
      diffs.push(['state.delayed', this.state.delayed, nextState.delayed]);
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'finished' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.state.finished !== nextState.finished) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'finished' does not exist on type 'Readon... Remove this comment to see the full error message
      diffs.push(['state.finished', this.state.finished, nextState.finished]);
    }

    // console.log('shouldComponentUpdate', this.props.key, JSON.stringify(diffs, undefined, 2));

    return diffs.length > 0;

    // return true;
  }

  componentWillUnmount() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'finished' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.state.finished) {
      return null;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'delayed' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.state.delayed) {
      return null;
    }
    return (
      <Sound
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'url' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
        url={this.props.url}
        autoLoad
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'loaded' does not exist on type 'Readonly... Remove this comment to see the full error message
        playStatus={this.state.loaded ? Sound.status.PLAYING : Sound.status.PAUSED}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'skip' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        playFromPosition={this.props.skip || 0}
        onLoad={() => {
          this.setState({ loaded: true });
        }}
        onFinishedPlaying={() => {
          this.setState({ finished: true });
        }}
      />
    );
  }
}

const DashboardSound = (props: any) => {
  const { sound } = props;
  switch (sound.key) {
    case 'USE_CREDITS':
    case 'BUY_CREDITS': {
      const credits =
        _.chain(sound.meta)
          .find(meta => meta.key === 'credits')
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'get' does not exist on type 'FunctionCha... Remove this comment to see the full error message
          .get('value', 0)
          .parseInt()
          .value() || 0;
      if (!credits) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ url: any; }' is not assignable to type 'In... Remove this comment to see the full error message
        return <SoundWrapper url={sound.url} />;
      }

      const sounds = [];
      const repeats = Math.floor(Math.max(credits, 100) / 100);
      for (let i = 0; i < repeats; i += 1) {
        sounds.push({ i, offset: i * 350 });
      }
      return (
        <Fragment>
          {sounds.map(instance => (
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: number; url: any; offset: number; }' ... Remove this comment to see the full error message
            <SoundWrapper key={instance.i} url={sound.url} offset={instance.offset} />
          ))}
        </Fragment>
      );
    }
    default:
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ url: any; }' is not assignable to type 'In... Remove this comment to see the full error message
      return <SoundWrapper url={sound.url} />;
  }
};

class DashboardSounds extends Component {
  soundPresets: any;

  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = {
      history: [],
      testSound: {
        url: '',
        key: '',
        meta: [],
      },
    };

    this.onTestValueChange = this.onTestValueChange.bind(this);
    this.playSound = this.playSound.bind(this);

    this.soundPresets = {
      'B’ding x 5': {
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/booking_complete.mp3',
        key: 'USE_CREDITS',
        meta: [{ key: 'credits', value: '500' }],
      },
      'Subtle sound-check': {
        key: 'TEST',
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/subtler-ting.wav',
      },
      'Sexy sax': {
        key: 'TEST',
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/careless_whispers.mp3',
      },
      'Notification ding': { key: 'TEST', url: 'https://www.myinstants.com/media/sounds/ding-sound-effect_2.mp3' },
      'Air horn': { key: 'TEST', url: 'https://www.myinstants.com/media/sounds/air-horn-club-sample_1.mp3' },
      Inception: { key: 'TEST', url: 'https://www.myinstants.com/media/sounds/inceptionbutton.mp3' },
      'Law & Order': { key: 'TEST', url: 'https://www.myinstants.com/media/sounds/dun_dun_1.mp3' },
      Seinfeld: { key: 'TEST', url: 'https://www.myinstants.com/media/sounds/seinfeld-theme_1.mp3' },
      'Filthy Christmas': {
        key: 'TEST',
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/Filthy-Xmas.mp3',
      },
      '💩': { key: 'TEST', url: 'https://www.myinstants.com/media/sounds/perfect-fart.mp3' },
      'Easy Money': {
        key: 'EASY_MONEY',
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/Easy-Money.mp3',
      },
      'Credit Sale < 10k (Kaching)': {
        key: 'BUY_CREDITS',
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/kaching.mp3',
      },
      'Credit Sale > 10k (Rocky)': {
        key: 'BUY_CREDITS',
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/rocky-new.mp3',
      },
      'Credit Sale - 20k (Magic)': {
        key: 'BUY_CREDITS',
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/24k-magic.mp3',
      },
      'Credit Sale - 50k (Jump around)': {
        key: 'BUY_CREDITS',
        url: 'https://askable-misc-public.s3.ap-southeast-2.amazonaws.com/dashboard_sounds/jump_around.mp3',
      },
      'Credit Sale - 75k (Mo Moneyzz)': {
        key: 'BUY_CREDITS',
        url: 'https://askable-misc-public.s3-ap-southeast-2.amazonaws.com/dashboard_sounds/mo_moneyzz.mp3',
      },
      'Credit Sale - 150k (Simba)': {
        key: 'BUY_CREDITS',
        url: 'https://askable-misc-public.s3.ap-southeast-2.amazonaws.com/dashboard_sounds/simba.mp3',
      },
      'Credit Sale - 250k (Succession mashup)': {
        key: 'BUY_CREDITS',
        url: 'https://askable-misc-public.s3.ap-southeast-2.amazonaws.com/dashboard_sounds/succession_mashup.mp3',
      },
      '6 Star Rating (Shooting stars)': {
        key: '6_STAR_RATING',
        url: 'https://cdn.askable.com/misc/internal/dashboard-sounds/Shooting-6-Star.m4a',
      },
    };
  }

  onTestValueChange(path: any) {
    return (event: any) => {
      const { value } = event.target;
      this.setState(state => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'testSound' does not exist on type 'Reado... Remove this comment to see the full error message
        _.set(state.testSound, path, value);
        return state;
      });
    };
  }

  onTestMetaChange(i: any, field: any) {
    return (event: any) => {
      const { value } = event.target;
      this.setState(state => {
        const meta = _.get(this.state, 'testSound.meta', []);

        if (!meta[i]) meta[i] = {};
        meta[i][field] = value;

        // @ts-expect-error ts-migrate(2339) FIXME: Property 'testSound' does not exist on type 'Reado... Remove this comment to see the full error message
        state.testSound.meta = meta.filter((metaRow: any) => metaRow.key || metaRow.value);
        return state;
      });
    };
  }

  playSound(options: any) {
    const sound = _.get(options, 'subscriptionData.data.adminSound');
    // console.log('playSound', sound);
    if (!sound) {
      return;
    }
    sound.ts = Date.now();
    this.setState(state => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message
      state.history.unshift(sound);
      return state;
    });
  }

  render() {
    return (
      <div className="table-responsive height-100">
        <div className={`content-page ${Classes.DARK} ${Classes.MONOSPACE_TEXT}`}>
          <H2>Subscription history</H2>
          <Subscription
            subscription={gql`
              subscription {
                adminSound {
                  url
                  key
                  description
                  meta {
                    key
                    value
                  }
                }
              }
            `}
            onSubscriptionData={this.playSound}
            // shouldResubscribe
            // fetchPolicy="network-only"
          />
          <HTMLTable bordered striped style={{ whiteSpace: 'nowrap' }}>
            <thead>
              <tr>
                <th>Time</th>
                <th>Description</th>
                <th>Sound</th>
                <th>Meta</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'history' does not exist on type 'Readonl... Remove this comment to see the full error message */}
              {this.state.history.map((sound: any) => (
                <tr key={`${sound.key}:${sound.ts}`}>
                  <td>{moment(sound.ts).toString()}</td>
                  <td>{sound.description}</td>
                  <td>{sound.key}</td>
                  <td>{sound.meta && sound.meta.map((meta: any) => `${meta.key}: ${meta.value}`).join(', ')}</td>
                  <td>
                    {sound.url}

                    <DashboardSound sound={sound} />
                  </td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>

          <Divider className="margin-top-2 margin-bottom-1" />

          <H2>Play test sound</H2>
          <Mutation
            mutation={gql`
              mutation testAdminSound($sound: AdminSoundInput!) {
                triggerAdminSound(sound: $sound)
              }
            `}
          >
            {(testAdminSound: any) => {
              return (
                <form
                  onSubmit={event => {
                    event.preventDefault();
                    const sound = _.get(this.state, 'testSound', {});
                    if (sound.description === '') delete sound.description;
                    if (sound.meta && sound.meta.length === 0) delete sound.meta;
                    testAdminSound({ variables: { sound } });
                  }}
                >
                  <InputGroup
                    placeholder="URL"
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'testSound' does not exist on type 'Reado... Remove this comment to see the full error message
                    value={_.get(this.state.testSound, 'url', '') || ''}
                    onChange={this.onTestValueChange('url')}
                    type="url"
                    required
                    className="margin-bottom-1"
                  />
                  <InputGroup
                    placeholder="Key"
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'testSound' does not exist on type 'Reado... Remove this comment to see the full error message
                    value={_.get(this.state.testSound, 'key', '') || ''}
                    onChange={this.onTestValueChange('key')}
                    required
                    className="margin-bottom-1"
                  />
                  <InputGroup
                    placeholder="Description (optional)"
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'testSound' does not exist on type 'Reado... Remove this comment to see the full error message
                    value={_.get(this.state.testSound, 'description', '') || ''}
                    onChange={this.onTestValueChange('description')}
                    className="margin-bottom-1"
                  />
                  <H6>Meta (optional)</H6>
                  {_.get(this.state, 'testSound.meta', [])
                    .concat({})
                    .map((meta: any, i: any) => (
                      <div className="flex flex-row margin-bottom-1" key={`meta-${JSON.stringify(meta)}`}>
                        <InputGroup
                          placeholder="Key"
                          // @ts-expect-error ts-migrate(2339) FIXME: Property 'testSound' does not exist on type 'Reado... Remove this comment to see the full error message
                          value={_.get(this.state.testSound, `meta.${i}.key`, '') || ''}
                          onChange={this.onTestMetaChange(i, 'key')}
                          className="flex-grow-1"
                        />
                        <span className="margin-right-1" />
                        <InputGroup
                          placeholder="Value"
                          // @ts-expect-error ts-migrate(2339) FIXME: Property 'testSound' does not exist on type 'Reado... Remove this comment to see the full error message
                          value={_.get(this.state.testSound, `meta.${i}.value`, '') || ''}
                          onChange={this.onTestMetaChange(i, 'value')}
                          className="flex-grow-1"
                        />
                      </div>
                    ))}

                  <Button type="submit" text="Test" intent="primary" className="margin-right-1" />
                  <HTMLSelect
                    options={[{ value: 'Preset sounds' }, ...Object.keys(this.soundPresets).map(value => ({ value }))]}
                    value="Preset sounds"
                    onChange={event => {
                      this.setState({
                        testSound: this.soundPresets[event.target.value] || {},
                      });
                    }}
                  />
                </form>
              );
            }}
          </Mutation>
        </div>
      </div>
    );
  }
}

export default DashboardSounds;
