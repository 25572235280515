/* eslint-disable max-lines */
import { Classes, Divider, Tag } from '@blueprintjs/core';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import { BookingTag, Icon, SkeletonWrapper } from 'components/common';
import {
  ASKABLE_COLOURS,
  BOOKING_ONLINE_TYPE,
  BOOKING_PARTICIPANT_CATEGORY,
  BOOKING_SESSION_TYPE,
  BOOKING_STATUS,
  BOOKING_TYPE,
} from 'lib/constants';

import { ApproveBooking } from './ApproveBooking/ApproveBooking';
import { BookingPersonInCharge } from './BookingPersonInCharge';

import type { BookingProp } from 'containers/Booking/types';

type Props = {
  _booking_id: string;
  setAppFrame: (a: { path: string; tab: string }) => void;
  booking: BookingProp;
  loading: boolean;
};

// eslint-disable-next-line react/display-name
export default ({ loading, booking, _booking_id }: Props) => {
  const details = {
    team_name: _.get(booking, 'team.name', '(No team)'),
    booking_name: _.get(booking, 'name', '(No name)'),
    booking_status: { text: '?STATUS', color: null },
    status_icons: [],
    booking_type: { label: '', icon: <Icon icon="blank" /> },
    session_duration: _.get(booking, 'config.session.duration'),
    sessions: [],
    timezone: _.get(booking, 'config.timezone', null),
  };

  switch (booking?.status) {
    case BOOKING_STATUS.DRAFT:
      details.booking_status.text = 'DRAFT';
      break;
    case BOOKING_STATUS.ACTIVE:
      details.booking_status.text = 'ACTIVE';
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
      details.booking_status.color = ASKABLE_COLOURS.BOOKING_STATUS_ACTIVE;
      break;
    case BOOKING_STATUS.IN_REVIEW:
      details.booking_status.text = 'IN REVIEW';
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
      details.booking_status.color = ASKABLE_COLOURS.BOOKING_STATUS_IN_REVIEW;
      break;
    case BOOKING_STATUS.COMPLETED:
      details.booking_status.text = 'COMPLETED';
      break;
    case BOOKING_STATUS.ARCHIVED:
      details.booking_status.text = 'ARCHIVED';
      break;
    default:
  }

  switch (_.get(booking, 'config.session.type')) {
    case BOOKING_SESSION_TYPE.ONE_ON_ONE:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{}'.
      details.booking_type.session = '1 on 1 interviews';
      break;
    case BOOKING_SESSION_TYPE.FOCUS_GROUP:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{}'.
      details.booking_type.session = 'Focus group';
      break;
    default:
  }

  switch (booking?.type) {
    case BOOKING_TYPE.FACE_TO_FACE:
      details.booking_type.label = 'In person';
      details.booking_type.icon = <Icon icon="askable-booking-in-person" color="BOOKING_IN_PERSON" />;
      break;
    case BOOKING_TYPE.REMOTE:
      details.booking_type.label = 'Remote';
      details.booking_type.icon = <Icon icon="askable-booking-remote" color="BOOKING_REMOTE" />;
      break;
    case BOOKING_TYPE.ONLINE:
      switch (_.get(booking, 'config.online_task.type')) {
        case BOOKING_ONLINE_TYPE.SURVEY:
          details.booking_type.label = 'Survey';
          break;
        case BOOKING_ONLINE_TYPE.AI_MODERATED:
          details.booking_type.label = 'AI Moderated';
          break;

        default:
          details.booking_type.label = 'Online unmoderated';
      }
      details.booking_type.icon = <Icon icon="askable-booking-online" color="BOOKING_ONLINE" />;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{}'.
      details.booking_type.session = null;
      break;
    case BOOKING_TYPE.LONGITUDINAL:
      details.booking_type.label = 'Longitudinal';
      details.booking_type.icon = <Icon icon="askable-booking-longitudinal" color="BOOKING_LONGITUDINAL" />;
      break;
    case BOOKING_ONLINE_TYPE.UNMODERATED:
      details.booking_type.label = 'Unmoderated';
      details.booking_type.icon = <Icon icon="askable-booking-online" color="BOOKING_ONLINE" />;
      // HACK: for now just hide the session type for new unmoderated bookings
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{}'.
      details.booking_type.session = null;
      break;
    default:
      details.booking_type.icon = <Icon icon="blank" />;
  }

  switch (_.get(booking, 'config.participant_category')) {
    case BOOKING_PARTICIPANT_CATEGORY.GENERAL:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_category' does not exist on ... Remove this comment to see the full error message
      details.participant_category = <strong>General</strong>;
      break;
    case BOOKING_PARTICIPANT_CATEGORY.PROFESSIONALS:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_category' does not exist on ... Remove this comment to see the full error message
      details.participant_category = <strong className="text-warning">Professional</strong>;
      break;
    case BOOKING_PARTICIPANT_CATEGORY.HARD:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_category' does not exist on ... Remove this comment to see the full error message
      details.participant_category = <strong className="text-danger">EXTRA HARD</strong>;
      break;
    default:
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'recruit_type' does not exist on type '{ ... Remove this comment to see the full error message
  details.recruit_type = _.get(booking, 'config.options.review_submission') ? 'Hand-picking' : 'Automated';

  if (details.session_duration > 60) {
    details.session_duration = `${Math.floor(details.session_duration / 60)} hr ${details.session_duration % 60} min`;
  } else if (details.session_duration) {
    details.session_duration = `${details.session_duration} min`;
  }

  if (_.get(booking, 'session.length', 0) > 0) {
    const sessionTimes = [];
    const sessions = _.filter(
      booking?.session,
      session => session?.start && session?.end && (session?.status || 1) === 1,
    );
    sessionTimes.push(..._.map(sessions, 'start'));
    sessionTimes.push(..._.map(sessions, 'end'));
    if (booking?.type === BOOKING_TYPE.ONLINE) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
      details.sessions = [moment(_.max(sessionTimes)).format('[Closes] D MMM hh:mma')];
    } else {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
      details.sessions = _.uniq([
        moment(_.min(sessionTimes)).format('D MMM'),
        moment(_.max(sessionTimes)).format('D MMM'),
      ]);
    }
  }

  if (_.get(booking, 'config.hidden')) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
    details.status_icons.push(<Icon key="hidden" muted icon="eye-off" />);
  }

  let emojiTag = _.get(booking, 'admin.emoji');
  if (!emojiTag) emojiTag = undefined;
  if (!emojiTag && booking?.team_booking_index === 1) emojiTag = '⭐️';

  details.status_icons.push(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
    <BookingTag
      _booking_id={_booking_id}
      value={emojiTag}
      isMissionCritical={_.get(booking, 'admin.mission_critical') || false}
    />,
  );

  const renderLongitudinalTimeframe = () => {
    if (booking?.type !== BOOKING_TYPE.LONGITUDINAL) return;
    if (
      _.get(booking, 'config.longitudinal_study.period.time') &&
      _.get(booking, 'config.longitudinal_study.period.frequency')
    ) {
      const periodFrequencyValue = _.get(
        {
          1: 'day',
          2: 'week',
          3: 'month',
        },
        booking?.config?.longitudinal_study?.period?.frequency || 1,
      );

      return `${booking?.config?.longitudinal_study?.period?.time} ${periodFrequencyValue || '?'}`;
    }
    return null;
  };

  return (
    <div className="section section-overview">
      <div className="margin-bottom-1 flex">
        <strong className={Classes.TEXT_SMALL}>
          <SkeletonWrapper wrap active={loading} length={18}>
            Team: <Link to={`/team/client/${booking?._team_id}`}>{details.team_name}</Link>
          </SkeletonWrapper>
        </strong>
      </div>
      <div className="margin-bottom-1 flex">
        <strong className={Classes.TEXT_SMALL}>
          <SkeletonWrapper wrap active={loading} length={18}>
            {booking.project?.type !== 0 && booking.project && (
              <>
                Project: <Link to={`/askable-plus/project/${booking.project._id}`}>{booking.project.name}</Link>
              </>
            )}
          </SkeletonWrapper>
        </strong>
      </div>
      <div className="margin-bottom-1">
        <SkeletonWrapper wrap active={loading} length={30} className={Classes.UI_TEXT}>
          {details.booking_name}
          &nbsp;
          {_.get(booking, 'team_booking_index') && <Tag minimal>#{booking?.team_booking_index}</Tag>}
        </SkeletonWrapper>
      </div>
      <div className="margin-bottom-2 tw-flex tw-items-center tw-justify-between tw-w-full">
        <div className="tw-flex tw-flex-row tw-items-center">
          <SkeletonWrapper active={loading} display="inline-block">
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'BackgroundC... Remove this comment to see the full error message */}
            <Tag round style={{ backgroundColor: details.booking_status.color }}>
              {details.booking_status.text}
            </Tag>
          </SkeletonWrapper>
          <div className="status-icons">{details.status_icons}</div>
        </div>

        <BookingPersonInCharge
          bookingId={_booking_id}
          adminUser={booking?.admin_user}
          operationalOffice={_.get(booking, 'team.operational_office')}
        />
      </div>
      {booking?.status === BOOKING_STATUS.IN_REVIEW && (
        <div className="margin-left--1 margin-right--1 width-auto">
          <ApproveBooking booking={booking} />
        </div>
      )}

      <Divider className="margin-top-2 margin-bottom-2" />

      <div className="tw-text-md tw-font-bold tw-flex tw-justify-between tw-items-center">
        <p>Minimum participant rating</p>
        <p>
          {booking?.config?.min_participant_rating
            ? `${(booking?.config?.min_participant_rating * 5).toFixed(2)} / 5`
            : 'None'}
        </p>
      </div>

      <Divider className="margin-top-2 margin-bottom-2" />
      <div className="detail-item margin-bottom-1">
        <div className="icon">
          <SkeletonWrapper active={loading}>{details.booking_type.icon}</SkeletonWrapper>
        </div>
        <div className={`label ${Classes.TEXT_MUTED}`}>
          <SkeletonWrapper active={loading} length={14}>
            {details.booking_type.label}
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{}'. */}
            {details.booking_type.session && ` - ${details.booking_type.session}`}
          </SkeletonWrapper>
        </div>
      </div>
      <div className="detail-item margin-bottom-1">
        <div className="icon">
          <SkeletonWrapper active={loading}>
            <Icon icon="people" />
          </SkeletonWrapper>
        </div>
        <div className="label">
          <SkeletonWrapper active={loading} length={11}>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_category' does not exist on ... Remove this comment to see the full error message */}
            {booking?.total_participants} {details.participant_category} participant
            {booking?.total_participants === 1 ? '' : 's'}
          </SkeletonWrapper>
        </div>
      </div>
      {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'recruit_type' does not exist on type '{ ... Remove this comment to see the full error message */}
      {details.recruit_type && (
        <div className="detail-item margin-bottom-1 margin-top--2 padding-top-05">
          <div className="icon">
            <Icon icon="blank" />
          </div>
          <div className={`label ${Classes.TEXT_MUTED}`}>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'recruit_type' does not exist on type '{ ... Remove this comment to see the full error message */}
            <SkeletonWrapper active={loading}>({details.recruit_type})</SkeletonWrapper>
          </div>
        </div>
      )}

      <div className="detail-item margin-bottom-1">
        <div className="icon">
          <SkeletonWrapper active={loading}>
            <Icon icon="stopwatch" muted />
          </SkeletonWrapper>
        </div>
        <div className={`label ${Classes.TEXT_MUTED}`}>
          <SkeletonWrapper active={loading} length={4}>
            {details.session_duration}
          </SkeletonWrapper>
        </div>
      </div>
      <div className="detail-item margin-bottom-1">
        <div className="icon">
          <SkeletonWrapper active={loading}>
            <Icon icon="calendar" muted />
          </SkeletonWrapper>
        </div>
        <div className={`label ${Classes.TEXT_MUTED}`}>
          <SkeletonWrapper active={loading} length={4}>
            {details.sessions ? details.sessions.join(' - ') : '-'}
            {renderLongitudinalTimeframe() ? ` (${renderLongitudinalTimeframe()} study)` : ''}
          </SkeletonWrapper>
        </div>
      </div>
      {_.get(details, 'timezone') && (
        <div className="detail-item margin-bottom-1">
          <div className="icon">
            <Icon icon="globe-network" muted />
          </div>
          <div className={`label ${Classes.TEXT_MUTED}`}>
            {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
            {moment.tz.zone(details.timezone).name} {moment.tz(details.timezone).format('(zz) Z')}
          </div>
        </div>
      )}
    </div>
  );
};
