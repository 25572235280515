import { Button, Icon, InputGroup } from '@blueprintjs/core';
import { forwardRef, useState } from 'react';

import type { PropsWithChildren, FC, ChangeEvent } from 'react';

type InlineEditProps = PropsWithChildren<{
  edited: boolean;
  onClick?: (v: any) => void;
  editable?: boolean;
  onSaveClick: () => void;
  onCancelClick: () => void;
  onClearClick?: (() => void) | null;
}>;

// eslint-disable-next-line react/display-name
export const InlineEdit = forwardRef<HTMLDivElement, InlineEditProps>(
  ({ onClick, onSaveClick, onCancelClick, onClearClick, editable, edited, children }, ref) => {
    const handleClick = (v: any) => {
      if (edited || !onClick) {
        return;
      }

      onClick(v);
    };

    const iconClasses =
      'tw-cursor-pointer tw-invisible tw-ml-2 group-hover:tw-visible tw-opacity-70 hover:tw-opacity-100';

    return (
      <div className="tw-flex tw-items-center tw-group" ref={ref}>
        <div>{children}</div>
        {edited && (
          <>
            <Button className="tw-ml-4" onClick={onSaveClick}>
              <Icon icon="tick" />
            </Button>
            <Button className="tw-ml-2" onClick={onCancelClick}>
              <Icon icon="cross" />
            </Button>
          </>
        )}
        {!edited && editable && (
          <>
            <div className={iconClasses} onClick={handleClick}>
              <Icon icon="edit" />
            </div>

            {onClearClick && (
              <div className={iconClasses} onClick={onClearClick}>
                <Icon icon="cross" />
              </div>
            )}
          </>
        )}
      </div>
    );
  },
);

type InlineInputEditProps = {
  onChange: (v: string | number) => void;
  editable: boolean;
  value: string | number;
};

export const InlineInputEdit: FC<InlineInputEditProps> = ({ value, editable, onChange }) => {
  const [edited, setEdited] = useState(false);
  const [editing, setEditing] = useState(false);
  const [localValue, setLocalValue] = useState<string | number>(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);

    setEdited(true);
  };

  const handleSave = () => {
    onChange(localValue);
    setEdited(false);
    setEditing(false);
  };

  const handleCancelClick = () => {
    setLocalValue(value);
    setEdited(false);
    setEditing(false);
  };

  return (
    <InlineEdit
      onClick={() => setEditing(true)}
      onSaveClick={handleSave}
      edited={edited || editing}
      editable={editable}
      onCancelClick={handleCancelClick}
    >
      {editing ? (
        <InputGroup value={localValue.toString()} onChange={handleChange} />
      ) : (
        Number(localValue).toLocaleString()
      )}
    </InlineEdit>
  );
};
