import gql from 'graphql-tag';

export default gql`
  query admin_getIndustryData {
    industryList {
      _id
      name
      subcategories {
        _id
        name
      }
    }
  }
`;
