import { Classes, Tooltip, Button, Alignment } from '@blueprintjs/core';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Fragment } from 'react';

import { AskableNavbar, Spinner } from 'components/common';

class ParticipantApp extends Component {
  popoutResolve: any;
  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = { iframeKey: Date.now() };

    this.appMessageListener = this.appMessageListener.bind(this);
    this.appPopout = this.appPopout.bind(this);
    this.iframeLogin = this.iframeLogin.bind(this);
  }
  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loginAsUser' does not exist on type 'Rea... Remove this comment to see the full error message
    this.props.loginAsUser.mutate().then((result: any) => {
      this.setState({ login: _.get(result, 'data.adminLoginAsClient.user') });
    });
    window.addEventListener('message', this.appMessageListener);
  }

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_app_iframe' does not exist o... Remove this comment to see the full error message
    if (window.participant_app_iframe && this.props.path !== prevProps.path) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_app_iframe' does not exist o... Remove this comment to see the full error message
      window.participant_app_iframe.postMessage(JSON.stringify({ event: 'admin_iframe', path: this.props.path }), '*');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.appMessageListener);
  }

  appMessageListenerData(data: any) {
    if (typeof data === 'object') return data;
    try {
      const parsed = JSON.parse(data);
      return parsed;
    } catch (err) {
      console.warn('appMessageListener returned string that could not be parsed', err);
      return undefined;
    }
  }

  appMessageListener(event: any) {
    const data = this.appMessageListenerData(_.get(event, 'data')) || {};
    if (data && data.participantAppPath && this.popoutResolve) {
      this.popoutResolve(data.participantAppPath);
      delete this.popoutResolve;
    }
  }

  appPopout() {
    if (this.popoutResolve) return;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_app_iframe' does not exist o... Remove this comment to see the full error message
    window.participant_app_iframe.postMessage(JSON.stringify({ event: 'admin_iframe', getPath: true }), '*');
    return new Promise(resolve => {
      this.popoutResolve = resolve;
    });
  }

  iframeLogin({ login, loaded }: any) {
    const state = {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'login' does not exist on type 'Readonly<... Remove this comment to see the full error message
      login: this.state.login || login,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loaded' does not exist on type 'Readonly... Remove this comment to see the full error message
      loaded: this.state.loaded || loaded,
    };
    if (state.login && state.loaded) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_app_iframe' does not exist o... Remove this comment to see the full error message
      window.participant_app_iframe.postMessage(
        JSON.stringify({ event: 'admin_iframe', login: state.login, path: '/' }),
        '*',
      );
    }
    this.setState(state);
  }

  appDomain() {
    switch (window.location.host) {
      case 'admin.askable.com':
        return 'https://my.askable.com';
      case 'admin-dev.askable.com':
        return 'https://my-dev.askable.com';
      case 'localhost:5100':
        return 'http://localhost:8081';
      // return 'https://my-dev.askable.com';
      default:
        return 'https://my-dev.askable.com';
    }
  }

  render() {
    const warnings = [];
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (_.get(this.props.user, 'location.timezone') && this.props.user.location.timezone !== moment.tz.guess()) {
      warnings.push(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        `Times below are displayed in your timezone, but the participant is in ${_.get(this.props.user, 'location.timezone', 'another timezone')}`,
      );
    }

    return (
      <Fragment>
        <AskableNavbar
          items={[
            {
              key: 'Participant App',
              className: 'margin-right-1',
              component: <span>Participant App</span>,
            },
            {
              key: 'pop-out',
              icon: 'share',
              align: 'right',
              onClick: async () => {
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                const path = `${this.appDomain()}/(tabs)/applied?_user_id=${this.state.login._id}&_token=${this.state.login.access_token}`;
                const win = window.open(path, 'participant_app_window');
                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                win.focus();
              },
            },
            {
              key: 'reset',
              icon: 'reset',
              align: 'right',
              onClick: () => {
                this.setState({ iframeKey: Date.now() });
              },
            },
            ...(warnings.length > 0
              ? [
                  {
                    key: 'timezone-warning',
                    icon: 'warning-sign',
                    align: Alignment.RIGHT,
                    component: (
                      <Tooltip
                        content={
                          <ul className={`${Classes.LIST_UNSTYLED} ${Classes.UI_TEXT}`} style={{ maxWidth: '260px' }}>
                            {warnings.map(warning => (
                              <li key={warning}>{warning}</li>
                            ))}
                          </ul>
                        }
                        intent="warning"
                      >
                        <Button icon="warning-sign" intent="warning" minimal small />
                      </Tooltip>
                    ),
                  },
                ]
              : []),
            // {
            //     key: 'back',
            //     icon: 'arrow-left',
            //     align: 'right'
            // },
          ]}
        />
        <div className="participant-app">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'login' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          {this.state.login && (
            <iframe
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'iframeKey' does not exist on type 'Reado... Remove this comment to see the full error message
              key={this.state.iframeKey}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'login' does not exist on type 'Readonly<... Remove this comment to see the full error message
              src={`${this.appDomain()}/(tabs)/applied?_user_id=${this.state.login._id}&_token=${this.state.login.access_token}`}
              title="participant_app_iframe"
              id="participant_app_iframe"
              name="participant_app_iframe"
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: 0,
              }}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          )}

          <Spinner className="iframe-spinner" />
        </div>
      </Fragment>
    );
  }
}

export default ParticipantApp;
