import { Query, Mutation } from '@apollo/client/react/components';
import _ from 'lodash';

import createMessage from '@graphql/mutations/messages/createMessage';
import adminMessages from '@graphql/queries/messages/adminMessages';
import messagesSubscription from '@graphql/subscriptions/messages/messagesSubscription';
import { MessagesConversation } from 'components/common';
import { MESSAGE_TYPE, MESSAGE_DIRECTION } from 'lib/constants';

// eslint-disable-next-line react/display-name
export default ({ user }: any) => {
  const messageFilters = {
    type: MESSAGE_TYPE.ADMIN,
    _user_id: user._id,
    direction: {
      sent: MESSAGE_DIRECTION.ASKABLE_TO_PARTICIPANT,
      received: MESSAGE_DIRECTION.PARTICIPANT_TO_ASKABLE,
    },
  };

  return (
    <div className="admin-messages">
      <Mutation mutation={createMessage}>
        {(createMessageMutation: any) => (
          <Query query={adminMessages} variables={{ _user_id: user._id }} fetchPolicy="network-only">
            {({ subscribeToMore, loading, ...result }: any) => {
              // console.log({ loading, result });
              const messages = _.chain(result)
                .get('data.userAdminMessages', [])
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'map' does not exist on type 'FunctionCha... Remove this comment to see the full error message
                .map((doc: any) => {
                  const message = {
                    key: doc._id,
                    date: doc.created,
                    body: doc.body,
                    from: _.get(doc, 'UserFrom.meta.identity.firstname'),
                    seen: doc.seen,
                    classes: [],
                  };

                  switch (doc.direction) {
                    case messageFilters.direction.sent:
                      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      message.classes.push('direction-sent');
                      break;
                    case messageFilters.direction.received:
                      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      message.classes.push('direction-received');
                      break;
                    default:
                  }

                  return message;
                })
                .value();

              return (
                <MessagesConversation
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ messages: any; subscribeToMessages: () => ... Remove this comment to see the full error message
                  messages={messages}
                  subscribeToMessages={() =>
                    subscribeToMore({
                      document: messagesSubscription,
                      variables: { filter: _.pick(messageFilters, ['type', '_user_id']) },
                      updateQuery: (prev: any, { subscriptionData }: any) => {
                        if (_.get(prev, 'userAdminMessages') && _.get(subscriptionData, 'data.messagesSubscription')) {
                          const next = _.cloneDeep(prev);
                          next.userAdminMessages.push(subscriptionData.data.messagesSubscription);
                          return next;
                        }

                        return prev;
                      },
                    })
                  }
                  loading={loading}
                  onSend={(body: any) => {
                    const message = {
                      type: MESSAGE_TYPE.ADMIN,
                      direction: MESSAGE_DIRECTION.ASKABLE_TO_PARTICIPANT,
                      tag: 'admin_app',
                      _to_user_id: user._id,
                      sms: true,
                      body,
                    };
                    createMessageMutation({ variables: { message } });
                  }}
                />
              );
            }}
          </Query>
        )}
      </Mutation>
    </div>
  );
};
