import { Button } from '@blueprintjs/core';
import { matchSorter } from 'match-sorter';
import { useMemo, useState } from 'react';
import { FaHubspot } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { ColumnFilter, VirtualisedTable } from 'components/table/VirtualisedTable';
import { useOrganisationsQuery, useSyncOrganisationOnHubspotMutation } from 'generated/graphql';

import { Spinner } from '../common';

import type { OrganisationsQuery } from 'generated/graphql';
import type { FC } from 'react';

type OrganisationType = NonNullable<OrganisationsQuery['organisations']>[number];
type OrganisationRowType = OrganisationType & { teamCount: number };

const TeamListTwo: FC = () => {
  const [loading, setLoading] = useState(false);
  const [{ data: fetchedData, fetching }] = useOrganisationsQuery();

  // Mutations
  const [, syncOrganisationOnHubspot] = useSyncOrganisationOnHubspotMutation();

  const data: OrganisationRowType[] = useMemo(() => {
    return (
      fetchedData?.organisations?.map(org => {
        return {
          ...org,
          _id: org!._id,
          teamCount: org?.Teams?.length ?? 0,
        };
      }) ?? []
    );
  }, [fetchedData]);

  const onSyncToHubspot = async (organisation_id: string) => {
    setLoading(true);
    try {
      await syncOrganisationOnHubspot({
        _id: organisation_id,
      });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const filterTypes = useMemo(
    () => ({
      fuzzyText: (rows: any, [id]: any[], filterValue: any) => {
        return matchSorter<any>(rows, filterValue, {
          keys: [
            row => {
              return row.values[id];
            },
          ],
        });
      },
    }),
    [],
  );

  const columns: any = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: (row: OrganisationRowType) => {
          return row.name;
        },
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        Cell: ({ row }: { row: any }) => {
          const { original } = row;
          return (
            <div>
              <Link to={`/team/organisation/${original._id}`}>{original.name}</Link>
              {original.integrations?.hubspot_company_id ? (
                <a
                  href={`https://app.hubspot.com/contacts/8293753/company/${original.integrations.hubspot_company_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className="tw-ml-4">
                    <FaHubspot />
                  </Button>
                </a>
              ) : (
                <Button className="tw-ml-4" onClick={() => onSyncToHubspot(original._id)}>
                  Sync to Hubspot
                </Button>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Total teams',
        accessor: (row: any) => row.teamCount,
        Cell: ({ row }: { row: any }) => {
          const { original } = row;

          return <div>{original.teamCount}</div>;
        },
      },
      {
        Header: 'Organisation wide ppt exclusion',
        accessor: (row: any) => (row.settings?.exclude_participants_from_teams ? 'Yes' : 'No'),
        Cell: ({ row }: { row: any }) => {
          const { original } = row;

          return <div>{original.settings?.exclude_participants_from_teams ? 'Yes' : 'No'}</div>;
        },
      },
    ];
  }, []);

  const initialSortBy = useMemo(() => {
    return [{ id: 'Name', desc: false }];
  }, []);

  if (fetching || loading) {
    return <Spinner />;
  }

  return <VirtualisedTable filterTypes={filterTypes} initialSortBy={initialSortBy} data={data} columns={columns} />;
};

export default TeamListTwo;
