import { Card, Tag } from '@blueprintjs/core';
import { uniq } from 'lodash';
import moment from 'moment';
import { useEffect, useRef } from 'react';

import { ScreenerAnalysisSubmissions } from './ScreenerAnalysisSubmissions';
import { DATE_ANCHOR_FORMAT } from './constants';

import type { UserSubmissionAnalysisFieldsFragment } from 'generated/graphql';
import type { FC } from 'react';

export const ScreenerAnalysisResult: FC<{ analysis: UserSubmissionAnalysisFieldsFragment }> = ({ analysis }) => {
  const reasoningDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!reasoningDivRef?.current?.innerHTML || !analysis.response?.inconsistency_reasoning) {
      return;
    }

    let newHtml = analysis.response?.inconsistency_reasoning;
    const pattern = /([0-9a-fA-F]{24})|(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z|\d{4}-\d{2}-\d{2}\b)/gm;
    const matches = uniq(newHtml.match(pattern) ?? []);

    for (const match of matches) {
      const [matchType, replaceId, replaceText] = (() => {
        if (match.length === 10) {
          const m = moment(match);
          return ['date', m.format(DATE_ANCHOR_FORMAT.DATE), m.format('D MMM YYYY')];
        }
        if (match.slice(-1) === 'Z') {
          const m = moment(match);
          return ['ts', m.format(DATE_ANCHOR_FORMAT.TS), m.format('D MMM, h:mma')];
        }
        return ['id', match, match];
      })();

      newHtml = newHtml?.replace(
        new RegExp(`\\b${match}\\b`, 'g'),
        `<a href="#sub-${matchType}-${replaceId}" class="!tw-text-inherit tw-underline tw-decoration-dotted tw-whitespace-nowrap">${replaceText}</a>`,
      );
    }

    reasoningDivRef.current.innerHTML = newHtml;
  }, [analysis.response?.inconsistency_reasoning]);

  return (
    <div className="tw-space-y-6">
      <section>
        <h2 className="tw-font-bold tw-text-lg tw-mb-2">
          <div className="tw-flex tw-flex-row tw-items-center">
            <span>Inconsistency:</span>{' '}
            <Tag
              minimal
              className="tw-text-lg tw-ml-2"
              intent={(() => {
                if (analysis.response.inconsistency_verdict <= 0.2) {
                  return 'success';
                }
                if (analysis.response.inconsistency_verdict < 0.7) {
                  return 'warning';
                }
                return 'danger';
              })()}
            >
              {Math.round(analysis.response.inconsistency_verdict * 100)}%
            </Tag>
          </div>
        </h2>
      </section>
      <section>
        <h2 className="tw-font-bold tw-text-lg tw-mb-2">Reasoning:</h2>
        <Card>
          <p className="bp3-running-text tw-whitespace-pre-wrap" ref={reasoningDivRef}>
            {analysis?.response?.inconsistency_reasoning ?? '(reasoning is missing)'}
          </p>
        </Card>
      </section>
      <section>
        <h2 className="tw-font-bold tw-text-lg tw-mb-2">Screener submissions:</h2>
        <ScreenerAnalysisSubmissions screenerResult={analysis?.prompt?.variables?.submissions ?? undefined} />
      </section>
    </div>
  );
};
