/* eslint-disable max-lines */
import { graphql } from '@apollo/client/react/hoc';
import { Classes, Icon, Tooltip, NonIdealState } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _, { flowRight as compose } from 'lodash';
import moment from 'moment';
import { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';

import { DataTable, ErrorCallout } from 'components/common';
import {
  // LABELS,
  BOOKING_STATUS,
  BOOKING_PARTICIPANT_STATUS,
  BOOKING_PARTICIPANT_CANCEL,
} from 'lib/constants';
import { utils } from 'lib/utils';
import { formatIncentive } from 'utils/currency-utils';

class TransactionsTable extends Component {
  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = {
      loading: true,
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    if (state.rows && props.timezone !== state.timezone) {
      state.rows = null;
    }
    if (!state.rows && _.get(props, 'fetchBookings.findBookingSubmission')) {
      state.rows = [];

      state.rows = props.fetchBookings.findBookingSubmission
        .map(({ booking, CurrentStatus, sessions, status, ...booking_submission }: any) => {
          if (!booking) return null;

          const row = {
            key: booking._id,
            booking,
            CurrentStatus,
            status,
            sessions,
            booking_submission,
            filters: [],
            sort: {
              name: (booking.name || '').toLowerCase().replace(/[^0-9a-z]/g, ''),
              format: booking.type,
              location: [
                _.get(booking, 'config.location.country', ''),
                _.get(booking, 'config.location.state', ''),
                _.get(booking, 'config.location.region', ''),
                _.get(booking, 'config.location.city', ''),
              ]
                .join(' ')
                .toLowerCase()
                .replace(/\s+/g, '')
                .replace(/[^0-9a-z\s]/g, ''),
              client: [
                _.get(booking, 'team.name', ''),
                _.get(booking, 'user.meta.identity.firstname'),
                _.get(booking, 'user.meta.identity.lastname'),
              ]
                .join('Z')
                .toLowerCase()
                .replace(/[^0-9a-z]/g, ''),
            },
          };
          let participantFilter = null;

          row.CurrentStatus = { ...row.CurrentStatus };

          if (!row.CurrentStatus) {
            const available = _.filter(
              sessions,
              session => session.status === BOOKING_PARTICIPANT_STATUS.AVAILABLE && !session.cancel,
            );
            if (available.length !== 0) {
              row.CurrentStatus = _.maxBy(available, 'created') || {};
              row.CurrentStatus.available_times = available;
            }
            row.CurrentStatus = { status };
          }

          switch (_.get(row.CurrentStatus, 'status')) {
            case 'reported':
              participantFilter = 'reported';
              row.CurrentStatus.label = 'Reported';
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ name: ... Remove this comment to see the full error message
              row.sort.status = 'Reported';
              break;
            case BOOKING_PARTICIPANT_STATUS.CONFIRMED:
              if (row.booking_submission.completed && !row.CurrentStatus.cancel) {
                participantFilter = 'completed';
                row.CurrentStatus.label = 'Completed';
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ name: ... Remove this comment to see the full error message
                row.sort.status = 'Completed';
              } else {
                participantFilter = 'upcoming';
                row.CurrentStatus.label = 'Confirmed';
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ name: ... Remove this comment to see the full error message
                row.sort.status = 'Confirmed';
              }
              break;
            case BOOKING_PARTICIPANT_STATUS.WAITLISTED:
              participantFilter = 'upcoming';
              row.CurrentStatus.label = 'Waitlisted';
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ name: ... Remove this comment to see the full error message
              row.sort.status = 'Waitlisted';
              break;
            case BOOKING_PARTICIPANT_STATUS.AVAILABLE:
              participantFilter = 'applied';
              row.CurrentStatus.label = 'Available';
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ name: ... Remove this comment to see the full error message
              row.sort.status = 'Available';
              break;
            case BOOKING_PARTICIPANT_STATUS.INVITED:
              participantFilter = 'upcoming';
              row.CurrentStatus.label = 'Invited';
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ name: ... Remove this comment to see the full error message
              row.sort.status = 'Invited';
              break;
            case BOOKING_PARTICIPANT_STATUS.IN_PROGRESS:
              participantFilter = 'upcoming';
              row.CurrentStatus.label = 'Quant In-progress';
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ name: ... Remove this comment to see the full error message
              row.sort.status = 'Quant In-progress';
              break;
            default:
              participantFilter = 'applied';
              row.booking_submission = {
                label: 'Applied',
                status_updated: booking_submission.created,
              };
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ name: ... Remove this comment to see the full error message
              row.sort.status = 'Applied';
              break;
          }

          if (row.booking_submission && !row.booking_submission.status_updated) {
            row.booking_submission.status_updated = row.booking_submission.updated || row.booking_submission.created;
          }

          // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_status' does not exist on ty... Remove this comment to see the full error message
          row.participant_status = null;
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_status' does not exist on ty... Remove this comment to see the full error message
          row.participant_status = (
            <Fragment>
              <span>{_.get(row, 'CurrentStatus.label', '?')}</span>
            </Fragment>
          );

          if (row.booking_submission.status_updated) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'status_updated' does not exist on type '... Remove this comment to see the full error message
            row.status_updated = (
              <Fragment>
                <span>{moment.duration(row.booking_submission.status_updated - Date.now()).humanize(true)}</span>
                <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                  {moment(row.booking_submission.status_updated).format('D MMM YY, h:mm A')}
                </small>
              </Fragment>
            );
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'status_updated' does not exist on type '... Remove this comment to see the full error message
            row.sort.status_updated = row.booking_submission.status_updated;
          }

          if (row.CurrentStatus.cancel) {
            let cancelText = null;
            switch (row.CurrentStatus.cancel) {
              case BOOKING_PARTICIPANT_CANCEL.BY_ADMIN:
                cancelText = 'Cancelled by Askable admin';
                break;
              case BOOKING_PARTICIPANT_CANCEL.BY_CLIENT:
                cancelText = 'Cancelled by the client';
                break;
              case BOOKING_PARTICIPANT_CANCEL.BY_PARTICIPANT:
                cancelText = 'Cancelled by the participant';
                break;
              case BOOKING_PARTICIPANT_CANCEL.BY_SYSTEM:
                cancelText = 'Auto-cancelled by system';
                break;
              default:
            }
            if (cancelText) {
              if (row.CurrentStatus.cancel_reason && row.CurrentStatus.cancel_reason !== 'null') {
                cancelText = (
                  <Tooltip
                    content={<div style={{ maxWidth: '300px' }}>{row.CurrentStatus.cancel_reason}</div>}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; content: Element; bound... Remove this comment to see the full error message
                    boundary="viewport"
                    style
                  >
                    <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                      {cancelText} <Icon icon="help" iconSize={9} />
                    </small>
                  </Tooltip>
                );
              } else {
                cancelText = <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>{cancelText}</small>;
              }
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'participant_status' does not exist on ty... Remove this comment to see the full error message
            row.participant_status = (
              <Fragment>
                <span className={`text-strikethough ${Classes.TEXT_MUTED}`}>
                  {_.get(row, 'CurrentStatus.label', '?')}
                </span>
                {cancelText}
              </Fragment>
            );
          }

          if (row.CurrentStatus) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'CurrentStatus_history' does not exist on... Remove this comment to see the full error message
            row.CurrentStatus_history = _.mapValues(
              row.CurrentStatus.history,
              history => history && new Date(history).toString(),
            );
          }

          // @ts-expect-error ts-migrate(2339) FIXME: Property 'rsvp' does not exist on type '{ key: any... Remove this comment to see the full error message
          row.rsvp = null;
          if (booking.status === BOOKING_STATUS.ACTIVE) {
            // const rsvpTime = _.chain(CurrentStatus)
            //     .get('history', {})
            //     .toPairs()
            //     .filter(history => history[1] && history[0].match(/session_start_reminder/))
            //     .map(history => history[1])
            //     .max()
            //     .value();

            const rsvpTime = _.get(CurrentStatus, 'history.session_start_reminder_action_needed');

            if (rsvpTime) {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'rsvp' does not exist on type '{ name: an... Remove this comment to see the full error message
              row.sort.rsvp = rsvpTime;
              if (_.get(booking_submission, 'user_confirm') > rsvpTime) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'rsvp' does not exist on type '{ key: any... Remove this comment to see the full error message
                row.rsvp = (
                  <Fragment>
                    <span>Confirmed</span>
                    <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                      {moment.duration(booking_submission.user_confirm - Date.now()).humanize(true)}
                    </small>
                  </Fragment>
                );
              } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'rsvp' does not exist on type '{ key: any... Remove this comment to see the full error message
                row.rsvp = (
                  <Fragment>
                    <span>Sent</span>
                    <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                      {moment.duration(rsvpTime - Date.now()).humanize(true)}
                    </small>
                  </Fragment>
                );
              }
            }
          }

          switch (booking.status) {
            case BOOKING_STATUS.ACTIVE:
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking_status' does not exist on type '... Remove this comment to see the full error message
              row.booking_status = 'Active';
              break;
            case BOOKING_STATUS.COMPLETED:
            case BOOKING_STATUS.ARCHIVED:
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking_status' does not exist on type '... Remove this comment to see the full error message
              row.booking_status = 'Finished';
              if (participantFilter !== 'completed') {
                participantFilter = 'closed';
              }
              break;
            default:
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking_status' does not exist on type '... Remove this comment to see the full error message
              row.booking_status = null;
          }

          const incentiveValue = booking_submission.incentive ? formatIncentive(booking_submission.incentive) : '';
          const incentivePayment = _.chain(sessions)
            .find(doc => _.get(doc, 'transaction.payment_due'))
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'get' does not exist on type 'FunctionCha... Remove this comment to see the full error message
            .get('transaction')
            .value();

          if (incentivePayment) {
            const paymentStatusClasses = [Classes.TEXT_SMALL, Classes.TEXT_MUTED];
            let paymentStatus = '?';
            if (_.get(incentivePayment, 'transactions.provider.reference')) {
              switch (_.get(incentivePayment, 'transactions.provider.name')) {
                case 'paypal':
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
                  paymentStatus = (
                    <a
                      href={`https://www.paypal.com/activity/masspay/MPA-${incentivePayment.transactions.provider.reference}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Paid
                    </a>
                  );
                  break;
                default:
                  paymentStatus = 'Paid';
              }
            } else if (_.get(incentivePayment, '_id')) {
              paymentStatus = 'Failed';
              paymentStatusClasses.push('text-danger');
            } else {
              paymentStatus = moment(incentivePayment.payment_due).format('[Due] D MMM YYYY');
            }
            const transaction = incentivePayment?.transactions;
            const value = formatIncentive({ value: transaction?.total_amount, currency_code: transaction?.currency });
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'incentive' does not exist on type '{ key... Remove this comment to see the full error message
            row.incentive = (
              <Fragment>
                <span>{value}</span>
                <small className={paymentStatusClasses.join(' ')}>{paymentStatus}</small>
              </Fragment>
            );
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'incentive' does not exist on type '{ key... Remove this comment to see the full error message
            row.incentive = incentiveValue;
          }
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'incentive' does not exist on type '{ nam... Remove this comment to see the full error message
          row.sort.incentive = booking_submission?.incentive?.value ?? 0;

          const country =
            booking_submission?.user?.location?.country ??
            booking_submission?.incentive?.country_code ??
            booking?.config?.location?.country ??
            '';
          const flag = country ? utils.countryCodeToFlag(country) : '';

          // @ts-expect-error FIXME: Property 'booking_submission_location' does not exist on type
          row.booking_submission_location = (
            <Fragment>
              <span>
                {country} {flag}
              </span>
            </Fragment>
          );
          // @ts-expect-error FIXME: Property 'booking_submission_location' does not exist on type
          row.sort.booking_submission_location = country;

          // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_time' does not exist on type '{ ... Remove this comment to see the full error message
          row.session_time = null;
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_start' does not exist on type '{... Remove this comment to see the full error message
          row.session_start = null;
          if (row.CurrentStatus) {
            if (_.get(row, 'CurrentStatus.available_times.length', 0) > 1) {
              const session_times = row.CurrentStatus.available_times.map((booking_participant: any) =>
                _.get(booking_participant, 'session.start'),
              );
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_time' does not exist on type '{ ... Remove this comment to see the full error message
              row.session_time = (
                <Fragment>
                  {row.CurrentStatus.available_times.length} time
                  {row.CurrentStatus.available_times.length === 1 ? '' : 's'}
                  <small className={Classes.TEXT_MUTED}>
                    {_.chain([_.min(session_times), _.max(session_times)])
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      .map(time => moment(time).format('D MMM YYYY'))
                      .uniq()
                      .value()
                      .join(' - ')}
                  </small>
                </Fragment>
              );
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ name:... Remove this comment to see the full error message
              row.sort.session = _.min(session_times);
            } else if (_.get(row, 'CurrentStatus.session._id')) {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'session' does not exist on type '{ name:... Remove this comment to see the full error message
              row.sort.session = row.CurrentStatus.session.start;
              const session_time = moment(row.CurrentStatus.session.start);
              if (booking.type === 3) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_time' does not exist on type '{ ... Remove this comment to see the full error message
                row.session_time = <div>{session_time.format('D MMM YYYY')}</div>;
              } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_time' does not exist on type '{ ... Remove this comment to see the full error message
                row.session_time = (
                  <Fragment>
                    <div>
                      {session_time.format('h:mm A')} - {moment(row.CurrentStatus.session.end).format('h:mm A')}
                    </div>
                    <small className={Classes.TEXT_MUTED}>{session_time.format('D MMM YYYY')}</small>
                  </Fragment>
                );
                if (row.CurrentStatus.session.start > Date.now()) {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'startsIn' does not exist on type '{ name... Remove this comment to see the full error message
                  row.sort.startsIn = row.CurrentStatus.session.start;
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_start' does not exist on type '{... Remove this comment to see the full error message
                  row.session_start = moment.duration(Date.now() - row.CurrentStatus.session.start).humanize();
                }
              }
            }

            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
            row.filters = [participantFilter];

            // row.session_time = row.booking.type === 3 ? [
            //     moment(row.session.start).format('D MMM YYYY'),
            // ] : [
            //     `${moment(row.session.start).format('h:mm A')} - ${moment(row.session.end).format('h:mm A')}`,
            //     moment(row.session.start).format('D MMM YYYY')
            // ];
          }

          return row;
        })
        .filter((row: any) => row);

      state.loading = false;
      return state;
    }
    return null;
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (this.state.error) {
      return (
        <div className="margin-2">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          <ErrorCallout error={this.state.error} />
        </div>
      );
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'rows' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const rows = this.state.rows || [];

    return (
      <div id="participant-table-bookings">
        <DataTable
          // bordered
          // condensed
          // className="border-bottom"
          striped
          useSettings
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
          loading={this.state.loading}
          loadingRows={6}
          rows={rows}
          filters={{
            props: { className: ['margin-2 margin-left-1', Classes.TEXT_SMALL].join(' ') },
            countRows: true,
            values: [
              { key: 'upcoming', label: 'Upcoming', defaultActive: true },
              { key: 'applied', label: 'Applied' },
              { key: 'completed', label: 'Completed' },
              { key: 'closed', label: 'Closed' },
              { key: 'reported', label: 'Reported' },
            ],
            all: true,
          }}
          noResults={
            <div className="margin-3 margin-top-1">
              <NonIdealState
                icon="th-disconnect"
                title="No results"
                description="It looks like this user hasn’t participated in any bookings"
              />
            </div>
          }
          columns={[
            {
              key: 'status',
              label: 'Status',
              render: 'participant_status',
              sort: { icon: 'alphabetical' },
            },
            {
              key: 'status_updated',
              label: 'Updated',
              render: 'status_updated',
              sort: true,
            },
            {
              key: 'rsvp',
              label: 'RSVP',
              render: 'rsvp',
              sort: true,
            },
            {
              key: 'name',
              label: 'Booking name',
              clip: 0.12,
              render: ({ row }: any) => (
                <Fragment>
                  {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'participantAppLinkClick' does not exist ... Remove this comment to see the full error message */}
                  <a href={`/booking/${row.booking._id}`} onClick={this.props.participantAppLinkClick}>
                    {_.get(row, 'booking.name', '[UNTITLED]')}
                  </a>
                  {row.booking_status && <small className={Classes.TEXT_MUTED}>{row.booking_status}</small>}
                </Fragment>
              ),
              sort: { icon: 'alphabetical' },
            },
            {
              key: 'incentive',
              label: 'Incentive',
              render: 'incentive',
              sort: { icon: 'numerical' },
            },
            {
              key: 'format',
              label: 'Format',
              render: 'booking_format',
              sort: true,
            },
            {
              key: 'booking_submission_location',
              label: 'Location',
              render: 'booking_submission_location',
              sort: { icon: 'alphabetical' },
            },
            {
              key: 'client',
              label: 'Client',
              clip: 0.1,
              render: 'booking_client',
              sort: { icon: 'alphabetical' },
            },
            {
              key: 'session',
              label: 'Session',
              render: 'session_time',
              sort: true,
            },
            {
              key: 'startsIn',
              label: 'Starts in',
              render: 'session_start',
              sort: true,
            },
            {
              key: 'messages',
              label: 'Messages',
              render: ({ row }: any) => (
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'participantAppLinkClick' does not exist ... Remove this comment to see the full error message
                <a href={`/messages/booking/${row.booking._id}`} onClick={this.props.participantAppLinkClick}>
                  <Icon icon="chat" /> Show Messages
                </a>
              ),
            },
            {
              key: 'id',
              label: 'Booking ID',
              render: ({ row }: any) => (
                <Link to={`/booking/${row.booking._id}`} className={Classes.TEXT_MUTED}>
                  <code>{row.booking._id}</code>
                </Link>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

const fetchBookingsQuery = graphql(
  gql`
    query admin_participantBookings($_user_id: ID!) {
      findBookingSubmission(userId: $_user_id) {
        _id
        eligibility
        created
        user_confirm
        status_updated
        status
        completed
        user {
          location {
            country
          }
        }
        incentive {
          value
          currency_symbol
          currency_code
        }
        booking {
          _id
          name
          type
          status
          config {
            location {
              region
              city
              country
              state
            }
          }
          team {
            _id
            name
          }
          user {
            _id
            meta {
              identity {
                firstname
                lastname
              }
            }
          }
        }
        CurrentStatus {
          _id
          status
          cancel
          user_confirm
          status_updated
          completed
          cancel_reason
          updated
          created
          session {
            _id
            start
            end
          }
          history {
            early_confirmation_request
            last_quantitative_application_time
            session_click_confirm_button
            session_completed
            session_completed_feedback_request
            session_completed_feedback_submitted
            session_confirmed_cancellation
            session_invitation_accepted
            session_invitation_declined
            session_invitation_notification
            session_invitation_notification_followup
            session_opportunity_application
            session_paid
            session_participant_cancelled_by_askable
            session_participant_cancelled_by_client
            session_quant_completed
            session_quant_invitation_notification
            session_reinvite_notification
            session_start_reminder_2_askable_incentive_message
            session_start_reminder_action_needed
            session_start_reminder_action_needed_followup
            session_start_reminder_confirmation_pending_askable_notification
            session_start_reminder_last_askable_warning
            session_start_reminder_no_action
          }
        }
        sessions {
          _id
          status
          cancel
          created
          updated
          status_updated
          session {
            _id
            start
            end
          }
          transaction {
            _id
            payment_due
            transactions {
              _id
              total_amount
              currency_symbol
              currency
              status
              method
              provider {
                name
                reference
              }
            }
          }
        }
      }
    }
  `,
  {
    name: 'fetchBookings',
    options: props => ({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type '{}'.
      variables: { _user_id: props.user._id },
      // fetchPolicy: 'network-only'
    }),
  },
);
export default compose(fetchBookingsQuery)(TransactionsTable);
