/* eslint-disable max-lines */
import { Query, Mutation } from '@apollo/client/react/components';
import { Classes, H2, FormGroup, InputGroup, Button, TextArea, NumericInput, NonIdealState } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Fragment } from 'react';

import { PageTitle } from 'components/PageTitle';
import { Select, JsonView, Spinner, Icon, ErrorCallout } from 'components/common';
import {
  CREDIT_ACTIVITY_TYPE,
  TRANSACTIONS_TYPE,
  ACCOUNTING_TYPE,
  BOOKING_TYPE,
  PROJECT_TYPE,
  BOOKING_PARTICIPANT_STATUS,
  BOOKING_PARTICIPANT_CANCEL,
} from 'lib/constants';
import { utils } from 'lib/utils';

class CreditAdjustment extends Component<any, any> {
  billing_subtype: any;
  booking_subtype: any;
  formGroups: any;
  lookupData: any;
  refund_subtype: any;
  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = {
      type: null,
      form: {},
    };

    this.lookupData = {};

    this.validateForm = this.validateForm.bind(this);
    this.getFormValues = this.getFormValues.bind(this);
    this.setFormState = this.setFormState.bind(this);
    this.lookupDataCache = this.lookupDataCache.bind(this);

    this.parseForm = this.parseForm.bind(this);

    this.renderFormPage = this.renderFormPage.bind(this);

    this.billing_subtype = {
      voided_invoice: { label: 'Voided invoice', accounting_type: ACCOUNTING_TYPE.DEBIT },
      transaction_refund: { label: 'Cash refund', accounting_type: ACCOUNTING_TYPE.DEBIT },
      transaction_adjustment: { label: 'Purchase order adjustment', accounting_type: null },
      other: { label: 'Other', accounting_type: null },
    };

    this.booking_subtype = [
      { value: CREDIT_ACTIVITY_TYPE.BOOKING_REQUIREMENT_ADJUSTMENT, label: 'Requirement adjustment' },
      { value: CREDIT_ACTIVITY_TYPE.ADMIN_MANUAL, label: 'Other' },
    ];

    this.refund_subtype = [
      { value: CREDIT_ACTIVITY_TYPE.REFUND_BAD_PARTICIPANT, label: 'Bad participant' },
      { value: CREDIT_ACTIVITY_TYPE.REFUND_OTHER, label: 'Other' },
    ];

    this.setInitialState = this.setInitialState.bind(this);
  }

  componentDidMount() {
    const urlParams = _.pick(utils.urlParams(window.location.search) || {}, ['type', 'form']);
    this.formGroups = this.setFormGroups();

    if (!_.isEmpty(urlParams)) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'form' does not exist on type 'Partial<{}... Remove this comment to see the full error message
      if (urlParams.form) {
        try {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'form' does not exist on type 'Partial<{}... Remove this comment to see the full error message
          urlParams.form = JSON.parse(urlParams.form);
        } catch {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'form' does not exist on type 'Partial<{}... Remove this comment to see the full error message
          delete urlParams.form;
        }
      }
      this.setInitialState(urlParams);
    }
  }

  getFormValues() {
    // run validation
    // get the values from state for the adjustment mutation(s) (and update booking mutation), depending on the type
    return null;
  }

  setFormState(formState: any) {
    this.setState((state: any) => {
      if (formState) {
        Object.assign(state.form, formState);
      } else {
        state.form = {};
      }
      state.formUpdated = Date.now();
      return state;
    });
  }

  getMutationDoc(name: any) {
    const creditActivityFields = gql`
      fragment teamFields on Teams {
        _id
        name
        settings {
          billing {
            subscription {
              credit {
                remaining
              }
            }
          }
        }
      }
      fragment creditActivityFields on CreditActivity {
        _id
        accounting_type
        amount
        created
        type
        comment
        Team {
          ...teamFields
        }
        FromTeam {
          ...teamFields
        }
        ToTeam {
          ...teamFields
        }
        Booking {
          _id
          name
        }
        BookingParticipant {
          _id
          user {
            _id
            email
          }
        }
        Transaction {
          _id
        }
      }
    `;

    switch (name) {
      case 'bookingRequirementAdjustment':
        return gql`
          mutation creditAdjustmentToolUpdate($booking: BookingInput!, $creditActivity: CreditActivityInput) {
            bookingRequirementAdjustment(booking: $booking, creditActivity: $creditActivity) {
              _id
              name
              team {
                name
                _id
              }
              config {
                credits_per_participant
              }
              credit_activity {
                ...creditActivityFields
              }
            }
          }
          ${creditActivityFields}
        `;
      case 'creditTransfer':
        return gql`
          mutation creditAdjustmentToolUpdate($creditActivity: CreditActivityInput!) {
            creditTransfer(creditActivity: $creditActivity) {
              ...creditActivityFields
            }
          }
          ${creditActivityFields}
        `;
      default:
        return gql`
          mutation creditAdjustmentToolUpdate($creditActivity: CreditActivityInput!) {
            creditAdjustment(creditActivity: $creditActivity) {
              ...creditActivityFields
            }
          }
          ${creditActivityFields}
        `;
    }
  }

  setInitialState(initialState: any) {
    this.setState((state: any) => {
      if (initialState.type) {
        state.type = initialState.type;
      }
      if (initialState.form) {
        Object.assign(state.form, initialState.form);
      }
      return state;
    });
  }

  lookupDataCache(name: any, data: any) {
    this.lookupData[name] = data;
    this.setState({ formUpdated: Date.now() });
  }

  parseForm() {
    let mutation = 'creditAdjustment';

    const variables = {
      creditActivity: {
        _admin_user_id: utils.getUserId(),
        type: null,
        comment: _.get(this.state, 'form.comment') || '',
        amount: _.get(this.state, 'form.amount'),
        accounting_type: _.get(this.state, 'form.accounting_type'),
        _team_id: utils.sanitizeObjectId(_.get(this.state, 'form._team_id')),
        _booking_id: utils.sanitizeObjectId(_.get(this.state, 'form._booking_id')),
        _project_id: utils.sanitizeObjectId(_.get(this.state, 'form._project_id')),
        _booking_participant_id: utils.sanitizeObjectId(_.get(this.state, 'form._booking_participant_id')),
        _from_team_id: utils.sanitizeObjectId(_.get(this.state, 'form._from_team_id')),
        _to_team_id: utils.sanitizeObjectId(_.get(this.state, 'form._to_team_id')),
        _transaction_id: utils.sanitizeObjectId(_.get(this.state, 'form._transaction_id')),
      },
    };

    const pick = ['_admin_user_id', '_team_id', 'type', 'amount', 'accounting_type', 'comment']; // the fields that should be in every credit_activity

    switch (this.state.type) {
      case 'billing':
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
        variables.creditActivity.type = CREDIT_ACTIVITY_TYPE.CREDIT_PURCHASE;
        if (_.get(this.billing_subtype, `${this.state.form.billing_subtype}.label`)) {
          variables.creditActivity.comment = `${this.billing_subtype[this.state.form.billing_subtype].label}: ${variables.creditActivity.comment}`;
        }
        pick.push('_booking_id', '_transaction_id');
        break;
      case 'transfer':
        mutation = 'creditTransfer';

        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
        variables.creditActivity.type = CREDIT_ACTIVITY_TYPE.TRANSFER;
        pick.push('_from_team_id', '_to_team_id');
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        _.pull(pick, ['_team_id', 'accounting_type']);
        break;
      case 'expriy':
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
        variables.creditActivity.type = CREDIT_ACTIVITY_TYPE.EXPIRING_CREDITS;
        variables.creditActivity.accounting_type = ACCOUNTING_TYPE.DEBIT;
        if (!variables.creditActivity.comment) {
          variables.creditActivity.comment = 'Expiring credits';
        }
        break;
      case 'promo':
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
        variables.creditActivity.type = CREDIT_ACTIVITY_TYPE.FREE_PROMOTIONAL;
        variables.creditActivity.accounting_type = ACCOUNTING_TYPE.CREDIT;
        break;
      case 'booking':
        mutation = 'bookingRequirementAdjustment';

        // @ts-expect-error ts-migrate(2339) FIXME: Property 'MANUAL' does not exist on type '{ ADMIN_... Remove this comment to see the full error message
        variables.creditActivity.type = _.get(this.state, 'form.booking_subtype', CREDIT_ACTIVITY_TYPE.MANUAL);
        pick.push('_booking_participant_id', '_booking_id');
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type '{ credi... Remove this comment to see the full error message
        variables.booking = {
          _id: _.get(this.state, 'form._booking_id'),
        };
        if (this.state.form.amount === 0) {
          // @ts-expect-error ts-migrate(2790) FIXME: The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
          delete variables.creditActivity;
        }
        if (
          this.state.form.credits_per_participant !==
          _.get(this.lookupData, '_booking_id.bookingByID.config.credits_per_participant')
        ) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type '{ credi... Remove this comment to see the full error message
          _.set(variables.booking, 'config.credits_per_participant', this.state.form.credits_per_participant);
        }
        break;
      case 'refund':
        variables.creditActivity.type = _.get(this.state, 'form.refund_subtype', CREDIT_ACTIVITY_TYPE.REFUND_OTHER);
        pick.push('_booking_participant_id', '_booking_id');
        variables.creditActivity.accounting_type = ACCOUNTING_TYPE.CREDIT;
        break;
      case 'non_booking_usage':
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
        variables.creditActivity.type = CREDIT_ACTIVITY_TYPE.NON_BOOKING_USAGE;
        variables.creditActivity.accounting_type = ACCOUNTING_TYPE.DEBIT;
        pick.push('_transaction_id', '_project_id');
        break;
      case 'custom':
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'null'.
        variables.creditActivity.type = CREDIT_ACTIVITY_TYPE.ADMIN_MANUAL;
        pick.push('_booking_id', '_project_id', '_booking_participant_id', '_transaction_id');
        break;
      default:
        return false;
    }
    if (variables.creditActivity) {
      variables.creditActivity.comment = variables.creditActivity.comment || null;
      // @ts-expect-error ts-migrate(2740) FIXME: Type 'Dictionary<any>' is missing the following pr... Remove this comment to see the full error message
      variables.creditActivity = _.chain(variables.creditActivity)
        .pick(pick)
        .pickBy(value => !(value === null || value === undefined))
        .value();
    }

    return { mutation, variables };
  }

  validateForm() {
    // dependnding on the type value
  }

  IdLookupField = ({ name, label, lookup, required = true, ...inputProps }: any) => {
    const wrapperProps = {
      label: required ? (
        label
      ) : (
        <span>
          {label} <span className={Classes.TEXT_MUTED}>(optional)</span>
        </span>
      ),
      labelFor: `field_${name}`,
    };

    const input = (
      <InputGroup
        value={this.state.form[name] || ''}
        onChange={(e: any) => {
          const formState = {};
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          formState[name] = e.target.value;
          this.setFormState(formState);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
          this.lookupDataCache(name);
        }}
        pattern="[0-9a-fA-F]{24}"
        title="Valid ID"
        className="input-width-30"
        required={required}
        {...inputProps}
      />
    );

    let lookupResult = null;
    if (
      lookup &&
      lookup.query &&
      lookup.resultRender &&
      this.state.form[name] &&
      this.state.form[name].match(/^[0-9a-fA-F]{24}$/)
    ) {
      lookupResult = (
        <div
          className={`margin-left-1 flex flex-column flex-justify-center ${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}
        >
          <Query
            fetchPolicy="cache-and-network"
            {...lookup}
            variables={{ id: this.state.form[name] }}
            onCompleted={(data: any) => {
              if (lookup.onCompleted) {
                lookup.onCompleted(data);
              }
              this.lookupDataCache(name, data);
            }}
            onError={(error: any) => {
              console.error(error);
            }}
          >
            {({ data, loading, error }: any) => {
              if (loading) return <Spinner withText inline tagName="span" />;
              if (error) {
                console.error(error);
                return <Icon icon="error" intent="danger" />;
              }
              return lookup.resultRender(data) || null;
            }}
          </Query>
        </div>
      );
    }

    return (
      <FormGroup {...wrapperProps}>
        <div className="flex flex-row">
          {input}
          {lookupResult}
        </div>
      </FormGroup>
    );
  };

  setFormGroups = () => {
    return {
      __name: () => null,
      _team_id: ({ lookup, ...props }: any) =>
        this.IdLookupField({
          name: '_team_id',
          label: 'Team ID',
          lookup: {
            query: gql`
              query credit_adjustment_lookup($id: ID) {
                teamById(_id: $id) {
                  _id
                  name
                  settings {
                    billing {
                      subscription {
                        credit {
                          remaining
                        }
                      }
                    }
                  }
                }
              }
            `,
            resultRender: (data: any) => {
              if (!_.get(data, 'teamById')) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Team not found
                  </span>
                );
              }
              const creditBalance = _.get(data, 'teamById.settings.billing.subscription.credit.remaining');
              return [
                <strong key="name">{data.teamById.name}</strong>,
                <span key="balance">
                  {creditBalance === undefined ? '?' : creditBalance.toLocaleString()} credit balance
                </span>,
              ];
            },
            ...lookup,
          },
          ...props,
        }),
      _booking_id: (props: any) =>
        this.IdLookupField({
          name: '_booking_id',
          label: 'Booking ID',
          _team_id: _.get(this.state, 'form._team_id'),
          lookup: {
            query: gql`
              query credit_adjustment_lookup($id: ID) {
                bookingByID(id: $id) {
                  _id
                  name
                  type
                  _team_id
                  config {
                    credits_per_participant
                  }
                }
              }
            `,
            resultRender: (data: any) => {
              if (!_.get(data, 'bookingByID')) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Booking not found
                  </span>
                );
              }
              if (
                _.get(this.state, 'form._team_id') &&
                _.get(this.state, 'form._team_id') !== _.get(data, 'bookingByID._team_id')
              ) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Booking does not match Team ID
                  </span>
                );
              }
              let type = '?';
              switch (data.bookingByID.type) {
                case BOOKING_TYPE.FACE_TO_FACE:
                  type = 'Face to face';
                  break;
                case BOOKING_TYPE.REMOTE:
                  type = 'Remote';
                  break;
                case BOOKING_TYPE.ONLINE:
                  type = 'Online unmoderated';
                  break;
                case BOOKING_TYPE.UNMODERATED:
                  type = 'Unmoderated';
                  break;
                default:
              }
              return [
                <strong key="name">{data.bookingByID.name}</strong>,
                <span key="type">
                  {type} ({_.get(data, 'bookingByID.config.credits_per_participant', '?')} credits / participant)
                </span>,
              ];
            },
            onCompleted: ({ bookingByID }: any) => {
              if (_.get(bookingByID, '_team_id') && !this.state.form._team_id) {
                this.setFormState({ _team_id: bookingByID._team_id });
              }
              if (_.get(bookingByID, 'config.credits_per_participant')) {
                this.setFormState({ credits_per_participant: bookingByID.config.credits_per_participant });
              }
            },
          },
          ...props,
        }),
      _project_id: (props: any) =>
        this.IdLookupField({
          name: '_project_id',
          label: 'Project ID',
          _team_id: _.get(this.state, 'form._team_id'),
          lookup: {
            query: gql`
              query credit_adjustment_lookup($id: ID) {
                projectByID(id: $id) {
                  _id
                  name
                  type
                  _team_id
                }
              }
            `,
            resultRender: (data: any) => {
              if (!_.get(data, 'projectByID')) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Project not found
                  </span>
                );
              }
              if (
                _.get(this.state, 'form._team_id') &&
                _.get(this.state, 'form._team_id') !== _.get(data, 'projectByID._team_id')
              ) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Project does not match Team ID
                  </span>
                );
              }
              let type: string | null;
              switch (data.projectByID.type) {
                case PROJECT_TYPE.ASKABLE_PLUS:
                  type = 'Askable+';
                  break;
                default:
                  type = null;
              }
              return [
                <strong key="name">{data.projectByID.name}</strong>,
                type ? <span key="type">{type}</span> : null,
              ];
            },
            onCompleted: ({ projectByID }: any) => {
              if (_.get(projectByID, '_team_id') && !this.state.form._team_id) {
                this.setFormState({ _team_id: projectByID._team_id });
              }
            },
          },
          ...props,
        }),
      _booking_participant_id: (props: any) =>
        this.IdLookupField({
          name: '_booking_participant_id',
          label: 'Booking Participant ID',
          lookup: {
            query: gql`
              query credit_adjustment_lookup($id: ID!) {
                findParticipantSessionById(_id: $id) {
                  _id
                  _booking_id
                  status
                  cancel
                  booking {
                    _id
                    config {
                      timezone
                    }
                  }
                  user {
                    _id
                    meta {
                      identity {
                        firstname
                        lastname
                      }
                    }
                  }
                  session {
                    start
                  }
                }
              }
            `,
            resultRender: (data: any) => {
              if (!_.get(data, 'findParticipantSessionById')) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Not found
                  </span>
                );
              }
              if (
                _.get(this.state, 'form._booking_id') &&
                _.get(this.state, 'form._booking_id') !== _.get(data, 'findParticipantSessionById._booking_id')
              ) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Participant does not match Booking ID
                  </span>
                );
              }
              let status = '?';
              switch (data.findParticipantSessionById.status) {
                case BOOKING_PARTICIPANT_STATUS.CONFIRMED:
                  status = 'Confirmed';
                  break;
                case BOOKING_PARTICIPANT_STATUS.WAITLISTED:
                  status = 'Waitlist';
                  break;
                case BOOKING_PARTICIPANT_STATUS.AVAILABLE:
                  status = 'Available';
                  break;
                case BOOKING_PARTICIPANT_STATUS.INVITED:
                  status = 'Invited';
                  break;
                case BOOKING_PARTICIPANT_STATUS.IN_PROGRESS:
                  status = 'In progress';
                  break;
                default:
              }
              let cancel = null;
              switch (data.findParticipantSessionById.cancel) {
                case 0:
                  break;
                case BOOKING_PARTICIPANT_CANCEL.BY_ADMIN:
                  cancel = 'Cancelled by Admin';
                  break;
                case BOOKING_PARTICIPANT_CANCEL.BY_CLIENT:
                  cancel = 'Cancelled by Client';
                  break;
                case BOOKING_PARTICIPANT_CANCEL.BY_PARTICIPANT:
                  cancel = 'Cancelled by Participant';
                  break;
                case BOOKING_PARTICIPANT_CANCEL.NOSHOW:
                  cancel = 'No-show';
                  break;
                case BOOKING_PARTICIPANT_CANCEL.BY_SYSTEM:
                  cancel = 'Cancelled by System';
                  break;
                case BOOKING_PARTICIPANT_CANCEL.RESCHEDULED:
                  cancel = 'Rescheduled';
                  break;
                default:
              }

              return [
                <strong key="name">
                  {_.get(data, 'findParticipantSessionById.user.meta.identity.firstname', '')}{' '}
                  {_.get(data, 'findParticipantSessionById.user.meta.identity.lastname', '')}
                  &nbsp;-&nbsp;
                  {cancel ? <span className="text-strikethough">{status}</span> : status}
                  {cancel && ` (${cancel})`}
                </strong>,
                <span key="session">
                  {_.get(data, 'findParticipantSessionById.session.start')
                    ? moment
                        .tz(
                          data.findParticipantSessionById.session.start,
                          _.get(data, 'findParticipantSessionById.booking.config.timezone') || 'Australia/Brisbane',
                        )
                        .format('ddd MMM D, h:mma z')
                    : '?'}
                </span>,
              ];
            },
            onCompleted: ({ findParticipantSessionById }: any) => {
              if (_.get(findParticipantSessionById, '_booking_id') && !this.state.form._booking_id) {
                this.setFormState({ _booking_id: findParticipantSessionById._booking_id });
              }
            },
          },
          ...props,
        }),
      _from_team_id: (props: any) =>
        this.IdLookupField({
          name: '_from_team_id',
          label: 'From Team ID',
          lookup: {
            query: gql`
              query credit_adjustment_lookup($id: ID) {
                teamById(_id: $id) {
                  _id
                  name
                  settings {
                    billing {
                      subscription {
                        credit {
                          remaining
                        }
                      }
                    }
                  }
                }
              }
            `,
            resultRender: (data: any) => {
              if (!_.get(data, 'teamById')) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Team not found
                  </span>
                );
              }
              const creditBalance = _.get(data, 'teamById.settings.billing.subscription.credit.remaining');
              return [
                <strong key="name">{data.teamById.name}</strong>,
                <span key="balance">
                  {creditBalance === undefined ? '?' : creditBalance.toLocaleString()} credit balance
                </span>,
              ];
            },
          },
          ...props,
        }),
      _to_team_id: (props: any) =>
        this.IdLookupField({
          name: '_to_team_id',
          label: 'To Team ID',
          lookup: {
            query: gql`
              query credit_adjustment_lookup($id: ID) {
                teamById(_id: $id) {
                  _id
                  name
                  settings {
                    billing {
                      subscription {
                        credit {
                          remaining
                        }
                      }
                    }
                  }
                }
              }
            `,
            resultRender: (data: any) => {
              if (!_.get(data, 'teamById')) {
                return (
                  <span className="text-warning">
                    <Icon icon="error" /> Team not found
                  </span>
                );
              }
              const creditBalance = _.get(data, 'teamById.settings.billing.subscription.credit.remaining');
              return [
                <strong key="name">{data.teamById.name}</strong>,
                <span key="balance">
                  {creditBalance === undefined ? '?' : creditBalance.toLocaleString()} credit balance
                </span>,
              ];
            },
          },
          ...props,
        }),
      _transaction_id: ({ label = 'Transaction', required = true, ...selectProps }) => {
        let select = null;
        if (_.get(this.state, 'form._team_id.match') && this.state.form._team_id.match(/^[0-9a-fA-F]{24}$/)) {
          select = (
            <Query
              query={gql`
                query transactionsByTeam($team: ID!) {
                  transactionsByTeam(_team_id: $team) {
                    _id
                    created
                    type
                    currency
                    total_amount
                  }
                }
              `}
              variables={{ team: this.state.form._team_id }}
            >
              {({ data }: any) => (
                <Select
                  id="field_transaction_id"
                  className="input-width-30"
                  placeholder="- Select transaction -"
                  options={_.chain(data)
                    .get('transactionsByTeam', [])
                    // @ts-expect-error ts-migrate(2554) FIXME
                    .map((transaction: any) => {
                      let type = null;
                      switch (transaction.type) {
                        case TRANSACTIONS_TYPE.MANUAL:
                          type = 'Custom Transaction';
                          break;
                        case TRANSACTIONS_TYPE.CREDIT_PURCHASE:
                          type = 'Credit purchase';
                          break;
                        case TRANSACTIONS_TYPE.CREDIT_REFUND:
                          type = 'Credit refund';
                          break;
                        default:
                      }

                      if (!type) return null;

                      return {
                        _sort: transaction.created,
                        key: transaction._id,
                        value: transaction._id,
                        label: `${type} - ${moment(transaction.created).format('D MMM YYYY')} - ${transaction.total_amount.toLocaleString(
                          undefined,
                          {
                            style: 'currency',
                            currency: transaction.currency,
                          },
                        )}`,
                      };
                    })
                    .filter()
                    .orderBy('_sort', 'desc')
                    .value()}
                  selectProps={{ ...selectProps, required }}
                  onChange={(value: any) => {
                    this.setFormState({ _transaction_id: value });
                  }}
                />
              )}
            </Query>
          );
        } else {
          select = (
            <Select
              id="field_transaction_id"
              className="input-width-30"
              placeholder="Enter a Team ID to list Transactions"
              options={[]}
              selectProps={{ ...selectProps, required }}
            />
          );
        }
        return (
          <FormGroup
            key="field_transaction_id"
            labelFor="field_transaction_id"
            label={
              required ? (
                label
              ) : (
                <span>
                  {label} <span className={Classes.TEXT_MUTED}>(optional)</span>
                </span>
              )
            }
          >
            {select}
          </FormGroup>
        );
      },
      amount: ({ withType }: any) => (
        <FormGroup labelFor="field_amount" label="Amount">
          <div className="flex flex-row flex-align-center">
            {withType && (
              <Select
                id="field_accounting_type"
                className="margin-right-1"
                placeholder="- Select -"
                value={this.state.form.accounting_type}
                selectProps={{
                  required: true,
                }}
                options={[
                  { value: ACCOUNTING_TYPE.DEBIT, label: 'Deduct' },
                  { value: ACCOUNTING_TYPE.CREDIT, label: 'Add' },
                ]}
                onChange={(value: any) => {
                  this.setFormState({ accounting_type: parseInt(value, 10) });
                }}
              />
            )}
            <NumericInput
              id="field_amount"
              stepSize={10}
              minorStepSize={1}
              majorStepSize={100}
              min={0}
              value={_.get(this.state, 'form.amount.toString') ? this.state.form.amount.toString() : ''}
              onValueChange={value => {
                // console.log(event);
                // const { value } = event.target;
                this.setFormState({ amount: value });
              }}
              buttonPosition="none"
              className="input-width-8"
              required
            />
            <span className="margin-left-1">credits</span>
          </div>
        </FormGroup>
      ),
      comment: ({ required = true, label = 'Comment' }) => (
        <FormGroup
          labelFor="field_comment"
          label={
            required ? (
              label
            ) : (
              <span>
                {label} <span className={Classes.TEXT_MUTED}>(optional)</span>
              </span>
            )
          }
        >
          <TextArea
            id="field_comment"
            className="input-width-30"
            value={this.state.form.comment || ''}
            rows={4}
            required={required}
            onChange={event => {
              const { value } = event.target;
              this.setFormState({ comment: value });
            }}
          />
        </FormGroup>
      ),
    };
  };

  renderFormPage() {
    switch (this.state.type) {
      case 'billing':
        return [
          <FormGroup key="field_billing_subtype" labelFor="field_billing_subtype" label="Subtype">
            <Select
              id="field_billing_subtype"
              className="input-width-30"
              value={this.state.form.billing_subtype || Object.keys(this.billing_subtype)[0]}
              options={_.chain(this.billing_subtype)
                .toPairs()
                .map(([value, option]) => ({
                  value,
                  label: option.label,
                }))
                .value()}
              onChange={(value: any) => {
                this.setFormState({
                  billing_subtype: value,
                  accounting_type: _.get(this.billing_subtype, `${value}.accounting_type`),
                });
              }}
              triggerChangeOnMount
            />
          </FormGroup>,
          <this.formGroups._team_id key="_team_id" />,
          <this.formGroups._booking_id key="_booking_id" required={false} />,
          <this.formGroups._transaction_id key="_transaction_id" required={false} />,
          <this.formGroups.amount key="amount" withType />,
          <this.formGroups.comment key="comment" />,
        ];
      case 'transfer':
        return [
          <this.formGroups._from_team_id key="_from_team_id" />,
          <this.formGroups.amount key="amount" />,
          <this.formGroups._to_team_id key="_to_team_id" />,
          <this.formGroups.comment key="comment" required={false} />,
        ];
      case 'expriy':
        return [
          <this.formGroups._team_id
            key="_team_id"
            lookup={{
              onCompleted: ({ teamById }: any) => {
                this.setFormState({ amount: _.get(teamById, 'settings.billing.subscription.credit.remaining') });
              },
            }}
          />,
          <this.formGroups.amount key="amount" />,
          <this.formGroups.comment key="comment" required={false} />,
        ];
      case 'promo':
        return [
          <this.formGroups._team_id key="_team_id" />,
          <this.formGroups.amount key="amount" />,
          <this.formGroups.comment key="comment" />,
        ];
      case 'booking': {
        return [
          <FormGroup key="field_booking_subtype" labelFor="field_booking_subtype" label="Subtype">
            <Select
              id="field_booking_subtype"
              className="input-width-30"
              value={this.state.form.booking_subtype || this.booking_subtype[0].value}
              options={this.booking_subtype}
              onChange={(value: any) => {
                this.setFormState({ booking_subtype: parseInt(value, 10) });
              }}
              triggerChangeOnMount
            />
          </FormGroup>,
          <this.formGroups._team_id key="_team_id" />,
          <this.formGroups._booking_id key="_booking_id" />,
          <this.formGroups._booking_participant_id key="_booking_participant_id" required={false} />,
          this.state.form.booking_subtype === CREDIT_ACTIVITY_TYPE.BOOKING_REQUIREMENT_ADJUSTMENT ? (
            <FormGroup
              labelFor="field_credits_per_participant"
              label="Credits per Participant"
              key="field_credits_per_participant"
            >
              <NumericInput
                id="field_credits_per_participant"
                stepSize={1}
                minorStepSize={1}
                majorStepSize={10}
                min={0}
                value={
                  _.get(this.state, 'form.credits_per_participant.toString')
                    ? this.state.form.credits_per_participant.toString()
                    : ''
                }
                onValueChange={value => {
                  // console.log(event);
                  // const { value } = event.target;
                  this.setFormState({ credits_per_participant: value });
                }}
                buttonPosition="none"
                className="input-width-6"
                required
              />
            </FormGroup>
          ) : null,
          <this.formGroups.amount key="amount" withType={_.get(this.state, 'form.amount') !== 0} />,
          <this.formGroups.comment key="comment" required={_.get(this.state, 'form.amount') !== 0} />,
        ];
      }
      case 'refund':
        return [
          <FormGroup key="field_refund_subtype" labelFor="field_refund_subtype" label="Refund Type">
            <Select
              id="field_refund_subtype"
              className="input-width-30"
              value={this.state.form.refund_subtype || this.refund_subtype[0].value}
              options={this.refund_subtype}
              onChange={(value: any) => {
                this.setFormState({ refund_subtype: parseInt(value, 10) });
              }}
              triggerChangeOnMount
            />
          </FormGroup>,
          <this.formGroups._team_id key="_team_id" />,
          <this.formGroups._booking_id key="_booking_id" />,
          <this.formGroups._booking_participant_id
            key="_booking_participant_id"
            required={this.state.form.refund_subtype === CREDIT_ACTIVITY_TYPE.REFUND_BAD_PARTICIPANT}
          />,
          <this.formGroups.amount key="amount" />,
          <this.formGroups.comment key="comment" />,
        ];
      case 'non_booking_usage':
        return [
          <this.formGroups._team_id key="_team_id" />,
          <this.formGroups._project_id key="_project_id" required={false} />,
          <this.formGroups.amount key="amount" />,
          <this.formGroups._transaction_id key="_transaction_id" required={false} />,
          <this.formGroups.comment key="comment" />,
        ];
      case 'custom':
        return [
          <this.formGroups._team_id key="_team_id" />,
          <this.formGroups._booking_id key="_booking_id" required={false} />,
          <this.formGroups._booking_participant_id key="_booking_participant_id" required={false} />,
          <this.formGroups._project_id key="_project_id" required={false} />,
          <this.formGroups._transaction_id key="_transaction_id" required={false} />,
          <this.formGroups.amount key="amount" withType />,
          <this.formGroups.comment key="comment" />,
        ];
      default:
        return null;
    }
  }
  renderCalledResult() {
    return <h2>Done</h2>;
    // return (
    //     <JsonView
    //         src={data}
    //     />
    // );
  }

  render() {
    const updateData = this.parseForm();
    const debug = false;

    return (
      <div className="flex">
        <div className="content-page width-auto margin-left-0">
          <H2 className="margin-bottom-2">Credit adjusment</H2>
          <div>
            <Mutation
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'mutation' does not exist on type 'false ... Remove this comment to see the full error message
              mutation={this.getMutationDoc(updateData.mutation)}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'variables' does not exist on type 'false... Remove this comment to see the full error message
              variables={updateData.variables}
            >
              {(submitMutation: any, { called, loading, error }: any) => {
                if (loading) {
                  return <NonIdealState icon={<Spinner />} />;
                }
                if (error) {
                  return <ErrorCallout error={error} />;
                }
                if (called) {
                  return this.renderCalledResult();
                }

                const helperText = (() => {
                  const { type } = this?.state ?? {};
                  if (type === 'billing') {
                    return 'Credit activity will be coded as a ”Credit purchase” even if credits are being deducted so that the activity won’t count as usage';
                  }
                })();

                return (
                  <Fragment>
                    <FormGroup
                      labelFor="field_adjustment_type"
                      label="Adjustment type"
                      className="margin-bottom-4"
                      helperText={helperText ? <p style={{ maxWidth: '22rem' }}>{helperText}</p> : null}
                    >
                      <Select
                        id="field_adjustment_type"
                        className="input-width-30"
                        placeholder="- Select a type -"
                        value={this.state.type}
                        options={[
                          { value: 'billing', label: 'Billing' },
                          { value: 'transfer', label: 'Transfer' },
                          { value: 'expriy', label: 'Expiry' },
                          { value: 'promo', label: 'Free / promo' },
                          { value: 'booking', label: 'Booking' },
                          { value: 'refund', label: 'Credit refund' },
                          { value: 'non_booking_usage', label: 'Non-booking usage' },
                          { value: 'custom', label: 'Custom' },
                        ]}
                        onChange={(value: any) => {
                          this.setState((state: any) => {
                            state.type = value;
                            _.unset(state.form, 'accounting_type');
                            _.unset(state.form, '_from_team_id');
                            // TODO: figure out which fields to reset
                            if (value === 'expriy' || value === 'promo') {
                              _.unset(state.form, 'amount');
                            }

                            return state;
                          });
                        }}
                      />
                    </FormGroup>
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        submitMutation();
                      }}
                      autoComplete="off"
                    >
                      {this.renderFormPage()}
                      {this.state.type && (
                        <Button type="submit" intent="primary" className="margin-top-2">
                          Submit
                        </Button>
                      )}
                    </form>
                  </Fragment>
                );
              }}
            </Mutation>
          </div>
        </div>
        {debug && (
          <div className="width-auto flex-grow-1 margin-1">
            <JsonView
              src={{
                update: updateData,
                state: this.state,
                lookupData: this.lookupData,
              }}
              className="margin-top-6"
            />
          </div>
        )}
        <PageTitle title="Credit Adjustment Tool" />
      </div>
    );
  }
}

export default CreditAdjustment;
