import { Button, Collapse } from '@blueprintjs/core';
import classNames from 'classnames';
import { useState } from 'react';

import type { FunctionalComponentWithChildren } from 'types/types';

type Props = {
  buttonLabel: string;
  className?: string;
};

export const Accordion: FunctionalComponentWithChildren<Props> = ({ children, buttonLabel, className }) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(a => !a);

  return (
    <div className={classNames('tw-w-full tw-border-b', className)}>
      <Button
        onClick={toggle}
        fill
        className="tw-flex tw-justify-between tw-rounded-none tw-py-2"
        minimal
        rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}
      >
        {buttonLabel}
      </Button>
      <Collapse isOpen={isOpen} keepChildrenMounted>
        <div className="tw-px-4 tw-py-2">{children}</div>
      </Collapse>
      z
    </div>
  );
};
