import gql from 'graphql-tag';

export const userStatusCountFieldsFragment = gql`
  fragment userStatusCountFields on UserStatusCount {
    completed
    confirmed
    scheduled
    waitlisted
    invited
    in_progress
    cancel_admin
    cancel_client
    cancel_participant
    cancel_noshow
    cancel_system
    cancel_reschedule
    available_eligible
    available_ineligible
    unavailable_eligible
    unavailable_ineligible
  }
`;
