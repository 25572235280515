import gql from 'graphql-tag';

export const participantFieldsFragment = gql`
  fragment participantFields on User {
    _id
    email
    status
    blacklist
    created
    updated
    contact {
      phone {
        mobile
        verification {
          mobile
        }
      }
    }
    location {
      city
      state
      postal_code
      country
      longitude
      latitude
      timezone
    }
    meta {
      identity {
        firstname
        lastname
        gender
        birthday {
          timestamp
        }
        languages {
          english {
            speak {
              native
              fluent
              conversational
              beginner
            }
          }
        }
      }
      family {
        status {
          divorced
          married
          relationship
          single
          widowed
        }
      }
      education {
        apprenticeship
        highschool
        postgraduate
        some_university
        undergraduate
      }
      work {
        _industry_id
        _subindustry_id
        employment_type
        status {
          fulltime
          fulltime_student
          homeduties
          parttime
          parttime_student
          retired
          unemployed
        }
        business {
          size
          turnover
        }
      }
      social {
        linkedin {
          profile_url
        }
      }
      feedback_sample {
        recording_url
      }
    }
    settings {
      billing {
        preferred
        paypal
        paypal_details {
          verification_details {
            verified_account
          }
        }
        giftpay
      }
      remote {
        setup_check
        network_quality
      }
    }
    LastLogin
    ParticipantIncentives {
      total_amount
    }
    rating {
      ratings
      score
    }
    devices {
      id
      os
      app_version
      last_login
      token
    }
  }
`;
