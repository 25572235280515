import gql from 'graphql-tag';

export default gql`
  mutation updateBooking($booking_id: ID!, $booking: BookingInput!) {
    updateBooking(booking_id: $booking_id, booking: $booking) {
      _id
      name
      status
      config {
        online_task {
          type
        }
      }
    }
  }
`;
