import { Classes } from '@blueprintjs/core';

import { Emoji, SkeletonWrapper } from 'components/common';
import { BOOKING_STATUS } from 'lib/constants';
import { utils } from 'lib/utils';
import { formatIncentive } from 'utils/currency-utils';

import type { BookingProp } from '../types';

type Props = {
  booking?: BookingProp | null;
  loading: boolean;
};

export const InfoIncentives = ({ booking, loading }: Props) => {
  if (booking?.status === BOOKING_STATUS.IN_REVIEW) {
    return null;
  }
  return (
    <ul className="tw-list-disc section section-overview tw-flex tw-flex-col tw-gap-1">
      {booking?.config?.incentives?.map((incentive, index) => (
        <li key={index} className="flex tw-flex-row tw-w-full">
          <div className="flex tw-flex-row tw-gap-2">
            <SkeletonWrapper active={loading}>
              <Emoji
                emoji={utils.countryCodeToFlag(incentive?.country_code || 'AU')}
                className={`flex tw-items-center margin-left-05 v-align-middle ${Classes.TEXT_LARGE}`}
              />
            </SkeletonWrapper>
            <div className={`flex tw-items-center label ${Classes.TEXT_MUTED}`}>
              <SkeletonWrapper active={loading} length={2}>
                {incentive?.country_code}
              </SkeletonWrapper>
            </div>
          </div>
          <div className="flex tw-flex-row tw-gap-2 tw-bg-slate-800 tw-px-2 tw-rounded-md tw-ml-auto">
            <div className={`label ${Classes.TEXT_MUTED} flex tw-items-center`}>
              <SkeletonWrapper active={loading} length={2}>
                {incentive ? formatIncentive(incentive) : ''}
              </SkeletonWrapper>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
