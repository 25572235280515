import { Tag } from '@blueprintjs/core';
import classnames from 'classnames';

import { ProjectStatus } from 'generated/graphql';
import { normalizeText } from 'utils/string-utils';

import type { ProjectProgressStatus } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  projectStatus: number;
  projectProgressStatus: ProjectProgressStatus;
};

const ProjectStatusMap: { [key: number]: ProjectStatus } = {
  1: ProjectStatus.Active,
  0: ProjectStatus.Draft,
  5: ProjectStatus.Completed,
  7: ProjectStatus.Archived,
};

export const ProjectStatusBadge: FC<Props> = ({ projectProgressStatus, projectStatus }) => {
  const TEXT_MAP: { [key in ProjectProgressStatus]: string } = {
    delivery: 'Delivery',
    in_review: 'In review',
    kick_off: 'Kick off',
    research: 'Research',
  };

  return (
    <Tag
      round
      className={classnames('tw-uppercase tw-text-black', { '!tw-bg-green-600 !tw-text-white': projectStatus === 1 })}
    >
      {projectStatus === 1 ? TEXT_MAP[projectProgressStatus] : normalizeText(ProjectStatusMap[projectStatus])}
    </Tag>
  );
};
