import { Mutation } from '@apollo/client/react/components';
import { AnchorButton, Button } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';

import { BookingPageBookingFields } from '@graphql/fragments/booking/bookingPageBooking';
import { useAppContext } from 'components/common/appProvider';
import ForceCompleteBookingSidebar from 'containers/Booking/booking-page/booking-page.force-complete-booking-sidebar.view';
import MoveBookingSidebar from 'containers/Booking/booking-page/booking-page.move-booking-sidebar.view';
import ReopenBookingSidebar from 'containers/Booking/booking-page/booking-page.reopen-booking-sidebar.view';
import { useParticipantSubmissionsExportCsvMutation } from 'generated/graphql';
import { downloadTextFile } from 'utils/string-utils';

import { BookingUpdateContainer } from './BookingUpdateContainer';
import { BookingUpdateIncentivesContainer } from './BookingUpdateIncentivesContainer';

import type { Booking } from 'generated/graphql';

type ActionsView = {
  _booking_id: string;
  booking: Booking;
  loading: boolean;
  refetchBooking?: () => void;
};

// eslint-disable-next-line react/display-name
export default ({ booking, _booking_id, loading, refetchBooking }: ActionsView) => {
  const context = useAppContext();

  const [, participantSubmissionsExportCSV] = useParticipantSubmissionsExportCsvMutation();

  return (
    <div className="section">
      <Mutation
        mutation={gql`
          mutation admin_bookingPageSetVisibility($booking: ID!, $hidden: Boolean) {
            setBookingVisibility(booking_id: $booking, hidden: $hidden) {
              ...BookingPageBookingFields
            }
          }
          ${BookingPageBookingFields}
        `}
      >
        {(setVisibility: any, setVisibilityProps: any) => (
          <div>
            <Button
              minimal
              intent="primary"
              disabled={loading || !!_.get(setVisibilityProps, 'loading')}
              onClick={() => {
                return setVisibility({ variables: { booking: _booking_id, hidden: !_.get(booking, 'config.hidden') } });
              }}
            >
              {_.get(booking, 'config.hidden') ? 'Show' : 'Hide'} booking
            </Button>
          </div>
        )}
      </Mutation>
      <div>
        <AnchorButton minimal intent="primary" disabled={loading || true} title="Coming soon">
          Cancel booking
        </AnchorButton>
      </div>
      <div>
        <Button
          minimal
          intent="primary"
          disabled={loading || _.get(booking, 'status') !== 1}
          onClick={() => {
            context.openSidebar({
              children: <ForceCompleteBookingSidebar _id={booking._id} onClose={() => context.closeSidebar()} />,
            });
          }}
        >
          Force complete
        </Button>
      </div>
      <div>
        <Button
          minimal
          intent="primary"
          disabled={
            loading ||
            _.get(booking, 'status') !== 5 ||
            moment().diff(_.get(booking, 'history.completed_date'), 'days') > 21
          }
          onClick={() => {
            context.openSidebar({
              children: (
                <ReopenBookingSidebar
                  _id={booking._id}
                  timezone={_.get(booking, 'config.timezone')}
                  onClose={() => context.closeSidebar()}
                />
              ),
            });
          }}
        >
          Reopen booking
        </Button>
      </div>
      <div>
        <Button
          minimal
          intent="primary"
          onClick={() => {
            context.openSidebar({
              children: (
                <MoveBookingSidebar
                  _id={booking._id}
                  timezone={_.get(booking, 'config.timezone')}
                  onClose={() => context.closeSidebar()}
                />
              ),
            });
          }}
        >
          Move booking
        </Button>
      </div>
      <div>
        <Button
          minimal
          intent="primary"
          onClick={async () => {
            const exportedSubmissions = await participantSubmissionsExportCSV({ _booking_id: booking._id });
            const date = new Date();
            const filename = `${booking.name?.replace(/ /g, '_')}_${booking._id}_${date.getFullYear()}_${String(date.getMonth() + 1).padStart(2, '0')}_${String(date.getDate()).padStart(2, '0')}.csv`;
            downloadTextFile(exportedSubmissions.data?.participantSubmissionsExportCSV as string, filename);
          }}
        >
          Export participants
        </Button>
      </div>
      <div>
        <Button
          minimal
          intent="primary"
          onClick={() => {
            context.openSidebar({
              children: (
                <BookingUpdateContainer
                  onClose={() => {
                    refetchBooking?.();
                    context.closeSidebar();
                  }}
                  bookingId={booking._id}
                  booking={booking}
                />
              ),
            });
          }}
        >
          Update booking
        </Button>
      </div>
      <div>
        <Button
          minimal
          intent="primary"
          disabled={loading || _.get(booking, 'status') !== 1}
          title={_.get(booking, 'status') !== 1 ? 'Booking must be in the "Completed" status' : ''}
          onClick={() => {
            context.openSidebar({
              children: (
                <BookingUpdateIncentivesContainer
                  booking={booking}
                  onClose={() => {
                    refetchBooking?.();
                    context.closeSidebar();
                  }}
                />
              ),
            });
          }}
        >
          Update incentives
        </Button>
      </div>
    </div>
  );
};
