import { Text } from '@blueprintjs/core';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { type ColumnWithLooseAccessor } from 'react-table';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';

import { ColumnFilter, SelectColumnFilter, VirtualisedTable } from 'components/table/VirtualisedTable';
import { formattedDate } from 'utils/date-utils';

import { TeamBookingsListQuery } from '../data/TeamBookingsList.query';

import type { Booking } from 'generated/graphql';

const BookingStatusMap = {
  0: 'Draft',
  1: 'Active',
  2: 'Pending Review',
  3: 'In Review',
  4: 'Rejected',
  5: 'Completed',
  6: 'Pending Payment',
  7: 'Archived',
} as const;

type BookingWithStatus = Omit<Booking, 'status'> & { status: (typeof BookingStatusMap)[keyof typeof BookingStatusMap] };

export const TeamsBookingsList = () => {
  const { id } = useParams<{ id: string }>();

  invariant(id, 'Team ID is required');

  const [{ data, fetching }] = useQuery({
    query: TeamBookingsListQuery,
    variables: {
      _id: id!,
    },
  });

  const memoedBookings = useMemo(
    () =>
      data?.bookingsByTeam?.map(booking => {
        return {
          ...booking,
          status: BookingStatusMap[Number(booking?.status) as keyof typeof BookingStatusMap],
        };
      }),
    [data],
  );

  const columns = useMemo<readonly ColumnWithLooseAccessor<BookingWithStatus>[]>(() => {
    return [
      {
        Header: 'Name',
        accessor: row => row.name,
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        Cell: ({ row }) => {
          const { original } = row;

          return <Link to={`/booking/${original._id}`}>{original.name}</Link>;
        },
      },
      {
        Header: 'Status',
        accessor: row => row.status,
        filter: 'equals',
        Filter: SelectColumnFilter,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;

          if (!original.status) return null;

          return <Text>{original.status}</Text>;
        },
      },
      {
        Header: 'ID',
        disableSortBy: true,
        accessor: row => row._id,
      },
      {
        Header: 'Created',
        accessor: row => row.created,
        Cell: ({ row }) => {
          return <Text>{formattedDate({ date: row.original.created, format: 'MMM D, YYYY' })}</Text>;
        },
      },
    ];
  }, [memoedBookings]);

  return (
    <VirtualisedTable
      isLoading={fetching}
      columns={columns}
      data={memoedBookings as any}
      initialSortBy={[
        { id: 'Status', desc: false },
        { id: 'Created', desc: true },
      ]}
    />
  );
};
