import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Component } from 'react';

import { AdminWigPeriodTypes } from 'generated/graphql';

import { currentWigFieldsFragment } from '../../graphql/fragments/wig/wigFields';

const CURRENT_WIG_QUERY = gql`
  query adminCurrentWigProgress($goal: String!, $periodType: AdminWigPeriodTypes, $office: OperationalOffices) {
    adminCurrentWigProgress(key: $goal, period_type: $periodType, operational_office: $office) {
      ...CurrentWigFields
    }
  }
  ${currentWigFieldsFragment}
`;

const CURRENT_WIG_SUBSCRIPTION = gql`
  subscription adminCurrentWigProgress($goal: String!, $periodType: AdminWigPeriodTypes, $office: OperationalOffices) {
    adminCurrentWigProgress(key: $goal, period_type: $periodType, operational_office: $office) {
      ...CurrentWigFields
    }
  }
  ${currentWigFieldsFragment}
`;

class WigBar extends Component {
  freshTimeout: any;

  refetch: any;

  unsubscribe: any;

  constructor(props: any) {
    super(props);
    this.state = { data: null, updated: 0 };

    this.keepFresh = this.keepFresh.bind(this);
  }

  componentDidMount() {
    this.keepFresh();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    if (this.freshTimeout) {
      clearTimeout(this.freshTimeout);
    }
  }

  async keepFresh() {
    const testInterval = 60000 * 2.5;
    const maxAge = 60000 * 12;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updated' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.refetch && Date.now() > this.state.updated + maxAge) {
      await this.refetch();
      this.setState({ updated: Date.now() });
    }

    this.freshTimeout = setTimeout(this.keepFresh, testInterval);
  }

  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'direction' does not exist on type 'Reado... Remove this comment to see the full error message
      direction = 'horizontal',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className = '',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'goal' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      goal,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'periodType' does not exist on type 'Read... Remove this comment to see the full error message
      periodType = AdminWigPeriodTypes.Month,
      // ...props
    } = this.props;

    const params = new URL(window.location.href).searchParams;
    const office = params.get('operational_office') || 'AU';

    const formatWigNumber = (input: any, { prefix }: any) => {
      let number = parseFloat(input);
      if (Number.isNaN(number)) return null;

      if (input >= 1000000) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        number = `${Math.round(number / 100000) / 10}M`;
      } else if (input >= 10000) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        number = `${Math.round(number / 1000)}K`;
      } else if (input >= 1000) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        number = `${Math.round(number / 100) / 10}K`;
      }
      let localPrefix = prefix || '';
      if (prefix === '$') {
        if (office === 'UK') {
          localPrefix = '£';
        }
      }

      return `${localPrefix}${number.toString(10)}`;
    };

    return (
      <Query
        key={goal}
        query={CURRENT_WIG_QUERY}
        variables={{ goal, periodType, office }}
        fetchPolicy="no-cache"
        onCompleted={(data: any) => {
          if (
            _.get(data, 'adminCurrentWigProgress.goals.0.progress') >=
              _.get(data, 'adminCurrentWigProgress.goals.0.value') &&
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
            this.props.onTargetComplete
          ) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error messa
            this.props.onTargetComplete();
          }
          this.setState({ data, updated: Date.now() });
        }}
      >
        {({ loading, error, subscribeToMore, refetch, ...queryResult }: any) => {
          if (error) {
            console.error(error);
            return null;
          }

          // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          const data = this.state.data || queryResult.data;

          if (refetch && !this.refetch) {
            this.refetch = refetch;
          }

          if (!this.unsubscribe) {
            this.unsubscribe = subscribeToMore({
              document: CURRENT_WIG_SUBSCRIPTION,
              variables: { goal },
              fetchPolicy: 'no-cache',
              updateQuery: (prev: any, { subscriptionData }: any) => {
                const next = _.get(subscriptionData, 'data', {});
                this.setState({ data: next, updated: Date.now() });
                return next;
              },
            });
          }

          const barStyle = {};
          const progresBarStyle = {};
          if (
            _.get(data, 'adminCurrentWigProgress.goals.0.progress') &&
            _.get(data, 'adminCurrentWigProgress.goals.0.value')
          ) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            progresBarStyle[direction === 'vertical' ? 'height' : 'width'] = `${(
              (_.get(data, 'adminCurrentWigProgress.goals.0.progress') /
                _.get(data, 'adminCurrentWigProgress.goals.0.value')) *
              100
            ).toFixed(2)}%`;
          }
          if (_.get(data, 'adminCurrentWigProgress.goals.0.meta.bar_colour')) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'color' does not exist on type '{}'.
            barStyle.color = data.adminCurrentWigProgress.goals[0].meta.bar_colour;
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundColor' does not exist on type ... Remove this comment to see the full error message
            progresBarStyle.backgroundColor = data.adminCurrentWigProgress.goals[0].meta.bar_colour;
          }

          const ghostStyle = { display: 'none' };
          if (
            _.get(data, 'adminCurrentWigProgress.goals.0.ghost') &&
            _.get(data, 'adminCurrentWigProgress.goals.0.value')
          ) {
            // @ts-expect-error ts-migrate(2790) FIXME: The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
            delete ghostStyle.display;
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ghostStyle[direction === 'vertical' ? 'bottom' : 'left'] = `${(
              (_.get(data, 'adminCurrentWigProgress.goals.0.ghost') /
                _.get(data, 'adminCurrentWigProgress.goals.0.value')) *
              100
            ).toFixed(2)}%`;
          }

          return (
            <div
              className={`wig-bar wig-bar-${direction} ${loading ? 'loading' : ''} ${
                _.get(data, 'adminCurrentWigProgress.goals.0.progress') >=
                _.get(data, 'adminCurrentWigProgress.goals.0.value')
                  ? 'completed'
                  : ''
              } ${className}`}
            >
              <div className="bar" style={barStyle}>
                <div className="progress" style={progresBarStyle} />
                <div className="ghost" style={ghostStyle}>
                  {_.get(data, 'adminCurrentWigProgress.goals.0.progress') >
                  _.get(data, 'adminCurrentWigProgress.goals.0.ghost') ? (
                    <span className="emoji killed" role="img" aria-label="Killed">
                      ☠️
                    </span>
                  ) : (
                    <span className="emoji alive" role="img" aria-label="killed">
                      👻
                    </span>
                  )}
                </div>
              </div>
              <div className="text">
                <span className="emoji">{_.get(data, 'adminCurrentWigProgress.goals.0.meta.emoji') || '\u00A0'}</span>
                <span className="progress">
                  {formatWigNumber(
                    _.get(data, 'adminCurrentWigProgress.goals.0.progress'),
                    _.chain(data)
                      .get('adminCurrentWigProgress.goals.0.meta')
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'pick' does not exist on type 'FunctionCh... Remove this comment to see the full error message
                      .pick(['prefix'])
                      .value(),
                  ) || '\u00A0'}
                </span>
                <span className="goal">
                  {formatWigNumber(
                    _.get(data, 'adminCurrentWigProgress.goals.0.value'),
                    _.chain(data)
                      .get('adminCurrentWigProgress.goals.0.meta')
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'pick' does not exist on type 'FunctionCh... Remove this comment to see the full error message
                      .pick(['prefix'])
                      .value(),
                  ) || '\u00A0'}
                </span>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default WigBar;
