import { Button } from '@blueprintjs/core';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';

import { LoginAsClientMutation } from '../data/LoginAsClient.mutation';

type LoginAsClientProps = {
  id: string;
};

export const LoginAsClient = ({ id }: LoginAsClientProps) => {
  const [{ fetching }, loginAsClient] = useMutation(LoginAsClientMutation);

  const handleLoginAsClient = async () => {
    const result = await loginAsClient({ id });

    if (result.error || !result.data?.adminLoginAsClient?.url) {
      toast.error('Failed to login as client');
      return;
    }

    window.open(result.data?.adminLoginAsClient?.url, '_blank');
  };

  return (
    <Button loading={fetching} onClick={handleLoginAsClient}>
      Login as client
    </Button>
  );
};
