import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Spinner } from 'components/common';
import { useGetUserByIdQuery } from 'generated/graphql';

import type { FC } from 'react';

export const UserFallback: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [{ data }] = useGetUserByIdQuery({
    variables: { id: params.id! },
  });

  useEffect(() => {
    if (!data?.userByID) {
      return;
    }

    if (data.userByID.type?.researcher) {
      navigate(`/user/researcher/${params.id}`);
      return;
    }

    if (data.userByID.type?.client) {
      navigate(`/user/client/${params.id}`);
      return;
    }

    if (data.userByID.type?.participant) {
      navigate(`/user/participant/${params.id}`);
      return;
    }
  }, [data]);

  return (
    <div className="tw-flex tw-justify-center tw-w-full tw-h-full">
      <Spinner />
    </div>
  );
};
