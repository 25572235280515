import gql from 'graphql-tag';

export default gql`
  mutation updateSession($booking_id: ID!, $session_id: ID!, $session: BookingInputSession!) {
    updateSession(booking_id: $booking_id, session_id: $session_id, session: $session) {
      _id
      name
      status
      config {
        online_task {
          type
        }
      }
      session {
        _id
        start
        end
        slot_min
        slot_max
        status
      }
    }
  }
`;
