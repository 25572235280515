import { H4, Icon, InputGroup } from '@blueprintjs/core';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { useBlockLayout, useFilters, useSortBy, useTable } from 'react-table';
import Autosizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { Select, SkeletonWrapper } from 'components/common';

import type { ChangeEvent, FC } from 'react';
import type {
  Column,
  ColumnWithLooseAccessor,
  FilterProps,
  FilterTypes,
  Renderer,
  SortingRule,
  TableState,
} from 'react-table';

const EmptyData: FC = () => {
  return (
    <div className="tw-py-6 tw-flex tw-h-full tw-justify-center tw-items-center">
      <H4>No data</H4>
    </div>
  );
};

export const SelectColumnFilter: Renderer<FilterProps<any>> = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  className,
}) => {
  const options = useMemo(() => {
    const optionsSet = new Set();
    optionsSet.add('All');

    preFilteredRows.forEach(row => {
      const v = row.values[id];

      if (!v) {
        return;
      }

      optionsSet.add(v);
    });

    return [...optionsSet.values()];
  }, [id, preFilteredRows]);

  const handleSelect = (v: string | null) => {
    if (v === 'All') {
      setFilter(null);
      return;
    }

    setFilter(v);
  };

  // Render a multi-select box
  // @ts-expect-error FIX OPTIONS
  return <Select className={classNames(className)} options={options} value={filterValue} onChange={handleSelect} />;
};

export const ColumnFilter: Renderer<FilterProps<any>> = ({
  column: { filterValue, setFilter, Header, id },
  className,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value || undefined);
  };

  const classes = classNames(className);

  return (
    <InputGroup
      className={classes}
      value={filterValue || ''}
      onChange={handleChange}
      placeholder={`Search by ${id ?? Header}...`}
    />
  );
};

/**
 * Empty row
 */
export const EmptyRowItem: FC = () => {
  return <span className="tw-opacity-60">None</span>;
};

type VirtualisedTableProps<T extends object = any, Q extends object = any> = {
  data: readonly T[];
  columns: readonly ColumnWithLooseAccessor<Q>[];
  defaultColumn?: Partial<Column<any>>;
  initialSortBy: SortingRule<object>[];
  initialFilters?: any[];
  filterTypes?: FilterTypes<object>;
  tableHeader?: JSX.Element;
  tableFooter?: JSX.Element;
  isLoading?: boolean;
  manualFilters?: boolean;
  onChangeTableState?: (state: TableState) => void;
};

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const PLACEHOLDER_DATA = new Array(30).fill({ id: 'hello' });
export const VirtualisedTable = function <T>({
  data,
  columns,
  filterTypes,
  initialSortBy,
  initialFilters,
  tableHeader,
  tableFooter,
  defaultColumn,
  isLoading = false,
  manualFilters = false,
  onChangeTableState,
}: VirtualisedTableProps<T & object>) {
  const baseDefaultColumn = useMemo<Partial<Column<any>>>(
    () => ({
      width: '100%',
      canSort: true,
    }),
    [],
  );

  const tableInstance = useTable(
    {
      data: isLoading ? PLACEHOLDER_DATA : data,
      columns,
      defaultColumn: defaultColumn ?? baseDefaultColumn,
      filterTypes,
      autoResetSortBy: false,
      autoResetFilters: false,
      initialState: {
        sortBy: initialSortBy,
        filters: initialFilters || [],
      },
      manualFilters,
    },
    useBlockLayout,
    useFilters,
    useSortBy,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = tableInstance;

  useEffect(() => {
    if (onChangeTableState && state) {
      onChangeTableState(state);
    }
  }, [state]);

  const RenderRow = useCallback(
    ({ index, style }: { index: number; style: object }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div
          {...row.getRowProps({
            style: {
              ...style,
              backgroundColor: index % 2 === 0 ? '#32404C' : '',
            },
          })}
          className="tw-flex tw-h-full"
        >
          {row.cells.map(cell => {
            return (
              // Key gets supplied from getCellProps
              // eslint-disable-next-line react/jsx-key
              <div
                {...cell.getCellProps({
                  style: {
                    ...cell.getCellProps().style,
                    minWidth: cell.column.minWidth,
                    width: cell.column.width ?? '100%',
                    height: '100%',
                    display: 'flex',
                  },
                  className: 'tw-items-center tw-p-4 tw-truncate',
                })}
              >
                {isLoading ? <SkeletonWrapper active length={randomIntFromInterval(10, 30)} /> : cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows],
  );

  return (
    <div className="tw-flex tw-flex-col tw-flex-1 tw-h-full">
      {tableHeader && <div className="tw-px-4 tw-pt-3 tw-pb-1">{tableHeader}</div>}
      <div className="tw-border-b tw-border-neutral-600">
        {headerGroups.map(headerGroup => {
          return (
            // Key gets supplied from getHeaderProps
            // eslint-disable-next-line react/jsx-key
            <div {...headerGroup.getHeaderGroupProps()} className="tw-flex tw-h-full">
              {headerGroup.headers.map(column => {
                const toggleProps = column.getSortByToggleProps();
                return (
                  // Key gets supplied from getHeaderProps
                  // eslint-disable-next-line react/jsx-key
                  <div
                    {...column.getHeaderProps({ style: { width: column.width, minWidth: column.minWidth } })}
                    className="tw-p-4"
                  >
                    <>
                      <div className="tw-flex tw-select-none tw-items-center">
                        <div className="tw-mr-3">{column.render('Header')}</div>
                        {column.canSort && (
                          <Icon
                            size={12}
                            icon={column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : 'sort'}
                            {...toggleProps}
                          />
                        )}
                      </div>

                      {column.canFilter &&
                        column.Filter &&
                        column.render('Filter', { className: 'tw-mt-2 tw-max-w-[12rem]' })}
                    </>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="tw-flex tw-flex-grow">
        <Autosizer>
          {({ height, width }) => {
            const style = { width };

            return (
              <div {...getTableProps({ style })}>
                <div {...getTableBodyProps({ style })}>
                  {rows.length === 0 ? (
                    <EmptyData />
                  ) : (
                    <List height={height} itemCount={rows.length} itemSize={50} width={width}>
                      {RenderRow}
                    </List>
                  )}
                </div>
              </div>
            );
          }}
        </Autosizer>
      </div>

      {tableFooter && tableFooter}
    </div>
  );
};
