import { Mutation } from '@apollo/client/react/components';
import { Classes, Button, ButtonGroup, Popover } from '@blueprintjs/core';
import gql from 'graphql-tag';

import { Emoji, Icon } from 'components/common';

interface BookingTagPopoverProps {
  _booking_id: string;
  value?: string;
  onChange?: (value: string) => void;
}

interface BookingTagProps extends BookingTagPopoverProps {
  isMissionCritical?: boolean;
}

const updateTagMutation = gql`
  mutation admin_tagBooking($_booking_id: ID!, $emoji: String) {
    adminUpdateBooking(booking_id: $_booking_id, booking: { admin: { emoji: $emoji } }) {
      _id
      updated
      admin {
        emoji
      }
    }
  }
`;

function BookingTagPopover({ _booking_id, value = '', onChange }: BookingTagPopoverProps) {
  const bookingTags = [
    // '⭐️',
    '✅',
    '🥵',
    // '❗️',
    '🧿',
    '📵',
    '📹',
    '✝️',
    '💿',
    null,
  ];

  return (
    <Mutation mutation={updateTagMutation}>
      {(tagMutate: any) => (
        <div className="padding-05 padding-left-1 padding-right-1">
          <ButtonGroup minimal>
            {bookingTags.map(emoji => (
              <Button
                key={emoji}
                minimal
                small
                icon={!emoji && 'disable'}
                onClick={(e: any) => {
                  let emojiList = emoji || '';
                  if (emoji && e.shiftKey && value) {
                    if (value.match(emoji)) {
                      emojiList = value.replace(emoji, '');
                    } else {
                      emojiList = `${value}${emoji}`;
                    }
                  }
                  if (onChange) {
                    onChange(emojiList);
                  }

                  tagMutate({ variables: { _booking_id, emoji: emojiList } }).then(() => {
                    if (onChange) {
                      onChange(emojiList);
                    }
                  });
                }}
                className={Classes.POPOVER_DISMISS}
              >
                {emoji && <Emoji emoji={emoji} label="Tag booking" />}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      )}
    </Mutation>
  );
}

function BookingTag({ ...props }: BookingTagProps) {
  const emojiList = props.isMissionCritical && !props.value?.includes('🚨') ? `🚨${props.value ?? ''}` : props.value;

  return (
    <Popover content={<BookingTagPopover {...props} />}>
      <Button minimal small>
        {emojiList ? <Emoji emoji={emojiList} label="Tag booking" /> : <Icon icon="blank" />}
      </Button>
    </Popover>
  );
}

export default BookingTag;
