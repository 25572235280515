import { ApolloClient, InMemoryCache, split, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { datadogRum } from '@datadog/browser-rum';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createClient as createWsClient } from 'graphql-ws';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { createClient, Provider } from 'urql';

import { localStorage } from './lib/storage';
import { AppRoutes } from './routes';

import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.scss';

if (import.meta.env.VITE_DD_APPLICATION_ID && import.meta.env.VITE_DD_CLIENT_TOKEN && import.meta.env.VITE_DD_ENV) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    env: import.meta.env.VITE_DD_ENV,
    service: 'admin',
    site: 'us3.datadoghq.com',
    sessionSampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [{ match: /https:\/\/.*\.askable\.com/, propagatorTypes: ['tracecontext'] }],
  });
}

const google_client_id = '86041926868-hmmvpg68q5aihobfbk7b3d7grbhm408a.apps.googleusercontent.com';
const urqlClient = createClient({
  url: import.meta.env.VITE_GRAPHQL_URL,
  maskTypename: true,
  fetchOptions: () => {
    return {
      headers: {
        Authorization: localStorage.get('auth_token') || null,
        'x-graphql-client-name': 'admin',
        'x-graphql-client-version': 'N/A',
        Accept: 'application/json',
        ...(localStorage.get('connectedAsClient') ? { 'x-askable-admin': 'true' } : {}),
        'apollographql-client-name': 'Admin app',
      },
    };
  },
});

// Set it up the HTTP Link
const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL,
  credentials: 'same-origin',
});

const wsLink = new GraphQLWsLink(
  createWsClient({
    url: import.meta.env.VITE_GRAPHQL_URL_WS,
    connectionParams: {
      authToken: localStorage.get('auth_token') || null,
    },
  }),
);

const links = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
);

// Set it up the Local State Link
// Setup the caching system
const cache = new InMemoryCache({ dataIdFromObject: o => o._id as string });

// Setup middleware with context
const middlewareLink = setContext((request, previousContext) => {
  return {
    headers: {
      Authorization: localStorage.get('auth_token') || null,
      Accept: 'application/json',
      'x-graphql-client-name': 'admin',
      'x-graphql-client-version': 'N/A',
      ...(localStorage.get('connectedAsClient') ? { 'x-askable-admin': true } : {}),
      'apollographql-client-name': 'Admin app',
      ...previousContext.headers,
    },
  };
});

// Concat all the links together to create a united link object to get passed to the Apollo Client
const link = middlewareLink.concat(links).concat(httpLink);

// Create the Apollo Client with the caching system and links
const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: true,
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <GoogleOAuthProvider clientId={google_client_id}>
    <ApolloProvider client={client}>
      <Provider value={urqlClient}>
        <AppRoutes />
        <ToastContainer position="top-right" hideProgressBar />
      </Provider>
    </ApolloProvider>
  </GoogleOAuthProvider>,
);
