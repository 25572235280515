import classnames from 'classnames';
import { Children, isValidElement } from 'react';

import type { FC, PropsWithChildren, ReactElement } from 'react';

type StackProps = { spacing?: number; isInline?: boolean; className?: string };
const Stack: FC<PropsWithChildren<StackProps>> = ({ children, isInline = true, spacing = 10, className }) => {
  const validChildren = Children.toArray(children).filter(child => isValidElement(child)) as ReactElement[];

  return (
    <div className={classnames(className, 'tw-flex', isInline ? 'tw-flex-row' : 'tw-flex-col')}>
      {validChildren.map((child, index) => {
        const style = isInline
          ? {
              marginRight: index === validChildren.length ? 0 : spacing,
            }
          : {
              marginTop: index === 0 ? 0 : spacing,
            };

        const key = typeof child.key !== 'undefined' ? child.key : index;

        return (
          <div key={key} style={style}>
            {child}
          </div>
        );
      })}
    </div>
  );
};

export const HStack: FC<PropsWithChildren<Pick<StackProps, 'spacing' | 'className'>>> = ({
  children,
  spacing,
  className,
}) => {
  return (
    <Stack className={className} spacing={spacing}>
      {children}
    </Stack>
  );
};

export const VStack: FC<PropsWithChildren<Pick<StackProps, 'spacing' | 'className'>>> = ({
  children,
  spacing,
  className,
}) => {
  return (
    <Stack spacing={spacing} isInline={false} className={className}>
      {children}
    </Stack>
  );
};
