/* eslint-disable max-lines */
import { Mutation, Query } from '@apollo/client/react/components';
import {
  Button,
  // Divider,
  Callout,
  Classes,
  FormGroup,
  H2,
  H3,
  H6,
  HTMLSelect,
  InputGroup,
  Tag,
  TextArea,
} from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import Papa from 'papaparse';
import { Component, Fragment } from 'react';

import { AppConsumer, Dialog, ErrorCallout, Spinner, TemplateEdit } from 'components/common';
import { MESSAGE_DIRECTION } from 'lib/constants';
import { utils } from 'lib/utils';

class CustomBatch extends Component {
  message_template_variables: any;

  message_templates: any;

  message_type_details: any;

  message_type_options: any;

  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = {
      inputIds: [],
      users: [],
      bookingID: '',
    };

    this.message_template_variables = [
      {
        section: 'User',
        label: 'First name',
        prop: 'user.meta.identity.firstname',
        length: 6,
      }, // 70% of participants <= 6
      {
        section: 'User',
        label: 'Last name',
        prop: 'user.meta.identity.lastname',
        length: 7,
      }, // 75% of participants <= 7
      {
        section: 'User',
        label: 'Mobile',
        prop: 'user.contact.phone.mobile',
        length: 12,
      },
      {
        section: 'User',
        label: 'Unsubscribe link',
        prop: 'unsubscribe_shortlink',
        length: 'https://askb.co/'.length + 11,
      }, // 11 char token
      { section: 'Booking', label: 'Booking Address', prop: 'booking.config.location' },
      { section: 'Booking', label: 'Session start time', prop: 'booking_participant.Session.start' },
      {
        section: 'Booking',
        label: 'Recruitment short link',
        prop: 'recruitment_shorlink',
        length: 'https://askb.co/'.length + 11,
      }, // 11 char token
      {
        section: 'Booking',
        label: 'Incentive',
        prop: 'booking_incentive',
        length: 3,
      },
      {
        section: 'Booking',
        label: 'Booking ID',
        prop: 'booking._id',
        length: 24,
      },
    ];

    this.message_templates = utils.getMessageTemplates();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'template' does not exist on type 'Readon... Remove this comment to see the full error message
    this.state.template = this.message_templates[0].value;

    this.message_type_options = [
      { value: 'BOOKING', label: 'Booking recruitment' },
      { value: 'ADMIN', label: 'Batch admin message' },
    ];

    this.message_type_details = {
      BOOKING: (
        <div>
          - The same kind of message from the Booking batch tool
          <br />- Automatically adds an unsubscribe link at the end
        </div>
      ),
      ADMIN: 'Messages will appear in participants’ admin message inbox',
    };

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'messageType' does not exist on type 'Rea... Remove this comment to see the full error message
    this.state.messageType = this.message_type_options[0].value;
  }

  render() {
    return (
      <div className="content-page">
        <H2>Custom Batch</H2>
        <div className="flex flex-row">
          <div className="margin-right-3 flex-grow-2 flex-shrink-1" style={{ maxWidth: '50vw', minWidth: '300px' }}>
            <TextArea
              fill
              style={{ height: '30vh', width: '100%', resize: 'vertical' }}
              onChange={event => {
                const csv = Papa.parse(event.target.value);
                if (!csv) return;
                const inputIds = _.chain(csv.data)
                  .flatten()
                  .map((v: any) => v.toString().replace(/^[\s'"]+|[\s'"]+$/g, ''))
                  .filter(v => v && v.match && v.match(/^[0-9A-Fa-f]{24}$/))
                  .uniq()
                  .value();

                // console.log(inputIds);
                this.setState({ inputIds });
              }}
            />
            <div className="margin-top-1 flex flex-wrap">
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'inputIds' does not exist on type 'Readon... Remove this comment to see the full error message */}
              {this.state.inputIds.length > 0 && (
                <Query
                  query={gql`
                    query checkUserIds($ids: [ID]) {
                      userByIDs(ids: $ids) {
                        _id
                        meta {
                          identity {
                            firstname
                            lastname
                          }
                        }
                      }
                    }
                  `}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputIds' does not exist on type 'Readon... Remove this comment to see the full error message
                  variables={{ ids: this.state.inputIds }}
                  onCompleted={(data: any) => {
                    this.setState({
                      users: _.get(data, 'userByIDs', []),
                    });
                  }}
                >
                  {({ error, loading }: any) => {
                    if (loading) {
                      return (
                        <Tag minimal>
                          <Spinner inline withText />
                        </Tag>
                      );
                    }
                    if (error) {
                      return <ErrorCallout error={error} />;
                    }

                    // const users = _.get(data, 'userByIDs', []);
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'users' does not exist on type 'Readonly<... Remove this comment to see the full error message
                    const { users } = this.state;

                    return (
                      <div>
                        <H3>
                          Sending to {users.length} user{users.length === 1 ? '' : 's'}
                        </H3>
                        <div className="margin--1 padding-05">
                          {users.map((user: any) => (
                            <Tag key={user._id} className="margin-05">
                              {[_.get(user, 'meta.identity.firstname'), _.get(user, 'meta.identity.lastname')].join(
                                ' ',
                              )}
                            </Tag>
                          ))}
                        </div>
                      </div>
                    );
                  }}
                </Query>
              )}
            </div>
          </div>
          <div className="flex flex-column flex-shrink-1" style={{ flexBasis: '400px' }}>
            <form
              onSubmit={e => {
                e.preventDefault();

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputIds' does not exist on type 'Readon... Remove this comment to see the full error message
                if (this.state.inputIds.length === 0) {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type 'Readonl... Remove this comment to see the full error message
                  this.props.context.newToast({ intent: 'danger', message: 'No users entered' });
                  return;
                }
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'users' does not exist on type 'Readonly<... Remove this comment to see the full error message
                if (this.state.users.length === 0) {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type 'Readonl... Remove this comment to see the full error message
                  this.props.context.newToast({ intent: 'danger', message: 'No users found for IDs' });
                  return;
                }

                const batchInput = {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'users' does not exist on type 'Readonly<... Remove this comment to see the full error message
                  _user_ids: this.state.users.map((user: any) => user._id),
                  direction: MESSAGE_DIRECTION.ASKABLE_TO_PARTICIPANT,
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'template' does not exist on type 'Readon... Remove this comment to see the full error message
                  template: this.state.template,
                  // type: MESSAGE_TYPE.BATCH,
                };

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'bookingID' does not exist on type 'Reado... Remove this comment to see the full error message
                if (this.state.bookingID) {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'bookingID' does not exist on type 'Reado... Remove this comment to see the full error message
                  if (!this.state.bookingID.match(/^[0-9a-f]{24}$/)) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type 'Readonl... Remove this comment to see the full error message
                    this.props.context.newToast({ intent: 'danger', message: 'Booking ID looks invalid' });
                    return;
                  }
                  // @ts-expect-error ts-migrate(2339) FIXME: Property '_booking_id' does not exist on type '{ _... Remove this comment to see the full error message
                  batchInput._booking_id = this.state.bookingID;
                }

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'messageType' does not exist on type 'Rea... Remove this comment to see the full error message
                switch (this.state.messageType) {
                  case 'BOOKING':
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'batch_tag' does not exist on type '{ _us... Remove this comment to see the full error message
                    batchInput.batch_tag = 'recruitment';
                    break;
                  case 'ADMIN':
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'batch_tag' does not exist on type '{ _us... Remove this comment to see the full error message
                    batchInput.batch_tag = 'batch_admin';
                    // batchInput.type = MESSAGE_TYPE.ADMIN;
                    break;
                  default:
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'batch_tag' does not exist on type '{ _us... Remove this comment to see the full error message
                    batchInput.batch_tag = 'admin_batch_other';
                }

                this.setState({
                  batchInput,
                });
              }}
            >
              <H6>Settings</H6>
              <FormGroup
                label="Message type"
                labelFor="messageType"
                // labelInfo="(required)"
                className="margin-bottom-2"
              >
                <HTMLSelect
                  options={this.message_type_options}
                  onChange={e => {
                    this.setState({ messageType: e.target.value });
                  }}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'messageType' does not exist on type 'Rea... Remove this comment to see the full error message
                  value={this.state.messageType}
                  id="messageType"
                  fill
                />
              </FormGroup>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'messageType' does not exist on type 'Rea... Remove this comment to see the full error message */}
              {this.state.messageType && this.message_type_details[this.state.messageType] ? (
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'messageType' does not exist on type 'Rea... Remove this comment to see the full error message
                <Callout className="margin-bottom-2">{this.message_type_details[this.state.messageType]}</Callout>
              ) : null}

              <FormGroup
                label="Booking ID"
                labelFor="bookingID"
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'messageType' does not exist on type 'Rea... Remove this comment to see the full error message
                labelInfo={this.state.messageType === 'BOOKING' ? null : '(optional)'}
                className="margin-bottom-2"
              >
                <InputGroup
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'bookingID' does not exist on type 'Reado... Remove this comment to see the full error message
                  value={this.state.bookingID || ''}
                  onChange={(e: any) => {
                    this.setState({ bookingID: e.target.value.toLowerCase().replace(/[^0-9a-f]/g, '') });
                  }}
                  id="bookingID"
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'messageType' does not exist on type 'Rea... Remove this comment to see the full error message
                  required={this.state.messageType === 'BOOKING'}
                  pattern="[a-z0-9A-Z]{24}"
                  title="ID (24 hex characters)"
                />
              </FormGroup>

              <div className="margin-bottom-2 margin-top-2">
                <H6>Template</H6>
                <div style={{ width: '380px' }}>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                    }}
                  >
                    <TemplateEdit
                      variables={this.message_template_variables}
                      templates={this.message_templates}
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'template' does not exist on type 'Readon... Remove this comment to see the full error message
                      defaultValue={this.state.template}
                      onChange={(value: any) => {
                        this.setState({ template: value });
                      }}
                      templateExtraChars="\n\nOpt out https://askb.co/XXXXXXXXXXX"
                    />
                  </form>
                </div>
              </div>

              <Button type="submit" intent="primary" text="Send" />
            </form>
          </div>
        </div>
        <Dialog
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'users' does not exist on type 'Readonly<... Remove this comment to see the full error message
          title={`Send to ${this.state.users.length} user${this.state.users.length === 1 ? '' : 's'}?`}
          icon="inbox"
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'batchInput' does not exist on type 'Read... Remove this comment to see the full error message
          isOpen={!!this.state.batchInput}
          onClose={() => {
            this.setState({ batchInput: null });
          }}
          onClosed={() => {
            this.setState({ batchInput: null });
          }}
          isCloseButtonShown
        >
          <Mutation
            mutation={gql`
              mutation admin_createCusomBatch($batchInput: CustomBatchInput!) {
                createCustomBatch(batch: $batchInput) {
                  _id
                }
              }
            `}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'batchInput' does not exist on type 'Read... Remove this comment to see the full error message
            variables={{ batchInput: this.state.batchInput }}
          >
            {(createBatch: any, { data, loading, error }: any) => {
              if (error) {
                return (
                  <div className={Classes.DIALOG_BODY}>
                    <ErrorCallout error={error} />
                  </div>
                );
              }
              if (data && data.createCustomBatch) {
                const messages = data.createCustomBatch.length;
                return (
                  <div className={Classes.DIALOG_BODY}>
                    <Callout intent="success" title="Batch created">
                      <p>
                        Created {messages} message{messages === 1 ? '' : 's'}
                      </p>
                    </Callout>
                  </div>
                );
              }
              return (
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; data: any; }' is not ... Remove this comment to see the full error message
                <Fragment data={data}>
                  <div className={Classes.DIALOG_BODY}>
                    <Callout className="">
                      {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'templateHTML' does not exist on type 'Re... Remove this comment to see the full error message */}
                      <div dangerouslySetInnerHTML={{ __html: this.state.template }} />
                    </Callout>
                  </div>
                  <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <Button text="Confirm" loading={loading} intent="primary" onClick={createBatch} />
                    </div>
                  </div>
                </Fragment>
              );
            }}
          </Mutation>
        </Dialog>
      </div>
    );
  }
}

// export default compose(loginUserMutation)(Messaging);
export default AppConsumer(CustomBatch);
