import { gql } from 'urql';

export default gql(`
mutation admin_SubmissionRateParticipant($input: RatingParticipantInput!) {
  submissionRateParticipant(input: $input) {
    _id
    rating {
      overall
    }
  }
}`);
