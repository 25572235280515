import { Classes, Spinner, Button } from '@blueprintjs/core';
import classnames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Dialog } from 'components/common';
import { useRequestESignatureTemplateDeleteMutation } from 'generated/graphql';
import { BOOKING_STATUS } from 'lib/constants';

import type { ESignatureTemplate } from 'generated/graphql';

const DeleteTemplateDialog = ({
  template,
  onClose,
}: {
  template: ESignatureTemplate | null;
  onClose: any;
  isNew?: boolean;
}) => {
  const activeBookings = (template?.AgreementBookings || []).filter(
    booking => booking?.status === BOOKING_STATUS.ACTIVE,
  );

  const [isLoading, setIsLoading] = useState(false);
  const deleteTemplate = useRequestESignatureTemplateDeleteMutation()[1];

  return (
    <Dialog
      isOpen={!!template}
      title={`Delete ${template?.name}`}
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
    >
      {template?._id ? (
        <>
          <div className={classnames(Classes.DIALOG_BODY, Classes.RUNNING_TEXT)}>
            <p>Are you sure? Clients will not be able to use this template anymore.</p>
            {activeBookings.length > 0 && (
              <>
                <p>This template is in use in the following active booking(s):</p>
                <ul className={`${Classes.LIST} ${Classes.UI_TEXT}`}>
                  {activeBookings.map(booking =>
                    booking ? (
                      <li key={booking?._id}>
                        <Link to={`/booking/${booking._id}`}>{booking.name}</Link>
                      </li>
                    ) : null,
                  )}
                </ul>
              </>
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                intent="danger"
                loading={isLoading}
                onClick={() => {
                  if (isLoading) return;
                  setIsLoading(true);
                  deleteTemplate({ _id: template._id || '' })
                    .then(response => {
                      if (!response?.data?.requestESignatureTemplateDelete) {
                        throw new Error(response?.error?.message || 'Unknown error');
                      }
                      setIsLoading(false);
                      onClose();
                    })
                    .catch(e => {
                      window.alert(e.message);
                      setIsLoading(false);
                    });
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </>
      ) : (
        <Spinner className="tw-mx-auto tw-my-8" />
      )}
    </Dialog>
  );
};

export default DeleteTemplateDialog;
