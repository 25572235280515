import { Button, H5 } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';

import { ErrorCallout } from 'components/common';
import { useUpdateBookingAdminMutation } from 'generated/graphql';
import { determineCountryFromCurrency } from 'utils/currency-utils';

import type { Booking, IncentiveBookingConfig } from 'generated/graphql';

type Props = {
  booking: Booking;
  onClose?: () => void;
};
export const BookingUpdateIncentivesContainer = ({ booking, onClose }: Props) => {
  const [{ fetching: updating }, updateAdminBooking] = useUpdateBookingAdminMutation();
  const incentives = booking.config?.incentives;
  const { register, handleSubmit, formState } = useForm<IncentiveBookingConfig[]>({
    defaultValues: incentives as IncentiveBookingConfig[],
  });

  const { isDirty } = formState;

  const onSubmit = async (v: IncentiveBookingConfig[]) => {
    try {
      const _incentives = v.map(incentive => ({
        ...incentive,
        value: parseFloat(incentive.value as unknown as string),
        country_code: incentive.country_code ?? determineCountryFromCurrency(incentive.currency_code ?? ''),
      }));
      await updateAdminBooking({
        booking_id: booking._id,
        booking: {
          config: {
            incentives: _incentives,
          },
        },
      });

      onClose?.();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-h-screen tw-p-4">
      <H5>Update Incentives</H5>
      <div>
        <ul className="flex tw-flex-col tw-gap-4">
          {incentives?.map((incentive, index) => {
            const countryCode = incentive?.country_code ?? determineCountryFromCurrency(incentive?.currency_code ?? '');
            if (!countryCode) {
              return (
                <ErrorCallout
                  key={index}
                  error={`Incentive for ${incentive?.currency_code} cannot be updated because it is missing critical information. It will need to be updated manually through a dev request.`}
                />
              );
            }
            return (
              <li key={countryCode}>
                <label htmlFor={`incentive-${countryCode}`} className="tw-block tw-mb-2 tw-font-bold">
                  {countryCode} ({incentive?.currency_code})
                </label>
                <input
                  id={`incentive-${countryCode}`}
                  {...register(`${index}.value` as const)}
                  defaultValue={incentive?.value ?? 0}
                  className="tw-w-full tw-p-2 tw-border tw-rounded"
                />
              </li>
            );
          })}
        </ul>
        <Button loading={updating} disabled={!isDirty} type="submit" className="tw-my-4 tw-w-full">
          Save
        </Button>
      </div>
    </form>
  );
};
