import { graphql } from '@apollo/client/react/hoc';
import { Button, Popover } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _, { flowRight as compose } from 'lodash';

import { Select, SwitchList } from 'components/common';

import './styles/industry-criteria-selector.scss';

const IndustrySelector = ({ value, onChange, data, className, ...props }: any) => {
  let activeIndustry = {};
  if (value && value._industry_id) {
    activeIndustry = _.find(data.industryList, ['_id', value._industry_id]) || {};
  }
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'subcategories' does not exist on type '{... Remove this comment to see the full error message
  if (!activeIndustry.subcategories) activeIndustry.subcategories = [];

  const activeSubcategories: any = [];
  if (value && value.subcategories) {
    value.subcategories.forEach((subcategory: any) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'subcategories' does not exist on type '{... Remove this comment to see the full error message
      const subindustry = _.find(activeIndustry.subcategories, ['_id', subcategory._subcategory_id]);
      if (subindustry) {
        activeSubcategories.push(subindustry);
      }
    });
  }

  let subcategoryLabel = null;
  switch (activeSubcategories.length) {
    case 0:
      subcategoryLabel = 'Any role';
      break;
    case 1:
      subcategoryLabel = activeSubcategories[0].name;
      break;
    default:
      subcategoryLabel = `${activeSubcategories.length} selected roles`;
  }

  const popoverProps = {
    // minimal: true,
    // boundary: 'scrollParent',
    position: 'bottom-left',
    portalClassName: 'industry-criteria-selector',
    fill: true,
    modifiers: {
      preventOverflow: false,
      flip: false,
      arrow: false,
    },
  };

  let primaryControl = <Button rightIcon="caret-down" disabled loading fill />;
  let secondaryControl = <Button rightIcon="caret-down" disabled loading fill />;
  if (!_.get(data, 'loading')) {
    primaryControl = (
      <Select
        options={_.get(data, 'industryList', []).map((industry: any) => ({
          value: industry._id,
          label: industry.name,
        }))}
        value={value._industry_id}
        placeholder="Select an industry"
        onChange={(selectedValue: any) => {
          const industry = _.find(data.industryList, ['_id', selectedValue]);
          if (industry) {
            onChange({
              _industry_id: industry._id,
              industry_name: industry.name,
              subcategories: [],
            });
          }
        }}
      />
    );

    secondaryControl = (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; content: Element; posit... Remove this comment to see the full error message
      <Popover
        {...popoverProps}
        content={
          <div className={['popover-wrapper'].join(' ')}>
            <SwitchList
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ multiple: true; options: any; defaultCheck... Remove this comment to see the full error message
              multiple
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'subcategories' does not exist on type '{... Remove this comment to see the full error message
              options={activeIndustry.subcategories.map((category: any) => ({
                value: category._id,
                label: category.name,
              }))}
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'category' implicitly has an 'any' type.
              defaultChecked={activeSubcategories.map(category => category._id)}
              onChange={(values: any) => {
                const subcategories: any = [];
                values.forEach((subcategory: any) => {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'subcategories' does not exist on type '{... Remove this comment to see the full error message
                  const industry = _.find(activeIndustry.subcategories, ['_id', subcategory]);
                  if (industry) {
                    subcategories.push({
                      _subcategory_id: industry._id,
                      subcategory_name: industry.name,
                    });
                  }
                });

                onChange({
                  // @ts-expect-error ts-migrate(2339) FIXME: Property '_id' does not exist on type '{}'.
                  _industry_id: activeIndustry._id,
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{}'.
                  industry_name: activeIndustry.name,
                  subcategories,
                });
              }}
            />
          </div>
        }
      >
        <Button
          rightIcon="caret-down"
          fill
          className="button-overflow"
          text={subcategoryLabel}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'subcategories' does not exist on type '{... Remove this comment to see the full error message
          disabled={activeIndustry.subcategories.length === 0}
        />
      </Popover>
    );
  }

  return (
    <div className={`industry-criteria-selector margin-bottom-1 ${className || ''}`} {...props}>
      <div className="flex width-100 flex-align-center">
        <div className="width-40 padding-right-1">{primaryControl}</div>
        <div className="width-40 padding-right-1">{secondaryControl}</div>
        <div className="width-auto">
          <Button
            minimal
            small
            icon="remove"
            intent="danger"
            onClick={() => {
              onChange();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const FetchIndustryList = graphql(
  gql`
    query {
      industryList {
        _id
        name
        subcategories {
          _id
          name
        }
      }
    }
  `,
  {
    name: 'data',
  },
);

export default compose(FetchIndustryList)(IndustrySelector);
