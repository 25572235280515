import { Classes, Card, H3, HTMLSelect, FormGroup, Button, InputGroup, Checkbox } from '@blueprintjs/core';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { AskableNavbar, SkeletonWrapper } from 'components/common';
import { useAppContext } from 'components/common/appProvider';
import { StackLayout } from 'components/layout/StackLayout';
import { OperationalOffices, useUpdateTeamMutation, useTeamByIdQuery } from 'generated/graphql';

import TeamTemplatesTable from './components/TeamTemplatesTable';

import type { FormEvent } from 'react';

export const TeamContainer = () => {
  const [operationalOffice, setOperationalOffice] = useState(OperationalOffices.Au);
  const [invoiceDueDays, setInvoiceDueDays] = useState(30);
  const [internalTeam, setInternalTeam] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const context = useAppContext();
  const params = useParams();
  const [, updateTeam] = useUpdateTeamMutation();

  const [teamByIdQuery] = useTeamByIdQuery({ variables: { _id: params.id! } });
  const team = _.get(teamByIdQuery, 'data.teamById');

  useEffect(() => {
    if (team?.operational_office) setOperationalOffice(team.operational_office);
    if (team?.settings?.billing?.invoice_due_days) setInvoiceDueDays(team.settings.billing.invoice_due_days);
    if (team?.settings?.ignore_tracking) setInternalTeam(team.settings.ignore_tracking);
  }, [team]);

  const FormGroupWrapper = ({ children, loading, ...props }: any) => {
    return (
      <FormGroup disabled={loading} {...props}>
        {children}
      </FormGroup>
    );
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();

    await updateTeam({
      _id: params.id!,
      team: {
        operational_office: operationalOffice,
        settings: {
          billing: {
            invoice_due_days: invoiceDueDays,
          },
          ignore_tracking: internalTeam,
        },
      },
    });

    context.newToast({ message: 'Team updated', intent: 'success' });
  };

  return (
    <StackLayout>
      <div className="tw-p-6 tw-border-gray-500 tw-space-y-6">
        <Card elevation={1}>
          <H3>
            <SkeletonWrapper active={teamByIdQuery.fetching} length={8}>
              {team?.name || 'Team?'}
            </SkeletonWrapper>
          </H3>
          <div className="tw-mb-2">ID: {team?._id}</div>
          <div className={classnames(Classes.TEXT_LARGE, 'tw-mb-6')}>
            <SkeletonWrapper active={teamByIdQuery.fetching} length={16}>
              {_.get(team, 'settings.billing.subscription.credit.remaining', '?').toLocaleString()} Credits
            </SkeletonWrapper>
          </div>
          <div className="tw-flex tw-flex-row tw-items-flex-end">
            <div className={classnames('tw-flex-1', Classes.TEXT_MUTED, Classes.TEXT_SMALL)}>
              {(team?.Organisation || teamByIdQuery.fetching) && (
                <SkeletonWrapper active={teamByIdQuery.fetching} length={16} tag="p">
                  Organisation:{' '}
                  <Link to={`/team/organisation/${team?.Organisation._id}`}>
                    {_.get(team, 'Organisation.name', '?')}
                  </Link>
                </SkeletonWrapper>
              )}
              <SkeletonWrapper active={teamByIdQuery.fetching} length={16} tag="p">
                Created: {moment(_.get(team, 'created', new Date())).fromNow()}
              </SkeletonWrapper>
            </div>
          </div>
        </Card>
        <form onSubmit={handleSubmit}>
          <FormGroupWrapper label="Operational office" labelFor="operational-office">
            <HTMLSelect
              options={Object.values(OperationalOffices)}
              id="operational-office"
              onChange={e => setOperationalOffice(e.target.value as OperationalOffices)}
              value={operationalOffice}
            />
          </FormGroupWrapper>
          <FormGroupWrapper label="Invoice payment terms (days)" labelFor="invoice_due_days">
            <InputGroup
              id="invoice_due_days"
              value={invoiceDueDays.toString()}
              type="number"
              onChange={e => setInvoiceDueDays(parseInt(e.target.value))}
              className="input-width-15"
            />
          </FormGroupWrapper>
          <FormGroupWrapper label="Internal Team" labelFor="internal-team">
            <Checkbox id="internal-team" checked={internalTeam} onChange={e => setInternalTeam(e.target.checked)} />
          </FormGroupWrapper>
          <Button type="submit" className="tw-my-4 tw-w-full">
            Save
          </Button>
        </form>
      </div>

      <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-pb-5">
        <AskableNavbar
          items={[
            {
              title: 'Users',
              active: location.pathname.split('/').includes('users'),
              onClick: () => navigate('users'),
            },
            {
              title: 'Bookings',
              active: location.pathname.split('/').includes('bookings'),
              onClick: () => navigate('bookings'),
            },
            {
              title: 'Templates',
              active: location.pathname.split('/').includes('esignature-templates'),
              onClick: () => navigate('esignature-templates'),
            },
          ]}
        />
        <Outlet />
      </div>
    </StackLayout>
  );
};

export { TeamTemplatesTable };
