import { useMemo, useState } from 'react';
import { Outlet } from 'react-router';

import { AskableNavbar } from 'components/common';

import type { AskableNavBarItem } from 'components/common/askable-navbar.view';

export const AskablePlusContainer = () => {
  const [activeNavBar, setActiveNavBar] = useState<'live' | 'project'>('live');
  const navItems = useMemo<AskableNavBarItem[]>(() => {
    return [
      {
        title: 'Active projects',
        path: '/askable-plus/live',
        onClick: () => {
          setActiveNavBar('live');
        },
      },
      {
        title: 'All projects',
        path: '/askable-plus/project',
        onClick: () => {
          setActiveNavBar('project');
        },
      },
    ];
  }, [activeNavBar]);

  return (
    <div className="tw-h-full tw-w-full">
      <AskableNavbar items={navItems} key="askable-plus-icons" />
      <Outlet />
    </div>
  );
};
