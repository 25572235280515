import dayjs from 'dayjs';

import type { Maybe } from 'generated/graphql';

type AvailableFormats = 'D MMM YYYY' | 'MMM D, YYYY' | 'h:mma - ddd D MMM YYYY';

type FormattedDatePayload = {
  date?: Maybe<number> | Maybe<Date> | Maybe<string>;
  format: AvailableFormats;
};

export function formattedDate(data: FormattedDatePayload) {
  if (!data.date) {
    return null;
  }
  return dayjs(data.date).format(data.format);
}
