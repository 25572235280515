import { gql } from 'urql';

export const MarkProjectMissionCritical = gql`
  mutation markProjectMissionCritical($projectId: ID!, $missionCritical: Boolean!, $missionCriticalReason: String) {
    markProjectMissionCritical(
      projectId: $projectId
      missionCritical: $missionCritical
      missionCriticalReason: $missionCriticalReason
    )
  }
`;
