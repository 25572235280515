import { gql } from 'urql';

export default gql(`query bookingSubmissionsForBookingId($bookingId: ID!) {
  findAllBookingSubmissions(bookingId: $bookingId) {
    _id
    created
    user {
      _id
      email
      location {
        country
        city
      }
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
    status
    rating {
      overall
    }
  }
}`);
