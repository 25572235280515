import { Checkbox, Radio, RadioGroup } from '@blueprintjs/core';
import _ from 'lodash';
import { Component } from 'react';

class SwitchList extends Component {
  constructor(props: any) {
    super(props);

    this.state = {
      values: {},
    };

    if (props.defaultChecked) {
      props.defaultChecked.forEach((value: any) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
        this.setState(prevState => ({ values: { ...prevState.values, [value]: true } }));
      });
    }

    this.onChangeCallback = this.onChangeCallback.bind(this);
  }

  onChangeCallback() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.onChange) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(_.keys(_.pickBy(this.state.values)));
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'multiple' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.multiple) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message
      return this.props.options.map((option: any) => {
        const { value } = option;
        let { label } = option;
        if (!label) label = value;

        return (
          <Checkbox
            key={value}
            value={value}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
            checked={!!this.state.values[value]}
            label={label}
            onChange={event => {
              const { checked } = event.target;
              this.setState(state => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
                state.values[value] = checked;
                return state;
              }, this.onChangeCallback);
            }}
          />
        );
      });
    }

    return (
      <RadioGroup
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        selectedValue={_.findKey(this.state.values) || null}
        onChange={event => {
          const values = {};
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          values[event.currentTarget.value] = true;
          this.setState({ values }, this.onChangeCallback);
        }}
      >
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message */}
        {this.props.options.map((option: any) => {
          const { value } = option;
          let { label } = option;
          if (!label) label = value;

          return <Radio key={value} value={value} label={label} />;
        })}
      </RadioGroup>
    );
  }
}

export default SwitchList;
