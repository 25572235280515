/* eslint-disable max-lines */
import { AnchorButton, Button, HTMLSelect } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Component } from 'react';
import { useParams } from 'react-router';

import { BookingPageBookingFields } from '@graphql/fragments/booking/bookingPageBooking';
import { PageTitle } from 'components/PageTitle';
import { AskableNavbar, Divider, ErrorCallout } from 'components/common';
import { useAppContext } from 'components/common/appProvider';
import ClientAppIFrame from 'components/iframe/ClientAppIframe';
import { useAdmin_AdminLoginAsClientMutation, useAdmin_BookingByIdQuery } from 'generated/graphql';
import { BOOKING_STATUS } from 'lib/constants';
import { utils } from 'lib/utils';

import { MissionCritical } from '../../components/MissionCritical';

import { BookingViewParticipants } from './BookingViewParticipants';
import Billing from './booking-page.billing.view';
import Comments from './booking-page.comments.view';
import InfoContacts from './booking-page.info-contacts.view';
import InfoFulfilment from './booking-page.info-fulfilment.view';
import { InfoIncentives } from './booking-page.info-incentives.view';
import InfoOverview from './booking-page.info-overview.view';
import { InfoTranslation } from './booking-page.info-translationview';
import LeftActions from './booking-page.left-actions.view';
import Log from './booking-page.log.view';

import type { AppContext } from 'components/common/appProvider';
import type { Admin_BookingByIdQuery, Maybe, Booking as BookingType } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  refetchBooking: ReturnType<typeof useAdmin_BookingByIdQuery>['1'];
  bookingByID: Admin_BookingByIdQuery & { loading: boolean };
  context: AppContext;
  _booking_id: string;
  loginAsClientMutation: ReturnType<typeof useAdmin_AdminLoginAsClientMutation>['1'];
};

type State = {
  subscriptionUpdated: Maybe<number>;
  appFrame: {
    tab?: string;
    path?: Maybe<string>;
    src?: any;
    key?: number;
  };
  clientLogin: any;
  bookingUnsubscribe: any;
};

class Booking extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const bookingStatus = this.props.bookingByID.bookingByID?.status;
    const appFrames = {
      clients: 'Client app',
      participants: bookingStatus !== BOOKING_STATUS.DRAFT ? 'Participants' : undefined,
    };
    this.state = {
      subscriptionUpdated: null,
      clientLogin: null,
      appFrame: {
        src: null,
        path: null,
        tab: 'clients',
      },
      bookingUnsubscribe: null,
    };

    this.appFrameTabs = appFrames;

    this.resetAppFrame = this.resetAppFrame.bind(this);
    this.setAppFrame = this.setAppFrame.bind(this);
    this.clientLogin = this.clientLogin.bind(this);
    this.bookingSubscribe = this.bookingSubscribe.bind(this);
  }

  componentDidMount() {
    if (_.get(this.props, 'bookingByID.bookingByID._id')) {
      this.setAppFrame();
    }
    if (!_.get(this.state, 'clientLogin.params') && _.get(this.props, 'bookingByID.bookingByID._owner_id.0')) {
      this.clientLogin(this.props.bookingByID?.bookingByID?._owner_id?.[0]);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      _.get(this.props, 'bookingByID.bookingByID._id') &&
      (_.get(this.props, 'bookingByID.bookingByID._id') !== _.get(prevProps, 'bookingByID.bookingByID._id') ||
        _.get(this.props, 'bookingByID.bookingByID.status') !== _.get(prevProps, 'bookingByID.bookingByID.status'))
    ) {
      this.resetAppFrame();
    }
    if (
      _.get(this.props, 'bookingByID.bookingByID._owner_id.0') &&
      _.get(this.props, 'bookingByID.bookingByID._owner_id.0') !==
        _.get(prevProps, 'bookingByID.bookingByID._owner_id.0')
    ) {
      this.clientLogin(this.props.bookingByID?.bookingByID?._owner_id?.[0]);
    }

    if (!this.state.bookingUnsubscribe && _.get(this.props, 'bookingByID.subscribeToMore')) {
      this.bookingSubscribe();
    }

    if (this.props.bookingByID.bookingByID?.status === BOOKING_STATUS.ACTIVE && !this.appFrameTabs.participants) {
      this.appFrameTabs.participants = 'Participants';
    }
  }

  getAppDomain(app: any, subdomainSuffix?: any) {
    switch (app) {
      case 'clients':
        if (window.location.host.match(/^\w+\.askable\.com/)) {
          if (subdomainSuffix) {
            return `https://iframe-${subdomainSuffix}-app.askable.com`;
          }
          return 'https://iframe-app.askable.com';
        }
        if (window.location.host.match(/^\w+-\w+\.askable\./))
          return window.location.origin.replace(/\b\w+-(\w+)\.askable/, 'app-$1.askable');
        if (window.location.host.match(/^localhost/))
          return window.location.origin.replace(/localhost:\d+/, 'localhost:7001');
        return '';
      default:
        return '';
    }
  }

  setAppFrame(appFrame: State['appFrame'] = {} as State['appFrame']) {
    const { bookingByID } = this.props;

    this.setState(state => {
      Object.assign(state.appFrame, appFrame);
      // console.log('setAppFrame', state.appFrame);
      if (appFrame.tab || appFrame.path || !state.appFrame.src) {
        switch (state.appFrame.tab) {
          case 'clients':
            if (!_.get(state, 'clientLogin.params')) {
              state.appFrame.src = null;
              break;
            }
            if (appFrame.path) {
              state.appFrame.path = appFrame.path;
            } else if (bookingByID.bookingByID?._id) {
              switch (bookingByID.bookingByID?.status) {
                case BOOKING_STATUS.DRAFT:
                  state.appFrame.path = `/booking-setup/${bookingByID.bookingByID._id}/project-setup/project-title`;
                  break;
                case BOOKING_STATUS.ACTIVE:
                case BOOKING_STATUS.IN_REVIEW:
                case BOOKING_STATUS.COMPLETED:
                case BOOKING_STATUS.ARCHIVED:
                  state.appFrame.path = `/bookings/${bookingByID.bookingByID._id}/participants`;
                  break;
                default:
                  state.appFrame.path = `/bookings/${bookingByID.bookingByID?._id}`;
              }
            }
            state.appFrame.src = `${this.getAppDomain('clients', state.clientLogin.params.user_id)}/login?${utils.urlSerialize(
              {
                ..._.pick(state.clientLogin.params, [
                  'user_id',
                  'email',
                  'firstname',
                  'lastname',
                  'team_id',
                  'team_name',
                  'phone',
                  // 'token',
                ]),
                ignoreWarning: 'true',
                redirectPath: state.appFrame.path,
                token: state.clientLogin.params.token,
              },
            )}`;
            break;
          default:
        }
      }
      return state;
    });
  }

  appFrameTabs: any;

  resetAppFrame() {
    this.setState(state => {
      return {
        ...state,
        appFrame: _.pick(state.appFrame, ['tab']) as State['appFrame'],
      };
    }, this.setAppFrame);
  }

  bookingSubscribe() {
    this.setState({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'bookingByID' does not exist on type 'Rea... Remove this comment to see the full error message
      bookingUnsubscribe: this.props.bookingByID.subscribeToMore({
        document: gql`
          subscription admin_BookingPageBookingSubscription($id: ID!) {
            bookingByID(id: $id) {
              ...BookingPageBookingFields
            }
          }
          # subscription admin_BookingPageBookingSubscription { bookings { ...BookingPageBookingFields } }
          ${BookingPageBookingFields}
        `,
        variables: { id: this.props._booking_id },
        // updateQuery: (previousResult, { subscriptionData, variables }) => {
        updateQuery: () => {
          this.setState({ subscriptionUpdated: Date.now() });
        },
      }),
    });
  }

  clientLogin(_user_id: any) {
    this.props
      .loginAsClientMutation({ _id: _user_id })
      .then(({ data }) => {
        const params = utils.urlParams(_.get(data, 'adminLoginAsClient.url'));

        const user = _.get(data, 'adminLoginAsClient.user', { _id: _user_id });
        this.setState({ clientLogin: { params, user } }, this.setAppFrame);
      })
      .catch((error: any) => {
        this.props.context.openAlert(
          <>
            <p>
              <strong>There was an error signing in as the client:</strong>
            </p>
            <p>{error.message}</p>
          </>,
          'primary',
        );
      });
  }

  renderErrorPage(contents: any) {
    return <div className="content-page">{contents}</div>;
  }

  render() {
    const {
      refetchBooking,
      _booking_id,
      bookingByID: { bookingByID: booking, loading },
    } = this.props;

    if (!loading && !booking?._id) {
      if (_.get(this.props, 'bookingByID.error')) {
        return this.renderErrorPage(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'bookingByID' does not exist on type 'Rea... Remove this comment to see the full error message
          <ErrorCallout title="Error loading booking" error={this.props.bookingByID.error} />,
        );
      }
      return this.renderErrorPage(
        <ErrorCallout title="Booking not found" error={_booking_id || 'No booking ID provided'} />,
      );
    }

    const clientAppUsers = (booking?.team?.users ?? []).map(user => user?.User);

    if (
      _.get(this.state, 'clientLogin.user._id') &&
      !_.find(clientAppUsers, user => user?._id === this.state.clientLogin.user._id)
    ) {
      clientAppUsers.push(this.state.clientLogin.user);
    }

    return (
      <div id="booking-page-details" key={this.props._booking_id}>
        <div className="contents">
          <div className="column left">
            {booking && (
              <InfoOverview
                _booking_id={this.props._booking_id}
                booking={booking}
                loading={loading}
                setAppFrame={this.setAppFrame}
              />
            )}
            <InfoIncentives booking={booking} loading={loading} />
            <InfoTranslation booking={booking} loading={loading} />
            <InfoContacts booking={booking} loading={loading} />
            <InfoFulfilment
              _booking_id={this.props._booking_id}
              booking={booking}
              loading={loading}
              setAppFrame={this.setAppFrame}
            />

            <LeftActions
              refetchBooking={refetchBooking}
              _booking_id={this.props._booking_id}
              booking={booking as BookingType}
              loading={loading}
            />
          </div>
          <div className="column appframe">
            <AskableNavbar
              items={[
                ..._.chain(this.appFrameTabs)
                  .mapValues((title, key) => ({
                    title,
                    active: this.state.appFrame.tab === key,
                    props: { disabled: !booking?._id },
                    onClick: () => {
                      this.setAppFrame({ tab: key });
                    },
                  }))
                  .values()
                  .value(),
                {
                  key: 'actions',
                  align: 'right',
                  component: (
                    <>
                      {_.get(this.state, 'appFrame.tab') === 'clients' &&
                        (_.get(this.state, 'clientLogin.user._id') || _.get(this.state, 'clientLogin.loading')) && (
                          <>
                            <HTMLSelect
                              options={[
                                ...clientAppUsers.map((user: any) => ({
                                  value: _.get(user, '_id'),

                                  label:
                                    `${_.get(user, 'meta.identity.firstname', '')} ${_.get(user, 'meta.identity.lastname', '')}`.replace(
                                      /^\s+|\s+$/g,
                                      '',
                                    ) ||
                                    _.get(user, 'email', '') ||
                                    _.get(user, '_id'),
                                })),
                              ]}
                              disabled={!!_.get(this.state, 'clientLogin.loading')}
                              value={_.get(this.state, 'clientLogin.user._id')}
                              onChange={({ target }) => {
                                const _user_id = target.value;
                                this.setState(
                                  state => {
                                    return {
                                      ...state,
                                      clientLogin: { loading: true, user: { _id: _user_id } },
                                      appFrame: {
                                        ...state.appFrame,
                                        src: null,
                                      },
                                    };
                                  },
                                  () => {
                                    this.clientLogin(_user_id);
                                  },
                                );
                              }}
                            />
                            <Divider />
                          </>
                        )}

                      <Button
                        icon="reset"
                        minimal
                        disabled={!_.get(this.state, 'appFrame.src')}
                        onClick={() => {
                          this.setAppFrame({ key: Date.now() });
                        }}
                      />
                      <AnchorButton
                        icon="share"
                        minimal
                        disabled={!_.get(this.state, 'appFrame.src')}
                        href={this.state.appFrame.src}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </>
                  ),
                },
              ]}
            />
            {booking?._id && this.state.appFrame.tab === 'participants' ? (
              <BookingViewParticipants bookingId={this.props._booking_id} />
            ) : (
              <ClientAppIFrame {..._.pick(this.state.appFrame, ['src', 'key'])} />
            )}
          </div>
          <div className="column right">
            <Comments _booking_id={this.props._booking_id} {...{ booking, loading }} />

            {booking?._id ? (
              <div className="section">
                <MissionCritical
                  id={booking?._id}
                  isDisabled={booking?.status === BOOKING_STATUS.COMPLETED}
                  missionCritical={booking?.admin?.mission_critical ?? false}
                  missionCriticalReason={booking?.admin?.mission_critical_reason ?? ''}
                  type="booking"
                />
              </div>
            ) : null}

            <Billing {...{ booking, loading }} />
            <Log
              _booking_id={this.props._booking_id}
              subscriptionUpdated={this.state.subscriptionUpdated}
              {...{ booking, loading }}
            />
          </div>
        </div>
        {booking?.name && <PageTitle title={booking.name} />}
      </div>
    );
  }
}

const TEMP_BookingsWrapper: FC = () => {
  const appContext = useAppContext();
  const params = useParams();
  const [, login] = useAdmin_AdminLoginAsClientMutation();
  const [{ data, fetching, error }, refetchBooking] = useAdmin_BookingByIdQuery({
    variables: {
      id: params._booking_id!,
    },
  });

  if (error) {
    return <div>Error loading booking: {error.message}</div>;
  }

  return (
    <Booking
      context={appContext}
      loginAsClientMutation={login}
      _booking_id={params._booking_id!}
      bookingByID={{ ...data, loading: fetching }}
      refetchBooking={refetchBooking}
    />
  );
};

export default TEMP_BookingsWrapper;
