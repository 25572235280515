import { Position, Tooltip } from '@blueprintjs/core';
import classnames from 'classnames';
import { BiUserCircle } from 'react-icons/bi';

import type { Maybe } from 'generated/graphql';
import type { VFC } from 'react';

export type AvatarSize = 'sm' | 'md' | 'lg';

type AvatarProps = {
  name?: Maybe<string>;
  alt?: string;
  image?: Maybe<string>;
  size?: AvatarSize;
  className?: string;
  tooltipDisabled?: boolean;
};

export const Avatar: VFC<AvatarProps> = ({ image, className, alt, name, tooltipDisabled = false, size = 'sm' }) => {
  const sizeClasses: { [key in AvatarSize]: string } = {
    sm: 'tw-h-6 tw-w-6',
    md: 'tw-h-8 tw-w-8',
    lg: 'tw-h-10 tw-w-10',
  };

  const Image = () => {
    if (!image && name) {
      const classes: { [key in AvatarSize]: string } = {
        sm: 'tw-text-sm',
        md: 'tw-text-base',
        lg: 'tw-text-lg',
      };
      return (
        <div
          className={classnames(
            classes[size],
            'tw-h-full tw-text- tw-w-full tw-rounded-full tw-flex tw-bg-slate-500 tw-justify-center tw-items-center',
          )}
        >
          {name[0].toUpperCase()}
        </div>
      );
    }

    if (image) {
      return <img className="tw-w-full tw-h-full tw-rounded-full" src={image} alt={alt} />;
    }

    return <BiUserCircle className="tw-w-full tw-h-full" />;
  };

  return (
    <div className={classnames('tw-flex', className, sizeClasses[size])}>
      <Tooltip
        className="tw-flex tw-w-full tw-h-full"
        disabled={tooltipDisabled}
        content={name}
        position={Position.RIGHT}
        openOnTargetFocus={false}
      >
        <Image />
      </Tooltip>
    </div>
  );
};
