import './styles/emoji.scss';

const Emoji = (props: any) => {
  const { emoji, label, ...htmlProps } = props;

  // if (icon && icon.match(/^askable-)) // Return a custom svg icon

  return (
    <span
      role="img"
      className="emoji-wrapper-span"
      aria-labelledby={label || 'It’s an emoji, get over it'}
      {...htmlProps}
    >
      {emoji}
    </span>
  );
};

export default Emoji;
