/* eslint-disable max-lines */
import { Query } from '@apollo/client/react/components';
import { Button, FormGroup, HTMLSelect, InputGroup, Tag } from '@blueprintjs/core';
import _ from 'lodash';
import moment from 'moment';
import { Component, Fragment } from 'react';

import getIndustryData from '@graphql/queries/misc/getIndustryData';
import { ErrorCallout, LocationSuggest, PhoneInput } from 'components/common';
import {
  BOOLEAN_FIELDS,
  LABELS,
  USER_BUSINESS_SIZE,
  USER_EMPLOYMENT_TYPE,
  USER_INCENTIVE_METHODS,
} from 'lib/constants';

import type { OptionProps } from '@blueprintjs/core';
import type {
  Admin_GetIndustryDataQuery,
  Admin_GetParticipantDetailsQuery,
  Admin_UpdateUserMutationVariables,
} from 'generated/graphql';
import type { FormEventHandler } from 'react';
import type * as React from 'react';

interface Props {
  user: Admin_GetParticipantDetailsQuery['userByID'];
  loading: boolean;
  error: any;
  onSave: (user: Admin_UpdateUserMutationVariables['user']) => void;
}

interface State {
  values: {
    [x: string]: string;
  };
  updates: {
    [x: string]: null | string | { [x: string]: string | number | boolean };
  };
  errors: {
    [x: string]: any;
  };
}

class UpdateProfile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      values: {},
      updates: {},
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.canSubmit = this.canSubmit.bind(this);
    this.controlledInput = this.controlledInput.bind(this);
    this.getDOBValue = this.getDOBValue.bind(this);
    this.FormGroupWrapper = this.FormGroupWrapper.bind(this);
    this.BooleanStatusField = this.BooleanStatusField.bind(this);
    this.renderPayoutDetail = this.renderPayoutDetail.bind(this);
  }

  getDOBValue(formatter: (date: moment.Moment) => JSX.Element | string) {
    let date: string | number | undefined;
    if (this.state.values._dob) {
      if (this.state.values._dob === '') return null;
      date = this.state.values._dob;
    } else if (this.props.user?.meta?.identity?.birthday?.timestamp) {
      date = this.props.user.meta.identity.birthday.timestamp;
    }
    if (!date) return null;
    const m = moment(date);
    if (!m || !m.isValid()) return null;
    if (formatter) return formatter(m);
  }

  handleChange(prop: string, value: any) {
    this.setState(state => {
      state.values[prop] = value;

      switch (prop) {
        case 'contact.phone.mobile':
          state.updates[prop] = _.get(value, 'format.E164');
          break;
        case '_dob':
          if (value) {
            const date = moment(`${value}T00:00:00Z`);
            state.updates['meta.identity.birthday'] = {
              timestamp: date.valueOf(),
              year: date.get('year'),
              month: date.get('month') + 1,
              day: date.get('date'),
            };
          } else if (state.updates['meta.identity.birthday']) {
            state.updates['meta.identity.birthday'] = {};
          }
          break;
        case 'meta.work._industry_id':
          state.updates[prop] = value;
          state.values['meta.work._subindustry_id'] = '';
          state.updates['meta.work._subindustry_id'] = null;
          break;
        case 'meta.work._subindustry_id':
          state.updates['meta.work._subindustry_id'] = value || null;
          break;
        default:
          state.updates[prop] = value;
      }

      return state;
    });
  }

  handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
    // TODO: handle errors
    const user: Admin_UpdateUserMutationVariables['user'] = {};
    if (Object.values(this.state.updates).length === 0) {
      return;
    }

    _.forIn(this.state.updates, (value, field) => {
      _.set(user, field, value);
    });

    if (this.props.onSave) {
      this.props.onSave(user);
    }

    document.querySelector('#user-participant-details .info-content')?.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  canSubmit() {
    return _.values(this.state.updates).length !== 0;
  }

  controlledInput(prop: string, valueTransform?: (value: string) => string) {
    const props: {
      disabled: boolean;
      onChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement>;
      value?: string;
    } = {
      disabled: this.props.loading,
      onChange: event => {
        let { value } = event.target;
        if (valueTransform) {
          value = valueTransform(value);
        }
        this.handleChange(prop, value);
      },
    };
    switch (prop) {
      case '_dob':
        if (this.state.values._dob) {
          props.value = this.state.values._dob;
        } else {
          props.value = (this.getDOBValue((date: moment.Moment) => date.utc().format('YYYY-MM-DD')) as string) || '';
        }
        break;
      default:
        props.value = _.get(this.state.values, prop, _.get(this.props.user, prop, ''));
    }
    if (!props.value) {
      props.value = '';
    }
    return props;
  }

  FormGroupWrapper({ children, loading, ...props }: any) {
    return (
      <FormGroup disabled={loading} {...props}>
        {children}
      </FormGroup>
    );
  }

  BooleanStatusField({ field, select, ...props }: any) {
    if (!BOOLEAN_FIELDS.user[field]) return null;
    const id = `profile-boolean-${field}`;
    let selected = this.state.values[`_${field}`];
    // console.log(this.props.user, field, _.get(this.props.user, field));
    if (!selected && _.get(this.props.user, field)) {
      _.chain(this.props.user)
        .get(field)
        .forIn((v: any, k: any) => {
          if (v) {
            selected = k;
            return false;
          }
        })
        .value();
    }

    const disabled = this.props.loading;

    return (
      <this.FormGroupWrapper labelFor={id} {...props} disabled={disabled}>
        <HTMLSelect
          id={id}
          fill
          disabled={disabled}
          {...select}
          onChange={event => {
            const { value } = event.target;
            this.setState(state => {
              state.values[`_${field}`] = value;
              state.updates[field] = _.fromPairs(BOOLEAN_FIELDS.user[field].map((k: any) => [k, null]));
              state.updates[field][value] = true;
              return state;
            });
          }}
          options={[
            ...(selected ? [] : [{ value: null, label: '' }]),
            ...BOOLEAN_FIELDS.user[field].map((option: any) => ({
              value: option,
              label: _.get(LABELS.user, `${field}.${option}`),
            })),
          ]}
          value={selected}
        />
      </this.FormGroupWrapper>
    );
  }

  renderPayoutDetail() {
    const preference = _.get(
      this.state.values,
      'settings.billing.preferred',
      _.get(this.props.user, 'settings.billing.preferred', ''),
    );
    if (!preference) return null;
    const preferenceDetail = _.get(LABELS.user, `settings.billing.${preference}`);
    if (!preferenceDetail) return null;
    return (
      <this.FormGroupWrapper label={`${preferenceDetail} email`} className="flex-grow-2" fill>
        <InputGroup
          {...this.controlledInput(`settings.billing.${preference}`)}
          id="profile-payout-detail"
          autoComplete="off"
        />
      </this.FormGroupWrapper>
    );
  }

  render() {
    const { FormGroupWrapper, BooleanStatusField } = this;
    return (
      <form onSubmit={this.handleSubmit} className="padding-4 padding-3">
        {this.props.error && <ErrorCallout error={this.props.error} className="margin-bottom-2" />}

        <div className="flex margin-bottom-1">
          <FormGroupWrapper label="First name" className="flex-grow-1 margin-right-1" labelFor="profile-meta-firstname">
            <InputGroup
              {...this.controlledInput('meta.identity.firstname')}
              id="profile-meta-firstname"
              autoComplete="off"
            />
          </FormGroupWrapper>
          <FormGroupWrapper label="Last name" className="flex-grow-1" labelFor="profile-meta-lastname">
            <InputGroup
              {...this.controlledInput('meta.identity.lastname')}
              id="profile-meta-lastname"
              autoComplete="off"
            />
          </FormGroupWrapper>
        </div>
        <div className="flex margin-bottom-1">
          <FormGroupWrapper label="Email" className="flex-grow-1" labelFor="profile-email">
            <InputGroup {...this.controlledInput('email')} id="profile-email" autoComplete="off" type="email" />
          </FormGroupWrapper>
        </div>
        <div className="flex margin-bottom-1">
          <FormGroupWrapper label="Mobile" labelFor="profile-mobile-number">
            {this.props.loading ? (
              <InputGroup disabled />
            ) : (
              <PhoneInput
                value={
                  _.get(this.state, 'contact.phone.mobile') || _.get(this.props.user, 'contact.phone.mobile') || ''
                }
                onChange={value => {
                  this.handleChange('contact.phone.mobile', value);
                }}
                disabled={this.props.loading}
                id="profile-phone-mobile"
                autoComplete="off"
                fill
              />
            )}
          </FormGroupWrapper>
        </div>
        <div className="flex margin-bottom-1">
          <FormGroupWrapper label="Gender" labelFor="profile-gender" className="margin-right-1">
            <HTMLSelect
              {...this.controlledInput('meta.identity.gender')}
              options={[
                { value: 'female', label: 'Female' },
                { value: 'male', label: 'Male' },
                { value: 'non-binary', label: 'Non-binary' },
              ]}
              id="profile-gender"
            />
          </FormGroupWrapper>
          <FormGroupWrapper label="Birthday" labelFor="profile-dob">
            <InputGroup
              {...this.controlledInput('_dob')}
              type="date"
              leftIcon="calendar"
              id="profile-dob"
              rightElement={this.getDOBValue((date: moment.Moment) => (
                <Tag minimal>{moment().diff(date.utc(), 'years')} yrs</Tag>
              ))}
            />
          </FormGroupWrapper>
        </div>
        <div className="flex margin-bottom-1">
          <FormGroupWrapper label="Location" labelFor="profile-mobile-number" className="flex-grow-1" fill>
            {this.props.loading ? (
              <InputGroup fill disabled />
            ) : (
              <LocationSuggest
                value={{ value: this.state.updates['location'] || this.props.user?.location }}
                onItemSelect={(item: any) => {
                  console.log(item);
                  this.setState(state => {
                    const updates = {
                      ...state.updates,
                      location: _.pick(item, [
                        'name',
                        'city',
                        'state',
                        'country',
                        'latitude',
                        'longitude',
                        'timezone',
                        'postal_code',
                        'region',
                      ]),
                    };
                    return { updates };
                  });
                }}
                inputProps={{
                  fill: true,
                  onKeyPress: (e: any) => {
                    if (e.charCode === 13) {
                      // press enter
                      e.preventDefault();
                      e.stopPropagation(true);
                    }
                  },
                }}
              />
            )}
          </FormGroupWrapper>
        </div>
        <div className="flex margin-bottom-1">
          <FormGroupWrapper
            label="Payout preference"
            className="margin-right-1 flex-grow-1"
            labelFor="profile-payout-pref"
          >
            <HTMLSelect
              {...this.controlledInput('settings.billing.preferred')}
              id="profile-payout-pref"
              options={_.chain(USER_INCENTIVE_METHODS)
                .mapValues(value => ({ value, label: _.get(LABELS.user, `settings.billing.${value}`, value) }))
                .values()
                .value()}
              fill
            />
          </FormGroupWrapper>
          {this.renderPayoutDetail()}
        </div>
        <div className="flex margin-bottom-1">
          <BooleanStatusField field="meta.family.status" label="Family Status" />
        </div>
        <div className="flex margin-bottom-1">
          <BooleanStatusField field="meta.education" label="Highest level of education" />
        </div>
        <div className="flex margin-bottom-1">
          <BooleanStatusField field="meta.identity.languages.english.speak" label="English speaking level" />
        </div>
        <div>
          <BooleanStatusField field="meta.work.status" label="Work" className="margin-right-1" />
        </div>
        <div className="flex">
          <FormGroupWrapper label="Employment type" className="margin-right-1" labelFor="profile-employment-type">
            <HTMLSelect
              {...this.controlledInput('meta.work.employment_type', value => parseInt(value).toString())}
              id="profile-employment-type"
              options={([{ label: 'N/A' }] as OptionProps[]).concat(
                _.chain(USER_EMPLOYMENT_TYPE)
                  .mapValues(value => ({
                    value,
                    label: _.get(LABELS.user, `meta.work.employment_type.${value}`, value),
                  }))
                  .values()
                  .value(),
              )}
            />
          </FormGroupWrapper>
          <FormGroupWrapper label="Business size" className="margin-right-1" labelFor="profile-business-size">
            <HTMLSelect
              {...this.controlledInput('meta.work.business.size', value => parseInt(value).toString())}
              id="profile-business-size"
              options={([{ label: 'N/A' }] as OptionProps[]).concat(
                _.chain(USER_BUSINESS_SIZE)
                  .mapValues(value => ({ value, label: _.get(LABELS.user, `meta.work.business.size.${value}`, value) }))
                  .values()
                  .value(),
              )}
              fill
            />
          </FormGroupWrapper>
        </div>
        <div className="flex margin-bottom-1">
          <Query<Admin_GetIndustryDataQuery> query={getIndustryData}>
            {({ loading, data }) => {
              const industries = _.get(data, 'industryList', []);
              const industryValue = this.controlledInput('meta.work._industry_id').value;
              const subindustries = _.chain(industries)
                .find(industry => industry?._id === industryValue)
                .get('subcategories', [])
                .value();
              return (
                <Fragment>
                  <FormGroupWrapper
                    label="Industry"
                    className="margin-right-1 flex-shrink-1"
                    labelFor="profile-industry"
                  >
                    <HTMLSelect
                      {...this.controlledInput('meta.work._industry_id')}
                      {...(loading && { disabled: true })}
                      id="profile-industry"
                      options={(industryValue ? [] : [{ value: '', label: '' }]).concat(
                        industries?.map((industry: any) => ({
                          value: industry._id,
                          label: industry.name,
                        })) || [],
                      )}
                      fill
                    />
                  </FormGroupWrapper>
                  <FormGroupWrapper
                    label="Job type"
                    className="margin-right-1 flex-shrink-1"
                    labelFor="profile-subindustry"
                  >
                    <HTMLSelect
                      {...this.controlledInput('meta.work._subindustry_id')}
                      {...((loading || subindustries.length === 0) && { disabled: true })}
                      id="profile-subindustry"
                      options={[{ value: '', label: '(not set)' }].concat(
                        subindustries.map((industry: any) => ({
                          value: industry._id,
                          label: industry.name,
                        })),
                      )}
                      fill
                    />
                  </FormGroupWrapper>
                </Fragment>
              );
            }}
          </Query>
        </div>
        <div className="flex margin-bottom-1">
          <FormGroupWrapper label="LinkedIn public profile" className="flex-grow-1" labelFor="profile-linkedin">
            <InputGroup
              {...this.controlledInput('meta.social.linkedin.profile_url')}
              id="profile-linkedin"
              autoComplete="off"
            />
          </FormGroupWrapper>
        </div>
        <div className="margin-top-3">
          <Button text="Update" type="submit" intent="primary" disabled={!this.canSubmit()} />
        </div>
      </form>
    );
  }
}

export default UpdateProfile;
