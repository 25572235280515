import { Spinner as BpSpinner } from '@blueprintjs/core';

import './styles/askable-spinner.scss';

const Spinner = (props: any) => {
  let WrapperTag = null;
  const wrapperProps = {};

  const spinnerProps = Object.assign({}, props);
  if (props.withText) {
    spinnerProps.size = 18;
    delete spinnerProps.withText;
  }

  if (props.inline) {
    WrapperTag = 'span';
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type '{}'.
    wrapperProps.className = 'askable-spinner-inline';
    delete spinnerProps.inline;
  }

  if (WrapperTag) {
    return (
      // @ts-expect-error ts-migrate(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
      <WrapperTag {...wrapperProps}>
        <BpSpinner {...spinnerProps} />
      </WrapperTag>
    );
  }
  return <BpSpinner {...spinnerProps} />;
};

const SpinnerPage = (props = {}) => (
  <div className="flex-page height-100 flex-justify-center">
    <Spinner {...props} />
  </div>
);

export { Spinner, SpinnerPage };
