import { Classes } from '@blueprintjs/core';
import classnames from 'classnames';
import _ from 'lodash';
import { createElement } from 'react';

import type { FunctionalComponent } from 'types/types';

export function uniqueArray(count: number) {
  return Array(count).fill(null).map(_.uniqueId);
}

type SkeletonWrapperProps = {
  wrap?: boolean;
  tag?: string;
  active?: boolean;
  display?: string;
  length?: string | number;
  className?: string;
};

const SkeletonWrapper: FunctionalComponent<SkeletonWrapperProps> = ({
  children,
  tag = 'span',
  className = '',
  length,
  display = 'auto',
  active = true,
  wrap = false,
}) => {
  const classes = classnames(className, { [Classes.SKELETON]: active, wrap });
  const child = active && length ? _.repeat('—', Number(length)) : children;

  return createElement(
    tag,
    {
      className: classes,
      style: { display },
    },
    child,
  );
};

export default SkeletonWrapper;
