import { gql } from 'urql';

export default gql(`
mutation admin_ResetSubmissionRating($submissionId: ObjectID!) {
  resetParticipantRating(submission_id: $submissionId) {
    _id
    rating {
      overall
    }
  }
}`);
