import gql from 'graphql-tag';

import { BookingPageBookingFields } from '../../fragments/booking/bookingPageBooking';

export default gql`
  mutation admin_bookingPageComment($booking: ID!, $comment: String!) {
    addAdminComment(booking_id: $booking, comment: $comment) {
      ...BookingPageBookingFields
    }
  }
  ${BookingPageBookingFields}
`;
