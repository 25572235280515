import { Classes, TextArea } from '@blueprintjs/core';
import GSM from 'gsm';
import _ from 'lodash';
import { useState } from 'react';

import { utils } from 'lib/utils';

import { AppConsumer } from './appConsumer';
import Suggest from './suggest.view';

import './styles/askable-template-edit.scss';

function TemplateEdit(props: any) {
  const {
    variables,
    templateExtraChars,
    className,
    editorClassName,
    defaultValue,
    onChange,
    onChangeHTML,
    previewClassName,
    context,
    templates,
  } = props;

  const [value, setValue] = useState(defaultValue);
  const [editing, setEditing] = useState(false);
  let textInput: any;

  const getSMSLength = () => {
    let body = value;
    const tags = _.uniq(value.match(/{{.+?}}/g));
    tags.forEach((tagValue: any) => {
      const tag = _.find(variables, variable => variable.prop === tagValue.replace(/^{+|}+$/g, ''));
      if (!tag) return;
      if (tag.length) {
        body = body.replace(tagValue, ''.padStart(tag.length, 'X'));
      } else if (tag.name) {
        body = body.replace(tagValue, tag.name);
      }
    });

    body += templateExtraChars || '';

    return GSM(body);
  };

  const variableTagOutput = (prop: any) => {
    const variable = _.find(variables, _variable => _variable.prop === prop);
    const label = variable ? variable.label : '?';
    return `<span class="${Classes.TAG} ${Classes.INTENT_PRIMARY}" contenteditable="false" data-tag="${prop}" title="${
      variable && variable.section
    } ${label}">${label}</span>`;
  };

  const formatHtmlOutput = (valueParam: any) => {
    let formatted = valueParam;
    formatted = formatted.replace(/{{(.+?)}}/g, (match: any, tag: any) => {
      return variableTagOutput(tag);
    });
    formatted = formatted.replace(/\r*\n/g, '<br>');
    formatted = formatted.replace(/>\s*$/, '>&nbsp;');
    if (formatted === '') {
      formatted = '&nbsp;';
    }
    return formatted;
  };

  const renderTemplateArea = () => {
    const classNames = (className || '').split(' ');

    classNames.push('askable-template-edit');
    classNames.push('margin-bottom-1');
    classNames.push(Classes.INPUT);

    if (editing) {
      if (editorClassName && editorClassName > '') {
        classNames.push(...editorClassName.split(' '));
      }
      classNames.push('askable-template-edit-editor');
      return (
        <TextArea
          className={classNames.join(' ')}
          growVertically
          onChange={event => {
            setValue(event.target.value);
            if (onChange) {
              onChange(event.target.value);
            }
          }}
          onBlur={() => {
            setEditing(false);
            // this.setState(state => {
            //   // @ts-expect-error ts-migrate(2339) FIXME: Property 'editing' does not exist on type 'Readonl... Remove this comment to see the full error message
            //   state.editing = false;
            //   return state;
            // });
            if (onChangeHTML) {
              onChangeHTML(formatHtmlOutput(value));
            }
          }}
          value={value}
          autoFocus
          inputRef={input => {
            textInput = input;
          }}
        />
      );
    }

    if (previewClassName && previewClassName > '') {
      classNames.push(...previewClassName.split(' '));
    }
    classNames.push('askable-template-edit-preview');
    return (
      <div
        className={classNames.join(' ')}
        onClick={() => {
          setEditing(true);
          console.log('textInput', textInput);
          if (textInput && textInput.scrollHeight) {
            textInput.style.height = `${textInput.scrollHeight}px`;
          }
        }}
        dangerouslySetInnerHTML={{
          __html: formatHtmlOutput(value),
        }}
      />
    );
  };

  const renderBodyLength = () => {
    const { sms_count, chars_left } = getSMSLength();
    return (
      <small
        className={[
          Classes.TEXT_SMALL,
          Classes.TEXT_MUTED,
          'align-right',
          'padding-05',
          'margin-top--1',
          'margin-bottom-05',
        ].join(' ')}
      >
        {sms_count} SMS part{sms_count === 1 ? '' : 's'} ({chars_left} character{chars_left === 1 ? '' : 's'} left)
      </small>
    );
  };

  if (onChangeHTML) {
    onChangeHTML(formatHtmlOutput(value));
  }

  return (
    <>
      <div className="flex flex-column">
        {renderTemplateArea()}
        {renderBodyLength()}
      </div>
      <div className="flex flex-row">
        <div className="margin-right-1 flex-grow-1 flex-shrink-1">
          <Suggest
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ resetOnSelect: true; onItemSelect: (item: ... Remove this comment to see the full error message
            resetOnSelect
            onItemSelect={(item: any) => {
              utils.copy(
                `{{${item.value}}}`,
                () => {
                  context.newToast({ intent: 'success', message: `Copied ${item.text} to clipboard` });
                },
                () => {
                  context.newToast({ intent: 'danger', message: `Failed to copy ${item.text}` });
                },
              );
            }}
            items={variables.map((variable: any) => ({
              text: variable.label,
              label: variable.section,
              search: variable.search || `${variable.section}|${variable.label}`,
              value: variable.prop,
            }))}
            inputProps={{
              placeholder: 'Search variables',
            }}
            fill
          />
        </div>
        <div className="flex-grow-1 flex-shrink-1">
          <Suggest
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ resetOnSelect: true; onItemSelect: (item: ... Remove this comment to see the full error message
            resetOnSelect
            onItemSelect={(item: any) => {
              context.openAlert(
                <>
                  <p>Replace the current template?</p>
                  <div
                    className={`askable-template-edit ${Classes.INPUT}`}
                    dangerouslySetInnerHTML={{ __html: formatHtmlOutput(item.value) }}
                  />
                </>,
                'primary',
                {
                  text: 'Set template',
                  callback: () => {
                    setValue(item.value);
                    if (onChange) {
                      onChange(item.value);
                    }
                  },
                },
                { text: 'Cancel' },
              );
            }}
            items={templates.map((variable: any) => ({
              text: variable.label,
              value: variable.value,
              search: `${variable.label}|${variable.value}`,
            }))}
            inputProps={{
              placeholder: 'Search templates',
            }}
            fill
          />
        </div>
      </div>
    </>
  );
}

export default AppConsumer(TemplateEdit);
