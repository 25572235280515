import gql from 'graphql-tag';

export default gql`
  mutation admin_adminLoginAsClient($_id: ID!) {
    adminLoginAsClient(_id: $_id) {
      url
      user {
        _id
        access_token
        type {
          client
          participant
        }
      }
    }
  }
`;
