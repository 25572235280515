import _ from 'lodash';
import moment from 'moment';

const bookingUtils = {
  getAgeRangeFromCriteriaList(criteriaList: any) {
    const min = _.find(criteriaList, config => config.operator === 'lt');
    const max = _.find(criteriaList, config => config.operator === 'gt');
    return [
      min ? Math.round(moment.duration(Date.now() - parseInt(min.value)).asYears()) : null,
      max ? Math.round(moment.duration(Date.now() - parseInt(max.value)).asYears()) : null,
    ];
  },
  getBoolenValuesFromCriteria(criteria: any) {
    const values = _.fromPairs(
      _.filter(criteria, criterion => criterion.value === 'true').map(criterion => [criterion.field, true]),
    );
    return values;
  },
};

export { bookingUtils };
