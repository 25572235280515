import { Query } from '@apollo/client/react/components';
import { Button, AnchorButton } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Fragment } from 'react';

import { SkeletonWrapper, Icon } from 'components/common';
import { CREDIT_ACTIVITY_TYPE, ACCOUNTING_TYPE, TRANSACTIONS_PROVIDER } from 'lib/constants';
import { utils } from 'lib/utils';

function renderPuchaseDetail(credit_activity: any) {
  const details = {
    credits: _.get(credit_activity, 'amount') && credit_activity.amount.toLocaleString(),
    cost: {
      amount: _.get(credit_activity, 'Transaction.total_amount'),
      currency: _.get(credit_activity, 'Transaction.currency'),
    },
    method: null,
  };

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'cost_value' does not exist on type '{ cr... Remove this comment to see the full error message
  details.cost_value = utils.formatCurrency(details.cost.amount, details.cost.currency);

  switch (_.get(credit_activity, 'Transaction.method')) {
    case TRANSACTIONS_PROVIDER.CREDIT_CARD:
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
      details.method = (
        <Fragment>
          {' '}
          via <span className="text-default">Credit&nbsp;Card</span>
        </Fragment>
      );
      break;
    case TRANSACTIONS_PROVIDER.INVOICE:
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
      details.method = (
        <Fragment>
          {' '}
          via <span className="text-default">Invoice</span>
        </Fragment>
      );
      break;
    default:
      details.method = null;
  }

  return (
    <div className="detail-item" key={credit_activity._id}>
      <div className="label">
        <span className="text-muted">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'cost_value' does not exist on type '{ cr... Remove this comment to see the full error message */}
          {details.credits} credits bought{details.method} ({details.cost_value})
        </span>
      </div>
      {_.get(credit_activity, 'Transaction.invoice.xero_id') && (
        <Query
          query={gql`
            query admin_getInvoiceURL($transaction: ID!) {
              getInvoiceURL(_id: $transaction) {
                OnlineInvoiceUrl
              }
            }
          `}
          variables={{ transaction: credit_activity.Transaction._id }}
        >
          {({ loading, data }: any) => {
            if (loading) return <Button loading disabled small minimal />;
            if (_.get(data, 'getInvoiceURL.OnlineInvoiceUrl')) {
              return (
                <div className="action">
                  <AnchorButton
                    minimal
                    intent="primary"
                    href={data.getInvoiceURL.OnlineInvoiceUrl}
                    target="_blank"
                    rightIcon="arrow-right"
                  >
                    Xero
                  </AnchorButton>
                </div>
              );
            }
            return null;
          }}
        </Query>
      )}
    </div>
  );
}

// eslint-disable-next-line react/display-name
export default ({ booking, loading }: any) => {
  const details = {
    usage: null,
    purchases: [],
  };

  if (_.get(booking, 'credit_activity.length') > 0) {
    const usage = _.chain(booking.credit_activity) // Get net credit usage
      .filter(
        ({ type }) =>
          [
            // CREDIT_ACTIVITY_TYPE.ADMIN_MANUAL,
            CREDIT_ACTIVITY_TYPE.CREDIT_USAGE,
            // CREDIT_ACTIVITY_TYPE.CREDIT_PURCHASE,
            // CREDIT_ACTIVITY_TYPE.REFUND_NOSHOW,
            CREDIT_ACTIVITY_TYPE.REFUND_UNFULIILLED,
            CREDIT_ACTIVITY_TYPE.REFUND_CANCELLED_REJECTED,
            // CREDIT_ACTIVITY_TYPE.REFUND_BAD_PARTICIPANT,
            // CREDIT_ACTIVITY_TYPE.REFUND_OTHER,
            CREDIT_ACTIVITY_TYPE.BOOKING_REQUIREMENT_ADJUSTMENT,
            // CREDIT_ACTIVITY_TYPE.FREE_PROMOTIONAL,
            // CREDIT_ACTIVITY_TYPE.EXPIRING_CREDITS,
            CREDIT_ACTIVITY_TYPE.TRANSFER,
          ].indexOf(type) >= 0,
      )
      .map(({ amount, accounting_type }) => {
        if (!amount) return 0;
        switch (accounting_type) {
          case ACCOUNTING_TYPE.CREDIT:
            return amount * -1;
          case ACCOUNTING_TYPE.DEBIT:
            return amount;
          default:
            return 0;
        }
      })
      .sum()
      .value();

    if (usage) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
      details.usage = `${usage.toLocaleString()} credits used`;
      if (usage < 0) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
        details.usage = <span className="text-warning">{details.usage}</span>;
      }
    } else {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
      details.usage = <span className="text-muted">No credits used</span>;
    }

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
    details.purchases = _.filter(
      booking.credit_activity,
      ({ type, Transaction }) => Transaction && type === CREDIT_ACTIVITY_TYPE.CREDIT_PURCHASE,
    );

    if (usage && usage / booking.total_participants !== _.get(booking, 'config.credits_per_participant')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'credits_per_participant_warning' does no... Remove this comment to see the full error message
      details.credits_per_participant_warning = 'Credits per participant and total usage do not match';
    }
  }

  if (_.get(booking, 'config.credits_per_participant')) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'credits_per_participant' does not exist ... Remove this comment to see the full error message
    details.credits_per_participant = `${_.get(booking, 'config.credits_per_participant')} credits per participant`;
  }

  if (loading) {
    return (
      <div className="section">
        <p className="section-heading">Billing</p>
        <div className="detail-item">
          <div className="label">
            <SkeletonWrapper active={loading} length={12} />
          </div>
        </div>
        <div className="detail-item">
          <div className="label">
            <SkeletonWrapper active={loading} length={24} wrap />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="section">
      <p className="section-heading">Billing</p>
      <div className="detail-item">
        <div className="label">{details.usage}</div>
      </div>
      {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'credits_per_participant' does not exist ... Remove this comment to see the full error message */}
      {details.credits_per_participant && (
        <div className="detail-item">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'credits_per_participant' does not exist ... Remove this comment to see the full error message */}
          <div className="label">{details.credits_per_participant}</div>
        </div>
      )}
      {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'credits_per_participant_warning' does no... Remove this comment to see the full error message */}
      {details.credits_per_participant_warning && (
        <div className="detail-item text-warning">
          <div className="icon">
            <Icon icon="warning-sign" />
          </div>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'credits_per_participant_warning' does no... Remove this comment to see the full error message */}
          <div className="label">{details.credits_per_participant_warning}</div>
        </div>
      )}
      {details.purchases.map(renderPuchaseDetail)}
    </div>
  );
};
