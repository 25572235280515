import { Button, Checkbox, Collapse } from '@blueprintjs/core';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Select } from 'components/common';

const PROFICIENCY_OPTIONS = [
  { label: 'Native', value: 'native' },
  { label: 'Fluent', value: 'fluent' },
  { label: 'Conversational', value: 'conversational' },
  { label: 'Beginner', value: 'beginner' },
];
type Fluency = (typeof PROFICIENCY_OPTIONS)[number]['value'];

// All in English instead of native languages so redefining here instead of using the LANGUAGES in @askableorg/i18n package
const LANGUAGE_OPTIONS = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'nl', label: 'Dutch' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
  { value: 'tr', label: 'Turkish' },
  { value: 'da', label: 'Danish' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ar', label: 'Arabic' },
  { value: 'zh-CN', label: 'Chinese (Cantonese)' },
  { value: 'zh-HK', label: 'Chinese (Mandarin)' },
];

type Language = (typeof LANGUAGE_OPTIONS)[number]['value'];

interface LanguageProficiencyProps {
  id: string;
  language: Language;
  proficiencies: Fluency[];
  onChange: (id: string, language: Language, proficiencies: Fluency[]) => void;
  onRemove: (id: string) => void;
}

const LanguageProficiency = ({ id, language, proficiencies, onChange, onRemove }: LanguageProficiencyProps) => {
  const onChangeProficiency = (prof: Fluency) => {
    onChange(
      id,
      language,
      proficiencies.includes(prof) ? proficiencies.filter(p => p !== prof) : [...proficiencies, prof],
    );
  };

  return (
    <div className="tw-my-2">
      <div className="tw-flex tw-gap-2 tw-items-center">
        <Select
          options={LANGUAGE_OPTIONS}
          onChange={lang => onChange(id, lang ?? 'en', proficiencies)}
          value={language}
        />
        <Button minimal small icon="trash" onClick={() => onRemove(id)} intent="danger" />
      </div>
      <div className="tw-flex tw-gap-2 tw-mt-2">
        {PROFICIENCY_OPTIONS.map(prof => (
          <Checkbox
            key={prof.value}
            label={prof.label}
            checked={proficiencies.includes(prof.value)}
            onChange={() => onChangeProficiency(prof.value)}
          />
        ))}
      </div>
    </div>
  );
};

interface LanguageProficiency {
  id: string;
  language: Language;
  fluency: Fluency[];
}
export default function LanguagesView({
  languages,
  setLanguages,
}: {
  languages: LanguageProficiency[];
  setLanguages: (languages: LanguageProficiency[]) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onChange = (id: string, lang: Language, profiencies: Fluency[]) => {
    const langIndex = languages.findIndex(l => l.id === id);
    if (langIndex === -1) {
      toast.error('Error updating language proficiency');
    } else {
      const updatedLanguages = languages.map((l, i) =>
        i === langIndex ? { ...l, language: lang, fluency: profiencies } : l,
      );
      setLanguages(updatedLanguages);
    }
  };

  const onRemove = (id: string) => {
    const updatedLanguages = languages.filter(lang => lang.id !== id);
    setLanguages(updatedLanguages);
  };

  return (
    <div className="margin-bottom-1">
      <Button
        minimal
        small
        rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}
        onClick={() => setIsOpen(!isOpen)}
        className="margin-left--1"
      >
        Languages
      </Button>
      <Collapse isOpen={isOpen}>
        {languages.map(lang => (
          <LanguageProficiency
            key={lang.id}
            id={lang.id}
            language={lang.language}
            proficiencies={lang.fluency}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
        <Button
          minimal
          small
          icon="add"
          text="Add language"
          intent="primary"
          onClick={() => {
            setLanguages([
              ...languages,
              { language: 'en', fluency: ['native', 'fluent'], id: new Date().getTime().toString() },
            ]);
          }}
        />
      </Collapse>
    </div>
  );
}
