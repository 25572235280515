import { Query } from '@apollo/client/react/components';
import { Colors } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import { SkeletonWrapper } from '../../common';

const ORG_QUERY = gql`
  query enterpriseWigOrg($id: ID!) {
    organisationById(_id: $id) {
      _id
      name
      AverageBookingRating(days: 60)
    }
  }
`;

const COLOUR_NEUTRAL = Colors.DARK_GRAY5;
const COLOUR_GOOD = Colors.GREEN5;
const COLOUR_BAD = Colors.RED4;

const GOOD_BOOKING_RATING = 4.5;

const stringifyRating = (n: any) => (_.isNumber(n) ? [Math.floor(n), '.', Math.floor((n % 1) * 10)].join('') : '-');

const BookingRating = ({ value }: any) => {
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (stringifyRating(value) !== stringifyRating(displayValue)) {
      // animate the value if new & previous are both numeric
      if (_.isNumber(value) && _.isNumber(displayValue)) {
        setTimeout(setDisplayValue, 40, displayValue + (value - displayValue) * 0.5);
      } else {
        // otherwise set it outright
        setDisplayValue(value);
      }
    }
  }, [value, displayValue]);

  let bg = COLOUR_NEUTRAL;
  if (_.isNumber(value)) {
    bg = value >= GOOD_BOOKING_RATING ? COLOUR_GOOD : COLOUR_BAD;
  }

  return (
    <span className="booking-rating" style={{ backgroundColor: bg }}>
      {stringifyRating(displayValue)}
    </span>
  );
};

const Wig = ({ goal }: any) => {
  const formatWigNumber = (input: any) => {
    let number = parseFloat(input);
    if (Number.isNaN(number)) return '\u00A0';

    const prefix = _.get(goal, 'meta.prefix') || '';

    if (input >= 100000) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
      number = `${Math.round(number / 1000)}k`;
    } else if (input >= 1000) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
      number = `${Math.round(number / 100) / 10}k`;
    }
    return `${prefix}${number.toString(10)}`;
  };

  const barStyle = { COLOUR_GOOD: COLOUR_NEUTRAL };
  const progresBarStyle = { backgroundColor: COLOUR_NEUTRAL };

  if (_.get(goal, 'progress') && _.get(goal, 'value')) {
    if (_.get(goal, 'progress') / _.get(goal, 'value') > 0.02) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type '{ backgro... Remove this comment to see the full error message
      progresBarStyle.width = `${((_.get(goal, 'progress') / _.get(goal, 'value')) * 100).toFixed(2)}%`;
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type '{ backgro... Remove this comment to see the full error message
      progresBarStyle.width = '2%';
    }
  }

  if (_.get(goal, 'progress') >= _.get(goal, 'ghost')) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ COLOUR_... Remove this comment to see the full error message
    barStyle.color = COLOUR_GOOD;
    progresBarStyle.backgroundColor = COLOUR_GOOD;
  } else {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ COLOUR_... Remove this comment to see the full error message
    barStyle.color = COLOUR_BAD;
    progresBarStyle.backgroundColor = COLOUR_BAD;
  }

  const ghostStyle = { display: 'none' };
  if (_.get(goal, 'ghost') && _.get(goal, 'value')) {
    // @ts-expect-error ts-migrate(2790) FIXME: The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
    delete ghostStyle.display;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'left' does not exist on type '{ display:... Remove this comment to see the full error message
    ghostStyle.left = `${((_.get(goal, 'ghost') / _.get(goal, 'value')) * 100).toFixed(2)}%`;
  }

  return (
    <Query query={ORG_QUERY} variables={{ id: goal.id }} pollInterval={1000 * 60 * 5}>
      {(orgQuery: any) => {
        const organisation = _.get(orgQuery, 'data.organisationById') || {};
        return (
          <div
            className={`wig-bar wig-bar-horizontal ${_.get(goal, 'progress') >= _.get(goal, 'value') ? 'completed' : ''}`}
            key={goal.id}
          >
            <div className="text">
              <span className="title">
                <SkeletonWrapper active={!organisation.name} length={_.random(6, 12)}>
                  {organisation.name || ''}
                </SkeletonWrapper>
              </span>
              <span className="numbers">
                <span className="progress">{formatWigNumber(_.get(goal, 'progress'))}</span>
                <span className="goal">{formatWigNumber(_.get(goal, 'value'))}</span>
              </span>
            </div>
            <div className="widgets">
              {/* @ts-expect-error ts-migrate(2559) FIXME: Type '{ COLOUR_GOOD: string; }' has no properties ... Remove this comment to see the full error message */}
              <div className="bar" style={barStyle}>
                <div className="progress" style={progresBarStyle} />
                <div className="ghost" style={ghostStyle}>
                  {_.get(goal, 'progress') > _.get(goal, 'ghost') ? (
                    <span className="emoji killed" role="img" aria-label="Killed">
                      ☠️
                    </span>
                  ) : (
                    <span className="emoji alive" role="img" aria-label="killed">
                      👻
                    </span>
                  )}
                </div>
              </div>
              <BookingRating value={_.get(organisation, 'AverageBookingRating')} />
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default Wig;
