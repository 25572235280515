import { Classes, Navbar, NavbarGroup } from '@blueprintjs/core';
import classnames from 'classnames';
import { useLocation } from 'react-router';

import { LinkButton } from 'components/common';

import type { Alignment } from '@blueprintjs/core';
import type { FunctionalComponentWithChildren } from 'types/types';

import './styles/askable-navbar.scss';

export type AskableNavBarItem = {
  key?: string;
  title?: string;
  icon?: string;
  rightIcon?: string;
  path?: string;
  component?: JSX.Element;
  props?: any;
  className?: string;
  active?: boolean;
  onClick?: any;
  align?: Alignment;
};

type AskableNavbarProps = {
  id?: string;
  items: AskableNavBarItem[];
  className?: string;
};

const AskableNavbar: FunctionalComponentWithChildren<AskableNavbarProps> = ({ className, id, children, items }) => {
  const location = useLocation();
  const classes = classnames(
    'askable-navbar tw-border-b tw-border-gray-600',
    Classes.TEXT_SMALL,
    Classes.TEXT_MUTED,
    className,
  );

  return (
    <Navbar id={id} className={classes}>
      {(items || []).map(
        ({ key, title, className: itemClassName, icon, rightIcon, path, component, props, active, ...navProps }) => {
          const isActive = active || (path && location.pathname.match(new RegExp(`^${key ? `/${key}` : path}`)));

          const itemClasses = classnames(itemClassName?.split(' '), { 'nav-active': isActive });

          return (
            <NavbarGroup key={key || title} className={itemClasses} {...navProps}>
              {component || <LinkButton minimal {...props} icon={icon} rightIcon={rightIcon} text={title} to={path} />}
            </NavbarGroup>
          );
        },
      )}
      {children}
    </Navbar>
  );
};

export default AskableNavbar;
