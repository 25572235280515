import { cloneElement, Component, Fragment } from 'react';

class MultiFieldSet extends Component {
  fieldChild: any;

  fieldCount: any;

  constructor(props: any) {
    super(props);

    this.fieldCount = parseInt(props.fields, 10);
    if (!this.fieldCount || typeof this.fieldCount !== 'number') {
      this.fieldCount = 1;
    }

    this.fieldChild = props.children;
    if (this.fieldChild.length) {
      console.warn('MultiFieldSet is intended for use with a single child. Other children have been ignored.');
      [this.fieldChild] = this.fieldChild;
    }

    this.state = {
      values: new Array(this.fieldCount).fill(null),
    };

    this.updateFieldHandler = this.updateFieldHandler.bind(this);
  }

  componentWillMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.values) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.setState({ values: this.props.values });
    }
  }

  componentWillReceiveProps(newProps: any) {
    if (newProps.values) {
      this.setState({ values: newProps.values });
    }
  }

  updateFieldHandler(i: any, value: any) {
    this.setState(state => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
      state.values[i] = value;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onUpdate' does not exist on type 'Readon... Remove this comment to see the full error message
      if (this.props.onUpdate) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onUpdate' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.onUpdate(state.values);
      }
      return state;
    });
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'wrapperTag' does not exist on type 'Read... Remove this comment to see the full error message
    const WrapperTag = this.props.wrapperTag || 'td';
    return (
      <Fragment>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message */}
        {this.state.values.map((value: any, i: any) => {
          const childProps = {};
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'childProps' does not exist on type 'Read... Remove this comment to see the full error message
          for (const prop in this.props.childProps) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'childProps' does not exist on type 'Read... Remove this comment to see the full error message
            if (this.props.childProps[prop].length) {
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              childProps[prop] = this.props.childProps[prop][i];
            } else {
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              childProps[prop] = this.props.childProps[prop];
            }
          }
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'callbacks' does not exist on type 'Reado... Remove this comment to see the full error message
          for (const callback in this.props.callbacks) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            childProps[callback] = (...args: any[]) => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'callbacks' does not exist on type 'Reado... Remove this comment to see the full error message
              this.updateFieldHandler(i, this.props.callbacks[callback](...args));
            };
          }
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'values' does not exist on type 'Readonly... Remove this comment to see the full error message
          if (this.state.values && this.state.values[i]) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type '{}'.
            childProps.value = this.state.values[i];
          }
          return (
            <Fragment key={`MultiFieldSet-field-${i}`}>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'separator' does not exist on type 'Reado... Remove this comment to see the full error message */}
              {i === 0 ? null : this.props.separator}

              <WrapperTag
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'wrapperProps' does not exist on type 'Re... Remove this comment to see the full error message
                {...this.props.wrapperProps}
              >
                {cloneElement(this.fieldChild, childProps)}
              </WrapperTag>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }
}

export default MultiFieldSet;
