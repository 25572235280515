import { Classes, Alert, Button } from '@blueprintjs/core';
import { cloneElement, createContext, PureComponent, useContext } from 'react';
import { toast } from 'react-toastify';

import type { PropsWithChildren } from 'react';

type ToastPayload = { intent: 'success' | 'danger'; message: string };

export type AppContext = {
  newToast: (a: ToastPayload) => void;
  openSidebar: (v: any) => void;
  closeDialog: () => void;
  closeSidebar: () => void;
  openAlert: (children: any, intent: string, confirm?: any, cancel?: any) => void;
};
// Create a new context for the app
export const AppContext = createContext<AppContext | undefined>(undefined);

export function useAppContext() {
  const ctx = useContext(AppContext);

  if (ctx === undefined) {
    throw new Error('useAppContext cannot be used outside of AppProvider');
  }

  return ctx;
}

// Creates a provider Component
class AppProvider extends PureComponent<PropsWithChildren<any>> {
  defaults: any;
  sidebar: any;
  constructor(props: any) {
    super(props);

    this.defaults = {
      sidebarWidth: 480,
    };

    this.state = {
      sidebar: {
        open: false,
        width: 0,
        props: {},
        children: null,
      },
      alert: {
        props: {
          isOpen: false,
        },
        children: null,
      },
    };

    this.openSidebar = this.openSidebar.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
    this.sidebarTransitionEnd = this.sidebarTransitionEnd.bind(this);
    this.openAlert = this.openAlert.bind(this);
  }

  componentDidMount() {
    this.sidebar = document.getElementById('askable-sidebar');
    if (this.sidebar) {
      this.sidebar.addEventListener('transitionend', this.sidebarTransitionEnd, false);
    }
  }

  newToast(toastPayload: { intent: 'success' | 'danger'; message: string }) {
    switch (toastPayload.intent) {
      case 'success':
        toast.success(toastPayload.message);
        return;
      case 'danger':
        toast.error(toastPayload.message);
        break;
      default:
        toast.error(`Toast intent not implemented ${toastPayload.intent}`);
    }
  }

  openAlert(children: any, intent = 'default', confirm: any, cancel: any) {
    const confirmProps = {};
    if (confirm) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmButtonText' does not exist on typ... Remove this comment to see the full error message
      confirmProps.confirmButtonText = confirm.text;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onConfirm' does not exist on type '{}'.
      confirmProps.onConfirm = confirm.callback;
    }
    const cancelProps = {};
    if (cancel) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'cancelButtonText' does not exist on type... Remove this comment to see the full error message
      cancelProps.cancelButtonText = cancel.text;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onCancel' does not exist on type '{}'.
      cancelProps.onCancel = cancel.callback;
    }
    this.setState({
      alert: {
        props: {
          isOpen: true,
          intent,
          ...confirmProps,
          ...cancelProps,
          onClose: () => {
            this.setState(state => ({
              ...state,
              alert: {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'alert' does not exist on type 'Readonly<... Remove this comment to see the full error message
                ...state.alert,
                props: {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'alert' does not exist on type 'Readonly<... Remove this comment to see the full error message
                  ...state.alert.props,
                  isOpen: false,
                },
              },
            }));
          },
        },
        children,
      },
    });
  }

  openSidebar(sidebar: any) {
    sidebar.open = true;
    if (sidebar.width === undefined) sidebar.width = this.defaults.sidebarWidth;
    if (sidebar.closeButton === undefined) sidebar.closeButton = true;
    this.setState({ sidebar });
  }

  closeSidebar() {
    this.setState({
      sidebar: {
        props: {},
        open: false,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message
        children: this.state.sidebar.children && cloneElement(this.state.sidebar.children),
      },
    });
  }

  sidebarTransitionEnd(event: any) {
    if (event.target !== this.sidebar) return;
    if (event.target.clientWidth === 0) {
      this.setState(state => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message
        state.sidebar.children = null;
        return state;
      });
    }
  }

  closeDialog = () => {
    this.setState(state => ({
      ...state,
      alert: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'alert' does not exist on type 'Readonly<... Remove this comment to see the full error message
        ...state.alert,
        props: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'alert' does not exist on type 'Readonly<... Remove this comment to see the full error message
          ...state.alert.props,
          isOpen: false,
        },
      },
    }));
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          closeDialog: this.closeDialog,
          newToast: this.newToast,
          openSidebar: this.openSidebar,
          closeSidebar: this.closeSidebar,
          openAlert: this.openAlert,
        }}
      >
        {this.props.children}

        <div
          id="askable-sidebar"
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message
          {...this.state.sidebar.props}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message
          style={{ width: this.state.sidebar.open ? `${this.state.sidebar.width}px` : 0 }}
        >
          <div
            className={`backdrop ${Classes.OVERLAY_BACKDROP}`}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message
            onClick={this.state.sidebar.onClose || this.closeSidebar}
            onKeyPress={() => {}}
            style={{
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message
              display: this.state.sidebar.open ? 'unset' : 'none',
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message
              opacity: this.state.sidebar.open ? 1 : 0,
            }}
          />
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message */}
          <div className="wrapper" style={{ width: `${this.state.sidebar.width}px` }}>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message */}
            <div className="scroll-container">{this.state.sidebar.children}</div>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message */}
            {this.state.sidebar.closeButton && (
              <div id="askable-sidebar-close-button">
                <Button
                  className="tw-z-20"
                  large
                  minimal
                  icon="cross"
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'sidebar' does not exist on type 'Readonl... Remove this comment to see the full error message
                  onClick={this.state.sidebar.onClose || this.closeSidebar}
                />
              </div>
            )}
          </div>
        </div>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'alert' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
        <Alert {...this.state.alert.props} className={Classes.DARK}>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'alert' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          {this.state.alert.children}
        </Alert>
      </AppContext.Provider>
    );
  }
}

export default AppProvider;
