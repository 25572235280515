/* eslint-disable max-lines */
import { Mutation } from '@apollo/client/react/components';
import {
  Button,
  Checkbox,
  FormGroup,
  H3,
  HTMLSelect,
  Icon,
  InputGroup,
  NumericInput,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import gql from 'graphql-tag';
import moment from 'moment';
import { Component, Fragment } from 'react';

import { AppConsumer, ErrorCallout, Spinner } from 'components/common';
import { COUPON_DISCOUNT_TYPE } from 'lib/constants';
import '../styles/coupon.scss';

const dateFormatter = {
  formatDate: (date: any) => moment(date).format('DD MMMM YYYY'),
  parseDate: (str: any) => moment(str, 'DD MMMM YYYY').toDate(),
  placeholder: 'D MMM YYYY',
};

class CouponCreate extends Component {
  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = {
      discount_types: [
        {
          value: COUPON_DISCOUNT_TYPE.DOLLARS,
          label: 'Fixed dollar amount',
          disabled: false,
        },
        {
          value: COUPON_DISCOUNT_TYPE.PERCENT,
          label: 'Percentage',
          disabled: false,
        },
        {
          value: COUPON_DISCOUNT_TYPE.CREDITS,
          label: 'Credits',
          disabled: true,
        },
      ],
      fields: {
        code: '',
        discount_type: 1,
        discount_value: 0,
        show_limit: true,
        limit_uses: 1,
        start_date: moment().startOf('day').toDate(),
        show_end_date: false,
        end_date: moment().add(1, 'month').endOf('day').toDate(),
        checkout_restrict: false,
        checkout_amount: null,
        limit_1_per_customer: true,
      },
    };

    this.createCoupon = this.createCoupon.bind(this);
    this.setDiscountValue = this.setDiscountValue.bind(this);
    this.setUsageLimit = this.setUsageLimit.bind(this);
    this.renderCustomerEligibility = this.renderCustomerEligibility.bind(this);
    this.renderUsageLimits = this.renderUsageLimits.bind(this);
    this.renderPurchaseRestrictions = this.renderPurchaseRestrictions.bind(this);
    this.renderActiveDates = this.renderActiveDates.bind(this);
  }

  setDiscountType(e: any) {
    this.setState({
      fields: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        ...this.state.fields,
        discount_type: parseInt(e.target.value),
      },
    });
  }

  setCouponCode(e: any) {
    const code = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    this.setState({
      fields: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        ...this.state.fields,
        code,
      },
    });
  }

  setDiscountValue(value: any) {
    this.setState({
      fields: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        ...this.state.fields,
        discount_value: value,
      },
    });
  }

  setStartDate(date: any) {
    const d = Number.isNaN(date.getTime()) ? null : date;
    this.setState({
      fields: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        ...this.state.fields,
        start_date: d,
      },
    });
  }

  setEndDate(date: any) {
    this.setState({
      fields: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        ...this.state.fields,
        end_date: date,
      },
    });
  }

  setUsageLimit(value: any) {
    this.setState({
      fields: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        ...this.state.fields,
        limit_uses: value,
      },
    });
  }

  setDealImage(e: any) {
    // console.log('value', e);
    this.setState({
      fields: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        ...this.state.fields,
        campaign_image: e.target.value,
      },
    });
  }

  createCoupon(mutation: any) {
    const errors = [];
    // Validate discount code
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (!this.state.fields.code.trim()) {
      errors.push('Discount code is required');
    }

    // Validate discount type and value
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    const value = parseFloat(this.state.fields.discount_value || 0);
    if (Number.isNaN(value)) {
      errors.push('Not a valid discount value');
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    } else if (this.state.fields.discount_type === COUPON_DISCOUNT_TYPE.PERCENT) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
      if (this.state.fields.discount_value <= 0 || this.state.fields.discount_value > 100) {
        errors.push('Discount percentage must be between 1 and 100');
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    } else if (this.state.fields.discount_value <= 0) {
      errors.push('Discount amount must be more than $0');
    }

    // Validate usage limits
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    const uses = parseInt(this.state.fields.limit_uses);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.state.fields.show_limit && (!uses || uses <= 0)) {
      errors.push('Number of uses must be more than 0');
    }

    if (errors.length > 0) {
      let errorMessageContent = errors[0];
      if (errors.length > 1) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
        errorMessageContent = (
          <ul className="margin-0 padding-left-2">
            {errors.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        );
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.props.context.newToast({ intent: 'danger', message: errorMessageContent });
      return;
    }

    const coupon = {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
      code: this.state.fields.code,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
      discount_type: this.state.fields.discount_type,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
      discount_value: this.state.fields.discount_value,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
      allow_reuse: !this.state.fields.limit_1_per_customer,
      validity: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        start: this.state.fields.start_date.valueOf(),
      },
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
      campaign_image: this.state.fields.campaign_image,
    };

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.state.fields.show_end_date && this.state.fields.end_date) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'end' does not exist on type '{ start: an... Remove this comment to see the full error message
      coupon.validity.end = this.state.fields.end_date.valueOf();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.state.fields.limit_uses) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'redeem_limit' does not exist on type '{ ... Remove this comment to see the full error message
      coupon.redeem_limit = this.state.fields.limit_uses;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.state.fields.checkout_amount) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'checkout_amount' does not exist on type ... Remove this comment to see the full error message
      coupon.validity.checkout_amount = this.state.fields.checkout_amount;
    }

    mutation({ variables: { coupon } });
  }

  renderUsageLimits() {
    return (
      <FormGroup label="Usage limits">
        <Checkbox
          label="Limit number of times this coupon can be used in total"
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
          checked={this.state.fields.show_limit}
          onChange={e => {
            this.setState({
              fields: {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
                ...this.state.fields,
                show_limit: e.target.checked,
                limit_uses: e.target.checked ? 1 : null,
              },
            });
          }}
        />
        {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
          this.state.fields.show_limit ? (
            <NumericInput
              min={1}
              minorStepSize={1}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
              value={this.state.fields.limit_uses}
              onValueChange={this.setUsageLimit}
              className="margin-left-1"
            />
          ) : (
            ''
          )
        }

        <Checkbox
          label="Limit to one use per customer"
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
          checked={this.state.fields.limit_1_per_customer}
          onChange={e => {
            this.setState({
              fields: {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
                ...this.state.fields,
                limit_1_per_customer: e.target.checked,
              },
            });
          }}
        />
      </FormGroup>
    );
  }

  renderCustomerEligibility() {
    const show = false;
    return show ? (
      <FormGroup label="Customer eligibility">
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'onChange' is missing in type '{ children... Remove this comment to see the full error message */}
        <RadioGroup selectedValue="all">
          <Radio disabled label="Anyone" value="all" checked />
          <Radio disabled label="Specific team" value="team" />
          <Radio disabled label="Specific person" value="user" />
        </RadioGroup>
      </FormGroup>
    ) : (
      ''
    );
  }

  renderPurchaseRestrictions() {
    // <RadioGroup selectedValue="all">
    //     <Radio disabled label="Any credits" value="all" checked />
    //     <Radio disabled label="Credit pack only" value="pack" />
    //     <Radio disabled label="Ad-hoc only" value="adhoc" />
    // </RadioGroup>
    return (
      <FormGroup label="Purchase restriction">
        <Checkbox
          label="Minimum order size"
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
          checked={this.state.fields.checkout_restrict}
          onChange={e => {
            const { checked } = e.target;
            this.setState(state => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
              state.fields.checkout_restrict = checked;
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
              state.fields.checkout_amount = null;
              return state;
            });
          }}
        />
        {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
          this.state.fields.checkout_restrict ? (
            <NumericInput
              buttonPosition="none"
              min={0}
              onValueChange={value => {
                this.setState(state => {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
                  state.fields.checkout_amount = value;
                  return state;
                });
              }}
              leftIcon="dollar"
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
              value={this.state.fields.checkout_amount || ''}
              className="margin-left-1"
              stepSize={100}
              majorStepSize={1000}
              minorStepSize={1}
            />
          ) : (
            ''
          )
        }
      </FormGroup>
    );
  }

  renderActiveDates() {
    const formGroup = (
      <FormGroup label="Active dates" className="dis-dates">
        <DateInput
          {...dateFormatter}
          onChange={value => this.setStartDate(value)}
          onError={value => this.setStartDate(value)}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
          defaultValue={this.state.fields.start_date}
        />
        <Checkbox
          label="Set end date"
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
          checked={this.state.fields.show_end_date}
          onChange={e => {
            const { checked } = e.target;
            this.setState(state => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
              state.fields.show_end_date = checked;
              return state;
            });
          }}
        />
        {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
          this.state.fields.show_end_date ? (
            <DateInput
              {...dateFormatter}
              onChange={value => this.setEndDate(value)}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
              defaultValue={this.state.fields.end_date}
            />
          ) : (
            ''
          )
        }
      </FormGroup>
    );
    return formGroup;
  }

  renderDiscountValueInput() {
    const inputProps = {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
    switch (this.state.fields.discount_type) {
      case COUPON_DISCOUNT_TYPE.DOLLARS:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'max' does not exist on type '{}'.
        inputProps.max = 100000;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'leftIcon' does not exist on type '{}'.
        inputProps.leftIcon = 'dollar';
        break;
      case COUPON_DISCOUNT_TYPE.PERCENT:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'max' does not exist on type '{}'.
        inputProps.max = 100;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'rightElement' does not exist on type '{}... Remove this comment to see the full error message
        inputProps.rightElement = <Icon icon="percentage" className="right-icon" />;
        break;
      default:
    }
    return (
      <NumericInput
        id="discount-value"
        buttonPosition="none"
        min={0}
        {...inputProps}
        onValueChange={this.setDiscountValue}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
        value={this.state.fields.discount_value || ''}
      />
    );
  }

  renderCreateCouponMutationResponse({ data, error }: any) {
    if (error) {
      return <ErrorCallout error={error} callout={{ className: 'margin-bottom-4' }} />;
    }
    if (data) {
      return window.location.replace(`/tools/coupon/${data?.createCoupon?.code}`);
    }
    return null;
  }

  render() {
    return (
      <div className="content-page" id="dis-create">
        <H3>Create discount code</H3>
        <Mutation
          mutation={gql`
            mutation admin_createCoupon($coupon: CouponInput!) {
              createCoupon(coupon: $coupon) {
                _id
                code
              }
            }
          `}
        >
          {(mutation: any, mutationResult: any) => {
            if (mutationResult.loading) {
              return <Spinner />;
            }
            return (
              <Fragment>
                {this.renderCreateCouponMutationResponse(mutationResult) || <div />}

                <form
                  id="dis-fields"
                  onSubmit={event => {
                    event.preventDefault();
                    this.createCoupon(mutation);
                  }}
                >
                  <FormGroup
                    className="dis-code"
                    label="Discount code"
                    helperText="Discount codes are NOT case sensitive"
                  >
                    <InputGroup
                      id="discount-code"
                      placeholder="e.g QANTASFREE"
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
                      value={this.state.fields.code}
                      onChange={(e: any) => this.setCouponCode(e)}
                    />
                  </FormGroup>
                  <div id="dis-value">
                    <FormGroup label="Discount type">
                      <HTMLSelect
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'discount_types' does not exist on type '... Remove this comment to see the full error message
                        options={this.state.discount_types}
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
                        value={this.state.fields.discount_type}
                        onChange={e => this.setDiscountType(e)}
                      />
                    </FormGroup>
                    <FormGroup label="Discount Value">{this.renderDiscountValueInput()}</FormGroup>
                  </div>
                  {this.renderCustomerEligibility()}
                  {this.renderUsageLimits()}
                  {this.renderPurchaseRestrictions()}
                  {this.renderActiveDates()}

                  <FormGroup
                    className="dis-image"
                    label="Deal image"
                    helperText="Image that will get displayed on the clients app once the user applies the coupon code. Recommended width should be 280px"
                  >
                    <InputGroup
                      id="deal-image"
                      placeholder="e.g https://google.com/sample_image.jpg"
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Readonly... Remove this comment to see the full error message
                      value={this.state.fields.campaign_image}
                      onChange={(e: any) => this.setDealImage(e)}
                    />
                  </FormGroup>
                  <Button intent="success" type="submit">
                    Create Discount
                  </Button>
                </form>
              </Fragment>
            );
          }}
        </Mutation>
      </div>
    );
  }
}

export default AppConsumer(CouponCreate);
