import { useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { type ColumnWithLooseAccessor } from 'react-table';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';

import { ColumnFilter, VirtualisedTable } from 'components/table/VirtualisedTable';
import { type TeamsUsersType } from 'generated/graphql';

import { TeamUsersListQuery } from '../data/TeamUsersList.query';

export const TeamUserList = () => {
  const { id } = useParams<{ id: string }>();

  invariant(id, 'Team ID is required');

  const [{ data, fetching }] = useQuery({
    query: TeamUsersListQuery,
    variables: { id },
  });

  const memoData = useMemo(() => {
    // Filters out users that are not active within their teams
    return data?.teamById?.users?.filter(user => user?.status !== 0) ?? [];
  }, [data]);

  const columns = useMemo<readonly ColumnWithLooseAccessor<TeamsUsersType>[]>(() => {
    return [
      {
        Header: 'Name',
        accessor: row => row.User?.displayName,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;

          return <Link to={`/user/${original?._id}`}>{original?.User?.displayName}</Link>;
        },
      },
      {
        Header: 'Email',
        accessor: row => row.User?.email,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        disableSortBy: true,
      },
      {
        Header: 'ID',
        accessor: row => row._id,
        disableSortBy: true,
      },
    ];
  }, [memoData]);

  return (
    <VirtualisedTable
      isLoading={fetching}
      data={memoData as any}
      columns={columns}
      initialSortBy={[{ id: 'displayName', desc: false }]}
    />
  );
};
