/* eslint-disable max-lines */
import { graphql } from '@apollo/client/react/hoc';
import { Classes, NonIdealState } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _, { flowRight as compose } from 'lodash';
import moment from 'moment';
import { Fragment, Component } from 'react';

import { DataTable, ErrorCallout } from 'components/common';
import { LABELS } from 'lib/constants';
import { formatIncentive } from 'utils/currency-utils';

class TransactionsTable extends Component {
  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = {
      loading: true,
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    if (state.rows && props.timezone !== state.timezone) {
      state.rows = null;
    }
    if (!state.rows && _.get(props, 'fetchTransactions.participantSessionsWithTransactions')) {
      state.rows = props.fetchTransactions.participantSessionsWithTransactions
        .map((booking_participant: any) => {
          const row = {
            ...booking_participant,
            sort: {},
            filters: [],
          };

          if (!row.booking) {
            row.booking = { _id: -1, name: '???' };
          }

          const transaction = booking_participant?.transaction?.transactions;
          const formattedIncentive = formatIncentive(
            transaction
              ? { currency_code: transaction.currency, value: transaction.total_amount }
              : booking_participant?.Submission?.incentive,
          );
          row.amount = formattedIncentive;

          row.moment = moment(
            _.get(
              booking_participant,
              'transaction.transactions.created',
              _.get(booking_participant, 'transaction.payment_due', false),
            ),
          );
          if (!row.moment.isValid()) {
            row.date = null;
          } else if (row.moment.isAfter(new Date())) {
            row.date = (
              <Fragment>
                <span>{moment.duration(row.moment.diff(moment())).humanize(true)}</span>
                <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                  {row.moment.format('D MMM, h:mm A')}
                </small>
              </Fragment>
            );
          } else {
            row.date = (
              <Fragment>
                <span>{row.moment.format('D MMM YYYY')}</span>
                <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>{row.moment.format('h:mm A')}</small>
              </Fragment>
            );
          }

          row.session_time =
            row.booking.type === 3
              ? [moment(row.session.start).format('D MMM YYYY')]
              : [
                  `${moment(row.session.start).format('h:mm A')} - ${moment(row.session.end).format('h:mm A')}`,
                  moment(row.session.start).format('D MMM YYYY'),
                ];

          if (_.get(row, 'transaction.transactions.method')) {
            const status_text = `Paid via ${_.get(LABELS.transactions, `method.${_.get(row, 'transaction.transactions.method')}`, '?')}`;
            let status_reference = _.get(row, 'transaction.transactions.provider.reference', null);
            switch (_.get(row, 'transaction.transactions.provider.name')) {
              case 'paypal':
                status_reference = (
                  <a
                    href={`https://www.paypal.com/activity/masspay/MPA-${status_reference}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {status_reference}
                  </a>
                );
                break;
              default:
            }

            row.payment_status = (
              <Fragment>
                <span>{status_text}</span>
                <code className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>{status_reference}</code>
              </Fragment>
            );
            row.filters = ['paid'];
            row.sort.status = `0 : ${status_text} : ${_.get(row, 'transaction.transactions.provider.reference', '')}`;
          } else if (_.get(row, 'transaction._id')) {
            row.payment_status = (
              <Fragment>
                <span className="text-danger">Failed</span>
                <span className={`text-danger ${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>Method not found</span>
              </Fragment>
            );
            row.filters = ['failed'];
            row.sort.status = '2 : failed: method not found';
          } else if (_.get(row, 'transaction.cancelled')) {
            row.payment_status = (
              <Fragment>
                <span className="text-warning">Cancelled</span>
                {_.get(row, 'transaction.cancel_date') && (
                  <span className={`text-warning ${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                    {moment(row.transaction.cancel_date).format('D MMM, h:mm A')}
                  </span>
                )}
              </Fragment>
            );
            row.filters = ['cancelled'];
            row.sort.status = '4 : cancelled';
          } else if (_.get(row, 'transaction.suspended')) {
            row.payment_status = <span className="text-warning">Suspended</span>;
            row.filters = ['cancelled'];
            row.sort.status = '3 : suspended';
          } else {
            row.payment_status = (
              <Fragment>
                <span>Upcoming</span>
              </Fragment>
            );
            row.filters = ['upcoming'];
            row.sort.status = '1 : upcoming';
          }

          row.sort.amount = row.amount || 0;
          row.sort.date = row.moment.valueOf();
          row.sort.name = row.booking.name.toLowerCase().replace(/[^0-9a-z]/g, '');
          row.sort.format = row.booking.type;
          row.sort.client = [
            _.get(row.booking, 'team.name', ''),
            _.get(row, 'booking.user.meta.identity.firstname'),
            _.get(row, 'booking.user.meta.identity.lastname'),
          ]
            .join('Z')
            .toLowerCase()
            .replace(/[^0-9a-z]/g, '');
          row.sort.session = row.session.start;

          return row;
        })
        .filter((row: any) => row);

      state.loading = false;
      return state;
    }
    return null;
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (this.state.error) {
      return (
        <div className="margin-2">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
          <ErrorCallout error={this.state.error} />
        </div>
      );
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'rows' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const rows = this.state.rows || [];

    return (
      <div id="participant-table-payments">
        <DataTable
          // bordered
          // condensed
          // className="border-bottom"
          striped
          useSettings
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
          loading={this.state.loading}
          loadingRows={6}
          rows={rows}
          filters={{
            props: { className: ['margin-2 margin-left-1', Classes.TEXT_SMALL].join(' ') },
            countRows: true,
            values: [
              { key: 'upcoming', label: 'Upcoming', defaultActive: true },
              { key: 'paid', label: 'Paid' },
              { key: 'failed', label: 'Failed' },
              { key: 'cancelled', label: 'Cancelled' },
            ],
            all: true,
          }}
          noResults={
            <div className="margin-3 margin-top-1">
              <NonIdealState
                icon="th-disconnect"
                title="No results"
                description="It looks like this user hasn’t received any payments"
              />
            </div>
          }
          columns={[
            {
              key: 'amount',
              label: 'Amount',
              render: ({ row }: any) => (row.amount ? `$${row.amount}` : '?'),
              sort: { icon: 'numerical' },
            },
            {
              key: 'status',
              label: 'Status',
              render: 'payment_status',
              sort: true,
            },
            {
              key: 'date',
              label: 'Due',
              render: ({ row }: any) => row.date,
              sort: { icon: 'numerical', direction: 'desc' },
            },
            {
              key: 'name',
              label: 'Booking name',
              clip: 0.12,
              render: ({ row }: any) => (
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'participantAppLinkClick' does not exist ... Remove this comment to see the full error message
                <a href={`/booking/${row.booking._id}`} onClick={this.props.participantAppLinkClick}>
                  {row.booking.name}
                </a>
              ),
              sort: { icon: 'alphabetical' },
            },
            {
              key: 'format',
              label: 'Format',
              render: 'booking_format',
              sort: true,
            },
            {
              key: 'client',
              label: 'Client',
              clip: 0.1,
              render: 'booking_client',
              sort: { icon: 'alphabetical' },
            },
            {
              key: 'session',
              label: 'Session',
              render: ({ row }: any) => (
                <Fragment>
                  <div>{row.session_time[0]}</div>
                  {row.session_time[1] && <small className={Classes.TEXT_MUTED}>{row.session_time[1]}</small>}
                </Fragment>
              ),
              sort: { icon: 'numerical', direction: 'desc' },
            },
            {
              key: 'id',
              label: 'Booking ID',
              render: ({ row }: any) => <code>{row.booking._id}</code>,
            },
          ]}
        />
      </div>
    );
  }
}

const fetchTransactionsQuery = graphql(
  gql`
    query admin_participantSessionsWithTransactions($_user_id: ID!) {
      participantSessionsWithTransactions(_user_id: $_user_id) {
        _id
        cancel
        _booking_id
        _session_id
        _user_id
        Submission {
          _id
          incentive {
            value
            currency_code
            currency_symbol
          }
        }
        booking {
          _id
          name
          type
          team {
            _id
            name
          }
          user {
            _id
            meta {
              identity {
                firstname
                lastname
              }
            }
          }
        }
        transaction {
          _id
          payment_due
          cancel_date
          cancelled
          suspended
          transactions {
            _id
            total_amount
            currency
            status
            method
            provider {
              name
              reference
            }
          }
        }
        session {
          _id
          start
          end
        }
      }
    }
  `,
  {
    name: 'fetchTransactions',
    options: props => ({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type '{}'.
      variables: { _user_id: props.user._id },
      // fetchPolicy: 'network-only'
    }),
  },
);
export default compose(fetchTransactionsQuery)(TransactionsTable);
