import { graphql } from 'gql.tada';

export const TeamBookingsListQuery = graphql(`
  query admin_BookingsByTeam($_id: ID!) {
    bookingsByTeam(_team_id: $_id) {
      _id
      status
      name
      created
    }
  }
`);
