/* eslint-disable max-lines */
import { Alignment, Button, Card, Checkbox, H3, InputGroup, Label, Switch, TextArea } from '@blueprintjs/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import jwt_decode from 'jwt-decode';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PageTitle } from 'components/PageTitle';
import { AskableNavbar, Emoji, PhoneInput, Select, Spinner } from 'components/common';
import ClientAppIframe from 'components/iframe/ClientAppIframe';
import { VStack } from 'components/layout/Stack';
import { VirtualisedTable } from 'components/table/VirtualisedTable';
import { Text } from 'components/ui/Text';
import {
  ResearcherCertificationStatus,
  ResearcherMethods,
  useAdmin_UpdateResearcherMutation,
  useAdminLoginAsResearcherMutation,
  useGetResearcherQuery,
  useProjectsForUserQuery,
  UserMetaUxLength,
} from 'generated/graphql';
import { normalizeText } from 'utils/string-utils';

import { formattedDate } from '../../../utils/date-utils';
import { DeleteUser } from '../components/DeleteUser';

import { ResearcherCurrentStatus } from './ResearchContainer';

import type { Maybe, Project, User } from 'generated/graphql';
import type { FC } from 'react';
import type { ColumnWithLooseAccessor } from 'react-table';

dayjs.extend(relativeTime);

type Props = { researcher: User };

type FormData = {
  firstname: string;
  methods: { [key in ResearcherMethods]: boolean };
  experience: UserMetaUxLength;
  mobile: string | { countryCode?: string; input?: string };
  lastname: string;
  timezone: string;
  email: string;
  linkedin: string;
};

export const RESEARCH_METHOD_LABEL_MAP: { [key in ResearcherMethods]: string } = {
  discovery: 'Interviews',
  longitudinal: 'Longitudinal',
  survey: 'Surveys',
  usability: 'Usability testing',
  competitive_analysis: 'Secondary',
  continuous_ai_moderated: 'Continuous Ai Moderated',
  continuous_researcher_moderated: 'Continuous Researcher Moderated',
};

type ResearcherPrimaryContentProps = { id: string };

function getAppDomain(app: 'clients', userId?: string) {
  switch (app) {
    case 'clients': {
      if (window.location.host.match(/^\w+\.askable\.com/)) {
        if (userId) {
          return `https://iframe-${userId}-app.askable.com`;
        }
        return 'https://iframe-app.askable.com';
      }
      if (window.location.host.match(/^\w+-\w+\.askable\./)) {
        return window.location.origin.replace(/\b\w+-(\w+)\.askable/, 'app-$1.askable');
      }
      if (window.location.host.match(/^localhost/))
        return window.location.origin.replace(/localhost:\d+/, 'localhost:7001');
      return '';
    }
    default:
      return '';
  }
}

const ResearcherApp: FC<ResearcherPrimaryContentProps> = ({ id }) => {
  const [loginDetails, setLoginDetails] = useState<Maybe<{ userId: string; token: string }>>(null);

  const [{ fetching }, login] = useAdminLoginAsResearcherMutation();

  useEffect(() => {
    handleTokenRequest();
  }, []);

  const handleTokenRequest = async () => {
    try {
      const { data } = await login({ id });

      const url = new URL(data?.adminLoginAsResearcher?.url ?? '');
      const args = new URLSearchParams(url.search);

      const parsedToken = args.get('token');

      if (!parsedToken) {
        toast.error('Error fetching URL. Check network activity');
        return;
      }

      const decodedToken = jwt_decode<{ user_id: string }>(parsedToken);

      setLoginDetails({
        token: parsedToken,
        userId: decodedToken.user_id,
      });
    } catch (e) {
      toast.error('Error: Check the console');
      console.error(e);
    }
  };

  return (
    <ClientAppIframe
      fetchingUrl={fetching}
      src={`${getAppDomain('clients', loginDetails?.userId)}/login?token=${loginDetails?.token}`}
    />
  );
};

const ResearcherProjectList: FC<ResearcherPrimaryContentProps> = ({ id }) => {
  const [{ data }] = useProjectsForUserQuery({ variables: { id } });

  const memoedData = useMemo(() => {
    return data?.userByID?.researcher?.projects?.nodes ?? [];
  }, [data]);

  const columns = useMemo<readonly ColumnWithLooseAccessor<Project>[]>(() => {
    return [
      {
        Header: 'Name',
        accessor: row => row.name,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Link to={`/askable-plus/project/${row.original._id}`}>
              <Text>{row.original.name}</Text>
            </Link>
          );
        },
      },
      {
        Header: 'Due date',
        accessor: row => row.askable_plus?.due_date,
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.askable_plus?.due_date
                ? formattedDate({ date: row.original.askable_plus.due_date, format: 'MMM D, YYYY' })
                : 'No due date'}
            </Text>
          );
        },
      },
      {
        Header: 'Created',
        accessor: row => row.created,
        Cell: ({ row }) => {
          return <Text>{formattedDate({ date: row.original.created!, format: 'MMM D, YYYY' })}</Text>;
        },
      },
      {
        Header: 'ID',
        disableSortBy: true,
        accessor: row => row._id,
        Cell: ({ row }) => {
          return <Text>{row.original._id}</Text>;
        },
      },
    ];
  }, []);

  const initialSortBy = useMemo(() => {
    return [{ id: 'Researcher since', desc: true }];
  }, []);

  return <VirtualisedTable initialSortBy={initialSortBy} columns={columns} data={memoedData as any} />;
};

const ResearcherPrimaryContent: FC<ResearcherPrimaryContentProps> = ({ id }) => {
  const [activeSideBarTab, setActiveSideBarTab] = useState<'projects' | 'app'>('projects');

  const navBarItems = useMemo(
    () => [
      {
        title: 'Projects',
        active: activeSideBarTab === 'projects',
        onClick: () => {
          setActiveSideBarTab('projects');
        },
      },
      {
        title: 'App',
        active: activeSideBarTab === 'app',
        onClick: () => {
          setActiveSideBarTab('app');
        },
      },
      {
        title: 'Open in new window',
        className: activeSideBarTab === 'projects' ? 'tw-hidden' : '',
        align: Alignment.RIGHT,
        onClick: () => {
          window.open(`${getAppDomain('clients', id)}`, '_blank');
        },
      },
    ],
    [activeSideBarTab],
  );

  const menuContent = useMemo(() => {
    switch (activeSideBarTab) {
      case 'projects': {
        return <ResearcherProjectList id={id} />;
      }
      case 'app': {
        return <ResearcherApp id={id} />;
      }
      default:
        break;
    }
  }, [activeSideBarTab, id]);

  return (
    <div className="tw-w-full tw-h-full tw-overflow-hidden">
      <AskableNavbar id="random-id" items={navBarItems} />
      <div className="tw-w-full tw-h-full tw-overflow-scroll">{menuContent}</div>
    </div>
  );
};

const ResearcherUpdateProfile: FC<Props> = ({ researcher }) => {
  const [{ fetching }, updateUser] = useAdmin_UpdateResearcherMutation();

  const onSubmit = async (fv: FormData) => {
    try {
      await updateUser({
        _id: researcher._id,
        user: {
          researcher: {
            ux_length: fv.experience,
            methods: (Object.keys(fv.methods) as ResearcherMethods[]).reduce(
              (acc: ResearcherMethods[], curr: ResearcherMethods) => {
                if (!fv.methods[curr]) {
                  return acc;
                }

                return [...acc, curr];
              },
              [],
            ),
          },
          email: fv.email,
          ...(typeof fv.mobile === 'object' && {
            contact: {
              phone: {
                country_code: fv.mobile.countryCode,
                mobile: fv.mobile.input,
              },
            },
          }),
          timezone: fv.timezone,
          meta: {
            ...(fv.linkedin && {
              social: {
                linkedin: {
                  profile_url: fv.linkedin,
                },
              },
            }),
            identity: {
              firstname: fv.firstname,
              lastname: fv.lastname,
            },
          },
        },
      });

      toast.success('Researcher details updated');
    } catch (e) {
      toast.error(`Error updating researcher details: ${JSON.stringify(e)}`);
    }
  };

  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      email: researcher?.email ?? '',
      methods: (Object.keys(ResearcherMethods) as (keyof typeof ResearcherMethods)[]).reduce(
        (acc, curr: keyof typeof ResearcherMethods) => {
          return {
            ...acc,
            [curr!]: researcher.researcher?.methods?.includes(ResearcherMethods[curr]),
          };
        },
        {} as { [key in ResearcherMethods]: boolean },
      ),
      timezone: researcher.timezone ?? '',
      experience: researcher.researcher?.ux_length ?? UserMetaUxLength.OneOrLess,
      mobile: researcher.contact?.phone?.mobile ?? '',
      firstname: researcher?.meta?.identity?.firstname ?? '',
      lastname: researcher?.meta?.identity?.lastname ?? '',
      linkedin: researcher?.meta?.social?.linkedin?.profile_url ?? '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-mt-6">
      <VStack>
        <div className="tw-flex tw-justify-between tw-items-center">
          <Label className="tw-w-full tw-mr-2">
            First name
            <Controller
              control={control}
              name="firstname"
              render={({ field }) => {
                return <InputGroup fill {...field} onChange={v => field.onChange(v)} value={field.value} />;
              }}
            />
          </Label>
          <Label className="tw-w-full tw-ml-2">
            Last name
            <Controller
              control={control}
              name="lastname"
              render={({ field }) => {
                return <InputGroup fill {...field} onChange={v => field.onChange(v)} value={field.value} />;
              }}
            />
          </Label>
        </div>

        <Label>
          Email
          <Controller
            control={control}
            name="email"
            render={({ field }) => {
              return <InputGroup fill {...field} onChange={v => field.onChange(v)} value={field.value} />;
            }}
          />
        </Label>

        <Label>
          Mobile
          <Controller
            control={control}
            name="mobile"
            render={({ field }) => {
              return (
                <PhoneInput
                  {...field}
                  value={typeof field.value === 'string' ? field.value : (field.value.input ?? '')}
                />
              );
            }}
          />
        </Label>

        <Label>
          LinkedIn
          <Controller
            control={control}
            name="linkedin"
            render={({ field }) => {
              return <InputGroup fill {...field} onChange={v => field.onChange(v)} value={field.value} />;
            }}
          />
        </Label>

        <Label>
          Experience
          <Controller
            control={control}
            name="experience"
            render={({ field }) => {
              return (
                <div className="tw-flex tw-items-center">
                  <div className="tw-w-1/2 tw-mr-2">
                    <Select
                      {...field}
                      onChange={v => field.onChange(v)}
                      value={field.value}
                      options={[
                        { label: '< 1 year', value: UserMetaUxLength.OneOrLess },
                        { label: '2 years', value: UserMetaUxLength.Two },
                        { label: '3 years', value: UserMetaUxLength.Three },
                        { label: '4 years', value: UserMetaUxLength.Four },
                        { label: '> 5 years', value: UserMetaUxLength.FiveOrMore },
                      ]}
                    />
                  </div>
                  {researcher?.researcher?.ux_length_as_of && (
                    <Text className="tw-ml-2">as of {dayjs(researcher.researcher.ux_length_as_of).fromNow()}</Text>
                  )}
                </div>
              );
            }}
          />
        </Label>

        <Label>
          Research methods
          <div className="tw-mt-2">
            {(Object.keys(ResearcherMethods) as ResearcherMethods[]).map(r => {
              return (
                <Controller
                  key={r}
                  control={control}
                  name={`methods.${r}`}
                  render={({ field }) => {
                    return <Checkbox {...field} label={RESEARCH_METHOD_LABEL_MAP[r]} checked={field.value} />;
                  }}
                />
              );
            })}
          </div>
        </Label>

        <Label>
          Timezone
          <Controller
            control={control}
            name="timezone"
            render={({ field }) => {
              return <InputGroup fill {...field} onChange={v => field.onChange(v)} value={field.value} />;
            }}
          />
        </Label>

        <div className="tw-mt-6 tw-flex tw-space-x-2">
          <Button loading={fetching} type="submit">
            Update
          </Button>
          <DeleteUser id={researcher._id!} displayName={researcher.displayName!} />
        </div>
      </VStack>
    </form>
  );
};

const ResearcherNotes: FC<{ researcherId: string; notes?: string }> = ({ researcherId, notes }) => {
  const [{ fetching }, handleUpdate] = useAdmin_UpdateResearcherMutation();
  const { handleSubmit, control } = useForm<{ notes: string }>({
    defaultValues: {
      notes: notes ?? '',
    },
  });

  const onSubmit = async (fv: { notes: string }) => {
    try {
      await handleUpdate({
        _id: researcherId,
        user: {
          researcher: {
            admin_notes: fv.notes,
          },
        },
      });
      toast.success('Researcher notes updated');
    } catch (e) {
      toast.error(`Error updating researcher notes: ${JSON.stringify(e)}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-h-full">
      <div className="tw-h-1/4 tw-mb-8">
        <Controller
          name="notes"
          control={control}
          render={({ field }) => {
            return <TextArea {...field} fill style={{ height: '100%' }} />;
          }}
        />
      </div>

      <Button loading={fetching} type="submit">
        Update
      </Button>
    </form>
  );
};

type ResearcherOptionsProps = {
  researcherId: string;
  status?: ResearcherCertificationStatus;
  blacklisted?: boolean;
};

type ResearcherOptionsFormData = {
  status: ResearcherCertificationStatus;
  blacklisted: boolean;
};

const ResearcherOptions: FC<ResearcherOptionsProps> = ({ researcherId, status, blacklisted }) => {
  const [{ fetching }, updateUser] = useAdmin_UpdateResearcherMutation();
  const { handleSubmit, control } = useForm<ResearcherOptionsFormData>({
    defaultValues: {
      status,
      blacklisted,
    },
  });

  const onSubmit = async (fv: ResearcherOptionsFormData) => {
    try {
      await updateUser({
        _id: researcherId,
        user: {
          researcher: {
            certification: {
              status: fv.status,
            },
          },
          blacklist: fv.blacklisted,
        },
      });
      toast.success('Researcher options updated');
    } catch (e) {
      toast.error(`Error updating researcher options: ${JSON.stringify(e)}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Label>
        Status
        <Controller
          control={control}
          name="status"
          render={({ field }) => {
            return (
              <Select
                {...field}
                options={Object.values(ResearcherCertificationStatus)
                  .filter(a => a !== 'in_progress')
                  .map(op => {
                    return {
                      label: normalizeText(op),
                      value: op,
                    };
                  })}
              />
            );
          }}
        />
      </Label>

      <Controller
        control={control}
        name="blacklisted"
        render={({ field }) => {
          return <Switch {...field} value={undefined} checked={field.value} label="Blacklisted" />;
        }}
      />

      <div className="tw-mt-8">
        <Button loading={fetching} type="submit">
          Update
        </Button>
      </div>
    </form>
  );
};

const ResearcherContainer: FC<Props> = ({ researcher }) => {
  const [activeSideBarTab, setActiveSideBarTab] = useState<'update_profile' | 'notes' | 'options'>('update_profile');

  const navBarItems = useMemo(
    () => [
      {
        title: 'Update profile',
        active: activeSideBarTab === 'update_profile',
        onClick: () => {
          setActiveSideBarTab('update_profile');
        },
      },
      {
        title: 'Notes',
        active: activeSideBarTab === 'notes',
        onClick: () => {
          setActiveSideBarTab('notes');
        },
      },
      {
        title: 'Options',
        active: activeSideBarTab === 'options',
        onClick: () => {
          setActiveSideBarTab('options');
        },
      },
    ],
    [activeSideBarTab],
  );

  const menuContent = useMemo(() => {
    switch (activeSideBarTab) {
      case 'update_profile': {
        return <ResearcherUpdateProfile researcher={researcher as User} />;
      }
      case 'notes': {
        return <ResearcherNotes researcherId={researcher._id} notes={researcher.researcher?.admin_notes ?? ''} />;
      }
      case 'options': {
        return (
          <ResearcherOptions
            researcherId={researcher._id}
            blacklisted={researcher.blacklist ?? false}
            status={researcher.researcher?.certification?.status ?? ResearcherCertificationStatus.Ineligible}
          />
        );
      }
      default:
        break;
    }
  }, [activeSideBarTab, researcher]);

  return (
    <div className="tw-overflow-scroll tw-flex tw-w-full">
      <div className="tw-min-w-[26rem] tw-max-w-[30rem] tw-border-r tw-border-gray-600">
        <div className="tw-p-4">
          <Card className="tw-w-full tw-pb-8">
            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <H3>
                {researcher?.meta?.identity?.firstname} {researcher?.meta?.identity?.lastname}
              </H3>
              {researcher.blacklist && <Emoji className="tw-mr-1 tw-text-lg" emoji="☠️" label="Blacklisted" />}
            </div>
            <ResearcherCurrentStatus user={researcher as User} />

            <Text className="tw-mt-4">Total projects: {researcher?.researcher?.projects?.totalCount}</Text>

            <div className="tw-flex tw-items-center">
              <Text className="tw-mt-4">Account age: </Text>
              <Text className="tw-mt-4 tw-ml-2 tw-text-gray-400">{dayjs(researcher?.created).fromNow()}</Text>
            </div>

            <div className="tw-flex tw-items-center">
              <Text className="tw-mt-1">Timezone:</Text>
              <Text className="tw-ml-2 tw-text-gray-400">{researcher?.timezone ?? 'No timezone'}</Text>
            </div>

            <div className="tw-flex tw-justify-between tw-items-center tw-mt-4">
              <Text>{researcher?.contact?.phone?.mobile ?? 'No mobile'}</Text>
              <Text>{researcher?.email}</Text>
            </div>

            <Text className="tw-text-gray-400 tw-mt-4">{researcher?._id}</Text>
          </Card>
        </div>

        <div className="tw-mt-6">
          <AskableNavbar id="another-random-id" items={navBarItems} key="sidebar-menu" />
          <div className="tw-p-4 tw-h-full">{menuContent}</div>
        </div>
      </div>
      <ResearcherPrimaryContent id={researcher._id} />
    </div>
  );
};

export const ResearchContainerWrapper = () => {
  const params = useParams();

  const [{ data, fetching }] = useGetResearcherQuery({
    variables: { id: params.id },
    requestPolicy: 'network-only',
  });

  if (fetching || !data?.userByID) {
    return <Spinner />;
  }

  return (
    <>
      <PageTitle title={data.userByID.displayName!} />
      <ResearcherContainer researcher={data?.userByID as User} />
    </>
  );
};
