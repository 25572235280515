import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ReportsAuth from 'components/app/ReportsAuth';
import App from 'components/app/app.view';
import { Login } from 'components/app/login.view';
import NotFound from 'components/app/notFound.view';
import DashboardSounds from 'components/dashboard-sounds/dashboard-sounds.view';
import Dashboard from 'components/dashboards/enterprise-growth/enterprise-growth-dashboard.view';
import MessagingCustomBatchView from 'components/messaging/messaging.custom-batch.view';
import { SidebarMetrics } from 'components/sidebar-metrics/sidebar-metrics.view';
import WIGPage from 'components/sidebar-metrics/wig-page.view';
import Organisation from 'components/teams/organisation-page.view';
import TeamListTwo from 'components/teams/organisations-list.view';
import TeamsListView from 'components/teams/teams-list.view';
import Teams from 'components/teams/teams.view';
import CouponCreateView from 'components/tools/coupon/coupon-create.view';
import CouponListView from 'components/tools/coupon/coupon-list.view';
import CreditAdjustment from 'components/tools/tools.credit-adjustment.view';
import Tools from 'components/tools/tools.view';
import { WigTwo } from 'components/tools/wig/wig.view';
import UserParticipantView from 'components/user/participant/user.participant.view';
import { AppInfo } from 'containers/AppInfo';
import { AskablePlusContainer } from 'containers/AskablePlus/AskablePlusContainer';
import { AskablePlusListContainer } from 'containers/AskablePlus/AskablePlusListContainer';
import { AskablePlusProjectContainer } from 'containers/AskablePlus/AskablePlusProjectContainer';
import BookingPage from 'containers/Booking/booking-page/booking-page.view';
import BookingListCurrentView from 'containers/Booking/booking.list-current.view';
import { Booking } from 'containers/Booking/booking.view';
import {
  AppliedResearchersTable,
  ProjectContainer,
  ProjectIFrame,
  ProjectPricing,
} from 'containers/Project/ProjectContainer';
import { TeamContainer, TeamTemplatesTable } from 'containers/Teams/TeamContainer';
import { TeamUserList } from 'containers/Teams/components/TeamUserList';
import { TeamsBookingsList } from 'containers/Teams/components/TeamsBookingsList';
import { ShortLinkContainer } from 'containers/Tools/Containers/ShortLinkContainer/ShortLinkContainer';
import { UserClientContainer } from 'containers/Users/Client/UserClientContainer';
import { ClientContainer } from 'containers/Users/ClientContainer';
import { ParticipantContainer } from 'containers/Users/ParticipantContainer';
import { ResearchContainer } from 'containers/Users/Researcher/ResearchContainer';
import { ResearchContainerWrapper } from 'containers/Users/Researcher/ResearcherContainer';
import { UserContainer } from 'containers/Users/UserContainer';
import { UserFallback } from 'containers/Users/UserFallback';

import type { FC } from 'react';

export const AppRoutes: FC<any> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          {/* Booking */}
          <Route path="/booking" element={<Booking />}>
            <Route path="list/current" element={<BookingListCurrentView ignoredTeamBookings={false} />} />
            <Route
              path="list/internal"
              element={<BookingListCurrentView ignoredTeamBookings key="internal-bookings" />}
            />
            <Route path=":_booking_id" element={<BookingPage />} />
            <Route index element={<Navigate to="list/current" />} />
          </Route>

          {/* User paths  */}
          <Route path="/user" element={<UserContainer />}>
            <Route path=":id" element={<UserFallback />} />

            <Route path="client" element={<ClientContainer />} />
            <Route path="client/:id" element={<UserClientContainer />} />
            <Route path="participant" element={<ParticipantContainer />} />
            <Route path="participant/:_user_id" element={<UserParticipantView />} />
            <Route path="researcher" element={<ResearchContainer />} />
            <Route path="researcher/:id" element={<ResearchContainerWrapper />} />

            <Route index element={<Navigate to="clients" />} />
          </Route>

          {/* Askable plus  */}
          <Route path="/askable-plus" element={<AskablePlusContainer />}>
            <Route path="live" element={<AskablePlusProjectContainer />} />
            <Route path="project" element={<AskablePlusListContainer />} />
            <Route path="project/:id" element={<ProjectContainer />}>
              <Route path="app" element={<ProjectIFrame />} />
              <Route path="applicants" element={<AppliedResearchersTable />} />
              <Route path="pricing" element={<ProjectPricing />} />

              <Route index element={<Navigate to="app" />} />
            </Route>
          </Route>

          {/* Teams  */}

          <Route path="/team" element={<Teams />}>
            <Route path="organisation" element={<TeamListTwo />} />
            <Route path="organisation/:id" element={<Organisation />} />

            <Route path="client" element={<TeamsListView key="teams-client" />} />
            <Route path="internal" element={<TeamsListView internal key="teams-internal" />} />
            <Route path=":type/:id" element={<TeamContainer />}>
              <Route path="users" element={<TeamUserList />} />
              <Route path="bookings" element={<TeamsBookingsList />} />
              <Route path="esignature-templates" element={<TeamTemplatesTable />} />

              <Route index element={<Navigate to="users" replace />} />
            </Route>

            <Route index element={<Navigate to="organisation" />} />
          </Route>

          {/* Tools */}
          <Route path="/tools" element={<Tools />}>
            <Route path="coupon" element={<CouponListView />} />
            <Route path="coupon/:code" element={<CouponListView />} />
            <Route path="coupon/create" element={<CouponCreateView />} />
            <Route path="credit-adjustment" element={<CreditAdjustment />} />
            <Route path="short-link" element={<ShortLinkContainer />} />
            <Route path="wig" element={<WigTwo />} />

            <Route path="batch-message" element={<MessagingCustomBatchView />} />

            <Route index element={<Navigate to="coupon" />} />
          </Route>

          <Route path="app-info" element={<AppInfo />} />

          <Route index element={<Navigate to="/booking/list/current" />} />
          <Route path="/reports-auth" element={<ReportsAuth />} />
        </Route>

        <Route path="/login" element={<Login />} />

        {/* Standalone routes */}
        <Route path="/dashboard-sounds" element={<DashboardSounds />} />
        <Route path="/sidebar-metrics" element={<SidebarMetrics standalone />} />
        <Route path="/dashboard/enterprise-growth" element={<Dashboard />} />

        <Route path="/wig">
          <Route path="sales" element={<WIGPage goal="sales" />} />
          <Route path="usage" element={<WIGPage goal="usage" />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
