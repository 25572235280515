import { Classes, Button, Popover, Menu, MenuItem } from '@blueprintjs/core';
import classnames from 'classnames';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';

import { DataTable } from 'components/common';
import { useESignatureTemplatesByTeamQuery } from 'generated/graphql';
import { ESIGNATURE_TEMPLATE_PROVIDER } from 'lib/constants';

import DeleteTemplateDialog from './DeleteTemplateDialog';
import EditTemplateDialog from './EditTemplateDialog';

import type { ESignatureTemplate } from 'generated/graphql';
import type { FC } from 'react';

const Templates: FC = () => {
  const params = useParams<{ id: string }>();

  invariant(params.id, 'Team ID is required');

  const [{ data, fetching, stale }] = useESignatureTemplatesByTeamQuery({ variables: { _team_id: params.id || '' } });

  const [newTemplate, setNewTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState<ESignatureTemplate | null>(null);
  const [deleteTemplate, setDeleteTemplate] = useState<ESignatureTemplate | null>(null);

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        label: 'Title',
        render: 'name',
        sort: true,
      },
      {
        key: 'cc',
        label: 'CC',
        render: 'cc',
      },
      {
        key: 'added',
        label: 'Added',
        render: 'added',
      },
      // {
      //     key: 'added_by',
      //     label: 'Added by',
      //     render: 'added_by',
      // },
      {
        key: 'annature_id',
        label: 'Annature template ID',
        render: 'annature_id',
      },
      {
        key: 'actions',
        label: '',
        // props: {
        //     className: 'tw-text-right',
        // },
        render: 'actions',
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!data?.eSignatureTemplatesByTeam) return [];
    return data.eSignatureTemplatesByTeam
      .map((template: ESignatureTemplate | null) => {
        if (!template?._id) return null;
        const row: {
          _id: string;
          key: string;
          name: string;
          cc: string | null;
          added: JSX.Element | null;
          added_by: JSX.Element | null;
          annature_id: JSX.Element | string | null;
          actions: JSX.Element | null;
          _template: ESignatureTemplate;
        } = {
          _id: template._id,
          key: template._id,
          name: template?.name || '',
          cc: null,
          added: null,
          added_by: null,
          annature_id: null,
          actions: null,
          _template: template,
        };

        if (template?.recipients) {
          row.cc = template.recipients.join(', ');
        }

        if (template?.created) {
          row.added = (
            <>
              <span>{moment(template.created).fromNow()}</span>
              <span className={classnames(Classes.TEXT_MUTED, Classes.TEXT_SMALL)}>
                {moment(template.created).format('D MMM YYYY h:mm A')}
              </span>
            </>
          );
        }

        if (template.provider?.reference && template.provider?.name === ESIGNATURE_TEMPLATE_PROVIDER.ANNATURE) {
          row.annature_id = (
            <a
              className={classnames(Classes.MONOSPACE_TEXT)}
              href={`https://dashboard.annature.com.au/create-template?templateId=${template.provider.reference}`}
              target="_blank"
              rel="noreferrer"
            >
              {template.provider.reference}
            </a>
          );
        }

        row.actions = (
          <span className="align-right">
            <Popover
              minimal
              content={
                <Menu>
                  <MenuItem
                    text="Edit"
                    onClick={() => {
                      setEditTemplate(template);
                    }}
                  />
                  <MenuItem
                    intent="danger"
                    text="Delete"
                    onClick={() => {
                      setDeleteTemplate(template);
                    }}
                  />
                </Menu>
              }
            >
              <Button minimal>
                <FaEllipsisV />
              </Button>
            </Popover>
          </span>
        );

        return row;
      })
      .filter(row => row);
  }, [fetching, stale]);

  return (
    <div className="tw-flex-grow">
      <Button
        intent="primary"
        text="Add new template"
        icon="plus"
        large
        onClick={() => {
          setNewTemplate(true);
        }}
        className="tw-m-4"
      />
      <DataTable borderedHorizontal loadingRows={6} rows={rows} columns={columns} loading={fetching} />
      <EditTemplateDialog
        template={newTemplate ? { _id: null } : editTemplate}
        isNew={newTemplate}
        onClose={() => {
          setNewTemplate(false);
          setEditTemplate(null);
        }}
      />
      <DeleteTemplateDialog
        template={deleteTemplate}
        onClose={() => {
          setDeleteTemplate(null);
        }}
      />
    </div>
  );
};

export default Templates;
