import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { Button, Classes, Divider, FormGroup, H3, H5, H6, InputGroup } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _, { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import { Fragment, useState } from 'react';

import moveBookingToAnotherTeamMutation from '@graphql/mutations/booking/moveBookingToAnotherTeam';
import admin_BookingByID from '@graphql/queries/booking/bookingByID';
import { AppConsumer, ErrorCallout, Icon, SkeletonWrapper, Spinner } from 'components/common';
// Mutation
// Query
import { BOOKING_ONLINE_TYPE, BOOKING_TYPE } from 'lib/constants';

import '../styles/forceCompleteBookingStyles.scss';

function MoveBookingSideBar(props: any) {
  const timezone = _.get(props, 'timezone');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [newTeamValue, setNewTeamValue] = useState('');
  const [newTeam, setNewTeam] = useState('');
  const [moveBookingCreditActivity] = useState(false);

  const onMoveBookingToAnotherTeam = () => {
    setLoadingUpdate(true);
    props
      .moveBookingToAnotherTeam(props._id, newTeamValue, moveBookingCreditActivity)
      .then(() => {
        setLoadingUpdate(false);
        props.onClose();
      })
      .catch((err: any) => {
        setLoadingUpdate(false);
        setErrorUpdate(err.message.replace('GraphQL error: ', ''));
      });
  };

  const renderError = () => {
    return (
      <div className="margin-bottom-4">
        <ErrorCallout error={errorUpdate} />
      </div>
    );
  };

  const IdLookupField = ({ name, label, lookup, required = true, ...inputProps }: any) => {
    const wrapperProps = {
      label: required ? (
        label
      ) : (
        <span>
          {label} <span className={Classes.TEXT_MUTED}>(optional)</span>
        </span>
      ),
      labelFor: `field_${name}`,
    };

    const input = (
      <InputGroup
        value={newTeamValue || ''}
        onChange={(e: any) => {
          setNewTeam('');
          setNewTeamValue(e.target.value);
        }}
        pattern="[0-9a-fA-F]{24}"
        title="Valid ID"
        className="input-width-30"
        required={required}
        {...inputProps}
      />
    );

    let lookupResult = null;
    if (lookup && lookup.query && lookup.resultRender && newTeamValue && newTeamValue.match(/^[0-9a-fA-F]{24}$/)) {
      lookupResult = (
        <div
          className={`margin-left-1 flex flex-column flex-justify-center ${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}
        >
          <Query
            fetchPolicy="cache-and-network"
            {...lookup}
            variables={{ id: newTeamValue }}
            onCompleted={(data: any) => {
              if (lookup.onCompleted) {
                lookup.onCompleted(data);
              }
            }}
            onError={(error: any) => {
              console.error(error);
            }}
          >
            {({ data, loading, error }: any) => {
              if (loading) return <Spinner withText inline tagName="span" />;
              if (error) {
                console.error(error);
                return <Icon icon="error" intent="danger" />;
              }
              return lookup.resultRender(data) || null;
            }}
          </Query>
        </div>
      );
    }

    return (
      <FormGroup {...wrapperProps}>
        <div className="flex flex-row">
          {input}
          {lookupResult}
        </div>
      </FormGroup>
    );
  };

  return (
    <div id="forceCompleteBookingContainer" className="content-page forceCompleteBookingContainer">
      <Query query={admin_BookingByID} variables={{ id: props._id }} fetchPolicy="cache-and-network">
        {({ loading, error, data }: any) => {
          if (loading) {
            return <Spinner />;
          }
          if (error) {
            return <ErrorCallout error={error} />;
          }

          const booking = _.get(data, 'bookingByID');

          const bookingType = () => {
            switch (booking.type) {
              case BOOKING_TYPE.FACE_TO_FACE:
                return (
                  <span>
                    <Icon icon="askable-booking-in-person" color="BOOKING_IN_PERSON" className="margin-right-05" /> In
                    Person
                  </span>
                );
              case BOOKING_TYPE.REMOTE:
                return (
                  <span>
                    <Icon icon="askable-booking-remote" color="BOOKING_REMOTE" className="margin-right-05" /> Remote
                  </span>
                );
              case BOOKING_TYPE.ONLINE:
                switch (_.get(booking, 'config.online_task.type')) {
                  case BOOKING_ONLINE_TYPE.SURVEY:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> Survey
                      </span>
                    );
                  case BOOKING_ONLINE_TYPE.AI_MODERATED:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> AI
                        Moderated
                      </span>
                    );
                  default:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> Online
                        unmoderated
                      </span>
                    );
                }
              case BOOKING_TYPE.LONGITUDINAL:
                return (
                  <span>
                    <Icon
                      icon="askable-booking-longitudinal"
                      color="BOOKING_LONGITUDINAL"
                      className="margin-right-05"
                    />{' '}
                    Longitudinal
                  </span>
                );
              default:
                return '';
            }
          };

          return (
            <Fragment>
              <H5>Move booking to another team</H5>
              {loadingUpdate && <Spinner />}

              <SkeletonWrapper active={loading}>{bookingType()}</SkeletonWrapper>
              <br />
              <H3>
                <SkeletonWrapper active={loading}>{booking.name}</SkeletonWrapper>
              </H3>
              <p className={[Classes.UI_TEXT, Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
                <SkeletonWrapper active={loading}>
                  Current team: <H5>{_.get(booking, 'team.name')}</H5>
                </SkeletonWrapper>
                {booking.status === 5 && (
                  <SkeletonWrapper active={loading}>
                    Completed:{' '}
                    <H6>
                      {_.get(booking, 'history.completed_date')
                        ? moment(_.get(booking, 'history.completed_date')).tz(timezone).format('Do MMM YYYY')
                        : ''}
                    </H6>
                  </SkeletonWrapper>
                )}
              </p>
              <Divider className="margin-bottom-2" />
              {errorUpdate && renderError()}

              <div className="reopenBookingButtonContainer">
                {IdLookupField({
                  name: '_to_team_id',
                  label: 'Move to team',
                  placeholder: 'New team ID',
                  lookup: {
                    query: gql`
                      query credit_adjustment_lookup($id: ID) {
                        teamById(_id: $id) {
                          _id
                          name
                          settings {
                            billing {
                              subscription {
                                credit {
                                  remaining
                                }
                              }
                            }
                          }
                        }
                      }
                    `,
                    resultRender: (teamData: any) => {
                      if (!_.get(teamData, 'teamById')) {
                        return (
                          <span className="text-warning">
                            <Icon icon="error" /> Team not found
                          </span>
                        );
                      }
                      setNewTeam(_.get(teamData, 'teamById._id'));
                      return [<strong key="name">{teamData.teamById.name}</strong>];
                    },
                  },
                })}
                {/*
                            // Disabled because it potentially creates a mess of credit balances not adding up on a given team
                            <Checkbox
                                label="Move booking transaction and credits used"
                                checked={moveBookingCreditActivity}
                                onChange={e => setMoveBookingCreditActivity(e.target.checked)}
                                className="margin-bottom-2"
                            />
                            */}

                <Button
                  className="reopenBookingButton margin-bottom-2"
                  intent="success"
                  onClick={onMoveBookingToAnotherTeam}
                  disabled={!newTeam}
                >
                  Move Booking
                </Button>

                {/* <Callout icon="issue" title="Please note">
                                <p>Some interesting info</p>
                            </Callout> */}
              </div>
            </Fragment>
          );
        }}
      </Query>
    </div>
  );
}

const moveBookingToAnotherTeamMutationContainer = graphql(moveBookingToAnotherTeamMutation, {
  props: ({ mutate }) => ({
    moveBookingToAnotherTeam: (booking_id: any, new_team_id: any, move_credit_activity: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
      mutate({
        variables: { booking_id, new_team_id, move_credit_activity },
      }),
  }),
});

export default compose(moveBookingToAnotherTeamMutationContainer)(AppConsumer(MoveBookingSideBar));
