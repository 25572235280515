import { Dialog, Classes } from '@blueprintjs/core';

import type { DialogProps } from '@blueprintjs/core';
import type { PropsWithChildren, ReactNode } from 'react';

interface CommonDialogProps extends DialogProps {
  dark?: boolean;
  footerActions?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
}

const CommonDialog = (props: PropsWithChildren<CommonDialogProps>) => {
  const footerActions = props.footerActions && (
    <div className={Classes.DIALOG_FOOTER_ACTIONS}>{props.footerActions}</div>
  );

  return (
    <Dialog {...props}>
      {props.body && <div className={Classes.DIALOG_BODY}>{props.body}</div>}
      {props.children}

      {(props.footer || footerActions) && (
        <div className={Classes.DIALOG_FOOTER}>
          {props.footer}
          {footerActions}
        </div>
      )}
    </Dialog>
  );
};

export default CommonDialog;
