import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { PageTitle } from 'components/PageTitle';
import { ColumnFilter, SelectColumnFilter, VirtualisedTable } from 'components/table/VirtualisedTable';
import { Text } from 'components/ui/Text';
import { useAskablePlusProjectsListQuery } from 'generated/graphql';

import { formattedDate } from '../../utils/date-utils';

import type { ColumnWithLooseAccessor } from 'react-table';

const STATUS_MAP = {
  0: 'Draft',
  1: 'Active',
  5: 'Completed',
  7: 'Archived',
};

export const AskablePlusListContainer = () => {
  const [{ data: fetchedData, fetching }] = useAskablePlusProjectsListQuery();

  const columns = useMemo<readonly ColumnWithLooseAccessor<any>[]>(() => {
    return [
      {
        Header: 'Project',
        accessor: row => row.name,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Link to={`/askable-plus/project/${row.original._id}`}>
              <Text>{row.original.name}</Text>
            </Link>
          );
        },
      },
      {
        Header: 'Team',
        accessor: row => row.team?.name,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Link to={{ pathname: `/team/client/${row.original.team?._id}` }} target="_blank" rel="noopener noreferrer">
              <Text>{row.original.team?.name}</Text>
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        accessor: row => row.status,
        Filter: SelectColumnFilter,
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: 'Due date',
        accessor: row => row.askable_plus?.due_date,
        Cell: ({ row }) => {
          if (!row.original.askable_plus?.due_date) {
            return <div>No due date</div>;
          }
          return <Text>{formattedDate({ date: row.original.askable_plus.due_date, format: 'MMM D, YYYY' })}</Text>;
        },
      },
      {
        Header: 'Operational Office',
        accessor: row => row.team?.operational_office,
        Filter: SelectColumnFilter,
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: 'Created',
        accessor: row => row.created,
        Cell: ({ row }) => {
          if (!row.original.created) {
            return <div>No created date.</div>;
          }
          return <Text>{formattedDate({ date: row.original.created, format: 'MMM D, YYYY' })}</Text>;
        },
      },
    ];
  }, []);

  const data = useMemo(() => {
    return (
      fetchedData?.projectsConnection?.nodes?.map(r => {
        return {
          ...r,
          status: STATUS_MAP[r?.status as 0 | 1 | 5 | 7],
        };
      }) ?? []
    );
  }, [fetchedData]);

  const initialSortBy = useMemo(() => {
    return [{ id: 'Name', desc: true }];
  }, []);

  return (
    <div className="tw-h-full tw-w-full">
      <PageTitle title="All projects" />
      <VirtualisedTable
        isLoading={fetching}
        tableHeader={<Text>Total projects: {fetchedData?.projectsConnection?.totalCount ?? 0}</Text>}
        data={data}
        initialSortBy={initialSortBy}
        columns={columns}
      />
    </div>
  );
};
