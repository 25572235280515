import { Classes, AnchorButton } from '@blueprintjs/core';
import _ from 'lodash';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { SkeletonWrapper } from 'components/common';
import { utils } from 'lib/utils';

// eslint-disable-next-line react/display-name
export default ({ booking, loading }: any) => {
  if (loading) {
    return (
      <div className="section">
        <SkeletonWrapper active className="margin-bottom-05">
          Booking owner
        </SkeletonWrapper>
        <div className="detail-item margin-bottom-2">
          <div className="label">
            <SkeletonWrapper active length={9} />
            <SkeletonWrapper active length={8} />
            <SkeletonWrapper active length={11} />
          </div>
        </div>
        <SkeletonWrapper active className="margin-bottom-05">
          Organiser
        </SkeletonWrapper>
        <div className="detail-item">
          <div className="label">
            <SkeletonWrapper active length={9} />
            <SkeletonWrapper active length={8} />
            <SkeletonWrapper active length={11} />
          </div>
        </div>
      </div>
    );
  }

  const contacts = [];
  if (_.get(booking, 'config.contact.UserOrganiser._id'))
    contacts.push({ title: 'Organiser', user: booking.config.contact.UserOrganiser });
  if (_.get(booking, 'config.contact.UserFacilitator._id'))
    contacts.push({ title: 'Facilitator', user: booking.config.contact.UserFacilitator });
  if (_.get(booking, 'user._id')) contacts.push({ title: 'Owner', user: booking.user });

  return (
    <div className="section">
      {_.chain(contacts)
        .groupBy(contact => _.get(contact, 'user._id'))
        .values()
        .value()
        .map(groupedContacts => {
          const contact = {
            title: _.map(groupedContacts, 'title').join(' / '),
            user: _.merge({}, ..._.map(groupedContacts, 'user')),
          };

          const details = {
            user_name:
              `${_.get(contact.user, 'meta.identity.firstname')} ${_.get(contact.user, 'meta.identity.lastname')}`.replace(
                /^\s+|\s+$/g,
                '',
              ),
          };
          if (!details.user_name) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
            details.user_name = <em>(Askable user)</em>;
          }

          return (
            <Fragment key={contact.user._id}>
              <strong className={`${Classes.TEXT_MUTED} margin-bottom-05`}>{contact.title}</strong>
              <div className="detail-item margin-bottom-2">
                <div className="label">
                  <Link to={`/user/${contact.user._id}`}>{details.user_name}</Link>
                  {_.get(contact.user, 'contact.phone.mobile') && (
                    <a
                      href={`tel:${utils.fotmatPhoneNumber(contact.user.contact.phone.mobile, 'E164')}`}
                      className={Classes.TEXT_MUTED}
                    >
                      {utils.fotmatPhoneNumber(contact.user.contact.phone.mobile, 'national')}
                    </a>
                  )}

                  {_.get(contact.user, 'email') && (
                    <span className={Classes.TEXT_MUTED} style={{ wordBreak: 'break-all' }}>
                      {contact.user.email}
                    </span>
                  )}
                </div>
                <AnchorButton
                  minimal
                  small
                  intent="primary"
                  rightIcon="arrow-right"
                  target="_blank"
                  className="action"
                  href={`https://app.intercom.io/a/apps/w5j4obnp/users/segments/all:${window.btoa(
                    JSON.stringify({
                      predicates: [
                        { comparison: 'eq', value: contact.user._id, attribute: 'user_id', type: 'string' },
                        { type: 'role', attribute: 'role', comparison: 'eq', value: 'user_role' },
                      ],
                    }),
                  )}`}
                >
                  Intercom
                </AnchorButton>
              </div>
            </Fragment>
          );
        })}
    </div>
  );
};
