import { graphql } from 'gql.tada';

export const ForceCompleteProjectMutation = graphql(`
  mutation ForceCompleteProject($projectId: ObjectID!) {
    completeProject(project_id: $projectId) {
      _id
      status
    }
  }
`);
