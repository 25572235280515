import { AppConsumer, AskableNavbar } from 'components/common';
import { SidebarMetrics } from 'components/sidebar-metrics/sidebar-metrics.view';

const navItems = (props = {}) => {
  return [
    {
      title: 'Bookings',
      path: '/booking',
    },
    {
      key: 'askable-plus',
      title: 'Askable+',
      path: '/askable-plus/live',
    },
    {
      key: 'user',
      title: 'Users',
      path: '/user/client',
    },
    {
      title: 'Teams',
      path: '/team',
    },
    {
      title: 'Metrics',
      onClick: () => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type '{}'.
        if (!props.context.openSidebar) {
          throw new Error('Sidebar unavailable');
        }
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type '{}'.
        props.context.openSidebar({ children: <SidebarMetrics /> });
      },
    },
    {
      title: 'Tools',
      path: '/tools',
    },
  ];
};

export { navItems };

const NavPrimary = (props: any) => {
  return <AskableNavbar items={navItems(props)} id="nav-primary" className="!tw-border-0" />;
};

export default AppConsumer(NavPrimary);
