/* eslint-disable max-lines */
import { graphql } from '@apollo/client/react/hoc';
import { Button, PopoverPosition, Tooltip } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _, { flowRight as compose } from 'lodash';
import moment from 'moment';
import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { SkeletonWrapper, Spinner } from 'components/common';
import { ACCOUNTING_TYPE, CREDIT_ACTIVITY_TYPE } from 'lib/constants';
import { utils } from 'lib/utils';

class Log extends Component {
  refetchDebounce: any;

  refetchTimeout: any;

  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = {
      events: [],
      sortDirection: 'asc',
      lastRefetch: 0,
    };

    this.refetchDebounce = 3000;

    this.refetchEvents = this.refetchEvents.bind(this);
    this.parseEvents = this.parseEvents.bind(this);
    this.eventItemDetails = this.eventItemDetails.bind(this);
  }

  componentDidMount() {
    this.parseEvents();
  }

  componentDidUpdate(prevProps: any) {
    if (
      (_.get(this.props, 'booking.credit_activity') &&
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
        JSON.stringify(this.props.booking.credit_activity) !==
          JSON.stringify(_.get(prevProps, 'booking.credit_activity', []))) ||
      (_.get(this.props, 'searchLogs.searchLogs') &&
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchLogs' does not exist on type 'Read... Remove this comment to see the full error message
        JSON.stringify(this.props.searchLogs.searchLogs) !==
          JSON.stringify(_.get(prevProps, 'searchLogs.searchLogs', []))) ||
      (_.get(this.props, 'batchCount.batchResults') &&
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'batchCount' does not exist on type 'Read... Remove this comment to see the full error message
        JSON.stringify(this.props.batchCount.batchResults) !==
          JSON.stringify(_.get(prevProps, 'batchCount.batchResults', [])))
    ) {
      this.parseEvents();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'subscriptionUpdated' does not exist on t... Remove this comment to see the full error message
    if (this.props.subscriptionUpdated && this.props.subscriptionUpdated > (prevProps.subscriptionUpdated || 0)) {
      this.refetchEvents();
      this.refetchTimeout = setTimeout(this.refetchEvents, 5000);
      this.refetchTimeout = setTimeout(this.refetchEvents, 10000);
    }
  }

  async refetchEvents() {
    // if (this.refetchTimeout) return null;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'lastRefetch' does not exist on type 'Rea... Remove this comment to see the full error message
    if (this.state.lastRefetch > Date.now() - this.refetchDebounce) {
      // console.log(new Date(), 'Don’t refetch', { lastRefetch: new Date(this.state.lastRefetch), subscriptionUpdated: new Date(this.props.subscriptionUpdated) });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'subscriptionUpdated' does not exist on t... Remove this comment to see the full error message
      if (this.props.subscriptionUpdated > this.state.lastRefetch) {
        // console.log(new Date(), 'Refetch debouncing');
        this.setState({ refetching: true });
        this.refetchTimeout = setTimeout(
          this.refetchEvents,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'subscriptionUpdated' does not exist on t... Remove this comment to see the full error message
          this.refetchDebounce - (this.props.subscriptionUpdated - this.state.lastRefetch),
        );
      }
      return null;
    }
    // console.log(new Date(), 'Do refetch');
    this.setState({ lastRefetch: Date.now(), refetching: true });
    const refetches = [];
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'batchCount' does not exist on type 'Read... Remove this comment to see the full error message
    if (this.props.batchCount.refetch) refetches.push(this.props.batchCount.refetch());
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchLogs' does not exist on type 'Read... Remove this comment to see the full error message
    if (this.props.searchLogs.refetch) refetches.push(this.props.searchLogs.refetch());
    // console.log('refetches', refetches);
    // console.log(new Date(), 'Finished refetch', refetchResults);
    this.setState({ refetching: false });
  }

  parseEvents() {
    // console.log('booking.credit_activity', _.get(this.props, 'booking.credit_activity', []));
    this.setState({
      events: _.chain([
        ..._.get(this.props, 'booking.credit_activity', []),
        ..._.get(this.props, 'searchLogs.searchLogs', []),
        ..._.get(this.props, 'batchCount.batchResults', []),
      ])
        .map(this.eventItemDetails)
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'date' does not exist on type '{ _documen... Remove this comment to see the full error message
        .filter(item => item && item.event && item.event.date && item.component)
        .value(),
    });
  }

  eventItemDetails(item: any) {
    const event = { _document: item };
    if (item.created) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'date' does not exist on type '{ _documen... Remove this comment to see the full error message
      event.date = item.created;
    }
    if (item._id) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property '_id' does not exist on type '{ _document... Remove this comment to see the full error message
      event._id = item._id;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type '{ _document... Remove this comment to see the full error message
      event.key = item._id;
    }
    switch (item.__typename) {
      case 'CreditActivity':
        {
          const accountingVerb = item.accounting_type === ACCOUNTING_TYPE.CREDIT ? 'added' : 'used';
          switch (item.type) {
            case CREDIT_ACTIVITY_TYPE.ADMIN_MANUAL: // 0
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Manual credit adjustment - ${item.amount || '?'}c ${accountingVerb}`;
              break;
            case CREDIT_ACTIVITY_TYPE.CREDIT_USAGE: // 1
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Credits used - ${item.amount || '?'}c`;
              break;
            case CREDIT_ACTIVITY_TYPE.CREDIT_PURCHASE: // 2
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Credits puchased - ${item.amount || '?'}c`;
              if (_.get(item, 'Transaction.total_amount')) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
                event.title = `${event.title} for ${utils.formatCurrency(_.get(item, 'Transaction.total_amount'), _.get(item, 'Transaction.currency') || '?')}`;
              }
              break;
            case CREDIT_ACTIVITY_TYPE.REFUND_NOSHOW: // 3
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Credit refund - ${item.amount || '?'}c for no show`;
              break;
            case CREDIT_ACTIVITY_TYPE.REFUND_UNFULIILLED: // 4
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Credit refund - ${item.amount || '?'}c for unfulfilled`;
              break;
            case CREDIT_ACTIVITY_TYPE.REFUND_CANCELLED_REJECTED: // 5
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Credit refund - ${item.amount || '?'}c for cancelled booking`;
              break;
            case CREDIT_ACTIVITY_TYPE.REFUND_BAD_PARTICIPANT: // 6
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Credit refund - ${item.amount || '?'}c for bad ppt`;
              break;
            case CREDIT_ACTIVITY_TYPE.REFUND_OTHER: // 7
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Credit refund - ${item.amount || '?'}c for other reason`;
              break;
            case CREDIT_ACTIVITY_TYPE.BOOKING_REQUIREMENT_ADJUSTMENT: // 8
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
              event.title = `Booking requirement adjustment - ${item.amount || '?'}c ${accountingVerb}`;
              break;
            default:
              return null;
          }
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'comment' does not exist on type '{ _docu... Remove this comment to see the full error message
          event.comment = item.comment;
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type '{ _documen... Remove this comment to see the full error message
          event.user = item.User;
        }
        break;
      case 'BatchResult':
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
        event.title = `Batched out - ${item.size.toLocaleString()} ${item.size === 1 ? 'person' : 'people'}`;
        break;
      case 'LogEntry':
        switch (item.event) {
          case 'booking_approve':
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
            event.title = 'Booking approved';
            break;
          case 'booking_archive':
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
            event.title = 'Booking archived';
            break;
          case 'booking_confirm':
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
            event.title = 'Booking submitted';
            break;
          case 'booking_create':
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
            event.title = 'Booking created';
            if (item.comment) {
              const cloneId = item.comment.match(/clone of ([a-f0-9]{24})/i);
              if (cloneId) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
                event.title = (
                  <Fragment>
                    Booking created - via duplicate
                    <br />
                    <Link to={`/booking/${cloneId[1]}`}>(original&nbsp;booking)</Link>
                  </Fragment>
                );
              }
            }
            break;
          case 'booking_reject':
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{ _docume... Remove this comment to see the full error message
            event.title = 'Booking rejected';
            break;
          default:
            return null;
        }
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type '{ _documen... Remove this comment to see the full error message
        event.user = item.User;
        break;
      default:
        return null;
    }
    return { event, component: this.renderEvent(event) };
  }

  renderEvent({ classes = [], props, ...event }: any) {
    if (!event.title) return null;

    const date = event.date && (event.date._isAMomentObject ? event.date : moment(event.date));
    classes.push('log-event');

    let user = null;
    if (_.get(event, 'user.type.admin')) {
      if (_.get(event.user, 'meta.identity.firstname', '').match(/askable/i)) {
        user = 'Askable Admin';
      } else {
        user = [
          'Askable Admin:',
          _.get(event.user, 'meta.identity.firstname', ''),
          _.get(event.user, 'meta.identity.lastname', '').substr(0, 1),
        ]
          .join(' ')
          .replace(/^\s+|\s+$/g, '');
      }
    } else if (_.get(event, 'user.type.client')) {
      user = (
        <Link to={`/user/${event.user._id}`}>
          {_.get(event.user, 'meta.identity.firstname', '')} {_.get(event.user, 'meta.identity.lastname', '')}
        </Link>
      );
    } else if (_.get(event, 'user.meta.identity.firstname')) {
      user = `${_.get(event.user, 'meta.identity.firstname', '')} ${_.get(event.user, 'meta.identity.lastname', '')}`;
    }

    return (
      <div
        className={classes.join(' ')}
        key={event.key || event._id}
        {...props}
        onDoubleClick={() => {
          console.log('Booking log event', event);
        }}
      >
        <div className="log-body">
          <div className="log-content">
            <div className="title">{event.title}</div>
            {event.comment && <div className="comment">{event.comment}</div>}
          </div>
          {date && (
            <div className="date">
              <Tooltip content={date.format('h:mm a - ddd D MMM YYYY')} position={PopoverPosition.TOP}>
                {date.fromNow()}
              </Tooltip>
            </div>
          )}
        </div>
        {user && <div className="user">{user}</div>}
      </div>
    );
  }

  render() {
    const loading =
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'events' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.state.events.length === 0 &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'events' does not exist on type 'Readonly... Remove this comment to see the full error message
      (this.props.loading || _.get(this.props, 'searchLogs.loading') || _.get(this.props, 'batchCount.loading'));
    if (loading) {
      return (
        <div className="section section-log">
          <p className="section-heading">Log</p>
          <p>
            <SkeletonWrapper active length={16} />
          </p>
          <p>
            <SkeletonWrapper active length={20} />
          </p>
          <p>
            <SkeletonWrapper active length={18} />
          </p>
          <p>
            <SkeletonWrapper active length={12} />
          </p>
          <p>
            <SkeletonWrapper active length={20} />
          </p>
        </div>
      );
    }
    return (
      <div className="section section-log">
        <div className="section-heading flex">
          <span>Log</span>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'refetching' does not exist on type 'Read... Remove this comment to see the full error message */}
          {this.state.refetching ? <Spinner withText className="margin-left-1 margin-top--05" /> : null}

          <span className="margin-left-auto">
            <Button
              minimal
              small
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'IconName ... Remove this comment to see the full error message
              icon={`sort-${this.state.sortDirection}`}
              className="margin-left-05"
              onClick={() => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'sortDirection' does not exist on type 'R... Remove this comment to see the full error message
                this.setState({ sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' });
              }}
            />
          </span>
        </div>
        <div className="log-items">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'events' does not exist on type 'Readonly... Remove this comment to see the full error message */}
          {_.orderBy(this.state.events, 'event.date', this.state.sortDirection).map(event => event.component)}
        </div>
      </div>
    );
  }
}

const searchLogsQuery = graphql(
  gql`
    query admin_bookingPageSearchLogs($search: LogSearchInput!) {
      searchLogs(search: $search) {
        __typename
        _id
        created
        event
        comment
        _user_id
        User {
          _id
          email
          type {
            admin
            client
          }
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
    }
  `,
  {
    name: 'searchLogs',
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        search: {
          // @ts-expect-error ts-migrate(2339) FIXME: Property '_booking_id' does not exist on type '{}'... Remove this comment to see the full error message
          _booking_id: props._booking_id,
          events: ['booking_approve', 'booking_archive', 'booking_confirm', 'booking_create', 'booking_reject'],
        },
      },
    }),
  },
);
const batchCountQuery = graphql(
  gql`
    query admin_bookingPageBatchCount($_id: ID!) {
      batchResults(search: { _booking_id: $_id, tag: "recruitment" }) {
        __typename
        _id
        size
        created
      }
    }
  `,
  {
    name: 'batchCount',
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property '_booking_id' does not exist on type '{}'... Remove this comment to see the full error message
        _id: props._booking_id,
      },
    }),
  },
);

export default compose(searchLogsQuery, batchCountQuery)(Log);

/*
    log types:
'booking_approve',
'booking_archive',
'booking_confirm',
'booking_create',
'booking_reject',

    credit activity
    transactions

    batches
*/
