import { gql } from 'urql';

export const ReopenProjectMutation = gql`
  mutation ReopenProject($projectId: ID!) {
    updateProject(_project_id: $projectId, project: { status: 1 }) {
      _id
      status
    }
  }
`;
