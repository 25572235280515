import gql from 'graphql-tag';

export default gql`
  mutation admin_updateUser($_id: ID!, $user: UserInput!) {
    updateUser(id: $_id, user: $user) {
      _id
      updated
    }
  }
`;
