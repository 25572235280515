/* eslint-disable max-lines */
import classnames from 'classnames';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { PageTitle } from 'components/PageTitle';
import { Pagination } from 'components/Pagination/Pagination';
import { Emoji } from 'components/common';
import { ColumnFilter, SelectColumnFilter, VirtualisedTable } from 'components/table/VirtualisedTable';
import { Text } from 'components/ui/Text';
import { ResearcherCertificationStatus, ResearcherMethods, useResearcherConnectionQuery } from 'generated/graphql';

import { formattedDate } from '../../../utils/date-utils';

import { RESEARCH_METHOD_LABEL_MAP } from './ResearcherContainer';

import type { Maybe, Project, User, UserMetaUxLength } from 'generated/graphql';
import type { FC } from 'react';
import type { Column, ColumnWithLooseAccessor } from 'react-table';

type ResearcherStatusProps = {
  user: User;
};

function isOnProject(user: User): { project?: Maybe<Project>; currentlyOnProject: boolean; multiple: boolean } {
  const activeProjects = (user.researcher?.projects?.nodes ?? []).filter(a => {
    return dayjs(a?.askable_plus?.due_date).isAfter(dayjs());
  });

  return {
    multiple: activeProjects.length > 1,
    project: activeProjects?.[0] ?? null,
    currentlyOnProject: activeProjects.length >= 1,
  };
}

export const ResearcherCurrentStatus: FC<ResearcherStatusProps> = ({ user }) => {
  const currentProjects = isOnProject(user);

  const baseClasses = 'tw-h-2 tw-w-2 tw-rounded-full';

  const alertClasses: { [key in ResearcherCertificationStatus]: string } = {
    in_progress: 'tw-bg-gray-500',
    in_progress_dropped: 'tw-bg-gray-500',
    in_review: 'tw-bg-blue-500',
    started: 'tw-bg-orange-500',
    certified: currentProjects.currentlyOnProject ? 'tw-bg-blue-500' : 'tw-bg-green-500',
    ineligible: 'tw-bg-red-500',
    on_hold: 'tw-bg-orange-500',
    not_certified: 'tw-bg-transparent tw-border',
  };

  const textMap: { [key in ResearcherCertificationStatus]: string | JSX.Element } = {
    started: 'Started',
    in_progress: 'In progress',
    in_progress_dropped: 'Abandoned',
    in_review: user.researcher?.certification?.video_ask_submission_url ? (
      <a target="_blank" href={user.researcher?.certification?.video_ask_submission_url} rel="noreferrer">
        Review response
      </a>
    ) : (
      'In review'
    ),
    certified: currentProjects.multiple ? (
      'Allocated'
    ) : currentProjects.currentlyOnProject ? (
      <Link to={`/project/${currentProjects.project?._id}`}>On project</Link>
    ) : (
      'Certified'
    ),
    ineligible: 'Ineligible',
    on_hold: 'On hold',
    not_certified: 'Not certified',
  };

  return (
    <div>
      <div className="tw-flex tw-items-center">
        <div
          className={classnames(
            baseClasses,
            alertClasses[user.researcher?.certification?.status ?? ResearcherCertificationStatus.Ineligible],
          )}
        />
        <Text className="tw-ml-2">
          {textMap[user.researcher?.certification?.status ?? ResearcherCertificationStatus.Ineligible]}
        </Text>
      </div>
      {currentProjects.currentlyOnProject && !currentProjects.multiple && (
        <Text className="tw-text-gray-400 tw-text-xs tw-mt-px">
          Until {dayjs(currentProjects.project?.askable_plus?.due_date).format('DD MMM')}
        </Text>
      )}
    </div>
  );
};

const RESEARCH_LENGTH_MAP: { [key in UserMetaUxLength]: string } = {
  four: '4',
  three: '3',
  two: '2',
  one_or_less: '<1',
  five_or_more: '5+',
};

export const ResearchContainer = () => {
  const FETCH_LIMIT = 1000;
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState<{ after: Maybe<string>; before: Maybe<string> }>({
    after: null,
    before: null,
  });

  const [{ data, fetching }] = useResearcherConnectionQuery({
    variables: {
      ...cursors,
      first: FETCH_LIMIT,
    },
  });

  const defaultColumn = useMemo<Partial<Column<any>>>(
    () => ({
      width: '100%',
      minWidth: 220,
      canSort: true,
    }),
    [],
  );

  const memoData = useMemo(() => {
    return data?.userConnection?.nodes ?? [];
  }, [data]);

  const columns = useMemo<readonly ColumnWithLooseAccessor<User>[]>(
    () => [
      {
        Header: 'Name',
        accessor: row => `${row?.meta?.identity?.firstname} ${row.meta?.identity?.lastname}`,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <div className="tw-flex tw-items-center tw-space-x-2">
              {original.blacklist && <Emoji emoji="☠️" label="Blacklisted" />}
              <Link to={`/user/researcher/${original._id}`}>
                <Text>
                  {original?.meta?.identity?.firstname} {original?.meta?.identity?.lastname}{' '}
                </Text>
              </Link>
              {original.meta?.social?.linkedin?.profile_url ? (
                <a href={original.meta.social.linkedin.profile_url} target="_blank" rel="noreferrer">
                  <BsLinkedin className="hover:tw-fill-blue-500 tw-fill-gray-300" />
                </a>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
        accessor: r => r.researcher?.certification?.status,
        Cell: ({ row }) => {
          const { original: user } = row;

          return <ResearcherCurrentStatus user={user} />;
        },
      },
      {
        Header: 'Experience',
        Filter: SelectColumnFilter,
        disableSortBy: true,
        filter: 'includes',
        accessor: row => row.researcher?.ux_length ?? 'None',
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <div>
              <Text>
                {original?.researcher?.ux_length ? RESEARCH_LENGTH_MAP[original.researcher.ux_length] : 'None'}
              </Text>
              {original.researcher?.ux_length_as_of && (
                <Text className="tw-text-gray-400 tw-text-xs tw-mt-px">
                  as of {dayjs(original.researcher?.ux_length_as_of).format('DD MMM')}
                </Text>
              )}
            </div>
          );
        },
      },
      {
        Header: '+Projects',
        accessor: row => row?.researcher?.projects?.totalCount,
        Cell: ({ row }) => {
          const { original } = row;

          return <Text>{original?.researcher?.projects?.totalCount}</Text>;
        },
      },
      {
        Header: 'Research methods',
        accessor: row => row.researcher?.methods,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original } = row;

          if (!original.researcher?.methods?.length) {
            return <div>None selected</div>;
          }

          return (
            <Text>
              {original.researcher?.methods
                ?.map(a => RESEARCH_METHOD_LABEL_MAP[a ?? ResearcherMethods.Discovery])
                .join(', ')}
            </Text>
          );
        },
      },
      {
        Header: 'Email',
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        accessor: row => row?.email,
        Cell: ({ row }) => {
          const { original } = row;

          return <Text>{original?.email}</Text>;
        },
      },
      {
        Header: 'Phone number',
        disableSortBy: true,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        accessor: row => row?.contact?.phone?.mobile,
        Cell: ({ row }) => {
          const { original } = row;

          return <Text>{original?.contact?.phone?.mobile}</Text>;
        },
      },
      {
        Header: 'Joined',
        accessor: row => row.created,
        Cell: ({ row }) => {
          const { original } = row;

          return <Text>{formattedDate({ date: original.created, format: 'MMM D, YYYY' })}</Text>;
        },
      },
      {
        Header: 'Notes',
        accessor: row => row?.researcher?.admin_notes,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        disableSortBy: true,
        Cell: ({ row }) => {
          const { original: user } = row;

          return <Text>{user.researcher?.admin_notes}</Text>;
        },
      },
    ],
    [data, memoData],
  );

  const initialSortBy = useMemo(() => {
    return [{ id: 'Joined', desc: true }];
  }, []);

  const handlePaginationNext = () => {
    setCursors({
      after: data?.userConnection?.pageInfo.endCursor ?? '',
      before: null,
    });

    setCurrentPage(currentPage + 1);
  };

  const handlePaginationPreviousClick = () => {
    setCursors({
      before: data?.userConnection?.pageInfo.startCursor ?? '',
      after: null,
    });

    setCurrentPage(currentPage - 1);
  };

  const TableFooter = () => {
    return (
      <Pagination
        fetching={fetching}
        hasNextPage={data?.userConnection?.pageInfo.hasNextPage ?? false}
        hasPreviousPage={data?.userConnection?.pageInfo.hasPreviousPage ?? false}
        totalCount={data?.userConnection?.totalCount ?? 0}
        currentPage={currentPage}
        onNextClick={handlePaginationNext}
        perPage={FETCH_LIMIT}
        onPreviousClick={handlePaginationPreviousClick}
      />
    );
  };

  return (
    <>
      <PageTitle title="Researchers" />
      <VirtualisedTable
        defaultColumn={defaultColumn}
        isLoading={fetching}
        data={memoData as any}
        tableFooter={<TableFooter />}
        initialSortBy={initialSortBy}
        columns={columns}
      />
    </>
  );
};
