import gql from 'graphql-tag';

import { messagesFieldsFragment } from '../../fragments/messages/messagesFields';

export default gql`
  subscription admin_messagesSubscription($filter: MessageSearchInput!) {
    messagesSubscription(filter: $filter) {
      ...messagesFields
    }
  }
  ${messagesFieldsFragment}
`;
