import { H3 } from '@blueprintjs/core';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

import { ColumnFilter, VirtualisedTable } from 'components/table/VirtualisedTable';

import { ClientTeamsQuery } from '../data/ClientTeams.query';

import type { Teams } from 'generated/graphql';
import type { ColumnWithLooseAccessor } from 'react-table';

type ClientTeamProps = {
  id: string;
};

export const ClientTeams = ({ id }: ClientTeamProps) => {
  const [{ data, fetching }] = useQuery({
    query: ClientTeamsQuery,
    variables: {
      id: id!,
    },
  });

  const memoedTeams = useMemo(() => data?.teamsByUser, [data]);

  const columns = useMemo<readonly ColumnWithLooseAccessor<Teams>[]>(() => {
    return [
      {
        Header: 'Name',
        accessor: row => row.name,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        Cell: ({ row }) => {
          return <Link to={`/team/client/${row.original?._id}/users`}>{row.original?.name}</Link>;
        },
      },
      {
        Header: 'ID',
        accessor: row => row._id,
        disableSortBy: true,
      },
    ];
  }, [memoedTeams]);

  return (
    <VirtualisedTable
      isLoading={fetching}
      tableHeader={<H3>Client teams</H3>}
      data={memoedTeams as any}
      columns={columns}
      initialSortBy={[{ id: 'name', desc: true }]}
    />
  );
};
