import { Query } from '@apollo/client/react/components';
import { Classes } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Component, Fragment } from 'react';

import { AdminWigPeriodTypes } from 'generated/graphql';

import { currentWigFieldsFragment } from '../../../graphql/fragments/wig/wigFields';
import { SkeletonWrapper, SpinnerPage } from '../../common';

import './enterprise-growth.scss';
import Wig from './enterprise-wig.view';

const CURRENT_WIG_QUERY = gql`
  query adminCurrentWigProgress($goal: String!, $periodType: AdminWigPeriodTypes) {
    adminCurrentWigProgress(key: $goal, period_type: $periodType) {
      ...CurrentWigFields
    }
  }
  ${currentWigFieldsFragment}
`;

const CURRENT_WIG_SUBSCRIPTION = gql`
  subscription adminCurrentWigProgress($goal: String!, $periodType: AdminWigPeriodTypes) {
    adminCurrentWigProgress(key: $goal, period_type: $periodType) {
      ...CurrentWigFields
    }
  }
  ${currentWigFieldsFragment}
`;

class Dashboard extends Component {
  freshTimeout: any;
  refetch: any;
  unsubscribe: any;
  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = { data: null, updated: 0 };

    this.keepFresh = this.keepFresh.bind(this);
  }

  componentDidMount() {
    this.keepFresh();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    if (this.freshTimeout) {
      clearTimeout(this.freshTimeout);
    }
  }

  async keepFresh() {
    const testInterval = 60000 * 2.5;
    const maxAge = 60000 * 12;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'updated' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.refetch && Date.now() > this.state.updated + maxAge) {
      await this.refetch();
      this.setState({ updated: Date.now() });
    }

    this.freshTimeout = setTimeout(this.keepFresh, testInterval);
  }

  wigGrid(length: any) {
    if (length <= 5) return 'grid-1';
    if (length <= 10) return 'grid-2';
    if (length <= 15) return 'grid-3';
    return 'grid-4';
  }

  render() {
    const goalKey = 'enterprise-usage';

    return (
      <div id="enterprise-growth-dashboard" className={Classes.UI_TEXT}>
        <Query
          query={CURRENT_WIG_QUERY}
          variables={{ goal: goalKey, periodType: AdminWigPeriodTypes.Mission }}
          fetchPolicy="no-cache"
          onCompleted={(data: any) => {
            this.setState({ data, updated: Date.now() });
          }}
        >
          {({ loading, error, subscribeToMore, refetch, ...queryResult }: any) => {
            if (error) {
              console.error(error);
              return null;
            }

            const { goals = [], ...wigData } =
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Readonly<{... Remove this comment to see the full error message
              _.get(this.state.data || queryResult.data, 'adminCurrentWigProgress') || {};

            if (refetch && !this.refetch) {
              this.refetch = refetch;
            }

            if (!this.unsubscribe) {
              this.unsubscribe = subscribeToMore({
                document: CURRENT_WIG_SUBSCRIPTION,
                variables: { goal: goalKey },
                fetchPolicy: 'no-cache',
                updateQuery: (prev: any, { subscriptionData }: any) => {
                  const next = _.get(subscriptionData, 'data', {});
                  this.setState({ data: next, updated: Date.now() });
                  return next;
                },
              });
            }

            return (
              <Fragment>
                <div className="header">
                  <strong>Enterprise Accounts</strong>
                  {loading ? (
                    <SkeletonWrapper length="7" />
                  ) : (
                    <span>
                      <strong>{_.get(wigData, 'period_name', '').replace(/\s.+/, '')}</strong>
                      &nbsp;
                      {_.get(wigData, 'period_name', '').replace(/^.+?\s/, '')}
                    </span>
                  )}
                </div>
                <div className={`page ${Classes.DARK}`}>
                  {loading ? (
                    <SpinnerPage />
                  ) : (
                    <div className={`${this.wigGrid(goals.length)} wigs-wrapper horizontal`}>
                      {goals.map((goal: any) => (
                        <Wig key={goal.id} goal={goal} />
                      ))}
                    </div>
                  )}
                </div>
              </Fragment>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Dashboard;
