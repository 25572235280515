import { graphql } from 'gql.tada';

export const TeamUsersListQuery = graphql(`
  query admin_TeamUsersList($id: ID!) {
    teamById(_id: $id) {
      users {
        _id
        status
        User {
          email
          displayName
        }
      }
    }
  }
`);
