import { Button, NonIdealState } from '@blueprintjs/core';
import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useBoolean } from 'usehooks-ts';

import { Spinner } from 'components/common';
import { useUserSubmissionLatestAnalysisQuery, useCreateUserSubmissionAnalysisMutation } from 'generated/graphql';

import { ScreenerAnalysisRatingDialog } from './ScreenerAnalysisRatingDialog';
import { ScreenerAnalysisResult } from './ScreenerAnalysisResult';
import { PROMPT_CUSTOM_TEMPLATE_STORAGE_KEYS } from './constants';

import type { UserSubmissionAnalysisFieldsFragment } from 'generated/graphql';
import type { FC } from 'react';

const ParticipantScreenerAnalysis: FC<{ userId?: string }> = ({ userId }) => {
  const [latestAnalysisQuery, fetchLatestAnalysis] = useUserSubmissionLatestAnalysisQuery({
    variables: { _user_id: userId },
    pause: true,
    requestPolicy: 'cache-and-network',
  });

  const [createAnalysisMutationResult, createAnalysisMutation] = useCreateUserSubmissionAnalysisMutation();

  const [analysisDoc, setAnalysisDoc] = useState<UserSubmissionAnalysisFieldsFragment | undefined>();
  const ratingsDialog = useBoolean(false);
  const showPrompt = useBoolean(false);

  useEffect(() => {
    if (fetchLatestAnalysis) {
      fetchLatestAnalysis();
    }
  }, [fetchLatestAnalysis]);

  useEffect(() => {
    if (latestAnalysisQuery.data?.userSubmissionLatestAnalysis?._id) {
      setAnalysisDoc(latestAnalysisQuery.data.userSubmissionLatestAnalysis);
    } else {
      setAnalysisDoc(undefined);
    }
  }, [latestAnalysisQuery.data?.userSubmissionLatestAnalysis]);

  const createAnalysisCb = useCallback(
    (confirmationMessage?: string) => () => {
      // console.log('confirmationMessage', confirmationMessage);
      if (confirmationMessage) {
        const prompt = window.confirm(confirmationMessage);
        if (!prompt) {
          return;
        }
      }
      createAnalysisMutation({
        _user_id: userId,
        prompt: {
          system: (window.localStorage.getItem(PROMPT_CUSTOM_TEMPLATE_STORAGE_KEYS.SYSTEM) || '').trim() || null,
          user: (window.localStorage.getItem(PROMPT_CUSTOM_TEMPLATE_STORAGE_KEYS.USER) || '').trim() || null,
        },
      })
        .then(result => {
          if (result.data?.createUserSubmissionAnalysis) {
            setAnalysisDoc(result.data.createUserSubmissionAnalysis);
            toast.success('Analysis complete');
          } else {
            console.error(
              'No analysis data returned',
              ...(result?.error?.graphQLErrors?.map(e => e.message) ?? [result?.error]),
            );
            toast.error('Error creating analysis - see console for details');
          }
        })
        .catch(e => {
          console.error(e);
          toast.error('Error creating analysis - see console for details');
        });
    },
    [createAnalysisMutation],
  );

  return (
    <>
      <div className="tw-absolute tw-w-full tw-h-full tw-overflow-y-auto">
        <div className="tw-p-4 tw-px-6">
          {(() => {
            if (createAnalysisMutationResult.fetching) {
              return (
                <NonIdealState
                  className="tw-my-16"
                  icon={
                    <div className="tw-py-4">
                      <Spinner size={60} />
                    </div>
                  }
                  title="Analysing participant screeners"
                  description="This may take a minute"
                />
              );
            }

            if (latestAnalysisQuery.fetching) {
              return <Spinner className="tw-my-16" />;
            }

            if (latestAnalysisQuery.error) {
              return <div>Error fetching analysis</div>;
            }

            if (analysisDoc) {
              return (
                <>
                  <ScreenerAnalysisResult analysis={analysisDoc} />
                  <div className="tw-mt-8 tw-mb-8">
                    <section className="tw-mb-4 tw-flex tw-flex-row tw-items-center tw-space-x-4">
                      <span>
                        Generated{' '}
                        <time dateTime={new Date(analysisDoc?.created).toISOString()}>
                          {moment(analysisDoc?.created).format('h:ma, D MMM Y')}
                        </time>
                      </span>
                      {showPrompt.value ? (
                        <Button onClick={showPrompt.setFalse}>Hide prompt</Button>
                      ) : (
                        <Button onClick={showPrompt.setTrue}>Show prompt</Button>
                      )}
                      <Button
                        onClick={createAnalysisCb(
                          'Creating a new analysis takes a minute and all previous ratings will be lost. Are you sure you want a new one?',
                        )}
                      >
                        Generate new analysis
                      </Button>
                    </section>
                    {showPrompt.value ? (
                      <section>
                        <h2 className="tw-font-bold tw-text-lg tw-mb-2">Prompt details:</h2>
                        <div className="tw-flex tw-flex-row tw-items-center tw-space-x-4 tw-mb-2">
                          <h3 className="tw-font-bold tw-flex-grow">System</h3>
                          <Button
                            onClick={() => {
                              if (
                                window.confirm(
                                  'This will overwrite the template you use for all participant analyses - are you sure?',
                                )
                              ) {
                                window.localStorage.setItem(
                                  PROMPT_CUSTOM_TEMPLATE_STORAGE_KEYS.SYSTEM,
                                  analysisDoc?.prompt?.system || '',
                                );
                              }
                            }}
                            small
                            minimal
                            icon="floppy-disk"
                          />
                        </div>
                        <pre className="bp3-code-block tw-overflow-x-auto tw-whitespace-pre-wrap tw-break-normal">
                          {analysisDoc?.prompt?.system}
                        </pre>
                        <div className="tw-flex tw-flex-row tw-items-center tw-space-x-4 tw-mb-2">
                          <h3 className="tw-font-bold tw-flex-grow">User</h3>
                          <Button
                            onClick={() => {
                              if (
                                window.confirm(
                                  'This will overwrite the template you use for all participant analyses - are you sure?',
                                )
                              ) {
                                window.localStorage.setItem(
                                  PROMPT_CUSTOM_TEMPLATE_STORAGE_KEYS.USER,
                                  analysisDoc?.prompt?.user || '',
                                );
                              }
                            }}
                            small
                            minimal
                            icon="floppy-disk"
                          />
                        </div>
                        <pre className="bp3-code-block tw-overflow-x-auto tw-whitespace-pre-wrap tw-break-normal">
                          {analysisDoc?.prompt?.user}
                        </pre>
                      </section>
                    ) : null}
                  </div>
                </>
              );
            }

            return (
              <NonIdealState
                className="tw-my-16"
                icon="help"
                title="No analysis found for this participant"
                action={<Button onClick={createAnalysisCb()}>Generate new analysis</Button>}
              />
            );
          })()}
        </div>
      </div>
      <div className="tw-absolute tw-top-0 tw-right-0 tw-m-2 tw-my-3">
        <Button minimal icon="comment" onClick={ratingsDialog.setTrue} />
      </div>
      {analysisDoc?._id ? (
        <ScreenerAnalysisRatingDialog
          isOpen={ratingsDialog.value}
          analysis={analysisDoc}
          onClose={ratingsDialog.setFalse}
        />
      ) : null}
    </>
  );
};

export default ParticipantScreenerAnalysis;
