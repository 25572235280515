import { Callout, Card, HTMLTable } from '@blueprintjs/core';
import moment from 'moment';
import { Fragment, useMemo } from 'react';

import { Icon } from 'components/common';

import { DATE_ANCHOR_FORMAT } from './constants';

import type { FC } from 'react';

export const ScreenerAnalysisSubmissions: FC<{ screenerResult?: string }> = ({ screenerResult }) => {
  const screeners = useMemo(() => {
    try {
      if (screenerResult) {
        const parsed = JSON.parse(screenerResult);
        if (parsed?.screeners) {
          return parsed.screeners as {
            created: string;
            booking_id: string;
            profile: { label: string; value?: string; values?: string[] }[];
            questions: ({
              title: string;
              description?: string;
            } & (
              | {
                  type: 'single-select' | 'multiple-select';
                  options?: {
                    label: string;
                    screenIn: boolean;
                    userSelected: boolean;
                  }[];
                }
              | {
                  type: 'text';
                  value: string;
                }
            ))[];
          }[];
        }
        return undefined;
      }
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, [screenerResult]);

  if (!screeners) {
    return <Callout intent="warning">Submissions missing / invalid</Callout>;
  }

  return (
    <div className="tw-space-y-2">
      {screeners.map(submission => {
        const createdMoment = submission.created ? moment(submission.created) : undefined;
        return (
          <Card key={submission.booking_id} className="tw-space-y-4">
            <a id={`sub-id-${submission.booking_id}`} />
            {createdMoment ? <a id={`sub-ts-${createdMoment.format(DATE_ANCHOR_FORMAT.TS)}`} /> : null}
            {createdMoment ? <a id={`sub-date-${createdMoment.format(DATE_ANCHOR_FORMAT.DATE)}`} /> : null}
            <HTMLTable>
              <thead>
                <tr>
                  <th>Submitted:</th>
                  <td>{createdMoment ? `Submitted ${createdMoment.format('h:mma, D MMM Y')}` : '?'}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Booking ID:</td>
                  <td>
                    <a href={`/booking/${submission.booking_id}`} target="_blank" rel="noreferrer">
                      {submission.booking_id}
                    </a>
                  </td>
                </tr>
              </tbody>
            </HTMLTable>
            <HTMLTable>
              <thead>
                <tr>
                  <th colSpan={2}>Profile:</th>
                </tr>
              </thead>
              <tbody>
                {submission.profile.map(field => (
                  <tr key={field.label}>
                    <td>{field.label}:</td>
                    <td>{field.value || field.values?.join(', ') || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
            <HTMLTable>
              <thead>
                <tr>
                  <th colSpan={4}>Questions:</th>
                </tr>
              </thead>
              <tbody>
                {submission.questions.map((question, i) => (
                  <Fragment key={i}>
                    <tr>
                      <td>{i + 1})</td>
                      <td colSpan={3}>{question.title}</td>
                    </tr>
                    {question.type === 'text' ? (
                      <tr>
                        <td></td>
                        <td colSpan={3}>
                          <blockquote className="tw-pl-3 tw-border-l-2 tw-border-current">{question.value}</blockquote>
                        </td>
                      </tr>
                    ) : (
                      (question.options || []).map(option => (
                        <tr key={option.label} className={option.userSelected ? '' : 'tw-opacity-50'}>
                          <td></td>
                          <td className="tw-w-2 !tw-pr-0">
                            <Icon icon={option.userSelected ? 'selection' : 'circle'} />
                          </td>
                          <td className={`${option.userSelected ? 'tw-font-medium' : 'tw-font-thin'}`}>
                            {option.label}
                          </td>
                          <td className="tw-w-2 !tw-pl-0">
                            <Icon
                              icon={option.screenIn ? 'small-tick' : 'small-cross'}
                              intent={option.screenIn ? 'success' : 'danger'}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </Fragment>
                ))}
              </tbody>
            </HTMLTable>
          </Card>
        );
      })}
    </div>
  );
};
