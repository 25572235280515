/* eslint-disable max-lines */
import { HTMLTable, ResizeSensor, Classes, Button, InputGroup } from '@blueprintjs/core';
import _ from 'lodash';
import { Component, Fragment, isValidElement } from 'react';
import { Link } from 'react-router-dom';

import { Dialog, SkeletonWrapper, InlineFilters, Icon, Select, YAMLInput, Spinner } from 'components/common';
import { BOOKING_TYPE, BOOKING_ONLINE_TYPE } from 'lib/constants';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';
import { getCountriesFromBooking } from 'utils/country-utils';

import './styles/data-table.scss';
class DataTable extends Component<any, any> {
  tableId: any;
  viewsStorageKey: any;
  countRows: number;

  constructor(props: any) {
    super(props);

    this.state = {
      sort: props.defaultSorting ? Object.keys(props.defaultSorting) : [],
      sortDirection: props.defaultSorting || {},
      filters: {},
      searchInput: '',
      width: 0,
    };

    this.countRows = 0;

    this.tableId = props.id || `data-table-${Math.random().toString(16)}`;

    this.getColumns = this.getColumns.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.filterRows = this.filterRows.bind(this);
    this.searchRowsFilter = this.searchRowsFilter.bind(this);
    this.renderHeading = this.renderHeading.bind(this);
    this.renderSortIcon = this.renderSortIcon.bind(this);
    this.renderTbody = this.renderTbody.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.renderRowLoading = this.renderRowLoading.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.renderViewsSettingsDialog = this.renderViewsSettingsDialog.bind(this);
  }

  componentDidMount() {
    if (this.props.stickyHeader) {
      const scrollParent = document.querySelector(this.props.stickyHeader);
      if (!scrollParent) return;
      scrollParent.addEventListener('scroll', this.handleScroll);
    }

    this.initFilters();
  }

  componentWillUnmount() {
    if (this.props.stickyHeader) {
      const scrollParent = document.querySelector(this.props.stickyHeader);
      if (!scrollParent) return;
      scrollParent.removeEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps: Readonly<any>) {
    if (!_.isEqual(prevProps.filters?.values, this.props.filters?.values) && this.props.filters?.values) {
      this.initFilters();
    }
  }

  initFilters() {
    const filters = this.getFiltersFromProps(this.props);

    if (filters) {
      const newFilters = filters.values.reduce(
        (acc: { [x: string]: boolean }, filter: any) => ({
          ...acc,
          [filter.key]: filter.active || filter.defaultActive,
        }),
        {},
      );
      this.setState((prevState: any) => ({ filters: { ...prevState.filters, ...newFilters } }));
    }
  }

  getColumns() {
    if (
      !(
        this.state.viewsSettings &&
        this.state.viewsSettings.selected !== undefined &&
        this.state.viewsSettings.views &&
        this.state.viewsSettings.views[this.state.viewsSettings.selected]
      )
    ) {
      return this.props.columns;
    }

    const view = this.state.viewsSettings.views[this.state.viewsSettings.selected];

    try {
      return _.chain(this.props.columns)
        .filter(col => view.columns.indexOf(col.key) >= 0)
        .orderBy(col => view.columns.indexOf(col.key))
        .value();
    } catch {
      return this.props.columns;
    }
  }

  getFiltersFromProps(props: any) {
    if (!props.filters) return null;

    const filter = {
      values: [],
    };

    filter.values = props.filters;
    if (filter.values.length === undefined && props.filters.values) {
      Object.assign(filter, props.filters);
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'props' does not exist on type '{ values:... Remove this comment to see the full error message
    if (!filter.props) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'props' does not exist on type '{ values:... Remove this comment to see the full error message
      filter.props = {
        className: Classes.TEXT_SMALL,
      };
    }

    return filter;
  }

  handleScroll() {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const offset = document.getElementById(this.tableId).getBoundingClientRect();
    const translate = offset.top >= 0 ? 0 : offset.top * -1;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    document.getElementById(this.tableId).querySelector('thead').style.transform = `translateY(${translate}px)`;
  }

  handleResize(entries: any) {
    if (this.props.loading) return false;
    this.setState({ width: entries[0].contentRect.width });
  }

  handleSort(column: any) {
    this.setState((state: any) => {
      const direction = state.sortDirection[column.key];
      if (!direction) {
        state.sortDirection[column.key] = _.get(column, 'sort.direction', 'asc');
      } else if ((this.state.sort || []).indexOf(column.key) === 0) {
        state.sortDirection[column.key] = direction === 'asc' ? 'desc' : 'asc';
      }
      state.sort = _.chain(state.sort || [])
        .pull(column.key)
        .unshift(column.key)
        .value();
      return state;
    });
  }

  filterRows(row?: any) {
    if (this.state.filters['*']) return true;
    if (!row?.filters) return false;
    const arrayOfFilters = Object.keys(this.state.filters).filter(key => this.state.filters[key] === true);
    return arrayOfFilters.every(filter => row?.filters.includes(filter));
  }

  searchRowsFilter(row: any) {
    if (!row?.search) return false;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type 'string | ... Remove this comment to see the full error message
    return !!this.searchRowsNormalise(row.search).match(this.state.searchInput);
  }

  searchRowsNormalise(value: any) {
    if (typeof value !== 'string') return false;
    return value
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .replace(/[^\sa-z0-9]/g, '');
  }

  renderHeading(column: any, index: any, columns: any) {
    if (column.label === null) return null;

    const classes = [];
    const sortable = !!column.sort;
    if (sortable) {
      classes.push('heading-sortable');
    }
    let colspan = 1;
    while (columns[index + colspan] && columns[index + colspan].label === null) {
      colspan += 1;
    }

    if (column.narrow) {
      classes.push('narrow');
    }

    return (
      <th
        key={column.key}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(() => void) | null' is not assignable to ty... Remove this comment to see the full error message
        onClick={
          sortable
            ? () => {
                this.handleSort(column);
              }
            : null
        }
        className={classes.join(' ')}
        colSpan={colspan}
      >
        <span>
          {column.label}
          {this.renderSortIcon(column)}
        </span>
      </th>
    );
  }

  renderSortIcon(column: any) {
    if (!column.sort) return null;
    const direction = this.state.sortDirection[column.key];
    const sorting = this.state.sort.indexOf(column.key) >= 0;

    const buttonProps = {
      icon: 'double-caret-vertical',
    };
    if (sorting) {
      switch (_.get(column.sort, 'icon')) {
        case 'numerical':
          buttonProps.icon = direction === 'desc' ? 'sort-numerical-desc' : 'sort-numerical';
          break;
        case 'alphabetical':
          buttonProps.icon = direction === 'desc' ? 'sort-alphabetical-desc' : 'sort-alphabetical';
          break;
        default:
          buttonProps.icon = direction === 'desc' ? 'sort-desc' : 'sort-asc';
      }
    }
    return <Icon className="sort-icon" iconSize={12} {...buttonProps} />;
  }

  renderTbody() {
    if (this.props.loading) {
      let loadingRows = [];
      if (_.get(this.props, 'rows.length') > 0) {
        loadingRows = this.props.rows;
      } else {
        for (let i = 0; i < (this.props.loadingRows || 1); i += 1) {
          loadingRows.push({ key: i });
        }
      }
      return loadingRows.map(this.renderRowLoading);
    }
    let { rows } = this.props;
    rows = rows.filter((row: any) => !!row);
    if (_.values(this.state.filters || {}).length > 0) {
      rows = rows.filter(this.filterRows);
    }

    if (this.props.search && this.state.searchInput.replace(/\s+$/g, '')) {
      rows = rows.filter(this.searchRowsFilter);
    }

    if (this.state.sort.length > 0) {
      rows = _.chain(rows)
        .map(row => {
          row = row ?? {};
          row._sort = {};
          this.state.sort.forEach((key: any) => {
            row._sort[key] = _.get(row, `sort.${key}`);
            if (row._sort[key] === undefined) {
              row._sort[key] = this.state.sortDirection[key] === 'desc' ? -Infinity : Infinity;
            }
          });
          return row;
        })
        .orderBy(
          this.state.sort.map((key: any) => `_sort.${key}`),
          this.state.sort.map((key: any) => this.state.sortDirection[key] || 'asc'),
        )
        .value();
    }
    this.countRows = rows.length;
    return rows.map(this.renderRow);
  }

  renderRow(row: any, index: any, rows: any) {
    const classes = _.get(row, 'classes', []);
    return (
      <tr
        key={row?.key || row?._id}
        onDoubleClick={() => {
          console.log('DataTable row:', row);
        }}
        className={classes.join(' ')}
      >
        {this.getColumns().map((column: any) =>
          this.renderCell({
            column,
            row,
            index,
            rows,
          }),
        )}
      </tr>
    );
  }

  renderRowLoading(row: any) {
    return (
      <tr key={row?.key || row?._id || Math.random()}>
        {this.getColumns().map((column: any) => (
          <td key={column.key || Math.random()}>
            <span>
              <SkeletonWrapper active length={Math.random() * 4 + 4} />
            </span>
          </td>
        ))}
      </tr>
    );
  }

  renderCell({ column, row, rows }: any) {
    const props = {
      key: column.key,
      style: column.style || {},
      ...column.props,
    };

    const classes = [];

    if (props.className) {
      classes.push(...props.className.split(' '));
    }

    if (column.narrow) {
      classes.push('narrow');
    }

    let cell = this.renderCellValue({ column, row, rows });
    if (column.clip) {
      cell = (
        <span className="clip-text" style={{ maxWidth: `${Math.round(column.clip * this.state.width)}px` }}>
          {cell}
        </span>
      );
    }
    if (!isValidElement(cell)) {
      cell = <span>{cell}</span>;
    }

    props.className = classes.join(' ');

    return <td {...props}>{cell}</td>;
  }

  renderCellValue({ column, row, index, rows }: any) {
    if (column.value) return column.value;
    if (typeof column.render === 'string') {
      switch (
        column.render // Look for reusable renderer keys
      ) {
        case 'booking_format': {
          if (!row?.booking) return '';
          let reviewSubmissionLabel = null;
          if (_.get(row?.booking, 'config.options.review_submission')) {
            reviewSubmissionLabel = 'Hand-picked';
          } else if (_.get(row?.booking, 'config.options.review_submission') !== undefined) {
            reviewSubmissionLabel = 'Automated';
          }
          switch (row?.booking.type) {
            case BOOKING_TYPE.FACE_TO_FACE:
              return (
                <Fragment>
                  <span>
                    <Icon icon="askable-booking-in-person" color="BOOKING_IN_PERSON" className="margin-right-05" /> In
                    Person
                  </span>
                  {reviewSubmissionLabel && (
                    <small className={Classes.TEXT_MUTED}>
                      <Icon icon="blank" className="margin-right-05" /> {reviewSubmissionLabel}
                    </small>
                  )}
                </Fragment>
              );
            case BOOKING_TYPE.REMOTE:
              return (
                <Fragment>
                  <span>
                    <Icon icon="askable-booking-remote" color="BOOKING_REMOTE" className="margin-right-05" /> Remote
                  </span>
                  {reviewSubmissionLabel && (
                    <small className={Classes.TEXT_MUTED}>
                      <Icon icon="blank" className="margin-right-05" /> {reviewSubmissionLabel}
                    </small>
                  )}
                </Fragment>
              );
            case BOOKING_TYPE.ONLINE:
              switch (_.get(row?.booking, 'config.online_task.type')) {
                case BOOKING_ONLINE_TYPE.SURVEY:
                  return (
                    <Fragment>
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> Survey
                      </span>
                      {reviewSubmissionLabel && (
                        <small className={Classes.TEXT_MUTED}>
                          <Icon icon="blank" className="margin-right-05" /> {reviewSubmissionLabel}
                        </small>
                      )}
                    </Fragment>
                  );
                case BOOKING_ONLINE_TYPE.AI_MODERATED:
                  return (
                    <Fragment>
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> AI
                        Moderated
                      </span>
                      {reviewSubmissionLabel && (
                        <small className={Classes.TEXT_MUTED}>
                          <Icon icon="blank" className="margin-right-05" /> {reviewSubmissionLabel}
                        </small>
                      )}
                    </Fragment>
                  );
                default:
                  return (
                    <Fragment>
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> Online
                        unmoderated
                      </span>
                      {reviewSubmissionLabel && (
                        <small className={Classes.TEXT_MUTED}>
                          <Icon icon="blank" className="margin-right-05" /> {reviewSubmissionLabel}
                        </small>
                      )}
                    </Fragment>
                  );
              }
            case BOOKING_TYPE.LONGITUDINAL:
              return (
                <Fragment>
                  <span>
                    <Icon
                      icon="askable-booking-longitudinal"
                      color="BOOKING_LONGITUDINAL"
                      className="margin-right-05"
                    />{' '}
                    Longitudinal
                  </span>
                  {reviewSubmissionLabel && (
                    <small className={Classes.TEXT_MUTED}>
                      <Icon icon="blank" className="margin-right-05" /> {reviewSubmissionLabel}
                    </small>
                  )}
                </Fragment>
              );
            case BOOKING_TYPE.UNMODERATED:
              return (
                <Fragment>
                  <span>
                    <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" />{' '}
                    Unmoderated
                  </span>
                  {reviewSubmissionLabel && (
                    <small className={Classes.TEXT_MUTED}>
                      <Icon icon="blank" className="margin-right-05" /> {reviewSubmissionLabel}
                    </small>
                  )}
                </Fragment>
              );
            default:
              return '';
          }
        }
        case 'booking_location': {
          if (!row?.booking) return '';
          const booking_location: [string[], string[]] = [[], []];
          const countries = getCountriesFromBooking(row?.booking);
          let flag = null;
          if (countries.length > 0) {
            booking_location[0] = countries.map(country => `${country} ${utils.countryCodeToFlag(country)}`);
          } else {
            if (_.get(row, 'booking.config.location.country', null)) {
              booking_location[0].push(row?.booking.config.location.country);
              flag = utils.countryCodeToFlag(row?.booking.config.location.country);
            }
            if (_.get(row, 'booking.config.location.state', null)) {
              booking_location[0].push(row?.booking.config.location.state);
            }

            if (_.get(row, 'booking.config.location.region', null)) {
              booking_location[1].push(row?.booking.config.location.region);
            }
            if (_.get(row, 'booking.config.location.city', null)) {
              booking_location[1].push(row?.booking.config.location.city);
            }
          }

          return (
            <Fragment>
              <span>
                {booking_location[0].join(', ')} {flag}
              </span>
              {booking_location[1].length > 0 && (
                <small className={Classes.TEXT_MUTED}>{booking_location[1].join(', ')}</small>
              )}
            </Fragment>
          );
        }
        case 'booking_client': {
          const booking_owner_name = [
            _.get(row, 'booking.user.meta.identity.firstname'),
            _.get(row, 'booking.user.meta.identity.lastname'),
          ]
            .filter(v => v)
            .join(' ');
          return (
            <Fragment>
              <Link to={`/team/client/${_.get(row, 'booking.team._id')}`}>
                <strong>{_.get(row, 'booking.team.name')}</strong>
              </Link>
              {booking_owner_name && <Link to={`/user/${_.get(row, 'booking.user._id')}`}>{booking_owner_name}</Link>}
            </Fragment>
          );
        }
        default:
          if (_.get(row, column.render) !== undefined) return _.get(row, column.render);
          return column.render;
      }
    }
    if (isValidElement(column.render)) return column.render;
    if (typeof column.render === 'function') {
      return column.render({
        row,
        column,
        index,
        rows,
      });
    }
    return null;
  }

  renderFilters() {
    const filtersFromProps = this.getFiltersFromProps(this.props);
    if (!filtersFromProps) {
      return null;
    }
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'props' does not exist on type '{ values:... Remove this comment to see the full error message
      props,
      values,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'countRows' does not exist on type '{ val... Remove this comment to see the full error message
      countRows = true,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'multiSeelct' does not exist on type '{ values: n... Remove this comment to see the full error message
      multiSelect = false,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'all' does not exist on type '{ values: n... Remove this comment to see the full error message
      all,
    } = filtersFromProps;

    return (
      <>
        <InlineFilters
          {...props}
          multiSelect={multiSelect}
          countRows={countRows}
          filters={[...values, ...(all ? [{ key: '*', label: 'Show all', defaultActive: false }] : [])].map(filter => {
            if (!countRows) return filter;
            if (_.get(this.props, 'rows.length', 0) > 0) {
              if (filter.key === '*') {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'count' does not exist on type '{ key: st... Remove this comment to see the full error message
                filter.count = this.props.rows.length;
                return filter;
              }
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'count' does not exist on type '{ key: st... Remove this comment to see the full error message
              filter.count = this.props.rows.reduce((accumulator: any, row: any) => {
                if (!row?.filters) {
                  return accumulator;
                }
                return accumulator + (row?.filters.indexOf(filter.key) >= 0 ? 1 : 0);
              }, 0);
            }
            return filter;
          })}
          active={this.state.filters}
          onChange={({ filters }: any) => {
            this.setState((state: any) => {
              filters.forEach((filter: any) => {
                state.filters[filter.key] = filter.active;
              });
              return state;
            });
          }}
        />
        {multiSelect && countRows === false && (
          <div className="countContainer">
            <p>Count: {this.countRows}</p>
          </div>
        )}
      </>
    );
  }

  renderSearch() {
    if (!this.props.search) return null;
    if (!_.find(this.props.rows, row => row?.search) && !this.props.loading) return null;

    const searchProps = {
      round: true,
      className: 'margin-left-1 margin-right-2',
      leftIcon: 'search',
      placeholder: 'Search',
      disabled: !!this.props.loading,
      rightElement: (
        <Button
          minimal
          icon="cross"
          onClick={() => {
            this.setState({ searchInput: '' });
          }}
        />
      ),
    };

    let WrapperElement = Fragment;

    if (_.isObject(this.props.search)) {
      const { wrapper, ...customProps } = this.props.search as { wrapper: any };
      if (customProps) Object.assign(searchProps, customProps);
      if (wrapper) WrapperElement = wrapper;
    }

    return (
      <WrapperElement>
        <InputGroup
          {...searchProps}
          value={this.state.searchInput}
          onChange={(event: any) => {
            this.setState({ searchInput: event.target.value });
          }}
        />
      </WrapperElement>
    );
  }

  renderViewsSettingsDialog() {
    return (
      <Dialog
        isOpen={!!this.state.viewSettingsDialog}
        onClose={() => {
          this.setState({ viewSettingsDialog: false });
        }}
        body={
          <YAMLInput
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ json: any; onChange: ({ json }: any) => vo... Remove this comment to see the full error message
            json={this.state.viewsSettings}
            onChange={({ json }: any) => {
              if (json) {
                this.setState({ viewsSettings: json });
                localStorage.save(this.viewsStorageKey, JSON.stringify(json));
              }
            }}
            textAreaProps={{
              placeholder: [
                'views:',
                '  - name: Demo',
                '    columns:',
                '      - status',
                '      - confirmed',
                '    sorting:',
                '      status: asc',
                '      confirmed: desc',
                '    filters:',
                '      - active',
              ].join('\n'),
            }}
          />
        }
        // footerActions
      />
    );
  }

  renderViewsSelect() {
    if (!this.props.selectViews) return null;
    if (!this.props.id) {
      console.warn('To select/edit views you must explicitly define the ID of your DataTable');
      return null;
    }
    if (!this.state.viewsSettings) return null;
    return (
      <div className="padding-1 flex flex-align-center margin-right-1">
        <strong className={`${Classes.TEXT_SMALL} margin-right-1`}>View:</strong>
        <Select
          options={[{ label: 'Default', value: -1 }].concat(
            (this.state.viewsSettings.views || [])
              .filter((view: any) => view && view.name)
              // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'view' implicitly has an 'any' type.
              .map((view, i) => ({
                label: view.name,
                value: i,
              })),
          )}
          value={
            typeof this.state.viewsSettings.selected === 'number' && this.state.viewsSettings.selected >= 0
              ? this.state.viewsSettings.selected.toString(10)
              : -1
          }
          onChange={(value: any) => {
            const selected = parseInt(value, 10);
            this.setState(
              (state: any) => {
                state.viewsSettings.selected = selected;
                const view = state.viewsSettings.views[selected];
                if (view && view.sorting) {
                  state.sort = Object.keys(view.sorting);
                  state.sortDirection = view.sorting;
                }

                state.filters = {};

                if (view && view.filters) {
                  view.filters.forEach((filter: any) => {
                    state.filters[filter] = true;
                  });
                }

                return state;
              },
              () => {
                localStorage.save(this.viewsStorageKey, JSON.stringify(this.state.viewsSettings));
              },
            );
          }}
        />
      </div>
    );
  }

  render() {
    const {
      rows,
      className = '',
      loading,
      noResults,
      borderedHorizontal,
      hover,
      reload,
      reloading,
      useSettings,
      ...props
    } = this.props;

    const classes = className.split(' ');
    classes.push('data-table');

    if (borderedHorizontal) {
      classes.push('table-border-horizontal');
    }
    if (hover) {
      classes.push('table-hover');
    }
    if (hover) {
      classes.push('sticky-header');
    }

    if (noResults && !loading && rows.length === 0) {
      return noResults;
    }

    return (
      <ResizeSensor onResize={this.handleResize}>
        <div className="data-table-wrapper">
          <div className="table-settings-bar">
            <div className="settings-components">
              {this.renderFilters()}
              {this.renderSearch()}
              {this.renderViewsSelect()}
              {useSettings && (
                <Button
                  small
                  minimal
                  icon="cog"
                  className="margin-right-1"
                  onClick={() => {
                    this.setState({ viewSettingsDialog: true });
                  }}
                />
              )}
              {reload &&
                (loading || reloading ? (
                  <Button small minimal disabled>
                    <Spinner withText inline tagName="span" />
                  </Button>
                ) : (
                  <Button small minimal icon="refresh" onClick={reload} />
                ))}
            </div>
          </div>
          <HTMLTable className={classes.join(' ')} id={this.tableId} {...props}>
            <tbody>{this.renderTbody()}</tbody>
            <thead>
              <tr>{this.getColumns().map(this.renderHeading)}</tr>
            </thead>
          </HTMLTable>
          {this.renderViewsSettingsDialog()}
        </div>
      </ResizeSensor>
    );
  }
}

export default DataTable;
