import { Outlet, useParams } from 'react-router';

import { AskableNavbar } from 'components/common';

import type { FC } from 'react';
import './styles/booking.scss';

export const Booking: FC<{ hideNavBar?: boolean }> = ({ hideNavBar = false }) => {
  const params = useParams();
  const secondaryNav = [
    { title: 'Current bookings', path: '/booking/list/current' },
    { title: 'Internal bookings', path: '/booking/list/internal' },
  ];

  return (
    <>
      {!hideNavBar && !params?._booking_id && <AskableNavbar className="nav-secondary" items={secondaryNav} />}
      <Outlet />
    </>
  );
};
