/* eslint-disable max-lines */
import { Query } from '@apollo/client/react/components';
import { Classes, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Omnibar, Suggest } from '@blueprintjs/select';
import gql from 'graphql-tag';
import _ from 'lodash';
import { Component, Fragment } from 'react';

// import { utils } from 'lib/utils';
import { Spinner } from './spinner.view';
import './styles/suggest.scss';

class Omnisearch extends Component {
  debounceLimit: any;
  debounceTimeout: any;
  constructor(props: any) {
    super(props);

    this.state = {
      // activeItem: null,
      loading: false,
      query: '',
      items: [],
      searchResults: {},
      selectedItem: _.get(props, 'value.value', null),
    };

    this.debounceTimeout = null;
    this.debounceLimit = 500;

    this.searchQuery = this.searchQuery.bind(this);
    this.searchQueryDebounced = this.searchQueryDebounced.bind(this);
    this.filterSearchResults = this.filterSearchResults.bind(this);

    this.renderList = this.renderList.bind(this);
  }

  componentWillReceiveProps(newProps: any) {
    if (newProps.query && !newProps.query.loading && newProps.query.data && newProps.query.data.omnisearch) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchResults' does not exist on type 'R... Remove this comment to see the full error message
      const searchResults = { ...this.state.searchResults, ...newProps.query.data.omnisearch };
      this.setState({
        searchResults,
        items: this.filterSearchResults(null, searchResults),
      });
    }
  }

  componentWillUnmount() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  }

  searchQuery() {
    this.setState({ loading: true });
    if (this.debounceTimeout) {
      return;
    }
    this.debounceTimeout = setTimeout(this.searchQueryDebounced, this.debounceLimit);
  }

  searchQueryDebounced() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (!this.props.query) return;
    this.setState({ loading: true });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.query
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
      .refetch({ query: this.state.query })
      .then(() => {
        this.setState({ loading: false });
      });
    this.debounceTimeout = null;
  }

  filterSearchResults(query: any, results: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'searchResults' does not exist on type 'R... Remove this comment to see the full error message
    return _.chain(results || this.state.searchResults || {})
      .mapValues((values, section) => {
        return values.map
          ? values.map((doc: any) => ({
              _section: section,
              doc,
            }))
          : [];
      })
      .values()
      .flatten()
      .sortBy(item => {
        switch (item._section) {
          case 'participants':
            return -20;
          case 'clients':
            return -10;
          default:
            return 100;
        }
      })
      .value();
  }

  renderItem(item: any, itemProps: any) {
    switch (item._section) {
      case 'participants':
      case 'clients': {
        const name =
          `${_.get(item.doc, 'meta.identity.firstname', '') || ''} ${_.get(item.doc, 'meta.identity.lastname', '') || ''}`
            .replace(/^\s+|\s+$/g, '')
            .replace(/\s+/, ' ');
        return (
          <MenuItem
            key={item.doc._id}
            text={name}
            label={item.doc.email}
            active={itemProps.modifiers.active}
            disabled={itemProps.modifiers.disabled}
            onClick={itemProps.handleClick}
          />
        );
      }
      case 'status':
        return <MenuItem active={false} {...item.props} />;
      default:
        return null;
    }
  }

  // renderList(activeItem, filteredItems, items, itemsParentRef, query, renderItem) {
  renderList({ items, renderItem, query }: any) {
    const groups = _.groupBy(items, '_section');
    const menuGroups = _.chain(groups)
      .mapValues((groupItems, section) => {
        const group = {
          section,
          groupItems,
          title: section,
        };
        switch (section) {
          case 'participants':
            group.title = 'Participants';
            // group.icon = 'id-number';
            break;
          case 'clients':
            group.title = 'Clients';
            // group.icon = 'id-number';
            break;
          default:
        }
        return group;
      })
      .values()
      .value();

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.state.loading) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ section: string; groupItems: [... Remove this comment to see the full error message
      menuGroups.push({
        section: 'status',
        groupItems: [
          {
            _section: 'status',
            props: {
              key: 'loading',
              text: 'Loading...',
              label: <Spinner withText />,
              disabled: true,
            },
          },
        ],
      });
    }
    if (menuGroups.length === 0) {
      if (!query) return null;
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ section: string; groupItems: [... Remove this comment to see the full error message
      menuGroups.push({
        section: 'status',
        groupItems: [
          {
            _section: 'status',
            props: {
              key: 'no-results',
              text: 'No results',
              disabled: true,
            },
          },
        ],
      });
    }

    return (
      <Menu>
        {menuGroups.map(({ section, groupItems, title }) => (
          <Fragment key={section}>
            {title !== undefined && <MenuDivider title={title} />}
            {groupItems.map(renderItem)}
          </Fragment>
        ))}
      </Menu>
    );
  }

  // locationToLabel(location) {
  //     console.log('locationToLabel', location);
  //     return `${location.city}, ${location.state || ''} ${location.postcode || ''} ${location.country || ''}`.replace(/\s+/g, ' ');
  // }

  render() {
    // let noResults = null;
    // if (this.state.query > '') {
    //     noResults = (
    //         <MenuItem
    //             text="No results"
    //             disabled
    //         />
    //     );
    // }

    const _props = {
      popover: {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'popoverProps' does not exist on type 'Re... Remove this comment to see the full error message
        ...this.props.popoverProps,
        minimal: true,
      },
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputProps' does not exist on type 'Read... Remove this comment to see the full error message
      input: this.props.inputProps || {},
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'suggestProps' does not exist on type 'Re... Remove this comment to see the full error message
      suggest: this.props.suggestProps || {},
    };

    if (_props.input && _props.input.fill) {
      _props.input.className = `${_props.input.className || ''} ${Classes.FILL}`;
      _props.popover.className = `${_props.popover.className || ''} ${Classes.FILL}`;
    }

    let MainComponent = Suggest;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'component' does not exist on type 'Reado... Remove this comment to see the full error message
    switch (this.props.component) {
      case 'Omnibar':
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'defaultProps' is missing in type 'typeof... Remove this comment to see the full error message
        MainComponent = Omnibar;
        break;
      default:
    }

    return (
      <MainComponent
        {..._props.suggest}
        // items={this.state.loading ? [] : this.state.items}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'items' does not exist on type 'Readonly<... Remove this comment to see the full error message
        items={this.state.items || []}
        itemRenderer={this.renderItem}
        itemListRenderer={this.renderList}
        popoverProps={_props.popover}
        inputProps={_props.input}
        // inputValueRenderer={this.locationToLabel}
        // noResults={(this.state.loading && this.filterSearchResults().length === 0) ? <MenuItem
        //     text="Loading..."
        //     disabled
        //     labelElement={<Spinner withText />}
        // /> : noResults}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
        query={this.state.query}
        onQueryChange={query => {
          this.setState({
            query,
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
            items: this.filterSearchResults(query),
          });
          this.searchQuery();
        }}
        // activeItem={this.state.selectedActive ? this.state.selectedItem : undefined}
        // onActiveItemChange={(item) => {
        //     console.log('onActiveItemChange', item);
        //     if (this.state.selectedActive) {
        //         this.setState({ selectedActive: false });
        //     }
        // }}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedItem' does not exist on type 'Re... Remove this comment to see the full error message
        selectedItem={this.state.selectedItem}
        onItemSelect={item => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onItemSelect' does not exist on type 'Re... Remove this comment to see the full error message
          if (this.props.onItemSelect) {
            this.setState({ query: '' });
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'onItemSelect' does not exist on type 'Re... Remove this comment to see the full error message
            this.props.onItemSelect(item);
          }
        }}
      />
    );
  }
}

// eslint-disable-next-line react/display-name
export default ({ searchTypes = [], limit = 10, ...props }: any) => {
  const searchEntities: any = [];
  searchTypes.forEach((type: any) => {
    switch (type) {
      case 'participants':
        searchEntities.push('participants { _id email meta { identity { firstname lastname } } }');
        break;
      case 'clients':
        searchEntities.push('clients { _id email meta { identity { firstname lastname } } }');
        break;
      default:
        console.warn(`Omniseach: unknown search type '${type}'`);
    }
  });
  if (searchEntities.length === 0) {
    console.warn('Omniseach: not searching for any search types');
    return <Omnisearch {...props} />;
  }

  return (
    <Query
      query={gql`query admin_omnisearch($query: String!, $limit: Int) {
              omnisearch (query: $query, limit: $limit) { ${searchEntities.join(' ')} }
            }`}
      variables={{ query: '', limit }}
    >
      {(query: any) => <Omnisearch query={query} {...props} />}
    </Query>
  );
};

// export default compose(OmnisearchContainer)(Omnisearch);
