/* eslint-disable max-lines */
import { Query } from '@apollo/client/react/components';
import {
  Classes,
  H6,
  Divider,
  Collapse,
  FormGroup,
  NumericInput,
  Checkbox,
  Button,
  Callout,
  InputGroup,
  Position,
  Tag,
} from '@blueprintjs/core';
import { TimezoneDisplayFormat, TimezoneSelect } from '@blueprintjs/datetime';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import gql from 'graphql-tag';
import _ from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';

import {
  SkeletonWrapper,
  MultiFieldSet,
  LocationSuggest,
  TemplateEdit,
  Spinner,
  Select,
  Dialog,
  IndustryCriteriaSelector,
  ErrorCallout,
  GoogleMap,
} from 'components/common';
import { useAdminCreateBatchMutation } from 'generated/graphql';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE } from 'lib/constants';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';
import { getCountriesFromBooking } from 'utils/country-utils';
import { formatIncentive } from 'utils/currency-utils';

import LanguagesView from './languages.view';

import type { Booking as BookingType, BatchCriteriaInput, Location } from 'generated/graphql';

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

type BoundingBox = {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
};

const useLocationCriteria = (booking: BookingType) => {
  const locationCriteria = booking.config?.criteria?.locations;
  return useMemo(
    () => [
      ...(locationCriteria?.states ?? []),
      ...(locationCriteria?.bounds ?? []),
      ...(locationCriteria?.countries ?? []),
    ],
    [booking.config?.criteria?.locations],
  );
};

const mapGoogleType = (types: string[]) => {
  if (types.includes('administrative_area_level_1')) {
    return 'state';
  }

  if (types.includes('country')) {
    return 'country';
  }

  return 'bounds';
};

function BookingBatchSidebarFilters(props: { booking: BookingType }) {
  const { booking } = props;
  const allLocations = useLocationCriteria(booking);
  const labels = {
    gender: {
      female: 'Female',
      male: 'Male',
      'non-binary': 'Non-binary',
    },
    family_status: {
      'user.meta.family.status.single': 'Single',
      'user.meta.family.status.relationship': 'In a Relationship',
      'user.meta.family.status.married': 'Married / Defacto',
      'user.meta.family.status.divorced': 'Divorced',
      'user.meta.family.status.widowed': 'Widowed',
    },
    work_status: {
      'user.meta.work.status.fulltime': 'Full time work',
      'user.meta.work.status.parttime': 'Part time / casual work',
      'user.meta.work.status.fulltime_student': 'Full time student',
      'user.meta.work.status.parttime_student': 'Part time student',
      'user.meta.work.status.unemployed': 'Unemployed',
      'user.meta.work.status.homeduties': 'Home duties',
      'user.meta.work.status.retired': 'Retired',
    },
    education: {
      'user.meta.education.highschool': 'Highschool',
      'user.meta.education.some_university': 'University Degree',
      'user.meta.education.undergraduate': 'Undergraduate Degree',
      'user.meta.education.postgraduate': 'Postgraduate Degree',
      'user.meta.education.apprenticeship': 'Apprenticeship',
    },
    english_speak: {
      'user.meta.identity.languages.english.speak.native': 'Native',
      'user.meta.identity.languages.english.speak.fluent': 'Fluent',
      'user.meta.identity.languages.english.speak.conversational': 'Conversational',
      'user.meta.identity.languages.english.speak.beginner': 'Beginner',
    },
    exclude_participants_from_time: [
      { value: 12, label: 'Exclude team participants from the last 12 months' },
      { value: 6, label: 'Exclude team participants from the last 6 months' },
      { value: 3, label: 'Exclude team participants from the last 3 months' },
      { value: null, label: 'Don’t exclude previous team participants' },
    ],
    employment_type: [
      { value: null, label: '(Not set)' },
      { value: 1, label: 'Employee' },
      { value: 2, label: 'Self-employed' },
      { value: 3, label: 'Business owner' },
    ],
    business_size: [
      { value: null, label: '(Not set)' },
      { value: 1, label: '1 employee' },
      { value: 2, label: '2 - 10 employees' },
      { value: 3, label: '11 - 50 employees' },
      { value: 4, label: '51 - 200 employees' },
      { value: 5, label: '201 - 1,000 employees' },
      { value: 6, label: 'More than 1,000 employees' },
    ],
    business_turnover: [
      { value: null, label: '(Not set)' },
      { value: 1, label: '< $100k' },
      { value: 2, label: '$100K - $500K' },
      { value: 3, label: '$500K - $2M' },
      { value: 4, label: '$2M - $5M' },
      { value: 5, label: '$5M - $25M' },
      { value: 6, label: '$25M+' },
    ],
    groups: {
      family_status: 'Family status',
      work_status: 'Work status',
      education: 'Highest level of education',
      english_speak: 'English speaking proficiency',
    },
    sort_preference: {
      random: 'Random',
    },
  };
  const default_values = {
    exclude_recent_recipients: 7,
    participant_active_threshold: 30,
  };
  let incentiveVariableAmount = 'unknown';
  // This is just for an example template. We don't know exactly which incentive
  // is being used, so we'll just use the first one as a visual indicator.
  if (booking.config?.incentives?.length) {
    incentiveVariableAmount = formatIncentive(booking.config.incentives[0] ?? {});
  }

  const message_template_variables = [
    { section: 'User', label: 'First name', prop: 'user.meta.identity.firstname', length: 6 }, // 70% of participants <= 6
    {
      section: 'Booking',
      label: 'Recruitment short link',
      prop: 'recruitment_shorlink',
      length: 'https://askb.co/'.length + 11,
    }, // 11 char token
    {
      section: 'Booking',
      label: `Incentive (${incentiveVariableAmount})`,
      prop: 'booking_incentive',
      length: incentiveVariableAmount.toString().length,
    },
    { section: 'Booking', label: 'ID', prop: 'booking._id', length: 24 },
  ];

  const message_templates = utils.getMessageTemplates();

  const timezone = _.get(booking, 'config.timezone', 'Australia/Brisbane');
  const batch_tag = 'recruitment';
  const notification_type = 'promotional';
  const message_type = 'sms';

  const initialLanguages = (booking.config?.criteria?.meta_identity_locales ?? []).map(locale => ({
    language: locale?.locale ?? 'en',
    fluency: locale?.value ?? ['native', 'fluent'],
    id: uuidv4(),
  }));

  const [languages, setLanguages] = useState(initialLanguages);

  const [location, setLocation] = useState({
    open: false,
    coordinates: { latitude: null as number | null | undefined, longitude: null as number | null | undefined },
    radius: 40,
    value: null as Location | null,
    types: [] as string[],
    state: null as null | string,
    country: null as null | string,
  });
  const [gender, setGender] = useState({
    open: false,
    values: _.fromPairs(
      (_.get(props, 'booking.config.criteria.meta_identity_gender') || []).map(
        (criterion: any, index: any, criteria: any) => [criterion.value, 1 / criteria.length],
      ),
    ),
  });
  const [age, setAge] = useState({
    open: false,
    values: [
      bookingUtils.getAgeRangeFromCriteriaList(
        _.get(props, 'booking.config.criteria.meta_identity_birthday_year') || [],
      ),
    ],
  });
  const [family_status, setFamily_status] = useState({
    open: false,
    values: bookingUtils.getBoolenValuesFromCriteria(_.get(props, 'booking.config.criteria.meta_family_status') || []),
  });
  const [work_status, setWork_status] = useState({
    open: false,
    values: bookingUtils.getBoolenValuesFromCriteria(_.get(props, 'booking.config.criteria.meta_work_status') || []),
  });
  const [work_details, setWorkDetails] = useState({
    open: false,
  });
  const [industry, setIndustry] = useState({
    open: false,
    values: _.get(props, 'booking.config.criteria.meta_work_industry') || [],
  });
  const [employment_type, setEmployment_type] = useState({
    open: false,
    values: [],
  });
  const [business_size, setBusiness_size] = useState({
    open: false,
    values: [],
  });
  const [business_turnover, setBusiness_turnover] = useState({
    open: false,
    values: [],
  });
  const [education, setEducation] = useState({
    open: false,
    values: bookingUtils.getBoolenValuesFromCriteria(_.get(props, 'booking.config.criteria.meta_education') || []),
  });
  const [exclusions_section, setExclusions_section] = useState({
    open: true,
  });
  const [exclude_participants_from_time, setExclude_participants_from_time] = useState(
    _.get(props, 'booking.config.criteria.exclude_participants_from_time', null),
  );
  const [exclude_participants_from_time_approved_after, setExclude_participants_from_time_approved_after] = useState(0);
  const [exclude_recent_recipients, setExclude_recent_recipients] = useState<{
    value: number;
    timestamp: number;
  } | null>({
    value: default_values.exclude_recent_recipients,
    timestamp: dayjs().subtract(default_values.exclude_recent_recipients, 'days').valueOf(),
  });
  const [participant_active_threshold, setParticipant_active_threshold] = useState<{
    value: number;
    timestamp: number;
  } | null>({
    value: default_values.participant_active_threshold,
    timestamp: dayjs().subtract(default_values.participant_active_threshold, 'days').valueOf(),
  });

  const [message_template, setMessage_template] = useState(
    localStorage.get(`batch_template_${booking._id}`) || message_templates[0].value,
  );
  const [batch_size, setBatch_size] = useState(100);
  const [exclude_previous_recipients, setExclude_previous_recipients] = useState(true);
  const [only_verfied_numbers, setOnly_verfied_numbers] = useState(true);
  const [sort_preference, setSort_preference] = useState('random');
  const [batch_send, setBatch_send] = useState<{ open?: boolean; state?: string; sent?: number; error?: string }>({
    open: false,
    state: '',
    sent: 0,
    error: '',
  });

  const { value: scheduled_send_open, toggle: toggle_scheduled_send_open } = useBoolean(false);
  const [scheduled_send_timezone, set_scheduled_send_timezone] = useState(timezone);
  const [scheduled_send_datestring, set_scheduled_send_datetstring] = useState(
    dayjs.tz(Date.now(), timezone).format('YYYY-MM-DD[T]HH:mm'),
  );
  const scheduled_send_datetime = useMemo(
    () => dayjs.tz(scheduled_send_datestring, scheduled_send_timezone),
    [scheduled_send_datestring, scheduled_send_timezone],
  );
  const [batchOutDisabledError, setBatchOutDisabledError] = useState('');
  const [boundingBox, setBoundingBox] = useState<BoundingBox>({
    minLatitude: 0,
    maxLatitude: 0,
    minLongitude: 0,
    maxLongitude: 0,
  });
  const [locationMethod, setLocationMethod] = useState('');
  // const [selectedCountry, setSelectedCountry] = useState(booking.config?.location?.country ?? '')
  const [team_previous_participants, setTeam_previous_participants] = useState<boolean>();

  // Mutations
  const [, createBatch] = useAdminCreateBatchMutation();

  useEffect(() => {
    if (booking.type === BOOKING_TYPE.ONLINE && _.get(booking, 'session.0.start') <= Date.now()) {
      setBatchOutDisabledError(
        `Completed date needs to be extended in order to batch out (Date ${dayjs(_.get(booking, 'session.0.start')).format('D MMM YYYY')})`,
      );
    }
  }, []);

  useEffect(() => {
    if (location.coordinates?.latitude && location.coordinates?.longitude && location.radius) {
      setBoundingBox(
        utils.geoBoundingBox(location.coordinates.latitude, location.coordinates.longitude, location.radius),
      );
      setLocationMethod('location');
    }
  }, [location]);

  useEffect(() => {
    if (exclude_participants_from_time) {
      setExclude_participants_from_time_approved_after(
        dayjs().subtract(exclude_participants_from_time, 'months').valueOf(),
      );
    }
  }, [exclude_participants_from_time]);

  const nullCountries = useMemo(
    () => booking?.config?.criteria?.locations?.countries?.filter(c => !c?.country) ?? [],
    [booking?.config?.criteria?.locations?.countries],
  );

  const getUserCriteria = (): [BatchCriteriaInput] => {
    const userCriteria = [{ name: 'user_type', data: '"participant"' }];

    const locations = booking?.config?.criteria?.locations;
    const locationCriteria = {
      states: (locations?.states ?? []).map(_location => ({ state: _location?.state })),
      bounds: (locations?.bounds ?? []).map(_location => _.pick(_location, 'google_location.geometry')),
      countries: (locations?.countries ?? []).map(_location => _location?.country).filter(Boolean),
      country: allLocations.length === 0 ? booking?.config?.location?.country : null,
    };

    userCriteria.push({
      name: 'locations',
      data: JSON.stringify(locationCriteria),
    });

    if (languages.length > 0) {
      userCriteria.push({
        name: 'languages',
        data: JSON.stringify(languages),
      });
    }

    /**
     * If location filter exists, add it to the criteria. This will be an additional filter to the location criteria
     * so will always yield a subset. If there is no intersection in the location filter, the batch will be empty.
     * For example: If a client selects "NSW" in PPT criteria, and an admin user filters by "QLD" in batching, it will yield 0 results/
     */
    if (location?.value) {
      const locationType = mapGoogleType(location.types);
      const data = {
        bounds: null as BoundingBox | null,
        state: null as string | null,
        country: null as string | null,
      };
      if (locationType === 'bounds' && boundingBox.minLatitude) {
        data.bounds = {
          minLatitude: boundingBox.minLatitude,
          maxLatitude: boundingBox.maxLatitude,
          minLongitude: boundingBox.minLongitude,
          maxLongitude: boundingBox.maxLongitude,
        };
      } else if (locationType === 'state' && location.value?.state) {
        // send through both values to avoid overlap in states from different countries. e.g. WA in Australia and USA
        data.state = location.value?.state;
        data.country = location.value?.country;
      } else if (location.value?.country) {
        data.country = location.value?.country;
      }

      userCriteria.push({
        name: 'locations_filter',
        data: JSON.stringify(data),
      });
    }

    userCriteria.push({ name: 'gender', data: JSON.stringify(gender.values) });
    userCriteria.push({ name: 'age', data: JSON.stringify(age.values) });
    [family_status, work_status, education].forEach(criteriaName => {
      const criteriaValues = _.toPairs(criteriaName.values)
        .map(pair => {
          if (!pair[1]) {
            return null;
          }
          const condition = {};
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          condition[pair[0].replace(/^user\./, '')] = pair[1];
          return condition;
        })
        .filter(condition => condition);
      if (criteriaValues.length > 0) {
        userCriteria.push({ name: 'user_values', data: JSON.stringify(criteriaValues) });
      }
    });

    if (exclude_participants_from_time) {
      userCriteria.push({
        name: 'exclude_team_participants',
        data: JSON.stringify({
          approvedAfter:
            exclude_participants_from_time_approved_after ||
            dayjs().subtract(exclude_participants_from_time, 'months').valueOf(),
          _team_id: booking._team_id,
        }),
      });
    }

    if (exclude_previous_recipients) {
      userCriteria.push({
        name: 'exclude_previous_recipients',
        data: JSON.stringify(true),
      });
    }

    if (exclude_recent_recipients) {
      userCriteria.push({
        name: 'exclude_recent_recipients',
        data: JSON.stringify(exclude_recent_recipients.timestamp),
      });
    }

    if (participant_active_threshold) {
      userCriteria.push({
        name: 'participant_active_threshold',
        data: JSON.stringify(participant_active_threshold.timestamp),
      });
    }

    if (only_verfied_numbers) {
      userCriteria.push({
        name: 'only_verfied_numbers',
        data: JSON.stringify(true),
      });
    }

    if (_.get(industry, 'values.length') > 0) {
      const industryFilters = industry.values
        .filter((meta_work_industry: any) => meta_work_industry._industry_id)
        .map((meta_work_industry: any) => ({
          _industry_id: meta_work_industry._industry_id,
          subcategories: meta_work_industry.subcategories
            ? meta_work_industry.subcategories.map((subcategory: any) => ({
                _subcategory_id: subcategory._subcategory_id,
              }))
            : [],
        }));

      if (industryFilters.length > 0) {
        userCriteria.push({
          name: 'industry',
          data: JSON.stringify(industryFilters),
        });
      }
    }

    if (_.get(employment_type, 'values.length') > 0) {
      userCriteria.push({
        name: 'employment_type',
        data: JSON.stringify(employment_type.values),
      });
    }

    if (_.get(business_size, 'values.length') > 0) {
      userCriteria.push({
        name: 'business_size',
        data: JSON.stringify(business_size.values),
      });
    }

    if (_.get(business_turnover, 'values.length') > 0) {
      userCriteria.push({
        name: 'business_turnover',
        data: JSON.stringify(business_turnover.values),
      });
    }

    if (_.get(props, 'booking.config.criteria.exclude_participants_from_bookings')) {
      userCriteria.push({
        name: 'exclude_participants_from_bookings',
        data: JSON.stringify(booking.config?.criteria?.exclude_participants_from_bookings),
      });
    }

    if (team_previous_participants) {
      userCriteria.push({
        name: 'team_previous_participants',
        data: JSON.stringify(_.get(props, 'booking._team_id')),
      });
    }

    // @ts-expect-error TODO: Fix batch input types
    return userCriteria;
  };

  const updateBooleanGroup = (group: any, field: any, value: any, options: any, stateFunction: any) => {
    if (field === false) {
      stateFunction({
        ...group,
        values: {
          ..._.mapValues(options, () => !value),
        },
      });
    } else {
      const valuesToUpdate = _.clone(group.values) || [];
      valuesToUpdate[field] = value;

      stateFunction({
        ...group,
        values: valuesToUpdate,
      });
    }
  };

  const updateEnumGroup = (group: any, value: any, isChecked: boolean, stateFunction: any) => {
    if (value === false) {
      stateFunction({
        ...group,
        values: [],
      });
    } else {
      const valuesOnState = _.clone(group.values) || [];
      if (isChecked) {
        valuesOnState.push(value);
      } else {
        _.pull(valuesOnState, value);
      }
      stateFunction({
        ...group,
        values: valuesOnState,
      });
    }
  };

  const createBatchSubmit = async ({ schedule_send_custom }: { schedule_send_custom: number | null }) => {
    localStorage.save(`batch_template_${booking._id}`, message_template);

    try {
      createBatch({
        batch: {
          _booking_id: booking._id,
          user_criteria: getUserCriteria(),
          schedule_send: schedule_send_custom,
          batch_tag,
          notification_type,
          message_type,
          batch_size,
          sort_preference,
          message_template,
        },
      });
      setBatch_send({ open: true, state: 'complete', sent: batch_size, error: '' });
    } catch (error: any) {
      setBatch_send({ open: true, state: 'error', sent: 0, error: error.message });
    }
  };

  const checkDisabledBatchoutButtonRules = (type: string) => {
    switch (type) {
      case 'normal':
        if (batch_size === 0) return true;
        // Check whether the booking is unmoderated AND the closing date is in the past. Ref: https://askable.atlassian.net/browse/PRO-1121
        if (booking.type === BOOKING_TYPE.ONLINE && _.get(booking, 'session.0.start') <= Date.now()) {
          return true;
        }
        break;
      case 'schedule':
        if (batch_size === 0 || !scheduled_send_datetime.isValid()) return true;
        // Check whether the booking is unmoderated AND the closing date is in the past. Ref: https://askable.atlassian.net/browse/PRO-1121
        if (booking.type === BOOKING_TYPE.ONLINE && _.get(booking, 'session.0.start') <= Date.now()) {
          return true;
        }
        break;
      default:
        break;
    }
    return false;
  };

  const renderCollapseLabel = (label: string, group: object, stateFunction: any, onClick: any = null) => {
    return (
      <p className="tw-mb-2">
        <Button
          minimal
          small
          rightIcon={_.get(group, 'open') ? 'chevron-down' : 'chevron-left'}
          className="margin-left--1"
          onClick={() => {
            if (onClick) onClick();
            stateFunction({ ...group, open: !_.get(group, 'open') });
          }}
        >
          {label}
        </Button>
      </p>
    );
  };

  const renderLocationCollapseGroup = () => {
    return (
      <>
        {renderCollapseLabel('Locations:', location, setLocation)}
        <Collapse isOpen={location.open} className="batch-settings-collapse-group">
          <div className="flex flex-row flex-wrap">
            {allLocations.length === 0
              ? renderLocationCriteriaItem(booking.config?.location?.country)
              : allLocations.map(c => renderLocationCriteriaItem(c?.formatted_address))}
          </div>

          <div className="flex flex-row flex-wrap margin-top-1 margin-bottom-1">
            <Select
              id="location-filter"
              options={[
                { label: 'No filter', value: '' },
                { label: 'Filter by location', value: 'location' },
              ]}
              value={locationMethod}
              onChange={(value: any) => {
                setLocationMethod(value);
                switch (value) {
                  case 'location':
                    setLocation({
                      ...location,
                      coordinates: { latitude: location.value?.latitude, longitude: location.value?.longitude },
                    });
                    if (location.coordinates.latitude && location.coordinates.longitude && location.radius) {
                      setBoundingBox(
                        utils.geoBoundingBox(
                          location.coordinates.latitude,
                          location.coordinates.longitude,
                          location.radius,
                        ),
                      );
                    }
                    break;
                  default:
                    setLocation({
                      ...location,
                      value: null,
                      coordinates: { latitude: null, longitude: null },
                      types: [],
                    });
                    setBoundingBox({
                      minLatitude: 0,
                      maxLatitude: 0,
                      minLongitude: 0,
                      maxLongitude: 0,
                    });
                }
              }}
            />
            {renderLocationFormControls()}
          </div>
        </Collapse>
      </>
    );
  };

  const renderLocationCriteriaItem = (address?: string | null) => {
    return (
      <Tag className="margin-left-05 margin-bottom-05" key={address} minimal round>
        {address}
      </Tag>
    );
  };

  const renderLocationMapControl = (locationField: any, radius: any = null) => {
    const isLocationSet = location.coordinates?.latitude && location.coordinates?.longitude;
    const firstLocationWithCoords = allLocations.find(l => l?.latitude && l?.longitude);
    const mapLocationType = mapGoogleType(location.types ?? firstLocationWithCoords?.google_location_types ?? []);
    return (
      <div className="width-100">
        <div className="flex margin-top-1 margin-bottom-1">
          {locationField}
          {radius && mapLocationType === 'bounds' && (
            <>
              <NumericInput
                value={location.radius}
                min={5}
                max={2500}
                clampValueOnBlur
                selectAllOnFocus
                selectAllOnIncrement
                buttonPosition="none"
                className="input-width-3 align-right margin-right-05"
                onValueChange={value => {
                  setLocation({ ...location, radius: value });
                  if (location.coordinates.latitude && location.coordinates.longitude) {
                    setBoundingBox(
                      utils.geoBoundingBox(location.coordinates.latitude, location.coordinates.longitude, value),
                    );
                  }
                }}
              />
              <span className="margin-top-auto margin-bottom-auto">km</span>
            </>
          )}
        </div>
        <GoogleMap
          location={isLocationSet ? location.coordinates : firstLocationWithCoords}
          type={isLocationSet ? mapLocationType : undefined}
          boundingBox={boundingBox}
          id="google-map"
        />
      </div>
    );
  };

  const renderLocationFormControls = () => {
    if (!locationMethod) {
      return null;
    }
    return renderLocationMapControl(
      <LocationSuggest
        onItemSelect={(item: any) => {
          setLocation({
            ...location,
            value: item,
            types: item?.google_location_types,
            coordinates: {
              longitude: item.longitude,
              latitude: item.latitude,
            },
          });
          // Don't set bounding box for country or state
          if (
            item?.google_location_types?.includes('administrative_area_level_1') ||
            item?.google_location_types?.includes('country')
          ) {
            return;
          }
          if (location.radius) {
            setBoundingBox(
              utils.geoBoundingBox(location.coordinates.latitude, location.coordinates.longitude, location.radius),
            );
          }
        }}
        inputProps={{ fill: true, autoComplete: 'off' }}
        suggestProps={{ className: 'margin-right-1' }}
        defaultLocations={[_.get(booking, 'config.location')].filter(
          item => item.city && item.latitude && item.longitude,
        )}
        value={location.value?.city && location.value?.latitude && location.value?.longitude && location}
        searchCentre={_.get(booking, 'config.location', {})}
        countries={getCountriesFromBooking(booking) ?? [_.get(booking, 'config.location.country')]}
      />,
      true,
    );
  };

  const renderGenderCollapseGroup = () => {
    return (
      <>
        {renderCollapseLabel('Gender:', gender, setGender)}
        <Collapse isOpen={gender.open} className="batch-settings-collapse-group">
          <Checkbox
            label="Any"
            checked={!_.find(Object.values(gender.values || {}))}
            onChange={event => {
              setGender({
                ...gender,
                values: _.get(event, 'target.checked') ? {} : { male: 1, female: 1, 'non-binary': 1 },
              });
            }}
          />
          {_.toPairs(labels.gender).map(([key, label]) => (
            <Checkbox
              key={key}
              label={label}
              checked={!!_.get(gender.values, key)}
              onChange={event => {
                const genderValues = _.omit<any>(gender.values, key);
                if (event.target.checked) genderValues[key] = 1;
                setGender({
                  ...gender,
                  values: genderValues,
                });
              }}
            />
          ))}
        </Collapse>
      </>
    );
  };

  const renderAgeCollapseGroup = () => {
    return (
      <>
        {renderCollapseLabel('Age:', age, setAge)}
        <Collapse isOpen={age.open} className="batch-settings-collapse-group">
          <table>
            <tbody>
              {age.values.map((oldValue: any, i: any) => {
                return (
                  <tr key={`FormGroup-age-tr-${oldValue}`}>
                    {/* @ts-expect-error TODO: Fix multifieldset types */}
                    <MultiFieldSet
                      fields="2"
                      wrapperTag="td"
                      values={age.values[i]}
                      childProps={{
                        placeholder: ['Min', 'Max'],
                      }}
                      callbacks={{
                        onValueChange: (valueAsNumber: any) => {
                          return valueAsNumber || null;
                        },
                      }}
                      onUpdate={(newValues: any) => {
                        const ageValues = _.clone(age.values);
                        ageValues[i] = newValues;
                        setAge({
                          ...age,
                          values: ageValues,
                        });
                      }}
                      separator={<td> - </td>}
                    >
                      <NumericInput
                        min={0}
                        max={100}
                        clampValueOnBlur
                        selectAllOnFocus
                        selectAllOnIncrement
                        buttonPosition="none"
                        className="input-width-4"
                        minorStepSize={null}
                      />
                    </MultiFieldSet>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Collapse>
      </>
    );
  };

  const renderIndustryCollapseGroup = () => {
    return (
      <>
        {renderCollapseLabel('Industry:', industry, setIndustry)}
        <Collapse isOpen={!!_.get(industry, 'open')} className="batch-settings-collapse-group">
          {industry.values.map((value: any, i: any) => (
            <IndustryCriteriaSelector
              key={i}
              value={value}
              onChange={(newValue: any) => {
                const industryValues = industry.values;
                if (newValue) {
                  industryValues[i] = newValue;
                } else {
                  industryValues.splice(i, 1);
                }
                setIndustry({
                  ...industry,
                  values: industryValues,
                });
              }}
            />
          ))}
          <Button
            minimal
            small
            icon="add"
            text="Add filter"
            intent="primary"
            onClick={() => {
              const industryValues = _.clone(industry.values);
              industryValues.push({});
              setIndustry({
                ...industry,
                values: industryValues,
              });
            }}
          />
        </Collapse>
      </>
    );
  };

  const renderWorkDetailsCollapseGroup = () => {
    return (
      <>
        {renderCollapseLabel('Work details:', work_details, setWorkDetails, () => {
          if (!work_details.open) {
            setIndustry({ ...industry, open: false });
            setEmployment_type({ ...employment_type, open: false });
            setBusiness_size({ ...business_size, open: false });
            setBusiness_turnover({ ...business_turnover, open: false });
          }
        })}
        <Collapse isOpen={!!_.get(work_details, 'open')} className="batch-settings-collapse-group padding-left-2">
          {renderIndustryCollapseGroup()}
          {renderCollapseLabel('Employment type', employment_type, setEmployment_type)}
          <Collapse isOpen={!!_.get(employment_type, 'open')} className="batch-settings-collapse-group">
            {renderFieldCheckboxes(employment_type, labels.employment_type, setEmployment_type)}
          </Collapse>
          {renderCollapseLabel('Business size', business_size, setBusiness_size)}
          <Collapse isOpen={!!_.get(business_size, 'open')} className="batch-settings-collapse-group">
            {renderFieldCheckboxes(business_size, labels.business_size, setBusiness_size)}
          </Collapse>
          {renderCollapseLabel('Business turnover', business_turnover, setBusiness_turnover)}
          <Collapse isOpen={!!_.get(business_turnover, 'open')} className="batch-settings-collapse-group">
            {renderFieldCheckboxes(business_turnover, labels.business_turnover, setBusiness_turnover)}
          </Collapse>
        </Collapse>
      </>
    );
  };

  const renderFieldCheckboxes = (group: any, options: any, stateFunction: any) => {
    return (
      <>
        <Checkbox
          label="Any"
          checked={_.get(group, 'values.length', 0) === 0}
          onChange={event => {
            updateEnumGroup(group, false, event.target.checked, stateFunction);
          }}
        />
        {options.map((item: any) => (
          <Checkbox
            key={`CollapseGroup-boolean-${group}-checkbox-${item.value}`}
            label={item.label}
            checked={(_.get(group, 'values') || []).indexOf(item.value) >= 0}
            onChange={event => {
              updateEnumGroup(group, item.value, event.target.checked, stateFunction);
            }}
          />
        ))}
      </>
    );
  };

  const renderBooleanFieldCheckboxes = (group: any, options: any, { any }: any, stateFunction: any) => {
    return (
      <>
        {any && (
          <Checkbox
            label="Any"
            checked={!_.find(Object.values(group.values))}
            onChange={event => {
              updateBooleanGroup(group, false, event.target.checked, options, stateFunction);
            }}
          />
        )}
        {Object.keys(options).map(key => (
          <Checkbox
            key={`CollapseGroup-boolean-${group}-checkbox-${key}`}
            label={options[key]}
            checked={!!_.get(group, `values['${key}']`)}
            onChange={event => {
              updateBooleanGroup(group, key, event.target.checked, options, stateFunction);
            }}
          />
        ))}
      </>
    );
  };

  const renderBooleanGroup = (label: string, group: object, stateFunction: any) => {
    return (
      <>
        {renderCollapseLabel(`${_.get(labels.groups, label)}:`, group, stateFunction)}
        <Collapse isOpen={!!_.get(group, 'open')} className="batch-settings-collapse-group">
          {renderBooleanFieldCheckboxes(group, _.get(labels, label), { any: true }, stateFunction)}
        </Collapse>
      </>
    );
  };

  const renderExclusionsCollapseGroup = () => {
    return (
      <>
        {renderCollapseLabel('Exclusions:', exclusions_section, setExclusions_section)}
        <Collapse isOpen={exclusions_section.open} className="batch-settings-collapse-group">
          <div className="margin-bottom-3">
            <Select
              options={labels.exclude_participants_from_time.map((option: any) => ({
                ...option,
                value: option.value || '',
              }))}
              value={exclude_participants_from_time || ''}
              onChange={(value: any) => {
                const excludeToUpdate = parseInt(value) || null;
                setExclude_participants_from_time(excludeToUpdate);
                if (excludeToUpdate) {
                  setExclude_participants_from_time_approved_after(
                    dayjs().subtract(excludeToUpdate, 'months').valueOf(),
                  );
                }
              }}
            />
          </div>
          <div className="margin-bottom-1">
            <Checkbox
              // NOTE: ie. recipients of messages with the same batch tag for the same booking id
              checked={exclude_previous_recipients}
              onChange={event => {
                setExclude_previous_recipients(event.target.checked);
              }}
            >
              <span>
                Exclude previous batch recipients for <strong>this booking</strong>
              </span>
            </Checkbox>
          </div>
          <div className="margin-bottom-1 flex flex-row height-input">
            <Checkbox
              // NOTE: ie. recipients of messages with the same batch tag for the same booking id
              checked={!!exclude_recent_recipients}
              onChange={(event: any) => {
                if (event.target.checked) {
                  const val = exclude_recent_recipients?.value || default_values.exclude_recent_recipients;
                  setExclude_recent_recipients({ value: val, timestamp: dayjs().subtract(val, 'days').valueOf() });
                } else {
                  setExclude_recent_recipients(null);
                }
              }}
              className="margin-top-auto margin-bottom-auto"
            >
              {exclude_recent_recipients === null ? (
                <span>
                  Exclude recent batch recipients for <strong>any booking</strong>
                </span>
              ) : (
                <span>
                  Exclude batch recipients for <strong>any booking</strong> within:
                </span>
              )}
            </Checkbox>
            {exclude_recent_recipients !== null && (
              <>
                <NumericInput
                  id="input-exclude-recent-recipients-limit"
                  value={
                    _.get(exclude_recent_recipients, 'value', null) === null
                      ? default_values.exclude_recent_recipients
                      : exclude_recent_recipients?.value
                  }
                  min={0}
                  max={60}
                  selectAllOnFocus
                  selectAllOnIncrement
                  buttonPosition="none"
                  majorStepSize={10}
                  minorStepSize={null}
                  className="input-width-2 align-right margin-left-05 margin-right-05 margin-top-auto margin-bottom-auto"
                  onValueChange={value => {
                    setExclude_recent_recipients({ value, timestamp: dayjs().subtract(value, 'days').valueOf() });
                  }}
                />
                <span className="margin-top-auto margin-bottom-auto">
                  day{_.get(exclude_recent_recipients, 'value') === 1 ? '' : 's'}
                </span>
              </>
            )}
          </div>
          <div className="margin-bottom-1">
            <Checkbox
              label="Only send to this team’s previous participants"
              // NOTE: ie. recipients of messages with the same batch tag for the same booking id
              checked={!!team_previous_participants}
              onChange={event => {
                setTeam_previous_participants(event.target.checked);
              }}
            />
          </div>
          <div className="margin-top-2 margin-bottom-1">
            <Checkbox
              label="Exclude unverified numbers"
              checked={only_verfied_numbers}
              onChange={event => {
                setOnly_verfied_numbers(event.target.checked);
              }}
            />
          </div>
          <div className="flex flex-row height-input margin-bottom-1">
            <Checkbox
              // NOTE: ie. recipients of messages with the same batch tag for the same booking id
              checked={!!participant_active_threshold}
              onChange={(event: any) => {
                if (event.target.checked) {
                  const val = participant_active_threshold?.value || default_values.participant_active_threshold;
                  setParticipant_active_threshold({ value: val, timestamp: dayjs().subtract(val, 'days').valueOf() });
                } else {
                  setParticipant_active_threshold(null);
                }
              }}
              className="margin-top-auto margin-bottom-auto"
            >
              {participant_active_threshold === null ? (
                <span>Exclude inactive participants</span>
              ) : (
                <span>Exclude participants not active within:</span>
              )}
            </Checkbox>
            {participant_active_threshold !== null && (
              <>
                <NumericInput
                  id="input-exclude-recent-recipients-limit"
                  value={
                    _.get(participant_active_threshold, 'value', null) === null
                      ? default_values.participant_active_threshold
                      : participant_active_threshold?.value
                  }
                  min={0}
                  max={60}
                  selectAllOnFocus
                  selectAllOnIncrement
                  buttonPosition="none"
                  majorStepSize={10}
                  minorStepSize={null}
                  className="input-width-2 align-right margin-left-05 margin-right-05 margin-top-auto margin-bottom-auto"
                  onValueChange={value => {
                    setParticipant_active_threshold({ value, timestamp: dayjs().subtract(value, 'days').valueOf() });
                  }}
                />
                <span className="margin-top-auto margin-bottom-auto">
                  day{_.get(participant_active_threshold, 'value') === 1 ? '' : 's'}
                </span>
              </>
            )}
          </div>
        </Collapse>
      </>
    );
  };

  const renderSmsTemplate = () => {
    return (
      <div className="margin-top-2 margin-bottom-2">
        <H6>SMS Template</H6>
        <TemplateEdit
          variables={message_template_variables}
          templates={message_templates}
          defaultValue={message_template}
          onChange={(value: any) => {
            setMessage_template(value);
          }}
          templateExtraChars="\n\nOpt out https://askb.co/XXXXXXXXXXX"
        />
      </div>
    );
  };

  const renderSendOptions = () => {
    return (
      <div className="margin-top-2 margin-bottom-2">
        <Query
          query={gql`
            query admin_countBatchMatches($batch: BatchInput!) {
              countBatchMatches(batch: $batch)
            }
          `}
          variables={{
            batch: {
              _booking_id: booking._id,
              user_criteria: getUserCriteria(),
              batch_tag,
              notification_type,
              message_type,
            },
          }}
          notifyOnNetworkStatusChange
        >
          {({ loading, error, data, refetch }: any) => {
            if (loading) {
              return (
                <p>
                  <Button small minimal onClick={() => {}} className="padding-0 margin-right-1">
                    <Spinner withText inline tagName="span" />
                  </Button>
                  <SkeletonWrapper>&gt;9,000 matched participants in database</SkeletonWrapper>
                </p>
              );
            }
            if (error || (data && !_.has(data, 'countBatchMatches'))) {
              console.error(error ? error.message : data);
              return (
                <Callout intent="danger" icon="error" className="margin-bottom-1">
                  Error matching participants
                </Callout>
              );
            }
            return (
              <p>
                {nullCountries.length ? (
                  <Callout intent="warning" className="tw-mb-2">
                    The following locations are excluded in batch because they are missing country data:
                    <strong>&nbsp;{nullCountries.map(c => c?.formatted_address).join(', ')}</strong>.
                    <span className="tw-block tw-mt-2">Try re-saving the participant locations and try again.</span>
                  </Callout>
                ) : null}
                <Button small minimal icon="refresh" onClick={() => refetch()} />
                <span>
                  {data.countBatchMatches && data.countBatchMatches.toLocaleString()} matched participants in database
                </span>
              </p>
            );
          }}
        </Query>
        <div className="flex flex-row">
          <FormGroup label="Batch size" labelFor="input-batch-size" className="margin-right-1">
            <NumericInput
              id="input-batch-size"
              value={batch_size}
              min={1}
              max={500}
              selectAllOnFocus
              selectAllOnIncrement
              buttonPosition="none"
              style={{ width: '100px' }}
              majorStepSize={10}
              minorStepSize={null}
              required
              onValueChange={value => {
                setBatch_size(value);
              }}
            />
          </FormGroup>
          <FormGroup label="Sort preference" labelFor="select-sort-preference">
            <Select
              id="select-sort-preference"
              options={_.toPairs(labels.sort_preference).map(option => ({
                value: option[0],
                label: option[1] as string,
              }))}
              value={sort_preference}
              onChange={(value: any) => {
                setSort_preference(value);
              }}
            />
          </FormGroup>
        </div>
        <Button
          text="Create batchout"
          intent="success"
          type="button"
          disabled={checkDisabledBatchoutButtonRules('normal')}
          onClick={() => createBatchSubmit({ schedule_send_custom: null })}
        />
        {batchOutDisabledError && (
          <div className="margin-top-1">
            <ErrorCallout error={batchOutDisabledError} />
          </div>
        )}
        <div className="margin-top-1">
          <Button text="Schedule send" intent="primary" type="button" minimal onClick={toggle_scheduled_send_open} />
          {scheduled_send_open && (
            <>
              <div className="flex flex-row margin-top-1">
                <TimezoneSelect
                  value={scheduled_send_timezone}
                  onChange={set_scheduled_send_timezone}
                  popoverProps={{ position: Position.BOTTOM_LEFT, minimal: true }}
                  valueDisplayFormat={TimezoneDisplayFormat.COMPOSITE}
                />
                <InputGroup
                  className="margin-left-1 margin-bottom-0 width-0 flex-grow-1 flex-shrink-1"
                  value={scheduled_send_datestring}
                  type="datetime-local"
                  fill
                  onChange={(event: any) => {
                    const { value } = event.target;
                    set_scheduled_send_datetstring(value);
                  }}
                />
              </div>
              <div className="margin-top-1 flex flex-row flex-align-center">
                <Button
                  text="Schedule batchout"
                  intent="primary"
                  type="button"
                  disabled={checkDisabledBatchoutButtonRules('schedule')}
                  onClick={() => createBatchSubmit({ schedule_send_custom: scheduled_send_datetime.valueOf() })}
                />
                {renderScheduledSendTime()}
              </div>
            </>
          )}
        </div>
        {localStorage.get('debug-user-criteria') && (
          <div
            style={{
              position: 'fixed',
              background: '#fff',
              top: 0,
              left: 0,
              right: '490px',
              padding: '1em',
              maxHeight: '100%',
              overflowY: 'auto',
            }}
          >
            {getUserCriteria().map(entry => (
              <pre
                key={entry.name}
                style={{ fontSize: '11px', lineHeight: 1, marginBottom: '0.75em', overflow: 'visible' }}
              >
                <strong>{entry.name}</strong>:{JSON.stringify(JSON.parse(entry.data as string), undefined, 2)}
              </pre>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderScheduledSendTime = () => {
    if (!scheduled_send_datetime?.isValid()) {
      return null;
    }

    const local = scheduled_send_datetime.local();

    const time = [];

    if (scheduled_send_datetime.valueOf() > Date.now()) {
      time.push(local.fromNow());
    }

    if (local.format('YYYY-MM-DD[T]HH:mm') !== scheduled_send_datestring) {
      time.push(local.format('(h:mma [your time])'));
    }

    return (
      <span className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL, 'margin-left-1'].join(' ')}>{time.join(' ')}</span>
    );
  };

  const renderSendingDialog = () => {
    switch (batch_send.state) {
      case 'complete':
        return {
          body: (
            <Callout intent="success" title="Batch created">
              <p>
                Successfully queued {batch_send.sent} message{batch_send.sent === 1 ? '' : 's'} for sending.
              </p>
            </Callout>
          ),
        };
      default:
        return {
          body: (
            <Callout intent="danger" title={batch_send.state === 'error' ? 'Batch error' : 'Unknown batch state'}>
              <p>{batch_send.error || 'Unknown error'}</p>
            </Callout>
          ),
        };
    }
  };

  return (
    <>
      {renderLocationCollapseGroup()}
      {renderGenderCollapseGroup()}
      {renderAgeCollapseGroup()}
      {renderBooleanGroup('family_status', family_status, setFamily_status)}
      {renderBooleanGroup('work_status', work_status, setWork_status)}
      {renderWorkDetailsCollapseGroup()}
      {renderBooleanGroup('education', education, setEducation)}
      <LanguagesView languages={languages} setLanguages={setLanguages as any} />
      {/* {renderBooleanGroup('english_speak', english_speak, setEnglish_speak)} */}
      {renderExclusionsCollapseGroup()}
      <Divider />
      {renderSmsTemplate()}

      <Divider />
      {renderSendOptions()}

      <Dialog
        title="Creating batch"
        icon="inbox"
        isOpen={!!batch_send.open}
        onClose={() => {
          setBatch_send({
            ...batch_send,
            open: false,
          });
        }}
        onClosed={() => {
          setBatch_send({});
        }}
        {...renderSendingDialog()}
      />
    </>
  );
}

export default BookingBatchSidebarFilters;
