import { Mutation } from '@apollo/client/react/components';
import { Classes, Button, Tooltip, PopoverPosition, EditableText } from '@blueprintjs/core';
import _ from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import BookingPageComment from '@graphql/mutations/booking/bookingPageComment';
import { SkeletonWrapper } from 'components/common';

type Props = {
  _booking_id: string;
};
type State = {
  compose: boolean;
  composeValue: string;
};
class Comments extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      compose: false,
      composeValue: '',
    };

    this.Wrapper = this.Wrapper.bind(this);
    this.renderComment = this.renderComment.bind(this);
    this.renderComposeArea = this.renderComposeArea.bind(this);
  }

  Wrapper({ comments = [], children }: any) {
    return (
      <div className="section comments-section">
        <p className="section-heading">Notes</p>
        <div className="comments-scroller">{comments}</div>
        {children}
      </div>
    );
  }

  renderComment(comment: any) {
    return (
      <div className="comment" key={comment._id}>
        <div className="body">{comment.comment}</div>
        <div className="info">
          {_.get(comment, 'User._id') && (
            <div className="author">
              <Link to={`/user/${comment._user_id}`} className={Classes.TEXT_MUTED}>
                {_.get(comment, 'User.meta.identity.firstname', 'Admin')}
              </Link>
            </div>
          )}

          <div className={`date ${Classes.TEXT_MUTED}`}>
            <Tooltip content={moment(comment.created).format('ddd MMM D [at] h:mma')} position={PopoverPosition.TOP}>
              {moment(comment.created).fromNow()}
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }

  handleTextChange = (value: string) => {
    this.setState({ composeValue: value });
  };

  renderComposeArea() {
    if (!this.state.compose) {
      return (
        <Button
          minimal
          intent="primary"
          icon="small-plus"
          className="margin-top-1"
          onClick={() => {
            this.setState({ compose: true });
          }}
        >
          Add note
        </Button>
      );
    }
    return (
      <Mutation mutation={BookingPageComment}>
        {(saveComment: any, { loading }: any) => (
          <div className="comment editor">
            <EditableText
              disabled={!!loading}
              multiline
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
              minLines="1"
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
              maxLines="5"
              placeholder="Type a note"
              onChange={this.handleTextChange}
              value={this.state.composeValue}
            />
            <div className="footer">
              <Button
                disabled={!!loading}
                minimal
                small
                intent="primary"
                className="margin-left-05"
                onClick={() => {
                  this.setState({ compose: false, composeValue: '' });
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!!loading}
                minimal
                small
                intent="primary"
                className="margin-left-05"
                onClick={() => {
                  saveComment({
                    variables: { booking: this.props._booking_id, comment: this.state.composeValue },
                  }).then(() => {
                    this.setState({ compose: false, composeValue: '' });
                  });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </Mutation>
    );
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.loading) {
      return (
        <this.Wrapper
          comments={[
            <div className="comment" key="loading-1">
              <div className="body">
                <SkeletonWrapper active wrap length={34} />
              </div>
              <div className="info">
                <div className="author">
                  <SkeletonWrapper active length={6} />
                </div>
                <div className="date">
                  <SkeletonWrapper active length={8} />
                </div>
              </div>
            </div>,
            <div className="comment" key="loading-2">
              <div className="body">
                <SkeletonWrapper active wrap length={19} />
              </div>
              <div className="info">
                <div className="author">
                  <SkeletonWrapper active length={9} />
                </div>
                <div className="date">
                  <SkeletonWrapper active length={8} />
                </div>
              </div>
            </div>,
          ]}
        />
      );
    }
    return (
      <this.Wrapper
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
        comments={_.chain(this.props.booking)
          .get('admin.comments', [])
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'orderBy' does not exist on type 'Functio... Remove this comment to see the full error message
          .orderBy('created', 'desc')
          .map(this.renderComment)
          .value()}
      >
        <div className="compose">{this.renderComposeArea()}</div>
      </this.Wrapper>
    );
  }
}

export default Comments;
