import { Divider } from '@blueprintjs/core';

import './styles/askable-divider.scss';

const AskableDivider = (props: any) => {
  const { width, height, className, ...passProps } = props;

  const classes = className ? className.split(' ') : [];
  if (width) {
    classes.push(`divider-w-${width.toString(10).replace(/[^\d]+/g, '-')}`);
  }
  if (height) {
    classes.push(`divider-h-${height.toString(10).replace(/[^\d]+/g, '-')}`);
  }
  return <Divider {...passProps} className={classes.join(' ')} />;
};

export default AskableDivider;
