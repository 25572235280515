import { Button, ButtonGroup, H4, Text } from '@blueprintjs/core';
import classnames from 'classnames';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { useMemo, useState } from 'react';

import { PageTitle } from 'components/PageTitle';
import { Select, SkeletonWrapper } from 'components/common';
import { uniqueArray } from 'components/common/skeleton-wrapper.view';
import { VStack } from 'components/layout/Stack';
import { OperationalOffices, ProjectProgressStatus, useAskablePlusProjectsQuery } from 'generated/graphql';

import { AskablePlusProjectCard } from '../components/AskablePlusProjectCard';

import type { AskablePlusProjectsQuery } from 'generated/graphql';
import type { VFC } from 'react';
import type { ArrayElement, FunctionalComponent } from 'types/types';

type ColumnSort = 'submitted-asc' | 'submitted-desc' | 'due-asc' | 'due-desc' | 'task-asc' | 'task-desc';

const COLUMN_SORT_MAP: { [key in ColumnSort]: string } = {
  'submitted-asc': 'Submitted first - last',
  'submitted-desc': 'Submitted last - first',
  'due-asc': 'Due first - last',
  'due-desc': 'Due last - first',
  'task-asc': 'Task first - last',
  'task-desc': 'Task last - first',
};

type HeaderProps = {
  value: ColumnSort;
  onSortChange: (opt: ColumnSort) => void;
  sortOptions: ColumnSort[];
  sortDisabled?: boolean;
};

const Header: FunctionalComponent<HeaderProps> = ({
  children,
  sortOptions,
  value,
  onSortChange,
  sortDisabled = false,
}) => {
  const options = sortOptions.map(option => {
    return {
      label: `Sort by: ${COLUMN_SORT_MAP[option]}`,
      value: option,
    };
  });

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-border-neutral-600 tw-border-b tw-w-full tw-p-6">
      <H4 className="tw-m-0">{children}</H4>
      <Select disabled={sortDisabled} options={options} value={value} onChange={v => onSortChange(v as ColumnSort)} />
    </div>
  );
};

type ColumnBase = { title: string; sort: ColumnSort[] };
type ReviewColumnBase = { accessor: ProjectProgressStatus.InReview } & ColumnBase;
type KickOffColumnBase = { accessor: ProjectProgressStatus.KickOff } & ColumnBase;
type ResearchColumnBase = { accessor: ProjectProgressStatus.Research } & ColumnBase;
type DeliveryColumnBase = { accessor: ProjectProgressStatus.Delivery } & ColumnBase;

type Columns = [ReviewColumnBase, KickOffColumnBase, ResearchColumnBase, DeliveryColumnBase];

type ProjectColumnProps = {
  data: AskablePlusProjectsQuery['projectsConnection'];
  column: ArrayElement<Columns>;
  isLoading: boolean;
};

const EmptyColumn: VFC = () => {
  return (
    <div>
      <Text>No projects...</Text>
    </div>
  );
};

const ProjectColumn: VFC<ProjectColumnProps> = ({ data, column, isLoading }) => {
  const [selectedSortOption, setSelectedSortOption] = useState<ColumnSort>(column.sort[0]);
  // TODO: Something very weird with the types here
  const sortedData: any = useMemo(() => {
    if (!data) {
      return data;
    }

    switch (selectedSortOption) {
      case 'submitted-desc':
        return orderBy(data, 'created', 'desc');
      case 'submitted-asc':
        return orderBy(data, 'created', 'asc');
      case 'due-desc':
        return orderBy(data, 'askable_plus.due_date', 'desc');
      case 'due-asc':
        return orderBy(data, 'askable_plus.due_date', 'asc');
      default:
        return data;
    }
  }, [data, selectedSortOption]);

  const className = classnames('tw-w-full tw-h-full tw-flex tw-flex-1 tw-flex-col', {
    'tw-border-r tw-border-neutral-600': column.accessor !== ProjectProgressStatus.Delivery,
  });

  return (
    <div className={className}>
      <Header
        sortDisabled={(sortedData?.length ?? 0) <= 1}
        sortOptions={column.sort}
        value={selectedSortOption}
        onSortChange={setSelectedSortOption}
      >
        {column.title} {!!(data as any)?.length && `(${(data as any).length})`}
      </Header>
      <VStack spacing={16} className="tw-p-6">
        {isLoading &&
          uniqueArray(3).map(key => {
            return <SkeletonWrapper key={key} className="tw-h-24 tw-w-full" tag="div" />;
          })}
        {!isLoading &&
          sortedData?.map((sd: any) => {
            return <AskablePlusProjectCard project={sd} key={sd?._id} />;
          })}
        {!isLoading && !sortedData?.length && <EmptyColumn />}
      </VStack>
    </div>
  );
};

export const AskablePlusProjectContainer: VFC = () => {
  const [operationalOffice, setOperationalOffice] = useState<OperationalOffices | null>(null);
  const [result] = useAskablePlusProjectsQuery();

  const columns = useMemo<Columns>(() => {
    return [
      {
        accessor: ProjectProgressStatus.InReview,
        sort: ['due-asc', 'due-desc', 'submitted-asc', 'submitted-desc'],
        title: 'In Review',
      },
      {
        accessor: ProjectProgressStatus.KickOff,
        sort: ['due-asc', 'due-desc', 'task-asc', 'task-desc'],
        title: 'Kick-off',
      },
      {
        accessor: ProjectProgressStatus.Research,
        sort: ['due-asc', 'due-desc', 'task-asc', 'task-desc'],
        title: 'Research',
      },
      {
        accessor: ProjectProgressStatus.Delivery,
        sort: ['due-asc', 'due-desc', 'task-asc', 'task-desc'],
        title: 'Delivery',
      },
    ];
  }, []);

  const data = useMemo(() => {
    // We are filtering out draft status for now on the frontend.
    const filteredProjects = result.data?.projectsConnection?.nodes?.filter(a => {
      if (operationalOffice) return a?.status !== 0 && a?.team?.operational_office === operationalOffice;
      return a?.status !== 0;
    });

    return groupBy(filteredProjects, 'progress.current_status');
  }, [result, operationalOffice]);

  return (
    <>
      <PageTitle title="Active projects" />
      <ButtonGroup className="tw-mt-4 tw-ml-4">
        <Button active={!operationalOffice} text="All" onClick={() => setOperationalOffice(null)} />
        <Button
          active={operationalOffice === OperationalOffices.Au}
          text="AU Office"
          onClick={() => setOperationalOffice(OperationalOffices.Au)}
        />
        <Button
          active={operationalOffice === OperationalOffices.Uk}
          text="UK Office"
          onClick={() => setOperationalOffice(OperationalOffices.Uk)}
        />
        <Button
          active={operationalOffice === OperationalOffices.Us}
          text="US Office"
          onClick={() => setOperationalOffice(OperationalOffices.Us)}
        />
      </ButtonGroup>
      <div className="tw-h-full tw-justify-between tw-overflow-scroll tw-grid tw-grid-cols-[repeat(4,_minmax(400px,_1fr))] tw-gap-x-px tw-auto-cols-min">
        {columns.map(col => {
          return (
            <ProjectColumn
              isLoading={result.fetching}
              key={col.accessor}
              column={col}
              data={(data?.[col.accessor] ?? []) as any}
            />
          );
        })}
      </div>
    </>
  );
};
