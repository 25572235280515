import gql from 'graphql-tag';

export default gql`
  query adminUsers($customer_success: Boolean, $growth: Boolean, $product: Boolean) {
    adminUsers(customer_success: $customer_success, growth: $growth, product: $product) {
      _id
      picture
      meta {
        identity {
          firstname
        }
      }
      admin {
        operational_office
      }
    }
  }
`;
