import { Button, Callout, FormGroup, InputGroup, Switch } from '@blueprintjs/core';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Dialog } from 'components/common';
import {
  Locale,
  useAdmin_ApproveBookingMutation,
  useAdmin_BookingPageRejectBookingMutation,
  useTeamInvoiceHistoryQuery,
} from 'generated/graphql';
import { TRANSACTIONS_STATUS } from 'lib/constants';
import { groupCurrenciesByCurrencyCode } from 'utils/currency-utils';

import type { BookingProp } from 'containers/Booking/types';
import type { BookingInputConfigIncentive } from 'generated/graphql';
import type { FC } from 'react';

type ApproveBookingFormInput = {
  incentives: { currency: string; value: string }[];
  translation: boolean;
};

export const ApproveBooking: FC<{ booking: BookingProp }> = ({ booking }) => {
  const [{ fetching: isApprovingBooking }, approveBooking] = useAdmin_ApproveBookingMutation();
  const [{ fetching: isRejectingBooking }, rejectBooking] = useAdmin_BookingPageRejectBookingMutation();
  const [invoiceHistoryResponse] = useTeamInvoiceHistoryQuery({ variables: { _id: booking._team_id! } });
  const [isApproveWarningOpen, setApproveWarningOpen] = useState(false);

  const currencies = groupCurrenciesByCurrencyCode(booking.CurrencyData ?? []);

  const recommendedIncentive = useMemo(() => {
    if (!booking?.config?.credits_per_participant) {
      return '';
    }

    const incentivePer100Credits = 55;
    const recommended = incentivePer100Credits * (booking.config.credits_per_participant / 100);

    return `Under $${Math.floor(recommended)}`;
  }, [booking]);

  const teamInvoiceStatus = useMemo(() => {
    const status = {
      transactions: 0,
      unpaidAmount: 0,
      paidInvoices: 0,
    };
    if (!invoiceHistoryResponse?.data?.teamInvoiceHistory) {
      return status;
    }
    return invoiceHistoryResponse.data.teamInvoiceHistory.reduce((acc, transaction) => {
      acc.transactions += 1;
      if (transaction?.status === TRANSACTIONS_STATUS.COMPLETED) {
        acc.paidInvoices += 1;
      }
      if (transaction?.invoice?.amount_due) {
        acc.unpaidAmount += transaction.invoice.amount_due;
      }
      return acc;
    }, status);
  }, [invoiceHistoryResponse.data]);

  const teamInvoiceWarning: null | string = useMemo(() => {
    if (teamInvoiceStatus.transactions === 0) {
      return 'This team doesn’t appear to have ever purchased credits. Are you sure this study can be approved?';
    }
    if (teamInvoiceStatus.paidInvoices > 0) {
      return null;
    }
    if (teamInvoiceStatus.unpaidAmount > 0) {
      return 'This team has never paid an invoice and has an outstanding account. Are you sure this study can be approved?';
    }
    if (teamInvoiceStatus.paidInvoices === 0) {
      return 'This team has never paid an invoice, are you sure this study can be approved?';
    }
    return null;
  }, [teamInvoiceStatus]);

  const shouldShowTranslationSetting = booking.config?.criteria?.meta_identity_locales?.filter(
    locale => locale?.locale !== Locale.En,
  ).length;

  const handleApproveBooking = async (fv: ApproveBookingFormInput) => {
    try {
      const incentives: BookingInputConfigIncentive[] = fv.incentives
        .map(({ value, currency }) => {
          const bookingCurrency = currencies.find(c => c.currency_code === currency);
          // spread the save values for the incentives back out to one per country
          return (bookingCurrency?.countries ?? []).map(country => ({
            value: Number(value),
            currency_code: currency,
            currency_symbol: bookingCurrency?.currency_symbol,
            country_code: country,
          }));
        })
        .flat();

      await approveBooking({
        booking: booking._id,
        incentives,
        settings: {
          translation: fv.translation,
        },
      })
        .then(result => {
          if (result?.error) {
            throw new Error(result.error?.graphQLErrors[0]?.message || result.error?.message || undefined);
          }
        })
        .catch(err => {
          console.error(err);
          if (err instanceof Error) {
            throw err;
          } else if (err?.message) {
            throw new Error(err.message);
          } else {
            throw new Error(undefined);
          }
        });

      toast.success('Booking approved');
    } catch (e) {
      console.error(e);
      if (e instanceof Error && e.message) {
        toast.error(e.message);
      } else {
        toast.error('Error approving booking. Check the console');
      }
    }
  };

  const handleApproveBookingSubmit = async (fv: ApproveBookingFormInput) => {
    if (teamInvoiceWarning) {
      setApproveWarningOpen(true);
    } else {
      return handleApproveBooking(fv);
    }
  };

  const handleRejectBooking = async () => {
    try {
      await rejectBooking({
        booking: booking._id,
      });

      toast.success('Booking rejected');
    } catch (e) {
      toast.success('Error rejecting booking. Check the console');
      console.error(e);
    }
  };

  const { control, register, handleSubmit, getValues } = useForm<ApproveBookingFormInput>({
    defaultValues: {
      translation: false,
    },
  });

  const formDisabled = useMemo(
    () => isApprovingBooking || isRejectingBooking || invoiceHistoryResponse.fetching,
    [isApprovingBooking, isRejectingBooking, invoiceHistoryResponse],
  );

  return (
    <Callout>
      <form onSubmit={handleSubmit(handleApproveBookingSubmit)}>
        <div className="tw-flex tw-flex-col tw-mb-2">
          {currencies.map((currency, index) => (
            <div key={`incentive_${currency.currency_code}`} className="tw-w-full">
              <input
                type="hidden"
                {...register(`incentives.${index}.currency` as const, { value: currency.currency_code })}
              />
              <Controller
                control={control}
                name={`incentives.${index}.value`}
                render={({ field }) => {
                  return (
                    <FormGroup
                      inline
                      label={currency.currency_code}
                      labelFor={`incentives.${index}.value`}
                      className="tw-w-full"
                      contentClassName="tw-grow"
                    >
                      <InputGroup
                        {...field}
                        required
                        pattern="[0-9.,]*"
                        type="text"
                        className="tw-grow-1"
                        placeholder={currency.currency_code === 'AUD' ? recommendedIncentive : ''} // TODO ASK-5630
                        disabled={formDisabled}
                      />
                    </FormGroup>
                  );
                }}
              />
            </div>
          ))}
        </div>
        {shouldShowTranslationSetting ? (
          <div className="tw-w-full">
            <Controller
              control={control}
              name="translation"
              render={({ field: { value, onChange } }) => {
                return (
                  <FormGroup inline label="Approve translation">
                    <Switch
                      checked={value}
                      onChange={event => {
                        const checked = !!event.target.checked;
                        onChange(checked);
                      }}
                    />
                  </FormGroup>
                );
              }}
            />
          </div>
        ) : null}
        <div className="tw-flex tw-row tw-justify-between tw-items-center tw-mt-2">
          <Button type="button" minimal disabled={formDisabled} onClick={handleRejectBooking}>
            Reject booking
          </Button>
          <Button
            type="submit"
            intent="primary"
            disabled={formDisabled}
            rightIcon={teamInvoiceWarning ? 'issue' : null}
          >
            Approve
          </Button>
        </div>
      </form>
      <Dialog
        isOpen={isApproveWarningOpen}
        body={<p>{teamInvoiceWarning}</p>}
        canEscapeKeyClose
        canOutsideClickClose={false}
        onClose={() => setApproveWarningOpen(false)}
        footerActions={[
          <Button key="cancel" onClick={() => setApproveWarningOpen(false)}>
            Cancel
          </Button>,
          <Button
            key="approve"
            intent="primary"
            onClick={() => {
              setApproveWarningOpen(false);
              handleApproveBooking(getValues());
            }}
          >
            Yes, approve
          </Button>,
        ]}
      />
    </Callout>
  );
};
