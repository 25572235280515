import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { Classes, H3, H5, H6, Divider, Button, Callout } from '@blueprintjs/core';
import _, { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import { Fragment, useState } from 'react';

import updateBookingMutation from '@graphql/mutations/booking/updateBooking';
import admin_BookingByID from '@graphql/queries/booking/bookingByID';
import { AppConsumer, SkeletonWrapper, Spinner, ErrorCallout, Icon } from 'components/common';
import { BOOKING_TYPE, BOOKING_ONLINE_TYPE } from 'lib/constants';

// Query

// Mutation

import '../styles/forceCompleteBookingStyles.scss';

function ForceCompleteBookingSideBar(props: any) {
  const timezone = _.get(props, 'timezone');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);

  const updateBookingStatus = (booking: any) => {
    setLoadingUpdate(true);
    props
      .updateBooking(booking._id, { status: 5 })
      .then(() => {
        setLoadingUpdate(false);
        props.onClose();
      })
      .catch((err: any) => {
        setLoadingUpdate(false);
        setErrorUpdate(err.message.replace('GraphQL error: ', ''));
      });
  };

  const renderError = () => {
    return (
      <div className="margin-bottom-4">
        <ErrorCallout error={errorUpdate} />
      </div>
    );
  };

  const onForceCompleteBooking = (booking: any) => {
    updateBookingStatus(booking);
  };

  return (
    <div id="forceCompleteBookingContainer" className="content-page forceCompleteBookingContainer">
      <Query query={admin_BookingByID} variables={{ id: props._id }} fetchPolicy="cache-and-network">
        {({ loading, error, data }: any) => {
          if (loading) {
            return <Spinner />;
          }
          if (error) {
            return <ErrorCallout error={error} />;
          }

          const booking = _.get(data, 'bookingByID');

          const bookingType = () => {
            switch (booking.type) {
              case BOOKING_TYPE.FACE_TO_FACE:
                return (
                  <span>
                    <Icon icon="askable-booking-in-person" color="BOOKING_IN_PERSON" className="margin-right-05" /> In
                    Person
                  </span>
                );
              case BOOKING_TYPE.REMOTE:
                return (
                  <span>
                    <Icon icon="askable-booking-remote" color="BOOKING_REMOTE" className="margin-right-05" /> Remote
                  </span>
                );
              case BOOKING_TYPE.ONLINE:
                switch (_.get(booking, 'config.online_task.type')) {
                  case BOOKING_ONLINE_TYPE.SURVEY:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> Survey
                      </span>
                    );
                  case BOOKING_ONLINE_TYPE.AI_MODERATED:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> AI
                        Moderated
                      </span>
                    );
                  default:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> Online
                        unmoderated
                      </span>
                    );
                }
              case BOOKING_TYPE.LONGITUDINAL:
                return (
                  <span>
                    <Icon
                      icon="askable-booking-longitudinal"
                      color="BOOKING_LONGITUDINAL"
                      className="margin-right-05"
                    />{' '}
                    Longitudinal
                  </span>
                );
              default:
                return '';
            }
          };

          return (
            <Fragment>
              <H5>Force complete Booking</H5>
              {loadingUpdate && <Spinner />}

              <SkeletonWrapper active={loading}>{bookingType()}</SkeletonWrapper>
              <br />
              <H3>
                <SkeletonWrapper active={loading}>{booking.name}</SkeletonWrapper>
              </H3>
              <p className={[Classes.UI_TEXT, Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
                <SkeletonWrapper active={loading}>
                  Team: <H5>{_.get(booking, 'team.name')}</H5>
                </SkeletonWrapper>
                {booking.status === 5 && (
                  <SkeletonWrapper active={loading}>
                    Completed:{' '}
                    <H6>
                      {_.get(booking, 'history.completed_date')
                        ? moment(_.get(booking, 'history.completed_date')).tz(timezone).format('Do MMM YYYY')
                        : ''}
                    </H6>
                  </SkeletonWrapper>
                )}
              </p>
              <Divider className="margin-bottom-2" />
              {errorUpdate && renderError()}
              {booking.status === 1 && (
                <div className="reopenBookingButtonContainer">
                  <Button
                    className="reopenBookingButton margin-bottom-2"
                    intent="success"
                    onClick={() => onForceCompleteBooking(booking)}
                  >
                    Force Complete Booking
                  </Button>

                  <Callout icon="issue" title="Please note">
                    <p>
                      Force-completing this booking updates its status <u>without</u> performing any of the follow-up
                      tasks that trigger when a booking completes naturally, such as sending confirmation / request for
                      feedback emails to clients, and playing sounds.
                    </p>
                  </Callout>
                </div>
              )}
            </Fragment>
          );
        }}
      </Query>
    </div>
  );
}

const updateBookingMutationContainer = graphql(updateBookingMutation, {
  props: ({ mutate }) => ({
    updateBooking: (booking_id: any, booking: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      mutate({
        variables: { booking_id, booking },
      }),
  }),
});

export default compose(updateBookingMutationContainer)(AppConsumer(ForceCompleteBookingSideBar));
