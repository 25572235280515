import _ from 'lodash';

const GLOBALS = {
  ASKABLE_THEME_PINK: '#FF5065',
  GOOGLE_JS_API_KEY: 'AIzaSyAl-1qGaviMNX0L8BYB2lxSqrFAunlOgAU',
  BOOKING_TYPES: { 1: 'Face to face', 2: 'Remote / Over the phone', 3: 'Online self-moderated' },
};

export default (varName: any, key?: any, fallback = null) => {
  if (!varName) {
    return GLOBALS;
  }
  const path = [varName];
  if (key) {
    path.push(key);
  }
  return _.get(GLOBALS, path.join('.'), fallback);
};
