import { Outlet } from 'react-router';

import { AskableNavbar } from 'components/common';

import SearchBar from '../../components/user/user.search-bar.view';

import type { FunctionalComponentWithChildren } from 'types/types';

const NAV_ITEMS = [
  {
    key: 'user/client',
    title: 'Clients',
    path: '/user/client',
  },
  {
    key: 'user/researcher',
    title: 'Researchers',
    path: '/user/researcher',
  },
  {
    key: 'user/participant',
    title: 'Participants',
    path: '/user/participant',
  },
];

export const UserContainer: FunctionalComponentWithChildren<any> = () => {
  return (
    <div className="tw-flex tw-h-full tw-flex-col">
      <AskableNavbar
        id="user-nav-bar"
        items={[
          ...NAV_ITEMS,
          {
            key: 'search',
            align: 'right',
            component: <SearchBar key="standalone-searchbar" />,
          },
        ]}
      />

      <div className="tw-flex tw-h-full tw-overflow-scroll">
        <Outlet />
      </div>
    </div>
  );
};

export default UserContainer;
