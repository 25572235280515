import type { TCountryCode } from 'countries-list';
import type { CurrencyData, IncentiveBookingConfig } from 'generated/graphql';

export const formatIncentivesToString = (incentives: IncentiveBookingConfig[] = []): string => {
  const formattedIncentives = incentives.map(formatIncentive).filter(Boolean);

  return formattedIncentives.join(', ');
};
export const formatIncentive = (incentive?: IncentiveBookingConfig): string => {
  if (!incentive) {
    return '';
  }
  if (incentive.currency_code && incentive.value) {
    return Intl.NumberFormat('en', {
      style: 'currency',
      currency: incentive.currency_code,
      currencyDisplay: 'code',
    }).format(incentive.value);
  }
  return Number.isFinite(incentive?.value) ? `${incentive.currency_symbol ?? ''}${incentive.value}` : '';
};

// this is here to support backwards compatibility for updating incentives. For studies created before
// ~6th June 2024, the country_code field was not set. In order to avoid manual updates, we can determine
// the country from the currency code for both AU and GB. Other currencies should be a manual data update.
export const determineCountryFromCurrency = (currency: string): TCountryCode | null => {
  switch (currency) {
    case 'AUD':
      return 'AU';
    case 'GBP':
      return 'GB';
    default:
      return null;
  }
};

interface GroupedCurrencyData {
  currency_code: string;
  currency_symbol: string;
  countries: TCountryCode[];
}
export const groupCurrenciesByCurrencyCode = (currencies: CurrencyData[] = []): GroupedCurrencyData[] => {
  return currencies.reduce((acc: GroupedCurrencyData[], currency) => {
    const existingCurrency = acc.find(c => c.currency_code === currency.currency_code);
    if (existingCurrency) {
      existingCurrency.countries.push(currency.country_code as TCountryCode);
      return acc;
    }
    return [
      ...acc,
      {
        currency_code: currency.currency_code,
        countries: [currency.country_code as TCountryCode],
        currency_symbol: currency.currency_symbol,
      },
    ];
  }, []);
};
