import Linkify from 'linkify-react';
import moment from 'moment';

// eslint-disable-next-line react/display-name
export default ({ classes, icon, title, from, body, date, time, seen, status, ...props }: any) => (
  <div {...props} className={['message'].concat(classes).join(' ')}>
    {icon && <span className="icon">{icon}</span>}

    <div className="contents">
      {from && <strong className="sender">{from}</strong>}

      {title && <span className="title">{title}</span>}

      <span className="body">
        <span className="body-contents">
          <Linkify>{body || ''}</Linkify>
        </span>
      </span>
      <span className="footer">
        {time !== false && <span className="time">{moment(date).format('h:mm A')}</span>}

        {seen && <span className="seen">Seen</span>}

        {status && <span className="status">{status}</span>}
      </span>
    </div>
  </div>
);
