import { Outlet } from 'react-router';

import { AskableNavbar } from 'components/common';

export const Tools = () => {
  const navItems = [
    { title: 'Discount codes', path: '/tools/coupon' },
    { title: 'Credit adjustment', path: '/tools/credit-adjustment' },
    { title: 'WIG', path: '/tools/wig' },
    { title: 'Custom batch', path: '/tools/batch-message' },
    { title: 'Short link generator', path: '/tools/short-link' },
  ];
  return (
    <>
      <AskableNavbar className="nav-secondary" items={navItems} />
      <Outlet />
    </>
  );
};

export default Tools;
