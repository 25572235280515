import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { Classes, H3, H5, H6, Divider, Button } from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import _, { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import { useState, Fragment } from 'react';

import createSessionMutation from '@graphql/mutations/booking/createSession';
import updateBookingMutation from '@graphql/mutations/booking/updateBooking';
import updateSessionMutation from '@graphql/mutations/booking/updateSession';
import admin_BookingByID from '@graphql/queries/booking/bookingByID';
import { AppConsumer, SkeletonWrapper, Spinner, ErrorCallout, Icon } from 'components/common';
import { BOOKING_TYPE, BOOKING_ONLINE_TYPE } from 'lib/constants';
import { utils } from 'lib/utils';

// Query

// Mutation

import '../styles/reopenBookingStyles.scss';

function ReopenBookingSideBar(props: any) {
  const timezone = _.get(props, 'timezone');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [newDate, setNewDate] = useState(moment().tz(timezone).toDate());

  const updateBookingStatus = (booking: any, hasSession: any) => {
    setLoadingUpdate(true);
    const newDateTime = moment(newDate).tz(timezone).seconds(0).milliseconds(0);

    props
      .updateBooking(booking._id, { status: 1 })
      .then(() => {
        if (hasSession) {
          const newSession = {
            start: newDateTime.valueOf(),
            end: moment(newDateTime).tz(timezone).add(_.get(booking, 'config.session.duration'), 'minutes').valueOf(),
          };

          props
            .createSession(booking._id, newSession)
            .then(() => {
              setLoadingUpdate(false);
              props.onClose();
            })
            .catch((err: any) => {
              setLoadingUpdate(false);
              setErrorUpdate(err.message.replace('GraphQL error: ', ''));
            });
        } else {
          const newCompleteByDate = {
            start: newDateTime.endOf('day').valueOf(),
            end: newDateTime.endOf('day').valueOf(),
          };

          props
            .updateSession(booking._id, _.get(booking, 'session[0]._id'), newCompleteByDate)
            .then(() => {
              setLoadingUpdate(false);
              props.onClose();
            })
            .catch((err: any) => {
              setLoadingUpdate(false);
              setErrorUpdate(err.message.replace('GraphQL error: ', ''));
            });
        }
      })
      .catch((err: any) => {
        setLoadingUpdate(false);
        setErrorUpdate(err.message.replace('GraphQL error: ', ''));
      });
  };

  const renderError = () => {
    return (
      <div className="margin-bottom-4">
        <ErrorCallout error={errorUpdate} />
      </div>
    );
  };

  const onReopenBooking = (booking: any, hasSession: any) => {
    updateBookingStatus(booking, hasSession);
  };

  return (
    <div id="reopenBookingContainer" className="content-page reopenBookingContainer">
      <Query query={admin_BookingByID} variables={{ id: props._id }} fetchPolicy="cache-and-network">
        {({ loading, error, data }: any) => {
          if (loading) {
            return <Spinner />;
          }
          if (error) {
            return <ErrorCallout error={error} />;
          }

          const booking = _.get(data, 'bookingByID');
          let diffDays = 0;
          if (_.get(booking, 'history.completed_date') && booking.status === 5) {
            diffDays = moment().tz(timezone).diff(_.get(booking, 'history.completed_date'), 'days');
          }

          let hasSession = false;
          const bookingType = () => {
            switch (booking.type) {
              case BOOKING_TYPE.FACE_TO_FACE:
                hasSession = true;
                return (
                  <span>
                    <Icon icon="askable-booking-in-person" color="BOOKING_IN_PERSON" className="margin-right-05" /> In
                    Person
                  </span>
                );
              case BOOKING_TYPE.REMOTE:
                hasSession = true;
                return (
                  <span>
                    <Icon icon="askable-booking-remote" color="BOOKING_REMOTE" className="margin-right-05" /> Remote
                  </span>
                );
              case BOOKING_TYPE.ONLINE:
                switch (_.get(booking, 'config.online_task.type')) {
                  case BOOKING_ONLINE_TYPE.SURVEY:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> Survey
                      </span>
                    );
                  case BOOKING_ONLINE_TYPE.AI_MODERATED:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> AI
                        Moderated
                      </span>
                    );
                  default:
                    return (
                      <span>
                        <Icon icon="askable-booking-online" color="BOOKING_ONLINE" className="margin-right-05" /> Online
                        unmoderated
                      </span>
                    );
                }
              case BOOKING_TYPE.LONGITUDINAL:
                return (
                  <span>
                    <Icon
                      icon="askable-booking-longitudinal"
                      color="BOOKING_LONGITUDINAL"
                      className="margin-right-05"
                    />{' '}
                    Longitudinal
                  </span>
                );
              default:
                return '';
            }
          };

          return (
            <Fragment>
              <H5>Reopen Booking</H5>
              {loadingUpdate && <Spinner />}

              <SkeletonWrapper active={loading}>{bookingType()}</SkeletonWrapper>
              <br />
              <H3>
                <SkeletonWrapper active={loading}>{booking.name}</SkeletonWrapper>
              </H3>
              <p className={[Classes.UI_TEXT, Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
                <SkeletonWrapper active={loading}>
                  Team: <H5>{_.get(booking, 'team.name')}</H5>
                </SkeletonWrapper>
                <SkeletonWrapper active={loading}>
                  Completed:{' '}
                  <H6>
                    {booking.status === 5
                      ? moment(_.get(booking, 'history.completed_date')).tz(timezone).format('Do MMM YYYY')
                      : ''}
                  </H6>
                </SkeletonWrapper>
              </p>
              <Divider className="margin-bottom-2" />
              {errorUpdate && renderError()}
              {booking.status === 5 && diffDays >= 0 && diffDays <= 21 && (
                <Fragment>
                  <div className="dateContainer">
                    <p>{hasSession ? 'New default session' : 'New completed by date'}</p>
                    <DatePicker
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'true | null' is not assignable to type 'Time... Remove this comment to see the full error message
                      timePrecision={hasSession ? true : null}
                      onChange={value => {
                        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
                        setNewDate(utils.convertTimestampToDifferentTimezone(moment(value).valueOf(), timezone));
                      }}
                      defaultValue={moment().tz(timezone).toDate()}
                    />
                  </div>
                  <div className="reopenBookingButtonContainer">
                    <Button
                      className="reopenBookingButton"
                      intent="success"
                      onClick={() => onReopenBooking(booking, hasSession)}
                    >
                      Reopen Booking
                    </Button>
                  </div>
                </Fragment>
              )}
            </Fragment>
          );
        }}
      </Query>
    </div>
  );
}

const updateBookingMutationContainer = graphql(updateBookingMutation, {
  props: ({ mutate }) => ({
    updateBooking: (booking_id: any, booking: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
      mutate({
        variables: { booking_id, booking },
      }),
  }),
});

const createSessionMutationContainer = graphql(createSessionMutation, {
  props: ({ mutate }) => ({
    createSession: (booking_id: any, session: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
      mutate({
        variables: { booking_id, session },
      }),
  }),
});

const updateSessionMutationContainer = graphql(updateSessionMutation, {
  props: ({ mutate }) => ({
    updateSession: (booking_id: any, session_id: any, session: any) =>
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
      mutate({
        variables: { booking_id, session_id, session },
      }),
  }),
});

export default compose(
  updateBookingMutationContainer,
  createSessionMutationContainer,
  updateSessionMutationContainer,
)(AppConsumer(ReopenBookingSideBar));
