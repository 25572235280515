import {
  Alignment,
  Button,
  Classes,
  HotkeysTarget2,
  InputGroup,
  MenuItem,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Tooltip,
} from '@blueprintjs/core';
import { Omnibar } from '@blueprintjs/select';
import { googleLogout } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { debounce, startCase } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { AppProvider } from 'components/common';
import { SearchResultItemType, useAdmin_SearchQuery, useGetViewerQuery, UserRoles } from 'generated/graphql';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';

import NavPrimary from './nav-primary.view';

import type { ItemRenderer } from '@blueprintjs/select';
import type { SearchResultItem } from 'generated/graphql';
import type { FC } from 'react';

import './styles/app.scss';

const { isAuthenticated } = utils;

const loginRedirectOptions = { state: { loginRedirect: window.location.href } };

const OmniSearch = Omnibar.ofType<SearchResultItem>();

const SearchBar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [{ data, fetching }] = useAdmin_SearchQuery({
    requestPolicy: 'network-only',
    pause: !query,
    variables: {
      query,
    },
  });

  const handleChange = (q: string) => {
    setQuery(q);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 200);
  }, []);

  const handleRender: ItemRenderer<SearchResultItem> = (item, { modifiers }) => {
    const [primaryLabel, secondaryLabel, thirdLabel] = item.labels ?? [];

    return (
      <MenuItem
        className="tw-py-2"
        active={modifiers.active}
        key={item._id}
        label={item.type === 'users' ? thirdLabel || '' : startCase(item.type || '')}
        onClick={handleItemClick(item)}
        text={
          <div>
            <p>{primaryLabel}</p>
            {secondaryLabel && <p className="tw-text-xs">{secondaryLabel}</p>}
          </div>
        }
      />
    );
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setQuery('');

    setIsOpen(false);
  };

  const handleItemClick = (item: SearchResultItem) => () => {
    handleItemSelect(item);
  };

  const handleItemSelect = (item: SearchResultItem) => {
    handleClose();

    switch (item.type) {
      case SearchResultItemType.Booking:
        navigate(`/booking/${item._id}`);
        break;
      case SearchResultItemType.Project:
        navigate(`/askable-plus/project/${item._id}`);
        break;
      case SearchResultItemType.Team:
        navigate(`/team/client/${item._id}`);
        break;

      case SearchResultItemType.Organisation:
        navigate(`/team/organisation/${item._id}`);
        break;

      case SearchResultItemType.Users: {
        const [, , userType] = item.labels || [];

        if (userType?.toLowerCase() === 'participant') {
          navigate(`/user/participant/${item._id}`);
          return;
        }

        if (userType?.toLowerCase() === 'client') {
          navigate(`/user/client/${item._id}`);
          return;
        }

        if (userType?.toLowerCase() === 'researcher') {
          navigate(`/user/researcher/${item._id}`);
          return;
        }

        break;
      }
      default:
        break;
    }
  };

  return (
    <HotkeysTarget2
      hotkeys={[
        {
          combo: 'shift + s',
          global: true,
          label: 'Show Omnibar',
          onKeyDown: handleFocus,
          // prevent typing "O" in omnibar input
          preventDefault: true,
        },
      ]}
    >
      {isOpen ? (
        <OmniSearch
          className="tw-fixed tw-top-[10vh] tw-w-[600px] tw-left-[calc(50%-300px)]"
          onClose={handleClose}
          onItemSelect={handleItemSelect}
          itemsEqual="_id"
          noResults={<div>No results returned. Try refining your search.</div>}
          overlayProps={{ canEscapeKeyClose: true }}
          items={fetching ? [{ labels: ['Loading...'] }] : ((data?.search as unknown as SearchResultItem[]) ?? [])}
          isOpen={isOpen}
          inputProps={{ placeholder: 'Search users, bookings, teams, organisation or projects...' }}
          onQueryChange={debouncedResults}
          itemRenderer={handleRender}
        />
      ) : (
        <InputGroup placeholder="Beta search Shift + S" onClick={handleFocus} readOnly className="tw-cursor-pointer" />
      )}
    </HotkeysTarget2>
  );
};

const NavSuper: FC = () => {
  const navigate = useNavigate();
  const [{ data }] = useGetViewerQuery({
    requestPolicy: 'cache-first',
  });

  return (
    <Navbar
      id="nav-super"
      className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED} tw-flex tw-items-center tw-justify-between`}
    >
      <NavbarGroup>
        <NavbarHeading className="logo tw-flex tw-row tw-items-center">
          <img src="/navbar-logo.svg" alt="Askable" className="tw-w-24" />
        </NavbarHeading>
        <div>
          <NavPrimary />
        </div>
      </NavbarGroup>
      <NavbarGroup align={Alignment.RIGHT} className="tw-flex tw-space-x-6">
        <SearchBar />
        {data?.userMe && (
          <Link to="/app-info">
            <Tooltip content={`${data.userMe.meta?.identity?.firstname} ${data.userMe.meta?.identity?.lastname}`}>
              {data.userMe.picture && (
                <img alt="User Profile" src={data.userMe.picture} className="tw-rounded-full tw-w-8" />
              )}
            </Tooltip>
          </Link>
        )}
        <Button
          minimal
          small
          rightIcon="log-out"
          text="Logout"
          onClick={async () => {
            localStorage.clear();

            googleLogout();

            navigate('/login', loginRedirectOptions);
          }}
        />
      </NavbarGroup>
    </Navbar>
  );
};

function useAuth() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.get('auth_token');

    if (!token) {
      navigate('/login', loginRedirectOptions);
      return;
    }

    const { roles, created, user_id } = jwtDecode<{ roles: UserRoles[]; created: number; user_id: string }>(token);

    const tokenExpired = moment().diff(created, 'hours', true) > 20;
    if (user_id === utils.getUserId() && roles.includes(UserRoles.Admin) && !tokenExpired) {
      return;
    }

    localStorage.delete('user');
    localStorage.delete('auth_token');

    navigate('/login', loginRedirectOptions);
  }, []);
}

const Provider: FC = () => {
  // Validate the authenticity of the token
  useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      return;
    }

    navigate('/login', loginRedirectOptions);
  }, []);

  return (
    <AppProvider>
      <div id="admin-app" className={Classes.DARK}>
        <NavSuper />
        <Outlet />
      </div>
    </AppProvider>
  );
};

export default Provider;
