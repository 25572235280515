const localStorage = {
  storageMethods: {
    local: window.localStorage,
    session: window.sessionStorage,
  },
  save(id: any, value: any, storageMethod = 'local') {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    localStorage.storageMethods[storageMethod].setItem(id, value);
  },
  get(id: any, storageMethod = 'local') {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return localStorage.storageMethods[storageMethod].getItem(id);
  },
  delete(id: any, storageMethod = 'local') {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    localStorage.storageMethods[storageMethod].removeItem(id);
  },
  clear(storageMethod = 'local') {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    localStorage.storageMethods[storageMethod].clear();
  },
};

export { localStorage };
