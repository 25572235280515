import { FormGroup, ControlGroup, Button } from '@blueprintjs/core';
import moment from 'moment';
import { Component } from 'react';
import Sound from 'react-sound';

import type { ReactSoundProps } from 'react-sound';

type State = {
  volumeOpen: boolean;
  loading: boolean;
  time: number;
  playStatus: ReactSoundProps['playStatus'];
};

class AudioButton extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      volumeOpen: false,
      loading: true,
      playStatus: 'STOPPED',
      time: 0,
    };

    this.onLoad = this.onLoad.bind(this);
    this.onPlaying = this.onPlaying.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onResume = this.onResume.bind(this);
    this.onStop = this.onStop.bind(this);
    this.onFinishedPlaying = this.onFinishedPlaying.bind(this);
    this.formatTime = this.formatTime.bind(this);

    // TODO: handle the keyboard play/pause buttons
  }

  // onLoading(sound) {
  //     console.log('onLoading', sound);
  // }
  onLoad() {
    this.setState({ loading: false });
  }
  onPlaying(sound: any) {
    this.setState({ time: sound.position, playStatus: 'PLAYING' });
  }
  onPause() {
    this.setState({ playStatus: 'PAUSED' });
  }
  onResume() {
    this.setState({ playStatus: 'PLAYING' });
  }
  onStop() {
    this.setState({ playStatus: 'STOPPED' });
  }
  onFinishedPlaying() {
    this.setState({ playStatus: 'STOPPED', time: 0 });
  }

  formatTime() {
    const t = moment.utc(this.state.time);
    if (t.valueOf() >= 3600000) {
      return t.format('H:mm:ss');
    }
    return t.format('mm:ss');
  }

  render() {
    const { sound, volumeControl, label, inline, intent, className, ...buttonProps } = this.props;

    return (
      <FormGroup label={label} inline={inline} intent={intent} className={className}>
        <ControlGroup>
          {volumeControl && (
            <Button
              {...buttonProps}
              icon="volume-up"
              onClick={() => {
                this.setState({ volumeOpen: !this.state.volumeOpen });
              }}
              intent={intent}
            />
          )}
          <Button
            {...buttonProps}
            text={this.formatTime()}
            icon={this.state.playStatus === 'PLAYING' ? 'pause' : 'play'}
            loading={this.state.loading}
            onClick={() => {
              if (this.state.loading) return;
              if (this.state.playStatus === 'PLAYING') {
                this.setState({ playStatus: 'PAUSED' });
                return;
              }
              this.setState({ playStatus: 'PLAYING' });
            }}
            intent={intent}
          />
          <Sound
            playStatus={this.state.playStatus}
            // onLoading={this.onLoading}
            onLoad={this.onLoad}
            onPlaying={this.onPlaying}
            onPause={this.onPause}
            onResume={this.onResume}
            onStop={this.onStop}
            onFinishedPlaying={this.onFinishedPlaying}
            autoLoad
            {...sound}
          />
        </ControlGroup>
      </FormGroup>
    );
  }
}

export default AudioButton;
