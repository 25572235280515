import gql from 'graphql-tag';

import { teamsListFragment } from '../../fragments/teamsListFields';

export default gql`
  query organisationById($_id: ID!) {
    organisationById(_id: $_id) {
      _id
      name
      created
      admin {
        Owner {
          _id
          picture
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
      Teams {
        ...TeamsListFields
      }
    }
  }
  ${teamsListFragment}
`;
