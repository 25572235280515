import { Label, Menu, MenuItem, Popover, TextArea } from '@blueprintjs/core';
import GSM from 'gsm';
import pluralize from 'pluralize';
import { useRef } from 'react';
import { useController } from 'react-hook-form';

import { Text } from 'components/ui/Text';
import { useDisclosure } from 'hooks/useDisclosure';

import type { ChangeEvent } from 'react';
import type { Control, FieldValues, Path } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: Path<T>;
  templateDefault?: string;
  control: Control<T>;
};

const TEMPLATE_VARIABLES: { value: string; label: string }[] = [
  {
    value: '{{recruitment_shorlink}}',
    label: 'Recruitment short link',
  },
  {
    value: '{{user.meta.identity.firstname}}',
    label: 'First name',
  },
  {
    value: '{{project_incentive}}',
    label: 'Payout',
  },
  {
    value: '{{research_methods}}',
    label: 'Research methods',
  },
];

export const SMSTemplateInput = <T extends FieldValues>({ control, name }: Props<T>) => {
  const inputRef = useRef<TextArea>(null);
  const { onClose, isOpen, onOpen } = useDisclosure();

  const { field } = useController({
    name,
    control,
  });

  const handleVariableClick = (template: string) => () => {
    onClose();

    field.onChange(`${field.value.replace(/\\/g, '')}${template}`);

    inputRef?.current?.textareaElement?.focus();
  };

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.match(/\\/)) {
      onOpen();
    }

    return field.onChange(e);
  };

  const { chars_left, sms_count } = GSM(field.value);

  return (
    <Label>
      Template
      <Popover
        autoFocus
        isOpen={isOpen}
        onClose={onClose}
        content={
          <Menu>
            {TEMPLATE_VARIABLES.map(variable => {
              return (
                <MenuItem text={variable.label} key={variable.value} onClick={handleVariableClick(variable.value)} />
              );
            })}
          </Menu>
        }
      >
        <>
          <TextArea
            className="!tw-h-[12rem]"
            fill
            {...field}
            defaultValue={field.value}
            ref={inputRef}
            onChange={handleTextChange}
          />
          <div className="tw-flex tw-w-full tw-justify-between tw-my-2 tw-text-gray-400 tw-text-xs">
            <Text>
              <code>\ for variables</code>
            </Text>
            <Text>{`${sms_count} SMS ${pluralize('part', sms_count)} (${chars_left} ${pluralize('character', chars_left)} left)`}</Text>
          </div>
        </>
      </Popover>
    </Label>
  );
};
