import { Mutation } from '@apollo/client/react/components';
import { Button, Classes, Dialog, FormGroup, InputGroup, Radio, RadioGroup, Switch } from '@blueprintjs/core';
import _ from 'lodash';
import { Component } from 'react';

import deleteUser from '@graphql/mutations/user/deleteUser';
// import moment from 'moment';
import updateUser from '@graphql/mutations/user/updateUser';
import verifyUserPhone from '@graphql/mutations/user/verifyUserPhone';
import { AppConsumer, Spinner } from 'components/common';

import type { MutationResult, MutationFunction } from '@apollo/client';
import type { AppContext } from 'components/common';

type Props = {
  context: AppContext;
};

class Options extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.renderMergeDialog = this.renderMergeDialog.bind(this);
  }

  renderMergeDialog(user: any) {
    const { mergeDialog, mergeAction, mergeType, mergeOther } = this.state;
    switch (mergeDialog) {
      case 'confirm':
        return (
          <div>
            <div className={Classes.DIALOG_BODY}>
              <p>Copy & paste into a new dev request:</p> {/* TODO: connect to a mutation of some sort */}
              <blockquote className="tw-mt-2 tw-p-2 tw-border-solid tw-border tw-border-gray-500 tw-rounded">
                <p>Merge users:</p>
                <p>
                  Keep user: <code>{mergeAction?.keep}</code>
                </p>
                <p>
                  Delete user: <code>{mergeAction?.delete}</code>
                </p>
              </blockquote>
            </div>
            <div className={`${Classes.DIALOG_FOOTER} flex flex-justify-end`}>
              <Button
                text="OK"
                type="button"
                onClick={() => {
                  this.setState({ mergeDialog: null });
                }}
              />
            </div>
          </div>
        );
      case 'input':
        return (
          <form
            onSubmit={event => {
              event.preventDefault();
              const action = { keep: null, delete: null };
              switch (mergeType) {
                case 'delete':
                  action.keep = user._id;
                  action.delete = mergeOther;
                  break;
                case 'keep':
                  action.delete = user._id;
                  action.keep = mergeOther;
                  break;
                default:
              }
              // TODO: send details to slack / or server mutation
              this.setState({ mergeDialog: 'confirm', mergeAction: action });
            }}
          >
            <div className={Classes.DIALOG_BODY}>
              <RadioGroup
                label="Merge type"
                onChange={event => {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
                  this.setState({ mergeType: event.target.value });
                }}
                selectedValue={mergeType}
              >
                <Radio label="Keep this account" value="delete" required />
                <Radio label="Delete this account" value="keep" required />
              </RadioGroup>
              <br />
              <FormGroup label={`User ID to ${mergeType || 'merge with'}`}>
                <InputGroup
                  className={Classes.MONOSPACE_TEXT}
                  onChange={(event: any) => {
                    const mergeOther = event.target.value.substr(0, 24);
                    this.setState({ mergeOther });
                  }}
                  value={mergeOther || ''}
                  required
                  pattern="[a-z0-9]{24}"
                  title="User ID (24 hex characters)"
                />
              </FormGroup>
            </div>
            <div className={`${Classes.DIALOG_FOOTER} flex flex-justify-end`}>
              <Button
                text="Cancel"
                type="button"
                className="margin-right-1"
                onClick={() => {
                  this.setState({ mergeDialog: null });
                }}
              />
              <Button text="Merge" type="submit" intent="primary" />
            </div>
          </form>
        );
      default:
        return null;
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { user, loading, context } = this.props;

    return (
      <div className="padding-4 padding-3">
        <FormGroup label="Status">
          {loading ? (
            <Spinner />
          ) : (
            <Mutation mutation={updateUser}>
              {(mutate: any) => (
                <>
                  <Switch
                    label="Active"
                    defaultChecked={user.status}
                    onChange={event => {
                      const value = event.target.checked ? 1 : 0;
                      mutate({
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
                        variables: { user: { status: value }, _id: this.props.user._id },
                        refetchQueries: ['admin_getParticipantDetails'],
                      })
                        .then(() => {
                          this.props.context.newToast({ message: 'User status updated', intent: 'success' });
                        })
                        .catch(() => {
                          this.props.context.newToast({ message: 'Update failed', intent: 'danger' });
                        });
                    }}
                  />
                  <Switch
                    label="Blacklisted"
                    defaultChecked={user.blacklist}
                    onChange={event => {
                      const value = event.target.checked;
                      mutate({
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
                        variables: { user: { blacklist: value }, _id: this.props.user._id },
                        refetchQueries: ['admin_getParticipantDetails'],
                      })
                        .then(() => {
                          this.props.context.newToast({
                            message: value ? 'User blacklisted' : 'User unblacklisted',
                            intent: 'success',
                          });
                        })
                        .catch(() => {
                          this.props.context.newToast({ message: 'Update failed', intent: 'danger' });
                        });
                    }}
                  />
                </>
              )}
            </Mutation>
          )}
        </FormGroup>
        {!_.get(user, 'contact.phone.verification.mobile') && (
          <Mutation mutation={verifyUserPhone}>
            {(mutate: MutationFunction, { called, loading: mutationLoading, error }: MutationResult) => {
              const verificationUnavailable = !(_.get(user, 'contact.phone.mobile') && _.get(user, 'location.country'));
              const onClick = async () => {
                try {
                  await mutate({ variables: { id: user._id } });
                } catch (e: any) {
                  console.error(e);
                  this.props.context.newToast({ message: `Verification failed: ${e?.message}`, intent: 'danger' });
                }
              };
              if (called && !error && !mutationLoading) {
                return null;
              }
              return (
                <FormGroup
                  label="Phone verification"
                  helperText={
                    verificationUnavailable
                      ? 'Cannot verify this participant. Check the phone number and country on their profile'
                      : undefined
                  }
                >
                  <Button
                    icon="endorsed"
                    text="Verify"
                    className="margin-right-1"
                    onClick={onClick}
                    loading={mutationLoading}
                    disabled={mutationLoading || verificationUnavailable}
                  />
                </FormGroup>
              );
            }}
          </Mutation>
        )}

        <FormGroup label="Notification settings">
          <Button
            icon="share"
            text="Open in app"
            className="margin-right-1"
            onClick={() => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'participantAppNavigate' does not exist o... Remove this comment to see the full error message
              this.props.participantAppNavigate({ path: '/notifications', scroll: true });
            }}
          />
        </FormGroup>
        <FormGroup label="Account management">
          <Button
            icon="git-merge"
            text="Merge
                        accounts"
            className="margin-right-1"
            onClick={() => {
              this.setState({ mergeDialog: 'input' });
            }}
          />
          <Mutation mutation={deleteUser}>
            {(mutate: any) => (
              <Button
                icon="trash"
                text="Delete User"
                className="margin-right-1"
                loading={this.state.deleteUserLoading}
                disabled={this.state.deleteUserLoading}
                onClick={() => {
                  context.openAlert(
                    <p>Permanently delete this user?</p>,
                    'danger',
                    {
                      text: 'Delete',
                      callback: () => {
                        this.setState({ deleteUserLoading: true });
                        mutate({
                          // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
                          variables: { _id: this.props.user._id },
                          refetchQueries: ['admin_getParticipantDetails'],
                        })
                          .then(() => {
                            this.props.context.newToast({ message: 'User deleted', intent: 'success' });
                            // @ts-expect-error ts-migrate(2339) FIXME: Property 'refetch' does not exist on type 'Readonl... Remove this comment to see the full error message
                            this.props.refetch();
                            this.setState({ deleteUserLoading: null });

                            context.closeDialog();
                          })
                          .catch(() => {
                            this.props.context.newToast({ message: 'Failed to delete user', intent: 'danger' });
                            this.setState({ deleteUserLoading: null });
                          });
                      },
                    },
                    { text: 'Cancel', callBack: context.closeDialog },
                  );
                }}
              />
            )}
          </Mutation>
        </FormGroup>
        <Dialog
          isOpen={!!this.state.mergeDialog}
          className={Classes.DARK}
          // confirmButtonText="Merge"
          // cancelButtonText="Cancel"
          onClose={() => {
            this.setState({ mergeDialog: null });
          }}
        >
          {this.renderMergeDialog(user)}
        </Dialog>
      </div>
    );
  }
}

export default AppConsumer(Options);
