import gql from 'graphql-tag';

export const messagesFieldsFragment = gql`
  fragment messagesFields on Messages {
    _id
    type
    tag
    body
    seen
    created
    _to_user_id
    _from_user_id
    direction
    created
    UserFrom {
      _id
      meta {
        identity {
          firstname
        }
      }
    }
    context {
      _booking_id
      _user_id
    }
  }
`;
