import { Button, Classes, HTMLSelect, TextArea } from '@blueprintjs/core';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Dialog, Spinner } from 'components/common';
import { useRateUserSubmissionAnalysisMutation } from 'generated/graphql';

import type { UserSubmissionAnalysisFieldsFragment } from 'generated/graphql';
import type { ChangeEvent, FormEvent } from 'react';

type ScreenerAnalysisRatingDialogProps = {
  isOpen: boolean;
  analysis: UserSubmissionAnalysisFieldsFragment;
  onClose: () => void;
};

export const ScreenerAnalysisRatingDialog = ({ isOpen, analysis, onClose }: ScreenerAnalysisRatingDialogProps) => {
  const [rateAnalysisMutationResult, rateAnalysisMutation] = useRateUserSubmissionAnalysisMutation();

  const [ratingInconsistencyVerdictAccurate, setRatingInconsistencyVerdictAccurate] = useState<boolean | undefined>();
  const [ratingInconsistencyReasoningAccurate, setRatingInconsistencyReasoningAccurate] = useState<
    boolean | undefined
  >();
  const [ratingUserWasDishonest, setRatingUserWasDishonest] = useState<boolean | undefined>();
  const [ratingComments, setRatingComments] = useState<string | undefined>();

  const [editMode, setEditMode] = useState(!analysis.rating?._admin_user_id);

  useEffect(() => {
    if (isOpen) {
      setRatingInconsistencyVerdictAccurate(undefined);
      setRatingInconsistencyReasoningAccurate(undefined);
      setRatingUserWasDishonest(undefined);
      setRatingComments(undefined);
    }
  }, [isOpen]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log({
      ratingInconsistencyVerdictAccurate,
      ratingInconsistencyReasoningAccurate,
      ratingUserWasDishonest,
      ratingComments,
    });
    if (
      ratingInconsistencyVerdictAccurate === undefined ||
      ratingInconsistencyReasoningAccurate === undefined ||
      ratingUserWasDishonest === undefined
    ) {
      return;
    }
    rateAnalysisMutation({
      _id: analysis._id,
      rating: {
        inconsistency_reasoning_accurate: ratingInconsistencyReasoningAccurate,
        inconsistency_verdict_accurate: ratingInconsistencyVerdictAccurate,
        user_was_dishonest: ratingUserWasDishonest,
        comments: ratingComments?.trim(),
      },
    })
      .then(() => {
        onClose();
      })
      .catch(e => {
        console.error(e);
        toast.error('Error creating analysis - see console for details');
      });
  };

  const jsonSelectValue = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    try {
      return JSON.parse(e.target.value);
    } catch {
      return undefined;
    }
  }, []);

  const commentsRequired = useMemo(
    () => ratingInconsistencyVerdictAccurate === false || ratingInconsistencyReasoningAccurate === false,
    [ratingInconsistencyVerdictAccurate, ratingInconsistencyReasoningAccurate],
  );

  const renderSelectValue = useCallback(
    (value: boolean | null | undefined, [ifTrue, ifFalse, ifNull, ifElse]: [string, string, string, string]) => {
      if (value === true && ifTrue) {
        return ifTrue;
      }
      if (value === false && ifFalse) {
        return ifFalse;
      }
      if (value === null && ifNull) {
        return ifNull;
      }
      return ifElse;
    },
    [],
  );

  if (rateAnalysisMutationResult.fetching) {
    return (
      <Dialog isOpen title="Rate this analysis">
        <div className={Classes.DIALOG_BODY}>
          <Spinner />
        </div>
      </Dialog>
    );
  }

  if (editMode) {
    return (
      <Dialog isOpen={isOpen} onClose={onClose} title="Rate this analysis">
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <div className="tw-flex tw-flex-col tw-space-y-4 tw-items-stretch">
              <div className="tw-w-full tw-flex tw-flex-row tw-space-x-4 tw-items-center tw-justify-between">
                <label htmlFor="_rating_inconsistency_verdict_accurate">Does the Inconsistency % seem accurate?</label>
                <HTMLSelect
                  id="_rating_inconsistency_verdict_accurate"
                  required
                  defaultValue=""
                  autoFocus
                  onChange={e => {
                    setRatingInconsistencyVerdictAccurate(jsonSelectValue(e));
                  }}
                  options={[
                    { label: '-', value: '', disabled: true },
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                  ]}
                />
              </div>
              <div className="tw-w-full tw-flex tw-flex-row tw-space-x-4 tw-items-center tw-justify-between">
                <label htmlFor="_rating_inconsistency_reasoning_accurate">Is the Reasoning correct and valid?</label>
                <HTMLSelect
                  id="_rating_inconsistency_reasoning_accurate"
                  required
                  defaultValue=""
                  onChange={e => {
                    setRatingInconsistencyReasoningAccurate(jsonSelectValue(e));
                  }}
                  options={[
                    { label: '-', value: '', disabled: true },
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                  ]}
                />
              </div>
              <div className="tw-w-full tw-flex tw-flex-row tw-space-x-4 tw-items-center tw-justify-between">
                <label htmlFor="_rating_user_was_dishonest">Has this user lied in the sampled submissions?</label>
                <HTMLSelect
                  id="_rating_user_was_dishonest"
                  required
                  defaultValue=""
                  onChange={e => {
                    setRatingUserWasDishonest(jsonSelectValue(e));
                  }}
                  options={[
                    { label: '-', value: '', disabled: true },
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                    { value: 'null', label: 'Inconclusive' },
                  ]}
                />
              </div>
              <div>
                <label htmlFor="_rating_comments" className="tw-block tw-mb-2">
                  Additional comments ({commentsRequired ? 'required' : 'optional'}):
                </label>
                <TextArea
                  fill
                  id="_rating_comments"
                  // placeholder="Additional comments"
                  required={commentsRequired}
                  value={ratingComments ?? ''}
                  rows={5}
                  onChange={e => {
                    setRatingComments(e.target.value?.trimStart());
                  }}
                />
              </div>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button type="submit" intent="primary">
                Save
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Rate this analysis">
      <div className={Classes.DIALOG_BODY}>
        <div className="tw-flex tw-flex-col tw-space-y-4 tw-items-stretch">
          <div className="tw-w-full tw-flex tw-flex-row tw-space-x-4 tw-items-center tw-justify-between">
            <span>Rating saved:</span>
            <em>{analysis?.rating?.date ? moment(analysis?.rating?.date).format('D MMM Y, h:ma') : ''}</em>
          </div>
          <div className="tw-w-full tw-flex tw-flex-row tw-space-x-4 tw-items-center tw-justify-between">
            <span>Does the Inconsistency % seem accurate?</span>
            <em>{renderSelectValue(analysis?.rating?.inconsistency_verdict_accurate, ['Yes', 'No', '', 'N/A'])}</em>
          </div>
          <div className="tw-w-full tw-flex tw-flex-row tw-space-x-4 tw-items-center tw-justify-between">
            <span>Is the Reasoning correct and valid?</span>
            <em>{renderSelectValue(analysis?.rating?.inconsistency_reasoning_accurate, ['Yes', 'No', '', 'N/A'])}</em>
          </div>
          <div className="tw-w-full tw-flex tw-flex-row tw-space-x-4 tw-items-center tw-justify-between">
            <span>Has this user lied in the sampled submissions?</span>
            <em>
              {renderSelectValue(analysis?.rating?.inconsistency_reasoning_accurate, [
                'Yes',
                'No',
                'Inconclusive',
                'N/A',
              ])}
            </em>
          </div>
          <div>
            <p>Additional comments:</p>
            <p>{analysis?.rating?.comments || 'N/A'}</p>
          </div>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            type="submit"
            intent="primary"
            onClick={() => {
              setEditMode(true);
            }}
          >
            Change
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
