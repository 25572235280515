import gql from 'graphql-tag';

import { userStatusCountFieldsFragment } from './userStatusCountFields';

export const BookingPageBookingFields = gql`
  fragment BookingPageBookingFields on Booking {
    _id
    status
    _owner_id
    _admin_id
    admin_user {
      _id
      picture
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
    name
    type
    total_participants
    team_booking_index
    _team_id
    settings {
      translation
    }
    CurrencyData {
      currency_code
      currency_symbol
      country_code
    }
    admin {
      emoji
      comments {
        _id
        _user_id
        created
        comment
        User {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
      mission_critical
      mission_critical_reason
    }
    config {
      min_participant_rating
      credits_per_participant
      participant_category
      timezone
      continuous_discovery
      hidden
      countries
      location {
        country
        state
        region
        postal_code
        city
        name
        level
        street1
        street2
        latitude
        longitude
      }
      session {
        type
        duration
        duration
        time_limit
        slot_min
        slot_max
      }
      incentive {
        currency_symbol
        value
      }
      incentives {
        currency_code
        currency_symbol
        country_code
        value
      }
      incentives {
        value
        currency_code
        currency_symbol
        country_code
      }
      criteria {
        locations {
          countries {
            name
            country
            currency_code
            currency_symbol
          }
          bounds {
            name
            country
            currency_code
            currency_symbol
          }
          states {
            name
            country
            currency_code
            currency_symbol
          }
        }
        meta_identity_locales {
          locale
        }
      }
      contact {
        email
        name
        phone
        UserOrganiser {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
          contact {
            phone {
              country_code
              mobile
            }
          }
        }
        UserFacilitator {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
          contact {
            phone {
              country_code
              mobile
            }
          }
        }
      }
      # contacts { _user_id } # Not reliable at this point
      options {
        review_submission
      }
      online_task {
        type
        tool
      }
      longitudinal_study {
        period {
          time
          frequency
        }
      }
      project {
        project_type
        allowed_credits
      }
      block_participants {
        unverified_paypal
        giftpay
      }
    }
    session {
      _id
      start
      end
      status
    }
    user {
      _id
      email
      contact {
        phone {
          mobile
        }
      }
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
    team {
      _id
      name
      operational_office
      users {
        _id
        User {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
    }
    UserStatusCount {
      ...userStatusCountFields
    }
    booking_participant(participant_status: [1, 4]) {
      _id
      _session_id
      _user_id
      cancel
      status
    }
    credit_activity {
      _id
      type
      accounting_type
      amount
      comment
      created
      Transaction {
        _id
        total_amount
        currency
        comment
        method
        invoice {
          amount_due
          purchase_order
          reference
          xero_id
        }
      }
      User {
        _id
        email
        type {
          client
          admin
        }
        meta {
          identity {
            firstname
            lastname
          }
        }
      }
    }
    history {
      completed_date
    }
  }
  ${userStatusCountFieldsFragment}
`;
