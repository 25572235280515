import { useEffect } from 'react';

import { AppConsumer, Spinner } from 'components/common';

const ReportsAuth = () => {
  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (!token) return;

    const params = new URLSearchParams(window.location.search);

    const reportsLink = `https://reports.askable.com/admin-auth?redirect=${encodeURIComponent(params.get('redirect') || '/')}&token=${token}`;
    window.location.href = reportsLink;
  }, []);
  return (
    <div className="content-page">
      <Spinner />
    </div>
  );
};

export default AppConsumer(ReportsAuth);
