import { datadogRum } from '@datadog/browser-rum';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';

import { AppConsumer, ErrorCallout } from 'components/common';
import { useAdmin_LoginMutation, UserRoles } from 'generated/graphql';
import { localStorage } from 'lib/storage';

import type { CredentialResponse } from '@react-oauth/google';

type GoogleDecodedToken = {
  email: string;
  emailVerified: boolean;
  name: string;
};

const afterLogin = (loginId: string, accessToken: string) => {
  localStorage.save('user', loginId);
  localStorage.save('auth_token', accessToken);

  const loginRedirect = window.history?.state?.usr?.loginRedirect || '/booking';
  window.location.href = loginRedirect;
};

export const Login = () => {
  const [, loginUser] = useAdmin_LoginMutation();
  const [er, setEr] = useState<string | null>(null);
  const onSuccess = async (cr: CredentialResponse) => {
    const decodedToken = jwtDecode<GoogleDecodedToken>(cr.credential!);

    if (!decodedToken.email.match(/.+@askable\.com$/)) {
      setEr('Please log in with an askable.com email address');
      return;
    }

    const { data } = await loginUser({
      email: decodedToken.email,
      googleToken: cr.credential,
    });

    if (!data?.login?.roles?.includes(UserRoles.Admin)) {
      setEr(`User "${decodedToken.email}" does not have Admin access`);
      return;
    }
    datadogRum.setUser({
      id: data?.login?._id,
      email: data?.login?.email ?? undefined,
      name: `${data?.login?.meta?.identity?.firstname} ${data?.login?.meta?.identity?.lastname}`,
    });
    const accessToken = data.login.access_token;
    if (!accessToken) {
      setEr('Access token is not found');
      return;
    }
    afterLogin(data.login._id, accessToken);
  };
  const onError = () => {
    setEr('An unknown error has occured');
  };

  return (
    <div className="tw-h-full tw-w-full tw-flex tw-items-center tw-mt-20 tw-flex-col tw-max-w-md tw-m-auto">
      <GoogleLogin size="large" auto_select onSuccess={onSuccess} onError={onError} />
      <div className="tw-flex tw-w-full tw-mt-6">{er && <ErrorCallout className="margin-bottom-1" error={er} />}</div>
    </div>
  );
};

export default AppConsumer(Login);
