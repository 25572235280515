import { Callout } from '@blueprintjs/core';
import _ from 'lodash';

import type { FC } from 'react';

const calloutContents = ({ children, error, title }: any) => {
  if (children) {
    return children;
  }
  const errors: any[] = _.get(error, 'networkError.result.errors', []);
  if (errors.length > 0) {
    return errors.map((graphQLError: any, index: number) => <p key={index}>{graphQLError.message}</p>);
  }
  if (typeof error === 'string' && error !== title) {
    return <p>{error}</p>;
  }
  if (error.message) {
    if (error.message === title) return;
    return <p>{error.message}</p>;
  }
  return <pre>{JSON.stringify(error, undefined, 2)}</pre>;
};

const ErrorCallout: FC<any> = ({ children, title, error, ...props }) => {
  const titleProp = title || error.message || 'Error';
  return (
    <Callout intent="danger" title={titleProp} {...props}>
      {calloutContents({ children, error, title: titleProp })}
    </Callout>
  );
};

export default ErrorCallout;
