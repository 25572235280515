import gql from 'graphql-tag';

import { messagesFieldsFragment } from '@graphql/fragments/messages/messagesFields';

export default gql`
  mutation createMessage($message: MessagesInput!) {
    createMessage(message: $message) {
      ...messagesFields
    }
  }
  ${messagesFieldsFragment}
`;
