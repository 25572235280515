import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import _ from 'lodash';

import countryData from '../../lib/phoneCountryCodes.json';

export type CountryData = (typeof countryData)[0];

const PhoneCountrySelect = ({ onChange, text, limit, popoverProps, buttonProps, ...props }: any) => (
  <Select
    {...props}
    items={countryData}
    itemListPredicate={(query, items) =>
      _.chain(items)
        .filter(
          item =>
            //  @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'. */}
            query.toUpperCase() === item.region.toUpperCase() ||
            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            item.country_code.match(new RegExp(query, 'i')) ||
            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            item.name.match(new RegExp(query, 'i')),
        )
        .sortBy(['pop_rank', 'country_name'])
        .slice(0, limit || 10)
        .value()
    }
    itemRenderer={(item, { modifiers }) => (
      <MenuItem
        active={modifiers.active}
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        key={item.region}
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        text={item.name}
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        label={item.country_code}
        onClick={() => {
          onChange?.(item);
        }}
      />
    )}
    onItemSelect={onChange}
    popoverProps={{
      minimal: true,
      ...popoverProps,
    }}
  >
    <Button text={text || ''} rightIcon="caret-down" {...buttonProps} />
  </Select>
);

export default PhoneCountrySelect;
