import { ControlGroup, HTMLSelect, InputGroup } from '@blueprintjs/core';
import { Component } from 'react';

import type { HTMLSelectProps } from '@blueprintjs/core';

const otherOption = { label: 'Other...', value: '_other' };

type SelectValue = { label: string; value: string | number };

type Props = {
  value?: string | null;
  options: SelectValue[];
  id?: string;
  disabled?: boolean;
  other?: boolean;
  placeholder?: string;
  className?: string;
  triggerChangeOnMount?: boolean;
  onChange?: (v: string | null) => void;
  selectProps?: HTMLSelectProps;
};

type State = {
  other: boolean;
  selectValue: string | null;
  otherValue: string;
};
class Select extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      other: false,
      selectValue: props.value || null,
      otherValue: '',
    };
  }

  static defaultProps = {
    other: false,
    triggerChangeOnMount: false,
  };

  componentDidMount() {
    if (this.props.triggerChangeOnMount && this.props.onChange) {
      this.props.onChange(this.state.selectValue);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.value !== prevProps.value && this.props.value !== prevState.selectValue) {
      this.setState({ selectValue: this.props.value as string | null });
    }
  }

  render() {
    const { options } = this.props;

    if (this.props.other) {
      options?.push(otherOption);
    }
    if (!(this.state.selectValue || this.props.value) && this.props.placeholder) {
      // options.unshift({ label: this.props.placeholder, value: '_placeholder', disabled: true });
      options.unshift({ label: this.props.placeholder, value: '' });
    }

    const select = (
      <HTMLSelect
        className={this.props.className}
        id={this.props.id}
        {...this.props.selectProps}
        options={options}
        // value={this.state.selectValue || (this.props.placeholder ? '_placeholder' : '')}
        value={this.state.selectValue || ''}
        disabled={!!this.props.disabled}
        onChange={event => {
          const { value } = event.target;
          const setState = { selectValue: value };
          let setValue = value;

          if (value === otherOption.value) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'other' does not exist on type '{ selectV... Remove this comment to see the full error message
            setState.other = true;
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
            setValue = null;
            // setState.otherValue = '';
          } else {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'other' does not exist on type '{ selectV... Remove this comment to see the full error message
            setState.other = false;
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'otherValue' does not exist on type '{ se... Remove this comment to see the full error message
            setState.otherValue = '';
          }

          this.setState(setState);
          if (this.props.onChange) {
            this.props.onChange(setValue);
          }
        }}
      />
    );

    if (this.state.other) {
      return (
        <ControlGroup>
          {select}

          <InputGroup
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'otherProps' does not exist on type 'Read... Remove this comment to see the full error message
            {...this.props.otherProps}
            value={this.state.otherValue}
            onChange={(event: any) => {
              const { value } = event.target;
              this.setState({ otherValue: value });
              if (this.state.other && this.props.onChange) {
                this.props.onChange(value);
              }
            }}
          />
        </ControlGroup>
      );
    }

    return select;
  }
}

export default Select;
