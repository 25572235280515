import { Button } from '@blueprintjs/core';
import Modal from 'react-modal';

import { Text } from 'components/ui/Text';

import type { ComponentPropsWithRef, FC, ReactNode } from 'react';

type ConfirmDialogProps = {
  isOpen: boolean;
  heading: string;
  body: string;
  loading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

Modal.setAppElement('#react-modal-root');

const customStyles = {
  content: {
    top: '20%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    borderRadius: 4,
    marginRight: '-50%',
    padding: 0,
    maxWidth: '25rem',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalBG = ({ style, ...props }: ComponentPropsWithRef<'div'>, el: ReactNode) => {
  return (
    <div {...props} style={{ ...style, backgroundColor: 'rgba(0, 0, 0, 0.25)' }} className="tw-bg-red-400">
      {el}
    </div>
  );
};

export const ConfirmDialog: FC<ConfirmDialogProps> = ({ heading, body, isOpen, onCancel, onConfirm, loading }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel={heading}
      style={customStyles}
      overlayElement={ModalBG}
    >
      <div className="tw-py-4 tw-px-8">
        <div className="tw-mb-10">
          <Text className="tw-text-xl tw-mb-2 tw-font-medium">{heading}</Text>
          <p>{body}</p>
        </div>

        <div className="tw-ml-auto tw-space-x-2 tw-justify-end tw-flex">
          <Button minimal onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
          <Button onClick={onConfirm} intent="primary" loading={loading}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
