import gql from 'graphql-tag';

import { BookingPageBookingFields } from '../../fragments/booking/bookingPageBooking';

export default gql`
  query admin_BookingByID($id: ID!) {
    bookingByID(id: $id) {
      ...BookingPageBookingFields
      history {
        completed_date
      }
      project {
        _id
        name
        type
      }
      admin {
        tags {
          nufp
        }
      }
      ParticipantSessionsCount {
        completed
        scheduled
        available
        invited
        waiting_list
        in_progress
        total
        total_eligible
        total_refunded
      }
    }
  }
  ${BookingPageBookingFields}
`;
