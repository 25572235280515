import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';

export function normalizeText(text: string): string {
  // We go lowerCase first because that utility removes underscores and dashes from text
  return capitalize(lowerCase(text));
}

export function downloadTextFile(data: string, fileName: string) {
  const a: HTMLAnchorElement = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  const blob = new Blob([data], { type: 'octet/stream' });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  // remove anchor element after 2 seconds
  setTimeout(() => {
    a.remove();
  }, 2000);
}
