import { Button, H5 } from '@blueprintjs/core';
import classNames from 'classnames';
import { crush } from 'radash';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useUpdateBookingAdminMutation } from 'generated/graphql';

import type { Booking, BookingInput, Maybe } from 'generated/graphql';
import type { FC } from 'react';
import type { FieldValues, UseFormRegister } from 'react-hook-form';

type ReturnType =
  | {
      type: 'string';
      parsedValue: string;
    }
  | {
      type: 'boolean';
      parsedValue: boolean;
    }
  | {
      type: 'number';
      parsedValue: number;
    };

type WhiteList = {
  [key: string]: {
    label: string;
    type?: ReturnType['type'];
    additionalProps?: Record<string, unknown>;
  };
};

const WHITE_LIST: WhiteList = {
  name: {
    label: 'Booking title',
  },
  total_participants: {
    label: 'Quota',
    type: 'number',
  },
  'config.continuous_discovery': {
    label: 'Continuous discovery',
    type: 'boolean',
  },
  'config.min_participant_rating': {
    label: 'Minimum participant rating',
    type: 'number',
    additionalProps: {
      step: 0.01,
    },
  },
  'config.session.slot_min': {
    label: 'Minimum slots per session',
  },
  'config.session.slot_max': {
    label: 'Maximum slots per session',
  },
  'config.session.duration': {
    label: 'Session duration',
  },
  'config.session.time_limit': {
    label: 'Time limit',
  },
  'config.project.allowed_credits': {
    label: 'Allocated credits',
  },
  'config.block_participants.unverified_paypal': {
    label: 'Block unverified PayPal participants',
    type: 'boolean',
  },
  'config.block_participants.giftpay': {
    label: 'Block Giftpay participants',
    type: 'boolean',
  },
  'config.location.name': {
    label: 'Location name',
  },
  'config.location.level': {
    label: 'Level',
  },
  'config.location.street1': {
    label: 'Street 1',
  },
  'config.location.street2': {
    label: 'Street 2',
  },
  'config.location.city': {
    label: 'City',
  },
  'config.location.state': {
    label: 'State',
  },
  'config.location.country': {
    label: 'Country',
  },
  'config.location.postal_code': {
    label: 'Postal code',
  },
  'config.location.longitude': {
    label: 'Longitude',
  },
  'config.location.latitude': {
    label: 'Latitude',
  },
};

function castFieldValue(value: unknown, type: ReturnType['type']) {
  switch (type) {
    case 'boolean':
      return value === 'true';
    case 'number':
      return Number(value);
    case 'string':
    default:
      return value?.toString() ?? '';
  }
}

function getFieldType(v: unknown): ReturnType {
  if (typeof v === 'number') {
    return {
      type: 'number',
      parsedValue: v as number,
    };
  }

  if (typeof v === 'boolean' || v === 'true' || v === 'false') {
    return { type: 'boolean', parsedValue: v === 'true' ? true : v === 'false' ? false : v };
  }

  return {
    type: 'string',
    parsedValue: v as string,
  };
}

const FORM_FIELD_ORDERS = Object.keys(WHITE_LIST);

type BookingUpdateContainerProps = {
  onClose: () => void;
  bookingId: string;
  booking?: Maybe<Booking>;
};

function getDirtyValues(dirtyFields: { [x: string]: any } | true, allValues: { [x: string]: any }): object {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [key, getDirtyValues(dirtyFields[key], allValues[key])]),
  );
}

export const BookingUpdateContainer: FC<BookingUpdateContainerProps> = ({ booking, bookingId, onClose }) => {
  const [{ fetching: updating }, updateAdminBooking] = useUpdateBookingAdminMutation();
  const { register, handleSubmit, formState, reset } = useForm<BookingInput>({
    defaultValues: (booking ?? {}) as BookingInput,
  });

  const { isDirty, dirtyFields } = formState;

  const onSubmit = async (v: BookingInput) => {
    try {
      await updateAdminBooking({
        booking_id: bookingId,
        booking: getDirtyValues(dirtyFields, v),
      });

      reset(v);

      onClose();
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-h-screen tw-p-4">
      <H5>Update Booking</H5>
      <div>
        <BookingObject booking={booking} register={register} depth={0} />
        <Button loading={updating} disabled={!isDirty} type="submit" className="tw-my-4 tw-w-full">
          Save
        </Button>
      </div>
    </form>
  );
};

type BookingObjectProps = Pick<BookingUpdateContainerProps, 'booking'> & {
  depth?: number;
  updatePath?: string;
  register: UseFormRegister<FieldValues>;
};

function getInputType(type: ReturnType['type']): HTMLInputElement['type'] {
  switch (type) {
    case 'boolean':
      return 'checkbox';
    case 'number':
      return 'number';
    case 'string':
    default:
      return 'text';
  }
}

export const BookingObject: FC<BookingObjectProps> = ({ booking, register }) => {
  const crushedBooking: Record<string, unknown> = useMemo(() => {
    if (!booking) {
      return {};
    }
    return crush(booking) as Record<string, unknown>;
  }, [booking]);

  if (!booking) {
    return null;
  }

  return (
    <>
      {FORM_FIELD_ORDERS.map(key => {
        const whiteList = WHITE_LIST[key];

        if (!whiteList) {
          return null;
        }

        const value = crushedBooking[key];

        const { type, parsedValue } = getFieldType(whiteList.type ? castFieldValue(value, whiteList.type) : value);
        const inputType = getInputType(type);

        const { className, ...additionalProps } = (() => {
          if (type === 'boolean') {
            return {
              defaultChecked: parsedValue,
              className: 'tw-w-auto',
            };
          }

          return {
            defaultValue: parsedValue,
            step: type === 'number' && !Number.isInteger(parsedValue) ? 'any' : undefined,
            ...whiteList.additionalProps,
            className: 'tw-w-full',
          };
        })();

        return (
          <div className="tw-py-2 tw-w-full" key={key}>
            <label htmlFor={key} className="tw-flex tw-flex-col tw-font-medium tw-mb-1">
              {whiteList.label}
            </label>
            <input
              id={key}
              type={inputType}
              className={classNames('tw-w-full tw-text-slate-900 tw-border tw-rounded-md tw-p-1', className)}
              {...additionalProps}
              {...register(key, { valueAsNumber: inputType === 'number' })}
            />
          </div>
        );
      })}
    </>
  );
};
