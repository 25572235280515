/* eslint-disable max-lines */
import axios from 'axios';
import { PhoneNumberUtil, PhoneNumberType, PhoneNumberFormat } from 'google-libphonenumber';
import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { OperationalOffices } from 'generated/graphql';

import { localStorage } from './storage';

import type { MomentInput } from 'moment';

export interface ParsedPhonenumber {
  numberType: string;
  format: {
    E164: string;
    national: string;
    international: string;
  };
  valid: boolean;
  countryCode: string;
  regionCode?: string;
}

export interface ParsedPhonenumberError {
  error: any;
  params: {
    input: string;
    regionCode?: string;
  };
}

export type ParsedPhoneNumberOrError = ParsedPhonenumber | ParsedPhonenumberError;

const phoneUtil = PhoneNumberUtil.getInstance();

const utils = {
  escapeRegexCharacters(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  },
  formatTimestampToDate(timestampInput: MomentInput, format = 'D MMM YYYY hh:mma') {
    return moment(timestampInput).format(format);
  },
  formatTimeFromX(startTimestamp: any, endTimestamp: any) {
    return moment(startTimestamp).from(moment(endTimestamp), true);
  },
  formatTimeToNow(startTimestamp: any) {
    return moment(startTimestamp).toNow(true);
  },
  camelize(str: any) {
    if (str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: any, index: any) => {
        if (+match === 0) return '';
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    }
    return str;
  },
  capitalize(str: any) {
    if (str === undefined) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  removeAllBlankSpaces(str: any) {
    return str.replace(/ /g, '');
  },
  getCurrentYear() {
    return new Date().getFullYear();
  },
  isAuthenticated() {
    // console.log({ user: localStorage.get('user'), auth_token: localStorage.get('auth_token') });
    return !!(localStorage.get('user') && localStorage.get('auth_token'));
  },
  getUserId() {
    return localStorage.get('user') || null;
  },
  parsePhoneNumber(input: string, regionCode?: string): ParsedPhoneNumberOrError {
    try {
      const number = phoneUtil.parseAndKeepRawInput(input, regionCode || 'AU');
      const numberType = _.invert(PhoneNumberType)[phoneUtil.getNumberType(number)];
      const format = {
        E164: phoneUtil.format(number, PhoneNumberFormat.E164),
        national: phoneUtil.format(number, PhoneNumberFormat.NATIONAL),
        international: phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL),
      };
      const fetchedRegionCode = regionCode ?? phoneUtil.getRegionCodeForNumber(number);
      const valid = phoneUtil.isValidNumberForRegion(number, fetchedRegionCode);
      const countryCode = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL).split(' ')[0];
      return {
        numberType,
        format,
        valid,
        countryCode,
        regionCode: fetchedRegionCode,
      };
    } catch (e) {
      return {
        params: { input, regionCode },
        error: e,
      };
    }
  },
  fotmatPhoneNumber(input: string, format?: 'E164' | 'national' | 'international') {
    const number = utils.parsePhoneNumber(input);
    if (!number || 'error' in number || !number.valid) return input;
    if (format && number.format[format]) return number.format[format];
    return input;
  },
  urlParams(searchString: any) {
    const findParams = searchString.match(/(?:^|\?|&)([^?&]+?)(?=&|$)/g);
    const params = {};
    if (findParams) {
      let parts = null;
      for (let i = 0; i < findParams.length; i += 1) {
        parts = decodeURIComponent(findParams[i]).match(/([^?&=]+)/g);
        if (parts) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          params[parts[0]] = parts[1];
        }
      }
    }
    return params;
  },
  urlParam(searchString: any, key: any) {
    const match = searchString.match(new RegExp(`(?:^|\\?|&)(?:${key}=)(.+?)(?=&|$)`));
    return match ? match[1] : null;
  },
  urlSerialize(obj: any) {
    const str = [];
    for (const p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
      }
    }
    return str.join('&');
  },
  fbPixelTrack(event: any, params = null) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fbq' does not exist on type 'Window & ty... Remove this comment to see the full error message
    if (window.fbq) {
      if (params) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fbq' does not exist on type 'Window & ty... Remove this comment to see the full error message
        window.fbq('track', event, params);
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fbq' does not exist on type 'Window & ty... Remove this comment to see the full error message
        window.fbq('track', event);
      }
    }
  },
  geoBoundingBox(latitude: any, longitude: any, km: any) {
    const degLatKm = 111;
    const degLngKm = Math.cos(latitude * (Math.PI / 180)) * 111;
    return {
      minLatitude: latitude - km / degLatKm,
      maxLatitude: latitude + km / degLatKm,
      minLongitude: longitude - km / degLngKm,
      maxLongitude: longitude + km / degLngKm,
    };
  },
  leaveWarningSet(message: any) {
    // console.log('leaveWarningSet', message);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'leaveWarning' does not exist on type 'Wi... Remove this comment to see the full error message
    window.leaveWarning = message;
  },
  leaveWarningDelete() {
    // console.log('leaveWarningDelete');
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'leaveWarning' does not exist on type 'Wi... Remove this comment to see the full error message
    if (window.leaveWarning) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'leaveWarning' does not exist on type 'Wi... Remove this comment to see the full error message
      delete window.leaveWarning;
    }
  },
  leaveWarningGet() {
    // console.log('leaveWarningGet');
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'leaveWarning' does not exist on type 'Wi... Remove this comment to see the full error message
    return window.leaveWarning || false;
  },
  leaveWarningPrompt() {
    // console.log('leaveWarningPrompt');
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'leaveWarning' does not exist on type 'Wi... Remove this comment to see the full error message
    if (window.leaveWarning) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'leaveWarning' does not exist on type 'Wi... Remove this comment to see the full error message
      const proceed = window.confirm(window.leaveWarning);
      if (proceed) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'leaveWarning' does not exist on type 'Wi... Remove this comment to see the full error message
        delete window.leaveWarning;
      }
      return proceed;
    }
    return true;
  },
  urlPatternReplace(url: any, data: any) {
    let treatedUrl = url;
    const patterns = url.match(/{{(.+?)}}/g);
    if (patterns) {
      for (let i = 0; i < patterns.length; i += 1) {
        treatedUrl = treatedUrl.replace(patterns[i], _.get(data, patterns[i].replace(/^{+|}+$/g, ''), ''));
      }
    }
    return treatedUrl;
  },
  findFirstDiffPos(a: any, b: any) {
    let i = 0;
    if (a === b) return -1;
    while (a[i] === b[i]) i += 1;
    return i;
  },
  async copy(text: any, onSuccess: any, onError: any) {
    if (!window.navigator.clipboard) {
      const textArea = document.createElement('textarea');
      document.body.appendChild(textArea);
      textArea.value = text;
      textArea.focus();
      textArea.setSelectionRange(0, text.length);

      try {
        const successful = document.execCommand('copy');
        if (!successful) {
          throw new Error("document.execCommand('copy') returned false");
        }
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        textArea.blur();
        document.body.removeChild(textArea);
        if (onError) {
          onError();
        }
      }
      textArea.blur();
      document.body.removeChild(textArea);
      return;
    }

    let nativeSuccessful = true;
    await window.navigator.clipboard.writeText(text).catch(err => {
      console.error('Async: Could not copy text: ', err);
      nativeSuccessful = false;
      if (onError) {
        onError();
      }
    });

    if (nativeSuccessful && onSuccess) {
      onSuccess();
    }
  },

  // @ts-expect-error ts-migrate(7023) FIXME: 'fetchLocationByIPAddress' implicitly has return t... Remove this comment to see the full error message
  async fetchLocationByIPAddress(field: any, force = false) {
    let cachedLocation = null,
      data = {};
    if (!force) {
      try {
        cachedLocation = JSON.parse(localStorage.get('fetchLocationByIPAddress', 'session'));
      } catch {
        cachedLocation = null;
      }
    }
    if (cachedLocation) {
      data = cachedLocation;
    } else {
      const result = await axios
        .get('https://api.ipdata.co?api-key=38269e8930e54b25b7ca59a6c78249a090bd8688f43ae7a334521568')
        .catch(error => {
          console.error('fetchLocationByIPAddress', error);
          return null;
        });
      data = result && result.data;
      localStorage.save('fetchLocationByIPAddress', JSON.stringify(data), 'session');
    }
    if (field) {
      if (field.length) {
        let allFound = true;
        const values = {};
        field.forEach((key: any) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          values[key] = _.get(data, field, null);
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          allFound = allFound && !!values[key];
        });
        if (allFound) {
          return values;
        }
      } else {
        const dataField = _.get(data, field, null);
        if (dataField) {
          return dataField;
        }
      }
      if (data && !force) {
        return utils.fetchLocationByIPAddress(field, true);
      }
      return null;
    }
    console.warn("Returning the whole locaiton object doesn't check for specific fields");
    return data;
  },

  returnRandom(...params: any[]) {
    const i = Math.floor(Math.random() * params.length) % params.length;
    return params[i];
  },

  round(value: any, precision = 0) {
    const multiplier = 10 ** precision;
    return Math.round(value * multiplier) / multiplier;
  },

  deepDiff(object: any, compareWith: any) {
    function changes(_object: any, _compareWith: any) {
      return _.transform(_object, (result, value, key) => {
        if (!_.isEqual(value, _compareWith[key])) {
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          result[key] = _.isObject(value) && _.isObject(_compareWith[key]) ? changes(value, _compareWith[key]) : value;
        }
      });
    }
    return changes(object, compareWith);
  },

  filterForDOM: (props: any, tag: any) => {
    const filteredProps = { ...props };
    delete filteredProps.staticContext;
    switch (tag) {
      // case 'a':
      //
      // break;
      default:
    }
    return filteredProps;
    // const valid = ['className'];
    // return _.pick(props, valid);
  },

  regionalIndicatorSymbol(letter: any) {
    const letterCode = letter.toUpperCase().charCodeAt(0);
    return String.fromCharCode(55356, 56806 - 65 + letterCode);
  },

  countryCodeToFlag(code?: string) {
    if (!code) {
      return '';
    }
    return code.split('').map(utils.regionalIndicatorSymbol).join('');
  },

  formatCurrency(amount: any, currency: any, { symbol = true, code = true, nonBreaking = true, decimals = 2 } = {}) {
    if (!(amount || amount === 0)) return '';

    const value = [];
    if (symbol) value.push(utils.currencySymbol(currency));
    value.push(
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      parseFloat(amount.replace ? amount.replace(/[^0-9,]/g, '') : amount, 10).toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      }),
    );
    if (code) {
      value.push(nonBreaking ? '\xa0' : ' ');
      value.push(currency.toUpperCase());
    }
    return value.join('').replace(/^\s+|\s+$/g, '');
  },

  currencySymbol(currency: any) {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      default:
        return '$';
    }
  },

  sanitizeObjectId(input: any) {
    if (!input) return null;
    if (!input.match) return null;
    if (!input.match(/^\s*[0-9a-fA-F]{24}\s*$/)) return null;
    return input.replace(/^\s*([0-9a-fA-F]{24})\s*$/, '$1');
  },

  isValidEmail: (email: any) => {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(
      email,
    );
  },

  validateEmail: (email: any) => {
    const result = utils.isValidEmail(email);
    if (!result) return 'Please enter a valid email';
  },

  convertTimestampToDifferentTimezone(timestamp: any, newTimezone: any, currentTimezone: any) {
    if (!newTimezone) return timestamp;

    // 1 - Convert the current timestamp into a valid time string
    //  Uses the browser timezone if no current timezone is passed
    let timeString = moment(timestamp).format('D MMM YYYY hh:mma');
    if (currentTimezone) timeString = momentTimezone(timestamp).tz(currentTimezone).format('D MMM YYYY hh:mma');

    // 2 - Attach to the end of the time string the new timezone UTC value
    const utcForTimezone = momentTimezone(timestamp).tz(newTimezone).format('Z');
    timeString = `${timeString} ${utcForTimezone}`;
    // 3 - Get the new timestamp for the time string
    return momentTimezone(timeString, 'D MMM YYYY hh:mma Z').tz(newTimezone).valueOf();
  },
  getStartDateTimestampForTimezone(month: number, year: string, timezone: string) {
    const date = `${year}-${(month + 1).toString().padStart(2, '0')}-01`;
    const m = momentTimezone.tz(date, timezone);
    return m.startOf('day').valueOf();
  },
  getEndDateTimestampForTimezone(month: number, year: string, timezone: string) {
    const formattedMonth = String(month + 1).padStart(2, '0');
    const startDate = `${year}-${formattedMonth}-01`;
    const lastDayOfMonth = momentTimezone.tz(startDate, timezone).endOf('month').format('DD');
    const date = `${year}-${(month + 1).toString().padStart(2, '0')}-${lastDayOfMonth}`;
    const m = momentTimezone.tz(date, timezone);
    return m.endOf('day').valueOf();
  },
  parseURL(urlString?: string | null): [boolean, string] {
    if (!urlString) {
      return [false, ''];
    }

    const urlPattern =
      /^(?:(?:https?|ftp):\/\/)?(?:(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]|(?:\d{1,3}\.){3}\d{1,3})(?::\d{1,5})?(?:\/\S*)?$/i;
    const isValid = urlPattern.test(urlString);

    if (isValid) {
      const url = new URL(urlString.indexOf('://') === -1 ? `https://${urlString}` : urlString);
      return [true, url.href];
    }

    return [false, ''];
  },
  getMessageTemplates() {
    return [
      {
        label: 'Session',
        value:
          'Hey {{user.meta.identity.firstname}}, we have a new session paying {{booking_incentive}}!\nRegister here: {{recruitment_shorlink}}\nThanks - Askable Team',
      },
      {
        label: 'Survey',
        value:
          'Hey {{user.meta.identity.firstname}}, we have a quick and easy survey paying {{booking_incentive}}!\nRegister here: {{recruitment_shorlink}}\nThanks - Askable Team',
      },
      {
        label: 'AI Moderated',
        value:
          'Hey {{user.meta.identity.firstname}}, we have a self-managed video call paying {{booking_incentive}}!\nRegister here: {{recruitment_shorlink}}\nThanks - Askable Team',
      },
      {
        label: 'Online task',
        value:
          'Hey {{user.meta.identity.firstname}}, we have a quick and easy online task paying {{booking_incentive}}!\nRegister here: {{recruitment_shorlink}}\nThanks - Askable Team',
      },
      {
        label: 'Researcher search',
        value:
          'Hey {{user.meta.identity.firstname}}, We are looking for a researcher with [EXPERIENCE] for an upcoming project. Apply here: {{recruitment_shorlink}}\nThanks - Askable Team',
      },
    ];
  },
  officeTimezone(office: OperationalOffices | undefined) {
    switch (office) {
      case OperationalOffices.Uk:
        return 'Europe/London';
      case OperationalOffices.Us:
        return 'America/Chicago';
      case OperationalOffices.Au:
      default:
        return 'Australia/Brisbane';
    }
  },
};

export { utils };
