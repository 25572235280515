import { useEffect, useRef, useState } from 'react';

import GLOBALS from 'lib/globals';

import type { CSSProperties, RefObject } from 'react';

const MAX_ZOOM = 12;
const DEFAULT_ZOOM = 4;
const STATE_ZOOM = 4;
const COUNTRY_ZOOM = 2;

type BoundingBpx = {
  minLatitude: number;
  maxLatitude: number;
  minLongitude: number;
  maxLongitude: number;
};
type Location = { latitude?: number | null; longitude?: number | null };
type Props = {
  location?: Location | null;
  type?: 'state' | 'country' | 'bounds';
  id: string;
  style?: CSSProperties;
  boundingBox?: BoundingBpx;
};

const useGoogleMap = (el: RefObject<HTMLDivElement | null>, options: google.maps.MapOptions = {}) => {
  const googleMap = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (!el.current) {
      console.warn('Google Map element is not available');
      return;
    }

    // If the map is already initialized, do nothing
    if (googleMap.current) {
      return;
    }

    googleMap.current = new window.google.maps.Map(el.current, {
      ...options,
    });
  }, [el.current]);

  return googleMap.current;
};

export const GoogleMaps = ({ location, id, style, type, boundingBox }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [marker, setMarker] = useState<google.maps.Marker | google.maps.Polygon | null>(null);

  // if no long/lat, just use Brisbane as a default starting point
  const map = useGoogleMap(ref, {
    center: { lat: location?.latitude ?? -27.4705, lng: location?.longitude ?? 153.026 },
    zoom: DEFAULT_ZOOM,
  });

  useEffect(() => {
    updateMap();
    addMarker();
  }, [map, location, type, boundingBox]);

  const updateMap = async () => {
    if (!map) {
      console.warn('Google Map is not initialized');
      return;
    }
    const _locations = new window.google.maps.LatLngBounds();

    if (location?.latitude && location?.longitude) {
      const latLng = new window.google.maps.LatLng(location.latitude, location.longitude);
      _locations.extend(latLng);
    }

    if (type === 'country') {
      map.setZoom(COUNTRY_ZOOM);
    }

    if (type === 'state') {
      map.setZoom(STATE_ZOOM);
    }

    if (type === 'bounds') {
      map.setZoom(DEFAULT_ZOOM);
    }

    const currentZoom = map.getZoom() ?? 0;
    if (currentZoom > MAX_ZOOM) {
      map.setZoom(MAX_ZOOM);
    }

    map.setCenter(_locations.getCenter());
  };

  const addMarker = () => {
    if (!map) {
      console.warn('Google Map is not initialized');
      return;
    }

    if (!location?.latitude || !location?.longitude) {
      return;
    }

    marker?.setMap(null);
    if (type === 'bounds' && boundingBox) {
      const polygon = new window.google.maps.Polygon();
      polygon.setOptions({
        strokeColor: GLOBALS('ASKABLE_THEME_PINK'),
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: GLOBALS('ASKABLE_THEME_PINK'),
        fillOpacity: 0.1,
      });
      polygon.setPaths([
        { lat: boundingBox.maxLatitude, lng: boundingBox.minLongitude },
        { lat: boundingBox.maxLatitude, lng: boundingBox.maxLongitude },
        { lat: boundingBox.minLatitude, lng: boundingBox.maxLongitude },
        { lat: boundingBox.minLatitude, lng: boundingBox.minLongitude },
      ]);
      polygon.setMap(map);
      setMarker(polygon);

      map.fitBounds(
        {
          west: boundingBox.minLongitude,
          east: boundingBox.maxLongitude,
          north: boundingBox.minLatitude,
          south: boundingBox.maxLatitude,
        },
        {
          bottom: 10,
          left: 10,
          right: 10,
          top: 10,
        },
      );
      return;
    }

    const _marker = new window.google.maps.Marker({
      position: { lat: location?.latitude, lng: location?.longitude },
      map,
    });
    _marker.setMap(map);

    setMarker(_marker);
  };

  return <div id={id} ref={ref} style={style || { width: 400, height: 233 }} />;
};

export default GoogleMaps;
