import { H3 } from '@blueprintjs/core';

import { PageTitle } from 'components/PageTitle';

export const ClientContainer = () => {
  return (
    <>
      <PageTitle title="Clients" />
      <div style={{ padding: '10px' }}>
        <H3>Clients</H3>
        <div>Use search to find clients users.</div>
      </div>
    </>
  );
};
