import { gql } from 'urql';

export const MarkBookingMissionCritical = gql`
  mutation markBookingMissionCritical($bookingId: ID!, $missionCritical: Boolean!, $missionCriticalReason: String) {
    markBookingMissionCritical(
      bookingId: $bookingId
      missionCritical: $missionCritical
      missionCriticalReason: $missionCriticalReason
    )
  }
`;
