import gql from 'graphql-tag';

import { userStatusCountFieldsFragment } from './userStatusCountFields';

export const bookingsListCurrentFieldsFragment = gql`
  fragment bookingsListCurrentFields on Booking {
    _id
    created
    updated
    name
    status
    approved_date
    confirmed_date
    type
    total_participants
    team_booking_index
    _admin_id
    admin_user {
      _id
      picture
      meta {
        identity {
          firstname
        }
      }
    }
    config {
      demo
      credits_per_participant
      participant_category
      hidden
      location {
        country
        state
        region
        city
      }
      in_context {
        location_type
      }
      contact {
        name
        email
        phone
      }
      session {
        type
        duration
      }
      incentives {
        currency_code
        currency_symbol
        country_code
        value
      }
      options {
        review_submission
      }
      recruitment {
        byo
      }
      online_task {
        type
        tool
      }
      longitudinal_study {
        period {
          time
          frequency
        }
      }
      project {
        project_type
        allowed_credits
      }
      criteria {
        locations {
          bounds {
            country
          }
          countries {
            country
          }
          states {
            country
          }
        }
      }
    }
    session {
      _id
      start
      end
    }
    admin {
      emoji
      mission_critical
    }
    team {
      _id
      name
      operational_office
      settings {
        ignore_tracking
        billing {
          company_name
          subscription {
            credit {
              remaining
            }
          }
        }
      }
    }
    user {
      _id
      email
      meta {
        identity {
          firstname
          lastname
        }
      }
      contact {
        phone {
          mobile
        }
      }
    }
    credit_activity {
      created
      amount
      accounting_type
      type
      comment
    }
    AutomationScore {
      admin_actions
      score
    }
    UserStatusCount {
      ...userStatusCountFields
    }
    ParticipantRatings {
      overall
    }
    project {
      _id
      name
      type
    }
  }
  ${userStatusCountFieldsFragment}
`;
