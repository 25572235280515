import gql from 'graphql-tag';

import { participantFieldsFragment } from '@graphql/fragments/user/participantDetails';

export default gql`
  query admin_getParticipantDetails($_id: ID!) {
    userByID(id: $_id) {
      ...participantFields
    }
  }
  ${participantFieldsFragment}
`;
