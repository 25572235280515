import { Alert, Button, Intent } from '@blueprintjs/core';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { useAdminDeleteUserMutation } from 'generated/graphql';

import type { FC } from 'react';

type Props = {
  id: string;
  className?: string;
  displayName?: string;
};

export const DeleteUser: FC<Props> = ({ id, displayName, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [{ fetching }, deleteUser] = useAdminDeleteUserMutation();

  const handleDelete = async () => {
    try {
      await deleteUser({
        id: id!,
      });
      toast.success('User deleted');

      navigate('..');
    } catch (e: any) {
      toast.error(`Could not delete user: ${e?.message ?? 'Unknown error'}`);
    }
  };

  return (
    <div className={className}>
      <Button intent={Intent.DANGER} onClick={() => setIsOpen(true)}>
        Delete user
      </Button>
      <Alert
        intent={Intent.DANGER}
        onConfirm={handleDelete}
        loading={fetching}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <p>
          Are you sure you want to delete user account: <strong>{displayName}</strong>
        </p>
      </Alert>
    </div>
  );

  return <Button>Delete</Button>;
};
