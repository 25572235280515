import { Query } from '@apollo/client/react/components';
import { Button, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { useState } from 'react';

import { Icon } from 'components/common';
import { InlineEdit } from 'components/common/InlineEdit';

import ORGS_LIST_QUERY from '../../graphql/queries/organisations/organisations';

import type { VFC } from 'react';
import type { Exact, Maybe, OrganisationsQuery, TeamInput } from 'src/generated/graphql';
import type { ArrayElement } from 'src/types/types';

type OrganisationSelectOrgType = ArrayElement<OrganisationsQuery['organisations']>;

type OrganisationSelectProps = {
  teamId?: string;
  orgLink: (organisation: OrganisationSelectOrgType) => JSX.Element;
  updateTeam: (vars: Exact<{ _id: string; team: TeamInput }>) => Promise<void>;
  unlinkOrg: (vars: { _id: string }) => Promise<void>;
  defaultOrg?: Maybe<OrganisationSelectOrgType>;
};

export const OrganisationSelect: VFC<OrganisationSelectProps> = ({
  teamId,
  defaultOrg = null,
  orgLink,
  updateTeam,
  unlinkOrg,
}) => {
  const [value, setValue] = useState(defaultOrg);
  const [open, setOpen] = useState(false);
  const [edited, setEdited] = useState(false);

  if (!teamId) {
    return null;
  }

  const handleOrgSelect = (org: OrganisationSelectOrgType) => {
    setValue(org);
    setEdited(true);
    setOpen(false);
  };

  const handleSaveClick = async () => {
    setEdited(false);

    if (value?._id) {
      updateTeam({
        _id: teamId,
        team: { _organisation_id: value?._id ?? null },
      });
    } else {
      unlinkOrg({
        _id: teamId,
      });
    }
  };

  const handleCancelClick = () => {
    setValue(defaultOrg);
    setEdited(false);
  };

  const handleClearClick = () => {
    setValue(null);
    setEdited(true);
  };

  return (
    <Query<OrganisationsQuery> query={ORGS_LIST_QUERY}>
      {({ data }) => {
        return !open ? (
          <InlineEdit
            onClick={() => {
              setOpen(true);
            }}
            editable
            edited={edited}
            onSaveClick={handleSaveClick}
            onCancelClick={handleCancelClick}
            onClearClick={handleClearClick}
          >
            {value ? orgLink(value) : <span className="tw-opacity-60">None</span>}
          </InlineEdit>
        ) : (
          <div>
            <Suggest
              openOnKeyDown
              items={data?.organisations ?? []}
              itemRenderer={(org, { modifiers }) => (
                <MenuItem
                  key={org?._id}
                  text={org?.name}
                  active={modifiers.active}
                  shouldDismissPopover={false}
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleOrgSelect(org);
                  }}
                />
              )}
              onItemSelect={handleOrgSelect}
              popoverProps={{
                minimal: true,
                captureDismiss: false,
              }}
              inputProps={{
                autoFocus: true,
              }}
              itemPredicate={(query, item) => (item?.name && query ? new RegExp(query, 'i').test(item.name!) : false)}
              noResults={<MenuItem disabled text="No matching organisations" />}
            />
            <Button
              className="tw-ml-3"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Icon icon="cross" />
            </Button>
          </div>
        );
      }}
    </Query>
  );
};
