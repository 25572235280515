import { graphql } from 'gql.tada';

export const AdminClientUserQuery = graphql(`
  query AdminClientUser($id: ID!) {
    userByID(id: $id) {
      _id
      displayName
      email
      location {
        state
        country
        country
      }
      blacklist
      status
      created
      contact {
        phone {
          mobile
        }
      }
    }
  }
`);
