/* eslint-disable max-lines */
import {
  AnchorButton,
  Button,
  HTMLTable,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  Spinner,
  Tooltip,
} from '@blueprintjs/core';
import classnames from 'classnames';
import { graphql } from 'gql.tada';
import { useEffect, useMemo, useState } from 'react';
import {
  BsCalendar2,
  BsClipboard,
  BsClock,
  BsCurrencyDollar,
  BsPatchCheckFill,
  BsPersonCheckFill,
  BsPhoneFill,
} from 'react-icons/bs';
import { FaEllipsisV } from 'react-icons/fa';
import { FcInvite } from 'react-icons/fc';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';

import { ReopenProjectMutation } from '@graphql/mutations/projects/reopenProject';
import { PageTitle } from 'components/PageTitle';
import { AskableNavbar } from 'components/common';
import { useAppContext } from 'components/common/appProvider';
import ClientAppIframe from 'components/iframe/ClientAppIframe';
import { StackLayout } from 'components/layout/StackLayout';
import { ConfirmDialog } from 'components/modals/ConfirmDialog';
import { VirtualisedTable } from 'components/table/VirtualisedTable';
import { Text } from 'components/ui/Text';
import {
  ProjectProgressStatus,
  ProjectUserStatus,
  ResearcherMethods,
  useAdmin_AdminLoginAsClientMutation,
  useApproveProjectMutation,
  useInviteResearcherToProjectMutation,
  useProjectApplicantsQuery,
  useProjectByIdQuery,
} from 'generated/graphql';
import { useDisclosure } from 'hooks/useDisclosure';

import { formattedDate } from '../../utils/date-utils';
import { normalizeText } from '../../utils/string-utils';
import { MissionCritical } from '../components/MissionCritical';

import { ProjectUpdateContainer } from './ProjectUpdateContainer';
import { ProjectResearcherBatch } from './components/ProjectResearcherBatch';
import { ProjectRightPanel } from './components/ProjectRightPanel';
import { ProjectStatusBadge } from './components/ProjectStatusBadge';
import { AddResearcherMutation } from './mutations/AddResearcher.mutation';
import { ForceCompleteProjectMutation } from './queries/ForceCompleteProject.mutation';

import type { Project, ProjectUser } from 'generated/graphql';
import type { FC } from 'react';
import type { ColumnWithLooseAccessor } from 'react-table';
import type { FunctionalComponentWithChildren } from 'types/types';

function getAppDomain(userId?: string) {
  // Production
  if (window.location.host.match(/^\w+\.askable\.com/)) {
    return userId ? `https://iframe-${userId}-app.askable.com` : 'https://app.askable.com';
  }

  // Dev/Staging
  if (window.location.host.match(/^\w+-\w+\.askable\./)) {
    return window.location.origin.replace(/\b\w+-(\w+)\.askable/, 'app-$1.askable');
  }

  return 'http://localhost:7001';
}

type Data = {
  url: string;
  userToken: string;
  projectStatus: number;
  projectId: string;
  userId?: string;
};

function parseURL({ userId, url, projectStatus, projectId }: Data): string {
  const rootDomain = getAppDomain(userId);

  const { searchParams, search } = new URL(url);

  const teamId = searchParams.get('team_id');
  const teamName = searchParams.get('team_name');

  const redirectPath = (() => {
    if (projectStatus === 0) {
      return `/askable-plus/${projectId}/project-setup/project-title`;
    }

    return `/manage-askable-plus-project/${projectId}`;
  })();

  return `${rootDomain}/login${search}&team_name=${teamName}&teamId=${teamId}&redirectPath=${redirectPath}`;
}

const RemoveResearcherMutation = graphql(`
  mutation RemoveResearcher($input: RemoveResearcherInput!) {
    removeResearcher(input: $input) {
      _id
    }
  }
`);

export const AppliedResearchersTable: FC = () => {
  const params = useParams();
  const [researcher, setResearcher] = useState<ProjectUser | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [{ fetching: inviting }, inviteResearcherToProject] = useInviteResearcherToProjectMutation();
  const [, removeResearcher] = useMutation(RemoveResearcherMutation);
  const [, addResearcher] = useMutation(AddResearcherMutation);

  const [{ data: projectApplicants, fetching }] = useProjectApplicantsQuery({
    variables: {
      id: params.id,
    },
  });

  const data = useMemo(() => {
    return projectApplicants?.projectByID?.users ?? [];
  }, [projectApplicants]);

  const openInvitePrompt = (r: ProjectUser) => () => {
    setResearcher(r);

    onOpen();
  };

  const removeResearcherFromProject = (r: ProjectUser) => async () => {
    const result = await removeResearcher({
      input: {
        _project_id: params.id!,
        _user_id: r._id!,
      },
    });

    if (result.error) {
      toast.error('Error removing researcher. Raise a dev request');
      return;
    }

    toast.success('Researcher removed');
  };

  const addResearcherToProject = (r: ProjectUser) => async () => {
    const result = await addResearcher({
      input: {
        _project_id: params.id!,
        _user_id: r._id!,
      },
    });

    if (result.error) {
      toast.error('Error adding researcher. Raise a dev request');
      return;
    }

    toast.success('Researcher added');
  };

  const handleSendInvite = async () => {
    try {
      await inviteResearcherToProject({
        projectId: params.id!,
        researcherId: researcher?._id ?? '',
      });

      onClose();

      toast.success('Invited');

      setResearcher(null);
    } catch (e) {
      toast.error('Error. Check console');
      console.error(e);
    }
  };

  const handleCopyInviteLinkClick = async () => {
    try {
      const link = `${getAppDomain()}/askable-plus/${params.id}/researcher-offer`;
      await navigator.clipboard.writeText(link);
      toast.success('Invite link copied to clipboard');
    } catch (e) {
      toast.error('Error. Check the console');
      console.error(e);
    }
  };

  const columns = useMemo<readonly ColumnWithLooseAccessor<ProjectUser>[]>(() => {
    return [
      {
        Header: 'Name',
        accessor: row => row.User?.displayName,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div className="tw-flex tw-space-x-2 tw-items-center">
              {row.original.status === ProjectUserStatus.Accepted && (
                <Tooltip
                  content={`Accepted invite at ${formattedDate({ date: row.original.invited_at, format: 'h:mma - ddd D MMM YYYY' })}`}
                >
                  <BsPatchCheckFill className="tw-text-green-700" size={20} />
                </Tooltip>
              )}
              {row.original.status === ProjectUserStatus.Invited && (
                <Tooltip
                  content={`Invited at ${formattedDate({ date: row.original.invited_at, format: 'h:mma - ddd D MMM YYYY' })}`}
                >
                  <FcInvite className="tw-text-green-700" size={20} />
                </Tooltip>
              )}
              <div className="tw-ml-7">
                <Link to={`/user/researcher/${row.original._id}`}>{row.original.User?.displayName}</Link>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Email',
        accessor: row => row.User?.email,
        disableSortBy: true,
        Cell: ({ row }) => {
          return <a href={`mailto:${row.original.User?.email}`}>{row.original.User?.email}</a>;
        },
      },
      {
        Header: 'Applied',
        accessor: row => row.added_date,
        Cell: ({ row }) => {
          return <Text>{formattedDate({ date: row.original.added_date!, format: 'D MMM YYYY' })}</Text>;
        },
      },
      {
        Header: 'Status',
        accessor: row => row.status,
        Cell: ({ row }) => {
          return <Text>{normalizeText(row.original.status!)}</Text>;
        },
      },
      {
        Header: 'Options',
        accessor: row => row._id,
        disableSortBy: true,
        width: 220,
        Cell: ({ row }) => {
          return (
            <Popover
              content={
                <Menu>
                  <MenuItem
                    disabled={row.original.status !== ProjectUserStatus.Applied}
                    icon="send-message"
                    onClick={openInvitePrompt(row.original)}
                    text="Invite to project"
                  />
                  <MenuItem onClick={addResearcherToProject(row.original)} text="Add to project" />
                  {row.original.status === ProjectUserStatus.Accepted ? (
                    <MenuItem onClick={removeResearcherFromProject(row.original)} text="Remove from project" />
                  ) : null}
                  {row.original.status === ProjectUserStatus.Invited && (
                    <MenuItem icon="clipboard" onClick={handleCopyInviteLinkClick} text="Copy invite link" />
                  )}
                </Menu>
              }
            >
              <Button minimal>
                <FaEllipsisV />
              </Button>
            </Popover>
          );
        },
      },
    ];
  }, [projectApplicants]);

  const initialSortBy = useMemo(() => {
    return [{ id: 'Applied', desc: true }];
  }, []);

  return (
    <>
      <VirtualisedTable isLoading={fetching} data={data as any} initialSortBy={initialSortBy} columns={columns} />
      <ConfirmDialog
        isOpen={isOpen}
        onCancel={onClose}
        onConfirm={handleSendInvite}
        heading="Invite researcher"
        loading={inviting}
        body={`Are you sure you want to invite ${researcher?.User?.displayName} to this project ?`}
      />
    </>
  );
};

export const ProjectPricing: FC = () => {
  const params = useParams();
  const [{ data }] = useProjectByIdQuery({ variables: { id: params.id! } });
  if (!data?.projectByID?.pricing) {
    return null;
  }
  const { pricing } = data.projectByID;
  if (!pricing) {
    return null;
  }

  return (
    <div className="tw-p-6">
      <HTMLTable striped className="tw-mb-4">
        <thead>
          <tr>
            <th colSpan={2}>Final pricing:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Total participants:</strong>
            </td>
            <td>{pricing?.total_participants}</td>
          </tr>
          <tr>
            <td>
              <strong>Booking credits:</strong>
            </td>
            <td>{pricing?.booking_credits}</td>
          </tr>
          <tr>
            <td>
              <strong>Researcher hours:</strong>
            </td>
            <td>{pricing?.researcher_hours}</td>
          </tr>
          <tr>
            <td>
              <strong>Total credits:</strong>
            </td>
            <td>{pricing?.total_credits}</td>
          </tr>
        </tbody>
      </HTMLTable>
      <HTMLTable striped className="tw-mb-4">
        <thead>
          <tr>
            <th colSpan={2}>Credits breakdown:</th>
          </tr>
        </thead>
        <tbody>
          {(pricing?.detail?.booking_credits || []).map(line => {
            if (!line?.split) return null;
            const [, label, description, total] = line.match(/^(.+):\s*(.+) = (.+)/) || [];
            if (!label) {
              return null;
            }
            return (
              <tr key={label}>
                <td>
                  <strong>{label}:</strong>
                </td>
                <td>{description}</td>
                <td>{total}</td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
      <HTMLTable striped className="tw-mb-4">
        <thead>
          <tr>
            <th colSpan={2}>Researcher hours breakdown:</th>
          </tr>
        </thead>
        <tbody>
          {(pricing?.detail?.researcher_hours || []).map(line => {
            if (!line?.split) return null;
            const [, label, description, total] = line.match(/^(.+):\s*(.+) = (.+)/) || [];
            if (!label) {
              return null;
            }
            return (
              <tr key={label}>
                <td>
                  <strong>{label}:</strong>
                </td>
                <td>{description}</td>
                <td>{total}</td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </div>
  );
};

const IconText: FunctionalComponentWithChildren<{ className?: string }> = ({ children, className }) => {
  return <div className={classnames('tw-flex tw-items-center tw-space-x-2', className)}>{children}</div>;
};

export const ProjectIFrame: FC = () => {
  const params = useParams();
  const [{ data: project }] = useProjectByIdQuery({ variables: { id: params.id! } });

  const [url, setUrl] = useState<string | null>(null);
  const [{ fetching }, loginAsClient] = useAdmin_AdminLoginAsClientMutation();

  useEffect(() => {
    if (!project) {
      return;
    }

    loginAsClient({ _id: project.projectByID?.owner?._id ?? '' }).then(a => {
      const u = parseURL({
        projectId: params.id ?? '',
        projectStatus: project.projectByID?.status ?? 0,
        url: a.data?.adminLoginAsClient?.url ?? '',
        userId: a.data?.adminLoginAsClient?.user?._id,
        userToken: a.data?.adminLoginAsClient?.user?.access_token ?? '',
      });

      setUrl(u);
    });
  }, []);

  return <ClientAppIframe fetchingUrl={fetching} src={url ?? ''} />;
};

export const ProjectContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [confirmForceCompleteOpen, setConfirmForceCompleteOpen] = useState(false);
  const [batchToolOpen, setBatchToolOpen] = useState(false);
  const [, approveProject] = useApproveProjectMutation();
  const [{ fetching: forceCompleteBookingLoading }, forceCompleteBooking] = useMutation(ForceCompleteProjectMutation);
  const [, reopenProject] = useMutation(ReopenProjectMutation);
  const context = useAppContext();
  const [{ data, fetching }, refetchProject] = useProjectByIdQuery({
    variables: {
      id: params.id!,
    },
  });

  const handleReopenProject = async () => {
    const { error } = await reopenProject({
      projectId: params.id!,
    });

    if (error) {
      toast.error('Error reopening project. Check the console');
      console.error(error);
      return;
    }

    toast.success('Project reopened');
  };

  const handleForceCompleteProject = async () => {
    const { error } = await forceCompleteBooking({
      projectId: params.id!,
    });

    setConfirmForceCompleteOpen(false);

    if (error) {
      toast.error('Error force closing booking. Check the console');
      console.error(error);
      return;
    }

    toast.success('Project successfully closed');
  };

  const handleApproveProject = async () => {
    try {
      await approveProject({
        projectId: params.id!,
      });

      toast.success('Project approved');
    } catch (e) {
      toast.error('Error. Check console');
      console.error(e);
    }
  };

  if (fetching) {
    return <Spinner />;
  }

  const researchTypes = Object.values(ResearcherMethods).filter(
    method => data?.projectByID?.askable_plus?.research_type?.[method]?.quota !== null,
  );

  return (
    <>
      <PageTitle title={data?.projectByID?.name ?? ''} />
      <StackLayout>
        {/* Left panel  */}
        <div>
          <div className="tw-p-6 tw-border-gray-500 tw-space-y-6 tw-border-b">
            <div>
              <Text className="tw-font-semibold">{data?.projectByID?.team?.name}</Text>
            </div>

            <h2>{data?.projectByID?.name}</h2>

            <div className="tw-flex tw-gap-2">
              <ProjectStatusBadge
                projectProgressStatus={data?.projectByID?.progress?.current_status ?? ProjectProgressStatus.Delivery}
                projectStatus={data?.projectByID?.status ?? 0}
              />
              {data?.projectByID?.admin?.mission_critical ? <div title="Mission critical">🚨</div> : null}
            </div>

            {data?.projectByID?.status === 0 && (
              <div className="tw-p-4 tw-bg-gray-500 tw-rounded-md tw-space-y-2">
                <div className="tw-flex tw-space-x-2">
                  <InputGroup
                    readOnly
                    value={
                      data?.projectByID?.pricing?.researcher_hours
                        ? `${data.projectByID?.pricing?.researcher_hours}`
                        : ''
                    }
                  />
                  <Button onClick={handleApproveProject} intent="success">
                    Approve
                  </Button>
                </div>
                <Button minimal>Reject</Button>
              </div>
            )}

            <div className="tw-space-y-3 tw-text-gray-400">
              <IconText>
                <BsClock />
                <Text>{data?.projectByID?.pricing?.researcher_hours} hr</Text>
              </IconText>

              <IconText>
                <BsCurrencyDollar />
                <Text>
                  $
                  {(
                    (data?.projectByID?.pricing?.researcher_hours ?? 0) *
                    (data?.projectByID?.askable_plus?.researcher_hourly_rate || 100)
                  ).toLocaleString()}{' '}
                  + GST researcher payout
                </Text>
              </IconText>

              <IconText>
                <BsCalendar2 />
                <Text>
                  Due date {formattedDate({ date: data?.projectByID?.askable_plus?.due_date, format: 'MMM D, YYYY' })}
                </Text>
              </IconText>

              <IconText className="!tw-items-start">
                <BsClipboard className="tw-mt-1" />
                <div>
                  {researchTypes.map(t => {
                    return <Text key={t}>{normalizeText(t)}</Text>;
                  })}
                </div>
              </IconText>
            </div>
          </div>

          <div className="tw-space-y-3 tw-border-b tw-border-gray-500 tw-p-6">
            <div className="tw-flex tw-items-center tw-justify-between">
              <Text>Client</Text>
              <AnchorButton minimal small intent="primary" rightIcon="arrow-right">
                Intercom
              </AnchorButton>
            </div>

            <div className="tw-text-gray-400">
              <Text>
                {data?.projectByID?.owner?.meta?.identity?.firstname}{' '}
                {data?.projectByID?.owner?.meta?.identity?.lastname}
              </Text>
              <Text>{data?.projectByID?.owner?.contact?.phone?.mobile}</Text>
              <a href={`mailto:${data?.projectByID?.owner?.email}`}>{data?.projectByID?.owner?.email}</a>
            </div>
          </div>

          <div className="tw-space-y-2 tw-p-6  tw-border-b tw-border-gray-500">
            <IconText className="tw-text-green-400">
              <BsPersonCheckFill size={18} />
              <Text>
                {data?.projectByID?.users?.filter(a => a?.status === ProjectUserStatus.Accepted).length ?? 0} assigned
              </Text>
            </IconText>

            <IconText className="tw-text-orange-400">
              <BsPersonCheckFill className="tw-text-orange-400" size={18} />
              <Text>
                {data?.projectByID?.users?.filter(a => a?.status === ProjectUserStatus.Invited).length ?? 0} invited
              </Text>
            </IconText>

            <IconText className="tw-text-gray-400">
              <BsPersonCheckFill size={18} />
              <Text>{data?.projectByID?.users?.length ?? 0} applied</Text>
            </IconText>

            <div className="tw-flex tw-items-center tw-justify-between tw-pt-4">
              <IconText className="tw-text-gray-400">
                <BsPhoneFill size={18} />
                <Text>WIP</Text>
              </IconText>

              {data?.projectByID?.status === 1 && (
                <AnchorButton
                  small
                  minimal
                  intent="primary"
                  rightIcon="arrow-right"
                  onClick={() => setBatchToolOpen(true)}
                >
                  Batch
                </AnchorButton>
              )}
            </div>
          </div>

          <div className="tw-flex tw-flex-col tw-items-start tw-p-6 tw-space-y-3 ">
            <Button minimal small intent="primary">
              Hide project
            </Button>
            <Button minimal small intent="primary">
              Cancel project
            </Button>
            {data?.projectByID?.status !== 5 && (
              <Button minimal small intent="primary" onClick={() => setConfirmForceCompleteOpen(true)}>
                Force complete
              </Button>
            )}
            {data?.projectByID?.status && data?.projectByID?.status >= 5 && (
              <Button minimal small intent="primary" onClick={() => handleReopenProject()}>
                Reopen project
              </Button>
            )}
            <Button minimal small intent="primary">
              Move project
            </Button>
            <Button
              minimal
              small
              intent="primary"
              onClick={() => {
                context.openSidebar({
                  children: data?.projectByID && (
                    <ProjectUpdateContainer
                      onClose={() => {
                        refetchProject?.();
                        context.closeSidebar();
                      }}
                      projectId={data?.projectByID?._id}
                      project={data?.projectByID as Project}
                    />
                  ),
                });
              }}
            >
              Update project
            </Button>
          </div>

          <ConfirmDialog
            loading={forceCompleteBookingLoading}
            isOpen={confirmForceCompleteOpen}
            onCancel={() => setConfirmForceCompleteOpen(false)}
            onConfirm={handleForceCompleteProject}
            heading="Force complete project?"
            body="This will set the project status to completed and return Project complete for the project progress."
          />

          <ProjectResearcherBatch
            isOpen={batchToolOpen}
            onClose={() => setBatchToolOpen(false)}
            projectId={params.id!}
          />
        </div>

        {/* IFrame  */}
        <div className="tw-w-full tw-h-full">
          <AskableNavbar
            items={[
              { title: 'App', active: location.pathname.split('/').includes('app'), onClick: () => navigate('app') },
              {
                title: 'Applicants',
                active: location.pathname.split('/').includes('applicants'),
                onClick: () => navigate('applicants'),
              },
              {
                title: 'Pricing',
                active: location.pathname.split('/').includes('pricing'),
                onClick: () => navigate('pricing'),
              },
            ]}
          />
          <Outlet />
          {/* <ProjectIFrame clientId={data?.projectByID?.owner?._id} projectId={data?.projectByID?._id} projectStatus={data?.projectByID?.status} /> */}
        </div>

        <div className=" tw-h-full">
          <div className="tw-py-10 tw-px-6 tw-w-flex">
            <ProjectRightPanel projectId={params.id!} />

            {data?.projectByID?._id ? (
              <div className="tw-w-full tw-py-6 tw">
                <MissionCritical
                  id={data?.projectByID?._id}
                  isDisabled={data?.projectByID?.status === 5}
                  missionCritical={data?.projectByID?.admin?.mission_critical ?? false}
                  missionCriticalReason={data?.projectByID?.admin?.mission_critical_reason ?? ''}
                  type="project"
                />
              </div>
            ) : null}
          </div>
        </div>
      </StackLayout>
    </>
  );
};
