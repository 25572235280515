import { Button, AnchorButton } from '@blueprintjs/core';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { utils } from 'lib/utils';

import type { FunctionalComponent } from 'types/types';

const LinkButton: FunctionalComponent<any> = props => {
  const navigate = useNavigate();
  if (props.to && props.to.match(/^(\/\/.|https{0,1}:\/\/)/)) {
    return (
      <AnchorButton
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        {...utils.filterForDOM(props)}
        href={props.to}
      />
    );
  }
  return (
    <Button
      {..._.omit(props, ['to', 'linkState', 'onClick', 'staticContext'])}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
        if (props.to) {
          navigate(props.to);
        }
      }}
    />
  );
};

export default LinkButton;
