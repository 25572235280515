import { Classes, NonIdealState } from '@blueprintjs/core';
import classnames from 'classnames';
import { useId, useState } from 'react';

import { Spinner } from 'components/common';

import type { FC } from 'react';

type BookingIFrameProps = {
  src?: string;
  fetchingUrl?: boolean;
};

const ClientAppIframe: FC<BookingIFrameProps> = ({ src, fetchingUrl }) => {
  const key = useId();
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoadComplete = () => {
    setIsLoading(false);
  };

  return (
    <div className="app-iframe-container tw-h-full tw-w-full tw-relative">
      {!fetchingUrl && (
        <iframe
          onLoad={handleIframeLoadComplete}
          loading="eager"
          title="booking_app_iframe"
          id="booking_app_iframe"
          className="tw-w-full tw-h-full"
          name="booking_app_iframe"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          key={key}
          src={src}
        />
      )}

      {isLoading && (
        <NonIdealState
          className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full"
          icon={<Spinner />}
          title="Loading frame contents"
          description={
            <code className={classnames('tw-truncate tw-w-full tw-text-xs', Classes.TEXT_MUTED)}>
              {src ?? 'Loading...'}
            </code>
          }
        />
      )}
      <div id="inactive-overlay" />
    </div>
  );
};

export default ClientAppIframe;

/*
    TODO: (v2)
     - on clients app when changing routes (but not tabs), instead up updating src, post a navigation message to the app
     - on both tabs, change popout method to request the frame url and send that to the new window (currently it pops out the original src, which might not match the user's current page if they've navigated)
*/
