import { graphql } from 'gql.tada';

export const ClientTeamsQuery = graphql(`
  query AdminClientTeams($id: ID!) {
    teamsByUser(_user_id: $id) {
      _id
      name
    }
  }
`);
