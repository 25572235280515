import gql from 'graphql-tag';

export const wigFieldsFragment = gql`
  fragment WigFields on AdminWig {
    _id
    period_name
    period_start
    period_end
    period_type
    operational_office
    goals {
      key
      value
      meta {
        name
        prefix
      }
    }
  }
`;

export const currentWigFieldsFragment = gql`
  fragment CurrentWigFields on AdminWig {
    _id
    period_name
    period_start
    period_end
    period_type
    operational_office
    goals {
      key
      value
      progress
      ghost
      meta {
        name
        prefix
        emoji
        bar_colour
      }
    }
  }
`;
