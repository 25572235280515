import { Query } from '@apollo/client/react/components';
import { H2 } from '@blueprintjs/core';
import _ from 'lodash';
import { useParams } from 'react-router';

import { PageTitle } from 'components/PageTitle';
import { TeamsList } from 'components/teams/teams-list.view';

import ORG_QUERY from '../../graphql/queries/organisations/organisationById';
import { AppConsumer } from '../common';

import type { FC } from 'react';

type Props = {
  context: {
    newToast: (p: any) => void;
  };
};

const Organisation: FC<Props> = ({ context }) => {
  const params = useParams();

  return (
    <Query query={ORG_QUERY} variables={{ _id: params.id }} key="teams">
      {({ loading, data }: any) => {
        if (loading) return null;
        const organisation = _.get(data, 'organisationById');
        return (
          <>
            <PageTitle title={organisation.name} />
            <div className="content-page tw-grow" style={{ marginBottom: '10px' }}>
              <H2>{_.get(organisation, 'name')}</H2>
              {_.get(organisation, 'admin.Owner') && (
                <p>
                  <strong>Owner:</strong> {organisation.admin.Owner.email}
                </p>
              )}
              <TeamsList teams={organisation.Teams} newToast={context?.newToast ?? (() => null)} />
            </div>
          </>
        );
      }}
    </Query>
  );
};

export default AppConsumer(Organisation);
