import { Button, TextArea, Checkbox, Label } from '@blueprintjs/core';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';

import { MarkBookingMissionCritical } from '../Booking/mutations/MarkBookingMissionCritical.mutation';
import { MarkProjectMissionCritical } from '../Project/mutations/MarkProjectMissionCritical.mutation';

import type { ChangeEvent, FormEvent, FC } from 'react';

interface MissionCriticalProps {
  id: string;
  isDisabled?: boolean;
  missionCritical: boolean | null;
  missionCriticalReason: string | null;
  type?: 'booking' | 'project';
}

interface MutationResult {
  error?: Error;
}

export const MissionCritical: FC<MissionCriticalProps> = ({
  id,
  isDisabled = false,
  missionCritical: missionCriticalProp,
  missionCriticalReason: missionCriticalReasonProp,
  type = 'booking',
}) => {
  const [missionCritical, setMissionCritical] = useState(false);
  const [missionCriticalReason, setMissionCriticalReason] = useState('');
  const [, markMissionCritical] = useMutation<MutationResult>(
    type === 'project' ? MarkProjectMissionCritical : MarkBookingMissionCritical,
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!id) {
      return;
    }

    const payload = {
      [`${type}Id`]: id,
      missionCritical,
      missionCriticalReason: missionCritical ? missionCriticalReason : '',
    };

    try {
      const result = await markMissionCritical(payload);
      if (result.error) {
        throw result.error;
      }

      toast.success(missionCritical ? `Updated ${type} to mission critical` : `Removed mission critical from ${type}`);
    } catch {
      toast.error('Error updating mission critical status. Raise a dev request');
    }
  };

  useEffect(() => {
    if (missionCriticalProp !== null) {
      setMissionCritical(missionCriticalProp);
      setMissionCriticalReason(missionCriticalReasonProp ?? '');
    }
  }, [missionCriticalProp, missionCriticalReasonProp]);

  return (
    <form onSubmit={handleSubmit} className="tw-flex tw-flex-col">
      <Checkbox
        checked={missionCritical}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setMissionCritical(e.target.checked)}
        label="Mark as mission critical 🚨"
        disabled={isDisabled}
      />

      <Label htmlFor="reason" className="tw-sr-only">
        Add a reason
      </Label>

      {missionCritical ? (
        <TextArea
          id="reason"
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMissionCriticalReason(e.target.value)}
          value={missionCriticalReason}
          rows={1}
          placeholder="Enter a reason..."
          className="tw-mb-2"
          disabled={isDisabled}
        />
      ) : null}

      <div>
        <Button type="submit" intent="primary" disabled={isDisabled}>
          Update
        </Button>
      </div>
    </form>
  );
};
