import { useNavigate } from 'react-router';

import { AppConsumer, Omnisearch, ErrorCallout } from 'components/common';

import type { FC } from 'react';

import './styles/user.scss';

type SearchBarProps = {
  context: any;
  autoFocus?: boolean;
  className?: string;
};

const sectionRouteMap: Record<string, string> = {
  participants: 'participant',
  clients: 'client',
};

const Searchbar: FC<SearchBarProps> = ({ autoFocus, context, className }) => {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <Omnisearch
        inputProps={{
          id: 'user-search-input',
          type: 'search',
          leftIcon: 'search',
          placeholder: 'Search users by name, number, email or ID',
          autoFocus,
          autoComplete: 'off',
        }}
        limit={6}
        onItemSelect={(item: any) => {
          if (!item?.doc?._id) {
            context.openAlert(
              <ErrorCallout title="No user ID">
                <pre>{JSON.stringify(item, undefined, 2)}</pre>
              </ErrorCallout>,
            );
            return;
          }

          navigate(`/user/${sectionRouteMap?.[item._section as string] ?? 'unknown'}/${item.doc._id}`);
        }}
        searchTypes={['participants', 'clients']}
      />
    </div>
  );
};

export default AppConsumer(Searchbar);
