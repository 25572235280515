import {
  // Classes,
  H6,
  Card,
  EditableText,
  Button,
  // Hotkey, Hotkeys, HotkeysTarget,
} from '@blueprintjs/core';
import _ from 'lodash';
import moment from 'moment';
import { Component, createRef } from 'react';

import { Spinner } from 'components/common';
import { utils } from 'lib/utils';

import Message from './message.view';

import './styles.scss';

type Props = object;
type State = {
  autoScrolled: boolean;
  composeValue: string;
  outgoingMessage: string | null;
};

class Conversation extends Component<Props, State> {
  emptyStateTimeout: any;
  manualScroll: any;
  scrollRef: any;
  scrollTimeout: any;
  unsubscribe: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      autoScrolled: false,
      composeValue: '',
      outgoingMessage: null,
    };

    this.scrollRef = createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderConversationContents = this.renderConversationContents.bind(this);
    this.renderDayMessages = this.renderDayMessages.bind(this);
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '99999999' is not assignable to p... Remove this comment to see the full error message
    this.autoScroll(99999999);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'subscribeToMessages' does not exist on t... Remove this comment to see the full error message
    if (this.props.subscribeToMessages) this.unsubscribe = this.props.subscribeToMessages();
    this.emptyStateTimeout = setTimeout(() => {}, 2000);
  }

  getSnapshotBeforeUpdate(prevProps: any, prevState: any) {
    const snapshot = {};
    if (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      (_.get(this.props, 'messages.length') > 0 || !this.props.loading) &&
      _.get(prevProps, 'messages.length') === 0
    ) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'firstLoadMessages' does not exist on typ... Remove this comment to see the full error message
      snapshot.firstLoadMessages = true;
      return snapshot;
    }

    if (_.get(this.props, 'messages.length') > _.get(prevProps, 'messages.length')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'newMessage' does not exist on type '{}'.
      snapshot.newMessage = true;
    }

    if (this.state.outgoingMessage && !prevState.outgoingMessage) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'outgoingMessage' does not exist on type ... Remove this comment to see the full error message
      snapshot.outgoingMessage = true;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'newMessage' does not exist on type '{}'.
    if (snapshot.newMessage || snapshot.outgoingMessage) {
      const el = this.scrollRef.current;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollBottom' does not exist on type '{}... Remove this comment to see the full error message
      snapshot.scrollBottom = el.scrollHeight - el.clientHeight - el.scrollTop;
    }

    return snapshot;
  }

  componentDidUpdate(_prevProps: any, _prevState: any, snapshot: any) {
    // console.log('snapshot', snapshot);
    if (snapshot.firstLoadMessages) {
      this.autoScroll('100%');
    }

    if (snapshot.newMessage) {
      this.setState({ outgoingMessage: null });
    }
    if (_.has(snapshot, 'scrollBottom') && snapshot.scrollBottom < 160) {
      this.autoScroll('100%', true);
    }
    // mark unseen messages as seen
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'messages' does not exist on type 'Readon... Remove this comment to see the full error message
    const _messages_id = _.chain(this.props.messages)
      .filter(message => message._to_user_id === utils.getUserId() && !message.seen)
      .map(message => message._id)
      .value();
    if (_messages_id && _messages_id.length > 0) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'markMultipleMessagesAsSeen' does not exi... Remove this comment to see the full error message
      this.props.markMultipleMessagesAsSeen(_messages_id);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  handleSubmit() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onSend' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.onSend && this.state.composeValue.replace(/\s/g, '')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onSend' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.onSend(this.state.composeValue);
      this.setState({ outgoingMessage: this.state.composeValue });
    }
    this.setState({ composeValue: '' });
  }

  async autoScroll(delta = '100%', smooth = false) {
    if (this.scrollTimeout || this.manualScroll) {
      return false;
    }
    const el = this.scrollRef.current;
    if (!el) {
      return false;
    }
    el.style.scrollBehavior = smooth ? 'smooth' : 'unset';
    if (delta === '100%') {
      el.scrollTop = el.scrollHeight;
      this.scrollTimeout = setTimeout(() => {
        this.scrollTimeout = null;
        this.setState({ autoScrolled: true });
      }, 150);
      return el;
    }
    if (!Number.isNaN(delta)) {
      this.scrollTimeout = null;
      el.scrollTop += delta;
      return el;
    }
    return null;
  }

  groupDayMessages(input: any) {
    /* [{ date: ts, ... }] */
    const messages = _.chain(input)
      .sortBy('date')
      .groupBy(item => moment(item.date).startOf('day').format())
      .map((group, day) => [moment(day), group])
      .sortBy('day')
      .value();

    if (this.state.outgoingMessage && messages.length > 0) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'format' does not exist on type 'Moment |... Remove this comment to see the full error message
      if (messages[messages.length - 1][0].format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
        messages.push([moment(), []]);
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'push' does not exist on type 'Moment | [... Remove this comment to see the full error message
      messages[messages.length - 1][1].push({
        key: 'outgoing',
        body: this.state.outgoingMessage,
        from: 'Me',
        classes: ['direction-sent', 'outgoing'],
        date: Date.now(),
      });
    }

    return messages;
  }

  renderConversationContents() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'messages' does not exist on type 'Readon... Remove this comment to see the full error message
    const messages = this.props.messages || [];
    if (messages.length === 0) {
      return <div className="dayMessages emptyState" />;
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([date, messages]: [any, any]) =... Remove this comment to see the full error message
    return this.groupDayMessages(messages).map(this.renderDayMessages);
  }

  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'date' implicitly has an 'any' typ... Remove this comment to see the full error message
  renderDayMessages([date, messages]) {
    /* {
            date: moment
            messages: [
                {
                    key: <>,
                    from: 'Name',
                    classes: ['sent' | 'received' | 'alert' ... ]
                    body: '',
                    date: ts
                    seen: boolean,
                    icon: 'icon-name'
                }
            ]
        } */

    return (
      <div className="day-messages" key={date.format()}>
        <H6 className="day-heading">
          {date.calendar(null, {
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: 'D MMMM YYYY',
          })}
        </H6>
        {messages.map(this.renderMessage)}
      </div>
    );
  }

  renderMessage({ key, ...message }: any) {
    return <Message key={key} {...message} />;
  }

  handleTextChange = (value: string) => {
    this.setState({ composeValue: value });
  };

  render() {
    return (
      <div className="messages-conversation">
        <div
          className={`message-scrollarea ${this.state.autoScrolled ? 'autoscrolled' : 'not-autoscrolled'}`}
          ref={this.scrollRef}
        >
          {this.renderConversationContents()}
        </div>
        <div className="message-compose">
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Elevation... Remove this comment to see the full error message */}
          <Card elevation="2" className="margin-2 margin-bottom-3 margin-top-0">
            <EditableText
              multiline
              minLines={3}
              maxLines={10}
              placeholder="Type a message"
              onChange={this.handleTextChange}
              value={this.state.composeValue}
              // onConfirm={this.handleSubmit} // TODO: remove this but attach CMD+RETURN hotkey to submit
            />
            <div className="compose-footer">
              <Button
                intent="primary"
                text="Send"
                icon
                onClick={this.handleSubmit}
                disabled={!this.state.composeValue.replace(/\s/g, '')}
              />
            </div>
          </Card>
        </div>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message */}
        {(this.props.loading || !this.state.autoScrolled) && (
          <div className="loading-state">
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

export default Conversation;
