/* eslint-disable max-lines */
import { Mutation, Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { AnchorButton, Button, ButtonGroup, Classes, H5, NonIdealState, Popover, Tag } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _, { flowRight as compose } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import updateBookingAdminUser from '@graphql/mutations/booking/updateBookingAdminUser';
import fetchBookingsQuery from '@graphql/queries/booking/bookingsListCurrent';
import fetchAdminUsers from '@graphql/queries/user/fetchAdminUsers';
import fetchBookingsSubscription from '@graphql/subscriptions/booking/bookingsListCurrent';
import userStatusCountSubscription from '@graphql/subscriptions/booking/userStatusCount';
import { PageTitle } from 'components/PageTitle';
import { AppConsumer, BookingTag, DataTable, Emoji, Icon, LinkButton, Spinner } from 'components/common';
import BookingSidebar from 'components/messaging/booking-batch/booking-batch-sidebar.view';
import { OperationalOffices } from 'generated/graphql';
import {
  ACCOUNTING_TYPE,
  BOOKING_PARTICIPANT_CATEGORY,
  BOOKING_SESSION_TYPE,
  BOOKING_STATUS,
  BOOKING_TYPE,
  CREDIT_ACTIVITY_TYPE,
} from 'lib/constants';
import { utils } from 'lib/utils';
import { formatIncentivesToString } from 'utils/currency-utils';

import { BookingAdminAssignee } from './components/BookingAdminAssignee';

import type { MutationFunction } from '@apollo/client';
import type { DataValue } from '@apollo/client/react/hoc';
import type {
  Admin_BookingsListCurrentQuery,
  UpdateBookingAdminUserMutationVariables,
  AdminUsersQuery,
  UpdateBookingAdminUserMutation,
  Admin_BookingsListCurrentSubscriptionSubscription,
  Admin_UserStatusCountSubscription,
} from 'generated/graphql';
import type { unitOfTime } from 'moment';

const setBookingVisibilityMutation = gql`
  mutation admin_setBookingVisibility($_booking_id: ID!, $hidden: Boolean) {
    setBookingVisibility(booking_id: $_booking_id, hidden: $hidden) {
      _id
      updated
      config {
        hidden
      }
    }
  }
`;

const LOW_RATING_THRESHOLD = 3;

type Props = {
  adminUsers: AdminUsersQuery;
  updateBookingAdminUser: MutationFunction<UpdateBookingAdminUserMutation, UpdateBookingAdminUserMutationVariables>;
  fetchBookings: DataValue<Admin_BookingsListCurrentQuery>;
  ignoredTeamBookings: boolean;
};

type State = {
  rows: any[];
  loading: boolean;
};

interface Row {
  _id: string;
  key: string;
  booking?: NonNullable<NonNullable<Admin_BookingsListCurrentQuery['bookings']>[number]>;
  filters: string[];
  sort: Record<string, any>;
  classes: string[];
}

class CurrentBookings extends Component<Props, State> {
  unsubscribeBookings: any;

  unsubscribeStatusCount: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      rows: [],
      loading: true,
    };

    this.parseRow = this.parseRow.bind(this);
    this.parseRows = this.parseRows.bind(this);
    this.queryCompletedCallback = this.queryCompletedCallback.bind(this);
    this.updateUserStatusCount = this.updateUserStatusCount.bind(this);
    this.getDefaultActiveBasedOnOperationalOffice = this.getDefaultActiveBasedOnOperationalOffice.bind(this);
  }

  componentWillReceiveProps(newProps: Props) {
    if (newProps.fetchBookings.bookings) {
      if (!this.unsubscribeBookings) {
        this.unsubscribeBookings = newProps.fetchBookings.subscribeToMore({
          document: fetchBookingsSubscription,
          updateQuery: (
            prev: Admin_BookingsListCurrentQuery,
            { subscriptionData }: { subscriptionData: { data: Admin_BookingsListCurrentSubscriptionSubscription } },
          ) => {
            const newBooking = subscriptionData.data.bookings;
            if (!newBooking) return prev;
            if ([BOOKING_STATUS.ACTIVE, BOOKING_STATUS.IN_REVIEW].indexOf(newBooking?.status || -1) === -1) return prev;

            const next: Admin_BookingsListCurrentQuery = { bookings: [] };

            if (prev.bookings?.find(booking => booking?._id === newBooking._id)) {
              next.bookings = [
                ...(prev.bookings?.filter(oldBooking => oldBooking?._id !== newBooking._id) ?? []),
                newBooking,
              ];
            } else {
              next.bookings = [newBooking, ...(prev.bookings || [])];
            }
            if (next && next.bookings) {
              this.queryCompletedCallback(next);
              return next;
            }
            return prev;
          },
        });
      }
      if (!this.unsubscribeStatusCount) {
        this.unsubscribeStatusCount = newProps.fetchBookings.subscribeToMore({
          document: userStatusCountSubscription,
          updateQuery: (
            prev: Admin_BookingsListCurrentQuery,
            { subscriptionData }: { subscriptionData: { data: Admin_UserStatusCountSubscription } },
          ) => {
            if (subscriptionData.data?.bookingUserStatus) {
              this.updateUserStatusCount(subscriptionData.data.bookingUserStatus);
            }
            return prev;
          },
        });
      }
      if (!this.state.rows.length) {
        this.queryCompletedCallback(newProps.fetchBookings);
      }
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeBookings) this.unsubscribeBookings();
    if (this.unsubscribeStatusCount) this.unsubscribeStatusCount();
  }

  updateUserStatusCount({ _id, UserStatusCount }: NonNullable<Admin_UserStatusCountSubscription['bookingUserStatus']>) {
    const updateIndex = _.findIndex(this.state.rows, { _id });
    if (updateIndex < 0) return;
    const { booking } = this.state.rows[updateIndex];
    if (JSON.stringify(booking.UserStatusCount) === JSON.stringify(UserStatusCount)) return;
    this.setState(state => {
      const newBooking = { ...booking, UserStatusCount };
      state.rows[updateIndex] = this.parseRow(newBooking);
      return state;
    });
  }

  handleBookingUserUpdate = async (variables: UpdateBookingAdminUserMutationVariables) => {
    const signedInUser = localStorage.getItem('user');

    try {
      const results = await this.props.updateBookingAdminUser({
        variables,
      });

      const updatedState = this.state.rows.map(row => {
        if (row._id === variables?.booking_id) {
          const filters = [...row.filters, 'my_bookings', 'unassigned'].filter(a => {
            if (variables.booking._admin_id === null) {
              return a !== 'my_bookings';
            }

            if (variables.booking._admin_id !== signedInUser) {
              return a !== 'unassigned' && a !== 'my_bookings';
            }

            if (variables.booking._admin_id === signedInUser) {
              return a !== 'unassigned';
            }

            return true;
          });

          const newRow = {
            ...row,
            filters,
            booking: {
              ...row.booking,
              _admin_id: variables?.booking._admin_id,
              admin_user: results?.data?.updateBookingAdmin?.admin_user,
            },
            assignee: (
              <BookingAdminAssignee
                adminUser={results?.data?.updateBookingAdmin?.admin_user}
                bookingId={variables.booking_id}
                handleBookingUserUpdate={this.handleBookingUserUpdate}
                operationalOffice={row?.booking?.team?.operational_office as unknown as OperationalOffices | undefined}
              />
            ),
          };

          return newRow;
        }

        return row;
      });

      this.setState({
        rows: updatedState,
      });
    } catch (e) {
      console.log(e);
    }
  };

  parseRow(booking: NonNullable<NonNullable<Admin_BookingsListCurrentQuery['bookings']>[number]>) {
    const row: Row = {
      _id: booking._id,
      key: booking._id,
      booking,
      filters: [],
      sort: {},
      classes: [],
    };

    if (this.props.ignoredTeamBookings && !_.get(booking, 'team.settings.ignore_tracking')) {
      return null;
    }
    if (this.props.ignoredTeamBookings === false && _.get(booking, 'team.settings.ignore_tracking')) {
      return null;
    }

    let emojiTag = booking.admin?.emoji;
    if (!emojiTag) emojiTag = undefined;
    if (!emojiTag && booking.team_booking_index === 1) emojiTag = '⭐️';

    // TODO: make interactive, update mutation
    // TODO: default ⭐️ tag on a team's first booking
    // row.tag = <span className="align-center">{_.get(booking, 'admin.emoji') ? <Emoji emoji={booking.admin.emoji} /> : null}</span>;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'tag' does not exist on type '{ _id: any;... Remove this comment to see the full error message
    row.tag = (
      <span key={emojiTag || ''}>
        <BookingTag
          value={emojiTag}
          _booking_id={booking._id}
          isMissionCritical={booking.admin?.mission_critical ?? false}
          onChange={(emoji: any) => {
            this.setState(state => {
              const updateIndex = _.findIndex(state.rows, { _id: booking._id });
              state.rows[updateIndex] = this.parseRow(_.set(booking, 'admin.emoji', emoji));
              return state;
            });
          }}
        />
      </span>
    );

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'asignee' does not exist on type '{}'.
    row.assignee = (
      <BookingAdminAssignee
        adminUser={booking.admin_user}
        bookingId={booking._id}
        handleBookingUserUpdate={this.handleBookingUserUpdate}
        operationalOffice={booking?.team?.operational_office as unknown as OperationalOffices | undefined}
      />
    );

    if (row.booking?._admin_id === localStorage.getItem('user')) {
      row.filters.push('my_bookings');
    }

    if (!booking._admin_id) {
      row.filters.push('unassigned');
    }

    row.sort.tag = 99;
    if (emojiTag) {
      // These are currently sorted in a "least important to most important" order.
      const availableEmojis = ['📵', '✝️', '📹', '🧿', '❗️', '🥵', '✅', '⭐️', '🚨', '💿'];

      /**
       * Emoji weightings are based on: emojiX > (next emoji + value of all subsequent emojis).
       * So 🚨 is worth more than all other emojis combined.
       * They accumulate in value so ⭐️🚨 has a higher weighting than 🚨
       */
      const emojiWeightings = availableEmojis.reduce((acc: { [x: string]: number }, curr, index) => {
        const value = Object.values(acc).reduce((total, val) => total + val, index + 1);

        return {
          ...acc,
          [curr.codePointAt(0)?.toString(16) || 'invalid']: value,
        };
      }, {});

      // Filter out the emoji modifiers
      const splitEmojis = [...emojiTag].map(a => a.codePointAt(0)?.toString(16))?.filter(a => a !== 'fe0f');

      row.sort.tag = splitEmojis.reduce((acc, curr) => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return acc - emojiWeightings[curr];
      }, row.sort.tag);
    }

    // add booking to Askable+ filter
    if (booking?.project?.type === 1 || /(\u271D)/.test(emojiTag || '')) {
      row.filters.push('askable_plus');
    }

    // Add booking to AU operational office filter
    if (booking?.team?.operational_office === OperationalOffices.Au) {
      row.filters.push('au_bookings');
    }

    // Add booking to UK operational office filter
    if (booking?.team?.operational_office === OperationalOffices.Uk) {
      row.filters.push('uk_bookings');
    }

    // Add booking to US operational office filter
    if (booking?.team?.operational_office === OperationalOffices.Us) {
      row.filters.push('us_bookings');
    }

    const dateFieldFormat = 'ddd D MMM, h:mmA';

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'created' does not exist on type '{ _id: ... Remove this comment to see the full error message
    row.created = moment(booking.created).format(dateFieldFormat);
    row.sort.created = booking.created;
    if (booking.confirmed_date) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmed_date' does not exist on type '... Remove this comment to see the full error message
      row.confirmed_date = moment(booking.confirmed_date).format(dateFieldFormat);
      row.sort.confirmed_date = booking.confirmed_date;
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmed_date' does not exist on type '... Remove this comment to see the full error message
      row.confirmed_date = '';
    }
    if (booking.approved_date) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'approved_date' does not exist on type '{... Remove this comment to see the full error message
      row.approved_date = moment(booking.approved_date).format(dateFieldFormat);
      row.sort.approved_date = booking.approved_date;
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'approved_date' does not exist on type '{... Remove this comment to see the full error message
      row.approved_date = '';
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking_name' does not exist on type '{ ... Remove this comment to see the full error message
    row.booking_name = (
      <Link to={`/booking/${booking._id}`} title={booking.name || ''} target="_blank" rel="noopener noreferrer">
        {booking.name}
      </Link>
    );

    const actionsCol = {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ _id: a... Remove this comment to see the full error message
    row.status = null;
    switch (booking.status) {
      case BOOKING_STATUS.ACTIVE:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ _id: a... Remove this comment to see the full error message
        row.status = (
          <span>
            <Icon icon="dot" color="BOOKING_STATUS_ACTIVE" /> Live
          </span>
        );
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'batch' does not exist on type '{}'.
        actionsCol.batch = (
          <Button
            text="Batch"
            small
            fill
            onClick={() => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type 'Readonl... Remove this comment to see the full error message
              this.props.context.openSidebar({
                children: <BookingSidebar _id={booking._id} />,
              });
            }}
          />
        );
        row.filters.push('active');
        row.sort.status = -1;
        break;
      case BOOKING_STATUS.IN_REVIEW:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ _id: a... Remove this comment to see the full error message
        row.status = (
          <span>
            <Icon icon="dot" color="BOOKING_STATUS_IN_REVIEW" /> In Review
          </span>
        );
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'review' does not exist on type '{}'.
        actionsCol.review = <LinkButton intent="primary" text="Review" to={`/booking/${booking._id}`} small fill />;
        row.filters.push('in_review');
        row.sort.status = -2;
        break;
      case BOOKING_STATUS.DRAFT:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ _id: a... Remove this comment to see the full error message
        row.status = <code className={Classes.TEXT_MUTED}>Draft</code>;
        break;
      case BOOKING_STATUS.COMPLETED:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ _id: a... Remove this comment to see the full error message
        row.status = <code className={Classes.TEXT_MUTED}>Completed</code>;
        break;
      case BOOKING_STATUS.ARCHIVED:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ _id: a... Remove this comment to see the full error message
        row.status = <code className={Classes.TEXT_MUTED}>Archived</code>;
        break;
      default:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{ _id: a... Remove this comment to see the full error message
        row.status = <code className={Classes.TEXT_MUTED}>({booking.status})</code>;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'batch' does not exist on type '{}'.
    if (actionsCol.batch && _.get(booking, 'config.recruitment.byo')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'batch' does not exist on type '{}'.
      delete actionsCol.batch;
    }

    if (
      booking.team_booking_index !== null &&
      booking.team_booking_index !== undefined &&
      booking.team_booking_index <= 3
    ) {
      row.filters.push('first_3');
    }

    // Check whether booking is not a BYO booking and add to the array
    if (!_.get(booking, 'config.recruitment.byo')) {
      row.filters.push('exclude_byo');
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'team' does not exist on type '{ _id: any... Remove this comment to see the full error message
    row.team = (
      <span className="clip-wrapper">
        <span
          className="clipped"
          // href={`/team/${booking.team._id}`}
          // title={_.get(booking, 'team.name')}
          // target="_blank"
          // rel="noopener noreferrer"
        >
          {booking?.team?.name}
        </span>
        {booking.team_booking_index !== null && (
          <Tag minimal round className="margin-left-05">
            #{booking.team_booking_index}
          </Tag>
        )}
      </span>
    );

    const facilitatorDetails = booking?.config?.contact;
    let phone = facilitatorDetails?.phone;
    if (facilitatorDetails?.name) {
      if (facilitatorDetails?.phone) {
        const facilitatorPhone = utils.parsePhoneNumber(facilitatorDetails.phone, booking?.config?.location?.country);
        if (facilitatorPhone && !('error' in facilitatorPhone) && facilitatorPhone.valid) {
          phone = facilitatorPhone.format.international;
        }
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'facilitator' does not exist on type '{ _... Remove this comment to see the full error message
      row.facilitator = (
        <span>
          <span>{facilitatorDetails.name}</span>
          <Popover
            interactionKind="click"
            autoFocus={false}
            content={
              <div className="padding-2">
                <H5>{facilitatorDetails.name}</H5>
                {facilitatorDetails.email && <div>{facilitatorDetails.email}</div>}

                {phone && <div>{phone}</div>}

                <AnchorButton
                  minimal
                  small
                  intent="primary"
                  rightIcon="arrow-right"
                  target="_blank"
                  className="margin-top-05"
                  href={`https://app.intercom.io/a/apps/w5j4obnp/users/segments/all:${window.btoa(
                    JSON.stringify({
                      predicates: [
                        {
                          comparison: 'eq',
                          value: facilitatorDetails.email,
                          attribute: 'email',
                          type: 'string',
                        },
                        {
                          type: 'role',
                          attribute: 'role',
                          comparison: 'eq',
                          value: 'user_role',
                        },
                      ],
                    }),
                  )}`}
                >
                  Intercom
                </AnchorButton>
              </div>
            }
          >
            <Button minimal small icon="info-sign" className="margin-left-05" />
          </Popover>
        </span>
      );
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'facilitator' does not exist on type '{ _... Remove this comment to see the full error message
      row.facilitator = '-';
    }

    row.filters.push(`format_${booking.type}`);
    let sessionDetail = null;
    switch (booking.type) {
      case BOOKING_TYPE.ONLINE:
        {
          sessionDetail = 'Task';
          const sessionEnd = _.get(booking, 'session.0.end');
          if (sessionEnd) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_dates' does not exist on type '{... Remove this comment to see the full error message
            row.session_dates = (
              <>
                <span>
                  {sessionEnd > Date.now() ? 'Ends ' : 'Ended '}
                  {moment(sessionEnd).format('D MMM')}
                </span>
                <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
                  {moment.duration(sessionEnd - Date.now()).humanize(true)}
                </small>
              </>
            );
          }
          row.sort.session_dates = sessionEnd;
        }
        break;
      case BOOKING_TYPE.LONGITUDINAL:
        {
          sessionDetail = 'Task';
          const sessions = [moment(_.get(booking, 'session.0.end'))];
          let sessionDateDetail = null;
          if (
            _.get(booking, 'config.longitudinal_study.period.time') &&
            _.get(booking, 'config.longitudinal_study.period.frequency')
          ) {
            const periodFrequencyValue = _.get(
              {
                1: 'day',
                2: 'week',
                3: 'month',
              },
              booking?.config?.longitudinal_study?.period?.frequency || 1,
            );
            if (periodFrequencyValue) {
              sessions.push(
                sessions[0]
                  .clone()
                  .subtract(
                    booking?.config?.longitudinal_study?.period?.time ?? 0,
                    periodFrequencyValue as unitOfTime.Base,
                  ),
              );
            }

            sessionDateDetail = `(${booking?.config?.longitudinal_study?.period?.time} ${periodFrequencyValue || '?'} study)`;
          }
          const sessionDates = _.chain(sessions)
            .sortBy(m => m.valueOf())
            .map(m => m.format('D MMM'))
            .uniq()
            .value();

          // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_dates' does not exist on type '{... Remove this comment to see the full error message
          row.session_dates = (
            <>
              <span>
                {sessions[0].valueOf() > Date.now() ? 'Ends ' : 'Ended '}
                {_.uniq(sessionDates).join(' for ')}
              </span>
              {sessionDateDetail && (
                <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>{sessionDateDetail}</small>
              )}
            </>
          );
          row.sort.session_dates = _.min(sessions.map(m => m.valueOf()));
        }
        break;
      default: {
        switch (_.get(booking, 'config.session.type')) {
          case BOOKING_SESSION_TYPE.ONE_ON_ONE:
            sessionDetail = 'Interviews';
            break;
          case BOOKING_SESSION_TYPE.FOCUS_GROUP:
            sessionDetail = 'Focus Group';
            break;
          default:
        }
        if (_.find(booking.session, 'start')) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          const sessionStart = _.minBy(booking.session, 'start').start;
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          const sessionEnd = _.maxBy(booking.session, 'end').end;
          const sessionDates = _.uniq([moment(sessionStart).format('D MMM'), moment(sessionEnd).format('D MMM')]);
          let sessionDateDetail = null;
          if (sessionStart && sessionStart > Date.now()) {
            sessionDateDetail = `Starting ${moment.duration(sessionStart - Date.now()).humanize(true)}`;
          } else if (sessionEnd && sessionEnd > Date.now()) {
            sessionDateDetail = `Ends ${moment.duration(sessionEnd && sessionEnd - Date.now()).humanize(true)}`;
          } else if (sessionEnd && sessionEnd < Date.now()) {
            sessionDateDetail = `Ended ${moment.duration(sessionEnd - Date.now()).humanize(true)}`;
          }
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'session_dates' does not exist on type '{... Remove this comment to see the full error message
          row.session_dates = (
            <>
              <span>{_.uniq(sessionDates).join(' - ')}</span>
              {sessionDateDetail && (
                <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>{sessionDateDetail}</small>
              )}
            </>
          );
          row.sort.session_dates = sessionStart;
        }
      }
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'sessions' does not exist on type '{ _id:... Remove this comment to see the full error message
    row.sessions = (
      <>
        <span>{booking?.config?.session?.duration ? `${booking.config?.session?.duration} min` : '?'}</span>
        {sessionDetail && <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>{sessionDetail}</small>}
      </>
    );
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'incentive' does not exist on type '{ _id... Remove this comment to see the full error message
    row.incentive = formatIncentivesToString(booking.config?.incentives?.filter(incentive => incentive !== null));

    let participantCategory = null;
    switch (_.get(booking, 'config.participant_category')) {
      case BOOKING_PARTICIPANT_CATEGORY.GENERAL:
        participantCategory = 'General';
        break;
      case BOOKING_PARTICIPANT_CATEGORY.PROFESSIONALS:
        participantCategory = 'Professional';
        break;
      case BOOKING_PARTICIPANT_CATEGORY.HARD:
        participantCategory = 'Super hard';
        break;
      default:
        participantCategory = '?';
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'total_participants' does not exist on ty... Remove this comment to see the full error message
    row.total_participants = (
      <span>
        {booking.total_participants || '0'} <small>({participantCategory})</small>
      </span>
    );

    let nettCreditSpend = 0;
    const credit_activity = booking?.credit_activity ?? [];
    credit_activity
      .filter((creditActivity: any) => {
        return [CREDIT_ACTIVITY_TYPE.CREDIT_PURCHASE].indexOf(creditActivity.type) === -1;
      })
      .forEach((creditActivity: any) => {
        // creditHistory.push(...) // TODO: build a history to display in a popover
        let amount = creditActivity.amount;
        if (creditActivity.accounting_type === ACCOUNTING_TYPE.CREDIT) {
          amount *= -1;
        }
        nettCreditSpend += amount;
      });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'credits_per_participant' does not exist ... Remove this comment to see the full error message
    row.credits_per_participant = (
      <>
        <span>{_.get(booking, 'config.credits_per_participant')}</span>
        <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
          {nettCreditSpend.toLocaleString()} total
        </small>
      </>
    );
    row.sort.credits_per_participant = _.get(booking, 'config.credits_per_participant', 0) + nettCreditSpend / 100000;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'participants_completed' does not exist o... Remove this comment to see the full error message
    row.participants_completed = '-';
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'turnout' does not exist on type '{ _id: ... Remove this comment to see the full error message
    row.turnout = '-';
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'eligible' does not exist on type '{ _id:... Remove this comment to see the full error message
    row.eligible = null;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmed' does not exist on type '{ _id... Remove this comment to see the full error message
    row.confirmed = null;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'needed' does not exist on type '{ _id: a... Remove this comment to see the full error message
    row.needed = null;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'cancellations' does not exist on type '{... Remove this comment to see the full error message
    row.cancellations = null;

    const stillNeed =
      (booking.total_participants ?? 0) -
      _.sum([
        _.get(booking, 'UserStatusCount.completed') || 0,
        _.get(booking, 'UserStatusCount.confirmed') || 0,
        _.get(booking, 'UserStatusCount.scheduled') || 0,
        _.get(booking, 'UserStatusCount.in_progress') || 0,
      ]);

    const timeRemaining = moment.duration(
      (_.chain(booking).get('session').maxBy('end').get('end').value() || Date.now()) - Date.now(),
    );

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'time_remaining' does not exist on type '... Remove this comment to see the full error message
    row.time_remaining =
      timeRemaining.asSeconds() > 0 ? (
        <span>Ends in {timeRemaining.humanize()}</span>
      ) : (
        <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
          Ended {timeRemaining.humanize(true)}
        </small>
      );
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'time_remaining' does not exist on type '... Remove this comment to see the full error message
    row.sort.time_remaining = timeRemaining.valueOf() > 0 ? timeRemaining.valueOf() : undefined;

    if (booking.status === BOOKING_STATUS.ACTIVE) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'participants_completed' does not exist o... Remove this comment to see the full error message
      row.participants_completed = <strong>{_.get(booking, 'UserStatusCount.completed') || 0}</strong>;
      row.sort.participants_completed = _.get(booking, 'UserStatusCount.completed') || 0;
      if (_.get(booking, 'UserStatusCount.completed')) {
        let averageRating = null;
        if (_.get(booking, 'ParticipantRatings.length', 0) > 0) {
          averageRating = _.chain(booking.ParticipantRatings)
            .map(r => _.values(r).filter(v => typeof v === 'number'))
            .flatten()
            .mean()
            .value();
        }
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'participants_completed' does not exist o... Remove this comment to see the full error message
        row.participants_completed = (
          <>
            <strong>{booking.UserStatusCount?.completed}</strong>
            {averageRating !== null && (
              <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
                {utils.round(averageRating, 1)} star{averageRating === 1 ? '' : 's'}
              </small>
            )}
          </>
        );

        const turnoutWarnings = [];
        const lowRatings = _.chain(booking)
          .get('ParticipantRatings', [])
          .map((r: any) => _.chain(r).values().min().value())
          .filter((r: any) => r <= LOW_RATING_THRESHOLD)
          .value().length;
        if (lowRatings) {
          turnoutWarnings.push(`${lowRatings} low star rating${lowRatings === 1 ? '' : 's'}`);
        }

        if (_.get(booking, 'UserStatusCount.cancel_noshow') || turnoutWarnings.length > 0) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'turnout' does not exist on type '{ _id: ... Remove this comment to see the full error message
          row.turnout = (
            <>
              {_.get(booking, 'UserStatusCount.cancel_noshow') && (
                <strong className="text-danger">
                  {booking.UserStatusCount?.cancel_noshow} no-show
                  {booking.UserStatusCount?.cancel_noshow === 1 ? '' : 's'}
                </strong>
              )}
              {turnoutWarnings.length > 0 && (
                <small className={['text-danger', Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
                  {turnoutWarnings.join(', ')}
                </small>
              )}
            </>
          );
        }
      }

      let eligible = _.get(booking, 'UserStatusCount.available_eligible', 0);
      if (booking.type === BOOKING_TYPE.ONLINE) {
        eligible =
          _.get(booking, 'UserStatusCount.available_eligible', 0) +
          _.get(booking, 'UserStatusCount.unavailable_eligible', 0);
      }

      const totalApplicants = _.sum([
        _.get(booking, 'UserStatusCount.available_eligible', 0),
        _.get(booking, 'UserStatusCount.available_ineligible', 0),
        _.get(booking, 'UserStatusCount.unavailable_eligible', 0),
        _.get(booking, 'UserStatusCount.unavailable_ineligible', 0),
      ]);

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'eligible' does not exist on type '{ _id:... Remove this comment to see the full error message
      row.eligible = (
        <>
          <strong className={stillNeed > eligible ? 'text-danger' : ''}>{eligible || '0'}</strong>
          <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>out of {totalApplicants} total</small>
        </>
      );
      row.sort.eligible = eligible + (stillNeed > eligible ? -1000 : 0) + totalApplicants / 100000;

      switch (booking.type) {
        case BOOKING_TYPE.ONLINE:
        case BOOKING_TYPE.LONGITUDINAL:
          {
            const totalConfirmed =
              _.get(booking, 'UserStatusCount.confirmed', 0) + _.get(booking, 'UserStatusCount.scheduled', 0);
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmed' does not exist on type '{ _id... Remove this comment to see the full error message
            row.confirmed = (
              <>
                {totalConfirmed ? <strong>{totalConfirmed} confirmed</strong> : null}

                <strong>{_.get(booking, 'UserStatusCount.in_progress') || '0'} in progress</strong>
                <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
                  {_.get(booking, 'UserStatusCount.waitlisted', 0) || '0'} waitlisted
                </small>
              </>
            );
          }
          break;
        default:
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmed' does not exist on type '{ _id... Remove this comment to see the full error message
          row.confirmed = (
            <>
              <strong>
                {_.get(booking, 'UserStatusCount.confirmed', 0) + _.get(booking, 'UserStatusCount.scheduled', 0)}
              </strong>
              <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
                {_.get(booking, 'UserStatusCount.waitlisted', 0) || '0'} waitlisted
              </small>
            </>
          );
      }
      row.sort.confirmed =
        _.get(booking, 'UserStatusCount.confirmed', 0) +
        _.get(booking, 'UserStatusCount.scheduled', 0) +
        _.get(booking, 'UserStatusCount.in_progress', 0) +
        _.get(booking, 'UserStatusCount.waitlisted', 0) / 10000;

      if (stillNeed > 0) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'needed' does not exist on type '{ _id: a... Remove this comment to see the full error message
        row.needed = (
          <>
            <strong>{stillNeed}</strong>
            <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
              {_.get(booking, 'UserStatusCount.invited', 0) || '0'} invited
            </small>
          </>
        );
      } else {
        row.classes.push('opacity-50');
      }
      row.sort.needed = stillNeed ?? undefined;

      const secondaryCancellations = [];
      if (_.get(booking, 'UserStatusCount.cancel_admin')) {
        secondaryCancellations.push(`${booking.UserStatusCount?.cancel_admin} admin`);
      }
      if (_.get(booking, 'UserStatusCount.cancel_client')) {
        secondaryCancellations.push(`${booking.UserStatusCount?.cancel_client} client`);
      }
      if (_.get(booking, 'UserStatusCount.cancel_system')) {
        secondaryCancellations.push(`${booking.UserStatusCount?.cancel_system} system`);
      }

      // cancel_participant

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'cancellations' does not exist on type '{... Remove this comment to see the full error message
      row.cancellations = (
        <>
          {_.get(booking, 'UserStatusCount.cancel_participant') && (
            <strong className="text-danger">{booking.UserStatusCount?.cancel_participant} participant</strong>
          )}

          {secondaryCancellations.length > 0 && (
            <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
              {secondaryCancellations.join(', ')}
            </small>
          )}
        </>
      );
      row.sort.cancellations =
        _.get(booking, 'UserStatusCount.cancel_participant', 0) +
        (_.get(booking, 'UserStatusCount.cancel_admin', 0) +
          _.get(booking, 'UserStatusCount.cancel_client', 0) +
          _.get(booking, 'UserStatusCount.cancel_system', 0)) /
          100;
      if (!row.sort.cancellations) row.sort.cancellations = undefined;

      if (stillNeed && stillNeed > eligible) {
        row.filters.push('under_recruited');
      } else if (stillNeed > 0) {
        row.filters.push('under_fulfilled');
      }
    }

    const urgency =
      ((booking.total_participants ??
        0 -
          _.sum([
            (_.get(booking, 'UserStatusCount.completed') || 0) * 1,
            (_.get(booking, 'UserStatusCount.confirmed') || 0) * 0.95,
            (_.get(booking, 'UserStatusCount.scheduled') || 0) * 0.85,
            // (_.get(booking, 'UserStatusCount.invited') || 0) * 0.75,
            Math.min(
              (Number.isNaN(stillNeed) || stillNeed === null ? booking.total_participants : stillNeed) ?? 0,
              _.get(booking, 'UserStatusCount.available_eligible') || 0,
            ) * 0.5,
          ])) *
        (_.get(booking, 'config.credits_per_participant') || 100)) /
      Math.max(timeRemaining.asWeeks(), 1);

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'urgency' does not exist on type '{ _id: ... Remove this comment to see the full error message
    row.urgency = urgency; // TODO
    row.sort.urgency = urgency; // TODO

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'urgencyStats' does not exist on type '{ ... Remove this comment to see the full error message
    row.urgencyStats = {
      id: booking._id,
      name: `${booking.name} - ${_.get(booking, 'team.name')}`,
      total_participants: booking.total_participants,
      completed: _.get(booking, 'UserStatusCount.completed') || 0,
      confirmed: _.get(booking, 'UserStatusCount.confirmed') || 0,
      scheduled: _.get(booking, 'UserStatusCount.scheduled') || 0,
      invited: _.get(booking, 'UserStatusCount.invited') || 0,
      available_eligible: _.get(booking, 'UserStatusCount.available_eligible') || 0,
      stillNeed,
      credits_per_participant: _.get(booking, 'config.credits_per_participant') || 100,
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      weeksLeft: timeRemaining.asWeeks(true),
    };

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'automated_icon' does not exist on type '... Remove this comment to see the full error message
    row.automated_icon = null;
    if (_.get(booking, 'AutomationScore')) {
      if (booking?.AutomationScore?.admin_actions && booking?.AutomationScore?.admin_actions <= 2) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'automated_icon' does not exist on type '... Remove this comment to see the full error message
        row.automated_icon = (
          <span className="align-center">
            <Emoji emoji="🤖" label="Automated" />
          </span>
        );
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'automated_icon' does not exist on type '... Remove this comment to see the full error message
        row.automated_icon = (
          <span className="align-center">
            <small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
              {(booking?.AutomationScore?.admin_actions || 0) * -1}
            </small>
          </span>
        );
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'automated' does not exist on type '{}'.
      row.sort.automated = booking.AutomationScore.admin_actions * -1;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'actions' does not exist on type '{ _id: ... Remove this comment to see the full error message
    row.actions = (
      <ButtonGroup>
        {_.chain(actionsCol)
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          .mapValues((button, key) => ({ ...button, key }))
          .values()
          .filter()
          .value()}
      </ButtonGroup>
    );

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking_name' does not exist on type '{}... Remove this comment to see the full error message
    row.sort.booking_name = _.get(booking, 'name') && booking.name.toLowerCase();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'team' does not exist on type '{}'.
    row.sort.team = _.get(booking, 'team.name') && booking.team.name.toLowerCase();
    if (booking.team_booking_index) row.sort.team += ` ${booking.team_booking_index / 10000}`;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'facilitator' does not exist on type '{}'... Remove this comment to see the full error message
    row.sort.facilitator = _.get(booking, 'config.contact.name') && booking.config.contact.name.toLowerCase();
    row.sort.format = `${booking.type}.${_.get(booking, 'config.options.review_submission') ? '1' : '0'}`;
    row.sort.location = [
      _.get(booking, 'config.location.country', ''),
      _.get(booking, 'config.location.state', ''),
      _.get(booking, 'config.location.region', ''),
      _.get(booking, 'config.location.city', ''),
    ]
      .join(' ')
      .toLowerCase()
      .replace(/\s+/g, '')
      .replace(/[^0-9a-z\s]/g, '');

    row.sort.sessions = _.get(booking, 'config.session.duration');
    row.sort.incentive = _.get(booking, 'config.incentives[0].value');
    row.sort.total_participants =
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'total_participants' does not exist on ty... Remove this comment to see the full error message
      booking.total_participants + _.get(booking, 'config.credits_per_participant', 0) / 10000;
    row.sort.turnout = _.get(booking, 'UserStatusCount.cancel_noshow') || undefined;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'search' does not exist on type '{ _id: a... Remove this comment to see the full error message
    row.search = [booking.name, _.get(booking, 'team.name'), _.get(booking, 'config.contact.name'), booking._id].join(
      ' ',
    );

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'visibility' does not exist on type '{ _i... Remove this comment to see the full error message
    row.visibility = (
      <Mutation mutation={setBookingVisibilityMutation}>
        {(visibilityMutate: any, { loading, data }: any) => {
          let hidden = _.get(booking, 'config.hidden');
          if (_.has(data, 'setBookingVisibility.config.hidden')) {
            hidden = data.setBookingVisibility.config.hidden;
          }
          return (
            <Button
              minimal
              small
              disabled={loading}
              className="visibility-button"
              icon={hidden ? 'eye-off' : 'eye-open'}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ minimal: true; small: true; disabled: any;... Remove this comment to see the full error message
              data={data}
              onClick={() => {
                visibilityMutate({ variables: { _booking_id: booking._id, hidden: !_.get(booking, 'config.hidden') } });
              }}
            />
          );
        }}
      </Mutation>
    );
    row.sort.visibility = _.get(booking, 'config.hidden') ? 1 : 0;
    row.classes.push(`visibility-${_.get(booking, 'config.hidden') ? 'hidden' : 'visible'}`);

    return row;
  }

  parseRows(bookings: NonNullable<Admin_BookingsListCurrentQuery['bookings']>) {
    const rows = bookings.filter(booking => !!booking).map(booking => this.parseRow(booking));
    if (!rows || rows.length === 0) return [];
    return rows;
  }

  queryCompletedCallback(data: Admin_BookingsListCurrentQuery) {
    this.setState({
      rows: this.parseRows(data.bookings || []),
      loading: false,
    });
  }

  getDefaultActiveBasedOnOperationalOffice(
    operationalOffice: OperationalOffices,
    adminUsers: AdminUsersQuery['adminUsers'],
  ) {
    const signedInUser = localStorage.getItem('user');
    const user = adminUsers?.find(item => item?._id === signedInUser);
    const userOperationalOffice = user?.admin?.operational_office;
    return operationalOffice === userOperationalOffice;
  }

  render() {
    return (
      <Query<AdminUsersQuery> query={fetchAdminUsers} variables={{ customer_success: true }} fetchPolicy="cache-first">
        {({ loading, data }) => {
          if (loading || !data) {
            return <Spinner />;
          }
          return (
            <div id="bookings-table-current">
              <DataTable
                borderedHorizontal
                hover
                selectViews
                loading={this.state.loading}
                loadingRows={12}
                stickyHeader="#admin-app"
                useSettings
                rows={this.state.rows}
                id="bookings-table-current-datatable"
                defaultSorting={{
                  status: 'asc',
                  needed: 'desc',
                }}
                search
                filters={{
                  props: { className: ['margin-2 margin-left-1', Classes.TEXT_SMALL].join(' ') },
                  countRows: false,
                  multiSelect: true,
                  values: [
                    {
                      key: 'au_bookings',
                      label: 'AU office',
                      defaultActive: this.getDefaultActiveBasedOnOperationalOffice(
                        OperationalOffices.Au,
                        data.adminUsers,
                      ),
                    },
                    {
                      key: 'uk_bookings',
                      label: 'UK office',
                      defaultActive: this.getDefaultActiveBasedOnOperationalOffice(
                        OperationalOffices.Uk,
                        data.adminUsers,
                      ),
                    },
                    {
                      key: 'us_bookings',
                      label: 'US office',
                      defaultActive: this.getDefaultActiveBasedOnOperationalOffice(
                        OperationalOffices.Us,
                        data.adminUsers,
                      ),
                    },
                    { key: 'active', label: 'Live', defaultActive: false },
                    { key: 'in_review', label: 'In Review', defaultActive: false },
                    { key: 'my_bookings', label: 'My bookings', defaultActive: false },
                    { key: 'unassigned', label: 'Unassigned', defaultActive: false },
                    { key: 'askable_plus', label: 'Askable+', defaultActive: false },
                    { key: `format_${BOOKING_TYPE.FACE_TO_FACE}`, label: 'Face to face' },
                    { key: `format_${BOOKING_TYPE.REMOTE}`, label: 'Remote' },
                    { key: `format_${BOOKING_TYPE.ONLINE}`, label: 'Online' },
                    { key: `format_${BOOKING_TYPE.LONGITUDINAL}`, label: 'Longitudinal' },
                    { key: 'under_recruited', label: 'Under-Recruited' },
                    { key: 'under_fulfilled', label: 'Under-Fulfilled' },
                    { key: 'first_3', label: 'First 3' },
                    { key: 'exclude_byo', label: 'Exclude BYO' },
                  ],
                  all: true,
                }}
                noResults={
                  <div className="margin-3 margin-top-1">
                    <NonIdealState
                      icon="th-disconnect"
                      title="No results"
                      description="No bookings found. It’s probably an error."
                    />
                  </div>
                }
                reload={async () => {
                  if (this.props.fetchBookings.refetch) {
                    await this.props.fetchBookings.refetch();
                  }
                }}
                reloading={this.state.loading}
                columns={[
                  {
                    key: 'assignee',
                    label: 'PIC',
                    render: 'assignee',
                  },
                  {
                    key: 'tag',
                    label: 'Tag',
                    render: 'tag',
                    sort: true,
                    props: { className: 'padding-0 padding-left-05' },
                  },
                  {
                    key: 'booking_name',
                    label: 'Booking Name',
                    clip: 0.15,
                    render: 'booking_name',
                    sort: { icon: 'alphabetical' },
                  },
                  {
                    key: 'status',
                    label: 'Status',
                    render: 'status',
                    sort: true,
                  },
                  {
                    key: 'visibility',
                    label: '',
                    render: 'visibility',
                    sort: true,
                    narrow: true,
                  },
                  {
                    key: 'actions',
                    label: '',
                    render: 'actions',
                    narrow: true,
                  },
                  {
                    key: 'team',
                    label: 'Client Team Name',
                    clip: 0.15,
                    render: 'team',
                    sort: { icon: 'alphabetical' },
                  },
                  {
                    key: 'facilitator',
                    label: 'Facilitator',
                    render: 'facilitator',
                    sort: { icon: 'alphabetical' },
                  },
                  {
                    key: 'format',
                    label: 'Format',
                    render: 'booking_format',
                    sort: true,
                  },
                  {
                    key: 'location',
                    label: 'Location',
                    render: 'booking_location',
                    sort: { icon: 'alphabetical' },
                  },
                  {
                    key: 'created',
                    label: 'Created',
                    render: 'created',
                    sort: { direction: 'desc' },
                  },
                  {
                    key: 'confirmed_date',
                    label: 'Submitted',
                    render: 'confirmed_date',
                    sort: { direction: 'desc' },
                  },
                  {
                    key: 'approved_date',
                    label: 'Approved',
                    render: 'approved_date',
                    sort: { direction: 'desc' },
                  },
                  {
                    key: 'sessions',
                    label: 'Sessions',
                    render: 'sessions',
                    sort: { icon: 'numerical' },
                  },
                  {
                    key: 'incentive',
                    label: 'Incentive',
                    render: 'incentive',
                    sort: { icon: 'numerical' },
                  },
                  {
                    key: 'session_dates',
                    label: 'Session Dates',
                    render: 'session_dates',
                    sort: { direction: 'asc' },
                  },
                  {
                    key: 'total_participants',
                    label: 'Participants',
                    render: 'total_participants',
                    sort: { icon: 'numerical', direction: 'desc' },
                  },
                  {
                    key: 'credits_per_participant',
                    // label: <Fragment><span>Credits</span><small className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>pp</small></Fragment>,
                    label: 'Credits each',
                    render: 'credits_per_participant',
                    sort: { icon: 'numerical', direction: 'desc' },
                  },
                  {
                    key: 'participants_completed',
                    label: 'Completed',
                    render: 'participants_completed',
                    sort: { icon: 'numerical' },
                  },
                  {
                    key: 'turnout',
                    label: 'Turnout',
                    render: 'turnout',
                    sort: { icon: 'numerical', direction: 'desc' },
                  },
                  {
                    key: 'time_remaining',
                    label: 'Time left',
                    render: 'time_remaining',
                    sort: { icon: 'numerical' },
                  },
                  {
                    key: 'eligible',
                    label: 'Eligible Applicants',
                    render: 'eligible',
                    sort: { icon: 'numerical' },
                  },
                  {
                    key: 'confirmed',
                    label: 'Confirmed',
                    render: 'confirmed',
                    sort: { icon: 'numerical' },
                  },
                  {
                    key: 'needed',
                    label: 'Still Need',
                    render: 'needed',
                    sort: { icon: 'numerical', direction: 'desc' },
                  },
                  {
                    key: 'cancellations',
                    label: 'Cancellations',
                    render: 'cancellations',
                    sort: { icon: 'numerical', direction: 'desc' },
                  },
                  {
                    key: 'automated',
                    label: 'Auto',
                    render: 'automated_icon',
                    sort: { direction: 'desc' },
                  },
                ]}
              />
              <PageTitle title="Current Bookings" />
            </div>
          );
        }}
      </Query>
    );
  }
}

const FetchBookingsContainer = graphql(fetchBookingsQuery, {
  name: 'fetchBookings',
  options: {
    variables: {
      status: [BOOKING_STATUS.ACTIVE, BOOKING_STATUS.IN_REVIEW],
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  },
});

const UpdateAdminUser = graphql(updateBookingAdminUser, {
  name: 'updateBookingAdminUser',
});

export default compose(FetchBookingsContainer, UpdateAdminUser)(AppConsumer(CurrentBookings));
