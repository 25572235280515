import { Button, H3 } from '@blueprintjs/core';
import _ from 'lodash';
import { useMemo } from 'react';

import { InlineInputEdit } from 'components/common/InlineEdit';
import { useAppContext } from 'components/common/appProvider';
import { VirtualisedTable } from 'components/table/VirtualisedTable';
import { AdminWigPeriodTypes, useAdminSaveWigMutation, useAdminWigsQuery, useWigMetaQuery } from 'generated/graphql';

import { WigAddView } from './wid-add.view';

export const WigTwo = () => {
  const context = useAppContext();
  const [, updateWigValue] = useAdminSaveWigMutation();
  const [{ data: wigData, fetching: fetchingWigData }, refetchWigData] = useAdminWigsQuery({
    variables: {
      search: { period_type: AdminWigPeriodTypes.Month },
    },
  });

  const [{ data: wigMeta, fetching: fetchingWigMeta }] = useWigMetaQuery();

  const columns = useMemo<any>(() => {
    const wigMetaData = _.chain(wigMeta?.settingsByKey?.value_raw)
      .toPairs()
      .map(([key, meta]) => ({ key, ...meta }))
      .value();

    const derivedValues =
      wigMetaData.map(wd => {
        return {
          Header: wd.name,
          canSort: false,
          Cell: ({ row }: { row: any }) => {
            const s = row?.original?.goals?.find((a: any) => a.key === wd.key);

            return (
              <InlineInputEdit
                onChange={(v: string | number) => {
                  const input = {
                    period_name: row.original.period_name,
                    period_type: AdminWigPeriodTypes.Month,
                    period_start: row.original.period_start,
                    period_end: row.original.period_end,
                    goals: [...row.original.goals, { key: wd.key, value: parseInt(v.toString()) }].map(goal => {
                      if (goal.key === wd.key) {
                        return {
                          key: goal.key,
                          value: parseInt(v.toString()),
                        };
                      }

                      return {
                        key: goal.key,
                        value: goal.value,
                      };
                    }),
                  };

                  updateWigValue({ input }).then(() => {
                    refetchWigData({ requestPolicy: 'network-only' });
                  });
                }}
                editable
                value={s?.value ?? 0}
              />
            );
          },
        };
      }) ?? [];

    return [
      {
        Header: 'Month',
        accessor: (row: any) => row.period_start,
        Cell: ({ row }: { row: any }) => {
          return row.original.period_name;
        },
      },
      {
        Header: 'Operational Office',
        Cell: ({ row }: { row: any }) => {
          return row.original.operational_office;
        },
      },
      ...derivedValues,
    ];
  }, [wigMeta, wigData]);

  const initialSortBy = useMemo(() => {
    return [{ id: 'Month', desc: true }];
  }, []);

  const TableHeader = () => {
    return (
      <div className="tw-flex tw-items-center tw-justify-between">
        <H3 className="tw-m-0 tw-mr-2">WIG</H3>
        <Button
          intent="success"
          onClick={() => {
            context.openSidebar({
              children: <WigAddView onClose={() => context.closeSidebar()} />,
            });
          }}
        >
          Create WIG
        </Button>
      </div>
    );
  };

  return (
    <VirtualisedTable
      isLoading={fetchingWigData || fetchingWigMeta}
      columns={columns}
      // @ts-expect-error: TODO: Virtualised Table types are Bunk
      data={wigData?.adminWigs || []}
      initialSortBy={initialSortBy}
      tableHeader={<TableHeader />}
    />
  );
};
