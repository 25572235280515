import { useUpdateBookingAdminUserMutation } from 'generated/graphql';

import { BookingAdminAssignee } from '../components/BookingAdminAssignee';

import type { Booking, UpdateBookingAdminUserMutationVariables, OperationalOffices } from 'generated/graphql';
import type { VFC } from 'react';

type Props = {
  bookingId: string;
  adminUser: Booking['admin_user'];
  operationalOffice?: OperationalOffices;
};

export const BookingPersonInCharge: VFC<Props> = ({ bookingId, adminUser, operationalOffice }) => {
  const [, updateAdminUser] = useUpdateBookingAdminUserMutation();

  const handleUpdate = async (updatePayload: UpdateBookingAdminUserMutationVariables) => {
    try {
      const update = await updateAdminUser(updatePayload);

      console.log(update);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <BookingAdminAssignee
      adminUser={adminUser}
      bookingId={bookingId}
      operationalOffice={operationalOffice}
      handleBookingUserUpdate={handleUpdate}
    />
  );
};
