import { Button } from '@blueprintjs/core';

import type { FunctionalComponent } from 'types/types';

type Props = {
  fetching: boolean;
  hasPreviousPage: boolean;
  perPage: number;
  currentPage: number;
  totalCount: number;
  onNextClick: () => void;
  hasNextPage: boolean;
  onPreviousClick: () => void;
};

export const Pagination: FunctionalComponent<Props> = ({
  perPage,
  totalCount,
  currentPage,
  onNextClick,
  onPreviousClick,
  hasNextPage,
  fetching,
  hasPreviousPage,
}) => {
  const getCurrentPage = () => {
    if (currentPage === 1) {
      return 1;
    }

    return currentPage * perPage - perPage;
  };

  return (
    <div className="tw-flex tw-p-6 tw-items-center tw-w-full tw-justify-center">
      <div className="tw-mr-6">
        {getCurrentPage()} - {Math.min(currentPage * perPage + (currentPage === 1 ? 0 : 25), totalCount)} of{' '}
        {totalCount}
      </div>
      <Button disabled={!hasPreviousPage || fetching} onClick={onPreviousClick} className="tw-mr-1">
        Previous
      </Button>
      <Button className="tw-ml-1" disabled={!hasNextPage || fetching} onClick={onNextClick}>
        Next
      </Button>
    </div>
  );
};
