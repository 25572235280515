import { BOOKING_STATUS } from 'lib/constants';

import type { BookingProp } from '../types';

type Props = {
  booking?: BookingProp | null;
  loading: boolean;
};

export const InfoTranslation = ({ booking, loading }: Props) => {
  if (booking?.status === BOOKING_STATUS.IN_REVIEW || loading || !booking?.settings) {
    return null;
  }

  const isTranslationOn = booking?.settings.translation;

  return (
    <div className="section">
      Translation is <span>{isTranslationOn ? 'on' : 'off'}</span>
    </div>
  );
};
