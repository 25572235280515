import { graphql } from '@apollo/client/react/hoc';
import { Classes, MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import gql from 'graphql-tag';
import _, { flowRight as compose } from 'lodash';
import { Component } from 'react';
import { v5 as uuidv5 } from 'uuid';

import { Spinner } from './spinner.view';
import './styles/suggest.scss';

interface Props {
  countries: string[];
  defaultLocations: any;
  getLocationFromGooglePlaceId: any;
  locationAutocomplete: any;
  onItemSelect: any;
  popoverProps: any;
  searchCentre: any;
  inputProps: any;
  suggestProps: any;
  value?: { value: { city: string; state: string; country: string } };
}
interface State {
  loading: boolean;
  query: string;
  queryPattern: RegExp;
  items: any[];
  searchResults: any[];
  selectedItem?: { city: string; state: string; country: string };
  disabled: boolean;
  placeId: string | null;
  sessionToken: string | null;
  placeholder: string;
}
class LocationSuggest extends Component<Props, State> {
  debounceLimit: any;
  debounceTimeout: any;
  constructor(props: Props) {
    super(props);

    this.state = {
      // activeItem: null,
      loading: false,
      query: '',
      queryPattern: new RegExp(''),
      items: [],
      searchResults: props.defaultLocations || [],
      selectedItem: props.value?.value,
      disabled: false,
      placeId: null,
      sessionToken: null,
      placeholder: '',
    };

    this.debounceTimeout = null;
    this.debounceLimit = 500;

    this.locationSearch = this.locationSearch.bind(this);
    this.locationSearchDebounced = this.locationSearchDebounced.bind(this);
    this.filterSearchResults = this.filterSearchResults.bind(this);
    this.sessionToken = this.sessionToken.bind(this);
  }

  componentWillReceiveProps(newProps: Props) {
    if (
      newProps.locationAutocomplete &&
      !newProps.locationAutocomplete.loading &&
      newProps.locationAutocomplete.locationAutocomplete
    ) {
      this.setState({
        searchResults: newProps.locationAutocomplete.locationAutocomplete,
        items: this.filterSearchResults(null, newProps.locationAutocomplete.locationAutocomplete),
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.placeId && !this.state.disabled && this.state.placeId !== prevState.placeId) {
      this.setState({ disabled: true });

      const { placeId, sessionToken } = this.state;
      this.props.getLocationFromGooglePlaceId.refetch({ placeId, sessionToken }).then(({ data }: any) => {
        const Location = _.get(data, 'getLocationFromGooglePlaceId');
        this.setState({ disabled: false, sessionToken: null, placeId: null });
        if (Location && this.props.onItemSelect) {
          this.props.onItemSelect(Location);
          this.setState({ selectedItem: Location });
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  }

  locationSearch() {
    if (this.debounceTimeout) {
      return;
    }
    this.debounceTimeout = setTimeout(this.locationSearchDebounced, this.debounceLimit);
  }

  locationSearchDebounced() {
    if (!this.state.query) {
      this.debounceTimeout = null;
      return;
    }
    this.props.locationAutocomplete.refetch({
      search: {
        countries: this.props.countries,
        types: '(regions)',
        input: this.state.query,
        latitude: _.get(this.props.searchCentre, 'latitude'),
        longitude: _.get(this.props.searchCentre, 'longitude'),
        sessionToken: this.sessionToken(),
      },
    });
    this.debounceTimeout = null;
  }

  sessionToken() {
    if (this.state.sessionToken) return this.state.sessionToken;

    const namespace = uuidv5('https://admin.askable.com', uuidv5.URL);
    const token = uuidv5(`LocationSuggest|${Date.now()}`, namespace);

    this.setState({ sessionToken: token });
    return token;
  }

  filterSearchResults(queryPattern: any, results: any) {
    return _.filter(results || this.state.searchResults, item =>
      item.description?.match(queryPattern || this.state.queryPattern),
    );
  }

  locationToLabel(location: any) {
    return `${location.city}, ${location.state || ''} ${location.country || ''}`.replace(/\s+/g, ' ');
  }

  render() {
    let noResults = <MenuItem text="Suburb / city / region" disabled />;
    if (this.state.query > '') {
      noResults = <MenuItem text="No items found" disabled />;
    }

    const _props = {
      popover: {
        ...this.props.popoverProps,
        minimal: true,
      },
      input: this.props.inputProps || {},
      suggest: this.props.suggestProps || {},
    };

    if (_props.input && _props.input.fill) {
      _props.input.className = `${_props.input.className || ''} ${Classes.FILL}`;
      _props.popover.className = `${_props.popover.className || ''} ${Classes.FILL}`;
    }

    _props.input.placeholder = this.state.placeholder;
    _props.input.autoComplete = 'new-password';

    console.log(this.state.selectedItem);

    return (
      <Suggest<{ placeId: string; description: string; city: string; state: string; country: string }>
        {..._props.suggest}
        items={this.state.loading ? [] : this.state.items}
        itemRenderer={(item, itemProps) => {
          return (
            <MenuItem
              key={item.placeId}
              text={item.description}
              // label={item.postcode}
              active={itemProps.modifiers.active}
              disabled={itemProps.modifiers.disabled}
              onClick={itemProps.handleClick}
            />
          );
        }}
        popoverProps={_props.popover}
        inputProps={_props.input}
        inputValueRenderer={this.locationToLabel}
        noResults={
          this.state.loading ? <MenuItem text="Loading..." disabled labelElement={<Spinner withText />} /> : noResults
        }
        query={this.state.query || ''}
        onQueryChange={query => {
          const queryPattern = new RegExp(query.replace(/[^a-z0-9\s]|^\s+|\s+$/gi, ''), 'i');
          this.setState({
            query,
            queryPattern,
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
            items: this.filterSearchResults(queryPattern),
          });
          this.locationSearch();
        }}
        disabled={!!this.state.disabled}
        selectedItem={this.state.selectedItem}
        onItemSelect={item => {
          this.setState({
            query: '',
            placeholder: item.description,
            placeId: item.placeId,
          });
        }}
      />
    );
  }
}

const AutocompleteContainer = graphql(
  gql`
    query admin_locationAutocomplete($search: LocationAutoCompleteSearch!) {
      locationAutocomplete(search: $search) {
        placeId
        description
        sessionToken
      }
    }
  `,
  {
    name: 'locationAutocomplete',
    options: () => ({
      variables: { search: { input: '' } },
    }),
  },
);
const LocationDetailsContainer = graphql(
  gql`
    query admin_getLocationFromGooglePlaceId($placeId: String!, $sessionToken: String) {
      getLocationFromGooglePlaceId(placeId: $placeId, sessionToken: $sessionToken) {
        country
        city
        state
        latitude
        longitude
        postcode
        google_location_types
        google_location {
          name
          geometry {
            latitude_ne
            longitude_ne
            latitude_sw
            longitude_sw
          }
        }
      }
    }
  `,
  {
    name: 'getLocationFromGooglePlaceId',
    options: () => ({
      variables: { placeId: '' },
    }),
  },
);

export default compose(AutocompleteContainer, LocationDetailsContainer)(LocationSuggest);
