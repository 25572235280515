import { Classes } from '@blueprintjs/core';
import _ from 'lodash';
import { Component } from 'react';

import './styles/inline-filters.scss';

class Filters extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      active: _.chain(props.filters || [])
        .map(filter => [filter.key, filter.defaultActive])
        .fromPairs()
        .value(),
    };

    this.renderFilter = this.renderFilter.bind(this);
  }

  componentWillReceiveProps(nextProps: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (nextProps.active && !_.isEqual(nextProps.active, this.props.active)) {
      if (Object.keys(nextProps.active).length > 0) {
        this.setState({ active: nextProps.active });
      } else {
        const activeFilters = {};
        nextProps.filters.forEach((filter: any) => {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          activeFilters[filter.key] = filter.defaultActive;
        });
        this.setState({ active: activeFilters });
      }
    }
  }

  renderFilter(filter: any) {
    const { key, label, className = '', count, ...props } = filter;

    const classes = className.split(' ');
    classes.push(Classes.TAG);
    classes.push('filter-control');
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
    classes.push(this.state.active[key] ? 'active' : 'inactive');

    return (
      <button
        key={key}
        // minimal
        onClick={() => {
          this.setState(
            state => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'multiSelect' does not exist on type 'Rea... Remove this comment to see the full error message
              if (this.props.multiSelect) {
                if (key === '*') {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                  if (state.active['*']) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                    state.active['*'] = false;
                  } else {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
                    this.props.filters.forEach((f: any) => {
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                      state.active[f.key] = true;
                    });
                  }
                  return state;
                }
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                if (state.active['*']) delete state.active['*'];
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                state.active[key] = !state.active[key];
                return state;
              }

              // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
              const wasActive = !!state.active[key];
              if (
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                _.chain(state.active)
                  .pickBy(isActive => isActive)
                  .values()
                  .value().length > 1
              ) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
                this.props.filters.forEach((f: any) => {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                  state.active[f.key] = f.key === key;
                });
                return;
              }

              // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
              this.props.filters.forEach((f: any) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                state.active[f.key] = false;
              });
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
              if (wasActive && _.find(this.props.filters, f => f.key === '*')) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                state.active['*'] = true;
              } else {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                state.active[key] = true;
              }
              return state;
            },
            () => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
              if (this.props.onChange) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
                this.props.onChange({
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                  active: this.state.active,
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
                  filters: this.props.filters.map((f: any) => ({
                    ...f,
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
                    active: this.state.active[f.key],
                  })),
                });
              }
            },
          );
        }}
        type="button"
        className={classes.join(' ')}
        {...props}
      >
        {label || key}
        {count === undefined ? '' : ` (${count})`}
      </button>
    );
  }

  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'filters' does not exist on type 'Readonl... Remove this comment to see the full error message
      filters,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type 'Readonly<... Remove this comment to see the full error message
      label = 'Filter:',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className = '',
      ...props
    } = this.props;

    const classes = className.split(' ');
    classes.push('inline-filters');

    return (
      <div className={classes.join(' ')} {...props}>
        <span className="label">{label}</span>
        <div className="tags">{filters.map(this.renderFilter)}</div>
      </div>
    );
  }
}

export default Filters;
