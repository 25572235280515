import { Button, ButtonGroup, Tag } from '@blueprintjs/core';
import { useMemo } from 'react';
import { FaThumbsUp, FaThumbsDown, FaUndo } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'urql';

import ResetRatingMutation from '@graphql/mutations/booking_submission/resetSubmissionRateParticipant';
import SubmissionRateParticipantMutation from '@graphql/mutations/booking_submission/submissionRateParticipant';
import bookingSubmissionsForBookingId from '@graphql/queries/booking/bookingSubmissionsForBookingId';
import { SkeletonWrapper } from 'components/common';
import { VirtualisedTable } from 'components/table/VirtualisedTable';
import { utils } from 'lib/utils';

import type { TCountryCode } from 'countries-list';
import type { Submission } from 'generated/graphql';
import type { ColumnWithLooseAccessor } from 'react-table';

interface Props {
  bookingId: string;
}
export const BookingViewParticipants = ({ bookingId }: Props) => {
  const [{ data, fetching }, refetchBooking] = useQuery<
    { findAllBookingSubmissions: Submission[] },
    { bookingId: string }
  >({
    query: bookingSubmissionsForBookingId,
    variables: { bookingId },
  });

  const [, updateRatingMutation] = useMutation(SubmissionRateParticipantMutation);
  const [, resetRatingMutation] = useMutation(ResetRatingMutation);

  const updateRating = async (submissionId?: string, rating?: 0 | 1) => {
    if (!submissionId) {
      return;
    }

    const { error } =
      rating === undefined
        ? await resetRatingMutation({ submissionId })
        : await updateRatingMutation({ input: { _submission_id: submissionId, overall: rating } });

    if (error) {
      console.error(error);
      toast.error('Failed to update rating');
      return;
    }
    refetchBooking({ requestPolicy: 'network-only' });
  };

  const columns = useMemo<readonly ColumnWithLooseAccessor<Submission>[]>(() => {
    return [
      {
        Header: 'Name',
        accessor: row => row.user?.meta?.identity?.firstname,
        Cell: ({ row }) => {
          const identity = row.original.user?.meta?.identity;
          return (
            <a href={`/user/participant/${row.original.user?._id}`} className="flex tw-justify-between tw-p-2">
              {identity?.firstname} {identity?.lastname}
            </a>
          );
        },
      },
      {
        Header: 'Email',
        accessor: row => row.user?.email,
      },
      {
        Header: 'Status',
        accessor: row => row.status,
        Cell: ({ row }) => {
          return (
            <Tag minimal intent={row?.original.status === 'completed' ? 'success' : 'primary'}>
              {row.original.status}
            </Tag>
          );
        },
      },
      {
        Header: 'Country',
        accessor: row => row.user?.location?.country as TCountryCode,
        Cell: ({ row }) => {
          return (
            <span>
              {row.original.user?.location?.country} {utils.countryCodeToFlag(row.original.user?.location?.country)}
            </span>
          );
        },
      },
      {
        Header: 'Rating',
        accessor: row => row.rating?.overall,
        Cell: ({ row }) => {
          const rating = row.original.rating?.overall;
          return (
            <div>
              <ButtonGroup>
                <Button
                  icon={<FaUndo />}
                  minimal
                  disabled={rating === undefined || rating === null}
                  onClick={() => updateRating(row.original._id ?? undefined, undefined)}
                />
                <Button
                  icon={<FaThumbsDown />}
                  minimal
                  disabled={rating === 0}
                  onClick={() => updateRating(row.original._id ?? undefined, 0)}
                />
                <Button
                  icon={<FaThumbsUp />}
                  minimal
                  disabled={rating === 1}
                  onClick={() => updateRating(row.original._id ?? undefined, 1)}
                />
              </ButtonGroup>
            </div>
          );
        },
      },
      {
        Header: 'Submitted',
        accessor: row => row.created,
        Cell: ({ value }) => {
          return <span>{utils.formatTimestampToDate(value)}</span>;
        },
      },
    ];
  }, [data]);

  // show completed at the top
  const submissions = useMemo(() => {
    return data?.findAllBookingSubmissions || [];
  }, [data]);

  const initialSortBy = useMemo(() => {
    return [{ id: 'Status', desc: true }];
  }, []);

  return (
    <div className="margin-4 tw-h-full">
      <SkeletonWrapper active={fetching} length="20">
        <VirtualisedTable
          isLoading={fetching}
          columns={columns}
          data={submissions}
          initialSortBy={initialSortBy}
          initialFilters={[]}
        />
      </SkeletonWrapper>
    </div>
  );
};
