import gql from 'graphql-tag';

export const teamsListFragment = gql`
  fragment TeamsListFields on Teams {
    _id
    name
    created
    updated
    settings {
      ignore_tracking
      billing {
        company_name
        subscription {
          end
          credit {
            remaining
          }
        }
      }
    }
    Organisation {
      _id
      name
    }
    operational_office
    ParentTeam {
      _id
      name
      settings {
        ignore_tracking
      }
    }
  }
`;
