/* eslint-disable max-lines */
import { Query } from '@apollo/client/react/components';
import { Button, Classes, Divider, H3, H5 } from '@blueprintjs/core';
import classnames from 'classnames';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AppConsumer, ErrorCallout, Spinner } from 'components/common';
import { ColumnFilter, SelectColumnFilter, VirtualisedTable } from 'components/table/VirtualisedTable';
import { useAdmin_GetCouponsQuery } from 'generated/graphql';
import { COUPON_DISCOUNT_TYPE } from 'lib/constants';

import type { FC } from 'react';
import type { Column } from 'react-table';

const CouponCommon = {
  couponStatus: (coupon: any) => {
    const now = new Date().valueOf(),
      validity = coupon.validity || {},
      start = validity.start,
      end = validity.end,
      redeemed = coupon.redeemed_by.length || 0,
      redeem_limit = coupon.redeem_limit;

    let intent = Classes.INTENT_SUCCESS;
    let value = 'Active';
    if (end && now > end) {
      intent = Classes.INTENT_DANGER;
      value = 'Expired';
    } else if (redeem_limit && redeemed >= redeem_limit) {
      intent = Classes.INTENT_DANGER;
      value = 'Redeemed';
    } else if (now < start) {
      intent = Classes.INTENT_DANGER;
      value = 'Inactive';
    }

    return { intent, value };
  },

  renderUsage: (uses: any, limit: any) => {
    if (limit && limit > 0 && limit < 999) {
      return `${uses} / ${limit} used`;
    }
    return `${uses} used`;
  },

  renderDiscountAmuount: (coupon: any) => {
    switch (coupon.discount_type) {
      case COUPON_DISCOUNT_TYPE.DOLLARS:
        return `$${coupon.discount_value.toLocaleString(2, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      case COUPON_DISCOUNT_TYPE.PERCENT:
        return `${coupon.discount_value}%`;
      default:
        return coupon.discount_amount;
    }
  },

  renderStatus: (coupon: any) => {
    const { intent, value } = CouponCommon.couponStatus(coupon);

    const classes = classnames({
      'bp3-tag': true,
      'bp3-minimal': true,
      'bp3-intent-success': intent === Classes.INTENT_SUCCESS,
      'bp3-intent-danger': intent === Classes.INTENT_DANGER,
    });

    return <span className={classes}>{value}</span>;
  },

  renderCreated: (created: any) => {
    return <span title={moment(created).format('LLLL')}>{moment(created).fromNow()}</span>;
  },

  renderTeamName: (team: any) => {
    let teamName = team.name;
    if (_.get(team, 'settings.billing.company_name', team.name) !== team.name) {
      teamName += ` (${team.settings.billing.company_name})`;
    }
    return teamName;
  },
};

const CouponSidebar = (props: any) => (
  <div id="coupon-list-sidebar" className="content-page extra-padding">
    <Query
      query={gql`
        query admin_getCoupon($code: String!) {
          coupon(code: $code) {
            _id
            created
            code
            redeem_limit
            allow_reuse
            discount_value
            discount_type
            redeemed_by
            validity {
              start
              end
              checkout_amount
            }
            RedeemedBy {
              _id
              name
              settings {
                billing {
                  company_name
                }
              }
            }
          }
        }
      `}
      variables={{
        code: props.code,
      }}
    >
      {({ loading, error, data }: any) => {
        if (loading) {
          return <Spinner />;
        }
        if (error) {
          return <ErrorCallout error={error} />;
        }
        const coupon = _.get(data, 'coupon');
        if (!coupon) {
          return <ErrorCallout error="Missing / invalid coupon code" />;
        }

        let dateRange = '';
        if (_.get(coupon, 'validity.start')) {
          dateRange += `From ${moment(coupon.validity.start).format('Do MMM YYYY')}`;
        }
        if (_.get(coupon, 'validity.end')) {
          dateRange += ` to ${moment(coupon.validity.end).format('Do MMM YYYY')}`;
        }

        let redeemBlock = null;
        switch (_.get(coupon, 'redeemed_by.length', 0)) {
          case 0:
            redeemBlock = (
              <p>
                <strong>Never redeemed</strong>
              </p>
            );
            break;
          case 1:
            {
              const team = _.find(coupon.RedeemedBy, redeemedBy => redeemedBy._id === coupon.redeemed_by[0]);
              redeemBlock = (
                <p>
                  <strong>Redeemed once</strong> by {team ? CouponCommon.renderTeamName(team) : 'an unknown team'}
                </p>
              );
            }
            break;
          default:
            redeemBlock = (
              <Fragment>
                <p className="margin-top-2">
                  <strong>Redeemed {coupon.redeemed_by.length} times</strong>, by:
                </p>
                <ul>
                  {coupon.redeemed_by.map((teamId: any) => {
                    const team = _.find(coupon.RedeemedBy, redeemedBy => redeemedBy._id === teamId);
                    if (!team) {
                      return <li key={teamId}>Unknown team</li>;
                    }
                    return <li key={teamId}>{CouponCommon.renderTeamName(team)}</li>;
                  })}
                </ul>
              </Fragment>
            );
        }

        return (
          <Fragment>
            <H5>Discount code details</H5>
            <H3 className="flex flex-align-center">
              <code className="margin-right-1">{coupon.code}</code>
              {CouponCommon.renderStatus(coupon)}
            </H3>
            <p className={[Classes.UI_TEXT, Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
              Created {moment(coupon.created).format('do MMM YYYY, h:mma')}
            </p>

            <Divider className="margin-bottom-2" />

            <p>
              <strong>Discount:</strong> {CouponCommon.renderDiscountAmuount(coupon)}
            </p>

            <p className="margin-top-2">
              <strong>Usage limit:</strong> {coupon.redeem_limit || <em>none</em>}
            </p>
            <p>
              <strong>Once per customer:</strong> {coupon.allow_reuse ? 'No' : 'Yes'}
            </p>
            <p>
              <strong>Minimum order size</strong>:
              {_.get(coupon, 'validity.checkout_amount') ? (
                ` $${coupon.validity.checkout_amount.toLocaleString()}`
              ) : (
                <em> none</em>
              )}
            </p>

            <p className="margin-top-2">
              <strong>Valid dates</strong>: {dateRange > '' ? dateRange : <em>none</em>}
            </p>

            <Divider className="margin-top-2 margin-bottom-2" />

            {redeemBlock}
          </Fragment>
        );
      }}
    </Query>
  </div>
);

type Props = {
  context: any;
};

const DiscountTable: FC<Props> = props => {
  const navigate = useNavigate();

  const [{ data: couponData, fetching }] = useAdmin_GetCouponsQuery();

  const handleCouponClick = (code: string) => {
    if (code) {
      props.context.openSidebar({
        children: <CouponSidebar code={code} />,
        onClose: () => {
          navigate('/tools/coupon');
          props.context.closeSidebar();
        },
      });
    } else {
      props.context.closeSidebar();
    }
  };

  const columns = useMemo<Column<any>[]>(() => {
    return [
      {
        id: 'Code',
        Header: 'Code',
        accessor: row => row?.code,
        Filter: ColumnFilter,
        Cell: ({ row }) => {
          return (
            <Link onClick={() => handleCouponClick(row.original?.code)} to={`/tools/coupon/${row.original?.code}`}>
              <code>{row.original?.code}</code>
            </Link>
          );
        },
      },
      {
        Header: 'Discount',
        accessor: row => row?.discount_value,
        Cell: ({ row }) => {
          return CouponCommon.renderDiscountAmuount(row.original);
        },
      },
      {
        Header: 'Status',
        accessor: row => row?.status,
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ row }) => {
          return CouponCommon.renderStatus(row.original);
        },
      },
      {
        Header: 'Usage',
        accessor: row => row?.redeemed_by,
        Cell: ({ row }) => {
          return CouponCommon.renderUsage(row.original?.redeemed_by?.length, row.original?.redeem_limit);
        },
      },
      {
        Header: 'Created',
        accessor: row => row?.created,
        Cell: ({ row }) => {
          return CouponCommon.renderCreated(row.original?.created);
        },
      },
    ];
  }, []);

  const data = useMemo(() => {
    return (
      couponData?.coupons?.map(coupon => {
        return {
          ...coupon,
          status: CouponCommon.couponStatus(coupon).value,
        };
      }) ?? []
    );
  }, [couponData]);

  const initialSortBy = useMemo(() => {
    return [{ id: 'Created', desc: true }];
  }, []);

  const TableHeader = () => {
    const navigate = useNavigate();
    return (
      <div className="tw-flex tw-items-center tw-justify-between">
        <H3 className="tw-m-0 tw-mr-2">Discount codes</H3>
        <Button
          intent="success"
          onClick={() => {
            navigate('/tools/coupon/create');
          }}
        >
          Create discount
        </Button>
      </div>
    );
  };

  return (
    <VirtualisedTable
      data={data}
      isLoading={fetching}
      initialSortBy={initialSortBy}
      columns={columns as any}
      tableHeader={<TableHeader />}
    />
  );
};

export default AppConsumer(DiscountTable);
