/* eslint-disable max-lines */
const BOOKING_STATUS = {
  DRAFT: 0,
  ACTIVE: 1,
  PENDING_REVIEW: 2,
  IN_REVIEW: 3,
  REJECTED: 4,
  COMPLETED: 5,
  PENDING_PAYMENT: 6,
  ARCHIVED: 7,
};

const BOOKING_TYPE = {
  FACE_TO_FACE: 1,
  REMOTE: 2,
  ONLINE: 3,
  LONGITUDINAL: 4,
  UNMODERATED: 5,
};

const BOOKING_SESSION_TYPE = {
  ONE_ON_ONE: 1,
  FOCUS_GROUP: 2,
  // MULTI_GROUP: 3,
};

const BOOKING_ONLINE_TYPE = {
  SURVEY: 2,
  AI_MODERATED: 3,
  UNMODERATED: 5,
};

const BOOKING_PARTICIPANT_CATEGORY = {
  GENERAL: 1,
  PROFESSIONALS: 2,
  HARD: 3,
  // MULTI_GROUP: 3,
};

const BOOKING_PARTICIPANT_STATUS = {
  CONFIRMED: 1, // Confirmed
  WAITLISTED: 2, // Waitlisted
  AVAILABLE: 3, // Available
  INVITED: 4, // Invited
  IN_PROGRESS: 5, // Task in progress
};

const BOOKING_PARTICIPANT_CANCEL = {
  NOT_CANCELLED: 0,
  BY_ADMIN: 1,
  BY_CLIENT: 2,
  BY_PARTICIPANT: 3,
  NOSHOW: 4,
  BY_SYSTEM: 5,
  RESCHEDULED: 6,
};

const PROJECT_TYPE = {
  ASKABLE_PLUS: 1,
};

const COUPON_DISCOUNT_TYPE = {
  DOLLARS: 1,
  PERCENT: 2,
  CREDITS: 3,
};

const USER_INCENTIVE_METHODS = {
  PAYPAL: 'paypal',
  GIFTPAY: 'giftpay',
};

const USER_EMPLOYMENT_TYPE = {
  EMPLOYEE: 1, // Employee
  SELF_EMPLOYED: 2, // Self-employed (contractor, sole trader, etc)
  BUSINESS_OWNER: 3, // Business owner"
};

const MESSAGE_TYPE = {
  // TODO
  IN_APP: 1, // messages between 2 users
  SYSTEM: 2, // reminders, confirmation requests
  BATCH: 3, // batch messages
  SMS_VERIFICATION: 4, // verification messages
  ADMIN: 5, // Askable communicating with users
};
const MESSAGE_DIRECTION = {
  OTHER: 0, // other or unknown - legacy only
  ASKABLE_TO_PARTICIPANT: 1,
  ASKABLE_TO_CLIENT: 2,
  CLIENT_TO_PARTICIPANT: 3,
  PARTICIPANT_TO_CLIENT: 4,
  ASKABLE_TO_OTHER: 5,
  PARTICIPANT_TO_ASKABLE: 6,
};
const MESSAGE_SMS_STATUS = {
  FAILED: 0,
  QUEUED: 1,
  SENT: 2, // sent to 3rd party provider
  DELIVERED: 3, // provider has reported successful delivery
};

const TRANSACTIONS_TYPE = {
  MANUAL: 0, // Askable admin manual transaction adjustment
  CREDIT_PURCHASE: 1, // Client credit purchase
  PARTICIPANT_PAYMENT: 2, // Participant payment
  CREDIT_REFUND: 3, // Credit sale refund
};

const TRANSACTIONS_STATUS = {
  COMPLETED: 1, //Completed
  PENDING: 2, //Pending
  CANCELLED: 3, //Cancelled
};

const TRANSACTIONS_PROVIDER = {
  PAYPAL: 1,
  CREDIT_CARD: 2,
  INVOICE: 3,
  GIFTPAY: 4,
};

const CREDIT_ACTIVITY_TYPE = {
  ADMIN_MANUAL: 0,
  CREDIT_USAGE: 1,
  CREDIT_PURCHASE: 2,
  REFUND_NOSHOW: 3,
  REFUND_UNFULIILLED: 4,
  REFUND_CANCELLED_REJECTED: 5,
  REFUND_BAD_PARTICIPANT: 6,
  REFUND_OTHER: 7,
  BOOKING_REQUIREMENT_ADJUSTMENT: 8,
  FREE_PROMOTIONAL: 9,
  EXPIRING_CREDITS: 10,
  TRANSFER: 11,
  NON_BOOKING_USAGE: 12,
};

const ACCOUNTING_TYPE = {
  DEBIT: 1, // team balance goes down
  CREDIT: 2, // team balance goes up
};

const USER_BUSINESS_SIZE = {
  MYSELF_ONLY: 1,
  EMPLOYEES_2_10: 2,
  EMPLOYEES_11_50: 3,
  EMPLOYEES_51_200: 4,
  EMPLOYEES_201_1000: 5,
  EMPLOYEES_1000_PLUS: 6,
};

const USER_BUSINESS_TURNOVER = {
  TURNOVER_UNDER_100K: 1,
  TURNOVER_100K_500K: 2,
  TURNOVER_500K_2M: 3,
  TURNOVER_2M_5M: 4,
  TURNOVER_5M_25M: 5,
  TURNOVER_25M_PLUS: 6,
};

const COLOURS = {
  BOOKING_IN_PERSON: '#009b19',
  BOOKING_REMOTE: '#f0a609',
  BOOKING_ONLINE: '#bb2aff',
  BOOKING_LONGITUDINAL: '#2F80ED',
  BOOKING_STATUS_ACTIVE: '#76F24A',
  BOOKING_STATUS_IN_REVIEW: '#F2994A',
};

const BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE = {
  ASKABLE_TERMS: 0,
  CUSTOM_AGREEMENT: 1,
};

const ESIGNATURE_TEMPLATE_TYPE = {
  PARTICIPANT_AGREEMENT: 1,
};

const ESIGNATURE_TEMPLATE_PROVIDER = {
  ANNATURE: 'annature',
};

const BOOLEAN_FIELDS: { user: { [x: string]: string[] } } = {
  user: {
    'meta.education': ['highschool', 'some_university', 'undergraduate', 'postgraduate', 'apprenticeship'],
    'meta.family.status': ['single', 'relationship', 'married', 'divorced', 'widowed'],
    'meta.work.status': [
      'fulltime',
      'parttime',
      'fulltime_student',
      'parttime_student',
      'homeduties',
      'retired',
      'unemployed',
    ],
    'meta.identity.languages.english.speak': ['native', 'fluent', 'conversational', 'beginner'],
  },
};

const LABELS = { user: {}, transactions: {} };
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['settings.billing.paypal'] = 'PayPal';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['settings.billing.giftpay'] = 'GiftPay';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.education.highschool'] = 'Highschool';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.education.some_university'] = 'University Degree';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.education.undergraduate'] = 'Undergraduate Degree';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.education.postgraduate'] = 'Postgraduate Degree';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.education.apprenticeship'] = 'Apprenticeship';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.family.status.single'] = 'Single';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.family.status.relationship'] = 'In a Relationship';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.family.status.married'] = 'Married / Defacto';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.family.status.divorced'] = 'Divorced';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.family.status.widowed'] = 'Widowed';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.status.fulltime'] = 'Full time work';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.status.parttime'] = 'Part time / casual work';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.status.fulltime_student'] = 'Full time student';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.status.parttime_student'] = 'Part time student';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.status.homeduties'] = 'Full time home duties';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.status.retired'] = 'Retired';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.status.unemployed'] = 'Unemployed';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.identity.languages.english.speak.native'] = 'Native';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.identity.languages.english.speak.fluent'] = 'Fluent';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.identity.languages.english.speak.conversational'] = 'Conversational';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.identity.languages.english.speak.beginner'] = 'Beginner';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.employment_type.1'] = 'Employee';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.employment_type.2'] = 'Self-employed';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.employment_type.3'] = 'Business owner';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.size.1'] = 'Myself only';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.size.2'] = '2 - 10 employees';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.size.3'] = '11 - 50 employees';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.size.4'] = '51 - 200 employees';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.size.5'] = '201 - 1,000 employees';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.size.6'] = 'More than 1,000 employees';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.turnover.1'] = 'Less than $100K';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.turnover.2'] = '$100K - $1M';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.turnover.3'] = '$1M - $5M';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.turnover.4'] = '$5M - $25M';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.turnover.5'] = '$25M - $100M';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.user['meta.work.business.turnover.6'] = 'More than $100';

// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.transactions['method.1'] = 'PayPal';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.transactions['method.2'] = 'Credit Card';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.transactions['method.3'] = 'Invoice';
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
LABELS.transactions['method.4'] = 'GiftPay';

export {
  LABELS,
  COLOURS as ASKABLE_COLOURS,
  BOOLEAN_FIELDS,
  BOOKING_STATUS,
  BOOKING_TYPE,
  BOOKING_SESSION_TYPE,
  BOOKING_ONLINE_TYPE,
  BOOKING_PARTICIPANT_CATEGORY,
  BOOKING_PARTICIPANT_STATUS,
  BOOKING_PARTICIPANT_CANCEL,
  PROJECT_TYPE,
  COUPON_DISCOUNT_TYPE,
  CREDIT_ACTIVITY_TYPE,
  ACCOUNTING_TYPE,
  USER_INCENTIVE_METHODS,
  USER_EMPLOYMENT_TYPE,
  USER_BUSINESS_SIZE,
  USER_BUSINESS_TURNOVER,
  MESSAGE_TYPE,
  MESSAGE_DIRECTION,
  MESSAGE_SMS_STATUS,
  TRANSACTIONS_TYPE,
  TRANSACTIONS_STATUS,
  TRANSACTIONS_PROVIDER,
  BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE,
  ESIGNATURE_TEMPLATE_TYPE,
  ESIGNATURE_TEMPLATE_PROVIDER,
};
