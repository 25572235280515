import gql from 'graphql-tag';

import { bookingsListCurrentFieldsFragment } from '../../fragments/booking/bookingsListCurrentFields';

export default gql`
  subscription admin_bookingsListCurrentSubscription {
    bookings {
      ...bookingsListCurrentFields
    }
  }
  ${bookingsListCurrentFieldsFragment}
`;
