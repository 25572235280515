import { H1 } from '@blueprintjs/core';
import invariant from 'tiny-invariant';

import { formattedDate } from 'utils/date-utils';

import { type AdminClientUserQuery } from '../data/ClientUser.query';

import type { ResultOf } from 'gql.tada';

type UserResult = ResultOf<typeof AdminClientUserQuery>['userByID'];

type Props = {
  user?: UserResult;
};

export const ClientInformation = ({ user }: Props) => {
  invariant(user, 'User is required');

  return (
    <div>
      <H1>{user.displayName}</H1>
      <ul className="tw-list tw-space-2 tw-flex-col">
        <li>ID: {user._id}</li>
        <li>Email: {user.email}</li>
        <li>Mobile: {user.contact?.phone?.mobile || 'None set'}</li>
        <li>State: {user.location?.state}</li>
        {/* @ts-expect-error we need to import the country code enum */}
        <li>Country: {user.location?.country}</li>
        <li>Created: {formattedDate({ date: user.created, format: 'MMM D, YYYY' })}</li>
        <li>Status: {user.status === 1 ? 'Active' : 'Inactive'}</li>
        <li>Blacklist: {user.blacklist ? 'Yes' : 'No'}</li>
      </ul>
    </div>
  );
};
