import gql from 'graphql-tag';

export default gql`
  mutation moveBookingToAnotherTeam($booking_id: ID!, $new_team_id: ID!, $move_credit_activity: Boolean) {
    moveBookingToAnotherTeam(
      booking_id: $booking_id
      new_team_id: $new_team_id
      move_credit_activity: $move_credit_activity
    ) {
      _id
    }
  }
`;
