import { ControlGroup, InputGroup } from '@blueprintjs/core';
import _ from 'lodash';
import { Component } from 'react';

import { utils } from 'lib/utils';

import PhoneCountrySelect from './phone-country-select.view';

import type { InputGroupProps } from '@blueprintjs/core';
import type { ParsedPhoneNumberOrError } from 'lib/utils';

type Props = {
  value: string;
  onChange: (value: ParsedPhoneNumberOrError) => void;
} & InputGroupProps;

interface State {
  phone: string;
  region?: string;
  countryCode: string | null;
}

class PhoneInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phone: '',
      countryCode: null,
    };

    this.setInitialState = this.setInitialState.bind(this);
    this.changeCallback = this.changeCallback.bind(this);
  }

  componentDidMount() {
    const phone = this.props.value;
    this.setInitialState(phone);
  }

  setInitialState(phone: string) {
    const number = utils.parsePhoneNumber(phone);
    if (number && !('error' in number)) {
      if (number.valid) {
        this.setState({
          // number,
          phone: _.get(number, 'format.national', phone),
          region: number.regionCode,
          countryCode: number.countryCode,
        });
      } else {
        this.setState({ phone });
      }
    }
  }

  changeCallback() {
    const number = utils.parsePhoneNumber(this.state.phone, this.state.region);
    this.props.onChange(number);
  }

  render() {
    const { disabled, fill, ...inputProps } = this.props;
    return (
      <ControlGroup fill={fill}>
        <PhoneCountrySelect
          text={this.state.region && this.state.countryCode ? `${this.state.region} ${this.state.countryCode}` : ''}
          onChange={(country: any) => {
            this.setState(
              {
                region: country.region,
                countryCode: country.country_code,
              },
              this.changeCallback,
            );
          }}
          disabled={disabled}
        />
        <InputGroup
          {...inputProps}
          value={this.state.phone}
          onChange={(event: any) => {
            this.setState(
              {
                phone: event.target.value,
              },
              this.changeCallback,
            );
          }}
          disabled={disabled}
          type="text"
        />
      </ControlGroup>
    );
  }
}

export default PhoneInput;
