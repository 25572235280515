import { MenuItem } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import _ from 'lodash';
import { Component } from 'react';
import './styles/suggest.scss';

class SuggestCommon extends Component {
  constructor(props: any) {
    super(props);

    this.state = {
      // activeItem: null,
      query: '',
    };
  }

  render() {
    const noResults = <MenuItem text="No results" disabled />;
    return (
      <Suggest
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'items' does not exist on type 'Readonly<... Remove this comment to see the full error message
        items={this.props.items}
        itemRenderer={(item, itemProps) => {
          return (
            <MenuItem
              {...item}
              key={item.value}
              active={itemProps.modifiers.active}
              disabled={itemProps.modifiers.disabled}
              onClick={itemProps.handleClick}
            />
          );
        }}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'inputProps' does not exist on type 'Read... Remove this comment to see the full error message
        inputProps={this.props.inputProps}
        popoverProps={{
          minimal: true,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'popoverProps' does not exist on type 'Re... Remove this comment to see the full error message
          ...this.props.popoverProps,
        }}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
        inputValueRenderer={() => this.state.query}
        noResults={
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
          this.state.loading ? (
            <MenuItem
              text="No results"
              disabled
              // labelElement={<Spinner size={18} />}
            />
          ) : (
            noResults
          )
        }
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'query' does not exist on type 'Readonly<... Remove this comment to see the full error message
        query={this.state.query}
        itemListPredicate={(query, items) => {
          const match = new RegExp(query.replace(/[^a-zA-Z0-9.\s|]/g, '').replace(/\s+/, ' '), 'i');
          return _.filter(items, item => {
            if (item.search) {
              return !!item.search.match(match);
            }
            if (item.text) {
              return !!item.search.match(match);
            }
            if (item.value) {
              return !!item.search.match(match);
            }
            return false;
          });
        }}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedItem' does not exist on type 'Re... Remove this comment to see the full error message
        selectedItem={this.state.selectedItem}
        onItemSelect={item => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'onItemSelect' does not exist on type 'Re... Remove this comment to see the full error message
          if (this.props.onItemSelect) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'onItemSelect' does not exist on type 'Re... Remove this comment to see the full error message
            this.props.onItemSelect(item);
          }
          let setQuery = item.description || item.text || item.label;
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'resetOnSelect' does not exist on type 'R... Remove this comment to see the full error message
          if (this.props.resetOnSelect) {
            setQuery = '';
          }
          this.setState({ selectedItem: item, query: setQuery });
        }}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fill' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        fill={this.props.fill}
      />
    );
  }
}

export default SuggestCommon;
