import { Classes, TextArea } from '@blueprintjs/core';
import { Component } from 'react';
import YAML from 'yaml';

import './styles/yaml-input.scss';

class YAMLInput extends Component {
  constructor() {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1-2 arguments, but got 0.
    super();
    this.state = { yaml: '', json: null };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    try {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'json' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      if (this.props.json) {
        this.setState({
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'json' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          yaml: YAML.stringify(this.props.json),
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'json' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          json: this.props.json,
          parseError: null,
        });
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'yaml' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      } else if (this.props.yaml) {
        this.setState({
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'yaml' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          yaml: this.props.yaml,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'yaml' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          json: YAML.parse(this.props.yaml),
          parseError: null,
        });
      }
    } catch (e: any) {
      console.warn(e);
      this.setState({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'yaml' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        yaml: this.props.yaml || '',
        json: null,
        parseError: e.message,
      });
    }
  }

  handleChange(event: any) {
    const yaml = event.target.value;
    try {
      const json = YAML.parse(yaml);
      this.setState({
        yaml,
        json,
        changed: true,
        parseError: null,
      });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      if (this.props.onChange) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.onChange({ yaml, json });
      }
    } catch (e: any) {
      console.warn(e);
      this.setState({
        yaml,
        json: null,
        parseError: e.message,
        changed: true,
      });
    }
  }

  handleBlur() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'json' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (this.state.json) {
      this.setState({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'json' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        yaml: YAML.stringify(this.state.json),
      });
    }
  }

  handleKeyDown(event: any) {
    if (event.keyCode === 9 || event.which === 9) {
      event.preventDefault();
      // const tab = '  ';
      // const s = event.target.selectionStart;
      // event.target.value = `${event.target.value.substring(0, event.target.selectionStart)}${tab}${event.target.value.substring(event.target.selectionEnd)}`;
      // event.target.selectionEnd = s + tab.length;

      // this.onChange(event);
    }
  }

  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'textAreaProps' does not exist on type 'R... Remove this comment to see the full error message
      textAreaProps,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'textAreaStyle' does not exist on type 'R... Remove this comment to see the full error message
      textAreaStyle,
      ...wrapperProps
    } = this.props;
    return (
      <div className="yaml-input" {...wrapperProps}>
        <TextArea
          fill
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'changed' does not exist on type 'Readonl... Remove this comment to see the full error message
          growVertically={!!this.state.changed}
          className={Classes.MONOSPACE_TEXT}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'yaml' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          rows={this.state.yaml.split('\n').length}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'yaml' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          value={this.state.yaml}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'parseError' does not exist on type 'Read... Remove this comment to see the full error message
          intent={this.state.parseError ? 'danger' : null}
          style={textAreaStyle}
          {...textAreaProps}
        />
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'parseError' does not exist on type 'Read... Remove this comment to see the full error message */}
        {this.state.parseError && (
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'parseError' does not exist on type 'Read... Remove this comment to see the full error message
          <div className={`error-message padding-05 text-danger ${Classes.TEXT_SMALL}`}>{this.state.parseError}</div>
        )}
      </div>
    );
  }
}

export default YAMLInput;
