import { Select } from '@blueprintjs/select';
import classnames from 'classnames';
import { useMemo, useState } from 'react';

import { Avatar } from 'components/common/Avatar';
import { useAdminUsersQuery } from 'generated/graphql';

import type { ItemRendererProps } from '@blueprintjs/select';
import type { AvatarSize } from 'components/common/Avatar';
import type {
  Booking,
  UpdateBookingAdminUserMutationVariables,
  AdminUsersQuery,
  OperationalOffices,
} from 'generated/graphql';
import type { FC } from 'react';

type BookingAdminAssigneeProps = {
  bookingId: string;
  adminUser: Booking['admin_user'];
  handleBookingUserUpdate: (variables: UpdateBookingAdminUserMutationVariables) => Promise<void>;
  avatarSize?: AvatarSize;
  inlineName?: boolean;
  className?: string;
  operationalOffice?: OperationalOffices;
};

type ItemSelect = NonNullable<NonNullable<AdminUsersQuery>['adminUsers']>[number];
const AdminUserSelect = Select.ofType<ItemSelect>();

const UNASSIGN_USER = {
  _id: 'unassign_users',
  meta: {
    identity: {
      firstname: 'Unassign',
    },
  },
  picture: null,
};

const EMPTY_LIST = {
  _id: 'empty_list',
  meta: {
    identity: {
      firstname: 'No admins found',
    },
  },
  picture: null,
};

export const BookingAdminAssignee: FC<BookingAdminAssigneeProps> = ({
  adminUser,
  bookingId,
  handleBookingUserUpdate,
  className,
  inlineName = false,
  avatarSize = 'sm',
  operationalOffice,
}) => {
  const [adminUsers] = useAdminUsersQuery({
    variables: {
      customer_success: true,
    },
  });
  const adminUsersData = adminUsers.data?.adminUsers;
  const [currentUser, setCurrentUser] = useState(adminUser);
  const filteredUsers = useMemo(() => {
    const active = adminUsersData?.filter(
      c =>
        c?._id !== currentUser?._id && (operationalOffice ? c?.admin?.operational_office === operationalOffice : true),
    );

    if (active?.length === 0) {
      active?.push(EMPTY_LIST);
    }

    if (!currentUser) {
      return active;
    }

    return active?.concat(UNASSIGN_USER);
  }, [currentUser, adminUsersData, operationalOffice]);

  const handleItemClick = async (a: ItemSelect) => {
    if (a?._id === currentUser?._id) {
      return;
    }

    try {
      // Optimistic
      setCurrentUser(a?._id === UNASSIGN_USER._id ? null : a);

      await handleBookingUserUpdate({
        booking_id: bookingId,
        booking: {
          _admin_id: a?._id === UNASSIGN_USER._id ? null : a?._id,
        },
      });
    } catch {
      setCurrentUser(adminUser);
    }
  };

  const renderItem = (item: ItemSelect, { handleClick }: ItemRendererProps) => {
    if (!item?._id || item?._id === EMPTY_LIST._id) {
      return (
        <div className="tw-cursor-pointer tw-flex tw-p-2 tw-rounded-sm tw-items-center opacity-50" key={item?._id}>
          <span>{item?.meta?.identity?.firstname}</span>
        </div>
      );
    }
    return (
      <div
        onClick={handleClick}
        className="tw-cursor-pointer tw-flex tw-p-2 tw-rounded-sm tw-items-center hover:tw-bg-slate-600"
        key={item?._id}
      >
        <Avatar
          image={item?.picture}
          alt={currentUser?._id}
          name={item?._id === UNASSIGN_USER._id ? null : item?.meta?.identity?.firstname}
          className="tw-mr-2"
          tooltipDisabled
        />
        <span>{item?.meta?.identity?.firstname}</span>
      </div>
    );
  };

  return (
    <AdminUserSelect
      filterable={false}
      onItemSelect={handleItemClick}
      items={filteredUsers ?? []}
      itemRenderer={renderItem}
    >
      <div className={classnames(className, 'tw-flex tw-flex-row tw-items-center tw-w-auto tw-group')}>
        <Avatar
          name={currentUser?.meta?.identity?.firstname}
          size={avatarSize}
          className="tw-cursor-pointer tw-border-transparent tw-border tw-rounded-full group-hover:tw-border-gray-400"
          image={currentUser?.picture}
          alt={currentUser?._id}
        />
        {inlineName && (
          <span className="tw-ml-2 tw-text-sm">{currentUser?.meta?.identity?.firstname ?? 'Unassigned'}</span>
        )}
      </div>
    </AdminUserSelect>
  );
};
