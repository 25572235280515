import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  CountryCode: { input: any; output: any };
  Currency: { input: any; output: any };
  JSON: { input: any; output: any };
  ObjectID: { input: any; output: any };
};

export type AusNationalityInput = {
  permanent_resident?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddResearcherInput = {
  _project_id: Scalars['ObjectID']['input'];
  _user_id: Scalars['ObjectID']['input'];
};

export type AdminNotesInput = {
  note?: InputMaybe<Scalars['String']['input']>;
};

export type AdminNotesType = {
  __typename?: 'AdminNotesType';
  _id: Scalars['ID']['output'];
  _user_id?: Maybe<Scalars['ID']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type AdminSound = {
  __typename?: 'AdminSound';
  description?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  meta?: Maybe<Array<Maybe<AdminSoundMeta>>>;
  url: Scalars['String']['output'];
};

export type AdminSoundInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  meta?: InputMaybe<Array<InputMaybe<AdminSoundMetaInput>>>;
  url: Scalars['String']['input'];
};

export type AdminSoundMeta = {
  __typename?: 'AdminSoundMeta';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AdminSoundMetaInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AdminWig = {
  __typename?: 'AdminWig';
  _id?: Maybe<Scalars['ID']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  goals?: Maybe<Array<Maybe<AdminWigGoal>>>;
  operational_office?: Maybe<OperationalOffices>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_name?: Maybe<Scalars['String']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  period_type?: Maybe<AdminWigPeriodTypes>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type AdminWigGoal = {
  __typename?: 'AdminWigGoal';
  ghost?: Maybe<Scalars['Float']['output']>;
  /**  The goal type key (eg. sales, usage)  */
  key?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<AdminWigGoalMeta>;
  progress?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type AdminWigGoalInput = {
  /**  Determine between goals of the same key (type)  */
  id?: InputMaybe<Scalars['String']['input']>;
  /**  The goal type key (eg. sales, usage)  */
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminWigGoalMeta = {
  __typename?: 'AdminWigGoalMeta';
  bar_colour?: Maybe<Scalars['String']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
};

export type AdminWigInput = {
  goals?: InputMaybe<Array<InputMaybe<AdminWigGoalInput>>>;
  operational_office?: InputMaybe<OperationalOffices>;
  period_end?: InputMaybe<Scalars['Float']['input']>;
  period_name?: InputMaybe<Scalars['String']['input']>;
  period_start?: InputMaybe<Scalars['Float']['input']>;
  period_type?: InputMaybe<AdminWigPeriodTypes>;
};

export enum AdminWigPeriodTypes {
  Custom = 'custom',
  Fy = 'fy',
  Mission = 'mission',
  Month = 'month',
}

export type Agreement = {
  __typename?: 'Agreement';
  _id: Scalars['ID']['output'];
  content?: Maybe<Scalars['String']['output']>;
  issue_date?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_type?: Maybe<AgreementUserTypeType>;
  version?: Maybe<Scalars['Int']['output']>;
};

export type AgreementUserTypeInput = {
  client?: InputMaybe<Scalars['Boolean']['input']>;
  participant?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgreementUserTypeType = {
  __typename?: 'AgreementUserTypeType';
  client?: Maybe<Scalars['Boolean']['output']>;
  participant?: Maybe<Scalars['Boolean']['output']>;
};

export type AiModeratedBookingConfig = {
  __typename?: 'AiModeratedBookingConfig';
  information?: Maybe<AiModeratedInformation>;
  prompt?: Maybe<AiModeratedPrompt>;
};

export type AiModeratedBookingInput = {
  information?: InputMaybe<AiModeratedInformationInput>;
  prompt?: InputMaybe<AiModeratedPromptInput>;
};

export enum AiModeratedConversationStatus {
  Finished = 'finished',
  InProgress = 'in_progress',
  NotStarted = 'not_started',
}

export type AiModeratedInformation = {
  __typename?: 'AiModeratedInformation';
  end_message?: Maybe<Scalars['String']['output']>;
  welcome_body?: Maybe<Scalars['String']['output']>;
  welcome_title?: Maybe<Scalars['String']['output']>;
};

export type AiModeratedInformationInput = {
  end_message?: InputMaybe<Scalars['String']['input']>;
  welcome_body?: InputMaybe<Scalars['String']['input']>;
  welcome_title?: InputMaybe<Scalars['String']['input']>;
};

export type AiModeratedMessageResponse = {
  __typename?: 'AiModeratedMessageResponse';
  /** estimated duration in seconds */
  audio_duration?: Maybe<Scalars['Float']['output']>;
  /** size in bytes */
  audio_filesize?: Maybe<Scalars['Float']['output']>;
  conversation_status?: Maybe<AiModeratedConversationStatus>;
  input_message?: Maybe<Scalars['String']['output']>;
  reply_message?: Maybe<Scalars['String']['output']>;
};

export type AiModeratedPrompt = {
  __typename?: 'AiModeratedPrompt';
  context?: Maybe<Scalars['String']['output']>;
  overview?: Maybe<Scalars['String']['output']>;
};

export type AiModeratedPromptInput = {
  context?: InputMaybe<Scalars['String']['input']>;
  overview?: InputMaybe<Scalars['String']['input']>;
};

export type Analyses = {
  __typename?: 'Analyses';
  _booking_id: Scalars['ObjectID']['output'];
  _facilitator_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _team_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  facilitator: AnalysesFacilitator;
  method?: Maybe<AnalysesMethod>;
  participant: AnalysesParticipant;
  quotes: Array<AnalysesQuote>;
  themes: Array<AnalysesThemeType>;
  updated?: Maybe<Scalars['Float']['output']>;
  video_code: Scalars['String']['output'];
  video_name: Scalars['String']['output'];
  video_started: Scalars['Float']['output'];
};

export type AnalysesConnection = {
  __typename?: 'AnalysesConnection';
  nodes: Array<Analyses>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AnalysesCount = {
  __typename?: 'AnalysesCount';
  count: Scalars['Int']['output'];
  method: AnalysesMethod;
};

export type AnalysesCountFilter = {
  _facilitator_id?: InputMaybe<FilterId>;
  _team_id?: InputMaybe<FilterId>;
  created?: InputMaybe<FilterFloat>;
};

export type AnalysesFacilitator = {
  __typename?: 'AnalysesFacilitator';
  _id: Scalars['ObjectID']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  picture_link?: Maybe<Scalars['String']['output']>;
};

export type AnalysesFacilitatorCount = {
  __typename?: 'AnalysesFacilitatorCount';
  _facilitator_id: Scalars['ObjectID']['output'];
  counts: Array<AnalysesCount>;
  facilitator: AnalysesFacilitator;
};

export type AnalysesFilter = {
  _booking_id?: InputMaybe<FilterId>;
  _id?: InputMaybe<FilterId>;
  _team_id?: InputMaybe<FilterId>;
  created?: InputMaybe<FilterFloat>;
  video_code?: InputMaybe<FilterString>;
};

export type AnalysesInsights = {
  __typename?: 'AnalysesInsights';
  _analyses_ids: Array<Scalars['ObjectID']['output']>;
  _id: Scalars['ObjectID']['output'];
  _team_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  quote_count: Scalars['Int']['output'];
  summary: Scalars['String']['output'];
  themes: Array<AnalysesInsightsTheme>;
  title: Scalars['String']['output'];
  updated?: Maybe<Scalars['Float']['output']>;
  user_count: Scalars['Int']['output'];
};

export type AnalysesInsightsConnection = {
  __typename?: 'AnalysesInsightsConnection';
  nodes: Array<AnalysesInsights>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AnalysesInsightsFilter = {
  _booking_id?: InputMaybe<FilterId>;
  _team_id?: InputMaybe<FilterId>;
  updated?: InputMaybe<FilterFloat>;
};

export type AnalysesInsightsTheme = {
  __typename?: 'AnalysesInsightsTheme';
  accent: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum AnalysesMethod {
  AiModerated = 'ai_moderated',
  Moderated = 'moderated',
  Unmoderated = 'unmoderated',
}

export type AnalysesParticipant = {
  __typename?: 'AnalysesParticipant';
  _id: Scalars['ObjectID']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
};

export type AnalysesQuote = {
  __typename?: 'AnalysesQuote';
  end_time: Scalars['Int']['output'];
  start_time: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type AnalysesTheme = {
  __typename?: 'AnalysesTheme';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _team_id: Scalars['ObjectID']['output'];
  accent: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AnalysesThemeConnection = {
  __typename?: 'AnalysesThemeConnection';
  nodes: Array<AnalysesTheme>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AnalysesThemeFilter = {
  _booking_id?: InputMaybe<FilterId>;
  _team_id?: InputMaybe<FilterId>;
  created?: InputMaybe<FilterFloat>;
};

export type AnalysesThemeType = {
  __typename?: 'AnalysesThemeType';
  accent: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Applicant = {
  __typename?: 'Applicant';
  _id: Scalars['ID']['output'];
  age?: Maybe<Scalars['Float']['output']>;
  blacklisted?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  employment_type?: Maybe<ApplicantEmploymentType>;
  english_level?: Maybe<Array<Maybe<ApplicantEnglishLevel>>>;
  family_status?: Maybe<Array<Maybe<ApplicantFamilyStatus>>>;
  firstname?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<ApplicantIndustry>;
  job_title?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  locale_proficiency?: Maybe<LocaleProficiency>;
  recording_url?: Maybe<Scalars['String']['output']>;
  setup_check?: Maybe<Scalars['Float']['output']>;
  sub_industry?: Maybe<ApplicantIndustry>;
  timezone?: Maybe<Scalars['String']['output']>;
  work_type?: Maybe<Array<Maybe<ApplicantWorkType>>>;
};

export enum ApplicantEmploymentType {
  BusinessOwner = 'business_owner',
  Employee = 'employee',
  SelfEmployed = 'self_employed',
}

export enum ApplicantEnglishLevel {
  Beginner = 'beginner',
  Conversational = 'conversational',
  Fluent = 'fluent',
  Native = 'native',
  None = 'none',
}

export enum ApplicantFamilyStatus {
  Divorced = 'divorced',
  Married = 'married',
  Relationship = 'relationship',
  Single = 'single',
  Widowed = 'widowed',
}

export type ApplicantIndustry = {
  __typename?: 'ApplicantIndustry';
  _id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum ApplicantWorkType {
  Fulltime = 'fulltime',
  FulltimeStudent = 'fulltime_student',
  Homeduties = 'homeduties',
  Parttime = 'parttime',
  ParttimeStudent = 'parttime_student',
  Retired = 'retired',
  Unemployed = 'unemployed',
}

export enum ApplyDeclineProjectType {
  Apply = 'apply',
  Decline = 'decline',
}

export type ApproveBookingSettingsInput = {
  translation: Scalars['Boolean']['input'];
};

export type ApproveDenyCancelRoomRequestInput = {
  code: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};

export type AskableApproveRequestType = {
  __typename?: 'AskableApproveRequestType';
  connectToken?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<AskableVideoUsersType>;
};

export type AskableLiveOnlineUser = {
  __typename?: 'AskableLiveOnlineUser';
  User?: Maybe<User>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  observer_identifier?: Maybe<Scalars['String']['output']>;
  online?: Maybe<Scalars['Boolean']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type AskableVideo = {
  __typename?: 'AskableVideo';
  /** Virtual field that fetches all the messages based on the context of the askable video */
  Booking?: Maybe<Booking>;
  _id: Scalars['ObjectID']['output'];
  _team_id?: Maybe<Scalars['ObjectID']['output']>;
  /** @deprecated Legacy field for twilio integration no longer in use */
  activeRoom?: Maybe<TwilioRoom>;
  code: Scalars['String']['output'];
  context?: Maybe<AskableVideoContextType>;
  created?: Maybe<Scalars['Float']['output']>;
  end?: Maybe<Scalars['Float']['output']>;
  method?: Maybe<AskableVideoMethods>;
  name?: Maybe<Scalars['String']['output']>;
  rooms?: Maybe<Array<Maybe<AskableVideoRoomsType>>>;
  start?: Maybe<Scalars['Float']['output']>;
  total_duration?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
  users?: Maybe<Array<Maybe<AskableVideoUsersType>>>;
  viewerAccessPolicy?: Maybe<Array<AskableVideoAccessPolicy>>;
};

export enum AskableVideoAccessPolicy {
  /** Viewer can join automatically */
  Join = 'join',
  /** Viewer has access to playback */
  PlaybackView = 'playback_view',
  /** Viewer has to request to join */
  Request = 'request',
  /** Viewer can start the call */
  Start = 'start',
}

export type AskableVideoComposition = {
  __typename?: 'AskableVideoComposition';
  duration?: Maybe<Scalars['Int']['output']>;
  filesize?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<AskableVideoCompositionProgress>;
  resolution?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  sid?: Maybe<Scalars['String']['output']>;
  status?: Maybe<VideoCompositionStatus>;
};

export type AskableVideoCompositionProgress = {
  __typename?: 'AskableVideoCompositionProgress';
  percentage_done?: Maybe<Scalars['Int']['output']>;
  seconds_remaining?: Maybe<Scalars['Int']['output']>;
};

export type AskableVideoContextType = {
  __typename?: 'AskableVideoContextType';
  _booking_id?: Maybe<Scalars['ObjectID']['output']>;
  _project_id?: Maybe<Scalars['ObjectID']['output']>;
  _session_id?: Maybe<Scalars['ObjectID']['output']>;
  _user_id?: Maybe<Scalars['ObjectID']['output']>;
};

export type AskableVideoInput = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _project_id?: InputMaybe<Scalars['ID']['input']>;
  _session_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  end?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Float']['input']>;
};

export enum AskableVideoMethods {
  AiModerated = 'ai_moderated',
  Moderated = 'moderated',
  Unmoderated = 'unmoderated',
}

export type AskableVideoRoomAiModeratedContextType = {
  __typename?: 'AskableVideoRoomAiModeratedContextType';
  context?: Maybe<Scalars['String']['output']>;
  overview?: Maybe<Scalars['String']['output']>;
};

export type AskableVideoRoomNote = {
  __typename?: 'AskableVideoRoomNote';
  _id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  time: Scalars['Float']['output'];
  updated: Scalars['Float']['output'];
};

export type AskableVideoRoomNoteInput = {
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  time: Scalars['Float']['input'];
};

export type AskableVideoRoomsType = {
  __typename?: 'AskableVideoRoomsType';
  _id?: Maybe<Scalars['ObjectID']['output']>;
  ai_moderated_prompt?: Maybe<AskableVideoRoomAiModeratedContextType>;
  composition?: Maybe<AskableVideoComposition>;
  end_time?: Maybe<Scalars['Float']['output']>;
  is_recorded?: Maybe<Scalars['Boolean']['output']>;
  locales?: Maybe<Array<Locale>>;
  notes?: Maybe<Array<Maybe<AskableVideoRoomNote>>>;
  sid?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['Float']['output']>;
  status: VideoRoomStatus;
  transcript?: Maybe<AskableVideoTranscriptType>;
};

export type AskableVideoTranscriptSpeakerInput = {
  _id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  type?: InputMaybe<AskableVideoUserType>;
};

export type AskableVideoTranscriptSpeakerType = {
  __typename?: 'AskableVideoTranscriptSpeakerType';
  _id: Scalars['ObjectID']['output'];
  label: Scalars['String']['output'];
  type?: Maybe<AskableVideoUserType>;
};

export type AskableVideoTranscriptType = {
  __typename?: 'AskableVideoTranscriptType';
  _id?: Maybe<Scalars['ObjectID']['output']>;
  provider?: Maybe<AskableVideoTranscriptionProviderType>;
  status: VideoTranscriptStatus;
  utterances?: Maybe<Array<Maybe<AskableVideoTranscriptUtteranceType>>>;
};

export type AskableVideoTranscriptUtteranceSpeakerType = {
  __typename?: 'AskableVideoTranscriptUtteranceSpeakerType';
  _id: Scalars['ID']['output'];
  speaker: AskableVideoTranscriptSpeakerType;
};

export type AskableVideoTranscriptUtteranceType = {
  __typename?: 'AskableVideoTranscriptUtteranceType';
  Text: Scalars['String']['output'];
  _id: Scalars['ObjectID']['output'];
  /** milliseconds */
  end: Scalars['Int']['output'];
  speaker: AskableVideoTranscriptSpeakerType;
  /** milliseconds */
  start: Scalars['Int']['output'];
  words: Array<AskableVideoTranscriptWordType>;
};

export type AskableVideoTranscriptWordType = {
  __typename?: 'AskableVideoTranscriptWordType';
  _id: Scalars['ObjectID']['output'];
  /** milliseconds */
  end: Scalars['Int']['output'];
  /** milliseconds */
  start: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type AskableVideoTranscriptionProviderType = {
  __typename?: 'AskableVideoTranscriptionProviderType';
  name: VideoTranscriptProviderName;
  reference?: Maybe<Scalars['String']['output']>;
};

export enum AskableVideoTypes {
  Discovery = 'discovery',
  Validation = 'validation',
}

export enum AskableVideoUserStatus {
  Approved = 'approved',
  Cancelled = 'cancelled',
  Denied = 'denied',
  Requested = 'requested',
}

export enum AskableVideoUserType {
  Facilitator = 'facilitator',
  Observer = 'observer',
  Participant = 'participant',
}

export type AskableVideoUsersType = {
  __typename?: 'AskableVideoUsersType';
  User?: Maybe<User>;
  _id?: Maybe<Scalars['ObjectID']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AskableVideoUserStatus>;
  type?: Maybe<AskableVideoUserType>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type BatchCriteriaInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<BatchCriteriaName>;
};

export enum BatchCriteriaName {
  Age = 'age',
  AskableStaffOnly = 'askable_staff_only',
  AssignedProjects = 'assigned_projects',
  BusinessSize = 'business_size',
  BusinessTurnover = 'business_turnover',
  EmploymentType = 'employment_type',
  ExcludeParticipantsFromBookings = 'exclude_participants_from_bookings',
  ExcludePreviousRecipients = 'exclude_previous_recipients',
  ExcludeRecentRecipients = 'exclude_recent_recipients',
  ExcludeTeamParticipants = 'exclude_team_participants',
  Exclusions = 'exclusions',
  Gender = 'gender',
  Industry = 'industry',
  LocationBoundingBox = 'location_boundingBox',
  LocationCountry = 'location_country',
  Locations = 'locations',
  LocationsFilter = 'locations_filter',
  OnlyVerfiedNumbers = 'only_verfied_numbers',
  ParticipantActiveThreshold = 'participant_active_threshold',
  PhoneNumberCountryCodes = 'phone_number_country_codes',
  ResearchMethods = 'research_methods',
  ResearcherFilter = 'researcher_filter',
  TeamPreviousParticipants = 'team_previous_participants',
  UserType = 'user_type',
  UserValues = 'user_values',
}

export type BatchInput = {
  /**  Saved on the message to prevent users getting multiple messages from the same job  */
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _project_id?: InputMaybe<Scalars['ID']['input']>;
  batch_size?: InputMaybe<Scalars['Int']['input']>;
  /**  Saved on the message to prevent users getting multiple messages from the same job  */
  batch_tag: Scalars['String']['input'];
  message_template?: InputMaybe<Scalars['String']['input']>;
  message_type: Scalars['String']['input'];
  notification_type: Scalars['String']['input'];
  schedule_send?: InputMaybe<Scalars['Float']['input']>;
  /**  The SMS templateID used in the batch out. null implies it is a custom one  */
  sms_template_id?: InputMaybe<Scalars['ID']['input']>;
  sort_preference?: InputMaybe<Scalars['String']['input']>;
  user_criteria?: InputMaybe<Array<InputMaybe<BatchCriteriaInput>>>;
};

export type BatchResult = {
  __typename?: 'BatchResult';
  Booking?: Maybe<Booking>;
  Messages?: Maybe<Array<Maybe<Messages>>>;
  Users?: Maybe<Array<Maybe<User>>>;
  _booking_id?: Maybe<Scalars['ID']['output']>;
  /**  Batch ID  */
  _id?: Maybe<Scalars['ID']['output']>;
  _user_ids?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  clicks?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  registrations?: Maybe<Scalars['Int']['output']>;
  schedule_send?: Maybe<Scalars['Float']['output']>;
  /**  Message count  */
  size?: Maybe<Scalars['Int']['output']>;
  sms_delivered?: Maybe<Scalars['Int']['output']>;
  sms_sent?: Maybe<Scalars['Int']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

export type BatchResultInput = {
  _batch_id?: InputMaybe<Scalars['ID']['input']>;
  _booking_id: Scalars['ID']['input'];
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type BetaInput = {
  enroll?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BirthdayInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  timestamp?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type Booking = {
  __typename?: 'Booking';
  AutomationScore?: Maybe<BookingAutomationScore>;
  CountryData?: Maybe<CountryData>;
  CurrencyData?: Maybe<Array<CurrencyData>>;
  ParticipantRatings?: Maybe<Array<Maybe<BookingSubmissionRating>>>;
  /** Virtual field to get participant sessions on the booking_submission document */
  ParticipantSessions?: Maybe<Array<Maybe<SessionsType>>>;
  ParticipantSessionsCount?: Maybe<ParticipantSessionsCount>;
  StartEndSessions?: Maybe<BookingStartEndSessions>;
  /** latest booking_participant by status_updated (try status: [1,2,4,5], then status: 3) */
  UserStatusCount?: Maybe<UserStatusCount>;
  _admin_id?: Maybe<Scalars['ID']['output']>;
  _id: Scalars['ID']['output'];
  _owner_id?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  _project_id?: Maybe<Scalars['ID']['output']>;
  _team_id?: Maybe<Scalars['ID']['output']>;
  admin?: Maybe<BookingAdminType>;
  admin_user?: Maybe<User>;
  /** Used withing the field 'exclude_participants_from_time' to determine whether a participant is eligible to apply */
  approved_date?: Maybe<Scalars['Float']['output']>;
  /** @deprecated You should use the new ParticipantSessions field */
  booking_participant?: Maybe<Array<Maybe<BookingParticipant>>>;
  /** From the team (if booking is BYO) */
  byo_recruitment?: Maybe<TeamSettingsRecruitmentPage>;
  cloned?: Maybe<Scalars['Boolean']['output']>;
  config?: Maybe<BookingConfig>;
  confirmed_date?: Maybe<Scalars['Float']['output']>;
  contact?: Maybe<BookingContact>;
  continuous?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  credit_activity?: Maybe<Array<Maybe<CreditActivity>>>;
  cron_ignored?: Maybe<Scalars['Boolean']['output']>;
  feature_flags?: Maybe<Array<Maybe<FeatureFlag>>>;
  flags?: Maybe<BookingFlags>;
  history?: Maybe<BookingHistory>;
  information?: Maybe<Array<Maybe<BookingInformation>>>;
  listing_information?: Maybe<BookingListingInformation>;
  messages?: Maybe<Array<Maybe<Messages>>>;
  name?: Maybe<Scalars['String']['output']>;
  participantStudySettings?: Maybe<ParticipantStudySettingsType>;
  /** Given a User ID, returns whether the participant should be excluded because of previous attendance */
  participant_is_excluded?: Maybe<Scalars['Boolean']['output']>;
  payments_ignored?: Maybe<Scalars['Boolean']['output']>;
  project?: Maybe<Project>;
  rating?: Maybe<BookingRating>;
  recruit?: Maybe<BookingRecruit>;
  session?: Maybe<Array<Maybe<BookingSession>>>;
  settings?: Maybe<BookingSettings>;
  /** 0 - Draft, 1 - Active, 2 - Pending Review, 3 - In Review, 4 - Rejected, 5 - Completed, 6 - Pending Payment, 7 - Archived */
  status?: Maybe<Scalars['Int']['output']>;
  steps?: Maybe<BookingSteps>;
  submissions?: Maybe<Array<Maybe<Submission>>>;
  team?: Maybe<Teams>;
  team_booking_index?: Maybe<Scalars['Int']['output']>;
  total_amount?: Maybe<BookingTotalAmount>;
  total_participants?: Maybe<Scalars['Int']['output']>;
  /** 1 - Face to face, 2 - remote / over the phone, 3 - Online self-moderated, 4 - Longitudinal, 5 - Unmoderated */
  type?: Maybe<Scalars['Int']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<User>;
};

export type BookingParticipantSessionsArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  cancel?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  viewer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingBooking_ParticipantArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  participant_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type BookingCredit_ActivityArgs = {
  search?: InputMaybe<CreditActivitySearch>;
};

export type BookingParticipant_Is_ExcludedArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
};

export type BookingSubmissionsArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<BookingSubmissionsFilter>;
  viewer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingTotal_AmountArgs = {
  currency_code?: InputMaybe<Scalars['String']['input']>;
};

export type BookingAdminCommentType = {
  __typename?: 'BookingAdminCommentType';
  User?: Maybe<User>;
  _id: Scalars['ID']['output'];
  _user_id?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
};

export type BookingAdminInput = {
  emoji?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<BookingAdminTagsInput>;
};

export type BookingAdminTags = {
  __typename?: 'BookingAdminTags';
  nufp?: Maybe<Scalars['Boolean']['output']>;
};

export type BookingAdminTagsInput = {
  nufp?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingAdminType = {
  __typename?: 'BookingAdminType';
  comments?: Maybe<Array<Maybe<BookingAdminCommentType>>>;
  emoji?: Maybe<Scalars['String']['output']>;
  mission_critical?: Maybe<Scalars['Boolean']['output']>;
  mission_critical_reason?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<BookingAdminTags>;
};

export type BookingAutomationScore = {
  __typename?: 'BookingAutomationScore';
  _booking_id?: Maybe<Scalars['ID']['output']>;
  actions?: Maybe<Scalars['Int']['output']>;
  admin_actions?: Maybe<Scalars['Int']['output']>;
  calls?: Maybe<Array<Maybe<BookingInteractionCalls>>>;
  client_actions?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type BookingConfig = {
  __typename?: 'BookingConfig';
  _facilitator_id?: Maybe<Scalars['ID']['output']>;
  _organiser_id?: Maybe<Scalars['ID']['output']>;
  ai_moderated?: Maybe<AiModeratedBookingConfig>;
  block_participants?: Maybe<BookingConfigBlockParticipants>;
  contact?: Maybe<BookingConfigContact>;
  continuous_discovery?: Maybe<Scalars['Boolean']['output']>;
  /** Array of all locations a booking can have. Based on the locations selected during booking creation. */
  countries?: Maybe<Array<Scalars['CountryCode']['output']>>;
  coupon?: Maybe<Coupon>;
  credits_per_participant?: Maybe<Scalars['Int']['output']>;
  criteria?: Maybe<CriteriaBookingConfig>;
  demo?: Maybe<Scalars['Boolean']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  in_context?: Maybe<InContextBookingConfig>;
  /** @deprecated Use field incentives */
  incentive?: Maybe<IncentiveBookingConfig>;
  /** Array of all incentives a booking can have. Based on the locations selected during booking creation. */
  incentives?: Maybe<Array<Maybe<IncentiveBookingConfig>>>;
  information?: Maybe<BookingConfigInformation>;
  /** Physical location of the booking. This may be set in remote bookings but only for backwards compatibility. */
  location?: Maybe<Location>;
  longitudinal_study?: Maybe<LongitudinalStudyBookingConfig>;
  min_participant_rating?: Maybe<Scalars['Float']['output']>;
  online_task?: Maybe<OnlineTaskBookingConfig>;
  options?: Maybe<OptionsBookingConfig>;
  participant_agreement?: Maybe<BookingConfigParticipantAgreement>;
  /** 1 - General, 2 - Professionals, 3 - Fully custom / super hard */
  participant_category?: Maybe<Scalars['Int']['output']>;
  project?: Maybe<ProjectBookingConfig>;
  question?: Maybe<Array<Maybe<QuestionBookingConfig>>>;
  recruitment?: Maybe<RecruitmentBookingConfig>;
  remote?: Maybe<RemoteBookingConfig>;
  segments?: Maybe<Array<Maybe<Segment>>>;
  session?: Maybe<SessionBookingConfig>;
  steps?: Maybe<Array<Maybe<BookingConfigSteps>>>;
  timezone?: Maybe<Scalars['String']['output']>;
  unmoderated?: Maybe<UnmoderatedBookingConfig>;
};

export type BookingConfigBlockParticipants = {
  __typename?: 'BookingConfigBlockParticipants';
  giftpay?: Maybe<Scalars['Boolean']['output']>;
  unverified_paypal?: Maybe<Scalars['Boolean']['output']>;
};

export type BookingConfigContact = {
  __typename?: 'BookingConfigContact';
  UserFacilitator?: Maybe<User>;
  UserOrganiser?: Maybe<User>;
  _facilitator_user_id?: Maybe<Scalars['ID']['output']>;
  _organiser_user_id?: Maybe<Scalars['ID']['output']>;
  /** Email of the Organiser */
  email?: Maybe<Scalars['String']['output']>;
  /** Temporary phone number in case the organiser doesnt have one */
  facilitator_temp_phone_number?: Maybe<Scalars['String']['output']>;
  /** Name of the Organiser */
  name?: Maybe<Scalars['String']['output']>;
  /** Phone number of the Organiser */
  phone?: Maybe<Scalars['String']['output']>;
};

export type BookingConfigContactUserOrganiserArgs = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
};

export type BookingConfigInformation = {
  __typename?: 'BookingConfigInformation';
  brief_summary?: Maybe<Scalars['String']['output']>;
  brief_summary_changed?: Maybe<Scalars['Boolean']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  special_instructions?: Maybe<Scalars['String']['output']>;
};

export type BookingConfigParticipantAgreement = {
  __typename?: 'BookingConfigParticipantAgreement';
  ESignatureTemplate?: Maybe<ESignatureTemplate>;
  _template_id?: Maybe<Scalars['ID']['output']>;
  recipients?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** 0: Standard Ts&Cs (no agreement), 1: Custom agreement */
  type?: Maybe<Scalars['Int']['output']>;
};

export type BookingConfigSteps = {
  __typename?: 'BookingConfigSteps';
  status?: Maybe<Scalars['Int']['output']>;
  step?: Maybe<Scalars['Int']['output']>;
};

export type BookingContact = {
  __typename?: 'BookingContact';
  _primary_contact_id?: Maybe<Scalars['ID']['output']>;
};

export type BookingContactInput = {
  _primary_contact_id?: InputMaybe<Scalars['ID']['input']>;
};

export enum BookingDevice {
  AnyDevice = 'any_device',
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export type BookingFeedback = {
  __typename?: 'BookingFeedback';
  client?: Maybe<Scalars['String']['output']>;
  participant?: Maybe<Scalars['String']['output']>;
};

export type BookingFlags = {
  __typename?: 'BookingFlags';
  sessions_livekit?: Maybe<Scalars['Boolean']['output']>;
};

export type BookingFulfillment = {
  __typename?: 'BookingFulfillment';
  close_reason_description?: Maybe<Scalars['String']['output']>;
  close_reason_option?: Maybe<Scalars['Int']['output']>;
  refund_reason?: Maybe<Scalars['String']['output']>;
};

export type BookingHistory = {
  __typename?: 'BookingHistory';
  askable_live_expiring_notification?: Maybe<Scalars['Float']['output']>;
  askable_live_recordings_expired?: Maybe<Scalars['Float']['output']>;
  closed_by_client?: Maybe<Scalars['Float']['output']>;
  completed_date?: Maybe<Scalars['Float']['output']>;
  reopen_by_client?: Maybe<Scalars['Float']['output']>;
};

export type BookingInformation = {
  __typename?: 'BookingInformation';
  _id: Scalars['ID']['output'];
  body?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** INPUTS */
export type BookingInput = {
  _admin_id?: InputMaybe<Scalars['ID']['input']>;
  _id?: InputMaybe<Scalars['ID']['input']>;
  _owner_id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  _project_id?: InputMaybe<Scalars['ID']['input']>;
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  admin?: InputMaybe<BookingAdminInput>;
  cloned?: InputMaybe<Scalars['Boolean']['input']>;
  config?: InputMaybe<BookingInputConfig>;
  feature_flags?: InputMaybe<Array<InputMaybe<FeatureFlag>>>;
  fulfillment?: InputMaybe<BookingInputFulfillment>;
  information?: InputMaybe<Array<InputMaybe<BookingInputInformation>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<Array<InputMaybe<BookingInputSession>>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  steps?: InputMaybe<BookingInputSteps>;
  total_participants?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfig = {
  ai_moderated?: InputMaybe<AiModeratedBookingInput>;
  block_participants?: InputMaybe<BookingInputConfigBlockParticipants>;
  contact?: InputMaybe<BookingInputConfigContact>;
  contacts?: InputMaybe<Array<InputMaybe<BookingInputConfigContacts>>>;
  continuous_discovery?: InputMaybe<Scalars['Boolean']['input']>;
  credits_per_participant?: InputMaybe<Scalars['Int']['input']>;
  criteria?: InputMaybe<BookingInputConfigCriteria>;
  demo?: InputMaybe<Scalars['Boolean']['input']>;
  fee?: InputMaybe<BookingInputConfigFee>;
  in_context?: InputMaybe<BookingInputConfigInContext>;
  incentive?: InputMaybe<BookingInputConfigIncentive>;
  incentives?: InputMaybe<Array<InputMaybe<BookingInputConfigIncentive>>>;
  information?: InputMaybe<BookingInputConfigInformation>;
  location?: InputMaybe<LocationInput>;
  locations?: InputMaybe<Array<InputMaybe<LocationInput>>>;
  longitudinal_study?: InputMaybe<BookingInputConfigLongitudinalStudy>;
  min_participant_rating?: InputMaybe<Scalars['Float']['input']>;
  online_task?: InputMaybe<BookingInputConfigOnlineTask>;
  options?: InputMaybe<BookingInputConfigOptions>;
  participant_agreement?: InputMaybe<BookingInputConfigParticipantAgreement>;
  participant_category?: InputMaybe<Scalars['Int']['input']>;
  project?: InputMaybe<ProjectBookingInputConfig>;
  promoted?: InputMaybe<Scalars['Boolean']['input']>;
  question?: InputMaybe<Array<InputMaybe<BookingInputConfigQuestion>>>;
  recruitment?: InputMaybe<BookingInputConfigRecruitment>;
  remote?: InputMaybe<BookingInputConfigRemote>;
  segments?: InputMaybe<Array<InputMaybe<SegmentInput>>>;
  session?: InputMaybe<BookingInputConfigSession>;
  steps?: InputMaybe<Array<InputMaybe<BookingInputConfigSteps>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputConfigBlockParticipants = {
  giftpay?: InputMaybe<Scalars['Boolean']['input']>;
  unverified_paypal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingInputConfigContact = {
  _facilitator_user_id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facilitator_temp_phone_number?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputConfigContacts = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  primary_contact?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingInputConfigCriteria = {
  custom?: InputMaybe<Array<InputMaybe<BookingInputCustomQuestion>>>;
  exclude_participants_from_bookings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  exclude_participants_from_time?: InputMaybe<Scalars['Int']['input']>;
  external_url?: InputMaybe<Scalars['String']['input']>;
  /** Save demographical filters in the order they were added */
  filters_order?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locations?: InputMaybe<BookingInputConfigLocationCriteria>;
  meta_children?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_education?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_education_field?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_family_income?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_family_status?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_home_ownership?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_identity_birthday_year?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_identity_gender?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  /** New demographical filters */
  meta_identity_languages?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_identity_languages_english_speak?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_identity_locales?: InputMaybe<Array<BookingInputConfigMetaCriteriaLocales>>;
  meta_individual_income?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_purchasing_behaviour?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_tech_savviness?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_work_employment_type?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
  meta_work_industry?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaWorkIndustry>>>;
  meta_work_status?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaCriteria>>>;
};

export type BookingInputConfigFee = {
  recruitment?: InputMaybe<BookingInputFee>;
};

export type BookingInputConfigInContext = {
  location_type?: InputMaybe<Scalars['Int']['input']>;
  travel_time_limit?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfigIncentive = {
  country_code?: InputMaybe<Scalars['CountryCode']['input']>;
  currency_code?: InputMaybe<Scalars['String']['input']>;
  currency_symbol?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type BookingInputConfigInformation = {
  brief_summary?: InputMaybe<Scalars['String']['input']>;
  brief_summary_changed?: InputMaybe<Scalars['Boolean']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  special_instructions?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputConfigLocationCriteria = {
  bounds?: InputMaybe<Array<InputMaybe<LocationInput>>>;
  countries?: InputMaybe<Array<InputMaybe<LocationInput>>>;
  states?: InputMaybe<Array<InputMaybe<LocationInput>>>;
};

export type BookingInputConfigLongitudinalExtra = {
  description?: InputMaybe<Scalars['String']['input']>;
  measure?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfigLongitudinalParticipantWorkload = {
  frequency?: InputMaybe<Scalars['Int']['input']>;
  measure?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfigLongitudinalPeriod = {
  frequency?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfigLongitudinalStudy = {
  extra?: InputMaybe<Array<InputMaybe<BookingInputConfigLongitudinalExtra>>>;
  participant_workload?: InputMaybe<BookingInputConfigLongitudinalParticipantWorkload>;
  period?: InputMaybe<BookingInputConfigLongitudinalPeriod>;
};

export type BookingInputConfigMetaCriteria = {
  field?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Input for specifying locale proficiency criteria in booking configurations */
export type BookingInputConfigMetaCriteriaLocales = {
  field: Scalars['String']['input'];
  locale: Locale;
  operator: Scalars['String']['input'];
  value: Array<LocaleProficiency>;
};

export type BookingInputConfigMetaWorkIndustry = {
  _industry_id?: InputMaybe<Scalars['ID']['input']>;
  industry_name?: InputMaybe<Scalars['String']['input']>;
  subcategories?: InputMaybe<Array<InputMaybe<BookingInputConfigMetaWorkIndustrySubcategories>>>;
};

export type BookingInputConfigMetaWorkIndustrySubcategories = {
  _subcategory_id?: InputMaybe<Scalars['ID']['input']>;
  subcategory_name?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputConfigOnlineTask = {
  links?: InputMaybe<Array<InputMaybe<BookingInputConfigOnlineTaskLink>>>;
  required_device?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  required_features?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tool?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfigOnlineTaskLink = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  checked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputConfigOptions = {
  lunch_end?: InputMaybe<Scalars['String']['input']>;
  lunch_start?: InputMaybe<Scalars['String']['input']>;
  review_submission?: InputMaybe<Scalars['Boolean']['input']>;
  session_break?: InputMaybe<Scalars['Int']['input']>;
  show_company?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingInputConfigParticipantAgreement = {
  _template_id?: InputMaybe<Scalars['ID']['input']>;
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** 0: Standard Ts&Cs (no agreement), 1: Custom agreement */
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfigQuestion = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  config?: InputMaybe<BookingInputConfigQuestionConfig>;
  description?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<InputMaybe<BookingInputConfigQuestionOptions>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputConfigQuestionConfig = {
  display_participant?: InputMaybe<Scalars['Boolean']['input']>;
  multiple_selection?: InputMaybe<Scalars['Boolean']['input']>;
  none_of_the_above?: InputMaybe<Scalars['Boolean']['input']>;
  /** 1: Multiple choice, 2: Free text */
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfigQuestionOptions = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  screen_in?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingInputConfigRecruitment = {
  byo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingInputConfigRemote = {
  askable_live?: InputMaybe<Scalars['Boolean']['input']>;
  tool?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputConfigSession = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  slot_max?: InputMaybe<Scalars['Int']['input']>;
  slot_min?: InputMaybe<Scalars['Int']['input']>;
  time_limit?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputConfigSteps = {
  status?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputCustomQuestion = {
  _question_id?: InputMaybe<Scalars['ID']['input']>;
  operator?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputFee = {
  currency?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type BookingInputFulfillment = {
  close_reason_description?: InputMaybe<Scalars['String']['input']>;
  close_reason_option?: InputMaybe<Scalars['Int']['input']>;
  refund_reason?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputInformation = {
  body?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInputRating = {
  additional_information?: InputMaybe<Scalars['String']['input']>;
  overall?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputSession = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _researcher_user_id?: InputMaybe<Scalars['ID']['input']>;
  end?: InputMaybe<Scalars['Float']['input']>;
  slot_max?: InputMaybe<Scalars['Int']['input']>;
  slot_min?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

export type BookingInputSteps = {
  additional_info?: InputMaybe<Scalars['String']['input']>;
  additional_info_additional_instructions?: InputMaybe<Scalars['String']['input']>;
  additional_info_closing_date?: InputMaybe<Scalars['String']['input']>;
  additional_info_contacts?: InputMaybe<Scalars['String']['input']>;
  additional_info_description?: InputMaybe<Scalars['String']['input']>;
  /** Deprecated field, use project_setup_devices */
  additional_info_devices?: InputMaybe<Scalars['String']['input']>;
  additional_info_legal_privacy?: InputMaybe<Scalars['String']['input']>;
  additional_info_session_location?: InputMaybe<Scalars['String']['input']>;
  additional_info_short_description?: InputMaybe<Scalars['String']['input']>;
  additional_info_your_timezone?: InputMaybe<Scalars['String']['input']>;
  ai_moderated?: InputMaybe<Scalars['String']['input']>;
  ai_moderated_research_objective?: InputMaybe<Scalars['String']['input']>;
  ai_moderated_welcome_end_screen?: InputMaybe<Scalars['String']['input']>;
  audience?: InputMaybe<Scalars['String']['input']>;
  audience_custom_screener?: InputMaybe<Scalars['String']['input']>;
  audience_demographic_filters?: InputMaybe<Scalars['String']['input']>;
  audience_incentives?: InputMaybe<Scalars['String']['input']>;
  audience_panel?: InputMaybe<Scalars['String']['input']>;
  audience_participant_locations?: InputMaybe<Scalars['String']['input']>;
  link_to_task?: InputMaybe<Scalars['String']['input']>;
  link_to_task_online_task?: InputMaybe<Scalars['String']['input']>;
  link_to_task_survey?: InputMaybe<Scalars['String']['input']>;
  project_setup?: InputMaybe<Scalars['String']['input']>;
  project_setup_devices?: InputMaybe<Scalars['String']['input']>;
  project_setup_participant_selection?: InputMaybe<Scalars['String']['input']>;
  project_setup_project_title?: InputMaybe<Scalars['String']['input']>;
  project_setup_quota?: InputMaybe<Scalars['String']['input']>;
  project_setup_research_type?: InputMaybe<Scalars['String']['input']>;
  project_setup_session_duration?: InputMaybe<Scalars['String']['input']>;
  project_setup_session_format?: InputMaybe<Scalars['String']['input']>;
  project_setup_study_duration?: InputMaybe<Scalars['String']['input']>;
  project_setup_task_duration?: InputMaybe<Scalars['String']['input']>;
  project_setup_tool?: InputMaybe<Scalars['String']['input']>;
  review_submit?: InputMaybe<Scalars['String']['input']>;
  review_submit_confirm_booking?: InputMaybe<Scalars['String']['input']>;
  session_times?: InputMaybe<Scalars['String']['input']>;
  session_times_backup?: InputMaybe<Scalars['String']['input']>;
  session_times_calendar?: InputMaybe<Scalars['String']['input']>;
};

export type BookingInteractionCalls = {
  __typename?: 'BookingInteractionCalls';
  author?: Maybe<Scalars['String']['output']>;
  calls?: Maybe<Scalars['Int']['output']>;
  date_max?: Maybe<Scalars['Float']['output']>;
  date_min?: Maybe<Scalars['Float']['output']>;
  distinct?: Maybe<Scalars['Int']['output']>;
  method?: Maybe<Scalars['String']['output']>;
};

export type BookingListingInformation = {
  __typename?: 'BookingListingInformation';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type BookingListingInformationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BookingParticipant = {
  __typename?: 'BookingParticipant';
  _id: Scalars['ID']['output'];
  _session_id?: Maybe<Scalars['ID']['output']>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  /** 0 - Not Cancel, 1 - Cancel by Admin, 2 - Cancel by Client, 3 - Cancel by Participant, 4 - Cancel because NoShow, 5 - Cancel by system (cron, etc), 6 - Cancelled because Rescheduled */
  cancel?: Maybe<Scalars['Int']['output']>;
  cancel_reason?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  feedback?: Maybe<BookingFeedback>;
  history?: Maybe<BookingParticipantHistory>;
  rating?: Maybe<BookingParticipantRating>;
  session?: Maybe<BookingSession>;
  /** 1 - Confirmed, 2 - Waitlisted, 3 - Available, 4 - Invited, 5 - Task in progress */
  status?: Maybe<Scalars['Int']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<User>;
  user_confirm?: Maybe<Scalars['Float']['output']>;
  waiting_list?: Maybe<Scalars['Boolean']['output']>;
};

export type BookingParticipantHistory = {
  __typename?: 'BookingParticipantHistory';
  /** 72hs message timestamp */
  early_confirmation_request?: Maybe<Scalars['Float']['output']>;
  /** When the client marked the participant for review */
  issue_reported?: Maybe<Scalars['Float']['output']>;
  /** When the participant issue was marked resolved */
  issue_resolved?: Maybe<Scalars['Float']['output']>;
  /** Last time quant user applied to session. */
  last_quantitative_application_time?: Maybe<Scalars['Float']['output']>;
  /** Last time quant user got sent a reminder message. */
  last_quantitative_reminder_time?: Maybe<Scalars['Float']['output']>;
  /** Message sent to follow up on unresolved quant help requests */
  quantitative_help_request_followup?: Maybe<Scalars['Float']['output']>;
  /** Message sent when the participant starts the task, reminding them to complete on Askable */
  quantitative_start_task_reminder?: Maybe<Scalars['Float']['output']>;
  /** The participant clicked the confirmation button in the app */
  session_click_confirm_button?: Maybe<Scalars['Float']['output']>;
  /** The participant completed their session through the app */
  session_completed?: Maybe<Scalars['Float']['output']>;
  /** Message that goes to client 15mins after session ends - timestamp */
  session_completed_client_feedback_request?: Maybe<Scalars['Float']['output']>;
  /** Message that goes 15mins after session ends - timestamp */
  session_completed_feedback_request?: Maybe<Scalars['Float']['output']>;
  /** User feedback creation timestamp */
  session_completed_feedback_submitted?: Maybe<Scalars['Float']['output']>;
  /** User has cancelled his attendance timestamp */
  session_confirmed_cancellation?: Maybe<Scalars['Float']['output']>;
  /** User accepted invite timestamp */
  session_invitation_accepted?: Maybe<Scalars['Float']['output']>;
  /** User has declined his invitation timestamp */
  session_invitation_declined?: Maybe<Scalars['Float']['output']>;
  /** User invited to session timestamp */
  session_invitation_notification?: Maybe<Scalars['Float']['output']>;
  /** User invited to session (followup) timestamp */
  session_invitation_notification_followup?: Maybe<Scalars['Float']['output']>;
  /** User applied for a job timestamp */
  session_opportunity_application?: Maybe<Scalars['Float']['output']>;
  /** User has been paid timestamp */
  session_paid?: Maybe<Scalars['Float']['output']>;
  /** Askable admin has cancelled participant on a confirmed session */
  session_participant_cancelled_by_askable?: Maybe<Scalars['Float']['output']>;
  /** Client has cancelled participant on a confirmed session */
  session_participant_cancelled_by_client?: Maybe<Scalars['Float']['output']>;
  /** When the (Quant) session was complted */
  session_quant_completed?: Maybe<Scalars['Float']['output']>;
  /** When the user was invited to the quant session */
  session_quant_invitation_notification?: Maybe<Scalars['Float']['output']>;
  /** User reinvited to session timestamp */
  session_reinvite_notification?: Maybe<Scalars['Float']['output']>;
  /** 2.5hs message timestamp */
  session_start_reminder_2_askable_incentive_message?: Maybe<Scalars['Float']['output']>;
  /**
   * 2.5hs message timestamp
   * @deprecated Clients no longer send incentives
   */
  session_start_reminder_2_client_incentive_message?: Maybe<Scalars['Float']['output']>;
  /** 24hs message (confirmation needed) timestamp */
  session_start_reminder_action_needed?: Maybe<Scalars['Float']['output']>;
  /** 24hs message (followup) timestamp */
  session_start_reminder_action_needed_followup?: Maybe<Scalars['Float']['output']>;
  /** Askable was notifified that a participant has failed to confirm their session */
  session_start_reminder_confirmation_pending_askable_notification?: Maybe<Scalars['Float']['output']>;
  /** Warning that gets sent to the Askable Team about unconfirmed ppts */
  session_start_reminder_last_askable_warning?: Maybe<Scalars['Float']['output']>;
  /** 24hs message (no confirmation) timestamp */
  session_start_reminder_no_action?: Maybe<Scalars['Float']['output']>;
};

export type BookingParticipantRating = {
  __typename?: 'BookingParticipantRating';
  engagement?: Maybe<Scalars['Int']['output']>;
  overall?: Maybe<Scalars['Int']['output']>;
  punctuality?: Maybe<Scalars['Int']['output']>;
};

export type BookingRating = {
  __typename?: 'BookingRating';
  additional_information?: Maybe<Scalars['String']['output']>;
  customer_support_quality?: Maybe<Scalars['Int']['output']>;
  /** @deprecated renamed field */
  emotion?: Maybe<Scalars['Int']['output']>;
  overall?: Maybe<Scalars['Int']['output']>;
  participant_quality?: Maybe<Scalars['Int']['output']>;
  /** @deprecated renamed field */
  platform_experience?: Maybe<Scalars['Int']['output']>;
  product_quality?: Maybe<Scalars['Int']['output']>;
  stress_free?: Maybe<Scalars['Int']['output']>;
  worthwhile?: Maybe<Scalars['Int']['output']>;
};

export type BookingRecruit = {
  __typename?: 'BookingRecruit';
  age_range?: Maybe<Array<Scalars['Int']['output']>>;
  business_ownership?: Maybe<Array<BookingRecruitBusinessOwnership>>;
  custom_terms?: Maybe<BookingRecruitCustomTerms>;
  device?: Maybe<BookingDevice>;
  education_level?: Maybe<Array<BookingRecruitEducationLevel>>;
  gender?: Maybe<Array<BookingRecruitGender>>;
  industry_and_occupation?: Maybe<Array<BookingRecruitIndustry>>;
  language_fluency?: Maybe<Array<BookingRecruitLanguageFluency>>;
  locations?: Maybe<Array<BookingRecruitLocation>>;
  marital_status?: Maybe<Array<BookingRecruitMaritalStatus>>;
  occupational_status?: Maybe<Array<BookingRecruitOccupationalStatus>>;
  participant_selection?: Maybe<BookingRecruitParticipantSelection>;
  participant_source?: Maybe<BookingRecruitParticipantSource>;
  participants?: Maybe<Scalars['Int']['output']>;
  participants_excluded_months?: Maybe<Scalars['Int']['output']>;
  screener_questions?: Maybe<Array<BookingRecruitQuestion>>;
};

export enum BookingRecruitBusinessOwnership {
  BusinessOwner = 'business_owner',
  Employee = 'employee',
  SoleTrader = 'sole_trader',
}

export type BookingRecruitCustomTerms = {
  __typename?: 'BookingRecruitCustomTerms';
  _template_id: Scalars['ID']['output'];
  recipients: Array<Scalars['String']['output']>;
};

export type BookingRecruitCustomTermsInput = {
  _template_id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
};

export enum BookingRecruitEducationLevel {
  Apprenticeship = 'apprenticeship',
  HighSchool = 'high_school',
  Postgraduate = 'postgraduate',
  SomeUniversity = 'some_university',
  Undergraduate = 'undergraduate',
}

export enum BookingRecruitGender {
  Female = 'female',
  Male = 'male',
  NonBinary = 'non_binary',
}

export type BookingRecruitIndustry = {
  __typename?: 'BookingRecruitIndustry';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subcategories: Array<BookingRecruitIndustrySubcategory>;
};

export type BookingRecruitIndustryInput = {
  _id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  subcategories: Array<BookingRecruitIndustrySubcategoryInput>;
};

export type BookingRecruitIndustrySubcategory = {
  __typename?: 'BookingRecruitIndustrySubcategory';
  _id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BookingRecruitIndustrySubcategoryInput = {
  _id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Only intended for updating config in DRAFT */
export type BookingRecruitInput = {
  age_range?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  business_ownership?: InputMaybe<Array<BookingRecruitBusinessOwnership>>;
  /** Set to null for standard app Ts&Cs */
  custom_terms?: InputMaybe<BookingRecruitCustomTermsInput>;
  device?: InputMaybe<BookingDevice>;
  education_level?: InputMaybe<Array<BookingRecruitEducationLevel>>;
  gender?: InputMaybe<Array<BookingRecruitGender>>;
  industry_and_occupation?: InputMaybe<Array<BookingRecruitIndustryInput>>;
  language_fluency?: InputMaybe<Array<BookingRecruitLanguageFluency>>;
  locations?: InputMaybe<Array<BookingRecruitLocationInput>>;
  marital_status?: InputMaybe<Array<BookingRecruitMaritalStatus>>;
  occupational_status?: InputMaybe<Array<BookingRecruitOccupationalStatus>>;
  participant_selection?: InputMaybe<BookingRecruitParticipantSelection>;
  participant_source?: InputMaybe<BookingRecruitParticipantSource>;
  participants?: InputMaybe<Scalars['Int']['input']>;
  participants_excluded_months?: InputMaybe<Scalars['Int']['input']>;
  screener_questions?: InputMaybe<Array<BookingRecruitQuestionInput>>;
};

export enum BookingRecruitLanguageFluency {
  Beginner = 'beginner',
  Conversational = 'conversational',
  Fluent = 'fluent',
  Native = 'native',
}

export type BookingRecruitLocation = {
  __typename?: 'BookingRecruitLocation';
  location?: Maybe<Location>;
  type?: Maybe<BookingRecruitLocationType>;
};

export type BookingRecruitLocationInput = {
  location?: InputMaybe<LocationInput>;
  type?: InputMaybe<BookingRecruitLocationType>;
};

export enum BookingRecruitLocationType {
  Bounds = 'bounds',
  Country = 'country',
  State = 'state',
}

export enum BookingRecruitMaritalStatus {
  Divorced = 'divorced',
  Married = 'married',
  Single = 'single',
  Widowed = 'widowed',
}

export enum BookingRecruitOccupationalStatus {
  FullTime = 'full_time',
  HomeDuties = 'home_duties',
  PartTime = 'part_time',
  Retired = 'retired',
  Student = 'student',
  Unemployed = 'unemployed',
}

export enum BookingRecruitParticipantSelection {
  Automated = 'automated',
  HandPicking = 'hand_picking',
}

export enum BookingRecruitParticipantSource {
  List = 'list',
  Panel = 'panel',
}

export type BookingRecruitQuestion = {
  __typename?: 'BookingRecruitQuestion';
  _id: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  is_multiple_selection?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<Array<BookingRecruitQuestionOption>>;
  title: Scalars['String']['output'];
  type: BookingRecruitQuestionType;
};

export type BookingRecruitQuestionInput = {
  _id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  is_multiple_selection?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<Array<BookingRecruitQuestionOptionInput>>;
  title: Scalars['String']['input'];
  type: BookingRecruitQuestionType;
};

export type BookingRecruitQuestionOption = {
  __typename?: 'BookingRecruitQuestionOption';
  _id: Scalars['ID']['output'];
  is_qualified?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
};

export type BookingRecruitQuestionOptionInput = {
  _id: Scalars['ID']['input'];
  is_qualified?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
};

export enum BookingRecruitQuestionType {
  MultipleChoice = 'multiple_choice',
  ShortAnswer = 'short_answer',
}

export type BookingSession = {
  __typename?: 'BookingSession';
  /** Field to get the latest status in relation to the attached session */
  CurrentStatus?: Maybe<SessionsType>;
  MeetingLink?: Maybe<Scalars['String']['output']>;
  ResearcherUser?: Maybe<Researcher>;
  UserStatusCount?: Maybe<UserStatusCount>;
  _id?: Maybe<Scalars['ID']['output']>;
  _researcher_user_id?: Maybe<Scalars['ID']['output']>;
  /** For remote bookings that are using askable live */
  askable_live_rooms?: Maybe<Array<Maybe<BookingSessionAskableLiveRoom>>>;
  askable_live_users?: Maybe<Array<Maybe<AskableLiveOnlineUser>>>;
  created?: Maybe<Scalars['Float']['output']>;
  end?: Maybe<Scalars['Float']['output']>;
  participants?: Maybe<Array<Maybe<SessionsType>>>;
  slot_max?: Maybe<Scalars['Int']['output']>;
  slot_min?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
  /** 1: Live, 2: Cancel by Admin, 3: Cancel by Client */
  status?: Maybe<Scalars['Int']['output']>;
};

export type BookingSessionCurrentStatusArgs = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
};

export type BookingSessionParticipantsArgs = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  cancel_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  participant_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  session_state?: InputMaybe<SessionState>;
};

export type BookingSessionAskableLiveRoom = {
  __typename?: 'BookingSessionAskableLiveRoom';
  /** Composition id used to retrieve a URL for the client to download the recording */
  composition_sid?: Maybe<Scalars['String']['output']>;
  /** @deprecated Doesn’t make sense to store URL with short-lived access token */
  composition_url?: Maybe<Scalars['String']['output']>;
  /** Room it on Twilio */
  sid?: Maybe<Scalars['String']['output']>;
};

export type BookingSessionHistory = {
  __typename?: 'BookingSessionHistory';
  /** 72hs message timestamp */
  early_confirmation_request?: Maybe<Scalars['Float']['output']>;
  /** When the client marked the participant for review */
  issue_reported?: Maybe<Scalars['Float']['output']>;
  /** When the participant issue was marked resolved */
  issue_resolved?: Maybe<Scalars['Float']['output']>;
  /** Last time quant user applied to session. */
  last_quantitative_application_time?: Maybe<Scalars['Float']['output']>;
  /** Last time quant user got sent a reminder message. */
  last_quantitative_reminder_time?: Maybe<Scalars['Float']['output']>;
  /** Message sent to follow up on unresolved quant help requests */
  quantitative_help_request_followup?: Maybe<Scalars['Float']['output']>;
  /** Message sent when the participant starts the task, reminding them to complete on Askable */
  quantitative_start_task_reminder?: Maybe<Scalars['Float']['output']>;
  /** The participant clicked the confirmation button in the app */
  session_click_confirm_button?: Maybe<Scalars['Float']['output']>;
  /** The participant completed their session through the app */
  session_completed?: Maybe<Scalars['Float']['output']>;
  /** Message that goes to client 15mins after session ends - timestamp */
  session_completed_client_feedback_request?: Maybe<Scalars['Float']['output']>;
  /** Message that goes 15mins after session ends - timestamp */
  session_completed_feedback_request?: Maybe<Scalars['Float']['output']>;
  /** User feedback creation timestamp */
  session_completed_feedback_submitted?: Maybe<Scalars['Float']['output']>;
  /** User has cancelled his attendance timestamp */
  session_confirmed_cancellation?: Maybe<Scalars['Float']['output']>;
  /** User accepted invite timestamp */
  session_invitation_accepted?: Maybe<Scalars['Float']['output']>;
  /** User has declined his invitation timestamp */
  session_invitation_declined?: Maybe<Scalars['Float']['output']>;
  /** User invited to session timestamp */
  session_invitation_notification?: Maybe<Scalars['Float']['output']>;
  /** User invited to session (followup) timestamp */
  session_invitation_notification_followup?: Maybe<Scalars['Float']['output']>;
  /** User applied for a job timestamp */
  session_opportunity_application?: Maybe<Scalars['Float']['output']>;
  /** User has been paid timestamp */
  session_paid?: Maybe<Scalars['Float']['output']>;
  /** Askable admin has cancelled participant on a confirmed session */
  session_participant_cancelled_by_askable?: Maybe<Scalars['Float']['output']>;
  /** Client has cancelled participant on a confirmed session */
  session_participant_cancelled_by_client?: Maybe<Scalars['Float']['output']>;
  /** When the (Quant) session was complted */
  session_quant_completed?: Maybe<Scalars['Float']['output']>;
  /** When the user was invited to the quant session */
  session_quant_invitation_notification?: Maybe<Scalars['Float']['output']>;
  /** User reinvited to session timestamp */
  session_reinvite_notification?: Maybe<Scalars['Float']['output']>;
  /** 2.5hs message timestamp */
  session_start_reminder_2_askable_incentive_message?: Maybe<Scalars['Float']['output']>;
  /** 24hs message (confirmation needed) timestamp */
  session_start_reminder_action_needed?: Maybe<Scalars['Float']['output']>;
  /** 24hs message (followup) timestamp */
  session_start_reminder_action_needed_followup?: Maybe<Scalars['Float']['output']>;
  /** Askable was notifified that a participant has failed to confirm their session */
  session_start_reminder_confirmation_pending_askable_notification?: Maybe<Scalars['Float']['output']>;
  /** Warning that gets sent to the Askable Team about unconfirmed ppts */
  session_start_reminder_last_askable_warning?: Maybe<Scalars['Float']['output']>;
  /** 24hs message (no confirmation) timestamp */
  session_start_reminder_no_action?: Maybe<Scalars['Float']['output']>;
};

export type BookingSettings = {
  __typename?: 'BookingSettings';
  translation: Scalars['Boolean']['output'];
};

export type BookingStartEndSessions = {
  __typename?: 'BookingStartEndSessions';
  end?: Maybe<BookingSession>;
  start?: Maybe<BookingSession>;
};

export type BookingSteps = {
  __typename?: 'BookingSteps';
  additional_info?: Maybe<Scalars['String']['output']>;
  additional_info_additional_instructions?: Maybe<Scalars['String']['output']>;
  additional_info_closing_date?: Maybe<Scalars['String']['output']>;
  additional_info_contacts?: Maybe<Scalars['String']['output']>;
  additional_info_description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use project_setup_devices */
  additional_info_devices?: Maybe<Scalars['String']['output']>;
  additional_info_legal_privacy?: Maybe<Scalars['String']['output']>;
  additional_info_session_location?: Maybe<Scalars['String']['output']>;
  additional_info_short_description?: Maybe<Scalars['String']['output']>;
  additional_info_your_timezone?: Maybe<Scalars['String']['output']>;
  ai_moderated?: Maybe<Scalars['String']['output']>;
  ai_moderated_research_objective?: Maybe<Scalars['String']['output']>;
  ai_moderated_welcome_end_screen?: Maybe<Scalars['String']['output']>;
  audience?: Maybe<Scalars['String']['output']>;
  audience_custom_screener?: Maybe<Scalars['String']['output']>;
  audience_demographic_filters?: Maybe<Scalars['String']['output']>;
  audience_incentives?: Maybe<Scalars['String']['output']>;
  audience_panel?: Maybe<Scalars['String']['output']>;
  audience_participant_locations?: Maybe<Scalars['String']['output']>;
  link_to_task?: Maybe<Scalars['String']['output']>;
  link_to_task_online_task?: Maybe<Scalars['String']['output']>;
  link_to_task_survey?: Maybe<Scalars['String']['output']>;
  project_setup?: Maybe<Scalars['String']['output']>;
  project_setup_devices?: Maybe<Scalars['String']['output']>;
  project_setup_participant_selection?: Maybe<Scalars['String']['output']>;
  project_setup_project_title?: Maybe<Scalars['String']['output']>;
  project_setup_quota?: Maybe<Scalars['String']['output']>;
  project_setup_research_type?: Maybe<Scalars['String']['output']>;
  project_setup_session_duration?: Maybe<Scalars['String']['output']>;
  project_setup_session_format?: Maybe<Scalars['String']['output']>;
  project_setup_study_duration?: Maybe<Scalars['String']['output']>;
  project_setup_task_duration?: Maybe<Scalars['String']['output']>;
  project_setup_tool?: Maybe<Scalars['String']['output']>;
  review_submit?: Maybe<Scalars['String']['output']>;
  review_submit_confirm_booking?: Maybe<Scalars['String']['output']>;
  session_times?: Maybe<Scalars['String']['output']>;
  session_times_backup?: Maybe<Scalars['String']['output']>;
  session_times_calendar?: Maybe<Scalars['String']['output']>;
};

export type BookingSubmissionConnection = {
  __typename?: 'BookingSubmissionConnection';
  nodes?: Maybe<Array<Maybe<Submission>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BookingSubmissionConnectionFilter = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  condition?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<SegmentFilterInput>>>;
  sort?: InputMaybe<Array<InputMaybe<SegmentSortValue>>>;
  submissionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type BookingSubmissionRating = {
  __typename?: 'BookingSubmissionRating';
  engagement?: Maybe<Scalars['Int']['output']>;
  overall?: Maybe<Scalars['Int']['output']>;
  punctuality?: Maybe<Scalars['Int']['output']>;
};

export type BookingSubmissionsFilter = {
  status?: InputMaybe<FilterSubmissionStatus>;
};

export type BookingSubmissionsTransaction = {
  __typename?: 'BookingSubmissionsTransaction';
  _id?: Maybe<Scalars['ID']['output']>;
  cancel_date?: Maybe<Scalars['Float']['output']>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  payment_due?: Maybe<Scalars['Float']['output']>;
  suspended?: Maybe<Scalars['Boolean']['output']>;
  transactions?: Maybe<Transactions>;
};

export type BookingSubmissionsWithMessages = {
  __typename?: 'BookingSubmissionsWithMessages';
  currentPage: Scalars['Int']['output'];
  nodes: Array<Maybe<Submission>>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type BookingTaskDetailsFigmaPrototypeInput = {
  path: Array<BookingTaskDetailsFigmaPrototypePathItemInput>;
};

/** Inputs */
export type BookingTaskDetailsFigmaPrototypePathItemInput = {
  clicks: Scalars['Int']['input'];
  ended: Scalars['Float']['input'];
  misclicks: Scalars['Int']['input'];
  node_id: Scalars['String']['input'];
  started: Scalars['Float']['input'];
};

export type BookingTaskDetailsMultipleChoiceQuestionInput = {
  answers: Array<BookingTaskDetailsMultipleChoiceQuestionOptionInput>;
  other_answer?: InputMaybe<Scalars['String']['input']>;
};

export type BookingTaskDetailsMultipleChoiceQuestionOption = {
  __typename?: 'BookingTaskDetailsMultipleChoiceQuestionOption';
  _id: Scalars['ObjectID']['output'];
  value: Scalars['String']['output'];
};

export type BookingTaskDetailsMultipleChoiceQuestionOptionInput = {
  _id: Scalars['ObjectID']['input'];
  value: Scalars['String']['input'];
};

export type BookingTaskDetailsOpinionScaleInput = {
  answer: Scalars['Int']['input'];
  answer_normalised: Scalars['Float']['input'];
};

export type BookingTaskEvent = {
  __typename?: 'BookingTaskEvent';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _path_id?: Maybe<Scalars['ObjectID']['output']>;
  _task_id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  details: BookingTaskEventDetails;
  location_id?: Maybe<Scalars['String']['output']>;
};

export type BookingTaskEventClick = {
  __typename?: 'BookingTaskEventClick';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _path_id?: Maybe<Scalars['ObjectID']['output']>;
  _task_id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  details: BookingTaskEventDetailsClick;
  location_id?: Maybe<Scalars['String']['output']>;
};

export type BookingTaskEventDetails = {
  type: BookingTaskEventDetailsType;
};

export type BookingTaskEventDetailsClick = BookingTaskEventDetails & {
  __typename?: 'BookingTaskEventDetailsClick';
  handled: Scalars['Boolean']['output'];
  position_x: Scalars['Float']['output'];
  position_y: Scalars['Float']['output'];
  target_location_id?: Maybe<Scalars['String']['output']>;
  type: BookingTaskEventDetailsType;
};

/** Inputs */
export type BookingTaskEventDetailsClickInput = {
  handled: Scalars['Boolean']['input'];
  position_x: Scalars['Float']['input'];
  position_y: Scalars['Float']['input'];
  target_location_id?: InputMaybe<Scalars['String']['input']>;
};

export type BookingTaskEventDetailsLoad = BookingTaskEventDetails & {
  __typename?: 'BookingTaskEventDetailsLoad';
  type: BookingTaskEventDetailsType;
};

/** Base schema */
export enum BookingTaskEventDetailsType {
  Click = 'click',
  Load = 'load',
}

export type BookingTaskEventLoad = {
  __typename?: 'BookingTaskEventLoad';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _path_id?: Maybe<Scalars['ObjectID']['output']>;
  _task_id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  details: BookingTaskEventDetailsLoad;
  location_id?: Maybe<Scalars['String']['output']>;
};

export type BookingTaskEventsCollection = {
  __typename?: 'BookingTaskEventsCollection';
  nodes: Array<BookingTaskEvent>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BookingTaskEventsFilterInput = {
  _booking_id?: InputMaybe<FilterId>;
  _path_id?: InputMaybe<FilterId>;
  _task_id?: InputMaybe<FilterId>;
  _user_id?: InputMaybe<FilterId>;
  location_id?: InputMaybe<FilterId>;
  type?: InputMaybe<FilterString>;
};

/** Base schema */
export type BookingTaskPath = {
  __typename?: 'BookingTaskPath';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _task_id: Scalars['ObjectID']['output'];
  path_items: Array<Scalars['String']['output']>;
};

export type BookingTaskResult = {
  __typename?: 'BookingTaskResult';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _task_id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  details: BookingTaskResultDetails;
  error_reason?: Maybe<Scalars['String']['output']>;
  nextResultId?: Maybe<Scalars['ObjectID']['output']>;
  previousResultId?: Maybe<Scalars['ObjectID']['output']>;
  status: BookingTaskResultStatus;
  task_ended?: Maybe<Scalars['Float']['output']>;
  task_loaded?: Maybe<Scalars['Float']['output']>;
  task_started?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<User>;
  video?: Maybe<AskableVideo>;
};

export type BookingTaskResultDetails = {
  type: BookingTaskResultDetailsType;
};

export type BookingTaskResultDetailsFigmaPrototype = BookingTaskResultDetails & {
  __typename?: 'BookingTaskResultDetailsFigmaPrototype';
  _path_id?: Maybe<Scalars['ObjectID']['output']>;
  path: Array<BookingTaskResultDetailsFigmaPrototypePathItem>;
  type: BookingTaskResultDetailsType;
};

export type BookingTaskResultDetailsFigmaPrototypePathItem = {
  __typename?: 'BookingTaskResultDetailsFigmaPrototypePathItem';
  _id: Scalars['ObjectID']['output'];
  clicks: Scalars['Int']['output'];
  ended?: Maybe<Scalars['Float']['output']>;
  image?: Maybe<ScreenImage>;
  misclicks: Scalars['Int']['output'];
  node_id: Scalars['String']['output'];
  started: Scalars['Float']['output'];
};

export type BookingTaskResultDetailsMultipleChoiceQuestion = BookingTaskResultDetails & {
  __typename?: 'BookingTaskResultDetailsMultipleChoiceQuestion';
  answers: Array<BookingTaskDetailsMultipleChoiceQuestionOption>;
  other_answer?: Maybe<Scalars['String']['output']>;
  type: BookingTaskResultDetailsType;
};

export type BookingTaskResultDetailsOpinionScale = BookingTaskResultDetails & {
  __typename?: 'BookingTaskResultDetailsOpinionScale';
  answer?: Maybe<Scalars['Int']['output']>;
  answer_normalised?: Maybe<Scalars['Float']['output']>;
  type: BookingTaskResultDetailsType;
};

export enum BookingTaskResultDetailsType {
  FigmaPrototype = 'figma_prototype',
  MultipleChoiceQuestion = 'multiple_choice_question',
  OpinionScale = 'opinion_scale',
}

export type BookingTaskResultFigmaPrototype = {
  __typename?: 'BookingTaskResultFigmaPrototype';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _task_id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  details: BookingTaskResultDetailsFigmaPrototype;
  status: BookingTaskResultStatus;
  task_ended?: Maybe<Scalars['Float']['output']>;
  task_loaded?: Maybe<Scalars['Float']['output']>;
  task_started?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type BookingTaskResultFilterInput = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _id?: InputMaybe<Scalars['ID']['input']>;
  _task_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
};

export type BookingTaskResultMultipleChoiceQuestion = {
  __typename?: 'BookingTaskResultMultipleChoiceQuestion';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _task_id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  details: BookingTaskResultDetailsMultipleChoiceQuestion;
  status: BookingTaskResultStatus;
  task_ended?: Maybe<Scalars['Float']['output']>;
  task_loaded?: Maybe<Scalars['Float']['output']>;
  task_started?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type BookingTaskResultOpinionScale = {
  __typename?: 'BookingTaskResultOpinionScale';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _task_id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  details: BookingTaskResultDetailsOpinionScale;
  status: BookingTaskResultStatus;
  task_ended?: Maybe<Scalars['Float']['output']>;
  task_loaded?: Maybe<Scalars['Float']['output']>;
  task_started?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

/** Base schema */
export enum BookingTaskResultStatus {
  Completed = 'completed',
  Error = 'error',
  Loaded = 'loaded',
  Pending = 'pending',
  Skipped = 'skipped',
  Started = 'started',
}

export type BookingTaskResultsCollection = {
  __typename?: 'BookingTaskResultsCollection';
  nodes: Array<BookingTaskResult>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BookingTaskResultsFilterInput = {
  _booking_id?: InputMaybe<FilterId>;
  _task_id?: InputMaybe<FilterId>;
  _user_id?: InputMaybe<FilterId>;
  status?: InputMaybe<FilterId>;
};

export type BookingTotalAmount = {
  __typename?: 'BookingTotalAmount';
  bookingPrice?: Maybe<Scalars['Float']['output']>;
  bookingPriceAfterTaxes?: Maybe<Scalars['Float']['output']>;
  bookingPriceGross?: Maybe<Scalars['Float']['output']>;
  bookingPriceGrossAfterTaxes?: Maybe<Scalars['Float']['output']>;
  bookingPriceGrossTaxes?: Maybe<Scalars['Float']['output']>;
  bookingPriceTaxes?: Maybe<Scalars['Float']['output']>;
  coupon?: Maybe<Coupon>;
  creditsPerParticipant?: Maybe<Scalars['Float']['output']>;
  currency_code?: Maybe<Scalars['String']['output']>;
  currency_symbol?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

export type BookingsConfigFilter = {
  online_task?: InputMaybe<BookingsConfigOnlineTaskFilter>;
  remote?: InputMaybe<BookingsConfigRemoteFilter>;
};

export type BookingsConfigOnlineTaskFilter = {
  type?: InputMaybe<FilterTypeInt>;
};

export type BookingsConfigRemoteFilter = {
  askable_live?: InputMaybe<FilterBoolean>;
};

export type BookingsConnection = {
  __typename?: 'BookingsConnection';
  nodes?: Maybe<Array<Maybe<Booking>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BookingsFilter = {
  _id?: InputMaybe<FilterId>;
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  config?: InputMaybe<BookingsConfigFilter>;
  name?: InputMaybe<BookingsNameFilter>;
  /** Match different combinations of booking props */
  or?: InputMaybe<Array<InputMaybe<BookingsOrFilter>>>;
  status?: InputMaybe<FilterStatusInt>;
  type?: InputMaybe<FilterTypeInt>;
};

export type BookingsNameFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
};

export type BookingsOrFilter = {
  config?: InputMaybe<BookingsConfigFilter>;
  status?: InputMaybe<FilterStatusInt>;
  type?: InputMaybe<FilterTypeInt>;
};

export type BrowserDataInput = {
  canvas_id?: InputMaybe<Scalars['String']['input']>;
  webgl_id?: InputMaybe<Scalars['String']['input']>;
};

export type BrowserEmbedContainer = {
  __typename?: 'BrowserEmbedContainer';
  embedUrl: Scalars['String']['output'];
  maxResolution: Scalars['Int']['output'];
};

export type BrowserEmbedOptions = {
  height?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type BulkInviteInput = {
  submissions: Array<BulkInviteSubmissionInput>;
};

export type BulkInviteSubmissionInput = {
  id: Scalars['ID']['input'];
  sessionOrTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type CollectionFilter = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
};

export type CollectionIcon = {
  __typename?: 'CollectionIcon';
  icon: Scalars['String']['output'];
  type: CollectionIconTypes;
};

export type CollectionIconInput = {
  icon: Scalars['String']['input'];
  type: CollectionIconTypes;
};

export enum CollectionIconTypes {
  Custom = 'custom',
  Emoji = 'emoji',
  Icon = 'icon',
}

export enum CollectionTitleTheme {
  Dark = 'dark',
  Light = 'light',
}

export type Collections = {
  __typename?: 'Collections';
  _id: Scalars['ID']['output'];
  byline?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  config: CollectionsConfig;
  created_at: Scalars['Float']['output'];
  created_by: User;
  created_by_id: Scalars['ID']['output'];
  icon?: Maybe<CollectionIcon>;
  insight_ids?: Maybe<Array<Scalars['ID']['output']>>;
  insights?: Maybe<Array<Insight>>;
  shares: Array<Share>;
  short_name: Scalars['String']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  team_ids: Array<Scalars['ID']['output']>;
  theme: CollectionsTheme;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Array<Maybe<View>>>;
};

export type CollectionsConfig = {
  __typename?: 'CollectionsConfig';
  sort: CollectionsConfigSort;
};

export enum CollectionsConfigSort {
  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
}

export type CollectionsConnection = {
  __typename?: 'CollectionsConnection';
  nodes?: Maybe<Array<Maybe<Collections>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CollectionsFilter = {
  _id?: InputMaybe<FilterId>;
  code?: InputMaybe<FilterString>;
  insight_ids?: InputMaybe<FilterId>;
  team_ids?: InputMaybe<FilterId>;
};

export type CollectionsOrderBy = {
  created_at: Sort;
};

export type CollectionsTheme = {
  __typename?: 'CollectionsTheme';
  primary_color: Scalars['String']['output'];
  secondary_color: Scalars['String']['output'];
  title_theme: CollectionTitleTheme;
};

export type ConfigQuestionBookingConfig = {
  __typename?: 'ConfigQuestionBookingConfig';
  display_participant?: Maybe<Scalars['Boolean']['output']>;
  multiple_selection?: Maybe<Scalars['Boolean']['output']>;
  none_of_the_above?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Multiple choice, 2: Free text */
  type?: Maybe<Scalars['Int']['output']>;
};

export type ContactInput = {
  phone?: InputMaybe<PhoneInput>;
};

export type CountryData = {
  __typename?: 'CountryData';
  _id?: Maybe<Scalars['ID']['output']>;
  country_code?: Maybe<Scalars['String']['output']>;
  currency_code?: Maybe<Scalars['String']['output']>;
  currency_symbol?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  states?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  RedeemedBy?: Maybe<Array<Maybe<Teams>>>;
  _id: Scalars['ID']['output'];
  allow_reuse?: Maybe<Scalars['Boolean']['output']>;
  campaign_image?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  /**  1: Dollar amount, 2: Percentage  */
  discount_type?: Maybe<Scalars['Int']['output']>;
  discount_value?: Maybe<Scalars['Float']['output']>;
  redeem_limit?: Maybe<Scalars['Int']['output']>;
  redeemed_by?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  validity?: Maybe<CouponValidity>;
};

export type CouponInput = {
  allow_reuse?: InputMaybe<Scalars['Boolean']['input']>;
  campaign_image?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  discount_type?: InputMaybe<Scalars['Int']['input']>;
  discount_value?: InputMaybe<Scalars['Float']['input']>;
  redeem_limit?: InputMaybe<Scalars['Int']['input']>;
  validity?: InputMaybe<CouponValidityInput>;
};

export type CouponValidity = {
  __typename?: 'CouponValidity';
  checkout_amount?: Maybe<Scalars['Float']['output']>;
  end?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

export type CouponValidityInput = {
  checkout_amount?: InputMaybe<Scalars['Float']['input']>;
  end?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateAiModeratedLiveKitRoom = {
  __typename?: 'CreateAiModeratedLiveKitRoom';
  access_token?: Maybe<Scalars['String']['output']>;
};

export type CreateAiModeratedLiveKitRoomInput = {
  meeting_code: Scalars['String']['input'];
};

export type CreateBookingFlagsInput = {
  sessions_livekit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateBookingTaskEventClickInput = {
  _booking_id: Scalars['ID']['input'];
  _path_id?: InputMaybe<Scalars['ID']['input']>;
  _task_id: Scalars['ID']['input'];
  _user_id: Scalars['ID']['input'];
  details: BookingTaskEventDetailsClickInput;
  location_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBookingTaskEventLoadInput = {
  _booking_id: Scalars['ID']['input'];
  _path_id?: InputMaybe<Scalars['ID']['input']>;
  _task_id: Scalars['ID']['input'];
  _user_id: Scalars['ID']['input'];
  location_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBookingTaskResultFigmaPrototypeInput = {
  _booking_id: Scalars['ID']['input'];
  _task_id: Scalars['ID']['input'];
  _user_id: Scalars['ID']['input'];
  error_reason?: InputMaybe<Scalars['String']['input']>;
  status: BookingTaskResultStatus;
  task_ended?: InputMaybe<Scalars['Float']['input']>;
  task_loaded?: InputMaybe<Scalars['Float']['input']>;
  task_started?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateBookingTaskResultMultipleChoiceQuestionInput = {
  _booking_id: Scalars['ID']['input'];
  _task_id: Scalars['ID']['input'];
  _user_id: Scalars['ID']['input'];
  error_reason?: InputMaybe<Scalars['String']['input']>;
  status: BookingTaskResultStatus;
  task_ended?: InputMaybe<Scalars['Float']['input']>;
  task_loaded?: InputMaybe<Scalars['Float']['input']>;
  task_started?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateBookingTaskResultOpinionScaleInput = {
  _booking_id: Scalars['ID']['input'];
  _task_id: Scalars['ID']['input'];
  _user_id: Scalars['ID']['input'];
  error_reason?: InputMaybe<Scalars['String']['input']>;
  status: BookingTaskResultStatus;
  task_ended?: InputMaybe<Scalars['Float']['input']>;
  task_loaded?: InputMaybe<Scalars['Float']['input']>;
  task_started?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateBookingTaskResultPathItemInput = {
  _id: Scalars['ID']['input'];
  _result_id: Scalars['ID']['input'];
  node_id: Scalars['String']['input'];
};

export type CreateCollectionsInput = {
  _team_id: Scalars['ID']['input'];
};

export type CreateHighlightInput = {
  _id: Scalars['ID']['input'];
  _room_id: Scalars['ID']['input'];
  end_word: HighlightEndWordInput;
  meeting_code: Scalars['String']['input'];
  start_word: HighlightStartWordInput;
  tag_ids: Array<Scalars['ID']['input']>;
};

export type CreateInsightInput = {
  _team_id: Scalars['ID']['input'];
  blocks?: InputMaybe<Scalars['String']['input']>;
  sub_title?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRoomTokenInput = {
  video_code: Scalars['String']['input'];
};

export type CreateShareInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  type: ShareType;
  type_id: Scalars['ID']['input'];
};

export type CreateSystemBlockInput = {
  instructions: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateTagInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _team_id: Scalars['ID']['input'];
  color: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateTagsInput = {
  tags: Array<CreateTagInput>;
};

export type CreateTaskBlockFigmaPrototypeInput = {
  _id: Scalars['ObjectID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  task_block: CreateTaskBlockFigmaPrototypeValueInput;
};

export type CreateTaskBlockFigmaPrototypeValueInput = {
  figma_prototype?: InputMaybe<FigmaPrototypeFileInput>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  is_recording_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTaskBlockMultipleChoiceQuestionInput = {
  _id: Scalars['ObjectID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  task_block: CreateTaskBlockMultipleChoiceQuestionValueInput;
};

export type CreateTaskBlockMultipleChoiceQuestionValueInput = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  is_recording_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  multiple_choice_question?: InputMaybe<MultipleChoiceQuestionInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTaskBlockOpinionScaleInput = {
  _id: Scalars['ObjectID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  task_block: CreateTaskBlockOpinionScaleValueInput;
};

export type CreateTaskBlockOpinionScaleValueInput = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  is_recording_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  opinion_scale?: InputMaybe<OpinionScaleInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUnmodLiveKitRoom = {
  __typename?: 'CreateUnmodLiveKitRoom';
  access_token?: Maybe<Scalars['String']['output']>;
  room_sid?: Maybe<Scalars['String']['output']>;
};

export type CreateUnmodLiveKitRoomInput = {
  bookingId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['String']['input'];
};

export type CreateUnmoderatedBookingConfigInput = {
  unmoderated: CreateUnmoderatedBookingUnmoderatedConfigInput;
};

export type CreateUnmoderatedBookingInput = {
  config: CreateUnmoderatedBookingConfigInput;
  currency: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type CreateUnmoderatedBookingUnmoderatedConfigInput = {
  thank_you_block: CreateSystemBlockInput;
  welcome_block: CreateSystemBlockInput;
};

export type CreateUploadResponse = {
  __typename?: 'CreateUploadResponse';
  put_file_url: Scalars['String']['output'];
  upload: UploadType;
};

export type CreateUserSubmissionAnalysisInput = {
  _user_id: Scalars['ObjectID']['input'];
  /**  default = 10  */
  booking_limit?: InputMaybe<Scalars['Int']['input']>;
  prompt_template?: InputMaybe<CreateUserSubmissionAnalysisPromptInput>;
};

export type CreateUserSubmissionAnalysisPromptInput = {
  system?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type CreditActivity = {
  __typename?: 'CreditActivity';
  Booking?: Maybe<Booking>;
  BookingParticipant?: Maybe<BookingParticipant>;
  CreditPack?: Maybe<Credits>;
  FromTeam?: Maybe<Teams>;
  Team?: Maybe<Teams>;
  ToTeam?: Maybe<Teams>;
  Transaction?: Maybe<Transactions>;
  User?: Maybe<User>;
  _admin_user_id?: Maybe<Scalars['ID']['output']>;
  _booking_id?: Maybe<Scalars['ID']['output']>;
  _booking_participant_id?: Maybe<Scalars['ID']['output']>;
  _credit_option_id?: Maybe<Scalars['ID']['output']>;
  /**  Credit request linked to the credit activity  */
  _credit_request_id?: Maybe<Scalars['ID']['output']>;
  /**  Team that transfered the credits  */
  _from_team_id?: Maybe<Scalars['ID']['output']>;
  _id: Scalars['ID']['output'];
  _project_id?: Maybe<Scalars['ID']['output']>;
  _team_id: Scalars['ID']['output'];
  /**  Team getting the credits transfered to  */
  _to_team_id?: Maybe<Scalars['ID']['output']>;
  _transaction_id?: Maybe<Scalars['ID']['output']>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  /**  1 - Debit (Team balance goes down), 2 - Credit (Team balance goes up)  */
  accounting_type?: Maybe<Scalars['Int']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  /**  0: Askable admin manual credit adjustment / 1: Credit usage / 2: Credit purchase / 3: Credit refund (noshow) / 4: Credit refund (unfulfilled) / 5: Credit refund (Booking cancelled / rejected) / 6: Credit refund (Bad participant) / 7: Credit refund (Other) / 8: Booking requirement adjustment / 9: Free promotional credits / 10: Expring credits / 11: Credit Transfer / 12: Non-booking usage  */
  type?: Maybe<Scalars['Int']['output']>;
};

export type CreditActivityInput = {
  _admin_user_id?: InputMaybe<Scalars['ID']['input']>;
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _booking_participant_id?: InputMaybe<Scalars['ID']['input']>;
  _credit_option_id?: InputMaybe<Scalars['ID']['input']>;
  _credit_request_id?: InputMaybe<Scalars['ID']['input']>;
  _from_team_id?: InputMaybe<Scalars['ID']['input']>;
  _project_id?: InputMaybe<Scalars['ID']['input']>;
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  _to_team_id?: InputMaybe<Scalars['ID']['input']>;
  _transaction_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  /**  1 - Debit (Team balance goes down), 2 - Credit (Team balance goes up)  */
  accounting_type?: InputMaybe<Scalars['Int']['input']>;
  amount: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  /**  0: Askable admin manual credit adjustment / 1: Credit usage / 2: Credit purchase / 3: Credit refund (noshow) / 4: Credit refund (unfulfilled) / 5: Credit refund (Booking cancelled / rejected) / 6: Credit refund (Bad participant) / 7: Credit refund (Other) / 8: Booking requirement adjustment / 9: Free promotional credits / 10: Expring credits / 11: Credit Transfer  */
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type CreditActivitySearch = {
  types?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CreditRequestInput = {
  booking_subtype?: InputMaybe<Scalars['Int']['input']>;
  booking_type?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  participant_agreement_type?: InputMaybe<CreditRequestParticipantAgreement>;
  participant_category: Scalars['Int']['input'];
  remote_video_call_tool?: InputMaybe<Scalars['String']['input']>;
  session_type: Scalars['Int']['input'];
  total_participants: Scalars['Int']['input'];
};

export enum CreditRequestParticipantAgreement {
  Custom = 'custom',
  Standard = 'standard',
}

export type CreditRequests = {
  __typename?: 'CreditRequests';
  _id: Scalars['ID']['output'];
  _parent_team_id: Scalars['ID']['output'];
  _team_id: Scalars['ID']['output'];
  _user_id: Scalars['ID']['output'];
  /**  Amount of Credits to request  */
  amount?: Maybe<Scalars['Float']['output']>;
  context?: Maybe<CreditRequestsContext>;
  /**  Status of the request. 0: Requested 1: Approved 2: Refused  */
  status?: Maybe<Scalars['Int']['output']>;
};

export type CreditRequestsContext = {
  __typename?: 'CreditRequestsContext';
  /**  1 - Online Task, 2 - Survey  */
  booking_subtype?: Maybe<Scalars['Int']['output']>;
  booking_type?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  participant_category?: Maybe<Scalars['Int']['output']>;
  session_type?: Maybe<Scalars['Int']['output']>;
  total_participants?: Maybe<Scalars['Int']['output']>;
};

export type Credits = {
  __typename?: 'Credits';
  _id: Scalars['ID']['output'];
  /** @deprecated We started using a more complex logic structure to define which sound to play */
  dashboard_sound?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Array<Maybe<CreditsPriceType>>>;
  quantity?: Maybe<Scalars['Int']['output']>;
  /**  Credit option type. 1-Pack 2-Ad-hoc  */
  type?: Maybe<Scalars['Int']['output']>;
};

export type CreditsPriceType = {
  __typename?: 'CreditsPriceType';
  currency_code?: Maybe<Scalars['String']['output']>;
  currency_symbol?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CreditsPricingReplacement = {
  __typename?: 'CreditsPricingReplacement';
  template?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CriteriaBookingConfig = {
  __typename?: 'CriteriaBookingConfig';
  custom?: Maybe<Array<Maybe<CustomCriteria>>>;
  /** If exists & non-empty, only exclude participants who have been active on the listed bookings, regardless of exclude_participants_from_time setting */
  exclude_participants_from_bookings?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  /**
   *  Number of months to exclude participants from.
   * Options are: 3 months, 6 months, 12 months or Don't exclude.
   * It will exclude participants that have been selected for bookings within a team attached to the booking, however, it is possible to re-purpose this logic to exclude from ALL teams within an organisation.
   */
  exclude_participants_from_time?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use field exclude_participants_from_time */
  exclude_previous_participant?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated New field to specify the online links on config.online_task */
  external_url?: Maybe<Scalars['String']['output']>;
  /** Save demographical filters in the order they were added */
  filters_order?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  locations?: Maybe<LocationCriteria>;
  meta_children?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_education?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_education_field?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_family_children?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_family_income?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_family_status?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_home_owner?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_home_ownership?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_household_income?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_identity_birthday_year?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_identity_gender?: Maybe<Array<Maybe<MetaCriteria>>>;
  /** New demographical filters */
  meta_identity_languages?: Maybe<Array<Maybe<MetaCriteria>>>;
  /** @deprecated Use meta_identity_locales */
  meta_identity_languages_english_speak?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_identity_locales?: Maybe<Array<Maybe<CriteriaBookingConfigMetaIdentityLocales>>>;
  meta_individual_income?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_purchasing_behaviour?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_tech_savviness?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_work_employment_type?: Maybe<Array<Maybe<MetaCriteria>>>;
  meta_work_industry?: Maybe<Array<Maybe<CriteriaBookingConfigMetaWorkIndustry>>>;
  meta_work_status?: Maybe<Array<Maybe<MetaCriteria>>>;
  /** Participant must have access to the device types (match any) (1: Desktop, 2: Mobile) */
  required_device?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** (not in use yet) */
  required_features?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

/** Type representing multiple locale proficiency criteria in booking configurations */
export type CriteriaBookingConfigMetaIdentityLocales = {
  __typename?: 'CriteriaBookingConfigMetaIdentityLocales';
  field: Scalars['String']['output'];
  locale: Locale;
  operator: Scalars['String']['output'];
  value: Array<LocaleProficiency>;
};

export type CriteriaBookingConfigMetaWorkIndustry = {
  __typename?: 'CriteriaBookingConfigMetaWorkIndustry';
  _industry_id?: Maybe<Scalars['ID']['output']>;
  industry_name?: Maybe<Scalars['String']['output']>;
  subcategories?: Maybe<Array<Maybe<CriteriaBookingConfigMetaWorkIndustrySubcategories>>>;
};

export type CriteriaBookingConfigMetaWorkIndustrySubcategories = {
  __typename?: 'CriteriaBookingConfigMetaWorkIndustrySubcategories';
  _subcategory_id?: Maybe<Scalars['ID']['output']>;
  subcategory_name?: Maybe<Scalars['String']['output']>;
};

export type CurrencyData = {
  __typename?: 'CurrencyData';
  country_code: Scalars['String']['output'];
  currency_code: Scalars['String']['output'];
  currency_symbol: Scalars['String']['output'];
};

export type CustomBatchInput = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _user_ids: Array<InputMaybe<Scalars['ID']['input']>>;
  batch_tag?: InputMaybe<Scalars['String']['input']>;
  direction: Scalars['Int']['input'];
  template: Scalars['String']['input'];
};

export type CustomCriteria = {
  __typename?: 'CustomCriteria';
  _question_id?: Maybe<Scalars['ID']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Data = {
  __typename?: 'Data';
  _answer_id?: Maybe<Scalars['ID']['output']>;
  _booking_id?: Maybe<Scalars['ID']['output']>;
  _question_id?: Maybe<Scalars['ID']['output']>;
  option?: Maybe<OptionsQuestionBookingConfig>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DataInput = {
  _answer_id?: InputMaybe<Scalars['ID']['input']>;
  _question_id: Scalars['ID']['input'];
  prefilled_response?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAskableVideoRoomNoteInput = {
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type DeleteTagInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTaskBlockInput = {
  _id: Scalars['ObjectID']['input'];
  _task_block_id: Scalars['ObjectID']['input'];
};

export type DevicePermissions = {
  __typename?: 'DevicePermissions';
  camera: Scalars['Boolean']['output'];
  microphone: Scalars['Boolean']['output'];
  notifications: Scalars['Boolean']['output'];
  tracking: Scalars['Boolean']['output'];
};

export type DevicePermissionsInput = {
  camera: Scalars['Boolean']['input'];
  microphone: Scalars['Boolean']['input'];
  notifications: Scalars['Boolean']['input'];
  tracking: Scalars['Boolean']['input'];
};

export type ESignatureTemplate = {
  __typename?: 'ESignatureTemplate';
  AgreementBookings?: Maybe<Array<Maybe<Booking>>>;
  _id?: Maybe<Scalars['ID']['output']>;
  /**  (future spec)  */
  _organisation_id?: Maybe<Scalars['ID']['output']>;
  _team_id?: Maybe<Scalars['ID']['output']>;
  /**  Template is able to be selected on new bookings  */
  active?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**  Available on all bookings  */
  global?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<ESignatureTemplateProvider>;
  /**  Email addresses to receive copies of signed/rejected documents  */
  recipients?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**  1: Participant agreement  */
  type?: Maybe<Scalars['Int']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type ESignatureTemplateInput = {
  /**  (future spec)  */
  _organisation_id?: InputMaybe<Scalars['ID']['input']>;
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<ESignatureTemplateProviderInput>;
  /**  Email addresses to receive copies of signed/rejected documents  */
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type ESignatureTemplateProvider = {
  __typename?: 'ESignatureTemplateProvider';
  name?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
};

export type ESignatureTemplateProviderInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type EarningsReportRequestInput = {
  from_date: Scalars['String']['input'];
  to_date: Scalars['String']['input'];
};

export type EarningsReportResponse = {
  __typename?: 'EarningsReportResponse';
  email?: Maybe<Scalars['String']['output']>;
};

export type EducationInput = {
  apprenticeship?: InputMaybe<Scalars['Boolean']['input']>;
  highschool?: InputMaybe<Scalars['Boolean']['input']>;
  postgraduate?: InputMaybe<Scalars['Boolean']['input']>;
  some_university?: InputMaybe<Scalars['Boolean']['input']>;
  undergraduate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EligibilityDetail = {
  __typename?: 'EligibilityDetail';
  eligible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type FacebookInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FamilyInput = {
  children?: InputMaybe<MetaFamilyChildrenInput>;
  status?: InputMaybe<FamilyStatusInput>;
};

export type FamilyStatusInput = {
  divorced?: InputMaybe<Scalars['Boolean']['input']>;
  married?: InputMaybe<Scalars['Boolean']['input']>;
  relationship?: InputMaybe<Scalars['Boolean']['input']>;
  single?: InputMaybe<Scalars['Boolean']['input']>;
  widowed?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum FeatureFlag {
  AskableSessions = 'ASKABLE_SESSIONS',
  MobileOpportunities = 'MOBILE_OPPORTUNITIES',
  ParticipantsApp_2 = 'PARTICIPANTS_APP_2',
  PlaybackApp = 'PLAYBACK_APP',
  PlaybackTranscripts = 'PLAYBACK_TRANSCRIPTS',
  SegmentRoutes = 'SEGMENT_ROUTES',
  SessionsNotes = 'SESSIONS_NOTES',
}

export type FigmaFile = {
  __typename?: 'FigmaFile';
  _id: Scalars['ObjectID']['output'];
  _importer_user_id: Scalars['ObjectID']['output'];
  canvases?: Maybe<Array<FigmaFileCanvas>>;
  created: Scalars['Float']['output'];
  figma_last_modified: Scalars['Float']['output'];
  file_id: Scalars['String']['output'];
  frames?: Maybe<Array<FigmaFileFrame>>;
  link_is_public: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updated?: Maybe<Scalars['Float']['output']>;
};

export type FigmaFileCanvas = {
  __typename?: 'FigmaFileCanvas';
  flow_starting_point_node_ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  frame_node_ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  node_id: Scalars['String']['output'];
};

export type FigmaFileFrame = {
  __typename?: 'FigmaFileFrame';
  height?: Maybe<Scalars['Int']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  is_mobile?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  node_id: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type FigmaPrototypeFile = {
  __typename?: 'FigmaPrototypeFile';
  file_id?: Maybe<Scalars['String']['output']>;
  goal_screen_frame?: Maybe<FigmaFileFrame>;
  goal_screen_id?: Maybe<Scalars['String']['output']>;
  start_screen_frame?: Maybe<FigmaFileFrame>;
  start_screen_id?: Maybe<Scalars['String']['output']>;
};

export type FigmaPrototypeFileInput = {
  file_id?: InputMaybe<Scalars['String']['input']>;
  goal_screen_id?: InputMaybe<Scalars['String']['input']>;
  start_screen_id?: InputMaybe<Scalars['String']['input']>;
};

export type FigmaPrototypeResult = {
  __typename?: 'FigmaPrototypeResult';
  paths_completed: Array<FigmaPrototypeResultPath>;
  paths_skipped: Array<FigmaPrototypeResultPath>;
  screens: Array<FigmaPrototypeResultItem>;
  stats: FigmaPrototypeResultStats;
};

export type FigmaPrototypeResultItem = {
  __typename?: 'FigmaPrototypeResultItem';
  _id: Scalars['String']['output'];
  _path_item_id?: Maybe<Scalars['ObjectID']['output']>;
  average_duration: Scalars['Float']['output'];
  image?: Maybe<ScreenImage>;
  viewed_count: Scalars['Int']['output'];
};

export type FigmaPrototypeResultItemClick = {
  __typename?: 'FigmaPrototypeResultItemClick';
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export type FigmaPrototypeResultPath = {
  __typename?: 'FigmaPrototypeResultPath';
  _id: Scalars['ObjectID']['output'];
  average_duration: Scalars['Float']['output'];
  completed_count: Scalars['Int']['output'];
  misclick_rate: Scalars['Float']['output'];
  path_items: Array<FigmaPrototypeResultItem>;
  status: BookingTaskResultStatus;
};

export type FigmaPrototypeResultStats = {
  __typename?: 'FigmaPrototypeResultStats';
  average_duration: Scalars['Float']['output'];
  completed_count: Scalars['Int']['output'];
  misclick_rate: Scalars['Float']['output'];
  skipped_count: Scalars['Int']['output'];
  total_responses: Scalars['Int']['output'];
};

export type FilterBoolean = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FilterExistence = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FilterFloat = {
  gte?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type FilterId = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ne?: InputMaybe<Scalars['ID']['input']>;
};

export type FilterProjectStatus = {
  eq?: InputMaybe<ProjectStatus>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  ne?: InputMaybe<ProjectStatus>;
};

export type FilterStatusInt = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type FilterString = {
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ne?: InputMaybe<Scalars['String']['input']>;
};

export type FilterSubmissionStatus = {
  eq?: InputMaybe<SubmissionStatus>;
  in?: InputMaybe<Array<InputMaybe<SubmissionStatus>>>;
};

export type FilterTypeInt = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type GenericEmailInput = {
  /**  Automatiaclly append login details to the button_url / path (currently participant app only)  */
  button_link_auth?: InputMaybe<Scalars['Boolean']['input']>;
  /**  App path for the button - domain will be determied by the user type  */
  button_path?: InputMaybe<Scalars['String']['input']>;
  button_text?: InputMaybe<Scalars['String']['input']>;
  /**  Fully qualified URL for the button  */
  button_url?: InputMaybe<Scalars['String']['input']>;
  context_body?: InputMaybe<Scalars['String']['input']>;
  intro_line?: InputMaybe<Scalars['String']['input']>;
  message_body: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type GettingStartedInput = {
  demo?: InputMaybe<Scalars['Boolean']['input']>;
  phone_call?: InputMaybe<Scalars['Boolean']['input']>;
  testing_process?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoogleAddressComponent = {
  __typename?: 'GoogleAddressComponent';
  long_name?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GoogleAddressComponentInput = {
  long_name?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GoogleInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GoogleLocationGeometryInput = {
  height?: InputMaybe<Scalars['Float']['input']>;
  latitude_ne?: InputMaybe<Scalars['Float']['input']>;
  latitude_sw?: InputMaybe<Scalars['Float']['input']>;
  longitude_ne?: InputMaybe<Scalars['Float']['input']>;
  longitude_sw?: InputMaybe<Scalars['Float']['input']>;
  radius_max?: InputMaybe<Scalars['Float']['input']>;
  radius_min?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type GoogleLocationGeometryType = {
  __typename?: 'GoogleLocationGeometryType';
  /**  Distance between the North & South corners (KM)  */
  height?: Maybe<Scalars['Float']['output']>;
  latitude_ne?: Maybe<Scalars['Float']['output']>;
  latitude_sw?: Maybe<Scalars['Float']['output']>;
  longitude_ne?: Maybe<Scalars['Float']['output']>;
  longitude_sw?: Maybe<Scalars['Float']['output']>;
  /**  The distance between the midpoint and the farther corner (KM)  */
  radius_max?: Maybe<Scalars['Float']['output']>;
  /**  The distance between the midpoint and the closer corner (KM)  */
  radius_min?: Maybe<Scalars['Float']['output']>;
  /**  Distance between the East & West corners (KM)  */
  width?: Maybe<Scalars['Float']['output']>;
};

export type GoogleLocationInput = {
  address_components?: InputMaybe<Array<InputMaybe<GoogleAddressComponentInput>>>;
  geometry?: InputMaybe<GoogleLocationGeometryInput>;
  map_polygon_id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['String']['input']>;
  viewport?: InputMaybe<GoogleViewportInput>;
};

export type GoogleLocationType = {
  __typename?: 'GoogleLocationType';
  address_components?: Maybe<Array<Maybe<GoogleAddressComponent>>>;
  geometry?: Maybe<GoogleLocationGeometryType>;
  map_polygon_id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['String']['output']>;
  viewport?: Maybe<GoogleViewportType>;
};

export type GoogleViewportBoundsInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type GoogleViewportBoundsType = {
  __typename?: 'GoogleViewportBoundsType';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type GoogleViewportInput = {
  northeast?: InputMaybe<GoogleViewportBoundsInput>;
  southwest?: InputMaybe<GoogleViewportBoundsInput>;
};

export type GoogleViewportType = {
  __typename?: 'GoogleViewportType';
  northeast?: Maybe<GoogleViewportBoundsType>;
  southwest?: Maybe<GoogleViewportBoundsType>;
};

export type HighlightClipFilter = {
  status?: InputMaybe<HighlightClipStatusFilter>;
};

export type HighlightClipImageUrlsType = {
  __typename?: 'HighlightClipImageUrlsType';
  /**  original size, quality = 80  */
  lg?: Maybe<Scalars['String']['output']>;
  /**  width = 480, quality = 70  */
  md?: Maybe<Scalars['String']['output']>;
  /**  width = 240, quality = 50  */
  sm?: Maybe<Scalars['String']['output']>;
};

export enum HighlightClipStatus {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing',
}

export type HighlightClipStatusFilter = {
  in?: InputMaybe<Array<InputMaybe<HighlightClipStatus>>>;
};

export type HighlightClipType = {
  __typename?: 'HighlightClipType';
  image_urls?: Maybe<HighlightClipImageUrlsType>;
  /**  AWS Job ID for polling status  */
  job_id?: Maybe<Scalars['String']['output']>;
  status: HighlightClipStatus;
  url?: Maybe<Scalars['String']['output']>;
};

export type HighlightConnectionOrFilter = {
  _booking_id?: InputMaybe<FilterId>;
  _id?: InputMaybe<FilterId>;
  clip?: InputMaybe<HighlightClipFilter>;
  label?: InputMaybe<FilterString>;
  quote_preview?: InputMaybe<FilterString>;
  tag_ids?: InputMaybe<FilterId>;
};

export type HighlightEndWordInput = {
  end: Scalars['Float']['input'];
  start: Scalars['Float']['input'];
};

export type HighlightEndWordType = {
  __typename?: 'HighlightEndWordType';
  end: Scalars['Float']['output'];
  start: Scalars['Float']['output'];
};

export enum HighlightEventType {
  HighlightCreate = 'highlight_create',
  HighlightDelete = 'highlight_delete',
  HighlightUpdate = 'highlight_update',
}

export type HighlightQuoteType = {
  __typename?: 'HighlightQuoteType';
  _id: Scalars['ID']['output'];
  end?: Maybe<Scalars['Float']['output']>;
  locale?: Maybe<Locale>;
  speaker: AskableVideoTranscriptSpeakerType;
  start?: Maybe<Scalars['Float']['output']>;
  text: Scalars['String']['output'];
};

export type HighlightResponse = {
  __typename?: 'HighlightResponse';
  highlight?: Maybe<HighlightType>;
  meta?: Maybe<HighlightSubscriptionMeta>;
};

export type HighlightStartWordInput = {
  start: Scalars['Float']['input'];
};

export type HighlightStartWordType = {
  __typename?: 'HighlightStartWordType';
  start: Scalars['Float']['output'];
};

export type HighlightSubscriptionMeta = {
  __typename?: 'HighlightSubscriptionMeta';
  event: HighlightEventType;
  triggeredBy: Scalars['ID']['output'];
};

export type HighlightType = {
  __typename?: 'HighlightType';
  _booking_id: Scalars['ID']['output'];
  _id: Scalars['ID']['output'];
  _room_id: Scalars['ID']['output'];
  _team_id: Scalars['ID']['output'];
  _transcript_id: Scalars['ID']['output'];
  _user_id: Scalars['ID']['output'];
  clip?: Maybe<HighlightClipType>;
  composition_sid: Scalars['String']['output'];
  created: Scalars['Float']['output'];
  end_time: Scalars['Float']['output'];
  end_word: HighlightEndWordType;
  label: Scalars['String']['output'];
  meeting_code: Scalars['String']['output'];
  quote_preview: Scalars['String']['output'];
  quotes: Array<Maybe<HighlightQuoteType>>;
  start_time: Scalars['Float']['output'];
  start_word: HighlightStartWordType;
  tag_ids: Array<Scalars['ID']['output']>;
  tags: TagsConnection;
  updated: Scalars['Float']['output'];
};

export type HighlightTypeTagsArgs = {
  filter?: InputMaybe<TagsConnectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TagsConnectionSort>;
};

export type HighlightsConnection = {
  __typename?: 'HighlightsConnection';
  nodes?: Maybe<Array<Maybe<HighlightType>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type HighlightsConnectionFilter = {
  _booking_id?: InputMaybe<FilterId>;
  _id?: InputMaybe<FilterId>;
  _team_id?: InputMaybe<FilterId>;
  clip?: InputMaybe<HighlightClipFilter>;
  or?: InputMaybe<Array<InputMaybe<HighlightConnectionOrFilter>>>;
  tag_ids?: InputMaybe<FilterId>;
};

export type IdentityInput = {
  birthday?: InputMaybe<BirthdayInput>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<LanguagesInput>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  locales?: InputMaybe<LocalesInput>;
  middlename?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<NationalityInput>;
};

export type InContextBookingConfig = {
  __typename?: 'InContextBookingConfig';
  /** 1 - Particpant's home, 2 - Participant's workplace, 3 - Neutral place / doesn't matter */
  location_type?: Maybe<Scalars['Int']['output']>;
  /** Max time the client wishes to travel */
  travel_time_limit?: Maybe<Scalars['Int']['output']>;
};

export type InContextSubmission = {
  __typename?: 'InContextSubmission';
  location?: Maybe<Location>;
  location_additional?: Maybe<Scalars['String']['output']>;
  travel_distance?: Maybe<Scalars['Float']['output']>;
  travel_time?: Maybe<Scalars['Float']['output']>;
};

export type InContextSubmissionInput = {
  location?: InputMaybe<LocationInput>;
  location_additional?: InputMaybe<Scalars['String']['input']>;
  travel_distance?: InputMaybe<Scalars['Float']['input']>;
  travel_time?: InputMaybe<Scalars['Float']['input']>;
};

export type IncentiveBookingConfig = {
  __typename?: 'IncentiveBookingConfig';
  country_code?: Maybe<Scalars['CountryCode']['output']>;
  /** @deprecated Use field currency_symbol */
  currency?: Maybe<Scalars['String']['output']>;
  /** Currency code that relates to the country of the booking */
  currency_code?: Maybe<Scalars['String']['output']>;
  currency_symbol?: Maybe<Scalars['String']['output']>;
  provided_by_client?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated We don't use it anymore */
  type?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type IndustryType = {
  __typename?: 'IndustryType';
  _id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subcategories?: Maybe<Array<Maybe<IndustryType>>>;
};

export type InitializeUserInput = {
  type: UserType;
};

export type Insight = {
  __typename?: 'Insight';
  _id: Scalars['ID']['output'];
  /** @deprecated Fetch document data from the docs server. */
  blocks?: Maybe<Scalars['String']['output']>;
  by_line?: Maybe<Scalars['String']['output']>;
  /**  A short unique identifier  */
  code: Scalars['String']['output'];
  created_at: Scalars['Float']['output'];
  created_by?: Maybe<User>;
  created_by_id: Scalars['ID']['output'];
  doc_id?: Maybe<Scalars['ObjectID']['output']>;
  /** @deprecated Fetch document data from the docs server. */
  document?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<InsightIcon>;
  last_edited_by?: Maybe<User>;
  last_edited_by_id: Scalars['ID']['output'];
  published_at?: Maybe<Scalars['Float']['output']>;
  short_name: Scalars['String']['output'];
  status: InsightStatus;
  sub_title?: Maybe<Scalars['String']['output']>;
  team_ids: Array<Scalars['ID']['output']>;
  teams?: Maybe<Array<Maybe<Teams>>>;
  theme: InsightTheme;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Array<Maybe<View>>>;
};

export type InsightIcon = {
  __typename?: 'InsightIcon';
  icon: Scalars['String']['output'];
  type: InsightIconTypes;
};

export type InsightIconInput = {
  icon: Scalars['String']['input'];
  type: InsightIconTypes;
};

export enum InsightIconTypes {
  Custom = 'custom',
  Emoji = 'emoji',
  Icon = 'icon',
}

export enum InsightStatus {
  Draft = 'draft',
  Published = 'published',
}

export type InsightTheme = {
  __typename?: 'InsightTheme';
  primary_color: Scalars['String']['output'];
  secondary_color: Scalars['String']['output'];
  title_theme: InsightTitleTheme;
};

export enum InsightTitleTheme {
  Dark = 'dark',
  Light = 'light',
}

export type InsightsConnection = {
  __typename?: 'InsightsConnection';
  nodes?: Maybe<Array<Maybe<Insight>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InsightsFilter = {
  created_by?: InputMaybe<FilterId>;
  team_ids?: InputMaybe<FilterId>;
  title?: InputMaybe<FilterString>;
};

export type InsightsOrderBy = {
  created_at: Sort;
};

export type InviteSubmissionInput = {
  _submission_id: Scalars['ID']['input'];
  sessionOrTaskId: Scalars['ID']['input'];
};

export type InvoiceTransactionsType = {
  __typename?: 'InvoiceTransactionsType';
  amount_due?: Maybe<Scalars['Float']['output']>;
  /**  $ per credit  */
  credit_amount?: Maybe<Scalars['Float']['output']>;
  /**  Number of credits  */
  credit_quantity?: Maybe<Scalars['Int']['output']>;
  /**  Invoice number  */
  number?: Maybe<Scalars['String']['output']>;
  purchase_order?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  /**  Invoice ID stored on Xero  */
  xero_id?: Maybe<Scalars['String']['output']>;
};

export type InvoiceUrlType = {
  __typename?: 'InvoiceURLType';
  /** @deprecated Use field onlineInvoiceUrl */
  OnlineInvoiceUrl?: Maybe<Scalars['String']['output']>;
  /**  New invoice URL spec from Xero  */
  onlineInvoiceUrl?: Maybe<Scalars['String']['output']>;
};

export type JoinRoom = {
  __typename?: 'JoinRoom';
  room_sid: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type JoinTeamInput = {
  _id: Scalars['ID']['input'];
};

export type JoinUnmodLiveKitRoom = {
  __typename?: 'JoinUnmodLiveKitRoom';
  access_token?: Maybe<Scalars['String']['output']>;
  room_sid?: Maybe<Scalars['String']['output']>;
};

export type JoinUnmodLiveKitRoomInput = {
  code: Scalars['String']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
};

export enum KindeSsoStrategy {
  Oauth2AzureAd = 'oauth2_azure_ad',
  SamlCustom = 'saml_custom',
  WsfedAzureAd = 'wsfed_azure_ad',
}

export type LanguagesInput = {
  english?: InputMaybe<LanguagesLanguageInput>;
};

export type LanguagesLanguageInput = {
  speak?: InputMaybe<LanguagesProficiencyInput>;
};

export type LanguagesProficiencyInput = {
  beginner?: InputMaybe<Scalars['Boolean']['input']>;
  conversational?: InputMaybe<Scalars['Boolean']['input']>;
  fluent?: InputMaybe<Scalars['Boolean']['input']>;
  native?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LastUsedSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type LinkOnlineTaskBookingConfig = {
  __typename?: 'LinkOnlineTaskBookingConfig';
  _id?: Maybe<Scalars['ID']['output']>;
  checked?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LinkedinInput = {
  profile_url?: InputMaybe<Scalars['String']['input']>;
};

export type LiveTranscriptionToken = {
  __typename?: 'LiveTranscriptionToken';
  expiresAt?: Maybe<Scalars['Float']['output']>;
  provider: VideoTranscriptProviderName;
  /** Key secret */
  tokenValue?: Maybe<Scalars['String']['output']>;
};

export enum Locale {
  Ar = 'ar',
  Da = 'da',
  De = 'de',
  En = 'en',
  EnAu = 'en_AU',
  EnGb = 'en_GB',
  EnUs = 'en_US',
  Es = 'es',
  Fr = 'fr',
  Hi = 'hi',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Nl = 'nl',
  Pt = 'pt',
  Tr = 'tr',
  ZhCn = 'zh_CN',
  ZhHk = 'zh_HK',
}

/** Locale proficiency value used for booking and participant */
export enum LocaleProficiency {
  Beginner = 'beginner',
  Conversational = 'conversational',
  Fluent = 'fluent',
  Native = 'native',
}

export type LocalesInput = {
  ar?: InputMaybe<LocaleProficiency>;
  da?: InputMaybe<LocaleProficiency>;
  de?: InputMaybe<LocaleProficiency>;
  en?: InputMaybe<LocaleProficiency>;
  en_AU?: InputMaybe<LocaleProficiency>;
  en_GB?: InputMaybe<LocaleProficiency>;
  en_US?: InputMaybe<LocaleProficiency>;
  es?: InputMaybe<LocaleProficiency>;
  fr?: InputMaybe<LocaleProficiency>;
  hi?: InputMaybe<LocaleProficiency>;
  it?: InputMaybe<LocaleProficiency>;
  ja?: InputMaybe<LocaleProficiency>;
  ko?: InputMaybe<LocaleProficiency>;
  nl?: InputMaybe<LocaleProficiency>;
  pt?: InputMaybe<LocaleProficiency>;
  tr?: InputMaybe<LocaleProficiency>;
  zh_CN?: InputMaybe<LocaleProficiency>;
  zh_HK?: InputMaybe<LocaleProficiency>;
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['CountryCode']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  currency_code?: Maybe<Scalars['String']['output']>;
  currency_symbol?: Maybe<Scalars['String']['output']>;
  /** The full text address of the location */
  formatted_address?: Maybe<Scalars['String']['output']>;
  google_location?: Maybe<GoogleLocationType>;
  /** Location types as defined by Google: https://developers.google.com/maps/documentation/geocoding/intro#Types */
  google_location_types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  latitude?: Maybe<Scalars['Float']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use field postal_code */
  postcode?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type LocationAutoCompleteResult = {
  __typename?: 'LocationAutoCompleteResult';
  description?: Maybe<Scalars['String']['output']>;
  descriptionMatches?: Maybe<Array<Maybe<LocationAutoCompleteResultMatch>>>;
  placeId?: Maybe<Scalars['String']['output']>;
  sessionToken?: Maybe<Scalars['String']['output']>;
};

export type LocationAutoCompleteResultMatch = {
  __typename?: 'LocationAutoCompleteResultMatch';
  match?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type LocationAutoCompleteSearch = {
  /**  If true, the search will be biased towards the specified radius, latitude and longitude.  */
  biasLocation?: InputMaybe<Scalars['Boolean']['input']>;
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  country?: InputMaybe<Scalars['String']['input']>;
  input: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  searchRadius?: InputMaybe<Scalars['Int']['input']>;
  searchRadiusStrict?: InputMaybe<Scalars['Boolean']['input']>;
  sessionToken?: InputMaybe<Scalars['String']['input']>;
  /**  geocode, address, establishment, (regions), (cities) - documented here: https://developers.google.com/places/web-service/autocomplete#place_types  */
  types?: InputMaybe<Scalars['String']['input']>;
};

export type LocationCriteria = {
  __typename?: 'LocationCriteria';
  ParticipantIsEligible?: Maybe<Scalars['Boolean']['output']>;
  bounds?: Maybe<Array<Maybe<Location>>>;
  countries?: Maybe<Array<Maybe<Location>>>;
  states?: Maybe<Array<Maybe<Location>>>;
};

export type LocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['Float']['input']>;
  formatted_address?: InputMaybe<Scalars['String']['input']>;
  google_location?: InputMaybe<GoogleLocationInput>;
  google_location_types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1?: InputMaybe<Scalars['String']['input']>;
  street2?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type LogEntry = {
  __typename?: 'LogEntry';
  User?: Maybe<User>;
  _booking_id?: Maybe<Scalars['ID']['output']>;
  _company_id?: Maybe<Scalars['ID']['output']>;
  _id: Scalars['ID']['output'];
  _project_id?: Maybe<Scalars['ID']['output']>;
  _team_id?: Maybe<Scalars['ID']['output']>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type LogSearchInput = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['Float']['input']>;
  before?: InputMaybe<Scalars['Float']['input']>;
  events?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type LoginAsClientType = {
  __typename?: 'LoginAsClientType';
  teams?: Maybe<Array<Maybe<Teams>>>;
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type LoginAsResearcherType = {
  __typename?: 'LoginAsResearcherType';
  url?: Maybe<Scalars['String']['output']>;
};

export type LongitudinalExtraConfig = {
  __typename?: 'LongitudinalExtraConfig';
  description?: Maybe<Scalars['String']['output']>;
  /** 1: Minutes, 2: Hours */
  measure?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

export type LongitudinalParticipantWorkloadConfig = {
  __typename?: 'LongitudinalParticipantWorkloadConfig';
  /** 1: Days, 2: Weeks, 3: Months */
  frequency?: Maybe<Scalars['Int']['output']>;
  /** 1: Minutes, 2: Hours */
  measure?: Maybe<Scalars['Int']['output']>;
  /** Time measure to be used in conjunction with 'measure' and 'frequency' */
  time?: Maybe<Scalars['Int']['output']>;
};

export type LongitudinalPeriodConfig = {
  __typename?: 'LongitudinalPeriodConfig';
  /** 1: Days, 2: Weeks, 3: Months */
  frequency?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

export type LongitudinalStudyBookingConfig = {
  __typename?: 'LongitudinalStudyBookingConfig';
  extra?: Maybe<Array<Maybe<LongitudinalExtraConfig>>>;
  participant_workload?: Maybe<LongitudinalParticipantWorkloadConfig>;
  period?: Maybe<LongitudinalPeriodConfig>;
};

export type MergeTagsInput = {
  destinationTagId: Scalars['ID']['input'];
  targetTagId: Scalars['ID']['input'];
};

export type MessageProviderType = {
  __typename?: 'MessageProviderType';
  /**  SMS provider  */
  name?: Maybe<Scalars['String']['output']>;
  /**  3rd-party identifier (SID, UUID, etc)  */
  reference?: Maybe<Scalars['String']['output']>;
  /**  ID of the particular service to use (ie. Plivo phone number) depending on country etc  */
  service?: Maybe<Scalars['String']['output']>;
};

export type MessageSearchInput = {
  _batch_id?: InputMaybe<Scalars['ID']['input']>;
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _booking_participant_id?: InputMaybe<Scalars['ID']['input']>;
  _from_user_id?: InputMaybe<Scalars['ID']['input']>;
  _live_demo_room_id?: InputMaybe<Scalars['ID']['input']>;
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  _to_user_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  askable_video_code?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['Int']['input']>;
  /**  Hide batch messages on message drawer  */
  hide_batch_messages?: InputMaybe<Scalars['Boolean']['input']>;
  /**  Default 30  */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /**  Default 0  */
  skip?: InputMaybe<Scalars['Int']['input']>;
  /**  Direction to sort by created (Default: -1)  */
  sort?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type Messages = {
  __typename?: 'Messages';
  ShortUrls?: Maybe<Array<Maybe<ShortUrl>>>;
  Submission?: Maybe<Submission>;
  UserFrom?: Maybe<User>;
  UserTo?: Maybe<User>;
  /**  User sending message  */
  _from_user_id?: Maybe<Scalars['ID']['output']>;
  _id: Scalars['ID']['output'];
  /**  User receiving message  */
  _to_user_id?: Maybe<Scalars['ID']['output']>;
  /**  Message being sent  */
  body?: Maybe<Scalars['String']['output']>;
  context?: Maybe<MessagesContextType>;
  created?: Maybe<Scalars['Float']['output']>;
  /**
   * The direction category:
   *     1: Askable → Participant
   *     2: Askable → Client
   *     3: Client → Participant
   *     4: Participant → Client
   *     5: Askable → Other
   *     6: Participant → Askable
   *     0: Other / Unknown (legacy only)
   */
  direction?: Maybe<Scalars['Int']['output']>;
  /**  (True or null) indicate that the message was migrated from old spec  */
  legacy?: Maybe<Scalars['Boolean']['output']>;
  /**  Timestamp or null when the recipient saw the message  */
  seen?: Maybe<Scalars['Float']['output']>;
  sms?: Maybe<MessagesSmsType>;
  /**  The template / subtype of message (eg. EARLY_REMINDER_1 in the cron)  */
  tag?: Maybe<Scalars['String']['output']>;
  /**
   * The category of message, ie batch, in-app, hotline, reminder, SMS verification
   *     1: IN_APP
   *     2: SYSTEM
   *     3: BATCH
   *     4: SMS_VERIFICATION
   *     5: ADMIN
   */
  type?: Maybe<Scalars['Int']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type MessagesShortUrlsArgs = {
  event?: InputMaybe<Scalars['String']['input']>;
};

export type MessagesConnection = {
  __typename?: 'MessagesConnection';
  nodes: Array<Maybe<Messages>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MessagesContextInput = {
  _batch_id?: InputMaybe<Scalars['ID']['input']>;
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _booking_participant_id?: InputMaybe<Scalars['ID']['input']>;
  _booking_submission_id?: InputMaybe<Scalars['ID']['input']>;
  _live_demo_room_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  askable_video_code?: InputMaybe<Scalars['String']['input']>;
};

export type MessagesContextType = {
  __typename?: 'MessagesContextType';
  /**  Batch ID (generated at a batch send, not a reference to another collection, used for grouping in stats)  */
  _batch_id?: Maybe<Scalars['ID']['output']>;
  _booking_id?: Maybe<Scalars['ID']['output']>;
  _booking_participant_id?: Maybe<Scalars['ID']['output']>;
  _booking_submission_id?: Maybe<Scalars['ID']['output']>;
  /**  The Room ID for an Askable Live demo  */
  _live_demo_room_id?: Maybe<Scalars['ID']['output']>;
  _project_id?: Maybe<Scalars['ID']['output']>;
  /**  Refers specifically to the non-Askable user (recipient or sender)  */
  _user_id?: Maybe<Scalars['ID']['output']>;
  askable_video_code?: Maybe<Scalars['String']['output']>;
};

export type MessagesFilter = {
  direction?: InputMaybe<FilterStatusInt>;
  seen?: InputMaybe<FilterExistence>;
  type?: InputMaybe<FilterStatusInt>;
};

export type MessagesInput = {
  _from_user_id?: InputMaybe<Scalars['ID']['input']>;
  _to_user_id?: InputMaybe<Scalars['ID']['input']>;
  body: Scalars['String']['input'];
  context?: InputMaybe<MessagesContextInput>;
  direction: Scalars['Int']['input'];
  seen?: InputMaybe<Scalars['Float']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
  sms_to?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['Int']['input'];
};

export type MessagesSmsType = {
  __typename?: 'MessagesSmsType';
  body?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<MessageProviderType>;
  /**  0 - Failed, 1 - Queued, 2 - Sent, 3 - Delivered  */
  status?: Maybe<Scalars['Int']['output']>;
  /**  E.164 phone number  */
  to?: Maybe<Scalars['String']['output']>;
};

export type MetaAcquisitionInput = {
  _referred_by?: InputMaybe<Scalars['ID']['input']>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type MetaCriteria = {
  __typename?: 'MetaCriteria';
  field?: Maybe<Scalars['String']['output']>;
  operator?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MetaEducationFieldInput = {
  accounting?: InputMaybe<Scalars['Boolean']['input']>;
  aeronautics?: InputMaybe<Scalars['Boolean']['input']>;
  anthropology?: InputMaybe<Scalars['Boolean']['input']>;
  art?: InputMaybe<Scalars['Boolean']['input']>;
  business?: InputMaybe<Scalars['Boolean']['input']>;
  chemistry?: InputMaybe<Scalars['Boolean']['input']>;
  computing?: InputMaybe<Scalars['Boolean']['input']>;
  economics?: InputMaybe<Scalars['Boolean']['input']>;
  education?: InputMaybe<Scalars['Boolean']['input']>;
  engineering?: InputMaybe<Scalars['Boolean']['input']>;
  environmental_science?: InputMaybe<Scalars['Boolean']['input']>;
  geography?: InputMaybe<Scalars['Boolean']['input']>;
  history?: InputMaybe<Scalars['Boolean']['input']>;
  humanities?: InputMaybe<Scalars['Boolean']['input']>;
  information_technology?: InputMaybe<Scalars['Boolean']['input']>;
  international_relations?: InputMaybe<Scalars['Boolean']['input']>;
  journalism?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Scalars['Boolean']['input']>;
  law?: InputMaybe<Scalars['Boolean']['input']>;
  literature?: InputMaybe<Scalars['Boolean']['input']>;
  medicine?: InputMaybe<Scalars['Boolean']['input']>;
  music?: InputMaybe<Scalars['Boolean']['input']>;
  nursing?: InputMaybe<Scalars['Boolean']['input']>;
  oceanography?: InputMaybe<Scalars['Boolean']['input']>;
  pharmacy?: InputMaybe<Scalars['Boolean']['input']>;
  photography?: InputMaybe<Scalars['Boolean']['input']>;
  psychology?: InputMaybe<Scalars['Boolean']['input']>;
  public_relations?: InputMaybe<Scalars['Boolean']['input']>;
  sociology?: InputMaybe<Scalars['Boolean']['input']>;
  technology?: InputMaybe<Scalars['Boolean']['input']>;
  tourism?: InputMaybe<Scalars['Boolean']['input']>;
  zoology?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaFamilyChildrenInput = {
  five?: InputMaybe<Scalars['Boolean']['input']>;
  four?: InputMaybe<Scalars['Boolean']['input']>;
  more_than_five?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Scalars['Boolean']['input']>;
  one?: InputMaybe<Scalars['Boolean']['input']>;
  three?: InputMaybe<Scalars['Boolean']['input']>;
  two?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaHomeInput = {
  owner?: InputMaybe<MetaHomeOwnerInput>;
};

export type MetaHomeOwnerInput = {
  financed?: InputMaybe<Scalars['Boolean']['input']>;
  no?: InputMaybe<Scalars['Boolean']['input']>;
  yes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaHouseholdIncomeInput = {
  between_50k_100k?: InputMaybe<Scalars['Boolean']['input']>;
  between_100k_150k?: InputMaybe<Scalars['Boolean']['input']>;
  between_150k_200k?: InputMaybe<Scalars['Boolean']['input']>;
  between_200k_250k?: InputMaybe<Scalars['Boolean']['input']>;
  less_than_50k?: InputMaybe<Scalars['Boolean']['input']>;
  more_than_250k?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaIncomeInput = {
  household?: InputMaybe<MetaHouseholdIncomeInput>;
  individual?: InputMaybe<MetaIndividualIncomeInput>;
};

export type MetaIndividualIncomeInput = {
  between_30k_60k?: InputMaybe<Scalars['Boolean']['input']>;
  between_60k_90k?: InputMaybe<Scalars['Boolean']['input']>;
  between_90k_120k?: InputMaybe<Scalars['Boolean']['input']>;
  between_120k_150k?: InputMaybe<Scalars['Boolean']['input']>;
  less_than_30k?: InputMaybe<Scalars['Boolean']['input']>;
  more_than_150k?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaInput = {
  acquisition?: InputMaybe<MetaAcquisitionInput>;
  beta?: InputMaybe<BetaInput>;
  education?: InputMaybe<EducationInput>;
  education_field?: InputMaybe<MetaEducationFieldInput>;
  family?: InputMaybe<FamilyInput>;
  hasPromptedForAppReview?: InputMaybe<Scalars['Boolean']['input']>;
  home?: InputMaybe<MetaHomeInput>;
  identity?: InputMaybe<IdentityInput>;
  income?: InputMaybe<MetaIncomeInput>;
  purchasing_behaviour?: InputMaybe<MetaPurchasingBehaviourInput>;
  social?: InputMaybe<SocialInput>;
  tech_savviness?: InputMaybe<MetaTechSavvinessInput>;
  work?: InputMaybe<MetaWorkInput>;
};

export type MetaPurchasingBehaviourInput = {
  less_than_two_times_month?: InputMaybe<Scalars['Boolean']['input']>;
  more_than_three_times_week?: InputMaybe<Scalars['Boolean']['input']>;
  once_week?: InputMaybe<Scalars['Boolean']['input']>;
  three_times_month?: InputMaybe<Scalars['Boolean']['input']>;
  three_times_week?: InputMaybe<Scalars['Boolean']['input']>;
  two_times_month?: InputMaybe<Scalars['Boolean']['input']>;
  two_times_week?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaTechSavvinessInput = {
  basic?: InputMaybe<Scalars['Boolean']['input']>;
  expert?: InputMaybe<Scalars['Boolean']['input']>;
  intermediate?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Scalars['Boolean']['input']>;
  professional?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetaWorkInput = {
  _industry_id?: InputMaybe<Scalars['ID']['input']>;
  _subindustry_id?: InputMaybe<Scalars['ID']['input']>;
  business?: InputMaybe<UserMetaWorkBusinessInput>;
  employment_type?: InputMaybe<Scalars['Int']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MetaWorkStatusInput>;
};

export type MetaWorkStatusInput = {
  fulltime?: InputMaybe<Scalars['Boolean']['input']>;
  fulltime_student?: InputMaybe<Scalars['Boolean']['input']>;
  homeduties?: InputMaybe<Scalars['Boolean']['input']>;
  parttime?: InputMaybe<Scalars['Boolean']['input']>;
  parttime_student?: InputMaybe<Scalars['Boolean']['input']>;
  retired?: InputMaybe<Scalars['Boolean']['input']>;
  unemployed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MultipleChoiceQuestion = {
  __typename?: 'MultipleChoiceQuestion';
  has_other_option: Scalars['Boolean']['output'];
  is_multiple_select: Scalars['Boolean']['output'];
  is_randomised_order: Scalars['Boolean']['output'];
  options: Array<MultipleChoiceQuestionOption>;
};

export type MultipleChoiceQuestionInput = {
  has_other_option: Scalars['Boolean']['input'];
  is_multiple_select: Scalars['Boolean']['input'];
  is_randomised_order: Scalars['Boolean']['input'];
  options: Array<MultipleChoiceQuestionOptionInput>;
};

export type MultipleChoiceQuestionOption = {
  __typename?: 'MultipleChoiceQuestionOption';
  _id: Scalars['ObjectID']['output'];
  value: Scalars['String']['output'];
};

export type MultipleChoiceQuestionOptionInput = {
  _id: Scalars['ObjectID']['input'];
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  TMP_updateBookingRecruit: Booking;
  /** Get _user_id & created date from context */
  addAdminComment?: Maybe<Booking>;
  addAdminProjectNote?: Maybe<Project>;
  addResearcher: Project;
  adminLoginAsClient?: Maybe<LoginAsClientType>;
  adminLoginAsResearcher?: Maybe<LoginAsResearcherType>;
  adminSaveWig?: Maybe<AdminWig>;
  adminUpdateBooking?: Maybe<Booking>;
  applyDeclineAskablePlusProject?: Maybe<Project>;
  approveBooking?: Maybe<Booking>;
  approveJoinRequest: VideoUser;
  archiveBooking?: Maybe<Booking>;
  archiveProject?: Maybe<Project>;
  askableLiveNetworkTestRoomSetup?: Maybe<Array<Maybe<TwilioVideoType>>>;
  assignOrganisationToTeam?: Maybe<Teams>;
  bookingRequirementAdjustment?: Maybe<Booking>;
  /** Mutation for clients to bulk invite participants for a booking */
  bulkInvite?: Maybe<Array<Maybe<Submission>>>;
  cancelInvite?: Maybe<Submission>;
  /** Mutation to cancel a session. Old mutation: cancelBookingParticipant */
  cancelParticipantSession?: Maybe<SessionsType>;
  /** Usually this will only be an observer */
  cancelRequestToJoinRoom?: Maybe<AskableVideoUsersType>;
  /** @deprecated Use deleteSession */
  cancelSession?: Maybe<Booking>;
  changeFacilitatorContact?: Maybe<Booking>;
  changeOrganiserContact?: Maybe<Booking>;
  changeStatus?: Maybe<RootSchema>;
  changeUserRoleOnTeam?: Maybe<Teams>;
  changeUserStatusOnTeam?: Maybe<Teams>;
  completeOrderBooking?: Maybe<Booking>;
  completeProject: Project;
  /** Mutation to complete a survey */
  completeSurvey?: Maybe<Submission>;
  createAiModLiveKitRoom?: Maybe<CreateAiModeratedLiveKitRoom>;
  createAiModeratedLiveKitRoom?: Maybe<CreateAiModeratedLiveKitRoom>;
  createAskableVideoMeeting?: Maybe<AskableVideo>;
  createAskableVideoRoomNote?: Maybe<AskableVideoRoomNote>;
  createBatch?: Maybe<Scalars['Int']['output']>;
  createBatchMessages?: Maybe<Array<Maybe<Messages>>>;
  createBooking?: Maybe<Booking>;
  /** Mutation used to save the participant submission */
  createBookingSubmission?: Maybe<Submission>;
  createBookingTaskEventClick: BookingTaskEventClick;
  createBookingTaskEventLoad: BookingTaskEventLoad;
  createBookingTaskResultFigmaPrototype: BookingTaskResultFigmaPrototype;
  createBookingTaskResultMultipleChoiceQuestion: BookingTaskResultMultipleChoiceQuestion;
  createBookingTaskResultOpinionScale: BookingTaskResultOpinionScale;
  createBookingTaskResultPathItem: BookingTaskResultFigmaPrototype;
  createBulkQuestions?: Maybe<Booking>;
  createCollection?: Maybe<Collections>;
  createCoupon?: Maybe<Coupon>;
  createCreditRequest?: Maybe<CreditRequests>;
  createCustomBatch?: Maybe<Array<Maybe<Messages>>>;
  createESignatureTemplate?: Maybe<ESignatureTemplate>;
  createHighlight?: Maybe<HighlightType>;
  createInsight?: Maybe<Insight>;
  createMessage?: Maybe<Messages>;
  createOnlineTaskLink?: Maybe<Booking>;
  createProject?: Maybe<Project>;
  createProjectQuestion?: Maybe<Project>;
  createQuestion?: Maybe<Booking>;
  createRoomToken: RoomToken;
  createSession?: Maybe<Booking>;
  createSessionsLiveBooking?: Maybe<Booking>;
  createShare?: Maybe<Share>;
  createShares?: Maybe<Array<Maybe<Share>>>;
  createSubmissionSession?: Maybe<Booking>;
  createTags?: Maybe<Array<Maybe<TagType>>>;
  createTaskBlockFigmaPrototype: Booking;
  createTaskBlockMultipleChoiceQuestion: Booking;
  createTaskBlockOpinionScale: Booking;
  createUnmodLiveKitRoom?: Maybe<CreateUnmodLiveKitRoom>;
  createUnmodVideo?: Maybe<AskableVideo>;
  createUnmoderatedBooking: Booking;
  createUpload: CreateUploadResponse;
  createUserSubmissionAnalysis: UserSubmissionAnalysis;
  creditAdjustment?: Maybe<CreditActivity>;
  creditRequestChangeStatus?: Maybe<CreditRequests>;
  creditTransfer?: Maybe<Array<Maybe<CreditActivity>>>;
  declineInvite?: Maybe<Submission>;
  deleteAskableVideoRoomNote?: Maybe<AskableVideoRoomNote>;
  deleteCollection?: Maybe<Collections>;
  deleteInsight?: Maybe<Insight>;
  deleteMe?: Maybe<User>;
  deleteOnlineTaskLink?: Maybe<Booking>;
  deleteProfilePicture: User;
  deleteProjectQuestion?: Maybe<Project>;
  deleteQuestion?: Maybe<Booking>;
  deleteSegment?: Maybe<Scalars['String']['output']>;
  deleteSession?: Maybe<Booking>;
  deleteTag?: Maybe<TagType>;
  deleteTaskBlock: Booking;
  deleteTeamCreditCard?: Maybe<Teams>;
  deleteUser?: Maybe<User>;
  denyJoinRequest: VideoUser;
  downloadInvoice?: Maybe<InvoiceUrlType>;
  duplicateBooking?: Maybe<Booking>;
  duplicateProject?: Maybe<Project>;
  duplicateProjectQuestion?: Maybe<Project>;
  duplicateQuestion?: Maybe<Booking>;
  duplicateUnmoderatedBooking: Booking;
  emailMyEarningsReport?: Maybe<EarningsReportResponse>;
  endRoom: Scalars['Boolean']['output'];
  extendBooking?: Maybe<Booking>;
  figmaOauthCallback?: Maybe<UserFigmaIntegration>;
  forceCloseBooking?: Maybe<Booking>;
  generateShortUrl: Scalars['String']['output'];
  initializeUser: User;
  /**
   * Mutation to invite a participant to a session. Old mutation: inviteBookingParticipant
   * @deprecated It doesnt take into account sessions the participant hasnt applied for. We should use the new inviteParticipant mutation
   */
  inviteParticipantSession?: Maybe<SessionsType>;
  inviteResearcherToProject?: Maybe<Project>;
  inviteToJoinTeam?: Maybe<Teams>;
  /** Method used by clients to save participants availability. */
  inviteWaitlisted?: Maybe<Array<Maybe<Submission>>>;
  joinRoom: JoinRoom;
  joinTeam?: Maybe<Teams>;
  joinUnmodLiveKitRoom?: Maybe<JoinUnmodLiveKitRoom>;
  loadFigmaImages?: Maybe<FigmaFile>;
  login?: Maybe<User>;
  markAllNotificationsAsReadByTeam?: Maybe<Array<Maybe<Notifications>>>;
  markBookingMissionCritical?: Maybe<Scalars['String']['output']>;
  markMultipleMessagesAsSeen?: Maybe<Array<Maybe<Messages>>>;
  markNotificationAsRead?: Maybe<Notifications>;
  /** Mutation for clients to mark their participant as a no-show. Old mutation: markBookingParticipantNoShow */
  markParticipantNoShow?: Maybe<SessionsType>;
  /** Mutation for clients to mark a participant as completed on a booking. Used on longitudinal studies. Old mutation: markBookingParticipantAsComplete */
  markParticipantSessionAsComplete?: Maybe<SessionsType>;
  /** Mutation for clients to ask for a replacement/refund of their participants. Old mutation: markBookingParticipantIssue */
  markParticipantSessionIssue?: Maybe<SessionsType>;
  markProjectMissionCritical?: Maybe<Scalars['String']['output']>;
  maybeCreateKindeOrganization?: Maybe<Teams>;
  mergeTags?: Maybe<TagType>;
  moveBookingToAnotherTeam?: Maybe<Booking>;
  newTeam?: Maybe<Teams>;
  notifyChangesOnRemoteTool?: Maybe<Booking>;
  notifyChangesOnSessionDuration?: Maybe<Booking>;
  onboardResearcher?: Maybe<User>;
  participantAbandonUnmoderated: Submission;
  /** Mutation to set a cancellation/no show reason. Old mutation: participantCancelReason */
  participantCancelReason?: Maybe<SessionsType>;
  /** Mutation to cancel a participant's session. Old mutation: participantCancelBookingParticipant */
  participantCancelSession?: Maybe<SessionsType>;
  /** Mutation to cancel a waitlisted session. Old mutation: participantUnwaitlistBookingParticipant */
  participantCancelWaitlistedSession?: Maybe<SessionsType>;
  /** The same as participantCompleteQuant, but takes a Booking ID and completes the 'in progress' session instead of Supplying the Session ID */
  participantCompleteInProgressUnmoderated?: Maybe<SessionsType>;
  /** Mutation for participants to mark themselves as completed on a quant task */
  participantCompleteQuant?: Maybe<SessionsType>;
  participantCompleteUnmoderated: Submission;
  /** Mutation to confirm that a session has been completed by a participant. Old mutation: participantCompleteBookingParticipant */
  participantConfirmCompletion?: Maybe<SessionsType>;
  /** Mutation for the participant to confirm for a session. Old mutation: participantRegisterBookingParticipant */
  participantConfirmOpportunity?: Maybe<Array<Maybe<Submission>>>;
  /** Mutation for participants to mark themselves as waitlisted when a booking is full. */
  participantJoinQuantWaitlist?: Maybe<SessionsType>;
  participantOptIn?: Maybe<User>;
  /** Mutation to confirm that you're still good for a session. Old mutation: participantConfirmBookingParticipant */
  participantReconfirmSession?: Maybe<SessionsType>;
  /** Mutation for participants to request help */
  participantRequestHelp?: Maybe<SessionsType>;
  /** Mutation for participants to reschedule their availability. Old mutation: participantRescheduleRequest */
  participantRescheduleAvailability?: Maybe<Array<Maybe<Submission>>>;
  /** Mutation to apply for an opportunity */
  participantSaveAvailability?: Maybe<Submission>;
  /** Mutation for participants to indicate they have started a longitudinal task */
  participantStartLongitudinalTask?: Maybe<SessionsType>;
  /** Mutation for participant to indicate they are starting a new quant task */
  participantStartQuantTask?: Maybe<SessionsType>;
  participantSubmissionsExportCSV?: Maybe<Scalars['String']['output']>;
  /** Mutation for the participant to submit a feedback about a session */
  participantSubmitFeedback?: Maybe<SessionsType>;
  patchKindeUser?: Maybe<Scalars['Boolean']['output']>;
  publishInsight?: Maybe<Insight>;
  purchaseCredits?: Maybe<Teams>;
  rateBooking?: Maybe<Booking>;
  rateProject?: Maybe<Project>;
  rateUserSubmissionAnalysis: UserSubmissionAnalysis;
  /** Mutation to re-invite a participant for a specific session. Old mutation: reInviteBookingParticipant */
  reInviteParticipantSession?: Maybe<SessionsType>;
  recalcParticipantsEligibility?: Maybe<Booking>;
  refundBookingUpdated?: Maybe<Booking>;
  refundUnfulfilledBooking?: Maybe<Booking>;
  rejectBooking?: Maybe<Booking>;
  removeOrganisationFromTeam?: Maybe<Teams>;
  removeResearcher?: Maybe<Project>;
  reopenBooking?: Maybe<Booking>;
  reorderProjectQuestions?: Maybe<Project>;
  reorderQuestions?: Maybe<Booking>;
  reportIssue?: Maybe<Submission>;
  requestBrowserEmbed?: Maybe<BrowserEmbedContainer>;
  requestESignatureTemplateDelete?: Maybe<Array<Maybe<ESignatureTemplate>>>;
  requestLiveTranscriptionToken?: Maybe<LiveTranscriptionToken>;
  requestShareAccess?: Maybe<Scalars['String']['output']>;
  requestShareAuthToken: Scalars['String']['output'];
  requestToJoinRoom?: Maybe<AskableVideoUsersType>;
  requestTranscription?: Maybe<AskableVideoTranscriptType>;
  requestTranslation?: Maybe<AskableVideoTranscriptType>;
  rescheduleSessionLiveBooking?: Maybe<Booking>;
  researcherAcceptProject?: Maybe<Project>;
  researcherBeginCertification?: Maybe<ResearcherCertificationResponse>;
  resendInvitation?: Maybe<TeamInvitations>;
  resetParticipantRating?: Maybe<Submission>;
  revokeInvitation?: Maybe<TeamInvitations>;
  /** Mutation for clients to revoke a participant invitation */
  revokeParticipantInvitation?: Maybe<Submission>;
  revokeShare: Share;
  saveFigmaFile?: Maybe<FigmaFile>;
  saveSegment?: Maybe<Segment>;
  /** Send user messages to the AI moderator to trigger responses */
  sendAiModeratedMessage?: Maybe<AiModeratedMessageResponse>;
  sendMyselfBookingLink?: Maybe<Scalars['Boolean']['output']>;
  sendMyselfBookingLiveLink?: Maybe<Scalars['Boolean']['output']>;
  sendMyselfGenericEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Trigger API requests to generate / send an envelope for the booking's participant agreement */
  sendParticipantAgreement?: Maybe<Submission>;
  setBookingVisibility?: Maybe<Booking>;
  shareByEmails: Array<Share>;
  ssoLoginCallback?: Maybe<User>;
  /** Sends the system messages to trigger the AI moderator's initial message to the user */
  startAiModeratedConversation?: Maybe<AiModeratedMessageResponse>;
  startAiModeratedMeeting?: Maybe<AskableVideo>;
  startRoomRecording: AskableVideo;
  /** New mutation to invite participants */
  submissionInvite?: Maybe<Submission>;
  submissionMarkComplete?: Maybe<Submission>;
  submissionRateParticipant?: Maybe<Submission>;
  submissionReinvite?: Maybe<Submission>;
  submissionReschedule?: Maybe<Submission>;
  submitAskablePlusProject?: Maybe<Project>;
  /** Currently unmoderated bookings only */
  submitBookingForReview: Booking;
  syncOrganisationOnHubspot?: Maybe<OrganisationType>;
  teamCreateSsoConnection?: Maybe<Teams>;
  teamDeleteSsoConnection?: Maybe<Teams>;
  teamUpdateSsoConnection?: Maybe<TeamSettingsSsoConnection>;
  /** TODO: Make shortlisted argument not optional once frontend is ready */
  toggleShortlistSubmission?: Maybe<Submission>;
  triggerAdminSound?: Maybe<Scalars['Boolean']['output']>;
  unauthCreateShareRequest: Scalars['Boolean']['output'];
  updateAskablePlusDetails?: Maybe<Project>;
  updateAskableVideoRoomNote?: Maybe<AskableVideoRoomNote>;
  updateBooking?: Maybe<Booking>;
  updateBookingAdmin?: Maybe<Booking>;
  updateBookingConfig: Booking;
  updateBookingSubmission?: Maybe<Submission>;
  updateBookingTaskResultFigmaPrototype: BookingTaskResultFigmaPrototype;
  updateBookingTaskResultMultipleChoiceQuestion: BookingTaskResultMultipleChoiceQuestion;
  updateBookingTaskResultOpinionScale: BookingTaskResultOpinionScale;
  updateCollection?: Maybe<Collections>;
  updateCollectionViews?: Maybe<Array<Maybe<View>>>;
  updateDevice?: Maybe<User>;
  updateESignatureTemplate?: Maybe<ESignatureTemplate>;
  updateHighlight?: Maybe<HighlightType>;
  updateInsight?: Maybe<Insight>;
  updateInsightIdsInCollection?: Maybe<Collections>;
  updateInsightViews?: Maybe<Array<Maybe<View>>>;
  updateMe?: Maybe<User>;
  updateOnlineTaskLink?: Maybe<Booking>;
  updateParticipantStudySettings?: Maybe<ParticipantStudySettingsType>;
  updateProject?: Maybe<Project>;
  updateProjectQuestion?: Maybe<Project>;
  updateQuestion?: Maybe<Booking>;
  updateSegment?: Maybe<Segment>;
  updateSession?: Maybe<Booking>;
  updateShareRequest?: Maybe<ShareRequest>;
  updateSubmissionSessionsAvailability?: Maybe<Submission>;
  updateTag?: Maybe<TagType>;
  updateTaskBlockFigmaPrototype: Booking;
  updateTaskBlockMultipleChoiceQuestion: Booking;
  updateTaskBlockOpinionScale: Booking;
  updateTaskBlockOrder: Booking;
  updateTeam?: Maybe<Teams>;
  updateTeamPaymentMethod?: Maybe<Teams>;
  updateUnmoderatedBooking: Booking;
  updateUnmoderatedBookingConfig: Booking;
  updateUser?: Maybe<User>;
  updateUtteranceSpeakers?: Maybe<Array<Maybe<AskableVideoTranscriptUtteranceSpeakerType>>>;
  validateCoupon?: Maybe<Coupon>;
  verifyAndSetCountry?: Maybe<VerifyAndSetCountryResponseType>;
  verifyPhone?: Maybe<VerificationResponseType>;
  verifyPhoneAndUpdateUser?: Maybe<User>;
  verifyPhoneConfirm?: Maybe<Scalars['ID']['output']>;
  verifyPhoneSendCode?: Maybe<VerificationResponseType>;
  verifyUserPhone?: Maybe<User>;
};

export type MutationTmp_UpdateBookingRecruitArgs = {
  input: Tmp_UpdateBookingRecruitInput;
};

export type MutationAddAdminCommentArgs = {
  booking_id: Scalars['ID']['input'];
  comment: Scalars['String']['input'];
};

export type MutationAddAdminProjectNoteArgs = {
  _project_id: Scalars['ID']['input'];
  input: AdminNotesInput;
};

export type MutationAddResearcherArgs = {
  input: AddResearcherInput;
};

export type MutationAdminLoginAsClientArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationAdminLoginAsResearcherArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAdminSaveWigArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  input: AdminWigInput;
};

export type MutationAdminUpdateBookingArgs = {
  booking: BookingInput;
  booking_id: Scalars['ID']['input'];
};

export type MutationApplyDeclineAskablePlusProjectArgs = {
  _project_id: Scalars['ID']['input'];
  action: ApplyDeclineProjectType;
};

export type MutationApproveBookingArgs = {
  booking_id: Scalars['ID']['input'];
  incentive_value?: InputMaybe<Scalars['Int']['input']>;
  incentives?: InputMaybe<Array<BookingInputConfigIncentive>>;
  settings?: InputMaybe<ApproveBookingSettingsInput>;
};

export type MutationApproveJoinRequestArgs = {
  input: ApproveDenyCancelRoomRequestInput;
};

export type MutationArchiveBookingArgs = {
  booking_id: Scalars['ID']['input'];
};

export type MutationArchiveProjectArgs = {
  project_id: Scalars['ID']['input'];
};

export type MutationAssignOrganisationToTeamArgs = {
  _id: Scalars['ID']['input'];
  _organisation_id: Scalars['ID']['input'];
};

export type MutationBookingRequirementAdjustmentArgs = {
  booking: BookingInput;
  creditActivity?: InputMaybe<CreditActivityInput>;
};

export type MutationBulkInviteArgs = {
  booking_id: Scalars['ID']['input'];
  input: BulkInviteInput;
};

export type MutationCancelInviteArgs = {
  message: Scalars['String']['input'];
  submission_id: Scalars['ID']['input'];
};

export type MutationCancelParticipantSessionArgs = {
  booking_id: Scalars['ID']['input'];
  participant_session_id: Scalars['ID']['input'];
};

export type MutationCancelRequestToJoinRoomArgs = {
  input: ApproveDenyCancelRoomRequestInput;
};

export type MutationCancelSessionArgs = {
  booking_id: Scalars['ID']['input'];
  session_id: Scalars['ID']['input'];
};

export type MutationChangeFacilitatorContactArgs = {
  _facilitator_user_id: Scalars['ID']['input'];
  booking_id: Scalars['ID']['input'];
};

export type MutationChangeOrganiserContactArgs = {
  _organiser_user_id: Scalars['ID']['input'];
  booking_id: Scalars['ID']['input'];
};

export type MutationChangeUserRoleOnTeamArgs = {
  _id: Scalars['ID']['input'];
  role: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationChangeUserStatusOnTeamArgs = {
  _id: Scalars['ID']['input'];
  status: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCompleteOrderBookingArgs = {
  booking_id: Scalars['ID']['input'];
  credits_per_participant_difference?: InputMaybe<Scalars['Float']['input']>;
  new_session_duration?: InputMaybe<Scalars['Int']['input']>;
  participants_added?: InputMaybe<Scalars['Int']['input']>;
  participants_reopen_booking?: InputMaybe<Scalars['Int']['input']>;
  participants_session_changed?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCompleteProjectArgs = {
  project_id: Scalars['ObjectID']['input'];
};

export type MutationCompleteSurveyArgs = {
  booking_id: Scalars['ID']['input'];
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationCreateAiModLiveKitRoomArgs = {
  input: CreateAiModeratedLiveKitRoomInput;
};

export type MutationCreateAiModeratedLiveKitRoomArgs = {
  input: CreateAiModeratedLiveKitRoomInput;
};

export type MutationCreateAskableVideoMeetingArgs = {
  input?: InputMaybe<AskableVideoInput>;
};

export type MutationCreateAskableVideoRoomNoteArgs = {
  input?: InputMaybe<AskableVideoRoomNoteInput>;
};

export type MutationCreateBatchArgs = {
  batch: BatchInput;
};

export type MutationCreateBatchMessagesArgs = {
  _booking_id: Scalars['ID']['input'];
  _user_ids: Array<InputMaybe<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
};

export type MutationCreateBookingArgs = {
  _project_id?: InputMaybe<Scalars['ID']['input']>;
  _team_id: Scalars['ID']['input'];
  country_code?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  extra_setup_fields?: InputMaybe<BookingInput>;
  flags?: InputMaybe<CreateBookingFlagsInput>;
  timezone: Scalars['String']['input'];
};

export type MutationCreateBookingSubmissionArgs = {
  challenge?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<SubmissionMetadataInput>;
  submission?: InputMaybe<SubmissionInput>;
  vpn_info?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateBookingTaskEventClickArgs = {
  input: CreateBookingTaskEventClickInput;
};

export type MutationCreateBookingTaskEventLoadArgs = {
  input: CreateBookingTaskEventLoadInput;
};

export type MutationCreateBookingTaskResultFigmaPrototypeArgs = {
  input: CreateBookingTaskResultFigmaPrototypeInput;
};

export type MutationCreateBookingTaskResultMultipleChoiceQuestionArgs = {
  input: CreateBookingTaskResultMultipleChoiceQuestionInput;
};

export type MutationCreateBookingTaskResultOpinionScaleArgs = {
  input: CreateBookingTaskResultOpinionScaleInput;
};

export type MutationCreateBookingTaskResultPathItemArgs = {
  input: CreateBookingTaskResultPathItemInput;
};

export type MutationCreateBulkQuestionsArgs = {
  booking_id: Scalars['ID']['input'];
  questions: Array<InputMaybe<BookingInputConfigQuestion>>;
};

export type MutationCreateCollectionArgs = {
  input: CreateCollectionsInput;
};

export type MutationCreateCouponArgs = {
  coupon: CouponInput;
};

export type MutationCreateCreditRequestArgs = {
  _team_id: Scalars['ID']['input'];
  credit_request: CreditRequestInput;
};

export type MutationCreateCustomBatchArgs = {
  batch: CustomBatchInput;
};

export type MutationCreateESignatureTemplateArgs = {
  template: ESignatureTemplateInput;
};

export type MutationCreateHighlightArgs = {
  input: CreateHighlightInput;
};

export type MutationCreateInsightArgs = {
  input: CreateInsightInput;
};

export type MutationCreateMessageArgs = {
  message: MessagesInput;
};

export type MutationCreateOnlineTaskLinkArgs = {
  booking_id: Scalars['ID']['input'];
  link: BookingInputConfigOnlineTaskLink;
};

export type MutationCreateProjectArgs = {
  project: ProjectInput;
};

export type MutationCreateProjectQuestionArgs = {
  project_id: Scalars['ID']['input'];
  question: BookingInputConfigQuestion;
};

export type MutationCreateQuestionArgs = {
  booking_id: Scalars['ID']['input'];
  question: BookingInputConfigQuestion;
};

export type MutationCreateRoomTokenArgs = {
  input: CreateRoomTokenInput;
};

export type MutationCreateSessionArgs = {
  booking_id: Scalars['ID']['input'];
  session: Array<InputMaybe<BookingInputSession>>;
};

export type MutationCreateSessionsLiveBookingArgs = {
  booking_id: Scalars['ID']['input'];
  session: Array<InputMaybe<BookingInputSession>>;
};

export type MutationCreateShareArgs = {
  input?: InputMaybe<CreateShareInput>;
};

export type MutationCreateSharesArgs = {
  input?: InputMaybe<Array<InputMaybe<CreateShareInput>>>;
};

export type MutationCreateSubmissionSessionArgs = {
  booking_id: Scalars['ID']['input'];
};

export type MutationCreateTagsArgs = {
  input: CreateTagsInput;
};

export type MutationCreateTaskBlockFigmaPrototypeArgs = {
  input: CreateTaskBlockFigmaPrototypeInput;
};

export type MutationCreateTaskBlockMultipleChoiceQuestionArgs = {
  input: CreateTaskBlockMultipleChoiceQuestionInput;
};

export type MutationCreateTaskBlockOpinionScaleArgs = {
  input: CreateTaskBlockOpinionScaleInput;
};

export type MutationCreateUnmodLiveKitRoomArgs = {
  input: CreateUnmodLiveKitRoomInput;
};

export type MutationCreateUnmodVideoArgs = {
  bookingId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export type MutationCreateUnmoderatedBookingArgs = {
  input: CreateUnmoderatedBookingInput;
};

export type MutationCreateUploadArgs = {
  input: NewUploadInput;
};

export type MutationCreateUserSubmissionAnalysisArgs = {
  input: CreateUserSubmissionAnalysisInput;
};

export type MutationCreditAdjustmentArgs = {
  creditActivity: CreditActivityInput;
};

export type MutationCreditRequestChangeStatusArgs = {
  _id: Scalars['ID']['input'];
  status: Scalars['Int']['input'];
};

export type MutationCreditTransferArgs = {
  creditActivity: CreditActivityInput;
};

export type MutationDeclineInviteArgs = {
  session_id?: InputMaybe<Scalars['ID']['input']>;
  submission_id: Scalars['ID']['input'];
};

export type MutationDeleteAskableVideoRoomNoteArgs = {
  input: DeleteAskableVideoRoomNoteInput;
};

export type MutationDeleteCollectionArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationDeleteInsightArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteOnlineTaskLinkArgs = {
  booking_id: Scalars['ID']['input'];
  link_id: Scalars['ID']['input'];
};

export type MutationDeleteProjectQuestionArgs = {
  project_id: Scalars['ID']['input'];
  question_id: Scalars['ID']['input'];
};

export type MutationDeleteQuestionArgs = {
  booking_id: Scalars['ID']['input'];
  question_id: Scalars['ID']['input'];
};

export type MutationDeleteSegmentArgs = {
  booking_id: Scalars['ID']['input'];
  segment_id: Scalars['ID']['input'];
};

export type MutationDeleteSessionArgs = {
  booking_id: Scalars['ID']['input'];
  session_id: Scalars['ID']['input'];
};

export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};

export type MutationDeleteTaskBlockArgs = {
  input: DeleteTaskBlockInput;
};

export type MutationDeleteTeamCreditCardArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDenyJoinRequestArgs = {
  input: ApproveDenyCancelRoomRequestInput;
};

export type MutationDownloadInvoiceArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationDuplicateBookingArgs = {
  booking_id: Scalars['ID']['input'];
};

export type MutationDuplicateProjectArgs = {
  project_id: Scalars['ID']['input'];
};

export type MutationDuplicateProjectQuestionArgs = {
  project_id: Scalars['ID']['input'];
  question_id: Scalars['ID']['input'];
};

export type MutationDuplicateQuestionArgs = {
  booking_id: Scalars['ID']['input'];
  question_id: Scalars['ID']['input'];
};

export type MutationDuplicateUnmoderatedBookingArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationEmailMyEarningsReportArgs = {
  request: EarningsReportRequestInput;
};

export type MutationEndRoomArgs = {
  code: Scalars['String']['input'];
};

export type MutationExtendBookingArgs = {
  booking_id: Scalars['ID']['input'];
};

export type MutationFigmaOauthCallbackArgs = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type MutationForceCloseBookingArgs = {
  booking_id: Scalars['ID']['input'];
  credits_to_refund: Scalars['Float']['input'];
  reason_description?: InputMaybe<Scalars['String']['input']>;
  reason_option?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationGenerateShortUrlArgs = {
  input: ShortUrlInput;
};

export type MutationInitializeUserArgs = {
  input: InitializeUserInput;
};

export type MutationInviteParticipantSessionArgs = {
  booking_id: Scalars['ID']['input'];
  online_task_link_id?: InputMaybe<Scalars['ID']['input']>;
  participant_session_id: Scalars['ID']['input'];
};

export type MutationInviteResearcherToProjectArgs = {
  _project_id: Scalars['ID']['input'];
  _researcher_id: Scalars['ID']['input'];
};

export type MutationInviteToJoinTeamArgs = {
  _id: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  enforcePhone?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type MutationInviteWaitlistedArgs = {
  booking_id: Scalars['ID']['input'];
  submission_id: Scalars['ID']['input'];
};

export type MutationJoinRoomArgs = {
  input: StartRoomInput;
};

export type MutationJoinTeamArgs = {
  input: JoinTeamInput;
};

export type MutationJoinUnmodLiveKitRoomArgs = {
  input: JoinUnmodLiveKitRoomInput;
};

export type MutationLoadFigmaImagesArgs = {
  fileId: Scalars['String']['input'];
  nodeIds: Array<Scalars['String']['input']>;
};

export type MutationLoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  enterpriseLogin?: InputMaybe<Scalars['String']['input']>;
  facebookId?: InputMaybe<Scalars['String']['input']>;
  googleId?: InputMaybe<Scalars['String']['input']>;
  googleToken?: InputMaybe<Scalars['String']['input']>;
  loginToJoinTeam?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  ssoLogin?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMarkAllNotificationsAsReadByTeamArgs = {
  _team_id: Scalars['ID']['input'];
};

export type MutationMarkBookingMissionCriticalArgs = {
  bookingId: Scalars['ID']['input'];
  missionCritical: Scalars['Boolean']['input'];
  missionCriticalReason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMarkMultipleMessagesAsSeenArgs = {
  _messages_id: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationMarkNotificationAsReadArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationMarkParticipantNoShowArgs = {
  booking_id: Scalars['ID']['input'];
  no_show_request?: InputMaybe<Scalars['Int']['input']>;
  participant_session_id: Scalars['ID']['input'];
};

export type MutationMarkParticipantSessionAsCompleteArgs = {
  booking_id: Scalars['ID']['input'];
  participant_session_id: Scalars['ID']['input'];
};

export type MutationMarkParticipantSessionIssueArgs = {
  booking_id: Scalars['ID']['input'];
  issue_reason?: InputMaybe<Scalars['String']['input']>;
  issue_request?: InputMaybe<Scalars['Int']['input']>;
  participant_session_id: Scalars['ID']['input'];
};

export type MutationMarkProjectMissionCriticalArgs = {
  missionCritical: Scalars['Boolean']['input'];
  missionCriticalReason?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
};

export type MutationMaybeCreateKindeOrganizationArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationMergeTagsArgs = {
  input: MergeTagsInput;
};

export type MutationMoveBookingToAnotherTeamArgs = {
  booking_id: Scalars['ID']['input'];
  move_credit_activity?: InputMaybe<Scalars['Boolean']['input']>;
  new_team_id: Scalars['ID']['input'];
};

export type MutationNewTeamArgs = {
  allowed_domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billing_country: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationNotifyChangesOnRemoteToolArgs = {
  booking_id: Scalars['ID']['input'];
  new_tool: Scalars['String']['input'];
  notify_participant?: InputMaybe<Scalars['Boolean']['input']>;
  old_tool: Scalars['String']['input'];
};

export type MutationNotifyChangesOnSessionDurationArgs = {
  booking_id: Scalars['ID']['input'];
  new_session_duration: Scalars['Int']['input'];
  notify_participant?: InputMaybe<Scalars['Boolean']['input']>;
  old_session_duration: Scalars['Int']['input'];
};

export type MutationOnboardResearcherArgs = {
  onboard: OnboardResearcherInput;
};

export type MutationParticipantAbandonUnmoderatedArgs = {
  input: ParticipantAbandonUnmoderatedInput;
};

export type MutationParticipantCancelReasonArgs = {
  cancel_reason: Scalars['String']['input'];
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantCancelSessionArgs = {
  cancel_reason?: InputMaybe<Scalars['String']['input']>;
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantCancelWaitlistedSessionArgs = {
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantCompleteInProgressUnmoderatedArgs = {
  booking_id: Scalars['ID']['input'];
};

export type MutationParticipantCompleteQuantArgs = {
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantCompleteUnmoderatedArgs = {
  input: ParticipantCompleteUnmoderatedInput;
};

export type MutationParticipantConfirmCompletionArgs = {
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantConfirmOpportunityArgs = {
  _booking_id: Scalars['ID']['input'];
  _session_ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type MutationParticipantJoinQuantWaitlistArgs = {
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantReconfirmSessionArgs = {
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantRequestHelpArgs = {
  message_body: Scalars['String']['input'];
  message_tag?: InputMaybe<Scalars['String']['input']>;
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantRescheduleAvailabilityArgs = {
  available_sessions: Array<InputMaybe<Scalars['ID']['input']>>;
  booking_id: Scalars['ID']['input'];
};

export type MutationParticipantSaveAvailabilityArgs = {
  booking_id: Scalars['ID']['input'];
  created_context?: InputMaybe<Scalars['Int']['input']>;
  session_ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationParticipantStartLongitudinalTaskArgs = {
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantStartQuantTaskArgs = {
  participant_session_id: Scalars['ID']['input'];
};

export type MutationParticipantSubmissionsExportCsvArgs = {
  _booking_id: Scalars['ID']['input'];
};

export type MutationParticipantSubmitFeedbackArgs = {
  feedback: Scalars['String']['input'];
  participant_session_id: Scalars['ID']['input'];
};

export type MutationPublishInsightArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPurchaseCreditsArgs = {
  purchase_credits: PurchaseCreditsInput;
};

export type MutationRateBookingArgs = {
  bookingId: Scalars['ID']['input'];
  rating: BookingInputRating;
};

export type MutationRateProjectArgs = {
  projectId: Scalars['ID']['input'];
  rating: ProjectInputRating;
};

export type MutationRateUserSubmissionAnalysisArgs = {
  _id: Scalars['ObjectID']['input'];
  rating: RateUserSubmissionAnalysisInput;
};

export type MutationReInviteParticipantSessionArgs = {
  booking_id: Scalars['ID']['input'];
  customMessage?: InputMaybe<Scalars['String']['input']>;
  participant_session_id: Scalars['ID']['input'];
};

export type MutationRecalcParticipantsEligibilityArgs = {
  booking_id: Scalars['ID']['input'];
};

export type MutationRefundBookingUpdatedArgs = {
  booking_id: Scalars['ID']['input'];
  credits_to_refund: Scalars['Float']['input'];
  refund_reason: Scalars['String']['input'];
};

export type MutationRefundUnfulfilledBookingArgs = {
  booking_id: Scalars['ID']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRejectBookingArgs = {
  booking_id: Scalars['ID']['input'];
};

export type MutationRemoveOrganisationFromTeamArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationRemoveResearcherArgs = {
  input: RemoveResearcherInput;
};

export type MutationReopenBookingArgs = {
  booking_id: Scalars['ID']['input'];
};

export type MutationReorderProjectQuestionsArgs = {
  project_id: Scalars['ID']['input'];
  questions?: InputMaybe<Array<InputMaybe<BookingInputConfigQuestion>>>;
};

export type MutationReorderQuestionsArgs = {
  booking_id: Scalars['ID']['input'];
  questions?: InputMaybe<Array<InputMaybe<BookingInputConfigQuestion>>>;
};

export type MutationReportIssueArgs = {
  booking_id: Scalars['ID']['input'];
  booking_submission_id: Scalars['ID']['input'];
  report_details?: InputMaybe<Scalars['String']['input']>;
  report_reason: ReportReason;
  report_request?: InputMaybe<ReportRequest>;
};

export type MutationRequestBrowserEmbedArgs = {
  input: RequestBrowserEmbedInput;
};

export type MutationRequestESignatureTemplateDeleteArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationRequestLiveTranscriptionTokenArgs = {
  bookingId: Scalars['ID']['input'];
};

export type MutationRequestShareAccessArgs = {
  input: RequestAccessInput;
};

export type MutationRequestShareAuthTokenArgs = {
  sharing_token: Scalars['String']['input'];
};

export type MutationRequestToJoinRoomArgs = {
  input: RequestToJoinRoomInput;
};

export type MutationRequestTranscriptionArgs = {
  input: RequestTranscriptionInput;
};

export type MutationRequestTranslationArgs = {
  input: RequestTranslationInput;
};

export type MutationRescheduleSessionLiveBookingArgs = {
  booking_id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  newSession: BookingInputSession;
  session_id: Scalars['ID']['input'];
  shouldMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationResearcherAcceptProjectArgs = {
  projectId: Scalars['ID']['input'];
};

export type MutationResendInvitationArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationResetParticipantRatingArgs = {
  submission_id: Scalars['ObjectID']['input'];
};

export type MutationRevokeInvitationArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationRevokeParticipantInvitationArgs = {
  participant_session_id: Scalars['ID']['input'];
  sms_message: Scalars['String']['input'];
};

export type MutationRevokeShareArgs = {
  id: Scalars['ID']['input'];
};

export type MutationSaveFigmaFileArgs = {
  fileId: Scalars['String']['input'];
  loadImages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSaveSegmentArgs = {
  booking_id: Scalars['ID']['input'];
  segment_input: SegmentInput;
};

export type MutationSendAiModeratedMessageArgs = {
  meeting_code: Scalars['String']['input'];
  message: Scalars['String']['input'];
  room_name: Scalars['String']['input'];
};

export type MutationSendMyselfBookingLinkArgs = {
  booking_id: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSendMyselfBookingLiveLinkArgs = {
  booking_id: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSendMyselfGenericEmailArgs = {
  email: GenericEmailInput;
};

export type MutationSendParticipantAgreementArgs = {
  submission_id: Scalars['ID']['input'];
};

export type MutationSetBookingVisibilityArgs = {
  booking_id: Scalars['ID']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationShareByEmailsArgs = {
  emails: Array<Scalars['String']['input']>;
  input: ShareTypeInput;
};

export type MutationSsoLoginCallbackArgs = {
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationStartAiModeratedConversationArgs = {
  meeting_code: Scalars['String']['input'];
  room_name: Scalars['String']['input'];
};

export type MutationStartAiModeratedMeetingArgs = {
  bookingId: Scalars['ID']['input'];
};

export type MutationStartRoomRecordingArgs = {
  room_name: Scalars['String']['input'];
};

export type MutationSubmissionInviteArgs = {
  input: InviteSubmissionInput;
};

export type MutationSubmissionMarkCompleteArgs = {
  submission_id: Scalars['ID']['input'];
};

export type MutationSubmissionRateParticipantArgs = {
  input: RatingParticipantInput;
};

export type MutationSubmissionReinviteArgs = {
  input: ReinviteSubmissionInput;
};

export type MutationSubmissionRescheduleArgs = {
  input: RescheduleSessionInput;
};

export type MutationSubmitAskablePlusProjectArgs = {
  _project_id: Scalars['ID']['input'];
  _transaction_id?: InputMaybe<Scalars['ID']['input']>;
  currency_code?: InputMaybe<Scalars['String']['input']>;
  study_flags?: InputMaybe<SubmitAskablePlusProjectStudyFlagsInput>;
};

export type MutationSubmitBookingForReviewArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationSyncOrganisationOnHubspotArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationTeamCreateSsoConnectionArgs = {
  strategy: KindeSsoStrategy;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationTeamDeleteSsoConnectionArgs = {
  connectionId: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationTeamUpdateSsoConnectionArgs = {
  connectionId: Scalars['String']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<TeamSettingsSsoConnectionOptionsInput>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationToggleShortlistSubmissionArgs = {
  shortlisted?: InputMaybe<Scalars['Boolean']['input']>;
  submission_id: Scalars['ID']['input'];
};

export type MutationTriggerAdminSoundArgs = {
  sound: AdminSoundInput;
};

export type MutationUnauthCreateShareRequestArgs = {
  input: UnauthCreateShareRequestInput;
};

export type MutationUpdateAskablePlusDetailsArgs = {
  _project_id: Scalars['ID']['input'];
  project: ProjectInput;
};

export type MutationUpdateAskableVideoRoomNoteArgs = {
  input?: InputMaybe<UpdateAskableVideoRoomNoteInput>;
};

export type MutationUpdateBookingArgs = {
  booking: BookingInput;
  booking_id: Scalars['ID']['input'];
  currency_code?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateBookingAdminArgs = {
  booking: BookingInput;
  booking_id: Scalars['ID']['input'];
  currency_code?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateBookingConfigArgs = {
  _id: Scalars['ID']['input'];
  input: UpdateBookingConfigInput;
};

export type MutationUpdateBookingSubmissionArgs = {
  input: UpdateBookingSubmissionInput;
};

export type MutationUpdateBookingTaskResultFigmaPrototypeArgs = {
  input: UpdateBookingTaskResultFigmaPrototypeInput;
};

export type MutationUpdateBookingTaskResultMultipleChoiceQuestionArgs = {
  input: UpdateBookingTaskResultMultipleChoiceQuestionInput;
};

export type MutationUpdateBookingTaskResultOpinionScaleArgs = {
  input: UpdateBookingTaskResultOpinionScaleInput;
};

export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionsInput;
};

export type MutationUpdateCollectionViewsArgs = {
  code: Scalars['String']['input'];
};

export type MutationUpdateDeviceArgs = {
  app_version: Scalars['String']['input'];
  id: Scalars['String']['input'];
  os: Os;
  permissions?: InputMaybe<DevicePermissionsInput>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateESignatureTemplateArgs = {
  _id: Scalars['ID']['input'];
  template: ESignatureTemplateInput;
};

export type MutationUpdateHighlightArgs = {
  input: UpdateHighlightInput;
};

export type MutationUpdateInsightArgs = {
  input: UpdateInsightInput;
};

export type MutationUpdateInsightIdsInCollectionArgs = {
  input: UpdateInsightIdsInCollectionInput;
};

export type MutationUpdateInsightViewsArgs = {
  code: Scalars['String']['input'];
};

export type MutationUpdateMeArgs = {
  user: UserInput;
};

export type MutationUpdateOnlineTaskLinkArgs = {
  booking_id: Scalars['ID']['input'];
  link: BookingInputConfigOnlineTaskLink;
  link_id: Scalars['ID']['input'];
};

export type MutationUpdateParticipantStudySettingsArgs = {
  _booking_id: Scalars['ID']['input'];
  filters?: InputMaybe<ParticipantStudySettingsFilter>;
};

export type MutationUpdateProjectArgs = {
  _project_id: Scalars['ID']['input'];
  project: ProjectInput;
};

export type MutationUpdateProjectQuestionArgs = {
  project_id: Scalars['ID']['input'];
  question: BookingInputConfigQuestion;
  question_id: Scalars['ID']['input'];
};

export type MutationUpdateQuestionArgs = {
  booking_id: Scalars['ID']['input'];
  question: BookingInputConfigQuestion;
  question_id: Scalars['ID']['input'];
};

export type MutationUpdateSegmentArgs = {
  input: UpdateSegmentInput;
};

export type MutationUpdateSessionArgs = {
  booking_id: Scalars['ID']['input'];
  session: BookingInputSession;
  session_id: Scalars['ID']['input'];
};

export type MutationUpdateShareRequestArgs = {
  input: UpdateShareRequestInput;
};

export type MutationUpdateSubmissionSessionsAvailabilityArgs = {
  _booking_id: Scalars['ID']['input'];
  _session_ids: Array<Scalars['ID']['input']>;
};

export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type MutationUpdateTaskBlockFigmaPrototypeArgs = {
  input: UpdateTaskBlockFigmaPrototypeInput;
};

export type MutationUpdateTaskBlockMultipleChoiceQuestionArgs = {
  input: UpdateTaskBlockMultipleChoiceQuestionInput;
};

export type MutationUpdateTaskBlockOpinionScaleArgs = {
  input: UpdateTaskBlockOpinionScaleInput;
};

export type MutationUpdateTaskBlockOrderArgs = {
  input: UpdateTaskBlockOrderInput;
};

export type MutationUpdateTeamArgs = {
  _id: Scalars['ID']['input'];
  team: TeamInput;
};

export type MutationUpdateTeamPaymentMethodArgs = {
  _id: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type MutationUpdateUnmoderatedBookingArgs = {
  input: UpdateUnmoderatedBookingInput;
};

export type MutationUpdateUnmoderatedBookingConfigArgs = {
  input: UpdateUnmoderatedBookingConfigInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  user: UserInput;
};

export type MutationUpdateUtteranceSpeakersArgs = {
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
  filterUtterances: UpdateTranscriptSpeakersFilterInput;
  speakerDetails: AskableVideoTranscriptSpeakerInput;
};

export type MutationValidateCouponArgs = {
  _team_id: Scalars['ID']['input'];
  coupon: Scalars['String']['input'];
  creditPack?: InputMaybe<Scalars['ID']['input']>;
  total_price?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationVerifyAndSetCountryArgs = {
  challenge: Scalars['String']['input'];
  vpn_info: Scalars['String']['input'];
};

export type MutationVerifyPhoneArgs = {
  country_code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type MutationVerifyPhoneAndUpdateUserArgs = {
  input: VerifyPhoneAndUpdateUserInput;
};

export type MutationVerifyPhoneConfirmArgs = {
  country_code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  verification_code: Scalars['String']['input'];
};

export type MutationVerifyPhoneSendCodeArgs = {
  _id: Scalars['ID']['input'];
};

export type MutationVerifyUserPhoneArgs = {
  _user_id: Scalars['ID']['input'];
};

export type NationalityInput = {
  AUS?: InputMaybe<AusNationalityInput>;
};

export type NewUploadInput = {
  area: UploadArea;
  area_id: Scalars['ID']['input'];
  mime_type: UploadMimeType;
};

export type Notifications = {
  __typename?: 'Notifications';
  Team?: Maybe<Teams>;
  _id: Scalars['ID']['output'];
  _team_id: Scalars['ID']['output'];
  context?: Maybe<NotificationsContextType>;
  created?: Maybe<Scalars['Float']['output']>;
  /**  Link URL  */
  link: Scalars['String']['output'];
  /**  Main message that gets displayed to the client. Stored as Markdown  */
  message: Scalars['String']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Booking type:
   *     1 - Booking Approved
   * Booking Participant type:
   *     1 - New applicants on a booking
   *     2 - Booking Participant cancelled
   *     3 - Booking Participant new feedback
   *     4 - Booking Participant confirms for a session
   *     5 - Booking Participant marks his session as completed
   * Team type:
   *     1 - User joined a team
   * Messages type:
   *     1 - New message from participant
   */
  subtype?: Maybe<Scalars['Int']['output']>;
  /**  1 - Booking / 2 - Booking Participant / 3 - Team / 4 - Messages  */
  type: Scalars['Int']['output'];
  updated?: Maybe<Scalars['Float']['output']>;
};

export type NotificationsContextType = {
  __typename?: 'NotificationsContextType';
  booking?: Maybe<Booking>;
  booking_participant?: Maybe<BookingParticipant>;
  messages?: Maybe<Messages>;
  participant_session?: Maybe<BookingSession>;
};

export type NotificationsCount = {
  __typename?: 'NotificationsCount';
  _id?: Maybe<Scalars['ID']['output']>;
  /**  Notifications.read = false  */
  unread?: Maybe<Scalars['Int']['output']>;
};

export enum Os {
  Android = 'android',
  Ios = 'ios',
  Other = 'other',
}

export type OmnisearchResult = {
  __typename?: 'OmnisearchResult';
  bookings?: Maybe<Array<Maybe<Booking>>>;
  clients?: Maybe<Array<Maybe<User>>>;
  participants?: Maybe<Array<Maybe<User>>>;
  teams?: Maybe<Array<Maybe<Teams>>>;
};

export type OnboardResearcherInput = {
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  methods?: InputMaybe<Array<InputMaybe<ResearcherMethods>>>;
  ux_length?: InputMaybe<UserMetaUxLength>;
};

export enum OnboardingStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
}

export type OnlineTaskBookingConfig = {
  __typename?: 'OnlineTaskBookingConfig';
  links?: Maybe<Array<Maybe<LinkOnlineTaskBookingConfig>>>;
  /** Participant device must match ANY value (1: Desktop, 2: Mobile) */
  required_device?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Participant device must have ALL listed features (1: Camera, 2: Microphone) */
  required_features?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  tool?: Maybe<Scalars['String']['output']>;
  /** 1: Online Task 2: Surveys 3: askableAiModerated */
  type?: Maybe<Scalars['Int']['output']>;
};

export enum OperationalOffices {
  Au = 'AU',
  Uk = 'UK',
  Us = 'US',
}

export type OpinionScale = {
  __typename?: 'OpinionScale';
  is_zero_start: Scalars['Boolean']['output'];
  label_high: Scalars['String']['output'];
  label_low: Scalars['String']['output'];
  label_mid: Scalars['String']['output'];
  scale_max: Scalars['Int']['output'];
  scale_type: OpinionScaleType;
};

export type OpinionScaleInput = {
  is_zero_start: Scalars['Boolean']['input'];
  label_high: Scalars['String']['input'];
  label_low: Scalars['String']['input'];
  label_mid: Scalars['String']['input'];
  scale_max: Scalars['Int']['input'];
  scale_type: OpinionScaleType;
};

export type OpinionScaleResult = {
  __typename?: 'OpinionScaleResult';
  average_rating: Scalars['Float']['output'];
  rating_counts: Array<RatingCount>;
  scale_type: OpinionScaleType;
  total_ratings: Scalars['Int']['output'];
  total_responses: Scalars['Int']['output'];
};

export enum OpinionScaleType {
  Emotions = 'emotions',
  Numerical = 'numerical',
  Stars = 'stars',
}

export type OpportunitySearchInput = {
  /** Optional: will use context _user_id if not defined */
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  /** Bookings with sessions before the date */
  dateMax?: InputMaybe<Scalars['Float']['input']>;
  /** Bookings with sessions after the date */
  dateMin?: InputMaybe<Scalars['Float']['input']>;
  /** Find face to face opportunities within x km of the user location */
  locationRadius?: InputMaybe<Scalars['Int']['input']>;
  /** Booking status */
  status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type OptionsBookingConfig = {
  __typename?: 'OptionsBookingConfig';
  lunch_end?: Maybe<Scalars['String']['output']>;
  lunch_start?: Maybe<Scalars['String']['output']>;
  review_submission?: Maybe<Scalars['Boolean']['output']>;
  session_break?: Maybe<Scalars['Int']['output']>;
  show_company?: Maybe<Scalars['Boolean']['output']>;
};

export type OptionsQuestionBookingConfig = {
  __typename?: 'OptionsQuestionBookingConfig';
  _id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  screen_in?: Maybe<Scalars['Boolean']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

export type OrganisationAdmin = {
  __typename?: 'OrganisationAdmin';
  Owner?: Maybe<User>;
  _owner_user_id?: Maybe<Scalars['ID']['output']>;
};

export type OrganisationIntegrationsType = {
  __typename?: 'OrganisationIntegrationsType';
  hubspot_company_id?: Maybe<Scalars['String']['output']>;
};

export type OrganisationSettingsType = {
  __typename?: 'OrganisationSettingsType';
  disallow_multiple_team_sign_on?: Maybe<Scalars['Boolean']['output']>;
  /**  It defines whether repeated participants should be excluded from bookings within teams connected to this organisation  */
  exclude_participants_from_teams?: Maybe<Scalars['Boolean']['output']>;
};

export type OrganisationType = {
  __typename?: 'OrganisationType';
  AverageBookingRating?: Maybe<Scalars['Float']['output']>;
  Teams?: Maybe<Array<Maybe<Teams>>>;
  _id: Scalars['ID']['output'];
  admin?: Maybe<OrganisationAdmin>;
  created?: Maybe<Scalars['Float']['output']>;
  integrations?: Maybe<OrganisationIntegrationsType>;
  name?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<OrganisationSettingsType>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type OrganisationTypeAverageBookingRatingArgs = {
  days?: InputMaybe<Scalars['Int']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ParticipantAbandonUnmoderatedInput = {
  _booking_id: Scalars['ObjectID']['input'];
};

export type ParticipantCompleteUnmoderatedInput = {
  _booking_id: Scalars['ObjectID']['input'];
  _user_id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type ParticipantRating = {
  __typename?: 'ParticipantRating';
  ratings?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type ParticipantSessionsCount = {
  __typename?: 'ParticipantSessionsCount';
  _session_id?: Maybe<Scalars['ID']['output']>;
  /** sessions.status = 3 & sessions.cancel = 0 */
  available?: Maybe<Scalars['Int']['output']>;
  /** sessions.status = 1 & sessions.cancel = 0 & sessions.completed */
  completed?: Maybe<Scalars['Int']['output']>;
  /** sessions.status = 5 & sessions.cancel = 0 */
  in_progress?: Maybe<Scalars['Int']['output']>;
  /** sessions.status = 4 & sessions.cancel = 0 */
  invited?: Maybe<Scalars['Int']['output']>;
  /** sessions.status = 1 & sessions.cancel = 0 */
  scheduled?: Maybe<Scalars['Int']['output']>;
  /** All applicants */
  total?: Maybe<Scalars['Int']['output']>;
  /** All eligble applicants */
  total_eligible?: Maybe<Scalars['Int']['output']>;
  /** Refunded applicants */
  total_refunded?: Maybe<Scalars['Int']['output']>;
  /** sessions.status = 2 & sessions.cancel = 0 */
  waiting_list?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantStudySettingsFilter = {
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ParticipantStudySettingsType = {
  __typename?: 'ParticipantStudySettingsType';
  _booking_id: Scalars['ObjectID']['output'];
  _id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
};

export type PayPalDetails = {
  __typename?: 'PayPalDetails';
  _id?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /**  Verified email AND verified account  */
  status?: Maybe<Scalars['Boolean']['output']>;
  verification_details?: Maybe<VerificationDetails>;
};

export type PayPalDetailsInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PhoneInput = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  verification?: InputMaybe<PhoneVerificationInput>;
};

export type PhoneVerificationInput = {
  fax?: InputMaybe<Scalars['ID']['input']>;
  mobile?: InputMaybe<Scalars['ID']['input']>;
};

export type PreviousResponseInput = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  created_after?: InputMaybe<Scalars['Float']['input']>;
};

export type Project = {
  __typename?: 'Project';
  _id: Scalars['ID']['output'];
  _team_id?: Maybe<Scalars['ID']['output']>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  admin?: Maybe<ProjectAdminType>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  askable_plus?: Maybe<ProjectAskablePlusDetails>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  cloned?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  /**  Resolved on the server  */
  pricing?: Maybe<ProjectPricing>;
  progress?: Maybe<ProjectProgress>;
  rating?: Maybe<ProjectRating>;
  /**  0: Draft, 1: Active, 5: Completed, 7: Archived  */
  status?: Maybe<Scalars['Int']['output']>;
  submitted_date?: Maybe<Scalars['Float']['output']>;
  team?: Maybe<Teams>;
  /**  0: Default/none, 1: Askable+  */
  type?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<ProjectUser>>>;
};

export type ProjectAdminType = {
  __typename?: 'ProjectAdminType';
  mission_critical?: Maybe<Scalars['Boolean']['output']>;
  mission_critical_reason?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<AdminNotesType>>>;
};

export type ProjectAskablePlusAdditional = {
  __typename?: 'ProjectAskablePlusAdditional';
  /**  1: provided by researcher, 2: provided by client  */
  moderation_guide_type?: Maybe<Scalars['Int']['output']>;
  moderation_guide_url?: Maybe<Scalars['String']['output']>;
  /**  1: provided by researcher, 2: provided by client  */
  nda_guide_type?: Maybe<Scalars['Int']['output']>;
  nda_guide_url?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type ProjectAskablePlusAdditionalInput = {
  /**  1: provided by researcher, 2: provided by client  */
  moderation_guide_type?: InputMaybe<Scalars['Int']['input']>;
  moderation_guide_url?: InputMaybe<Scalars['String']['input']>;
  /**  1: provided by researcher, 2: provided by client  */
  nda_guide_type?: InputMaybe<Scalars['Int']['input']>;
  nda_guide_url?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectAskablePlusAssetLink = {
  __typename?: 'ProjectAskablePlusAssetLink';
  _id?: Maybe<Scalars['ID']['output']>;
  checked?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ProjectAskablePlusAssetLinkInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  checked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectAskablePlusAudience = {
  __typename?: 'ProjectAskablePlusAudience';
  booking_config?: Maybe<BookingConfig>;
  description?: Maybe<Scalars['String']['output']>;
};

export type ProjectAskablePlusAudienceInput = {
  booking_config?: InputMaybe<BookingInputConfig>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectAskablePlusBriefMeetings = {
  __typename?: 'ProjectAskablePlusBriefMeetings';
  briefing?: Maybe<ProjectAskablePlusBriefMeetingsDetails>;
  debriefing?: Maybe<ProjectAskablePlusBriefMeetingsDetails>;
};

export type ProjectAskablePlusBriefMeetingsDetails = {
  __typename?: 'ProjectAskablePlusBriefMeetingsDetails';
  client_confirmed?: Maybe<Scalars['Boolean']['output']>;
  meeting_date?: Maybe<Scalars['Float']['output']>;
  meeting_link?: Maybe<Scalars['String']['output']>;
  researcher_confirmed?: Maybe<Scalars['Boolean']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type ProjectAskablePlusBriefMeetingsDetailsInput = {
  client_confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  meeting_date?: InputMaybe<Scalars['Float']['input']>;
  meeting_link?: InputMaybe<Scalars['String']['input']>;
  researcher_confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectAskablePlusBriefMeetingsInput = {
  briefing?: InputMaybe<ProjectAskablePlusBriefMeetingsDetailsInput>;
  debriefing?: InputMaybe<ProjectAskablePlusBriefMeetingsDetailsInput>;
};

export type ProjectAskablePlusBriefSteps = {
  __typename?: 'ProjectAskablePlusBriefSteps';
  additional_info?: Maybe<Scalars['String']['output']>;
  additional_info_additional_notes?: Maybe<Scalars['String']['output']>;
  additional_info_legal_privacy?: Maybe<Scalars['String']['output']>;
  additional_info_link_to_assets?: Maybe<Scalars['String']['output']>;
  additional_info_moderation_guide?: Maybe<Scalars['String']['output']>;
  additional_info_your_timezone?: Maybe<Scalars['String']['output']>;
  audience?: Maybe<Scalars['String']['output']>;
  audience_custom_screener?: Maybe<Scalars['String']['output']>;
  audience_demographic_filters?: Maybe<Scalars['String']['output']>;
  audience_incentives?: Maybe<Scalars['String']['output']>;
  audience_participant?: Maybe<Scalars['String']['output']>;
  audience_participant_criteria?: Maybe<Scalars['String']['output']>;
  audience_participant_locations?: Maybe<Scalars['String']['output']>;
  competitive_analysis?: Maybe<Scalars['String']['output']>;
  competitive_analysis_quota?: Maybe<Scalars['String']['output']>;
  discovery_interview?: Maybe<Scalars['String']['output']>;
  discovery_interview_meeting_format?: Maybe<Scalars['String']['output']>;
  discovery_interview_quota?: Maybe<Scalars['String']['output']>;
  discovery_interview_session_duration?: Maybe<Scalars['String']['output']>;
  discovery_interview_session_format?: Maybe<Scalars['String']['output']>;
  longitudinal_study?: Maybe<Scalars['String']['output']>;
  longitudinal_study_duration?: Maybe<Scalars['String']['output']>;
  longitudinal_study_quota?: Maybe<Scalars['String']['output']>;
  project_setup?: Maybe<Scalars['String']['output']>;
  project_setup_deliverables?: Maybe<Scalars['String']['output']>;
  project_setup_due_date?: Maybe<Scalars['String']['output']>;
  project_setup_frequency_duration?: Maybe<Scalars['String']['output']>;
  project_setup_key_objective?: Maybe<Scalars['String']['output']>;
  project_setup_meeting_format?: Maybe<Scalars['String']['output']>;
  project_setup_project_brief?: Maybe<Scalars['String']['output']>;
  project_setup_project_context?: Maybe<Scalars['String']['output']>;
  project_setup_project_title?: Maybe<Scalars['String']['output']>;
  project_setup_quota?: Maybe<Scalars['String']['output']>;
  project_setup_research_type?: Maybe<Scalars['String']['output']>;
  project_setup_session_duration?: Maybe<Scalars['String']['output']>;
  project_setup_session_format?: Maybe<Scalars['String']['output']>;
  review_submit?: Maybe<Scalars['String']['output']>;
  review_submit_confirm_brief?: Maybe<Scalars['String']['output']>;
  survey?: Maybe<Scalars['String']['output']>;
  survey_duration?: Maybe<Scalars['String']['output']>;
  survey_quota?: Maybe<Scalars['String']['output']>;
  usability_testing?: Maybe<Scalars['String']['output']>;
  usability_testing_meeting_format?: Maybe<Scalars['String']['output']>;
  usability_testing_quota?: Maybe<Scalars['String']['output']>;
  usability_testing_session_duration?: Maybe<Scalars['String']['output']>;
};

export type ProjectAskablePlusBriefStepsInput = {
  additional_info?: InputMaybe<Scalars['String']['input']>;
  additional_info_additional_notes?: InputMaybe<Scalars['String']['input']>;
  additional_info_legal_privacy?: InputMaybe<Scalars['String']['input']>;
  additional_info_link_to_assets?: InputMaybe<Scalars['String']['input']>;
  additional_info_moderation_guide?: InputMaybe<Scalars['String']['input']>;
  additional_info_your_timezone?: InputMaybe<Scalars['String']['input']>;
  audience?: InputMaybe<Scalars['String']['input']>;
  audience_custom_screener?: InputMaybe<Scalars['String']['input']>;
  audience_demographic_filters?: InputMaybe<Scalars['String']['input']>;
  audience_incentives?: InputMaybe<Scalars['String']['input']>;
  audience_participant?: InputMaybe<Scalars['String']['input']>;
  audience_participant_criteria?: InputMaybe<Scalars['String']['input']>;
  audience_participant_locations?: InputMaybe<Scalars['String']['input']>;
  competitive_analysis?: InputMaybe<Scalars['String']['input']>;
  competitive_analysis_quota?: InputMaybe<Scalars['String']['input']>;
  discovery_interview?: InputMaybe<Scalars['String']['input']>;
  discovery_interview_meeting_format?: InputMaybe<Scalars['String']['input']>;
  discovery_interview_quota?: InputMaybe<Scalars['String']['input']>;
  discovery_interview_session_duration?: InputMaybe<Scalars['String']['input']>;
  discovery_interview_session_format?: InputMaybe<Scalars['String']['input']>;
  longitudinal_study?: InputMaybe<Scalars['String']['input']>;
  longitudinal_study_duration?: InputMaybe<Scalars['String']['input']>;
  longitudinal_study_quota?: InputMaybe<Scalars['String']['input']>;
  project_setup?: InputMaybe<Scalars['String']['input']>;
  project_setup_deliverables?: InputMaybe<Scalars['String']['input']>;
  project_setup_due_date?: InputMaybe<Scalars['String']['input']>;
  project_setup_frequency_duration?: InputMaybe<Scalars['String']['input']>;
  project_setup_key_objective?: InputMaybe<Scalars['String']['input']>;
  project_setup_meeting_format?: InputMaybe<Scalars['String']['input']>;
  project_setup_project_brief?: InputMaybe<Scalars['String']['input']>;
  project_setup_project_context?: InputMaybe<Scalars['String']['input']>;
  project_setup_project_title?: InputMaybe<Scalars['String']['input']>;
  project_setup_quota?: InputMaybe<Scalars['String']['input']>;
  project_setup_research_type?: InputMaybe<Scalars['String']['input']>;
  project_setup_session_duration?: InputMaybe<Scalars['String']['input']>;
  project_setup_session_format?: InputMaybe<Scalars['String']['input']>;
  review_submit?: InputMaybe<Scalars['String']['input']>;
  review_submit_confirm_brief?: InputMaybe<Scalars['String']['input']>;
  survey?: InputMaybe<Scalars['String']['input']>;
  survey_duration?: InputMaybe<Scalars['String']['input']>;
  survey_quota?: InputMaybe<Scalars['String']['input']>;
  usability_testing?: InputMaybe<Scalars['String']['input']>;
  usability_testing_meeting_format?: InputMaybe<Scalars['String']['input']>;
  usability_testing_quota?: InputMaybe<Scalars['String']['input']>;
  usability_testing_session_duration?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectAskablePlusDeliverables = {
  __typename?: 'ProjectAskablePlusDeliverables';
  executive_summary?: Maybe<Scalars['Boolean']['output']>;
  highlight_reel?: Maybe<Scalars['Boolean']['output']>;
  other?: Maybe<Scalars['Boolean']['output']>;
  other_description?: Maybe<Scalars['String']['output']>;
  research_report?: Maybe<Scalars['Boolean']['output']>;
  transcriptions?: Maybe<Scalars['Boolean']['output']>;
  video_recordings?: Maybe<Scalars['Boolean']['output']>;
};

export type ProjectAskablePlusDeliverablesInput = {
  executive_summary?: InputMaybe<Scalars['Boolean']['input']>;
  highlight_reel?: InputMaybe<Scalars['Boolean']['input']>;
  other?: InputMaybe<Scalars['Boolean']['input']>;
  other_description?: InputMaybe<Scalars['String']['input']>;
  research_report?: InputMaybe<Scalars['Boolean']['input']>;
  transcriptions?: InputMaybe<Scalars['Boolean']['input']>;
  video_recordings?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectAskablePlusDetails = {
  __typename?: 'ProjectAskablePlusDetails';
  additional_info?: Maybe<ProjectAskablePlusAdditional>;
  assets?: Maybe<Array<Maybe<ProjectAskablePlusAssetLink>>>;
  audience?: Maybe<ProjectAskablePlusAudience>;
  /**  In months  */
  continuous_duration?: Maybe<Scalars['Int']['output']>;
  deliverables?: Maybe<ProjectAskablePlusDeliverables>;
  /**  Project context  */
  description?: Maybe<Scalars['String']['output']>;
  /**  Timestamp  */
  due_date?: Maybe<Scalars['Float']['output']>;
  meetings?: Maybe<ProjectAskablePlusBriefMeetings>;
  objective?: Maybe<Scalars['String']['output']>;
  research_type?: Maybe<ProjectAskablePlusResearch>;
  researcher_deliverables?: Maybe<ProjectAskablePlusResearcherDeliverables>;
  /**  Custom researcher hourly rate. If not set, assumes the default hourly rate  */
  researcher_hourly_rate?: Maybe<Scalars['Float']['output']>;
  steps?: Maybe<ProjectAskablePlusBriefSteps>;
};

export type ProjectAskablePlusDetailsInput = {
  additional_info?: InputMaybe<ProjectAskablePlusAdditionalInput>;
  assets?: InputMaybe<Array<InputMaybe<ProjectAskablePlusAssetLinkInput>>>;
  audience?: InputMaybe<ProjectAskablePlusAudienceInput>;
  /**  In months  */
  continuous_duration?: InputMaybe<Scalars['Int']['input']>;
  deliverables?: InputMaybe<ProjectAskablePlusDeliverablesInput>;
  /**  Project context  */
  description?: InputMaybe<Scalars['String']['input']>;
  /**  Timestamp  */
  due_date?: InputMaybe<Scalars['Float']['input']>;
  meetings?: InputMaybe<ProjectAskablePlusBriefMeetingsInput>;
  objective?: InputMaybe<Scalars['String']['input']>;
  research_type?: InputMaybe<ProjectAskablePlusResearchInput>;
  researcher_deliverables?: InputMaybe<ProjectAskablePlusResearcherDeliverablesInput>;
  researcher_hourly_rate?: InputMaybe<Scalars['Float']['input']>;
  /**  Resolved on the server  */
  steps?: InputMaybe<ProjectAskablePlusBriefStepsInput>;
};

export type ProjectAskablePlusResearch = {
  __typename?: 'ProjectAskablePlusResearch';
  competitive_analysis?: Maybe<ProjectAskablePlusResearchConfig>;
  /**  Continuous Discovery  */
  continuous_ai_moderated?: Maybe<ProjectAskablePlusResearchConfig>;
  continuous_researcher_moderated?: Maybe<ProjectAskablePlusResearchConfig>;
  discovery?: Maybe<ProjectAskablePlusResearchConfig>;
  longitudinal?: Maybe<ProjectAskablePlusResearchConfig>;
  survey?: Maybe<ProjectAskablePlusResearchConfig>;
  usability?: Maybe<ProjectAskablePlusResearchConfig>;
};

export type ProjectAskablePlusResearchConfig = {
  __typename?: 'ProjectAskablePlusResearchConfig';
  Booking?: Maybe<Booking>;
  _booking_id?: Maybe<Scalars['ID']['output']>;
  booking_type?: Maybe<Scalars['Int']['output']>;
  continuous?: Maybe<Scalars['Boolean']['output']>;
  /**  longitudinal config  */
  longitudinal_study?: Maybe<LongitudinalStudyBookingConfig>;
  quota?: Maybe<Scalars['Int']['output']>;
  /**  booking session config  */
  session?: Maybe<SessionBookingConfig>;
};

export type ProjectAskablePlusResearchConfigInput = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  booking_type?: InputMaybe<Scalars['Int']['input']>;
  continuous?: InputMaybe<Scalars['Boolean']['input']>;
  /**  longitudinal config  */
  longitudinal_study?: InputMaybe<BookingInputConfigLongitudinalStudy>;
  quota?: InputMaybe<Scalars['Int']['input']>;
  /**  booking session config  */
  session?: InputMaybe<BookingInputConfigSession>;
};

export type ProjectAskablePlusResearchInput = {
  competitive_analysis?: InputMaybe<ProjectAskablePlusResearchConfigInput>;
  /**  Continuous Discovery  */
  continuous_ai_moderated?: InputMaybe<ProjectAskablePlusResearchConfigInput>;
  continuous_researcher_moderated?: InputMaybe<ProjectAskablePlusResearchConfigInput>;
  discovery?: InputMaybe<ProjectAskablePlusResearchConfigInput>;
  longitudinal?: InputMaybe<ProjectAskablePlusResearchConfigInput>;
  survey?: InputMaybe<ProjectAskablePlusResearchConfigInput>;
  usability?: InputMaybe<ProjectAskablePlusResearchConfigInput>;
};

export type ProjectAskablePlusResearcherDeliverables = {
  __typename?: 'ProjectAskablePlusResearcherDeliverables';
  executive_summary?: Maybe<Array<Maybe<ProjectAskablePlusResearcherDeliverablesDetails>>>;
  highlight_reel?: Maybe<Array<Maybe<ProjectAskablePlusResearcherDeliverablesDetails>>>;
  other?: Maybe<Array<Maybe<ProjectAskablePlusResearcherDeliverablesDetails>>>;
  research_report?: Maybe<Array<Maybe<ProjectAskablePlusResearcherDeliverablesDetails>>>;
  transcriptions?: Maybe<Array<Maybe<ProjectAskablePlusResearcherDeliverablesDetails>>>;
};

export type ProjectAskablePlusResearcherDeliverablesDetails = {
  __typename?: 'ProjectAskablePlusResearcherDeliverablesDetails';
  url?: Maybe<Scalars['String']['output']>;
};

export type ProjectAskablePlusResearcherDeliverablesDetailsInput = {
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectAskablePlusResearcherDeliverablesInput = {
  executive_summary?: InputMaybe<Array<InputMaybe<ProjectAskablePlusResearcherDeliverablesDetailsInput>>>;
  highlight_reel?: InputMaybe<Array<InputMaybe<ProjectAskablePlusResearcherDeliverablesDetailsInput>>>;
  other?: InputMaybe<Array<InputMaybe<ProjectAskablePlusResearcherDeliverablesDetailsInput>>>;
  research_report?: InputMaybe<Array<InputMaybe<ProjectAskablePlusResearcherDeliverablesDetailsInput>>>;
  transcriptions?: InputMaybe<Array<InputMaybe<ProjectAskablePlusResearcherDeliverablesDetailsInput>>>;
};

export type ProjectBookingConfig = {
  __typename?: 'ProjectBookingConfig';
  allowed_credits?: Maybe<Scalars['Float']['output']>;
  /** 1 - Askable+ */
  project_type?: Maybe<Scalars['Int']['output']>;
};

export type ProjectBookingInputConfig = {
  allowed_credits?: InputMaybe<Scalars['Float']['input']>;
  /** 1 - Askable+ */
  project_type?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectConnectionFilter = {
  _team_id?: InputMaybe<FilterId>;
  status?: InputMaybe<Array<InputMaybe<ProjectStatus>>>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  askable_plus?: InputMaybe<ProjectAskablePlusDetailsInput>;
  cloned?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /**  0: Draft, 1: Active, 5: Completed, 7: Archived  */
  status?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<TeamInput>;
  /**  0: Default/none, 1: Askable+  */
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectInputRating = {
  additional_information?: InputMaybe<Scalars['String']['input']>;
  overall?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectPricing = {
  __typename?: 'ProjectPricing';
  booking_credits?: Maybe<Scalars['Int']['output']>;
  detail?: Maybe<ProjectPricingDetailType>;
  researcher_hours?: Maybe<Scalars['Float']['output']>;
  total_credits?: Maybe<Scalars['Int']['output']>;
  total_participants?: Maybe<Scalars['Int']['output']>;
};

export type ProjectPricingDetailType = {
  __typename?: 'ProjectPricingDetailType';
  booking_credits?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  researcher_hours?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ProjectProgress = {
  __typename?: 'ProjectProgress';
  current_status?: Maybe<ProjectProgressStatus>;
  delivery?: Maybe<ProjectProgressGroup>;
  in_review?: Maybe<ProjectProgressGroup>;
  kick_off?: Maybe<ProjectProgressGroup>;
  research?: Maybe<ProjectProgressGroup>;
};

export type ProjectProgressGroup = {
  __typename?: 'ProjectProgressGroup';
  steps?: Maybe<Array<Maybe<ProjectProgressItem>>>;
};

export type ProjectProgressItem = {
  __typename?: 'ProjectProgressItem';
  key?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ProjectProgressItemStatus>;
};

export enum ProjectProgressItemStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  ToDo = 'to_do',
}

export enum ProjectProgressStatus {
  Delivery = 'delivery',
  InReview = 'in_review',
  KickOff = 'kick_off',
  Research = 'research',
}

export type ProjectRating = {
  __typename?: 'ProjectRating';
  additional_information?: Maybe<Scalars['String']['output']>;
  overall?: Maybe<Scalars['Int']['output']>;
};

export enum ProjectStatus {
  Active = 'active',
  Archived = 'archived',
  Completed = 'completed',
  Draft = 'draft',
}

export type ProjectUser = {
  __typename?: 'ProjectUser';
  User?: Maybe<User>;
  _id: Scalars['ID']['output'];
  accepted_at?: Maybe<Scalars['Float']['output']>;
  added_date?: Maybe<Scalars['Float']['output']>;
  invited_at?: Maybe<Scalars['Float']['output']>;
  role?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ProjectUserStatus>;
};

export enum ProjectUserStatus {
  Accepted = 'accepted',
  Applied = 'applied',
  Declined = 'declined',
  Expired = 'expired',
  Invited = 'invited',
}

export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  nodes?: Maybe<Array<Maybe<Project>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProjectsConnectionNew = {
  __typename?: 'ProjectsConnectionNew';
  nodes?: Maybe<Array<Maybe<Project>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProjectsFilter = {
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<FilterProjectStatus>;
  type?: InputMaybe<FilterTypeInt>;
};

export type ProviderTransactions = {
  __typename?: 'ProviderTransactions';
  /**  Name of the 3rd party integration  */
  name?: Maybe<Scalars['String']['output']>;
  /**  Id in the format that the 3rd party uses  */
  reference?: Maybe<Scalars['String']['output']>;
  /**  URL to view more details about the transaction  */
  url?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  adminCurrentWigProgress?: Maybe<AdminWig>;
  adminUsers?: Maybe<Array<Maybe<User>>>;
  adminWigById?: Maybe<AdminWig>;
  /**  A full list  */
  adminWigs?: Maybe<Array<Maybe<AdminWig>>>;
  analyses: AnalysesConnection;
  analysesCount: Array<AnalysesCount>;
  analysesCountByFacilitator: Array<AnalysesFacilitatorCount>;
  analysesInsights: AnalysesInsightsConnection;
  analysesThemes: AnalysesThemeConnection;
  askableVideoMeetingByCode?: Maybe<AskableVideo>;
  /** code is optional but helps with index */
  askableVideoRoomById?: Maybe<AskableVideoRoomsType>;
  batchResults?: Maybe<Array<Maybe<BatchResult>>>;
  bookingByID?: Maybe<Booking>;
  bookingParticipantMessages?: Maybe<Booking>;
  bookingSessionByID?: Maybe<BookingSession>;
  bookingSessionsByID?: Maybe<Array<Maybe<BookingSession>>>;
  bookingSessionsWithParticipants?: Maybe<Booking>;
  bookingSubmissionById?: Maybe<Submission>;
  bookingSubmissionConnection?: Maybe<BookingSubmissionConnection>;
  /** Find all the submissions for a booking and their first message */
  bookingSubmissionsWithMessages?: Maybe<BookingSubmissionsWithMessages>;
  bookingTaskEvent?: Maybe<BookingTaskEvent>;
  bookingTaskEvents: BookingTaskEventsCollection;
  bookingTaskResult?: Maybe<BookingTaskResult>;
  bookingTaskResultFigmaPrototype: FigmaPrototypeResult;
  bookingTaskResultOpinionScale: OpinionScaleResult;
  bookingTaskResults: BookingTaskResultsCollection;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  /** Query to search all bookings by user id */
  bookingsByParticipant?: Maybe<Array<Maybe<Booking>>>;
  bookingsByTeam?: Maybe<Array<Maybe<Booking>>>;
  bookingsConnection: BookingsConnection;
  collection?: Maybe<Collections>;
  collections: CollectionsConnection;
  countBatchMatches?: Maybe<Scalars['Int']['output']>;
  countryList?: Maybe<Array<Maybe<CountryData>>>;
  coupon?: Maybe<Coupon>;
  coupons?: Maybe<Array<Maybe<Coupon>>>;
  creditActivityByBooking?: Maybe<Array<Maybe<CreditActivity>>>;
  creditActivityByTeam?: Maybe<Array<Maybe<CreditActivity>>>;
  creditPacks?: Maybe<Array<Maybe<Credits>>>;
  creditsPricingReplacements?: Maybe<Array<Maybe<CreditsPricingReplacement>>>;
  demoBooking?: Maybe<Booking>;
  eSignatureTemplatesByTeam?: Maybe<Array<Maybe<ESignatureTemplate>>>;
  figmaFile?: Maybe<FigmaFile>;
  findAllBookingSubmissions?: Maybe<Array<Maybe<Submission>>>;
  findBookingSubmission?: Maybe<Array<Maybe<Submission>>>;
  /** Query to find and return a participant session by ID. Old query: bookingParticipant */
  findParticipantSessionById?: Maybe<SessionsType>;
  /**  based on booking team / organisation  */
  getBookingESignatureTemplates?: Maybe<Array<Maybe<ESignatureTemplate>>>;
  getESignaturePreviewURL?: Maybe<Scalars['String']['output']>;
  getInvoiceURL?: Maybe<InvoiceUrlType>;
  getLocationByAddress?: Maybe<Location>;
  getLocationFromGooglePlaceId?: Maybe<Location>;
  highlightById?: Maybe<HighlightType>;
  highlights: HighlightsConnection;
  industryList?: Maybe<Array<Maybe<IndustryType>>>;
  insight?: Maybe<Insight>;
  insights: InsightsConnection;
  latestAgreementByType?: Maybe<Agreement>;
  listAvailablePayoutMethods?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  locationAutocomplete?: Maybe<Array<Maybe<LocationAutoCompleteResult>>>;
  messages?: Maybe<Array<Maybe<Messages>>>;
  messagesSeen?: Maybe<Array<Maybe<Messages>>>;
  notificationsByTeam?: Maybe<Array<Maybe<Notifications>>>;
  omnisearch?: Maybe<OmnisearchResult>;
  /** Query to search opportunities to be displayed on the participants feed. _user_id in the second argument is used to filter deep return fields */
  opportunitiesListSearch?: Maybe<Array<Maybe<Booking>>>;
  organisationById?: Maybe<OrganisationType>;
  organisations?: Maybe<Array<Maybe<OrganisationType>>>;
  participantBookingsWithMessages?: Maybe<Array<Maybe<Booking>>>;
  participantReferralLink?: Maybe<Scalars['String']['output']>;
  /** Query to search participant sessions by booking. Old query: bookingParticipantsByBooking */
  participantSessionsByBooking?: Maybe<Array<Maybe<SessionsType>>>;
  /** Query to search participant sessions by many different arguments. Old query: bookingParticipantSearch */
  participantSessionsSearch?: Maybe<Array<Maybe<SessionsType>>>;
  /** Search participant sessions with their transactions. Old query: bookingParticipantsWithTransactions */
  participantSessionsWithTransactions?: Maybe<Array<Maybe<SessionsType>>>;
  playbackGetHighlights: Array<Maybe<HighlightType>>;
  playbackMediaUrl?: Maybe<Scalars['String']['output']>;
  projectAskablePlusCreditPrice?: Maybe<ProjectPricing>;
  projectByID?: Maybe<Project>;
  projects?: Maybe<ProjectsConnectionNew>;
  projectsByTeam?: Maybe<Array<Maybe<Project>>>;
  projectsConnection?: Maybe<ProjectsConnection>;
  resolveShareLink: ShareLink;
  roomNotes?: Maybe<Array<Maybe<AskableVideoRoomNote>>>;
  roomTranscript?: Maybe<AskableVideoTranscriptType>;
  search?: Maybe<Array<Maybe<SearchResultItem>>>;
  searchAskableMeeting?: Maybe<Array<Maybe<AskableVideo>>>;
  searchLogs?: Maybe<Array<Maybe<LogEntry>>>;
  segmentsByBookingId?: Maybe<Array<Maybe<Segment>>>;
  settings?: Maybe<Array<Maybe<Settings>>>;
  settingsByKey?: Maybe<Settings>;
  shareRequests: ShareRequestsCollection;
  shares: SharesConnection;
  tags: TagsConnection;
  taxes?: Maybe<Array<Maybe<Taxes>>>;
  teamById?: Maybe<Teams>;
  teamInvoiceHistory?: Maybe<Array<Maybe<Transactions>>>;
  teams?: Maybe<Array<Maybe<Teams>>>;
  teamsByEmailMatch?: Maybe<Array<Maybe<Teams>>>;
  teamsByParentTeam?: Maybe<Array<Maybe<Teams>>>;
  teamsBySsoDomain?: Maybe<Array<Maybe<Teams>>>;
  teamsByUser?: Maybe<Array<Maybe<Teams>>>;
  transactionsByTeam?: Maybe<Array<Maybe<Transactions>>>;
  /**  Get the long URL from a short URL token. Used for deeplinking shorturl.  */
  translateShortUrl: Scalars['String']['output'];
  unauthShareRequest: Scalars['Boolean']['output'];
  unmoderatedBooking: Booking;
  unreadMessages: Array<Scalars['ID']['output']>;
  unreadNotificationsCountByTeam?: Maybe<Array<Maybe<NotificationsCount>>>;
  userAdminMessages?: Maybe<Array<Maybe<Messages>>>;
  userAgent?: Maybe<UserAgentType>;
  userByEmail?: Maybe<User>;
  userByID?: Maybe<User>;
  /**  Public route to resolve whether the user ID exists in the database and is active  */
  userByIDPublic?: Maybe<Scalars['Boolean']['output']>;
  userByIDs?: Maybe<Array<Maybe<User>>>;
  userConnection?: Maybe<UserConnection>;
  userDevice?: Maybe<UserDeviceType>;
  userMe?: Maybe<User>;
  userRegisteredBookings?: Maybe<Array<Maybe<Booking>>>;
  userSubmissionLatestAnalysis?: Maybe<UserSubmissionAnalysis>;
  users: UserConnection;
  validatePayPal?: Maybe<ValidatePayPalResponse>;
  viewer?: Maybe<User>;
  viewerFigmaAuthStatus?: Maybe<Scalars['Boolean']['output']>;
};

export type QueryAdminCurrentWigProgressArgs = {
  date?: InputMaybe<Scalars['Float']['input']>;
  key: Scalars['String']['input'];
  operational_office?: InputMaybe<OperationalOffices>;
  period_type?: InputMaybe<AdminWigPeriodTypes>;
};

export type QueryAdminUsersArgs = {
  customer_success?: InputMaybe<Scalars['Boolean']['input']>;
  growth?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAdminWigByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryAdminWigsArgs = {
  search?: InputMaybe<AdminWigInput>;
};

export type QueryAnalysesArgs = {
  filter: AnalysesFilter;
  first: Scalars['Int']['input'];
};

export type QueryAnalysesCountArgs = {
  filter?: InputMaybe<AnalysesCountFilter>;
};

export type QueryAnalysesCountByFacilitatorArgs = {
  filter?: InputMaybe<AnalysesCountFilter>;
};

export type QueryAnalysesInsightsArgs = {
  filter: AnalysesInsightsFilter;
  first: Scalars['Int']['input'];
};

export type QueryAnalysesThemesArgs = {
  filter: AnalysesThemeFilter;
  first: Scalars['Int']['input'];
};

export type QueryAskableVideoMeetingByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryAskableVideoRoomByIdArgs = {
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
};

export type QueryBatchResultsArgs = {
  search?: InputMaybe<BatchResultInput>;
};

export type QueryBookingByIdArgs = {
  currency_code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  participant_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  status?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBookingParticipantMessagesArgs = {
  _booking_id: Scalars['ID']['input'];
  _user_id: Scalars['ID']['input'];
};

export type QueryBookingSessionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBookingSessionsByIdArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryBookingSessionsWithParticipantsArgs = {
  booking_id: Scalars['ID']['input'];
  session_id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBookingSubmissionByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryBookingSubmissionConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<BookingSubmissionConnectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBookingSubmissionsWithMessagesArgs = {
  bookingId: Scalars['ID']['input'];
  displayParticipantsWithoutAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  hideBatchMessages?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBookingTaskEventArgs = {
  _id: Scalars['ObjectID']['input'];
};

export type QueryBookingTaskEventsArgs = {
  filter?: InputMaybe<BookingTaskEventsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBookingTaskResultArgs = {
  filter: BookingTaskResultFilterInput;
};

export type QueryBookingTaskResultFigmaPrototypeArgs = {
  _booking_id: Scalars['ID']['input'];
  _task_id: Scalars['ID']['input'];
};

export type QueryBookingTaskResultOpinionScaleArgs = {
  _booking_id: Scalars['ID']['input'];
  _task_id: Scalars['ID']['input'];
};

export type QueryBookingTaskResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BookingTaskResultsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBookingsArgs = {
  demo?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryBookingsByParticipantArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryBookingsByTeamArgs = {
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  excludeAskablePlusBookings?: InputMaybe<Scalars['Boolean']['input']>;
  excludeBookingsWithNoQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  excludeDemoBookings?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryBookingsConnectionArgs = {
  filter?: InputMaybe<BookingsFilter>;
  first: Scalars['Int']['input'];
};

export type QueryCollectionArgs = {
  filter: CollectionFilter;
};

export type QueryCollectionsArgs = {
  filter: CollectionsFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<CollectionsOrderBy>;
};

export type QueryCountBatchMatchesArgs = {
  batch: BatchInput;
};

export type QueryCouponArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCreditActivityByBookingArgs = {
  _booking_id: Scalars['ID']['input'];
  type?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryCreditActivityByTeamArgs = {
  _team_id: Scalars['ID']['input'];
  type?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryCreditPacksArgs = {
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDemoBookingArgs = {
  _team_id: Scalars['ID']['input'];
};

export type QueryESignatureTemplatesByTeamArgs = {
  _team_id: Scalars['ID']['input'];
};

export type QueryFigmaFileArgs = {
  fileId: Scalars['String']['input'];
};

export type QueryFindAllBookingSubmissionsArgs = {
  bookingId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFindBookingSubmissionArgs = {
  bookingId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  studyId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFindParticipantSessionByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryGetBookingESignatureTemplatesArgs = {
  _booking_id: Scalars['ID']['input'];
};

export type QueryGetESignaturePreviewUrlArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryGetInvoiceUrlArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryGetLocationByAddressArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryGetLocationFromGooglePlaceIdArgs = {
  placeId?: InputMaybe<Scalars['String']['input']>;
  sessionToken?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHighlightByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryHighlightsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<HighlightsConnectionFilter>;
  first: Scalars['Int']['input'];
  locale?: InputMaybe<Locale>;
};

export type QueryInsightArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryInsightsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InsightsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<InsightsOrderBy>;
};

export type QueryLatestAgreementByTypeArgs = {
  user_type: AgreementUserTypeInput;
};

export type QueryLocationAutocompleteArgs = {
  search: LocationAutoCompleteSearch;
};

export type QueryMessagesArgs = {
  search: MessageSearchInput;
};

export type QueryMessagesSeenArgs = {
  _messages_id: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryNotificationsByTeamArgs = {
  _team_id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOmnisearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type QueryOpportunitiesListSearchArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  search: OpportunitySearchInput;
};

export type QueryOrganisationByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryParticipantBookingsWithMessagesArgs = {
  _user_id: Scalars['ID']['input'];
};

export type QueryParticipantSessionsByBookingArgs = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  cancel?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryParticipantSessionsSearchArgs = {
  _booking_id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  _participant_session_id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  _session_id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  _user_id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  cancel?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryParticipantSessionsWithTransactionsArgs = {
  _user_id: Scalars['ID']['input'];
};

export type QueryPlaybackGetHighlightsArgs = {
  _room_id: Scalars['ID']['input'];
  meeting_code: Scalars['String']['input'];
};

export type QueryPlaybackMediaUrlArgs = {
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
};

export type QueryProjectAskablePlusCreditPriceArgs = {
  project: ProjectInput;
};

export type QueryProjectByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProjectsByTeamArgs = {
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryProjectsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectConnectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryResolveShareLinkArgs = {
  payload: ShareTypeInput;
  share_id: Scalars['ID']['input'];
};

export type QueryRoomNotesArgs = {
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
};

export type QueryRoomTranscriptArgs = {
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
  locale?: InputMaybe<Locale>;
};

export type QuerySearchArgs = {
  query: Scalars['String']['input'];
  type?: InputMaybe<Array<InputMaybe<SearchResultItemType>>>;
};

export type QuerySearchAskableMeetingArgs = {
  input: AskableVideoInput;
};

export type QuerySearchLogsArgs = {
  search: LogSearchInput;
};

export type QuerySegmentsByBookingIdArgs = {
  bookingId: Scalars['ID']['input'];
};

export type QuerySettingsByKeyArgs = {
  key: Scalars['String']['input'];
};

export type QueryShareRequestsArgs = {
  filter: ShareRequestsFilter;
  first: Scalars['Int']['input'];
};

export type QuerySharesArgs = {
  filter?: InputMaybe<SharesFilter>;
  first: Scalars['Int']['input'];
};

export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<TagsConnectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<TagsConnectionSort>;
};

export type QueryTeamByIdArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTeamInvoiceHistoryArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryTeamsArgs = {
  search?: InputMaybe<TeamsSearchInput>;
};

export type QueryTeamsByEmailMatchArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTeamsByParentTeamArgs = {
  _parent_team: Scalars['ID']['input'];
};

export type QueryTeamsBySsoDomainArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTeamsByUserArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTransactionsByTeamArgs = {
  _team_id: Scalars['ID']['input'];
  from?: InputMaybe<Scalars['Float']['input']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type QueryTranslateShortUrlArgs = {
  token: Scalars['String']['input'];
};

export type QueryUnauthShareRequestArgs = {
  filter: UnauthShareRequestsFilter;
};

export type QueryUnmoderatedBookingArgs = {
  _id: Scalars['ObjectID']['input'];
};

export type QueryUnreadMessagesArgs = {
  bookingId: Scalars['ID']['input'];
};

export type QueryUnreadNotificationsCountByTeamArgs = {
  _team_id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserAdminMessagesArgs = {
  _user_id: Scalars['ID']['input'];
};

export type QueryUserAgentArgs = {
  UserAgent?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryUserByIdArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserByIdPublicArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserByIDsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserConnectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserDeviceArgs = {
  UserAgent?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserMeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserRegisteredBookingsArgs = {
  _user_id: Scalars['ID']['input'];
};

export type QueryUserSubmissionLatestAnalysisArgs = {
  _user_id: Scalars['ObjectID']['input'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<UsersFilter>;
  first: Scalars['Int']['input'];
  strict?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryValidatePayPalArgs = {
  client_code: Scalars['String']['input'];
};

export type QuestionBookingConfig = {
  __typename?: 'QuestionBookingConfig';
  _id?: Maybe<Scalars['ID']['output']>;
  config?: Maybe<ConfigQuestionBookingConfig>;
  description?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<OptionsQuestionBookingConfig>>>;
  previous_responses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type QuestionBookingConfigPrevious_ResponsesArgs = {
  search?: InputMaybe<PreviousResponseInput>;
};

export type RateUserSubmissionAnalysisInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  inconsistency_reasoning_accurate: Scalars['Boolean']['input'];
  inconsistency_verdict_accurate: Scalars['Boolean']['input'];
  user_was_dishonest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RatingCount = {
  __typename?: 'RatingCount';
  count: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
};

export type RatingParticipantInput = {
  _submission_id: Scalars['ID']['input'];
  overall: Scalars['Int']['input'];
};

export type RecruitmentBookingConfig = {
  __typename?: 'RecruitmentBookingConfig';
  ClientShareLink?: Maybe<ShortUrl>;
  _client_share_link_id?: Maybe<Scalars['ID']['output']>;
  byo?: Maybe<Scalars['Boolean']['output']>;
};

export type ReinviteSubmissionInput = {
  _booking_id: Scalars['ID']['input'];
  _submission_id: Scalars['ID']['input'];
  activeSessionId: Scalars['ID']['input'];
  customMessage?: InputMaybe<Scalars['String']['input']>;
};

export type RemoteBookingConfig = {
  __typename?: 'RemoteBookingConfig';
  askable_live?: Maybe<Scalars['Boolean']['output']>;
  tool?: Maybe<Scalars['String']['output']>;
};

export type RemoveResearcherInput = {
  _project_id: Scalars['ObjectID']['input'];
  _user_id: Scalars['ObjectID']['input'];
};

export enum ReportReason {
  CommunicationIssues = 'communication_issues',
  InaccurateScreenerAnswers = 'inaccurate_screener_answers',
  Others = 'others',
  ParticipantNoShow = 'participant_no_show',
  SubmissionQuality = 'submission_quality',
  TechnicalIssues = 'technical_issues',
}

export enum ReportRequest {
  Refund = 'refund',
  Replace = 'replace',
}

export type RequestAccessInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  type: ShareType;
};

export type RequestBrowserEmbedInput = {
  _id: Scalars['ObjectID']['input'];
  _task_id: Scalars['ObjectID']['input'];
  /** Optional base URL that should be used for Hyperbeam, this is only applied for development or testing, not in production */
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  options: Scalars['JSON']['input'];
};

export type RequestToJoinRoomInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['String']['input'];
};

export type RequestTranscriptionInput = {
  _room_id?: InputMaybe<Scalars['ID']['input']>;
  code: Scalars['String']['input'];
  room_sid?: InputMaybe<Scalars['String']['input']>;
};

export type RequestTranslationInput = {
  _transcript_id: Scalars['ObjectID']['input'];
  locale: Locale;
};

export type RescheduleSessionInput = {
  _submission_id: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  newSessionId: Scalars['ID']['input'];
};

export type Researcher = {
  __typename?: 'Researcher';
  _id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ResearcherCertification = {
  __typename?: 'ResearcherCertification';
  status?: Maybe<ResearcherCertificationStatus>;
  video_ask_contact_id?: Maybe<Scalars['String']['output']>;
  video_ask_form_id?: Maybe<Scalars['String']['output']>;
  video_ask_interaction_id?: Maybe<Scalars['String']['output']>;
  video_ask_status?: Maybe<Scalars['String']['output']>;
  video_ask_submission_url?: Maybe<Scalars['String']['output']>;
};

export type ResearcherCertificationInput = {
  status?: InputMaybe<ResearcherCertificationStatus>;
};

export type ResearcherCertificationResponse = {
  __typename?: 'ResearcherCertificationResponse';
  redirect_url: Scalars['String']['output'];
};

export enum ResearcherCertificationStatus {
  Certified = 'certified',
  InProgress = 'in_progress',
  InProgressDropped = 'in_progress_dropped',
  InReview = 'in_review',
  Ineligible = 'ineligible',
  NotCertified = 'not_certified',
  OnHold = 'on_hold',
  Started = 'started',
}

export enum ResearcherMethods {
  CompetitiveAnalysis = 'competitive_analysis',
  ContinuousAiModerated = 'continuous_ai_moderated',
  ContinuousResearcherModerated = 'continuous_researcher_moderated',
  Discovery = 'discovery',
  Longitudinal = 'longitudinal',
  Survey = 'survey',
  Usability = 'usability',
}

export type RoomParticipantsType = {
  __typename?: 'RoomParticipantsType';
  identity: Scalars['String']['output'];
};

export type RoomToken = {
  __typename?: 'RoomToken';
  token: Scalars['String']['output'];
};

export type RootSchema = {
  __typename?: 'RootSchema';
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type ScreenImage = {
  __typename?: 'ScreenImage';
  height: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  src: Scalars['String']['output'];
  updated?: Maybe<Scalars['Float']['output']>;
  width: Scalars['Int']['output'];
};

export type SearchResultItem = {
  __typename?: 'SearchResultItem';
  _id?: Maybe<Scalars['ID']['output']>;
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  score?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<SearchResultItemType>;
};

export enum SearchResultItemType {
  Booking = 'booking',
  Organisation = 'organisation',
  Project = 'project',
  Team = 'team',
  Users = 'users',
}

export type Segment = {
  __typename?: 'Segment';
  _id: Scalars['ID']['output'];
  filters?: Maybe<Array<Maybe<SegmentFilter>>>;
  method?: Maybe<SegmentMethod>;
  name: Scalars['String']['output'];
  sort?: Maybe<Array<Maybe<SegmentSortValue>>>;
  tableConfig?: Maybe<SegmentsTableConfig>;
};

export type SegmentColumnConfig = {
  __typename?: 'SegmentColumnConfig';
  column?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type SegmentColumnConfigInput = {
  column?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SegmentFilter = {
  __typename?: 'SegmentFilter';
  _id: Scalars['ID']['output'];
  field: Scalars['String']['output'];
  method: SegmentFilterMethod;
  values: Array<Maybe<SegmentFilterValue>>;
};

export type SegmentFilterInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  field: Scalars['String']['input'];
  method: SegmentFilterMethod;
  values: Array<SegmentFilterValueInput>;
};

export enum SegmentFilterMethod {
  AnyOf = 'any_of',
  Contains = 'contains',
  DoesNotContain = 'does_not_contain',
  EqualTo = 'equal_to',
  GreaterOrEqualTo = 'greater_or_equal_to',
  GreaterThan = 'greater_than',
  LessOrEqualTo = 'less_or_equal_to',
  LessThan = 'less_than',
  NoneOf = 'none_of',
  NotEqualTo = 'not_equal_to',
}

export type SegmentFilterValue = {
  __typename?: 'SegmentFilterValue';
  label: Scalars['String']['output'];
  value_numeric?: Maybe<Scalars['Float']['output']>;
  value_string?: Maybe<Scalars['String']['output']>;
};

export type SegmentFilterValueInput = {
  label: Scalars['String']['input'];
  value_numeric?: InputMaybe<Scalars['Float']['input']>;
  value_string?: InputMaybe<Scalars['String']['input']>;
};

export type SegmentInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<InputMaybe<SegmentFilterInput>>>;
  method: SegmentMethod;
  name: Scalars['String']['input'];
  sort?: InputMaybe<Array<InputMaybe<SegmentSortValue>>>;
  tableConfig?: InputMaybe<SegmentTableConfigInput>;
};

export enum SegmentMethod {
  And = 'and',
  Or = 'or',
}

export enum SegmentSortValue {
  Age = 'age',
  Eligibility = 'eligibility',
  Session = 'session',
  Status = 'status',
  StatusUpdated = 'status_updated',
}

export type SegmentTableConfigInput = {
  columns?: InputMaybe<Array<InputMaybe<SegmentColumnConfigInput>>>;
};

export type SegmentsTableConfig = {
  __typename?: 'SegmentsTableConfig';
  columns?: Maybe<Array<Maybe<SegmentColumnConfig>>>;
};

export type SessionBookingConfig = {
  __typename?: 'SessionBookingConfig';
  duration?: Maybe<Scalars['Int']['output']>;
  slot_max?: Maybe<Scalars['Int']['output']>;
  slot_min?: Maybe<Scalars['Int']['output']>;
  time_limit?: Maybe<Scalars['Int']['output']>;
  /** 1 - One on One Session, 2 - Everyone in one group, 3 - Multi Group */
  type?: Maybe<Scalars['Int']['output']>;
};

export enum SessionState {
  Active = 'active',
  Inactive = 'inactive',
}

export type SessionsType = {
  __typename?: 'SessionsType';
  OnlineTaskLink?: Maybe<LinkOnlineTaskBookingConfig>;
  Submission?: Maybe<Submission>;
  /** @deprecated Use the _booking_id on the root of the booking submissions document */
  _booking_id?: Maybe<Scalars['ID']['output']>;
  /** Unique id. It used to be the same as bookingParticipants._id */
  _id?: Maybe<Scalars['ID']['output']>;
  /** Online task link that the user got sent to */
  _online_task_link_id?: Maybe<Scalars['ID']['output']>;
  /** Id of the session selected by the user. Useful to connect to the booking session details */
  _session_id?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use the _user_id on the root of the booking submissions document */
  _user_id?: Maybe<Scalars['ID']['output']>;
  /** Virtual field that returns the booking in case _booking_id is present */
  booking?: Maybe<Booking>;
  /** 0 - Not Cancel, 1 - Cancel by Admin, 2 - Cancel by Client, 3 - Cancel by Participant, 4 - Cancel because NoShow, 5 - Cancel by system (cron, etc), 6 - Cancelled because Rescheduled */
  cancel?: Maybe<Scalars['Int']['output']>;
  /** Reason why the participant cancelled his session */
  cancel_reason?: Maybe<Scalars['String']['output']>;
  client_report_details?: Maybe<Scalars['String']['output']>;
  client_report_reason?: Maybe<ReportReason>;
  /** Field to point out to the bookingSubmission.completed */
  completed?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  /**
   *  Extra information about how the session was created
   * 1 - Participant availability,
   * 2 - Client new session,
   * 3 - Client invited to unavailable session
   */
  created_context?: Maybe<Scalars['Int']['output']>;
  /** Field to point out to the bookingSubmission.feedback */
  feedback?: Maybe<BookingFeedback>;
  /** Field to point out to the bookingSubmission.help_requested */
  help_requested?: Maybe<Scalars['Float']['output']>;
  history?: Maybe<BookingSessionHistory>;
  /** 1 - Refund, 2 - Replace */
  issue_request?: Maybe<Scalars['Int']['output']>;
  /** 1 - Refund, 2 - Replace, 3 - Credit */
  no_show_request?: Maybe<Scalars['Int']['output']>;
  /** Field to point out to the bookingSubmission.rating */
  rating?: Maybe<BookingSubmissionRating>;
  session?: Maybe<BookingSession>;
  /** New status named state which determines that session is active or not */
  state?: Maybe<SessionState>;
  /** 1 - Confirmed, 2 - Waitlisted, 3 - Available, 4 - Invited, 5 - Task in progress */
  status?: Maybe<Scalars['Int']['output']>;
  status_updated?: Maybe<Scalars['Float']['output']>;
  /** Field to point out to the bookingSubmission.transaction */
  transaction?: Maybe<BookingSubmissionsTransaction>;
  updated?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<User>;
  user_confirm?: Maybe<Scalars['Float']['output']>;
};

export type Settings = {
  __typename?: 'Settings';
  _id: Scalars['ID']['output'];
  key?: Maybe<SettingsKey>;
  /**  The value represented as a string, if it can be  */
  value?: Maybe<Scalars['String']['output']>;
  /**  The value represented as JSON  */
  value_raw?: Maybe<Scalars['JSON']['output']>;
};

export type SettingsAgreementInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['Float']['input']>;
};

export type SettingsBillingBankInput = {
  account_name?: InputMaybe<Scalars['String']['input']>;
  account_number?: InputMaybe<Scalars['String']['input']>;
  branch?: InputMaybe<Scalars['String']['input']>;
};

export type SettingsBillingInput = {
  bank?: InputMaybe<SettingsBillingBankInput>;
  giftpay?: InputMaybe<Scalars['String']['input']>;
  paypal?: InputMaybe<Scalars['String']['input']>;
  paypal_details?: InputMaybe<PayPalDetailsInput>;
  preferred?: InputMaybe<Scalars['String']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
};

export type SettingsEnrollInput = {
  beta?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SettingsInput = {
  agreement?: InputMaybe<SettingsAgreementInput>;
  billing?: InputMaybe<SettingsBillingInput>;
  enroll?: InputMaybe<SettingsEnrollInput>;
  notification?: InputMaybe<SettingsNotificationInput>;
  onboarding?: InputMaybe<SettingsOnboardingInput>;
  remote?: InputMaybe<SettingsRemoteInput>;
  restrictions?: InputMaybe<SettingsRestrictionsInput>;
  tutorial?: InputMaybe<SettingsTutorialInput>;
};

export enum SettingsKey {
  AdminUsers = 'admin_users',
  AdminWigMeta = 'admin_wig_meta',
  AdminWigSkipDates = 'admin_wig_skip_dates',
  AdminWigSkipDatesAu = 'admin_wig_skip_dates_au',
  AdminWigSkipDatesUk = 'admin_wig_skip_dates_uk',
  AdminWigSkipDatesUs = 'admin_wig_skip_dates_us',
  AskableCheapPhoneNumber = 'askable_cheap_phone_number',
  AskablePhoneNumber = 'askable_phone_number',
  AskablePhoneNumberList = 'askable_phone_number_list',
  AskablePhoneNumberPretty = 'askable_phone_number_pretty',
  AskablePlusPricing = 'askable_plus_pricing',
  AvailableCountries = 'available_countries',
  BookingFeeRecruitmentComplexAu = 'booking_fee_recruitment_complex_AU',
  BookingFeeRecruitmentDefaultAu = 'booking_fee_recruitment_default_AU',
  BookingFeeRecruitmentDefaultCreditAu = 'booking_fee_recruitment_default_credit_AU',
  ChannelBookings = 'channel_bookings',
  ChannelBookingsUk = 'channel_bookings_uk',
  ChannelCancellations = 'channel_cancellations',
  ChannelClientRegistration = 'channel_client_registration',
  ChannelInvitations = 'channel_invitations',
  ChannelInvoices = 'channel_invoices',
  ChannelParticipantHelp = 'channel_participant_help',
  ChannelPayments = 'channel_payments',
  ChannelReminders = 'channel_reminders',
  ChannelWarnings = 'channel_warnings',
  CreditPackSuffixNumbers = 'credit_pack_suffix_numbers',
  IgnoredBookingPayments = 'ignored_booking_payments',
  IgnoredCronBookings = 'ignored_cron_bookings',
  InvoiceReferenceSuffix = 'invoice_reference_suffix',
  NewBookingSessionTimeLimit = 'new_booking_session_time_limit',
  NewBookingTotalParticipants = 'new_booking_total_participants',
  PaymentWindowQualitative = 'payment_window_qualitative',
  PaymentWindowQuantitative = 'payment_window_quantitative',
  ResearcherCertificationVideoaskUrl = 'researcher_certification_videoask_url',
  XeroJobNumbers = 'xero_job_numbers',
  XeroRefreshToken = 'xero_refreshToken',
}

export type SettingsNotificationInput = {
  messages?: InputMaybe<SettingsNotificationTypeInput>;
  promotional?: InputMaybe<SettingsNotificationTypeInput>;
  reminders?: InputMaybe<SettingsNotificationTypeInput>;
  updates?: InputMaybe<SettingsNotificationTypeInput>;
};

export type SettingsNotificationTypeInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
  push?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SettingsOnboardingInput = {
  apricot?: InputMaybe<SettingsVersionsInput>;
};

export type SettingsRemoteInput = {
  network_quality?: InputMaybe<Scalars['Float']['input']>;
  setup_check?: InputMaybe<Scalars['Float']['input']>;
};

export type SettingsRestrictionsInput = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
};

export type SettingsTutorialInput = {
  app?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  my?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SettingsVersionsInput = {
  create_booking?: InputMaybe<ShowInput>;
  manage_booking?: InputMaybe<ShowInput>;
};

export type Share = {
  __typename?: 'Share';
  _id: Scalars['ID']['output'];
  _user_id?: Maybe<Scalars['ID']['output']>;
  created_at: Scalars['Float']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  invitee_email?: Maybe<Scalars['String']['output']>;
  inviter_user_id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  opened_at?: Maybe<Scalars['Float']['output']>;
  opened_count: Scalars['Int']['output'];
  requester_type?: Maybe<ShareRequesterType>;
  revoked_at?: Maybe<Scalars['Float']['output']>;
  status: ShareStatus;
  type: ShareType;
  type_code?: Maybe<Scalars['String']['output']>;
  type_id: Scalars['ID']['output'];
};

export type ShareFilterStatus = {
  eq?: InputMaybe<ShareStatus>;
  in?: InputMaybe<Array<InputMaybe<ShareStatus>>>;
  ne?: InputMaybe<ShareStatus>;
};

export type ShareFilterType = {
  eq?: InputMaybe<ShareType>;
  in?: InputMaybe<Array<InputMaybe<ShareType>>>;
  ne?: InputMaybe<ShareType>;
};

export type ShareLink = {
  __typename?: 'ShareLink';
  _id: Scalars['ID']['output'];
  auth_token: Scalars['String']['output'];
  invitee_email: Scalars['String']['output'];
  share_token: Scalars['String']['output'];
  sharing_token: Scalars['String']['output'];
  type: ShareType;
  type_id: Scalars['ID']['output'];
};

export type ShareRequest = {
  __typename?: 'ShareRequest';
  _id: Scalars['ID']['output'];
  code: Scalars['String']['output'];
  created_at: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  share?: Maybe<Share>;
  share_id?: Maybe<Scalars['ID']['output']>;
  status: ShareRequestStatus;
  type: ShareType;
  type_id: Scalars['ID']['output'];
};

export enum ShareRequestStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  Requested = 'requested',
}

export enum ShareRequesterType {
  Domain = 'domain',
  Email = 'email',
  Team = 'team',
}

export type ShareRequesterTypeFilter = {
  eq?: InputMaybe<ShareRequesterType>;
  in?: InputMaybe<Array<InputMaybe<ShareRequesterType>>>;
  ne?: InputMaybe<ShareRequesterType>;
};

export type ShareRequestsCollection = {
  __typename?: 'ShareRequestsCollection';
  nodes: Array<ShareRequest>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShareRequestsFilter = {
  code?: InputMaybe<FilterString>;
  email?: InputMaybe<FilterString>;
  status?: InputMaybe<ShareRequestsFilterStatus>;
  type?: InputMaybe<ShareFilterType>;
};

export type ShareRequestsFilterStatus = {
  eq?: InputMaybe<ShareRequestStatus>;
  in?: InputMaybe<Array<ShareRequestStatus>>;
  ne?: InputMaybe<ShareRequestStatus>;
};

export enum ShareStatus {
  Invited = 'invited',
  Opened = 'opened',
  Revoked = 'revoked',
}

export enum ShareType {
  Collection = 'collection',
  Insight = 'insight',
}

export type ShareTypeInput = {
  _invitee_email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  type: ShareType;
  type_id: Scalars['ID']['input'];
};

export type SharesConnection = {
  __typename?: 'SharesConnection';
  nodes: Array<Maybe<Share>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SharesFilter = {
  requester_type?: InputMaybe<ShareRequesterTypeFilter>;
  status?: InputMaybe<ShareFilterStatus>;
  type?: InputMaybe<ShareFilterType>;
  type_id?: InputMaybe<FilterId>;
};

export type ShortUrl = {
  __typename?: 'ShortUrl';
  _id?: Maybe<Scalars['ID']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  hits?: Maybe<Scalars['Int']['output']>;
  payload?: Maybe<ShortUrlPayload>;
  payload_raw?: Maybe<Scalars['String']['output']>;
  short_url?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ShortUrlInput = {
  payload: Array<ShortUrlPayloadInput>;
  url: Scalars['String']['input'];
};

export type ShortUrlPayload = {
  __typename?: 'ShortUrlPayload';
  _batch_id?: Maybe<Scalars['ID']['output']>;
  _booking_id?: Maybe<Scalars['ID']['output']>;
  _message_id?: Maybe<Scalars['ID']['output']>;
  _participant_id?: Maybe<Scalars['ID']['output']>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  batch_tag?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
};

export type ShortUrlPayloadInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SocialInput = {
  facebook?: InputMaybe<FacebookInput>;
  google?: InputMaybe<GoogleInput>;
  linkedin?: InputMaybe<LinkedinInput>;
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc',
}

export type StartRoomInput = {
  code: Scalars['String']['input'];
  is_recorded?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Submission = {
  __typename?: 'Submission';
  /** Returns the most relevant session for the user on this booking */
  CurrentStatus?: Maybe<SessionsType>;
  /**
   * Messages send to the user
   * @deprecated Use messagesList instead to make use of proper pagination features
   */
  Messages?: Maybe<Array<Maybe<Messages>>>;
  _booking_id?: Maybe<Scalars['ID']['output']>;
  /** Participant Current Status Session ID */
  _current_status_session_id?: Maybe<Scalars['ID']['output']>;
  _id?: Maybe<Scalars['ID']['output']>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  agreement?: Maybe<SubmissionAgreement>;
  applicant: Applicant;
  booking?: Maybe<Booking>;
  /** The client is reviewing whether the participant should be paid */
  client_review?: Maybe<Scalars['Boolean']['output']>;
  /** Field to indicate whether the user has completed his session */
  completed?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<Array<Maybe<Data>>>;
  eligibility?: Maybe<Scalars['Float']['output']>;
  eligibility_list?: Maybe<Array<Maybe<EligibilityDetail>>>;
  external_survey_completed?: Maybe<Scalars['Boolean']['output']>;
  /** Feedback given from the participant */
  feedback?: Maybe<BookingFeedback>;
  /** The particicpant needs help to complete the task (timestamp) */
  help_requested?: Maybe<Scalars['Float']['output']>;
  in_context?: Maybe<InContextSubmission>;
  incentive?: Maybe<IncentiveBookingConfig>;
  /** Locale that participant prefers to conduct research in */
  locale?: Maybe<Locale>;
  messagesList?: Maybe<MessagesConnection>;
  participant_is_excluded?: Maybe<Scalars['Boolean']['output']>;
  preferred_sessions?: Maybe<Array<Maybe<BookingSession>>>;
  previousAttendance?: Maybe<Array<Maybe<Submission>>>;
  /** Rating that it was given to the participant for his session */
  rating?: Maybe<BookingSubmissionRating>;
  session?: Maybe<SessionsType>;
  /** Sessions that a participant has indicated availability. It used to be the booking_participant_id */
  sessions?: Maybe<Array<Maybe<SessionsType>>>;
  shortlisted?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<SubmissionStatus>;
  /** The most recent time that the status was updated */
  status_updated?: Maybe<Scalars['Float']['output']>;
  /** Transaction object to link to the Transactions document */
  transaction?: Maybe<BookingSubmissionsTransaction>;
  user?: Maybe<User>;
  /** Time when the user confirmed his attendance */
  user_confirm?: Maybe<Scalars['Float']['output']>;
  video?: Maybe<AskableVideo>;
  viewed_by?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type SubmissionMessagesArgs = {
  filter?: InputMaybe<MessagesFilter>;
};

export type SubmissionMessagesListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessagesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SubmissionAgreement = {
  __typename?: 'SubmissionAgreement';
  consent?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  /** 1: Consent given, 2: Consent denied, 3: Link sent, 4: Viewed in provider, 5: Declined in provider, 6: Completed in provider */
  status?: Maybe<Scalars['Int']['output']>;
};

export type SubmissionAgreementInput = {
  consent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubmissionInput = {
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _user_id: Scalars['ID']['input'];
  /** participant consents to be sent an agreement/NDA if they are selected. Not stored directly in the database */
  agreement?: InputMaybe<SubmissionAgreementInput>;
  data?: InputMaybe<Array<InputMaybe<DataInput>>>;
  in_context?: InputMaybe<InContextSubmissionInput>;
  /** Locale that participant prefers to conduct research in */
  locale?: InputMaybe<Locale>;
};

export type SubmissionMetadataInput = {
  browser_data?: InputMaybe<BrowserDataInput>;
  fingerprint?: InputMaybe<Scalars['JSON']['input']>;
};

export enum SubmissionStatus {
  Available = 'available',
  Completed = 'completed',
  Confirmed = 'confirmed',
  Excluded = 'excluded',
  HelpRequested = 'help_requested',
  InProgress = 'in_progress',
  InviteDeclined = 'invite_declined',
  Invited = 'invited',
  ParticipantCancelled = 'participant_cancelled',
  PendingCheck = 'pending_check',
  PendingTechCheck = 'pending_tech_check',
  Reported = 'reported',
  TimeExpired = 'time_expired',
  Waitlisted = 'waitlisted',
}

export type SubmissionsByBookingResponse = {
  __typename?: 'SubmissionsByBookingResponse';
  meta: SubscriptionMeta;
  submission?: Maybe<Submission>;
};

export type SubmitAskablePlusProjectStudyFlagsInput = {
  sessions_livekit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  StatusSubscription?: Maybe<RootSchema>;
  adminCurrentWigProgress?: Maybe<AdminWig>;
  adminSound?: Maybe<AdminSound>;
  bookingByID?: Maybe<Booking>;
  bookingSessionsWithParticipants?: Maybe<Booking>;
  bookingSubmissionsByBooking?: Maybe<Submission>;
  bookingUserStatus?: Maybe<Booking>;
  bookings?: Maybe<Booking>;
  highlight?: Maybe<HighlightResponse>;
  joinRoomRequest?: Maybe<AskableApproveRequestType>;
  messagesSeenSubscription?: Maybe<Array<Maybe<Messages>>>;
  messagesSubscription?: Maybe<Messages>;
  notificationsByTeam?: Maybe<Notifications>;
  projectByID?: Maybe<Project>;
  projects?: Maybe<Project>;
  roomStarted: VideoStatusType;
  submissionsByBooking?: Maybe<SubmissionsByBookingResponse>;
  tags?: Maybe<TagsSubscriptionResponse>;
  teamById?: Maybe<Teams>;
  teamEOFYLead?: Maybe<Teams>;
  teamLeadNotification?: Maybe<Teams>;
  teams?: Maybe<Teams>;
  unreadNotificationsCountByTeam?: Maybe<Array<Maybe<NotificationsCount>>>;
  utteranceSpeakers?: Maybe<Array<Maybe<AskableVideoTranscriptUtteranceSpeakerType>>>;
  videoRoomNote?: Maybe<VideoRoomNoteSubscription>;
};

export type SubscriptionAdminCurrentWigProgressArgs = {
  key: Scalars['String']['input'];
  period_type?: InputMaybe<AdminWigPeriodTypes>;
};

export type SubscriptionBookingByIdArgs = {
  currency_code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  participant_status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type SubscriptionBookingSessionsWithParticipantsArgs = {
  booking_id: Scalars['ID']['input'];
};

export type SubscriptionBookingSubmissionsByBookingArgs = {
  bookingId: Scalars['ID']['input'];
};

export type SubscriptionHighlightArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  meeting_code?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionJoinRoomRequestArgs = {
  _user_id?: InputMaybe<Scalars['ID']['input']>;
  code: Scalars['String']['input'];
  status?: InputMaybe<Array<InputMaybe<AskableVideoUserStatus>>>;
};

export type SubscriptionMessagesSeenSubscriptionArgs = {
  _messages_id: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type SubscriptionMessagesSubscriptionArgs = {
  filter: MessageSearchInput;
};

export type SubscriptionNotificationsByTeamArgs = {
  _team_id: Scalars['ID']['input'];
};

export type SubscriptionProjectByIdArgs = {
  id: Scalars['ID']['input'];
};

export type SubscriptionRoomStartedArgs = {
  code: Scalars['String']['input'];
};

export type SubscriptionSubmissionsByBookingArgs = {
  bookingId: Scalars['ID']['input'];
};

export type SubscriptionTagsArgs = {
  input: TagsSubscriptionInput;
};

export type SubscriptionTeamByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type SubscriptionUnreadNotificationsCountByTeamArgs = {
  _team_id: Scalars['ID']['input'];
};

export type SubscriptionUtteranceSpeakersArgs = {
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
};

export type SubscriptionVideoRoomNoteArgs = {
  filter?: InputMaybe<VideoRoomNoteFilter>;
};

export type SubscriptionMeta = {
  __typename?: 'SubscriptionMeta';
  event: Scalars['String']['output'];
  triggeredBy: Scalars['ID']['output'];
};

export type SystemBlock = {
  __typename?: 'SystemBlock';
  instructions: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: SystemBlockType;
};

export enum SystemBlockType {
  ThankYou = 'thank_you',
  Welcome = 'welcome',
}

export type Tmp_UpdateBookingRecruitInput = {
  _id: Scalars['ObjectID']['input'];
  review_submission?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TagEventType {
  TagsCreate = 'tags_create',
  TagsDelete = 'tags_delete',
  TagsUpdate = 'tags_update',
}

export type TagType = {
  __typename?: 'TagType';
  _id: Scalars['ID']['output'];
  _team_id: Scalars['ID']['output'];
  color: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  highlight_ids: Array<Maybe<Scalars['ID']['output']>>;
  last_used: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type TagsConnection = {
  __typename?: 'TagsConnection';
  nodes?: Maybe<Array<Maybe<TagType>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TagsConnectionFilter = {
  _id?: InputMaybe<TagsConnectionIdFilter>;
  _team_id?: InputMaybe<TagsConnectionTeamIdFilter>;
  name?: InputMaybe<TagsConnectionNameFilter>;
};

export type TagsConnectionIdFilter = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ne?: InputMaybe<Scalars['ID']['input']>;
};

export type TagsConnectionNameFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
};

export type TagsConnectionSort = {
  last_used?: InputMaybe<LastUsedSortDirection>;
};

export type TagsConnectionTeamIdFilter = {
  eq: Scalars['ID']['input'];
};

export type TagsSubscriptionInput = {
  _team_id: Scalars['ID']['input'];
};

export type TagsSubscriptionMeta = {
  __typename?: 'TagsSubscriptionMeta';
  event: TagEventType;
  triggeredBy: Scalars['ID']['output'];
};

export type TagsSubscriptionResponse = {
  __typename?: 'TagsSubscriptionResponse';
  meta: TagsSubscriptionMeta;
  tags: Array<TagType>;
};

export type TaskBlock = {
  _id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  instructions: Scalars['String']['output'];
  is_recording_enabled: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: TaskBlockType;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type TaskBlockFigmaPrototype = TaskBlock & {
  __typename?: 'TaskBlockFigmaPrototype';
  _id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  figma_prototype?: Maybe<FigmaPrototypeFile>;
  instructions: Scalars['String']['output'];
  is_recording_enabled: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: TaskBlockType;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type TaskBlockMultipleChoiceQuestion = TaskBlock & {
  __typename?: 'TaskBlockMultipleChoiceQuestion';
  _id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  instructions: Scalars['String']['output'];
  is_recording_enabled: Scalars['Boolean']['output'];
  multiple_choice_question?: Maybe<MultipleChoiceQuestion>;
  title: Scalars['String']['output'];
  type: TaskBlockType;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type TaskBlockOpinionScale = TaskBlock & {
  __typename?: 'TaskBlockOpinionScale';
  _id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  instructions: Scalars['String']['output'];
  is_recording_enabled: Scalars['Boolean']['output'];
  opinion_scale?: Maybe<OpinionScale>;
  title: Scalars['String']['output'];
  type: TaskBlockType;
  updated?: Maybe<Scalars['Float']['output']>;
};

export enum TaskBlockType {
  FigmaPrototype = 'figma_prototype',
  MultipleChoiceQuestion = 'multiple_choice_question',
  OpinionScale = 'opinion_scale',
}

export type Taxes = {
  __typename?: 'Taxes';
  _id?: Maybe<Scalars['ObjectID']['output']>;
  /** @deprecated Incorrect values - actually represents the currency code */
  country_code?: Maybe<Scalars['String']['output']>;
  currency_code: Scalars['String']['output'];
  currency_symbol: Scalars['String']['output'];
  tax_label?: Maybe<Scalars['String']['output']>;
  tax_number_label?: Maybe<Scalars['String']['output']>;
  tax_rate?: Maybe<Scalars['Int']['output']>;
  xero_discount_account_code?: Maybe<Scalars['String']['output']>;
  xero_revenue_account_code?: Maybe<Scalars['String']['output']>;
};

export type TeamAllowedDomain = {
  __typename?: 'TeamAllowedDomain';
  added_at?: Maybe<Scalars['Float']['output']>;
  added_by?: Maybe<Scalars['ID']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
};

export type TeamCurrency = {
  __typename?: 'TeamCurrency';
  code?: Maybe<Scalars['Currency']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
};

export type TeamInput = {
  _organisation_id?: InputMaybe<Scalars['ID']['input']>;
  allowed_domains?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  operational_office?: InputMaybe<OperationalOffices>;
  research?: InputMaybe<TeamResearchInput>;
  settings?: InputMaybe<TeamSettingsInput>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamInvitations = {
  __typename?: 'TeamInvitations';
  InviterUser?: Maybe<User>;
  Team?: Maybe<Teams>;
  /** @deprecated Should use specific fields instead of the whole object. Field shouldnt be public */
  User?: Maybe<User>;
  _id: Scalars['ID']['output'];
  _team_id: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  expired?: Maybe<Scalars['Boolean']['output']>;
  inviter?: Maybe<Scalars['ID']['output']>;
  last_sent?: Maybe<Scalars['Float']['output']>;
  request_id: Scalars['String']['output'];
  role?: Maybe<Scalars['String']['output']>;
  team_name?: Maybe<Scalars['String']['output']>;
};

export type TeamInviteFilter = {
  expired?: InputMaybe<FilterBoolean>;
};

export type TeamRequests = {
  __typename?: 'TeamRequests';
  _id: Scalars['ID']['output'];
  _team_id: Scalars['ID']['output'];
  _user_id: Scalars['ID']['output'];
  /**  0: Requested 1: Approved 2: Refused  */
  status: Scalars['Int']['output'];
};

export type TeamResearch = {
  __typename?: 'TeamResearch';
  context?: Maybe<Scalars['String']['output']>;
};

export type TeamResearchInput = {
  context?: InputMaybe<Scalars['String']['input']>;
};

export type TeamSettingsBilling = {
  __typename?: 'TeamSettingsBilling';
  company_name?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_first_name?: Maybe<Scalars['String']['output']>;
  contact_last_name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the fields contact_first_name and contact_last_name */
  contact_name?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<TeamCurrency>;
  invoice_due_days?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Location>;
  preferred?: Maybe<Scalars['Int']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
  stripe?: Maybe<TeamStripeType>;
  subscription?: Maybe<TeamSettingsBillingSubscription>;
  tax?: Maybe<Taxes>;
  xero_id?: Maybe<Scalars['String']['output']>;
};

export type TeamSettingsBillingInput = {
  company_name?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_first_name?: InputMaybe<Scalars['String']['input']>;
  contact_last_name?: InputMaybe<Scalars['String']['input']>;
  contact_name?: InputMaybe<Scalars['String']['input']>;
  invoice_due_days?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<LocationInput>;
  preferred?: InputMaybe<Scalars['Int']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<TeamSettingsBillingSubscriptionInput>;
};

export type TeamSettingsBillingSubscription = {
  __typename?: 'TeamSettingsBillingSubscription';
  credit?: Maybe<TeamSettingsBillingSubscriptionCredit>;
  /**  Credits Expire Date  */
  end?: Maybe<Scalars['Float']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['Float']['output']>;
};

export type TeamSettingsBillingSubscriptionCredit = {
  __typename?: 'TeamSettingsBillingSubscriptionCredit';
  remaining?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TeamSettingsBillingSubscriptionCreditInput = {
  remaining?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamSettingsBillingSubscriptionInput = {
  credit?: InputMaybe<TeamSettingsBillingSubscriptionCreditInput>;
  end?: InputMaybe<Scalars['Float']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Float']['input']>;
};

export type TeamSettingsInput = {
  billing?: InputMaybe<TeamSettingsBillingInput>;
  ignore_tracking?: InputMaybe<Scalars['Boolean']['input']>;
  recruitment_page?: InputMaybe<TeamSettingsRecruitmentPageInput>;
  sso?: InputMaybe<TeamSettingsSsoInput>;
};

export type TeamSettingsRecruitmentPage = {
  __typename?: 'TeamSettingsRecruitmentPage';
  blurb?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TeamSettingsRecruitmentPageInput = {
  blurb?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type TeamSettingsSso = {
  __typename?: 'TeamSettingsSso';
  company_domains?: Maybe<Scalars['String']['output']>;
  idp_certificate?: Maybe<Scalars['String']['output']>;
  idp_issuer?: Maybe<Scalars['String']['output']>;
  idp_metadata?: Maybe<Scalars['String']['output']>;
  idp_sso_url?: Maybe<Scalars['String']['output']>;
  team_sso_id?: Maybe<Scalars['String']['output']>;
  team_sso_url?: Maybe<Scalars['String']['output']>;
};

export type TeamSettingsSsoConnection = {
  __typename?: 'TeamSettingsSsoConnection';
  _id: Scalars['ID']['output'];
  connection_id: Scalars['String']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<TeamSettingsSsoConnectionOptions>;
  strategy: KindeSsoStrategy;
};

export type TeamSettingsSsoConnectionOptions = {
  __typename?: 'TeamSettingsSsoConnectionOptions';
  azure_ad?: Maybe<TeamSettingsSsoConnectionOptionsAzure>;
  /**  Generic fields apply to all types  */
  home_realm_domains: Array<Maybe<Scalars['String']['output']>>;
  /**  Connection type specific field sets  */
  saml_custom?: Maybe<TeamSettingsSsoConnectionOptionsSaml>;
};

export type TeamSettingsSsoConnectionOptionsAzure = {
  __typename?: 'TeamSettingsSsoConnectionOptionsAzure';
  client_id: Scalars['String']['output'];
  client_secret: Scalars['String']['output'];
  entra_id_domain?: Maybe<Scalars['String']['output']>;
  is_use_common_endpoint?: Maybe<Scalars['Boolean']['output']>;
};

export type TeamSettingsSsoConnectionOptionsAzureInput = {
  client_id?: InputMaybe<Scalars['String']['input']>;
  client_secret?: InputMaybe<Scalars['String']['input']>;
  entra_id_domain?: InputMaybe<Scalars['String']['input']>;
  is_use_common_endpoint?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamSettingsSsoConnectionOptionsInput = {
  azure_ad?: InputMaybe<TeamSettingsSsoConnectionOptionsAzureInput>;
  /**  Generic fields apply to all types  */
  home_realm_domains: Array<InputMaybe<Scalars['String']['input']>>;
  /**  Connection type specific field sets  */
  saml_custom?: InputMaybe<TeamSettingsSsoConnectionOptionsSamlInput>;
};

export type TeamSettingsSsoConnectionOptionsSaml = {
  __typename?: 'TeamSettingsSsoConnectionOptionsSaml';
  saml_email_key_attr?: Maybe<Scalars['String']['output']>;
  saml_entity_id: Scalars['String']['output'];
  saml_idp_metadata_url: Scalars['String']['output'];
};

export type TeamSettingsSsoConnectionOptionsSamlInput = {
  saml_email_key_attr?: InputMaybe<Scalars['String']['input']>;
  saml_entity_id?: InputMaybe<Scalars['String']['input']>;
  saml_idp_metadata_url?: InputMaybe<Scalars['String']['input']>;
};

export type TeamSettingsSsoInput = {
  company_domains?: InputMaybe<Scalars['String']['input']>;
  idp_certificate?: InputMaybe<Scalars['String']['input']>;
  idp_issuer?: InputMaybe<Scalars['String']['input']>;
  idp_metadata?: InputMaybe<Scalars['String']['input']>;
  idp_sso_url?: InputMaybe<Scalars['String']['input']>;
  team_sso_id?: InputMaybe<Scalars['String']['input']>;
  team_sso_url?: InputMaybe<Scalars['String']['input']>;
};

export type TeamSettingsType = {
  __typename?: 'TeamSettingsType';
  billing?: Maybe<TeamSettingsBilling>;
  ignore_tracking?: Maybe<Scalars['Boolean']['output']>;
  recruitment_page?: Maybe<TeamSettingsRecruitmentPage>;
  /** @deprecated Legacy SAML custom integration. Migrate to sso_connections (Kinde connections */
  sso?: Maybe<TeamSettingsSso>;
  sso_connections?: Maybe<Array<Maybe<TeamSettingsSsoConnection>>>;
};

export type TeamStripePaymentType = {
  __typename?: 'TeamStripePaymentType';
  exp_month?: Maybe<Scalars['Int']['output']>;
  exp_year?: Maybe<Scalars['Int']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type TeamStripeType = {
  __typename?: 'TeamStripeType';
  customer_id?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<TeamStripePaymentType>;
};

export type TeamStripeTypePaymentArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
};

export type Teams = {
  __typename?: 'Teams';
  Credits?: Maybe<Credits>;
  Organisation?: Maybe<OrganisationType>;
  ParentTeam?: Maybe<Teams>;
  TeamInvitations?: Maybe<Array<Maybe<TeamInvitations>>>;
  Transaction?: Maybe<Array<Maybe<Transactions>>>;
  _id: Scalars['ID']['output'];
  _organisation_id?: Maybe<Scalars['ID']['output']>;
  allowed_domains?: Maybe<Array<Maybe<TeamAllowedDomain>>>;
  created?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  integrations?: Maybe<TeamsIntegrationsType>;
  join_requests?: Maybe<Array<Maybe<TeamsJoinRequestsType>>>;
  kinde_org_id?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  operational_office?: Maybe<OperationalOffices>;
  /**  Parent team. Used for some PRO features where the parent team has to approve  */
  parent_team?: Maybe<Scalars['ID']['output']>;
  research?: Maybe<TeamResearch>;
  settings?: Maybe<TeamSettingsType>;
  status?: Maybe<Scalars['Int']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
  users?: Maybe<Array<Maybe<TeamsUsersType>>>;
};

export type TeamsIntegrationsType = {
  __typename?: 'TeamsIntegrationsType';
  hubspot_company_id?: Maybe<Scalars['String']['output']>;
};

export type TeamsJoinRequestsType = {
  __typename?: 'TeamsJoinRequestsType';
  User?: Maybe<User>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
};

export type TeamsSearchInput = {
  has_subscription?: InputMaybe<Scalars['Boolean']['input']>;
  internal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamsUsersType = {
  __typename?: 'TeamsUsersType';
  InvitedByUser?: Maybe<User>;
  User?: Maybe<User>;
  _id: Scalars['ID']['output'];
  added_date?: Maybe<Scalars['Float']['output']>;
  invited_by?: Maybe<Scalars['ID']['output']>;
  /**
   *  The role of the user withing the team
   * 1  - Admin
   * 2  - Full Member
   * 3  - Guest
   * 99 - Super Admin
   */
  role?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use the field role */
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**  0: Inactive, 1: Active  */
  status?: Maybe<Scalars['Int']['output']>;
};

export type Transactions = {
  __typename?: 'Transactions';
  Team?: Maybe<Teams>;
  User?: Maybe<User>;
  /**  If triggered by admin (manually)  */
  _admin_user_id?: Maybe<Scalars['ID']['output']>;
  _coupon_id?: Maybe<Scalars['ID']['output']>;
  _credit_option_id?: Maybe<Scalars['ID']['output']>;
  _id: Scalars['ID']['output'];
  _team_id?: Maybe<Scalars['ID']['output']>;
  _user_id?: Maybe<Scalars['ID']['output']>;
  /**  1 - Debit (Someone pays Askable), 2 - Credit (Askable pays someone)  */
  accounting_type?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currency_symbol?: Maybe<Scalars['String']['output']>;
  invoice?: Maybe<InvoiceTransactionsType>;
  /**  1- Paypal, 2 - Credit Card, 3- Invoice, 4- GiftPay  */
  method?: Maybe<Scalars['Int']['output']>;
  operational_office?: Maybe<OperationalOffices>;
  provider?: Maybe<ProviderTransactions>;
  /**  1 - Completed, 2 - Pending, 3 - Cancelled  */
  status?: Maybe<Scalars['Int']['output']>;
  /**  Total $ amount  */
  total_amount?: Maybe<Scalars['Float']['output']>;
  /**  0 - Askable admin manual transaction adjustment, 1 - Client credit purchase, 2 - Participant payment, 3 - Credit sale refund, 4 - Wallet withdrawal  */
  type?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<UserTransactionsType>;
};

export type TwilioRoom = {
  __typename?: 'TwilioRoom';
  _id?: Maybe<Scalars['ID']['output']>;
  is_recorded: Scalars['Boolean']['output'];
  notes?: Maybe<Array<Maybe<AskableVideoRoomNote>>>;
  participants?: Maybe<Array<Maybe<RoomParticipantsType>>>;
  sid: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['Float']['output']>;
  status: VideoRoomStatus;
};

export type TwilioVideoType = {
  __typename?: 'TwilioVideoType';
  room?: Maybe<TwilioVideoTypeRoomType>;
  /** acceess token for connecting to a room */
  token?: Maybe<Scalars['String']['output']>;
};

export type TwilioVideoTypeRoomType = {
  __typename?: 'TwilioVideoTypeRoomType';
  created?: Maybe<Scalars['Float']['output']>;
  /** Twilio internal ID for the room */
  sid?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
};

export type TypeInput = {
  client?: InputMaybe<Scalars['Boolean']['input']>;
  participant?: InputMaybe<Scalars['Boolean']['input']>;
  researcher?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UnauthCreateShareRequestInput = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  type: ShareType;
};

export type UnauthShareRequestsFilter = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  type: ShareType;
};

export type UnmoderatedBookingConfig = {
  __typename?: 'UnmoderatedBookingConfig';
  task_blocks: Array<Maybe<TaskBlock>>;
  thank_you_block: SystemBlock;
  welcome_block: SystemBlock;
};

export type UpdateAskableVideoRoomNoteInput = {
  _id: Scalars['ID']['input'];
  _room_id: Scalars['ID']['input'];
  code: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBookingConfigInput = {
  contact?: InputMaybe<BookingContactInput>;
  listing_information?: InputMaybe<BookingListingInformationInput>;
  recruit?: InputMaybe<BookingRecruitInput>;
};

export type UpdateBookingSubmissionInput = {
  _id: Scalars['ID']['input'];
  cancel_reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SubmissionStatus>;
  status_updated?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateBookingTaskResultFigmaPrototypeInput = {
  _id: Scalars['ID']['input'];
  details?: InputMaybe<BookingTaskDetailsFigmaPrototypeInput>;
  error_reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookingTaskResultStatus>;
  task_ended?: InputMaybe<Scalars['Float']['input']>;
  task_loaded?: InputMaybe<Scalars['Float']['input']>;
  task_started?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateBookingTaskResultMultipleChoiceQuestionInput = {
  _id: Scalars['ID']['input'];
  details?: InputMaybe<BookingTaskDetailsMultipleChoiceQuestionInput>;
  error_reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookingTaskResultStatus>;
  task_ended?: InputMaybe<Scalars['Float']['input']>;
  task_loaded?: InputMaybe<Scalars['Float']['input']>;
  task_started?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateBookingTaskResultOpinionScaleInput = {
  _id: Scalars['ID']['input'];
  details?: InputMaybe<BookingTaskDetailsOpinionScaleInput>;
  error_reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookingTaskResultStatus>;
  task_ended?: InputMaybe<Scalars['Float']['input']>;
  task_loaded?: InputMaybe<Scalars['Float']['input']>;
  task_started?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCollectionsInput = {
  _id: Scalars['ID']['input'];
  byline?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<UpdateCollectionsInputConfig>;
  icon?: InputMaybe<CollectionIconInput>;
  insight_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<UpdateCollectionsInputTheme>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCollectionsInputConfig = {
  sort?: InputMaybe<CollectionsConfigSort>;
};

export type UpdateCollectionsInputTheme = {
  primary_color?: InputMaybe<Scalars['String']['input']>;
  secondary_color?: InputMaybe<Scalars['String']['input']>;
  title_theme?: InputMaybe<CollectionTitleTheme>;
};

export type UpdateHighlightInput = {
  _id: Scalars['ID']['input'];
  _room_id: Scalars['ID']['input'];
  end_word?: InputMaybe<HighlightEndWordInput>;
  meeting_code: Scalars['String']['input'];
  start_word?: InputMaybe<HighlightStartWordInput>;
  tag_ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateInsightInput = {
  blocks?: InputMaybe<Scalars['String']['input']>;
  by_line?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<InsightIconInput>;
  id: Scalars['ID']['input'];
  sub_title?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<UpdateInsightThemeInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInsightThemeInput = {
  primary_color: Scalars['String']['input'];
  secondary_color?: InputMaybe<Scalars['String']['input']>;
  title_theme: InsightTitleTheme;
};

export type UpdateResearcherInput = {
  admin_notes?: InputMaybe<Scalars['String']['input']>;
  certification?: InputMaybe<ResearcherCertificationInput>;
  methods?: InputMaybe<Array<InputMaybe<ResearcherMethods>>>;
  ux_length?: InputMaybe<UserMetaUxLength>;
};

export type UpdateSegmentInput = {
  booking_id: Scalars['String']['input'];
  filters?: InputMaybe<Array<InputMaybe<SegmentFilterInput>>>;
  method?: InputMaybe<SegmentMethod>;
  name?: InputMaybe<Scalars['String']['input']>;
  segment_id: Scalars['String']['input'];
  sort?: InputMaybe<Array<InputMaybe<SegmentSortValue>>>;
  tableConfig?: InputMaybe<SegmentTableConfigInput>;
};

export type UpdateShareRequestInput = {
  _id: Scalars['ID']['input'];
  status: ShareRequestStatus;
};

export type UpdateSystemBlockInput = {
  instructions: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  highlight_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskBlockFigmaPrototypeInput = {
  _id: Scalars['ObjectID']['input'];
  task_block: UpdateTaskBlockFigmaPrototypeValueInput;
};

export type UpdateTaskBlockFigmaPrototypeValueInput = {
  _id: Scalars['ObjectID']['input'];
  figma_prototype?: InputMaybe<FigmaPrototypeFileInput>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  is_recording_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskBlockMultipleChoiceQuestionInput = {
  _id: Scalars['ObjectID']['input'];
  task_block: UpdateTaskBlockMultipleChoiceQuestionValueInput;
};

export type UpdateTaskBlockMultipleChoiceQuestionValueInput = {
  _id: Scalars['ObjectID']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  is_recording_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  multiple_choice_question?: InputMaybe<MultipleChoiceQuestionInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskBlockOpinionScaleInput = {
  _id: Scalars['ObjectID']['input'];
  task_block: UpdateTaskBlockOpinionScaleValueInput;
};

export type UpdateTaskBlockOpinionScaleValueInput = {
  _id: Scalars['ObjectID']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  is_recording_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  opinion_scale?: InputMaybe<OpinionScaleInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskBlockOrderInput = {
  _id: Scalars['ObjectID']['input'];
  _task_block_ids: Array<InputMaybe<Scalars['ObjectID']['input']>>;
};

export type UpdateTranscriptSpeakersFilterInput = {
  _speaker_id?: InputMaybe<Scalars['ID']['input']>;
  _utterance_id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateUnmoderatedBookingConfigInput = {
  _id: Scalars['ObjectID']['input'];
  thank_you_block?: InputMaybe<UpdateSystemBlockInput>;
  welcome_block?: InputMaybe<UpdateSystemBlockInput>;
};

export type UpdateUnmoderatedBookingInput = {
  _id: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

export enum UploadArea {
  Insights = 'insights',
  Profile = 'profile',
}

export enum UploadMimeType {
  ImageJpg = 'image_jpg',
  ImagePng = 'image_png',
  ImageWebp = 'image_webp',
}

export enum UploadStatus {
  Available = 'available',
  Deleted = 'deleted',
  Failed = 'failed',
}

export type UploadType = {
  __typename?: 'UploadType';
  _id: Scalars['ID']['output'];
  area: UploadArea;
  /**  ID related to the area, eg. insights._id  */
  area_id: Scalars['ID']['output'];
  created: Scalars['Float']['output'];
  last_accessed_at?: Maybe<Scalars['Float']['output']>;
  mime_type: UploadMimeType;
  status: UploadStatus;
  updated: Scalars['Float']['output'];
  uploaded_by_user_id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  ConnectedTeam?: Maybe<Teams>;
  LastLogin?: Maybe<Scalars['Float']['output']>;
  ParticipantIncentives?: Maybe<Array<Maybe<Transactions>>>;
  ParticipantStarRating?: Maybe<Array<Maybe<BookingSubmissionRating>>>;
  TeamRequests?: Maybe<Array<Maybe<TeamRequests>>>;
  Teams?: Maybe<Array<Maybe<Teams>>>;
  _default_team?: Maybe<Scalars['ID']['output']>;
  _id: Scalars['ID']['output'];
  access_token?: Maybe<Scalars['String']['output']>;
  admin?: Maybe<UserAdminType>;
  blacklist?: Maybe<Scalars['Boolean']['output']>;
  contact?: Maybe<UserContactType>;
  created?: Maybe<Scalars['Float']['output']>;
  devices?: Maybe<Array<Maybe<ViewerDeviceDetails>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_verified?: Maybe<Scalars['Boolean']['output']>;
  integrations?: Maybe<UserIntegrationsType>;
  intercomHash?: Maybe<Scalars['String']['output']>;
  invites?: Maybe<Array<Maybe<TeamInvitations>>>;
  kinde_user_id?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  messages?: Maybe<MessagesConnection>;
  meta?: Maybe<UserMetaType>;
  participant?: Maybe<UserParticipantType>;
  picture?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<ParticipantRating>;
  researcher?: Maybe<UserResearcher>;
  roles?: Maybe<Array<Maybe<UserRoles>>>;
  settings?: Maybe<UserSettingsType>;
  sso_error?: Maybe<Scalars['String']['output']>;
  sso_url?: Maybe<Scalars['String']['output']>;
  /**  0 - Inactive, 1 - Active, 2 - Banned  */
  status?: Maybe<Scalars['Int']['output']>;
  submission?: Maybe<Submission>;
  submissions?: Maybe<Array<Submission>>;
  timezone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<UserTypeType>;
  updated?: Maybe<Scalars['Float']['output']>;
};

export type UserConnectedTeamArgs = {
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserInvitesArgs = {
  filter?: InputMaybe<TeamInviteFilter>;
};

export type UserMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserMessagesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserSubmissionArgs = {
  bookingId: Scalars['ID']['input'];
};

export type UserSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ViewerSubmissionsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAdminType = {
  __typename?: 'UserAdminType';
  customer_success?: Maybe<Scalars['Boolean']['output']>;
  growth?: Maybe<Scalars['Boolean']['output']>;
  operational_office?: Maybe<OperationalOffices>;
  product?: Maybe<Scalars['Boolean']['output']>;
};

export type UserAgentType = {
  __typename?: 'UserAgentType';
  _id?: Maybe<Scalars['ID']['output']>;
  browser?: Maybe<UserBrowserType>;
  device?: Maybe<UserDeviceType>;
  os?: Maybe<UserOsType>;
  ua?: Maybe<Scalars['String']['output']>;
};

export type UserBrowserType = {
  __typename?: 'UserBrowserType';
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  version_major?: Maybe<Scalars['String']['output']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserConnectionFilter = {
  status?: InputMaybe<Array<InputMaybe<UserStatus>>>;
  type?: InputMaybe<Array<InputMaybe<UserType>>>;
};

export type UserContactPhoneType = {
  __typename?: 'UserContactPhoneType';
  country_code?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  verification?: Maybe<UserContactPhoneVerificationType>;
};

export type UserContactPhoneVerificationType = {
  __typename?: 'UserContactPhoneVerificationType';
  fax?: Maybe<Scalars['ID']['output']>;
  mobile?: Maybe<Scalars['ID']['output']>;
};

export type UserContactType = {
  __typename?: 'UserContactType';
  phone?: Maybe<UserContactPhoneType>;
};

export type UserDeviceType = {
  __typename?: 'UserDeviceType';
  type?: Maybe<Scalars['String']['output']>;
};

export type UserFigmaIntegration = {
  __typename?: 'UserFigmaIntegration';
  is_connected?: Maybe<Scalars['Boolean']['output']>;
  oauth_access_token_expiry?: Maybe<Scalars['Float']['output']>;
};

export type UserFiltersIdentify = {
  firstname?: InputMaybe<FilterString>;
  lastname?: InputMaybe<FilterString>;
};

export type UserInput = {
  _default_team?: InputMaybe<Scalars['ID']['input']>;
  blacklist?: InputMaybe<Scalars['Boolean']['input']>;
  contact?: InputMaybe<ContactInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  help_getting_started?: InputMaybe<GettingStartedInput>;
  location?: InputMaybe<LocationInput>;
  meta?: InputMaybe<MetaInput>;
  participant?: InputMaybe<UserParticipantInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  researcher?: InputMaybe<UpdateResearcherInput>;
  role?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<SettingsInput>;
  status?: InputMaybe<Scalars['Int']['input']>;
  testCredentials?: InputMaybe<Scalars['Float']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TypeInput>;
};

export type UserIntegrationsType = {
  __typename?: 'UserIntegrationsType';
  figma?: Maybe<UserFigmaIntegration>;
  hubspot_contact_id?: Maybe<Scalars['String']['output']>;
  segment_last_sync_date?: Maybe<Scalars['Int']['output']>;
};

export type UserMessagesFilter = {
  direction?: InputMaybe<FilterStatusInt>;
  seen?: InputMaybe<FilterExistence>;
  submission?: InputMaybe<ViewerSubmissionsFilter>;
  type?: InputMaybe<FilterStatusInt>;
};

export type UserMetaAcquisitionType = {
  __typename?: 'UserMetaAcquisitionType';
  _referred_by?: Maybe<Scalars['ID']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type UserMetaEducationType = {
  __typename?: 'UserMetaEducationType';
  apprenticeship?: Maybe<Scalars['Boolean']['output']>;
  highschool?: Maybe<Scalars['Boolean']['output']>;
  postgraduate?: Maybe<Scalars['Boolean']['output']>;
  some_university?: Maybe<Scalars['Boolean']['output']>;
  undergraduate?: Maybe<Scalars['Boolean']['output']>;
};

export type UserMetaFamilyChildrenType = {
  __typename?: 'UserMetaFamilyChildrenType';
  five?: Maybe<Scalars['Boolean']['output']>;
  four?: Maybe<Scalars['Boolean']['output']>;
  more_than_five?: Maybe<Scalars['Boolean']['output']>;
  none?: Maybe<Scalars['Boolean']['output']>;
  one?: Maybe<Scalars['Boolean']['output']>;
  three?: Maybe<Scalars['Boolean']['output']>;
  two?: Maybe<Scalars['Boolean']['output']>;
};

export type UserMetaFamilyStatusType = {
  __typename?: 'UserMetaFamilyStatusType';
  divorced?: Maybe<Scalars['Boolean']['output']>;
  married?: Maybe<Scalars['Boolean']['output']>;
  relationship?: Maybe<Scalars['Boolean']['output']>;
  single?: Maybe<Scalars['Boolean']['output']>;
  widowed?: Maybe<Scalars['Boolean']['output']>;
};

export type UserMetaFamilyType = {
  __typename?: 'UserMetaFamilyType';
  children?: Maybe<UserMetaFamilyChildrenType>;
  status?: Maybe<UserMetaFamilyStatusType>;
};

export type UserMetaFeedbackSampleType = {
  __typename?: 'UserMetaFeedbackSampleType';
  recording_url?: Maybe<Scalars['String']['output']>;
};

export type UserMetaHomeTypes = {
  __typename?: 'UserMetaHomeTypes';
  financed?: Maybe<Scalars['Boolean']['output']>;
  no?: Maybe<Scalars['Boolean']['output']>;
  yes?: Maybe<Scalars['Boolean']['output']>;
};

export type UserMetaIdentityBirthdayType = {
  __typename?: 'UserMetaIdentityBirthdayType';
  day?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  timestamp?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type UserMetaIdentityLanguageLanguageType = {
  __typename?: 'UserMetaIdentityLanguageLanguageType';
  speak?: Maybe<UserMetaIdentityLanguageProficiencyType>;
};

export type UserMetaIdentityLanguageProficiencyType = {
  __typename?: 'UserMetaIdentityLanguageProficiencyType';
  beginner?: Maybe<Scalars['Boolean']['output']>;
  conversational?: Maybe<Scalars['Boolean']['output']>;
  fluent?: Maybe<Scalars['Boolean']['output']>;
  native?: Maybe<Scalars['Boolean']['output']>;
  none?: Maybe<Scalars['Boolean']['output']>;
};

export type UserMetaIdentityLanguageType = {
  __typename?: 'UserMetaIdentityLanguageType';
  english?: Maybe<UserMetaIdentityLanguageLanguageType>;
};

export type UserMetaIdentityLocalesType = {
  __typename?: 'UserMetaIdentityLocalesType';
  ar?: Maybe<LocaleProficiency>;
  da?: Maybe<LocaleProficiency>;
  de?: Maybe<LocaleProficiency>;
  en?: Maybe<LocaleProficiency>;
  en_AU?: Maybe<LocaleProficiency>;
  en_GB?: Maybe<LocaleProficiency>;
  en_US?: Maybe<LocaleProficiency>;
  es?: Maybe<LocaleProficiency>;
  fr?: Maybe<LocaleProficiency>;
  hi?: Maybe<LocaleProficiency>;
  it?: Maybe<LocaleProficiency>;
  ja?: Maybe<LocaleProficiency>;
  ko?: Maybe<LocaleProficiency>;
  nl?: Maybe<LocaleProficiency>;
  pt?: Maybe<LocaleProficiency>;
  tr?: Maybe<LocaleProficiency>;
  zh_CN?: Maybe<LocaleProficiency>;
  zh_HK?: Maybe<LocaleProficiency>;
};

export type UserMetaIdentityNationalityAusType = {
  __typename?: 'UserMetaIdentityNationalityAUSType';
  permanent_resident?: Maybe<Scalars['Boolean']['output']>;
};

export type UserMetaIdentityNationalityType = {
  __typename?: 'UserMetaIdentityNationalityType';
  AUS?: Maybe<UserMetaIdentityNationalityAusType>;
};

export type UserMetaIdentityType = {
  __typename?: 'UserMetaIdentityType';
  birthday?: Maybe<UserMetaIdentityBirthdayType>;
  firstname?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use locales field instead to support multi locales */
  languages?: Maybe<UserMetaIdentityLanguageType>;
  lastname?: Maybe<Scalars['String']['output']>;
  locales?: Maybe<UserMetaIdentityLocalesType>;
  middlename?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<UserMetaIdentityNationalityType>;
};

export type UserMetaSocialFacebookType = {
  __typename?: 'UserMetaSocialFacebookType';
  id?: Maybe<Scalars['String']['output']>;
};

export type UserMetaSocialGoogleType = {
  __typename?: 'UserMetaSocialGoogleType';
  id?: Maybe<Scalars['String']['output']>;
};

export type UserMetaSocialLinkedinType = {
  __typename?: 'UserMetaSocialLinkedinType';
  profile_url?: Maybe<Scalars['String']['output']>;
};

export type UserMetaSocialType = {
  __typename?: 'UserMetaSocialType';
  facebook?: Maybe<UserMetaSocialFacebookType>;
  google?: Maybe<UserMetaSocialGoogleType>;
  linkedin?: Maybe<UserMetaSocialLinkedinType>;
};

export type UserMetaType = {
  __typename?: 'UserMetaType';
  acquisition?: Maybe<UserMetaAcquisitionType>;
  education?: Maybe<UserMetaEducationType>;
  family?: Maybe<UserMetaFamilyType>;
  feedback_sample?: Maybe<UserMetaFeedbackSampleType>;
  hasPromptedForAppReview?: Maybe<Scalars['Boolean']['output']>;
  identity?: Maybe<UserMetaIdentityType>;
  social?: Maybe<UserMetaSocialType>;
  work?: Maybe<UserMetaWorkType>;
};

export enum UserMetaUxLength {
  FiveOrMore = 'five_or_more',
  Four = 'four',
  OneOrLess = 'one_or_less',
  Three = 'three',
  Two = 'two',
}

export type UserMetaWorkBusinessInput = {
  size?: InputMaybe<Scalars['Int']['input']>;
  turnover?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMetaWorkBusinessType = {
  __typename?: 'UserMetaWorkBusinessType';
  size?: Maybe<Scalars['Int']['output']>;
  turnover?: Maybe<Scalars['Int']['output']>;
};

export type UserMetaWorkStatusType = {
  __typename?: 'UserMetaWorkStatusType';
  fulltime?: Maybe<Scalars['Boolean']['output']>;
  fulltime_student?: Maybe<Scalars['Boolean']['output']>;
  homeduties?: Maybe<Scalars['Boolean']['output']>;
  parttime?: Maybe<Scalars['Boolean']['output']>;
  parttime_student?: Maybe<Scalars['Boolean']['output']>;
  retired?: Maybe<Scalars['Boolean']['output']>;
  unemployed?: Maybe<Scalars['Boolean']['output']>;
};

export type UserMetaWorkType = {
  __typename?: 'UserMetaWorkType';
  _industry_id?: Maybe<Scalars['ID']['output']>;
  _subindustry_id?: Maybe<Scalars['ID']['output']>;
  business?: Maybe<UserMetaWorkBusinessType>;
  company_name?: Maybe<Scalars['String']['output']>;
  employment_type?: Maybe<Scalars['Int']['output']>;
  industry?: Maybe<IndustryType>;
  job_title?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserMetaWorkStatusType>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UserOsType = {
  __typename?: 'UserOSType';
  family?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UserParticipantInput = {
  trust_status?: InputMaybe<UserParticipantTrustStatus>;
};

export type UserParticipantNoteType = {
  __typename?: 'UserParticipantNoteType';
  _created_by?: Maybe<Scalars['ID']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
};

export enum UserParticipantTrustStatus {
  NotScammer = 'not_scammer',
  Scammer = 'scammer',
}

export type UserParticipantType = {
  __typename?: 'UserParticipantType';
  notes?: Maybe<Array<Maybe<UserParticipantNoteType>>>;
  /**  Trust score from 0 to 100. Calculated based on the suspicious activity of the user  */
  trust_score?: Maybe<Scalars['Float']['output']>;
  trust_score_updated?: Maybe<Scalars['Float']['output']>;
  trust_status?: Maybe<UserParticipantTrustStatus>;
};

export type UserResearchOnBoarding = {
  __typename?: 'UserResearchOnBoarding';
  linkedIn?: Maybe<OnboardingStatus>;
  methods?: Maybe<OnboardingStatus>;
  ux_length?: Maybe<OnboardingStatus>;
};

export type UserResearcher = {
  __typename?: 'UserResearcher';
  _user_id?: Maybe<Scalars['String']['output']>;
  admin_notes?: Maybe<Scalars['String']['output']>;
  certification?: Maybe<ResearcherCertification>;
  methods?: Maybe<Array<Maybe<ResearcherMethods>>>;
  onboarding?: Maybe<UserResearchOnBoarding>;
  projects?: Maybe<ProjectsConnection>;
  ux_length?: Maybe<UserMetaUxLength>;
  ux_length_as_of?: Maybe<Scalars['Float']['output']>;
};

export type UserResearcherProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectConnectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum UserRoles {
  Admin = 'admin',
  Client = 'client',
  Cron = 'cron',
  DemoClient = 'demo_client',
  DemoParticipant = 'demo_participant',
  Participant = 'participant',
  Researcher = 'researcher',
  SessionsObserver = 'sessions_observer',
  ShareViewer = 'share_viewer',
}

export type UserSettingsAgreementType = {
  __typename?: 'UserSettingsAgreementType';
  _id?: Maybe<Scalars['ID']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
};

export type UserSettingsBillingBankType = {
  __typename?: 'UserSettingsBillingBankType';
  account_name?: Maybe<Scalars['String']['output']>;
  account_number?: Maybe<Scalars['String']['output']>;
  branch?: Maybe<Scalars['String']['output']>;
};

export type UserSettingsBillingType = {
  __typename?: 'UserSettingsBillingType';
  bank?: Maybe<UserSettingsBillingBankType>;
  giftpay?: Maybe<Scalars['String']['output']>;
  paypal?: Maybe<Scalars['String']['output']>;
  paypal_details?: Maybe<PayPalDetails>;
  preferred?: Maybe<Scalars['String']['output']>;
  /**  User's registration number (E.g ABN or VAT). Initially used for researchers  */
  registration?: Maybe<Scalars['String']['output']>;
};

export type UserSettingsNotificationType = {
  __typename?: 'UserSettingsNotificationType';
  messages?: Maybe<UserSettingsNotificationTypeType>;
  promotional?: Maybe<UserSettingsNotificationTypeType>;
  reminders?: Maybe<UserSettingsNotificationTypeType>;
  updates?: Maybe<UserSettingsNotificationTypeType>;
};

export type UserSettingsNotificationTypeType = {
  __typename?: 'UserSettingsNotificationTypeType';
  email?: Maybe<Scalars['Boolean']['output']>;
  push?: Maybe<Scalars['Boolean']['output']>;
  sms?: Maybe<Scalars['Boolean']['output']>;
};

export type UserSettingsOnboardingType = {
  __typename?: 'UserSettingsOnboardingType';
  apricot?: Maybe<UserSettingsOnboardingVersionType>;
};

export type UserSettingsOnboardingVersionType = {
  __typename?: 'UserSettingsOnboardingVersionType';
  create_booking?: Maybe<ShowType>;
  manage_booking?: Maybe<ShowType>;
};

export type UserSettingsRemoteType = {
  __typename?: 'UserSettingsRemoteType';
  network_quality?: Maybe<Scalars['Float']['output']>;
  setup_check?: Maybe<Scalars['Float']['output']>;
};

export type UserSettingsRestrictionsType = {
  __typename?: 'UserSettingsRestrictionsType';
  _booking_id?: Maybe<Scalars['ID']['output']>;
};

export type UserSettingsTutorialType = {
  __typename?: 'UserSettingsTutorialType';
  app?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  my?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserSettingsType = {
  __typename?: 'UserSettingsType';
  agreement?: Maybe<UserSettingsAgreementType>;
  billing?: Maybe<UserSettingsBillingType>;
  notification?: Maybe<UserSettingsNotificationType>;
  remote?: Maybe<UserSettingsRemoteType>;
  restrictions?: Maybe<UserSettingsRestrictionsType>;
  tutorial?: Maybe<UserSettingsTutorialType>;
};

export enum UserStatus {
  Active = 'active',
  Banned = 'banned',
  Inactive = 'inactive',
}

export type UserStatusCount = {
  __typename?: 'UserStatusCount';
  /** (status: 3, cancel: 0, eligible: 1) */
  available_eligible?: Maybe<Scalars['Int']['output']>;
  /** (status: 3, cancel: 0) */
  available_ineligible?: Maybe<Scalars['Int']['output']>;
  /** (status: [1, 5], cancel: 1) */
  cancel_admin?: Maybe<Scalars['Int']['output']>;
  /** (status: [1, 5], cancel: 2) */
  cancel_client?: Maybe<Scalars['Int']['output']>;
  /** (status: [1, 5], cancel: 4) */
  cancel_noshow?: Maybe<Scalars['Int']['output']>;
  /** (status: [1, 5], cancel: 3) */
  cancel_participant?: Maybe<Scalars['Int']['output']>;
  /** (status: [1, 5], cancel: 6) */
  cancel_reschedule?: Maybe<Scalars['Int']['output']>;
  /** (status: [1, 5], cancel: 5) */
  cancel_system?: Maybe<Scalars['Int']['output']>;
  /** (completed: true, cancel: 0) */
  completed?: Maybe<Scalars['Int']['output']>;
  /** (status: 1, cancel: 0, [rsvpd]) */
  confirmed?: Maybe<Scalars['Int']['output']>;
  /** (status: 5, cancel: 0) */
  in_progress?: Maybe<Scalars['Int']['output']>;
  /** (status: 4, cancel: 0) */
  invited?: Maybe<Scalars['Int']['output']>;
  /** (status: 1, cancel: 0) */
  scheduled?: Maybe<Scalars['Int']['output']>;
  /** (eligible: 1) */
  unavailable_eligible?: Maybe<Scalars['Int']['output']>;
  /** (eligible: <1) */
  unavailable_ineligible?: Maybe<Scalars['Int']['output']>;
  /** (status: 2, cancel: 0) */
  waitlisted?: Maybe<Scalars['Int']['output']>;
};

export type UserSubmissionAnalysis = {
  __typename?: 'UserSubmissionAnalysis';
  _booking_ids: Array<Scalars['ObjectID']['output']>;
  _id: Scalars['ObjectID']['output'];
  _user_id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  prompt: UserSubmissionAnalysisPrompt;
  rating?: Maybe<UserSubmissionAnalysisRating>;
  response: UserSubmissionAnalysisResponse;
};

export type UserSubmissionAnalysisPrompt = {
  __typename?: 'UserSubmissionAnalysisPrompt';
  system: Scalars['String']['output'];
  user: Scalars['String']['output'];
  variables?: Maybe<UserSubmissionAnalysisPromptVariables>;
};

export type UserSubmissionAnalysisPromptVariables = {
  __typename?: 'UserSubmissionAnalysisPromptVariables';
  submissions?: Maybe<Scalars['String']['output']>;
};

export type UserSubmissionAnalysisRating = {
  __typename?: 'UserSubmissionAnalysisRating';
  _admin_user_id: Scalars['ObjectID']['output'];
  comments?: Maybe<Scalars['String']['output']>;
  date: Scalars['Float']['output'];
  inconsistency_reasoning_accurate: Scalars['Boolean']['output'];
  inconsistency_verdict_accurate: Scalars['Boolean']['output'];
  user_was_dishonest?: Maybe<Scalars['Boolean']['output']>;
};

export type UserSubmissionAnalysisResponse = {
  __typename?: 'UserSubmissionAnalysisResponse';
  inconsistency_reasoning: Scalars['String']['output'];
  inconsistency_verdict: Scalars['Float']['output'];
};

export type UserTransactionsType = {
  __typename?: 'UserTransactionsType';
  type?: Maybe<UserTypeType>;
};

export enum UserType {
  Admin = 'admin',
  Client = 'client',
  Participant = 'participant',
  Researcher = 'researcher',
  ShareViewer = 'share_viewer',
}

export type UserTypeType = {
  __typename?: 'UserTypeType';
  admin?: Maybe<Scalars['Boolean']['output']>;
  client?: Maybe<Scalars['Boolean']['output']>;
  participant?: Maybe<Scalars['Boolean']['output']>;
  researcher?: Maybe<Scalars['Boolean']['output']>;
};

export type UsersFilter = {
  _default_team?: InputMaybe<FilterId>;
  email?: InputMaybe<FilterString>;
  location?: InputMaybe<UsersFilterLocation>;
  meta?: InputMaybe<UsersFilterMeta>;
  or?: InputMaybe<Array<InputMaybe<UsersFilter>>>;
  participant?: InputMaybe<UsersFilterParticipantType>;
  type?: InputMaybe<UsersFilterUserType>;
};

export type UsersFilterLocation = {
  country?: InputMaybe<FilterString>;
};

export type UsersFilterMeta = {
  identity?: InputMaybe<UserFiltersIdentify>;
};

export type UsersFilterParticipantType = {
  trust_score?: InputMaybe<FilterFloat>;
  trust_status?: InputMaybe<FilterString>;
};

export type UsersFilterUserType = {
  client?: InputMaybe<FilterBoolean>;
  participant?: InputMaybe<FilterBoolean>;
  researcher?: InputMaybe<FilterBoolean>;
};

export type ValidatePayPalResponse = {
  __typename?: 'ValidatePayPalResponse';
  email?: Maybe<Scalars['String']['output']>;
  isValidPayPalLogin: Scalars['Boolean']['output'];
};

export type VerificationDetails = {
  __typename?: 'VerificationDetails';
  verified_account?: Maybe<Scalars['Boolean']['output']>;
};

export type VerificationResponseType = {
  __typename?: 'VerificationResponseType';
  _id?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  rate_limited?: Maybe<Scalars['Boolean']['output']>;
  resend?: Maybe<Scalars['Float']['output']>;
  sent?: Maybe<Scalars['Float']['output']>;
  /**  1: SMS, 2: PHONE_CALL  */
  type?: Maybe<Scalars['Int']['output']>;
};

export type VerifyAndSetCountryResponseType = {
  __typename?: 'VerifyAndSetCountryResponseType';
  country: Scalars['CountryCode']['output'];
};

export type VerifyPhoneAndUpdateUserInput = {
  country_code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  verification_code: Scalars['String']['input'];
};

export enum VideoCompositionStatus {
  Completed = 'completed',
  Deleted = 'deleted',
  Enqueued = 'enqueued',
  Failed = 'failed',
  Processing = 'processing',
}

export enum VideoNoteSubscriptionType {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
}

export type VideoRoomNoteFilter = {
  code: Scalars['String']['input'];
};

export type VideoRoomNoteSubscription = {
  __typename?: 'VideoRoomNoteSubscription';
  code?: Maybe<Scalars['String']['output']>;
  note?: Maybe<AskableVideoRoomNote>;
  type: VideoNoteSubscriptionType;
};

/** These align with twilios room status */
export enum VideoRoomStatus {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'inProgress',
}

export type VideoStatusType = {
  __typename?: 'VideoStatusType';
  room: Scalars['String']['output'];
  status: VideoRoomStatus;
};

export enum VideoTranscriptProviderName {
  Assemblyai = 'assemblyai',
  Deepgram = 'deepgram',
}

export enum VideoTranscriptStatus {
  Complete = 'complete',
  Failed = 'failed',
  Processing = 'processing',
  Requested = 'requested',
}

export type VideoUser = {
  __typename?: 'VideoUser';
  _id: Scalars['ObjectID']['output'];
  created: Scalars['Float']['output'];
  updated?: Maybe<Scalars['Float']['output']>;
};

export type View = {
  __typename?: 'View';
  count: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  lastViewedAt: Scalars['Float']['output'];
};

export type ViewerDeviceDetails = {
  __typename?: 'ViewerDeviceDetails';
  app_version: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last_login: Scalars['Float']['output'];
  os: Os;
  permissions?: Maybe<DevicePermissions>;
  /**  For push notifications  */
  token?: Maybe<Scalars['String']['output']>;
};

export type ViewerSubmissionsFilter = {
  status?: InputMaybe<FilterSubmissionStatus>;
};

export type PurchaseCreditsInput = {
  /**  Booking ID that is related to the purchase. Used just for metrics to identify whether or not the client is purchasing credits in bulk or PAYG  */
  _booking_id?: InputMaybe<Scalars['ID']['input']>;
  _team_id: Scalars['ID']['input'];
  coupon_code?: InputMaybe<Scalars['String']['input']>;
  credit_pack: Scalars['ID']['input'];
  currency_code?: InputMaybe<Scalars['String']['input']>;
  currency_symbol?: InputMaybe<Scalars['String']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  purchase_order?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  stripe_token?: InputMaybe<Scalars['String']['input']>;
};

export type ShowInput = {
  show?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShowType = {
  __typename?: 'showType';
  show?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateInsightIdsInCollectionInput = {
  _collection_id: Scalars['ID']['input'];
  add?: InputMaybe<Array<Scalars['ID']['input']>>;
  remove?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UserMetaHouseholdIncome = {
  __typename?: 'userMetaHouseholdIncome';
  between_50k_100k?: Maybe<Scalars['Boolean']['output']>;
  between_100k_150k?: Maybe<Scalars['Boolean']['output']>;
  between_150k_200k?: Maybe<Scalars['Boolean']['output']>;
  between_200k_250k?: Maybe<Scalars['Boolean']['output']>;
  less_than_50k?: Maybe<Scalars['Boolean']['output']>;
  more_than_250k?: Maybe<Scalars['Boolean']['output']>;
};

export type UserMetaIndividualIncome = {
  __typename?: 'userMetaIndividualIncome';
  between_30k_60k?: Maybe<Scalars['Boolean']['output']>;
  between_60k_90k?: Maybe<Scalars['Boolean']['output']>;
  between_90k_120k?: Maybe<Scalars['Boolean']['output']>;
  between_120k_150k?: Maybe<Scalars['Boolean']['output']>;
  less_than_30k?: Maybe<Scalars['Boolean']['output']>;
  more_than_150k?: Maybe<Scalars['Boolean']['output']>;
};

export type ApproveProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ApproveProjectMutation = {
  __typename?: 'Mutation';
  updateProject?: { __typename?: 'Project'; _id: string } | null;
};

export type CreateResearcherBatchMutationVariables = Exact<{
  input: BatchInput;
}>;

export type CreateResearcherBatchMutation = { __typename?: 'Mutation'; createBatch?: number | null };

export type InviteResearcherToProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  researcherId: Scalars['ID']['input'];
}>;

export type InviteResearcherToProjectMutation = {
  __typename?: 'Mutation';
  inviteResearcherToProject?: { __typename?: 'Project'; _id: string } | null;
};

export type ProjectApplicantsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ProjectApplicantsQuery = {
  __typename?: 'Query';
  projectByID?: {
    __typename?: 'Project';
    users?: Array<{
      __typename?: 'ProjectUser';
      status?: ProjectUserStatus | null;
      _id: string;
      added_date?: number | null;
      invited_at?: number | null;
      accepted_at?: number | null;
      User?: {
        __typename?: 'User';
        email?: string | null;
        displayName?: string | null;
        researcher?: {
          __typename?: 'UserResearcher';
          certification?: {
            __typename?: 'ResearcherCertification';
            status?: ResearcherCertificationStatus | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AdminDeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminDeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: { __typename?: 'User'; _id: string; displayName?: string | null } | null;
};

export type UserSubmissionAnalysisFieldsFragment = {
  __typename?: 'UserSubmissionAnalysis';
  _id: any;
  _booking_ids: Array<any>;
  created: number;
  prompt: {
    __typename?: 'UserSubmissionAnalysisPrompt';
    user: string;
    system: string;
    variables?: { __typename?: 'UserSubmissionAnalysisPromptVariables'; submissions?: string | null } | null;
  };
  response: {
    __typename?: 'UserSubmissionAnalysisResponse';
    inconsistency_reasoning: string;
    inconsistency_verdict: number;
  };
  rating?: {
    __typename?: 'UserSubmissionAnalysisRating';
    _admin_user_id: any;
    date: number;
    inconsistency_verdict_accurate: boolean;
    inconsistency_reasoning_accurate: boolean;
    user_was_dishonest?: boolean | null;
    comments?: string | null;
  } | null;
};

export type BookingPageBookingFieldsFragment = {
  __typename?: 'Booking';
  _id: string;
  status?: number | null;
  _owner_id?: Array<string | null> | null;
  _admin_id?: string | null;
  name?: string | null;
  type?: number | null;
  total_participants?: number | null;
  team_booking_index?: number | null;
  _team_id?: string | null;
  admin_user?: {
    __typename?: 'User';
    _id: string;
    picture?: string | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
    } | null;
  } | null;
  settings?: { __typename?: 'BookingSettings'; translation: boolean } | null;
  CurrencyData?: Array<{
    __typename?: 'CurrencyData';
    currency_code: string;
    currency_symbol: string;
    country_code: string;
  }> | null;
  admin?: {
    __typename?: 'BookingAdminType';
    emoji?: string | null;
    mission_critical?: boolean | null;
    mission_critical_reason?: string | null;
    comments?: Array<{
      __typename?: 'BookingAdminCommentType';
      _id: string;
      _user_id?: string | null;
      created?: number | null;
      comment?: string | null;
      User?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  config?: {
    __typename?: 'BookingConfig';
    min_participant_rating?: number | null;
    credits_per_participant?: number | null;
    participant_category?: number | null;
    timezone?: string | null;
    continuous_discovery?: boolean | null;
    hidden?: boolean | null;
    countries?: Array<any> | null;
    location?: {
      __typename?: 'Location';
      country?: any | null;
      state?: string | null;
      region?: string | null;
      postal_code?: string | null;
      city?: string | null;
      name?: string | null;
      level?: string | null;
      street1?: string | null;
      street2?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    session?: {
      __typename?: 'SessionBookingConfig';
      type?: number | null;
      duration?: number | null;
      time_limit?: number | null;
      slot_min?: number | null;
      slot_max?: number | null;
    } | null;
    incentive?: {
      __typename?: 'IncentiveBookingConfig';
      currency_symbol?: string | null;
      value?: number | null;
    } | null;
    incentives?: Array<{
      __typename?: 'IncentiveBookingConfig';
      currency_code?: string | null;
      currency_symbol?: string | null;
      country_code?: any | null;
      value?: number | null;
    } | null> | null;
    criteria?: {
      __typename?: 'CriteriaBookingConfig';
      locations?: {
        __typename?: 'LocationCriteria';
        countries?: Array<{
          __typename?: 'Location';
          name?: string | null;
          country?: any | null;
          currency_code?: string | null;
          currency_symbol?: string | null;
        } | null> | null;
        bounds?: Array<{
          __typename?: 'Location';
          name?: string | null;
          country?: any | null;
          currency_code?: string | null;
          currency_symbol?: string | null;
        } | null> | null;
        states?: Array<{
          __typename?: 'Location';
          name?: string | null;
          country?: any | null;
          currency_code?: string | null;
          currency_symbol?: string | null;
        } | null> | null;
      } | null;
      meta_identity_locales?: Array<{
        __typename?: 'CriteriaBookingConfigMetaIdentityLocales';
        locale: Locale;
      } | null> | null;
    } | null;
    contact?: {
      __typename?: 'BookingConfigContact';
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      UserOrganiser?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
        contact?: {
          __typename?: 'UserContactType';
          phone?: { __typename?: 'UserContactPhoneType'; country_code?: string | null; mobile?: string | null } | null;
        } | null;
      } | null;
      UserFacilitator?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
        contact?: {
          __typename?: 'UserContactType';
          phone?: { __typename?: 'UserContactPhoneType'; country_code?: string | null; mobile?: string | null } | null;
        } | null;
      } | null;
    } | null;
    options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
    online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
    longitudinal_study?: {
      __typename?: 'LongitudinalStudyBookingConfig';
      period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
    } | null;
    project?: {
      __typename?: 'ProjectBookingConfig';
      project_type?: number | null;
      allowed_credits?: number | null;
    } | null;
    block_participants?: {
      __typename?: 'BookingConfigBlockParticipants';
      unverified_paypal?: boolean | null;
      giftpay?: boolean | null;
    } | null;
  } | null;
  session?: Array<{
    __typename?: 'BookingSession';
    _id?: string | null;
    start?: number | null;
    end?: number | null;
    status?: number | null;
  } | null> | null;
  user?: {
    __typename?: 'User';
    _id: string;
    email?: string | null;
    contact?: {
      __typename?: 'UserContactType';
      phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
    } | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
    } | null;
  } | null;
  team?: {
    __typename?: 'Teams';
    _id: string;
    name?: string | null;
    operational_office?: OperationalOffices | null;
    users?: Array<{
      __typename?: 'TeamsUsersType';
      _id: string;
      User?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  UserStatusCount?: {
    __typename?: 'UserStatusCount';
    completed?: number | null;
    confirmed?: number | null;
    scheduled?: number | null;
    waitlisted?: number | null;
    invited?: number | null;
    in_progress?: number | null;
    cancel_admin?: number | null;
    cancel_client?: number | null;
    cancel_participant?: number | null;
    cancel_noshow?: number | null;
    cancel_system?: number | null;
    cancel_reschedule?: number | null;
    available_eligible?: number | null;
    available_ineligible?: number | null;
    unavailable_eligible?: number | null;
    unavailable_ineligible?: number | null;
  } | null;
  booking_participant?: Array<{
    __typename?: 'BookingParticipant';
    _id: string;
    _session_id?: string | null;
    _user_id?: string | null;
    cancel?: number | null;
    status?: number | null;
  } | null> | null;
  credit_activity?: Array<{
    __typename?: 'CreditActivity';
    _id: string;
    type?: number | null;
    accounting_type?: number | null;
    amount?: number | null;
    comment?: string | null;
    created?: number | null;
    Transaction?: {
      __typename?: 'Transactions';
      _id: string;
      total_amount?: number | null;
      currency?: string | null;
      comment?: string | null;
      method?: number | null;
      invoice?: {
        __typename?: 'InvoiceTransactionsType';
        amount_due?: number | null;
        purchase_order?: string | null;
        reference?: string | null;
        xero_id?: string | null;
      } | null;
    } | null;
    User?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      type?: { __typename?: 'UserTypeType'; client?: boolean | null; admin?: boolean | null } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
  } | null> | null;
  history?: { __typename?: 'BookingHistory'; completed_date?: number | null } | null;
};

export type BookingsListCurrentFieldsFragment = {
  __typename?: 'Booking';
  _id: string;
  created?: number | null;
  updated?: number | null;
  name?: string | null;
  status?: number | null;
  approved_date?: number | null;
  confirmed_date?: number | null;
  type?: number | null;
  total_participants?: number | null;
  team_booking_index?: number | null;
  _admin_id?: string | null;
  admin_user?: {
    __typename?: 'User';
    _id: string;
    picture?: string | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
    } | null;
  } | null;
  config?: {
    __typename?: 'BookingConfig';
    demo?: boolean | null;
    credits_per_participant?: number | null;
    participant_category?: number | null;
    hidden?: boolean | null;
    location?: {
      __typename?: 'Location';
      country?: any | null;
      state?: string | null;
      region?: string | null;
      city?: string | null;
    } | null;
    in_context?: { __typename?: 'InContextBookingConfig'; location_type?: number | null } | null;
    contact?: {
      __typename?: 'BookingConfigContact';
      name?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    session?: { __typename?: 'SessionBookingConfig'; type?: number | null; duration?: number | null } | null;
    incentives?: Array<{
      __typename?: 'IncentiveBookingConfig';
      currency_code?: string | null;
      currency_symbol?: string | null;
      country_code?: any | null;
      value?: number | null;
    } | null> | null;
    options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
    recruitment?: { __typename?: 'RecruitmentBookingConfig'; byo?: boolean | null } | null;
    online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
    longitudinal_study?: {
      __typename?: 'LongitudinalStudyBookingConfig';
      period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
    } | null;
    project?: {
      __typename?: 'ProjectBookingConfig';
      project_type?: number | null;
      allowed_credits?: number | null;
    } | null;
    criteria?: {
      __typename?: 'CriteriaBookingConfig';
      locations?: {
        __typename?: 'LocationCriteria';
        bounds?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
        countries?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
        states?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
      } | null;
    } | null;
  } | null;
  session?: Array<{
    __typename?: 'BookingSession';
    _id?: string | null;
    start?: number | null;
    end?: number | null;
  } | null> | null;
  admin?: { __typename?: 'BookingAdminType'; emoji?: string | null; mission_critical?: boolean | null } | null;
  team?: {
    __typename?: 'Teams';
    _id: string;
    name?: string | null;
    operational_office?: OperationalOffices | null;
    settings?: {
      __typename?: 'TeamSettingsType';
      ignore_tracking?: boolean | null;
      billing?: {
        __typename?: 'TeamSettingsBilling';
        company_name?: string | null;
        subscription?: {
          __typename?: 'TeamSettingsBillingSubscription';
          credit?: { __typename?: 'TeamSettingsBillingSubscriptionCredit'; remaining?: number | null } | null;
        } | null;
      } | null;
    } | null;
  } | null;
  user?: {
    __typename?: 'User';
    _id: string;
    email?: string | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
    } | null;
    contact?: {
      __typename?: 'UserContactType';
      phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
    } | null;
  } | null;
  credit_activity?: Array<{
    __typename?: 'CreditActivity';
    created?: number | null;
    amount?: number | null;
    accounting_type?: number | null;
    type?: number | null;
    comment?: string | null;
  } | null> | null;
  AutomationScore?: {
    __typename?: 'BookingAutomationScore';
    admin_actions?: number | null;
    score?: number | null;
  } | null;
  UserStatusCount?: {
    __typename?: 'UserStatusCount';
    completed?: number | null;
    confirmed?: number | null;
    scheduled?: number | null;
    waitlisted?: number | null;
    invited?: number | null;
    in_progress?: number | null;
    cancel_admin?: number | null;
    cancel_client?: number | null;
    cancel_participant?: number | null;
    cancel_noshow?: number | null;
    cancel_system?: number | null;
    cancel_reschedule?: number | null;
    available_eligible?: number | null;
    available_ineligible?: number | null;
    unavailable_eligible?: number | null;
    unavailable_ineligible?: number | null;
  } | null;
  ParticipantRatings?: Array<{ __typename?: 'BookingSubmissionRating'; overall?: number | null } | null> | null;
  project?: { __typename?: 'Project'; _id: string; name?: string | null; type?: number | null } | null;
};

export type UserStatusCountFieldsFragment = {
  __typename?: 'UserStatusCount';
  completed?: number | null;
  confirmed?: number | null;
  scheduled?: number | null;
  waitlisted?: number | null;
  invited?: number | null;
  in_progress?: number | null;
  cancel_admin?: number | null;
  cancel_client?: number | null;
  cancel_participant?: number | null;
  cancel_noshow?: number | null;
  cancel_system?: number | null;
  cancel_reschedule?: number | null;
  available_eligible?: number | null;
  available_ineligible?: number | null;
  unavailable_eligible?: number | null;
  unavailable_ineligible?: number | null;
};

export type ESignatureTemplateFieldsFragment = {
  __typename?: 'ESignatureTemplate';
  _id?: string | null;
  global?: boolean | null;
  active?: boolean | null;
  _team_id?: string | null;
  _organisation_id?: string | null;
  name?: string | null;
  description?: string | null;
  recipients?: Array<string | null> | null;
  created?: number | null;
  updated?: number | null;
  provider?: { __typename?: 'ESignatureTemplateProvider'; name?: string | null; reference?: string | null } | null;
};

export type MessagesFieldsFragment = {
  __typename?: 'Messages';
  _id: string;
  type?: number | null;
  tag?: string | null;
  body?: string | null;
  seen?: number | null;
  created?: number | null;
  _to_user_id?: string | null;
  _from_user_id?: string | null;
  direction?: number | null;
  UserFrom?: {
    __typename?: 'User';
    _id: string;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
    } | null;
  } | null;
  context?: { __typename?: 'MessagesContextType'; _booking_id?: string | null; _user_id?: string | null } | null;
};

export type TeamsListFieldsFragment = {
  __typename?: 'Teams';
  _id: string;
  name?: string | null;
  created?: number | null;
  updated?: number | null;
  operational_office?: OperationalOffices | null;
  settings?: {
    __typename?: 'TeamSettingsType';
    ignore_tracking?: boolean | null;
    billing?: {
      __typename?: 'TeamSettingsBilling';
      company_name?: string | null;
      subscription?: {
        __typename?: 'TeamSettingsBillingSubscription';
        end?: number | null;
        credit?: { __typename?: 'TeamSettingsBillingSubscriptionCredit'; remaining?: number | null } | null;
      } | null;
    } | null;
  } | null;
  Organisation?: { __typename?: 'OrganisationType'; _id: string; name?: string | null } | null;
  ParentTeam?: {
    __typename?: 'Teams';
    _id: string;
    name?: string | null;
    settings?: { __typename?: 'TeamSettingsType'; ignore_tracking?: boolean | null } | null;
  } | null;
};

export type ParticipantFieldsFragment = {
  __typename?: 'User';
  _id: string;
  email?: string | null;
  status?: number | null;
  blacklist?: boolean | null;
  created?: number | null;
  updated?: number | null;
  LastLogin?: number | null;
  contact?: {
    __typename?: 'UserContactType';
    phone?: {
      __typename?: 'UserContactPhoneType';
      mobile?: string | null;
      verification?: { __typename?: 'UserContactPhoneVerificationType'; mobile?: string | null } | null;
    } | null;
  } | null;
  location?: {
    __typename?: 'Location';
    city?: string | null;
    state?: string | null;
    postal_code?: string | null;
    country?: any | null;
    longitude?: number | null;
    latitude?: number | null;
    timezone?: string | null;
  } | null;
  meta?: {
    __typename?: 'UserMetaType';
    identity?: {
      __typename?: 'UserMetaIdentityType';
      firstname?: string | null;
      lastname?: string | null;
      gender?: string | null;
      birthday?: { __typename?: 'UserMetaIdentityBirthdayType'; timestamp?: number | null } | null;
      languages?: {
        __typename?: 'UserMetaIdentityLanguageType';
        english?: {
          __typename?: 'UserMetaIdentityLanguageLanguageType';
          speak?: {
            __typename?: 'UserMetaIdentityLanguageProficiencyType';
            native?: boolean | null;
            fluent?: boolean | null;
            conversational?: boolean | null;
            beginner?: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    family?: {
      __typename?: 'UserMetaFamilyType';
      status?: {
        __typename?: 'UserMetaFamilyStatusType';
        divorced?: boolean | null;
        married?: boolean | null;
        relationship?: boolean | null;
        single?: boolean | null;
        widowed?: boolean | null;
      } | null;
    } | null;
    education?: {
      __typename?: 'UserMetaEducationType';
      apprenticeship?: boolean | null;
      highschool?: boolean | null;
      postgraduate?: boolean | null;
      some_university?: boolean | null;
      undergraduate?: boolean | null;
    } | null;
    work?: {
      __typename?: 'UserMetaWorkType';
      _industry_id?: string | null;
      _subindustry_id?: string | null;
      employment_type?: number | null;
      status?: {
        __typename?: 'UserMetaWorkStatusType';
        fulltime?: boolean | null;
        fulltime_student?: boolean | null;
        homeduties?: boolean | null;
        parttime?: boolean | null;
        parttime_student?: boolean | null;
        retired?: boolean | null;
        unemployed?: boolean | null;
      } | null;
      business?: { __typename?: 'UserMetaWorkBusinessType'; size?: number | null; turnover?: number | null } | null;
    } | null;
    social?: {
      __typename?: 'UserMetaSocialType';
      linkedin?: { __typename?: 'UserMetaSocialLinkedinType'; profile_url?: string | null } | null;
    } | null;
    feedback_sample?: { __typename?: 'UserMetaFeedbackSampleType'; recording_url?: string | null } | null;
  } | null;
  settings?: {
    __typename?: 'UserSettingsType';
    billing?: {
      __typename?: 'UserSettingsBillingType';
      preferred?: string | null;
      paypal?: string | null;
      giftpay?: string | null;
      paypal_details?: {
        __typename?: 'PayPalDetails';
        verification_details?: { __typename?: 'VerificationDetails'; verified_account?: boolean | null } | null;
      } | null;
    } | null;
    remote?: {
      __typename?: 'UserSettingsRemoteType';
      setup_check?: number | null;
      network_quality?: number | null;
    } | null;
  } | null;
  ParticipantIncentives?: Array<{ __typename?: 'Transactions'; total_amount?: number | null } | null> | null;
  rating?: { __typename?: 'ParticipantRating'; ratings?: number | null; score?: number | null } | null;
  devices?: Array<{
    __typename?: 'ViewerDeviceDetails';
    id: string;
    os: Os;
    app_version: string;
    last_login: number;
    token?: string | null;
  } | null> | null;
};

export type WigFieldsFragment = {
  __typename?: 'AdminWig';
  _id?: string | null;
  period_name?: string | null;
  period_start?: number | null;
  period_end?: number | null;
  period_type?: AdminWigPeriodTypes | null;
  operational_office?: OperationalOffices | null;
  goals?: Array<{
    __typename?: 'AdminWigGoal';
    key?: string | null;
    value?: number | null;
    meta?: { __typename?: 'AdminWigGoalMeta'; name?: string | null; prefix?: string | null } | null;
  } | null> | null;
};

export type CurrentWigFieldsFragment = {
  __typename?: 'AdminWig';
  _id?: string | null;
  period_name?: string | null;
  period_start?: number | null;
  period_end?: number | null;
  period_type?: AdminWigPeriodTypes | null;
  operational_office?: OperationalOffices | null;
  goals?: Array<{
    __typename?: 'AdminWigGoal';
    key?: string | null;
    value?: number | null;
    progress?: number | null;
    ghost?: number | null;
    meta?: {
      __typename?: 'AdminWigGoalMeta';
      name?: string | null;
      prefix?: string | null;
      emoji?: string | null;
      bar_colour?: string | null;
    } | null;
  } | null> | null;
};

export type Admin_LoginMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  googleToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type Admin_LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'User';
    _id: string;
    access_token?: string | null;
    roles?: Array<UserRoles | null> | null;
    email?: string | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
    } | null;
  } | null;
};

export type Admin_ApproveBookingMutationVariables = Exact<{
  booking: Scalars['ID']['input'];
  incentives: Array<BookingInputConfigIncentive> | BookingInputConfigIncentive;
  settings: ApproveBookingSettingsInput;
}>;

export type Admin_ApproveBookingMutation = {
  __typename?: 'Mutation';
  approveBooking?: {
    __typename?: 'Booking';
    _id: string;
    status?: number | null;
    _owner_id?: Array<string | null> | null;
    _admin_id?: string | null;
    name?: string | null;
    type?: number | null;
    total_participants?: number | null;
    team_booking_index?: number | null;
    _team_id?: string | null;
    admin_user?: {
      __typename?: 'User';
      _id: string;
      picture?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    settings?: { __typename?: 'BookingSettings'; translation: boolean } | null;
    CurrencyData?: Array<{
      __typename?: 'CurrencyData';
      currency_code: string;
      currency_symbol: string;
      country_code: string;
    }> | null;
    admin?: {
      __typename?: 'BookingAdminType';
      emoji?: string | null;
      mission_critical?: boolean | null;
      mission_critical_reason?: string | null;
      comments?: Array<{
        __typename?: 'BookingAdminCommentType';
        _id: string;
        _user_id?: string | null;
        created?: number | null;
        comment?: string | null;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    config?: {
      __typename?: 'BookingConfig';
      min_participant_rating?: number | null;
      credits_per_participant?: number | null;
      participant_category?: number | null;
      timezone?: string | null;
      continuous_discovery?: boolean | null;
      hidden?: boolean | null;
      countries?: Array<any> | null;
      location?: {
        __typename?: 'Location';
        country?: any | null;
        state?: string | null;
        region?: string | null;
        postal_code?: string | null;
        city?: string | null;
        name?: string | null;
        level?: string | null;
        street1?: string | null;
        street2?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      session?: {
        __typename?: 'SessionBookingConfig';
        type?: number | null;
        duration?: number | null;
        time_limit?: number | null;
        slot_min?: number | null;
        slot_max?: number | null;
      } | null;
      incentive?: {
        __typename?: 'IncentiveBookingConfig';
        currency_symbol?: string | null;
        value?: number | null;
      } | null;
      incentives?: Array<{
        __typename?: 'IncentiveBookingConfig';
        currency_code?: string | null;
        currency_symbol?: string | null;
        country_code?: any | null;
        value?: number | null;
      } | null> | null;
      criteria?: {
        __typename?: 'CriteriaBookingConfig';
        locations?: {
          __typename?: 'LocationCriteria';
          countries?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          bounds?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          states?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
        } | null;
        meta_identity_locales?: Array<{
          __typename?: 'CriteriaBookingConfigMetaIdentityLocales';
          locale: Locale;
        } | null> | null;
      } | null;
      contact?: {
        __typename?: 'BookingConfigContact';
        email?: string | null;
        name?: string | null;
        phone?: string | null;
        UserOrganiser?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
        UserFacilitator?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
      longitudinal_study?: {
        __typename?: 'LongitudinalStudyBookingConfig';
        period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
      } | null;
      project?: {
        __typename?: 'ProjectBookingConfig';
        project_type?: number | null;
        allowed_credits?: number | null;
      } | null;
      block_participants?: {
        __typename?: 'BookingConfigBlockParticipants';
        unverified_paypal?: boolean | null;
        giftpay?: boolean | null;
      } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
      status?: number | null;
    } | null> | null;
    user?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      operational_office?: OperationalOffices | null;
      users?: Array<{
        __typename?: 'TeamsUsersType';
        _id: string;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    UserStatusCount?: {
      __typename?: 'UserStatusCount';
      completed?: number | null;
      confirmed?: number | null;
      scheduled?: number | null;
      waitlisted?: number | null;
      invited?: number | null;
      in_progress?: number | null;
      cancel_admin?: number | null;
      cancel_client?: number | null;
      cancel_participant?: number | null;
      cancel_noshow?: number | null;
      cancel_system?: number | null;
      cancel_reschedule?: number | null;
      available_eligible?: number | null;
      available_ineligible?: number | null;
      unavailable_eligible?: number | null;
      unavailable_ineligible?: number | null;
    } | null;
    booking_participant?: Array<{
      __typename?: 'BookingParticipant';
      _id: string;
      _session_id?: string | null;
      _user_id?: string | null;
      cancel?: number | null;
      status?: number | null;
    } | null> | null;
    credit_activity?: Array<{
      __typename?: 'CreditActivity';
      _id: string;
      type?: number | null;
      accounting_type?: number | null;
      amount?: number | null;
      comment?: string | null;
      created?: number | null;
      Transaction?: {
        __typename?: 'Transactions';
        _id: string;
        total_amount?: number | null;
        currency?: string | null;
        comment?: string | null;
        method?: number | null;
        invoice?: {
          __typename?: 'InvoiceTransactionsType';
          amount_due?: number | null;
          purchase_order?: string | null;
          reference?: string | null;
          xero_id?: string | null;
        } | null;
      } | null;
      User?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        type?: { __typename?: 'UserTypeType'; client?: boolean | null; admin?: boolean | null } | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    history?: { __typename?: 'BookingHistory'; completed_date?: number | null } | null;
  } | null;
};

export type Admin_BookingPageCommentMutationVariables = Exact<{
  booking: Scalars['ID']['input'];
  comment: Scalars['String']['input'];
}>;

export type Admin_BookingPageCommentMutation = {
  __typename?: 'Mutation';
  addAdminComment?: {
    __typename?: 'Booking';
    _id: string;
    status?: number | null;
    _owner_id?: Array<string | null> | null;
    _admin_id?: string | null;
    name?: string | null;
    type?: number | null;
    total_participants?: number | null;
    team_booking_index?: number | null;
    _team_id?: string | null;
    admin_user?: {
      __typename?: 'User';
      _id: string;
      picture?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    settings?: { __typename?: 'BookingSettings'; translation: boolean } | null;
    CurrencyData?: Array<{
      __typename?: 'CurrencyData';
      currency_code: string;
      currency_symbol: string;
      country_code: string;
    }> | null;
    admin?: {
      __typename?: 'BookingAdminType';
      emoji?: string | null;
      mission_critical?: boolean | null;
      mission_critical_reason?: string | null;
      comments?: Array<{
        __typename?: 'BookingAdminCommentType';
        _id: string;
        _user_id?: string | null;
        created?: number | null;
        comment?: string | null;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    config?: {
      __typename?: 'BookingConfig';
      min_participant_rating?: number | null;
      credits_per_participant?: number | null;
      participant_category?: number | null;
      timezone?: string | null;
      continuous_discovery?: boolean | null;
      hidden?: boolean | null;
      countries?: Array<any> | null;
      location?: {
        __typename?: 'Location';
        country?: any | null;
        state?: string | null;
        region?: string | null;
        postal_code?: string | null;
        city?: string | null;
        name?: string | null;
        level?: string | null;
        street1?: string | null;
        street2?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      session?: {
        __typename?: 'SessionBookingConfig';
        type?: number | null;
        duration?: number | null;
        time_limit?: number | null;
        slot_min?: number | null;
        slot_max?: number | null;
      } | null;
      incentive?: {
        __typename?: 'IncentiveBookingConfig';
        currency_symbol?: string | null;
        value?: number | null;
      } | null;
      incentives?: Array<{
        __typename?: 'IncentiveBookingConfig';
        currency_code?: string | null;
        currency_symbol?: string | null;
        country_code?: any | null;
        value?: number | null;
      } | null> | null;
      criteria?: {
        __typename?: 'CriteriaBookingConfig';
        locations?: {
          __typename?: 'LocationCriteria';
          countries?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          bounds?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          states?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
        } | null;
        meta_identity_locales?: Array<{
          __typename?: 'CriteriaBookingConfigMetaIdentityLocales';
          locale: Locale;
        } | null> | null;
      } | null;
      contact?: {
        __typename?: 'BookingConfigContact';
        email?: string | null;
        name?: string | null;
        phone?: string | null;
        UserOrganiser?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
        UserFacilitator?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
      longitudinal_study?: {
        __typename?: 'LongitudinalStudyBookingConfig';
        period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
      } | null;
      project?: {
        __typename?: 'ProjectBookingConfig';
        project_type?: number | null;
        allowed_credits?: number | null;
      } | null;
      block_participants?: {
        __typename?: 'BookingConfigBlockParticipants';
        unverified_paypal?: boolean | null;
        giftpay?: boolean | null;
      } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
      status?: number | null;
    } | null> | null;
    user?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      operational_office?: OperationalOffices | null;
      users?: Array<{
        __typename?: 'TeamsUsersType';
        _id: string;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    UserStatusCount?: {
      __typename?: 'UserStatusCount';
      completed?: number | null;
      confirmed?: number | null;
      scheduled?: number | null;
      waitlisted?: number | null;
      invited?: number | null;
      in_progress?: number | null;
      cancel_admin?: number | null;
      cancel_client?: number | null;
      cancel_participant?: number | null;
      cancel_noshow?: number | null;
      cancel_system?: number | null;
      cancel_reschedule?: number | null;
      available_eligible?: number | null;
      available_ineligible?: number | null;
      unavailable_eligible?: number | null;
      unavailable_ineligible?: number | null;
    } | null;
    booking_participant?: Array<{
      __typename?: 'BookingParticipant';
      _id: string;
      _session_id?: string | null;
      _user_id?: string | null;
      cancel?: number | null;
      status?: number | null;
    } | null> | null;
    credit_activity?: Array<{
      __typename?: 'CreditActivity';
      _id: string;
      type?: number | null;
      accounting_type?: number | null;
      amount?: number | null;
      comment?: string | null;
      created?: number | null;
      Transaction?: {
        __typename?: 'Transactions';
        _id: string;
        total_amount?: number | null;
        currency?: string | null;
        comment?: string | null;
        method?: number | null;
        invoice?: {
          __typename?: 'InvoiceTransactionsType';
          amount_due?: number | null;
          purchase_order?: string | null;
          reference?: string | null;
          xero_id?: string | null;
        } | null;
      } | null;
      User?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        type?: { __typename?: 'UserTypeType'; client?: boolean | null; admin?: boolean | null } | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    history?: { __typename?: 'BookingHistory'; completed_date?: number | null } | null;
  } | null;
};

export type Admin_BookingPageRejectBookingMutationVariables = Exact<{
  booking: Scalars['ID']['input'];
}>;

export type Admin_BookingPageRejectBookingMutation = {
  __typename?: 'Mutation';
  rejectBooking?: {
    __typename?: 'Booking';
    _id: string;
    status?: number | null;
    _owner_id?: Array<string | null> | null;
    _admin_id?: string | null;
    name?: string | null;
    type?: number | null;
    total_participants?: number | null;
    team_booking_index?: number | null;
    _team_id?: string | null;
    admin_user?: {
      __typename?: 'User';
      _id: string;
      picture?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    settings?: { __typename?: 'BookingSettings'; translation: boolean } | null;
    CurrencyData?: Array<{
      __typename?: 'CurrencyData';
      currency_code: string;
      currency_symbol: string;
      country_code: string;
    }> | null;
    admin?: {
      __typename?: 'BookingAdminType';
      emoji?: string | null;
      mission_critical?: boolean | null;
      mission_critical_reason?: string | null;
      comments?: Array<{
        __typename?: 'BookingAdminCommentType';
        _id: string;
        _user_id?: string | null;
        created?: number | null;
        comment?: string | null;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    config?: {
      __typename?: 'BookingConfig';
      min_participant_rating?: number | null;
      credits_per_participant?: number | null;
      participant_category?: number | null;
      timezone?: string | null;
      continuous_discovery?: boolean | null;
      hidden?: boolean | null;
      countries?: Array<any> | null;
      location?: {
        __typename?: 'Location';
        country?: any | null;
        state?: string | null;
        region?: string | null;
        postal_code?: string | null;
        city?: string | null;
        name?: string | null;
        level?: string | null;
        street1?: string | null;
        street2?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      session?: {
        __typename?: 'SessionBookingConfig';
        type?: number | null;
        duration?: number | null;
        time_limit?: number | null;
        slot_min?: number | null;
        slot_max?: number | null;
      } | null;
      incentive?: {
        __typename?: 'IncentiveBookingConfig';
        currency_symbol?: string | null;
        value?: number | null;
      } | null;
      incentives?: Array<{
        __typename?: 'IncentiveBookingConfig';
        currency_code?: string | null;
        currency_symbol?: string | null;
        country_code?: any | null;
        value?: number | null;
      } | null> | null;
      criteria?: {
        __typename?: 'CriteriaBookingConfig';
        locations?: {
          __typename?: 'LocationCriteria';
          countries?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          bounds?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          states?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
        } | null;
        meta_identity_locales?: Array<{
          __typename?: 'CriteriaBookingConfigMetaIdentityLocales';
          locale: Locale;
        } | null> | null;
      } | null;
      contact?: {
        __typename?: 'BookingConfigContact';
        email?: string | null;
        name?: string | null;
        phone?: string | null;
        UserOrganiser?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
        UserFacilitator?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
      longitudinal_study?: {
        __typename?: 'LongitudinalStudyBookingConfig';
        period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
      } | null;
      project?: {
        __typename?: 'ProjectBookingConfig';
        project_type?: number | null;
        allowed_credits?: number | null;
      } | null;
      block_participants?: {
        __typename?: 'BookingConfigBlockParticipants';
        unverified_paypal?: boolean | null;
        giftpay?: boolean | null;
      } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
      status?: number | null;
    } | null> | null;
    user?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      operational_office?: OperationalOffices | null;
      users?: Array<{
        __typename?: 'TeamsUsersType';
        _id: string;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    UserStatusCount?: {
      __typename?: 'UserStatusCount';
      completed?: number | null;
      confirmed?: number | null;
      scheduled?: number | null;
      waitlisted?: number | null;
      invited?: number | null;
      in_progress?: number | null;
      cancel_admin?: number | null;
      cancel_client?: number | null;
      cancel_participant?: number | null;
      cancel_noshow?: number | null;
      cancel_system?: number | null;
      cancel_reschedule?: number | null;
      available_eligible?: number | null;
      available_ineligible?: number | null;
      unavailable_eligible?: number | null;
      unavailable_ineligible?: number | null;
    } | null;
    booking_participant?: Array<{
      __typename?: 'BookingParticipant';
      _id: string;
      _session_id?: string | null;
      _user_id?: string | null;
      cancel?: number | null;
      status?: number | null;
    } | null> | null;
    credit_activity?: Array<{
      __typename?: 'CreditActivity';
      _id: string;
      type?: number | null;
      accounting_type?: number | null;
      amount?: number | null;
      comment?: string | null;
      created?: number | null;
      Transaction?: {
        __typename?: 'Transactions';
        _id: string;
        total_amount?: number | null;
        currency?: string | null;
        comment?: string | null;
        method?: number | null;
        invoice?: {
          __typename?: 'InvoiceTransactionsType';
          amount_due?: number | null;
          purchase_order?: string | null;
          reference?: string | null;
          xero_id?: string | null;
        } | null;
      } | null;
      User?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        type?: { __typename?: 'UserTypeType'; client?: boolean | null; admin?: boolean | null } | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    history?: { __typename?: 'BookingHistory'; completed_date?: number | null } | null;
  } | null;
};

export type CreateSessionMutationVariables = Exact<{
  booking_id: Scalars['ID']['input'];
  session: Array<InputMaybe<BookingInputSession>> | InputMaybe<BookingInputSession>;
}>;

export type CreateSessionMutation = {
  __typename?: 'Mutation';
  createSession?: {
    __typename?: 'Booking';
    _id: string;
    name?: string | null;
    status?: number | null;
    config?: {
      __typename?: 'BookingConfig';
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
      slot_min?: number | null;
      slot_max?: number | null;
      status?: number | null;
    } | null> | null;
  } | null;
};

export type MoveBookingToAnotherTeamMutationVariables = Exact<{
  booking_id: Scalars['ID']['input'];
  new_team_id: Scalars['ID']['input'];
  move_credit_activity?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MoveBookingToAnotherTeamMutation = {
  __typename?: 'Mutation';
  moveBookingToAnotherTeam?: { __typename?: 'Booking'; _id: string } | null;
};

export type UpdateBookingMutationVariables = Exact<{
  booking_id: Scalars['ID']['input'];
  booking: BookingInput;
}>;

export type UpdateBookingMutation = {
  __typename?: 'Mutation';
  updateBooking?: {
    __typename?: 'Booking';
    _id: string;
    name?: string | null;
    status?: number | null;
    config?: {
      __typename?: 'BookingConfig';
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null } | null;
    } | null;
  } | null;
};

export type UpdateBookingAdminMutationVariables = Exact<{
  booking_id: Scalars['ID']['input'];
  booking: BookingInput;
}>;

export type UpdateBookingAdminMutation = {
  __typename?: 'Mutation';
  updateBookingAdmin?: {
    __typename?: 'Booking';
    _id: string;
    name?: string | null;
    status?: number | null;
    config?: {
      __typename?: 'BookingConfig';
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null } | null;
    } | null;
  } | null;
};

export type UpdateBookingAdminUserMutationVariables = Exact<{
  booking_id: Scalars['ID']['input'];
  booking: BookingInput;
}>;

export type UpdateBookingAdminUserMutation = {
  __typename?: 'Mutation';
  updateBookingAdmin?: {
    __typename?: 'Booking';
    admin_user?: {
      __typename?: 'User';
      _id: string;
      picture?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateSessionMutationVariables = Exact<{
  booking_id: Scalars['ID']['input'];
  session_id: Scalars['ID']['input'];
  session: BookingInputSession;
}>;

export type UpdateSessionMutation = {
  __typename?: 'Mutation';
  updateSession?: {
    __typename?: 'Booking';
    _id: string;
    name?: string | null;
    status?: number | null;
    config?: {
      __typename?: 'BookingConfig';
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
      slot_min?: number | null;
      slot_max?: number | null;
      status?: number | null;
    } | null> | null;
  } | null;
};

export type ParticipantSubmissionsExportCsvMutationVariables = Exact<{
  _booking_id: Scalars['ID']['input'];
}>;

export type ParticipantSubmissionsExportCsvMutation = {
  __typename?: 'Mutation';
  participantSubmissionsExportCSV?: string | null;
};

export type Admin_ResetSubmissionRatingMutationVariables = Exact<{
  submissionId: Scalars['ObjectID']['input'];
}>;

export type Admin_ResetSubmissionRatingMutation = {
  __typename?: 'Mutation';
  resetParticipantRating?: {
    __typename?: 'Submission';
    _id?: string | null;
    rating?: { __typename?: 'BookingSubmissionRating'; overall?: number | null } | null;
  } | null;
};

export type Admin_SubmissionRateParticipantMutationVariables = Exact<{
  input: RatingParticipantInput;
}>;

export type Admin_SubmissionRateParticipantMutation = {
  __typename?: 'Mutation';
  submissionRateParticipant?: {
    __typename?: 'Submission';
    _id?: string | null;
    rating?: { __typename?: 'BookingSubmissionRating'; overall?: number | null } | null;
  } | null;
};

export type CreateUserSubmissionAnalysisMutationVariables = Exact<{
  _user_id: Scalars['ObjectID']['input'];
  prompt?: InputMaybe<CreateUserSubmissionAnalysisPromptInput>;
}>;

export type CreateUserSubmissionAnalysisMutation = {
  __typename?: 'Mutation';
  createUserSubmissionAnalysis: {
    __typename?: 'UserSubmissionAnalysis';
    _id: any;
    _booking_ids: Array<any>;
    created: number;
    prompt: {
      __typename?: 'UserSubmissionAnalysisPrompt';
      user: string;
      system: string;
      variables?: { __typename?: 'UserSubmissionAnalysisPromptVariables'; submissions?: string | null } | null;
    };
    response: {
      __typename?: 'UserSubmissionAnalysisResponse';
      inconsistency_reasoning: string;
      inconsistency_verdict: number;
    };
    rating?: {
      __typename?: 'UserSubmissionAnalysisRating';
      _admin_user_id: any;
      date: number;
      inconsistency_verdict_accurate: boolean;
      inconsistency_reasoning_accurate: boolean;
      user_was_dishonest?: boolean | null;
      comments?: string | null;
    } | null;
  };
};

export type CreateESignatureTemplateMutationVariables = Exact<{
  template: ESignatureTemplateInput;
}>;

export type CreateESignatureTemplateMutation = {
  __typename?: 'Mutation';
  createESignatureTemplate?: {
    __typename?: 'ESignatureTemplate';
    _id?: string | null;
    global?: boolean | null;
    active?: boolean | null;
    _team_id?: string | null;
    _organisation_id?: string | null;
    name?: string | null;
    description?: string | null;
    recipients?: Array<string | null> | null;
    created?: number | null;
    updated?: number | null;
    provider?: { __typename?: 'ESignatureTemplateProvider'; name?: string | null; reference?: string | null } | null;
  } | null;
};

export type RequestESignatureTemplateDeleteMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type RequestESignatureTemplateDeleteMutation = {
  __typename?: 'Mutation';
  requestESignatureTemplateDelete?: Array<{
    __typename?: 'ESignatureTemplate';
    _id?: string | null;
    global?: boolean | null;
    active?: boolean | null;
    _team_id?: string | null;
    _organisation_id?: string | null;
    name?: string | null;
    description?: string | null;
    recipients?: Array<string | null> | null;
    created?: number | null;
    updated?: number | null;
    provider?: { __typename?: 'ESignatureTemplateProvider'; name?: string | null; reference?: string | null } | null;
  } | null> | null;
};

export type UpdateESignatureTemplateMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  template: ESignatureTemplateInput;
}>;

export type UpdateESignatureTemplateMutation = {
  __typename?: 'Mutation';
  updateESignatureTemplate?: {
    __typename?: 'ESignatureTemplate';
    _id?: string | null;
    global?: boolean | null;
    active?: boolean | null;
    _team_id?: string | null;
    _organisation_id?: string | null;
    name?: string | null;
    description?: string | null;
    recipients?: Array<string | null> | null;
    created?: number | null;
    updated?: number | null;
    provider?: { __typename?: 'ESignatureTemplateProvider'; name?: string | null; reference?: string | null } | null;
  } | null;
};

export type AdminCreateBatchMutationVariables = Exact<{
  batch: BatchInput;
}>;

export type AdminCreateBatchMutation = { __typename?: 'Mutation'; createBatch?: number | null };

export type CreateMessageMutationVariables = Exact<{
  message: MessagesInput;
}>;

export type CreateMessageMutation = {
  __typename?: 'Mutation';
  createMessage?: {
    __typename?: 'Messages';
    _id: string;
    type?: number | null;
    tag?: string | null;
    body?: string | null;
    seen?: number | null;
    created?: number | null;
    _to_user_id?: string | null;
    _from_user_id?: string | null;
    direction?: number | null;
    UserFrom?: {
      __typename?: 'User';
      _id: string;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
      } | null;
    } | null;
    context?: { __typename?: 'MessagesContextType'; _booking_id?: string | null; _user_id?: string | null } | null;
  } | null;
};

export type SyncOrganisationOnHubspotMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type SyncOrganisationOnHubspotMutation = {
  __typename?: 'Mutation';
  syncOrganisationOnHubspot?: { __typename?: 'OrganisationType'; _id: string } | null;
};

export type RemoveOrganisationFromTeamMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type RemoveOrganisationFromTeamMutation = {
  __typename?: 'Mutation';
  removeOrganisationFromTeam?: { __typename?: 'Teams'; _id: string; name?: string | null } | null;
};

export type AddProjectNoteMutationVariables = Exact<{
  input: AdminNotesInput;
  projectId: Scalars['ID']['input'];
}>;

export type AddProjectNoteMutation = {
  __typename?: 'Mutation';
  addAdminProjectNote?: { __typename?: 'Project'; _id: string } | null;
};

export type ForceCompleteBookingMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;

export type ForceCompleteBookingMutation = {
  __typename?: 'Mutation';
  updateProject?: {
    __typename?: 'Project';
    _id: string;
    status?: number | null;
    name?: string | null;
    created?: number | null;
    owner?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    team?: { __typename?: 'Teams'; _id: string; name?: string | null } | null;
    progress?: { __typename?: 'ProjectProgress'; current_status?: ProjectProgressStatus | null } | null;
    users?: Array<{
      __typename?: 'ProjectUser';
      _id: string;
      status?: ProjectUserStatus | null;
      User?: {
        __typename?: 'User';
        type?: { __typename?: 'UserTypeType'; researcher?: boolean | null; client?: boolean | null } | null;
      } | null;
    } | null> | null;
    pricing?: {
      __typename?: 'ProjectPricing';
      booking_credits?: number | null;
      researcher_hours?: number | null;
      total_credits?: number | null;
      total_participants?: number | null;
      detail?: {
        __typename?: 'ProjectPricingDetailType';
        researcher_hours?: Array<string | null> | null;
        booking_credits?: Array<string | null> | null;
      } | null;
    } | null;
    askable_plus?: {
      __typename?: 'ProjectAskablePlusDetails';
      due_date?: number | null;
      description?: string | null;
      objective?: string | null;
      researcher_hourly_rate?: number | null;
      research_type?: {
        __typename?: 'ProjectAskablePlusResearch';
        discovery?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        competitive_analysis?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        survey?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        longitudinal?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        usability?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        continuous_ai_moderated?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          _booking_id?: string | null;
          quota?: number | null;
        } | null;
        continuous_researcher_moderated?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          _booking_id?: string | null;
          quota?: number | null;
        } | null;
      } | null;
      meetings?: {
        __typename?: 'ProjectAskablePlusBriefMeetings';
        briefing?: {
          __typename?: 'ProjectAskablePlusBriefMeetingsDetails';
          meeting_date?: number | null;
          client_confirmed?: boolean | null;
          researcher_confirmed?: boolean | null;
        } | null;
      } | null;
      audience?: { __typename?: 'ProjectAskablePlusAudience'; description?: string | null } | null;
    } | null;
    admin?: {
      __typename?: 'ProjectAdminType';
      mission_critical?: boolean | null;
      mission_critical_reason?: string | null;
    } | null;
  } | null;
};

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
  input: ProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject?: {
    __typename?: 'Project';
    _id: string;
    status?: number | null;
    name?: string | null;
    created?: number | null;
    owner?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    team?: { __typename?: 'Teams'; _id: string; name?: string | null } | null;
    progress?: { __typename?: 'ProjectProgress'; current_status?: ProjectProgressStatus | null } | null;
    users?: Array<{
      __typename?: 'ProjectUser';
      _id: string;
      status?: ProjectUserStatus | null;
      User?: {
        __typename?: 'User';
        type?: { __typename?: 'UserTypeType'; researcher?: boolean | null; client?: boolean | null } | null;
      } | null;
    } | null> | null;
    pricing?: {
      __typename?: 'ProjectPricing';
      booking_credits?: number | null;
      researcher_hours?: number | null;
      total_credits?: number | null;
      total_participants?: number | null;
      detail?: {
        __typename?: 'ProjectPricingDetailType';
        researcher_hours?: Array<string | null> | null;
        booking_credits?: Array<string | null> | null;
      } | null;
    } | null;
    askable_plus?: {
      __typename?: 'ProjectAskablePlusDetails';
      due_date?: number | null;
      description?: string | null;
      objective?: string | null;
      researcher_hourly_rate?: number | null;
      research_type?: {
        __typename?: 'ProjectAskablePlusResearch';
        discovery?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        competitive_analysis?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        survey?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        longitudinal?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        usability?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        continuous_ai_moderated?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          _booking_id?: string | null;
          quota?: number | null;
        } | null;
        continuous_researcher_moderated?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          _booking_id?: string | null;
          quota?: number | null;
        } | null;
      } | null;
      meetings?: {
        __typename?: 'ProjectAskablePlusBriefMeetings';
        briefing?: {
          __typename?: 'ProjectAskablePlusBriefMeetingsDetails';
          meeting_date?: number | null;
          client_confirmed?: boolean | null;
          researcher_confirmed?: boolean | null;
        } | null;
      } | null;
      audience?: { __typename?: 'ProjectAskablePlusAudience'; description?: string | null } | null;
    } | null;
    admin?: {
      __typename?: 'ProjectAdminType';
      mission_critical?: boolean | null;
      mission_critical_reason?: string | null;
    } | null;
  } | null;
};

export type RateUserSubmissionAnalysisMutationVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
  rating: RateUserSubmissionAnalysisInput;
}>;

export type RateUserSubmissionAnalysisMutation = {
  __typename?: 'Mutation';
  rateUserSubmissionAnalysis: {
    __typename?: 'UserSubmissionAnalysis';
    _id: any;
    _booking_ids: Array<any>;
    created: number;
    prompt: {
      __typename?: 'UserSubmissionAnalysisPrompt';
      user: string;
      system: string;
      variables?: { __typename?: 'UserSubmissionAnalysisPromptVariables'; submissions?: string | null } | null;
    };
    response: {
      __typename?: 'UserSubmissionAnalysisResponse';
      inconsistency_reasoning: string;
      inconsistency_verdict: number;
    };
    rating?: {
      __typename?: 'UserSubmissionAnalysisRating';
      _admin_user_id: any;
      date: number;
      inconsistency_verdict_accurate: boolean;
      inconsistency_reasoning_accurate: boolean;
      user_was_dishonest?: boolean | null;
      comments?: string | null;
    } | null;
  };
};

export type AssignOrganisationToTeamMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  _organisation_id: Scalars['ID']['input'];
}>;

export type AssignOrganisationToTeamMutation = {
  __typename?: 'Mutation';
  assignOrganisationToTeam?: {
    __typename?: 'Teams';
    _id: string;
    name?: string | null;
    Organisation?: { __typename?: 'OrganisationType'; _id: string; name?: string | null } | null;
    settings?: {
      __typename?: 'TeamSettingsType';
      billing?: {
        __typename?: 'TeamSettingsBilling';
        subscription?: { __typename?: 'TeamSettingsBillingSubscription'; end?: number | null } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateTeamMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  team: TeamInput;
}>;

export type UpdateTeamMutation = {
  __typename?: 'Mutation';
  updateTeam?: {
    __typename?: 'Teams';
    _id: string;
    name?: string | null;
    Organisation?: { __typename?: 'OrganisationType'; name?: string | null; _id: string } | null;
    settings?: {
      __typename?: 'TeamSettingsType';
      billing?: {
        __typename?: 'TeamSettingsBilling';
        subscription?: { __typename?: 'TeamSettingsBillingSubscription'; end?: number | null } | null;
      } | null;
    } | null;
  } | null;
};

export type AdminLoginAsResearcherMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminLoginAsResearcherMutation = {
  __typename?: 'Mutation';
  adminLoginAsResearcher?: { __typename?: 'LoginAsResearcherType'; url?: string | null } | null;
};

export type Admin_AdminLoginAsClientMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type Admin_AdminLoginAsClientMutation = {
  __typename?: 'Mutation';
  adminLoginAsClient?: {
    __typename?: 'LoginAsClientType';
    url?: string | null;
    user?: {
      __typename?: 'User';
      _id: string;
      access_token?: string | null;
      type?: { __typename?: 'UserTypeType'; client?: boolean | null; participant?: boolean | null } | null;
    } | null;
  } | null;
};

export type Admin_DeleteUserMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type Admin_DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: { __typename?: 'User'; _id: string } | null;
};

export type Admin_UpdateResearcherMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  user: UserInput;
}>;

export type Admin_UpdateResearcherMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    _id: string;
    created?: number | null;
    email?: string | null;
    displayName?: string | null;
    timezone?: string | null;
    blacklist?: boolean | null;
    researcher?: {
      __typename?: 'UserResearcher';
      ux_length?: UserMetaUxLength | null;
      ux_length_as_of?: number | null;
      methods?: Array<ResearcherMethods | null> | null;
      admin_notes?: string | null;
      certification?: {
        __typename?: 'ResearcherCertification';
        status?: ResearcherCertificationStatus | null;
        video_ask_submission_url?: string | null;
      } | null;
      projects?: {
        __typename?: 'ProjectsConnection';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'Project';
          _id: string;
          name?: string | null;
          askable_plus?: { __typename?: 'ProjectAskablePlusDetails'; due_date?: number | null } | null;
        } | null> | null;
      } | null;
    } | null;
    contact?: {
      __typename?: 'UserContactType';
      phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
    } | null;
    meta?: {
      __typename?: 'UserMetaType';
      social?: {
        __typename?: 'UserMetaSocialType';
        linkedin?: { __typename?: 'UserMetaSocialLinkedinType'; profile_url?: string | null } | null;
      } | null;
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
    } | null;
  } | null;
};

export type Admin_UpdateUserMutationVariables = Exact<{
  _id: Scalars['ID']['input'];
  user: UserInput;
}>;

export type Admin_UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: { __typename?: 'User'; _id: string; updated?: number | null } | null;
};

export type Admin_VerifyUserPhoneMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Admin_VerifyUserPhoneMutation = {
  __typename?: 'Mutation';
  verifyUserPhone?: {
    __typename?: 'User';
    _id: string;
    created?: number | null;
    updated?: number | null;
    contact?: {
      __typename?: 'UserContactType';
      phone?: {
        __typename?: 'UserContactPhoneType';
        mobile?: string | null;
        verification?: {
          __typename?: 'UserContactPhoneVerificationType';
          mobile?: string | null;
          fax?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AdminSaveWigMutationVariables = Exact<{
  _id?: InputMaybe<Scalars['ID']['input']>;
  input: AdminWigInput;
}>;

export type AdminSaveWigMutation = {
  __typename?: 'Mutation';
  adminSaveWig?: {
    __typename?: 'AdminWig';
    _id?: string | null;
    period_name?: string | null;
    period_start?: number | null;
    period_end?: number | null;
    period_type?: AdminWigPeriodTypes | null;
    operational_office?: OperationalOffices | null;
    goals?: Array<{
      __typename?: 'AdminWigGoal';
      key?: string | null;
      value?: number | null;
      meta?: { __typename?: 'AdminWigGoalMeta'; name?: string | null; prefix?: string | null } | null;
    } | null> | null;
  } | null;
};

export type Admin_BookingByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Admin_BookingByIdQuery = {
  __typename?: 'Query';
  bookingByID?: {
    __typename?: 'Booking';
    _id: string;
    status?: number | null;
    _owner_id?: Array<string | null> | null;
    _admin_id?: string | null;
    name?: string | null;
    type?: number | null;
    total_participants?: number | null;
    team_booking_index?: number | null;
    _team_id?: string | null;
    history?: { __typename?: 'BookingHistory'; completed_date?: number | null } | null;
    project?: { __typename?: 'Project'; _id: string; name?: string | null; type?: number | null } | null;
    admin?: {
      __typename?: 'BookingAdminType';
      emoji?: string | null;
      mission_critical?: boolean | null;
      mission_critical_reason?: string | null;
      tags?: { __typename?: 'BookingAdminTags'; nufp?: boolean | null } | null;
      comments?: Array<{
        __typename?: 'BookingAdminCommentType';
        _id: string;
        _user_id?: string | null;
        created?: number | null;
        comment?: string | null;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    ParticipantSessionsCount?: {
      __typename?: 'ParticipantSessionsCount';
      completed?: number | null;
      scheduled?: number | null;
      available?: number | null;
      invited?: number | null;
      waiting_list?: number | null;
      in_progress?: number | null;
      total?: number | null;
      total_eligible?: number | null;
      total_refunded?: number | null;
    } | null;
    admin_user?: {
      __typename?: 'User';
      _id: string;
      picture?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    settings?: { __typename?: 'BookingSettings'; translation: boolean } | null;
    CurrencyData?: Array<{
      __typename?: 'CurrencyData';
      currency_code: string;
      currency_symbol: string;
      country_code: string;
    }> | null;
    config?: {
      __typename?: 'BookingConfig';
      min_participant_rating?: number | null;
      credits_per_participant?: number | null;
      participant_category?: number | null;
      timezone?: string | null;
      continuous_discovery?: boolean | null;
      hidden?: boolean | null;
      countries?: Array<any> | null;
      location?: {
        __typename?: 'Location';
        country?: any | null;
        state?: string | null;
        region?: string | null;
        postal_code?: string | null;
        city?: string | null;
        name?: string | null;
        level?: string | null;
        street1?: string | null;
        street2?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      session?: {
        __typename?: 'SessionBookingConfig';
        type?: number | null;
        duration?: number | null;
        time_limit?: number | null;
        slot_min?: number | null;
        slot_max?: number | null;
      } | null;
      incentive?: {
        __typename?: 'IncentiveBookingConfig';
        currency_symbol?: string | null;
        value?: number | null;
      } | null;
      incentives?: Array<{
        __typename?: 'IncentiveBookingConfig';
        currency_code?: string | null;
        currency_symbol?: string | null;
        country_code?: any | null;
        value?: number | null;
      } | null> | null;
      criteria?: {
        __typename?: 'CriteriaBookingConfig';
        locations?: {
          __typename?: 'LocationCriteria';
          countries?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          bounds?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          states?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
        } | null;
        meta_identity_locales?: Array<{
          __typename?: 'CriteriaBookingConfigMetaIdentityLocales';
          locale: Locale;
        } | null> | null;
      } | null;
      contact?: {
        __typename?: 'BookingConfigContact';
        email?: string | null;
        name?: string | null;
        phone?: string | null;
        UserOrganiser?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
        UserFacilitator?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
      longitudinal_study?: {
        __typename?: 'LongitudinalStudyBookingConfig';
        period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
      } | null;
      project?: {
        __typename?: 'ProjectBookingConfig';
        project_type?: number | null;
        allowed_credits?: number | null;
      } | null;
      block_participants?: {
        __typename?: 'BookingConfigBlockParticipants';
        unverified_paypal?: boolean | null;
        giftpay?: boolean | null;
      } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
      status?: number | null;
    } | null> | null;
    user?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      operational_office?: OperationalOffices | null;
      users?: Array<{
        __typename?: 'TeamsUsersType';
        _id: string;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    UserStatusCount?: {
      __typename?: 'UserStatusCount';
      completed?: number | null;
      confirmed?: number | null;
      scheduled?: number | null;
      waitlisted?: number | null;
      invited?: number | null;
      in_progress?: number | null;
      cancel_admin?: number | null;
      cancel_client?: number | null;
      cancel_participant?: number | null;
      cancel_noshow?: number | null;
      cancel_system?: number | null;
      cancel_reschedule?: number | null;
      available_eligible?: number | null;
      available_ineligible?: number | null;
      unavailable_eligible?: number | null;
      unavailable_ineligible?: number | null;
    } | null;
    booking_participant?: Array<{
      __typename?: 'BookingParticipant';
      _id: string;
      _session_id?: string | null;
      _user_id?: string | null;
      cancel?: number | null;
      status?: number | null;
    } | null> | null;
    credit_activity?: Array<{
      __typename?: 'CreditActivity';
      _id: string;
      type?: number | null;
      accounting_type?: number | null;
      amount?: number | null;
      comment?: string | null;
      created?: number | null;
      Transaction?: {
        __typename?: 'Transactions';
        _id: string;
        total_amount?: number | null;
        currency?: string | null;
        comment?: string | null;
        method?: number | null;
        invoice?: {
          __typename?: 'InvoiceTransactionsType';
          amount_due?: number | null;
          purchase_order?: string | null;
          reference?: string | null;
          xero_id?: string | null;
        } | null;
      } | null;
      User?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        type?: { __typename?: 'UserTypeType'; client?: boolean | null; admin?: boolean | null } | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Admin_BookingPageBookingQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type Admin_BookingPageBookingQuery = {
  __typename?: 'Query';
  bookingByID?: {
    __typename?: 'Booking';
    _id: string;
    status?: number | null;
    _owner_id?: Array<string | null> | null;
    _admin_id?: string | null;
    name?: string | null;
    type?: number | null;
    total_participants?: number | null;
    team_booking_index?: number | null;
    _team_id?: string | null;
    admin_user?: {
      __typename?: 'User';
      _id: string;
      picture?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    settings?: { __typename?: 'BookingSettings'; translation: boolean } | null;
    CurrencyData?: Array<{
      __typename?: 'CurrencyData';
      currency_code: string;
      currency_symbol: string;
      country_code: string;
    }> | null;
    admin?: {
      __typename?: 'BookingAdminType';
      emoji?: string | null;
      mission_critical?: boolean | null;
      mission_critical_reason?: string | null;
      comments?: Array<{
        __typename?: 'BookingAdminCommentType';
        _id: string;
        _user_id?: string | null;
        created?: number | null;
        comment?: string | null;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    config?: {
      __typename?: 'BookingConfig';
      min_participant_rating?: number | null;
      credits_per_participant?: number | null;
      participant_category?: number | null;
      timezone?: string | null;
      continuous_discovery?: boolean | null;
      hidden?: boolean | null;
      countries?: Array<any> | null;
      location?: {
        __typename?: 'Location';
        country?: any | null;
        state?: string | null;
        region?: string | null;
        postal_code?: string | null;
        city?: string | null;
        name?: string | null;
        level?: string | null;
        street1?: string | null;
        street2?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      session?: {
        __typename?: 'SessionBookingConfig';
        type?: number | null;
        duration?: number | null;
        time_limit?: number | null;
        slot_min?: number | null;
        slot_max?: number | null;
      } | null;
      incentive?: {
        __typename?: 'IncentiveBookingConfig';
        currency_symbol?: string | null;
        value?: number | null;
      } | null;
      incentives?: Array<{
        __typename?: 'IncentiveBookingConfig';
        currency_code?: string | null;
        currency_symbol?: string | null;
        country_code?: any | null;
        value?: number | null;
      } | null> | null;
      criteria?: {
        __typename?: 'CriteriaBookingConfig';
        locations?: {
          __typename?: 'LocationCriteria';
          countries?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          bounds?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
          states?: Array<{
            __typename?: 'Location';
            name?: string | null;
            country?: any | null;
            currency_code?: string | null;
            currency_symbol?: string | null;
          } | null> | null;
        } | null;
        meta_identity_locales?: Array<{
          __typename?: 'CriteriaBookingConfigMetaIdentityLocales';
          locale: Locale;
        } | null> | null;
      } | null;
      contact?: {
        __typename?: 'BookingConfigContact';
        email?: string | null;
        name?: string | null;
        phone?: string | null;
        UserOrganiser?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
        UserFacilitator?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
          contact?: {
            __typename?: 'UserContactType';
            phone?: {
              __typename?: 'UserContactPhoneType';
              country_code?: string | null;
              mobile?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
      longitudinal_study?: {
        __typename?: 'LongitudinalStudyBookingConfig';
        period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
      } | null;
      project?: {
        __typename?: 'ProjectBookingConfig';
        project_type?: number | null;
        allowed_credits?: number | null;
      } | null;
      block_participants?: {
        __typename?: 'BookingConfigBlockParticipants';
        unverified_paypal?: boolean | null;
        giftpay?: boolean | null;
      } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
      status?: number | null;
    } | null> | null;
    user?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    team?: {
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      operational_office?: OperationalOffices | null;
      users?: Array<{
        __typename?: 'TeamsUsersType';
        _id: string;
        User?: {
          __typename?: 'User';
          _id: string;
          email?: string | null;
          meta?: {
            __typename?: 'UserMetaType';
            identity?: {
              __typename?: 'UserMetaIdentityType';
              firstname?: string | null;
              lastname?: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
    UserStatusCount?: {
      __typename?: 'UserStatusCount';
      completed?: number | null;
      confirmed?: number | null;
      scheduled?: number | null;
      waitlisted?: number | null;
      invited?: number | null;
      in_progress?: number | null;
      cancel_admin?: number | null;
      cancel_client?: number | null;
      cancel_participant?: number | null;
      cancel_noshow?: number | null;
      cancel_system?: number | null;
      cancel_reschedule?: number | null;
      available_eligible?: number | null;
      available_ineligible?: number | null;
      unavailable_eligible?: number | null;
      unavailable_ineligible?: number | null;
    } | null;
    booking_participant?: Array<{
      __typename?: 'BookingParticipant';
      _id: string;
      _session_id?: string | null;
      _user_id?: string | null;
      cancel?: number | null;
      status?: number | null;
    } | null> | null;
    credit_activity?: Array<{
      __typename?: 'CreditActivity';
      _id: string;
      type?: number | null;
      accounting_type?: number | null;
      amount?: number | null;
      comment?: string | null;
      created?: number | null;
      Transaction?: {
        __typename?: 'Transactions';
        _id: string;
        total_amount?: number | null;
        currency?: string | null;
        comment?: string | null;
        method?: number | null;
        invoice?: {
          __typename?: 'InvoiceTransactionsType';
          amount_due?: number | null;
          purchase_order?: string | null;
          reference?: string | null;
          xero_id?: string | null;
        } | null;
      } | null;
      User?: {
        __typename?: 'User';
        _id: string;
        email?: string | null;
        type?: { __typename?: 'UserTypeType'; client?: boolean | null; admin?: boolean | null } | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
    history?: { __typename?: 'BookingHistory'; completed_date?: number | null } | null;
  } | null;
};

export type BookingSubmissionsForBookingIdQueryVariables = Exact<{
  bookingId: Scalars['ID']['input'];
}>;

export type BookingSubmissionsForBookingIdQuery = {
  __typename?: 'Query';
  findAllBookingSubmissions?: Array<{
    __typename?: 'Submission';
    _id?: string | null;
    created?: number | null;
    status?: SubmissionStatus | null;
    user?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      location?: { __typename?: 'Location'; country?: any | null; city?: string | null } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    rating?: { __typename?: 'BookingSubmissionRating'; overall?: number | null } | null;
  } | null> | null;
};

export type Admin_BookingsListCurrentQueryVariables = Exact<{
  status?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;

export type Admin_BookingsListCurrentQuery = {
  __typename?: 'Query';
  bookings?: Array<{
    __typename?: 'Booking';
    _id: string;
    created?: number | null;
    updated?: number | null;
    name?: string | null;
    status?: number | null;
    approved_date?: number | null;
    confirmed_date?: number | null;
    type?: number | null;
    total_participants?: number | null;
    team_booking_index?: number | null;
    _admin_id?: string | null;
    admin_user?: {
      __typename?: 'User';
      _id: string;
      picture?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
      } | null;
    } | null;
    config?: {
      __typename?: 'BookingConfig';
      demo?: boolean | null;
      credits_per_participant?: number | null;
      participant_category?: number | null;
      hidden?: boolean | null;
      location?: {
        __typename?: 'Location';
        country?: any | null;
        state?: string | null;
        region?: string | null;
        city?: string | null;
      } | null;
      in_context?: { __typename?: 'InContextBookingConfig'; location_type?: number | null } | null;
      contact?: {
        __typename?: 'BookingConfigContact';
        name?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      session?: { __typename?: 'SessionBookingConfig'; type?: number | null; duration?: number | null } | null;
      incentives?: Array<{
        __typename?: 'IncentiveBookingConfig';
        currency_code?: string | null;
        currency_symbol?: string | null;
        country_code?: any | null;
        value?: number | null;
      } | null> | null;
      options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
      recruitment?: { __typename?: 'RecruitmentBookingConfig'; byo?: boolean | null } | null;
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
      longitudinal_study?: {
        __typename?: 'LongitudinalStudyBookingConfig';
        period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
      } | null;
      project?: {
        __typename?: 'ProjectBookingConfig';
        project_type?: number | null;
        allowed_credits?: number | null;
      } | null;
      criteria?: {
        __typename?: 'CriteriaBookingConfig';
        locations?: {
          __typename?: 'LocationCriteria';
          bounds?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
          countries?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
          states?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
        } | null;
      } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
    } | null> | null;
    admin?: { __typename?: 'BookingAdminType'; emoji?: string | null; mission_critical?: boolean | null } | null;
    team?: {
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      operational_office?: OperationalOffices | null;
      settings?: {
        __typename?: 'TeamSettingsType';
        ignore_tracking?: boolean | null;
        billing?: {
          __typename?: 'TeamSettingsBilling';
          company_name?: string | null;
          subscription?: {
            __typename?: 'TeamSettingsBillingSubscription';
            credit?: { __typename?: 'TeamSettingsBillingSubscriptionCredit'; remaining?: number | null } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
    } | null;
    credit_activity?: Array<{
      __typename?: 'CreditActivity';
      created?: number | null;
      amount?: number | null;
      accounting_type?: number | null;
      type?: number | null;
      comment?: string | null;
    } | null> | null;
    AutomationScore?: {
      __typename?: 'BookingAutomationScore';
      admin_actions?: number | null;
      score?: number | null;
    } | null;
    UserStatusCount?: {
      __typename?: 'UserStatusCount';
      completed?: number | null;
      confirmed?: number | null;
      scheduled?: number | null;
      waitlisted?: number | null;
      invited?: number | null;
      in_progress?: number | null;
      cancel_admin?: number | null;
      cancel_client?: number | null;
      cancel_participant?: number | null;
      cancel_noshow?: number | null;
      cancel_system?: number | null;
      cancel_reschedule?: number | null;
      available_eligible?: number | null;
      available_ineligible?: number | null;
      unavailable_eligible?: number | null;
      unavailable_ineligible?: number | null;
    } | null;
    ParticipantRatings?: Array<{ __typename?: 'BookingSubmissionRating'; overall?: number | null } | null> | null;
    project?: { __typename?: 'Project'; _id: string; name?: string | null; type?: number | null } | null;
  } | null> | null;
};

export type Admin_GetCouponsQueryVariables = Exact<{ [key: string]: never }>;

export type Admin_GetCouponsQuery = {
  __typename?: 'Query';
  coupons?: Array<{
    __typename?: 'Coupon';
    _id: string;
    code?: string | null;
    redeem_limit?: number | null;
    allow_reuse?: boolean | null;
    discount_type?: number | null;
    discount_value?: number | null;
    redeemed_by?: Array<string | null> | null;
    created?: number | null;
    validity?: { __typename?: 'CouponValidity'; start?: number | null; end?: number | null } | null;
  } | null> | null;
};

export type ESignatureTemplatesByTeamQueryVariables = Exact<{
  _team_id: Scalars['ID']['input'];
}>;

export type ESignatureTemplatesByTeamQuery = {
  __typename?: 'Query';
  eSignatureTemplatesByTeam?: Array<{
    __typename?: 'ESignatureTemplate';
    _id?: string | null;
    global?: boolean | null;
    active?: boolean | null;
    _team_id?: string | null;
    _organisation_id?: string | null;
    name?: string | null;
    description?: string | null;
    recipients?: Array<string | null> | null;
    created?: number | null;
    updated?: number | null;
    AgreementBookings?: Array<{
      __typename?: 'Booking';
      _id: string;
      status?: number | null;
      name?: string | null;
    } | null> | null;
    provider?: { __typename?: 'ESignatureTemplateProvider'; name?: string | null; reference?: string | null } | null;
  } | null> | null;
};

export type GetBookingESignatureTemplatesQueryVariables = Exact<{
  _booking_id: Scalars['ID']['input'];
}>;

export type GetBookingESignatureTemplatesQuery = {
  __typename?: 'Query';
  getBookingESignatureTemplates?: Array<{
    __typename?: 'ESignatureTemplate';
    _id?: string | null;
    global?: boolean | null;
    active?: boolean | null;
    _team_id?: string | null;
    _organisation_id?: string | null;
    name?: string | null;
    description?: string | null;
    recipients?: Array<string | null> | null;
    created?: number | null;
    updated?: number | null;
    provider?: { __typename?: 'ESignatureTemplateProvider'; name?: string | null; reference?: string | null } | null;
  } | null> | null;
};

export type Admin_AdminMessagesQueryVariables = Exact<{
  _user_id: Scalars['ID']['input'];
}>;

export type Admin_AdminMessagesQuery = {
  __typename?: 'Query';
  userAdminMessages?: Array<{
    __typename?: 'Messages';
    _id: string;
    type?: number | null;
    tag?: string | null;
    body?: string | null;
    seen?: number | null;
    created?: number | null;
    _to_user_id?: string | null;
    _from_user_id?: string | null;
    direction?: number | null;
    UserFrom?: {
      __typename?: 'User';
      _id: string;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
      } | null;
    } | null;
    context?: { __typename?: 'MessagesContextType'; _booking_id?: string | null; _user_id?: string | null } | null;
  } | null> | null;
};

export type Admin_CountBatchMatchesQueryVariables = Exact<{
  batch: BatchInput;
}>;

export type Admin_CountBatchMatchesQuery = { __typename?: 'Query'; countBatchMatches?: number | null };

export type Admin_BookingParticipantMessagesQueryVariables = Exact<{
  _booking_id: Scalars['ID']['input'];
  _user_id: Scalars['ID']['input'];
}>;

export type Admin_BookingParticipantMessagesQuery = {
  __typename?: 'Query';
  bookingParticipantMessages?: {
    __typename?: 'Booking';
    _id: string;
    type?: number | null;
    config?: {
      __typename?: 'BookingConfig';
      incentive?: {
        __typename?: 'IncentiveBookingConfig';
        currency_symbol?: string | null;
        value?: number | null;
      } | null;
      location?: { __typename?: 'Location'; country?: any | null; city?: string | null; region?: string | null } | null;
      in_context?: { __typename?: 'InContextBookingConfig'; location_type?: number | null } | null;
    } | null;
    messages?: Array<{
      __typename?: 'Messages';
      _id: string;
      type?: number | null;
      tag?: string | null;
      body?: string | null;
      seen?: number | null;
      created?: number | null;
      _to_user_id?: string | null;
      _from_user_id?: string | null;
      direction?: number | null;
      UserFrom?: {
        __typename?: 'User';
        _id: string;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
        } | null;
      } | null;
      context?: { __typename?: 'MessagesContextType'; _booking_id?: string | null; _user_id?: string | null } | null;
    } | null> | null;
    booking_participant?: Array<{
      __typename?: 'BookingParticipant';
      _id: string;
      status?: number | null;
      cancel?: number | null;
      session?: { __typename?: 'BookingSession'; _id?: string | null; start?: number | null } | null;
      history?: {
        __typename?: 'BookingParticipantHistory';
        early_confirmation_request?: number | null;
        last_quantitative_application_time?: number | null;
        session_click_confirm_button?: number | null;
        session_completed?: number | null;
        session_completed_feedback_request?: number | null;
        session_completed_feedback_submitted?: number | null;
        session_confirmed_cancellation?: number | null;
        session_invitation_accepted?: number | null;
        session_invitation_declined?: number | null;
        session_invitation_notification?: number | null;
        session_invitation_notification_followup?: number | null;
        session_opportunity_application?: number | null;
        session_paid?: number | null;
        session_participant_cancelled_by_askable?: number | null;
        session_participant_cancelled_by_client?: number | null;
        session_quant_completed?: number | null;
        session_quant_invitation_notification?: number | null;
        session_reinvite_notification?: number | null;
        session_start_reminder_2_askable_incentive_message?: number | null;
        session_start_reminder_action_needed?: number | null;
        session_start_reminder_action_needed_followup?: number | null;
        session_start_reminder_confirmation_pending_askable_notification?: number | null;
        session_start_reminder_last_askable_warning?: number | null;
        session_start_reminder_no_action?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type Admin_MessagesSeenQueryVariables = Exact<{
  _messages_id: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type Admin_MessagesSeenQuery = {
  __typename?: 'Query';
  messagesSeen?: Array<{ __typename?: 'Messages'; _id: string; seen?: number | null } | null> | null;
};

export type Admin_GetIndustryDataQueryVariables = Exact<{ [key: string]: never }>;

export type Admin_GetIndustryDataQuery = {
  __typename?: 'Query';
  industryList?: Array<{
    __typename?: 'IndustryType';
    _id?: string | null;
    name?: string | null;
    subcategories?: Array<{ __typename?: 'IndustryType'; _id?: string | null; name?: string | null } | null> | null;
  } | null> | null;
};

export type OrganisationByIdQueryVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type OrganisationByIdQuery = {
  __typename?: 'Query';
  organisationById?: {
    __typename?: 'OrganisationType';
    _id: string;
    name?: string | null;
    created?: number | null;
    admin?: {
      __typename?: 'OrganisationAdmin';
      Owner?: {
        __typename?: 'User';
        _id: string;
        picture?: string | null;
        email?: string | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    Teams?: Array<{
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      created?: number | null;
      updated?: number | null;
      operational_office?: OperationalOffices | null;
      settings?: {
        __typename?: 'TeamSettingsType';
        ignore_tracking?: boolean | null;
        billing?: {
          __typename?: 'TeamSettingsBilling';
          company_name?: string | null;
          subscription?: {
            __typename?: 'TeamSettingsBillingSubscription';
            end?: number | null;
            credit?: { __typename?: 'TeamSettingsBillingSubscriptionCredit'; remaining?: number | null } | null;
          } | null;
        } | null;
      } | null;
      Organisation?: { __typename?: 'OrganisationType'; _id: string; name?: string | null } | null;
      ParentTeam?: {
        __typename?: 'Teams';
        _id: string;
        name?: string | null;
        settings?: { __typename?: 'TeamSettingsType'; ignore_tracking?: boolean | null } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type OrganisationsQueryVariables = Exact<{ [key: string]: never }>;

export type OrganisationsQuery = {
  __typename?: 'Query';
  organisations?: Array<{
    __typename?: 'OrganisationType';
    _id: string;
    name?: string | null;
    created?: number | null;
    admin?: {
      __typename?: 'OrganisationAdmin';
      Owner?: {
        __typename?: 'User';
        _id: string;
        picture?: string | null;
        email?: string | null;
        meta?: {
          __typename?: 'UserMetaType';
          identity?: {
            __typename?: 'UserMetaIdentityType';
            firstname?: string | null;
            lastname?: string | null;
          } | null;
        } | null;
      } | null;
    } | null;
    Teams?: Array<{ __typename?: 'Teams'; _id: string; name?: string | null } | null> | null;
    settings?: { __typename?: 'OrganisationSettingsType'; exclude_participants_from_teams?: boolean | null } | null;
    integrations?: { __typename?: 'OrganisationIntegrationsType'; hubspot_company_id?: string | null } | null;
  } | null> | null;
};

export type AskablePlusProjectsListQueryVariables = Exact<{ [key: string]: never }>;

export type AskablePlusProjectsListQuery = {
  __typename?: 'Query';
  projectsConnection?: {
    __typename?: 'ProjectsConnection';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'Project';
      _id: string;
      status?: number | null;
      type?: number | null;
      name?: string | null;
      created?: number | null;
      progress?: { __typename?: 'ProjectProgress'; current_status?: ProjectProgressStatus | null } | null;
      askable_plus?: { __typename?: 'ProjectAskablePlusDetails'; due_date?: number | null } | null;
      team?: {
        __typename?: 'Teams';
        _id: string;
        name?: string | null;
        operational_office?: OperationalOffices | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AskablePlusProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type AskablePlusProjectsQuery = {
  __typename?: 'Query';
  projectsConnection?: {
    __typename?: 'ProjectsConnection';
    nodes?: Array<{
      __typename?: 'Project';
      _id: string;
      status?: number | null;
      name?: string | null;
      created?: number | null;
      team?: {
        __typename?: 'Teams';
        _id: string;
        name?: string | null;
        operational_office?: OperationalOffices | null;
      } | null;
      progress?: {
        __typename?: 'ProjectProgress';
        current_status?: ProjectProgressStatus | null;
        in_review?: {
          __typename?: 'ProjectProgressGroup';
          steps?: Array<{
            __typename?: 'ProjectProgressItem';
            key?: string | null;
            status?: ProjectProgressItemStatus | null;
          } | null> | null;
        } | null;
        research?: {
          __typename?: 'ProjectProgressGroup';
          steps?: Array<{
            __typename?: 'ProjectProgressItem';
            key?: string | null;
            status?: ProjectProgressItemStatus | null;
          } | null> | null;
        } | null;
        delivery?: {
          __typename?: 'ProjectProgressGroup';
          steps?: Array<{
            __typename?: 'ProjectProgressItem';
            key?: string | null;
            status?: ProjectProgressItemStatus | null;
          } | null> | null;
        } | null;
        kick_off?: {
          __typename?: 'ProjectProgressGroup';
          steps?: Array<{
            __typename?: 'ProjectProgressItem';
            key?: string | null;
            status?: ProjectProgressItemStatus | null;
          } | null> | null;
        } | null;
      } | null;
      bookings?: Array<{ __typename?: 'Booking'; _id: string; status?: number | null } | null> | null;
      users?: Array<{
        __typename?: 'ProjectUser';
        User?: {
          __typename?: 'User';
          type?: { __typename?: 'UserTypeType'; researcher?: boolean | null } | null;
        } | null;
      } | null> | null;
      askable_plus?: {
        __typename?: 'ProjectAskablePlusDetails';
        objective?: string | null;
        description?: string | null;
        due_date?: number | null;
        research_type?: {
          __typename?: 'ProjectAskablePlusResearch';
          discovery?: {
            __typename?: 'ProjectAskablePlusResearchConfig';
            _booking_id?: string | null;
            quota?: number | null;
          } | null;
          usability?: {
            __typename?: 'ProjectAskablePlusResearchConfig';
            _booking_id?: string | null;
            quota?: number | null;
          } | null;
          competitive_analysis?: {
            __typename?: 'ProjectAskablePlusResearchConfig';
            _booking_id?: string | null;
            quota?: number | null;
          } | null;
          longitudinal?: {
            __typename?: 'ProjectAskablePlusResearchConfig';
            _booking_id?: string | null;
            quota?: number | null;
          } | null;
          survey?: {
            __typename?: 'ProjectAskablePlusResearchConfig';
            _booking_id?: string | null;
            quota?: number | null;
          } | null;
          continuous_ai_moderated?: {
            __typename?: 'ProjectAskablePlusResearchConfig';
            _booking_id?: string | null;
            quota?: number | null;
          } | null;
          continuous_researcher_moderated?: {
            __typename?: 'ProjectAskablePlusResearchConfig';
            _booking_id?: string | null;
            quota?: number | null;
          } | null;
        } | null;
      } | null;
      admin?: { __typename?: 'ProjectAdminType'; mission_critical?: boolean | null } | null;
    } | null> | null;
  } | null;
};

export type ProjectFieldsFragment = {
  __typename?: 'Project';
  _id: string;
  status?: number | null;
  name?: string | null;
  created?: number | null;
  owner?: {
    __typename?: 'User';
    _id: string;
    email?: string | null;
    contact?: {
      __typename?: 'UserContactType';
      phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
    } | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
    } | null;
  } | null;
  team?: { __typename?: 'Teams'; _id: string; name?: string | null } | null;
  progress?: { __typename?: 'ProjectProgress'; current_status?: ProjectProgressStatus | null } | null;
  users?: Array<{
    __typename?: 'ProjectUser';
    _id: string;
    status?: ProjectUserStatus | null;
    User?: {
      __typename?: 'User';
      type?: { __typename?: 'UserTypeType'; researcher?: boolean | null; client?: boolean | null } | null;
    } | null;
  } | null> | null;
  pricing?: {
    __typename?: 'ProjectPricing';
    booking_credits?: number | null;
    researcher_hours?: number | null;
    total_credits?: number | null;
    total_participants?: number | null;
    detail?: {
      __typename?: 'ProjectPricingDetailType';
      researcher_hours?: Array<string | null> | null;
      booking_credits?: Array<string | null> | null;
    } | null;
  } | null;
  askable_plus?: {
    __typename?: 'ProjectAskablePlusDetails';
    due_date?: number | null;
    description?: string | null;
    objective?: string | null;
    researcher_hourly_rate?: number | null;
    research_type?: {
      __typename?: 'ProjectAskablePlusResearch';
      discovery?: {
        __typename?: 'ProjectAskablePlusResearchConfig';
        quota?: number | null;
        _booking_id?: string | null;
      } | null;
      competitive_analysis?: {
        __typename?: 'ProjectAskablePlusResearchConfig';
        quota?: number | null;
        _booking_id?: string | null;
      } | null;
      survey?: {
        __typename?: 'ProjectAskablePlusResearchConfig';
        quota?: number | null;
        _booking_id?: string | null;
      } | null;
      longitudinal?: {
        __typename?: 'ProjectAskablePlusResearchConfig';
        quota?: number | null;
        _booking_id?: string | null;
      } | null;
      usability?: {
        __typename?: 'ProjectAskablePlusResearchConfig';
        quota?: number | null;
        _booking_id?: string | null;
      } | null;
      continuous_ai_moderated?: {
        __typename?: 'ProjectAskablePlusResearchConfig';
        _booking_id?: string | null;
        quota?: number | null;
      } | null;
      continuous_researcher_moderated?: {
        __typename?: 'ProjectAskablePlusResearchConfig';
        _booking_id?: string | null;
        quota?: number | null;
      } | null;
    } | null;
    meetings?: {
      __typename?: 'ProjectAskablePlusBriefMeetings';
      briefing?: {
        __typename?: 'ProjectAskablePlusBriefMeetingsDetails';
        meeting_date?: number | null;
        client_confirmed?: boolean | null;
        researcher_confirmed?: boolean | null;
      } | null;
    } | null;
    audience?: { __typename?: 'ProjectAskablePlusAudience'; description?: string | null } | null;
  } | null;
  admin?: {
    __typename?: 'ProjectAdminType';
    mission_critical?: boolean | null;
    mission_critical_reason?: string | null;
  } | null;
};

export type ProjectByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectByIdQuery = {
  __typename?: 'Query';
  projectByID?: {
    __typename?: 'Project';
    _id: string;
    status?: number | null;
    name?: string | null;
    created?: number | null;
    owner?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null;
    team?: { __typename?: 'Teams'; _id: string; name?: string | null } | null;
    progress?: { __typename?: 'ProjectProgress'; current_status?: ProjectProgressStatus | null } | null;
    users?: Array<{
      __typename?: 'ProjectUser';
      _id: string;
      status?: ProjectUserStatus | null;
      User?: {
        __typename?: 'User';
        type?: { __typename?: 'UserTypeType'; researcher?: boolean | null; client?: boolean | null } | null;
      } | null;
    } | null> | null;
    pricing?: {
      __typename?: 'ProjectPricing';
      booking_credits?: number | null;
      researcher_hours?: number | null;
      total_credits?: number | null;
      total_participants?: number | null;
      detail?: {
        __typename?: 'ProjectPricingDetailType';
        researcher_hours?: Array<string | null> | null;
        booking_credits?: Array<string | null> | null;
      } | null;
    } | null;
    askable_plus?: {
      __typename?: 'ProjectAskablePlusDetails';
      due_date?: number | null;
      description?: string | null;
      objective?: string | null;
      researcher_hourly_rate?: number | null;
      research_type?: {
        __typename?: 'ProjectAskablePlusResearch';
        discovery?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        competitive_analysis?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        survey?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        longitudinal?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        usability?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          quota?: number | null;
          _booking_id?: string | null;
        } | null;
        continuous_ai_moderated?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          _booking_id?: string | null;
          quota?: number | null;
        } | null;
        continuous_researcher_moderated?: {
          __typename?: 'ProjectAskablePlusResearchConfig';
          _booking_id?: string | null;
          quota?: number | null;
        } | null;
      } | null;
      meetings?: {
        __typename?: 'ProjectAskablePlusBriefMeetings';
        briefing?: {
          __typename?: 'ProjectAskablePlusBriefMeetingsDetails';
          meeting_date?: number | null;
          client_confirmed?: boolean | null;
          researcher_confirmed?: boolean | null;
        } | null;
      } | null;
      audience?: { __typename?: 'ProjectAskablePlusAudience'; description?: string | null } | null;
    } | null;
    admin?: {
      __typename?: 'ProjectAdminType';
      mission_critical?: boolean | null;
      mission_critical_reason?: string | null;
    } | null;
  } | null;
};

export type ProjectNotesFieldsFragment = {
  __typename?: 'Project';
  admin?: {
    __typename?: 'ProjectAdminType';
    notes?: Array<{
      __typename?: 'AdminNotesType';
      _id: string;
      note?: string | null;
      created?: number | null;
      user?: { __typename?: 'User'; displayName?: string | null } | null;
    } | null> | null;
  } | null;
};

export type AdminProjectNotesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminProjectNotesQuery = {
  __typename?: 'Query';
  projectByID?: {
    __typename?: 'Project';
    admin?: {
      __typename?: 'ProjectAdminType';
      notes?: Array<{
        __typename?: 'AdminNotesType';
        _id: string;
        note?: string | null;
        created?: number | null;
        user?: { __typename?: 'User'; displayName?: string | null } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ProjectsForUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProjectsForUserQuery = {
  __typename?: 'Query';
  userByID?: {
    __typename?: 'User';
    researcher?: {
      __typename?: 'UserResearcher';
      certification?: { __typename?: 'ResearcherCertification'; status?: ResearcherCertificationStatus | null } | null;
      projects?: {
        __typename?: 'ProjectsConnection';
        nodes?: Array<{
          __typename?: 'Project';
          _id: string;
          created?: number | null;
          name?: string | null;
          askable_plus?: { __typename?: 'ProjectAskablePlusDetails'; due_date?: number | null } | null;
          progress?: { __typename?: 'ProjectProgress'; current_status?: ProjectProgressStatus | null } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type Admin_SearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;

export type Admin_SearchQuery = {
  __typename?: 'Query';
  search?: Array<{
    __typename?: 'SearchResultItem';
    type?: SearchResultItemType | null;
    _id?: string | null;
    labels?: Array<string | null> | null;
  } | null> | null;
};

export type TeamByIdQueryVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type TeamByIdQuery = {
  __typename?: 'Query';
  teamById?: {
    __typename?: 'Teams';
    _id: string;
    name?: string | null;
    created?: number | null;
    updated?: number | null;
    operational_office?: OperationalOffices | null;
    settings?: {
      __typename?: 'TeamSettingsType';
      ignore_tracking?: boolean | null;
      billing?: {
        __typename?: 'TeamSettingsBilling';
        company_name?: string | null;
        invoice_due_days?: number | null;
        subscription?: {
          __typename?: 'TeamSettingsBillingSubscription';
          end?: number | null;
          credit?: { __typename?: 'TeamSettingsBillingSubscriptionCredit'; remaining?: number | null } | null;
        } | null;
      } | null;
    } | null;
    Organisation?: { __typename?: 'OrganisationType'; _id: string; name?: string | null } | null;
  } | null;
};

export type TeamInvoiceHistoryQueryVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type TeamInvoiceHistoryQuery = {
  __typename?: 'Query';
  teamInvoiceHistory?: Array<{
    __typename?: 'Transactions';
    _id: string;
    status?: number | null;
    total_amount?: number | null;
    invoice?: { __typename?: 'InvoiceTransactionsType'; amount_due?: number | null } | null;
  } | null> | null;
};

export type TeamsQueryVariables = Exact<{
  search?: InputMaybe<TeamsSearchInput>;
}>;

export type TeamsQuery = {
  __typename?: 'Query';
  teams?: Array<{
    __typename?: 'Teams';
    _id: string;
    name?: string | null;
    created?: number | null;
    updated?: number | null;
    operational_office?: OperationalOffices | null;
    settings?: {
      __typename?: 'TeamSettingsType';
      ignore_tracking?: boolean | null;
      billing?: {
        __typename?: 'TeamSettingsBilling';
        company_name?: string | null;
        subscription?: {
          __typename?: 'TeamSettingsBillingSubscription';
          end?: number | null;
          credit?: { __typename?: 'TeamSettingsBillingSubscriptionCredit'; remaining?: number | null } | null;
        } | null;
      } | null;
    } | null;
    Organisation?: { __typename?: 'OrganisationType'; _id: string; name?: string | null } | null;
    ParentTeam?: {
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      settings?: { __typename?: 'TeamSettingsType'; ignore_tracking?: boolean | null } | null;
    } | null;
  } | null> | null;
};

export type ClientsConnectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ClientsConnectionQuery = {
  __typename?: 'Query';
  userConnection?: {
    __typename?: 'UserConnection';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'User';
      _id: string;
      email?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
      } | null;
    } | null> | null;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  } | null;
};

export type AdminUsersQueryVariables = Exact<{
  customer_success?: InputMaybe<Scalars['Boolean']['input']>;
  growth?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminUsersQuery = {
  __typename?: 'Query';
  adminUsers?: Array<{
    __typename?: 'User';
    _id: string;
    picture?: string | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
    } | null;
    admin?: { __typename?: 'UserAdminType'; operational_office?: OperationalOffices | null } | null;
  } | null> | null;
};

export type Admin_GetParticipantDetailsQueryVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type Admin_GetParticipantDetailsQuery = {
  __typename?: 'Query';
  userByID?: {
    __typename?: 'User';
    _id: string;
    email?: string | null;
    status?: number | null;
    blacklist?: boolean | null;
    created?: number | null;
    updated?: number | null;
    LastLogin?: number | null;
    contact?: {
      __typename?: 'UserContactType';
      phone?: {
        __typename?: 'UserContactPhoneType';
        mobile?: string | null;
        verification?: { __typename?: 'UserContactPhoneVerificationType'; mobile?: string | null } | null;
      } | null;
    } | null;
    location?: {
      __typename?: 'Location';
      city?: string | null;
      state?: string | null;
      postal_code?: string | null;
      country?: any | null;
      longitude?: number | null;
      latitude?: number | null;
      timezone?: string | null;
    } | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: {
        __typename?: 'UserMetaIdentityType';
        firstname?: string | null;
        lastname?: string | null;
        gender?: string | null;
        birthday?: { __typename?: 'UserMetaIdentityBirthdayType'; timestamp?: number | null } | null;
        languages?: {
          __typename?: 'UserMetaIdentityLanguageType';
          english?: {
            __typename?: 'UserMetaIdentityLanguageLanguageType';
            speak?: {
              __typename?: 'UserMetaIdentityLanguageProficiencyType';
              native?: boolean | null;
              fluent?: boolean | null;
              conversational?: boolean | null;
              beginner?: boolean | null;
            } | null;
          } | null;
        } | null;
      } | null;
      family?: {
        __typename?: 'UserMetaFamilyType';
        status?: {
          __typename?: 'UserMetaFamilyStatusType';
          divorced?: boolean | null;
          married?: boolean | null;
          relationship?: boolean | null;
          single?: boolean | null;
          widowed?: boolean | null;
        } | null;
      } | null;
      education?: {
        __typename?: 'UserMetaEducationType';
        apprenticeship?: boolean | null;
        highschool?: boolean | null;
        postgraduate?: boolean | null;
        some_university?: boolean | null;
        undergraduate?: boolean | null;
      } | null;
      work?: {
        __typename?: 'UserMetaWorkType';
        _industry_id?: string | null;
        _subindustry_id?: string | null;
        employment_type?: number | null;
        status?: {
          __typename?: 'UserMetaWorkStatusType';
          fulltime?: boolean | null;
          fulltime_student?: boolean | null;
          homeduties?: boolean | null;
          parttime?: boolean | null;
          parttime_student?: boolean | null;
          retired?: boolean | null;
          unemployed?: boolean | null;
        } | null;
        business?: { __typename?: 'UserMetaWorkBusinessType'; size?: number | null; turnover?: number | null } | null;
      } | null;
      social?: {
        __typename?: 'UserMetaSocialType';
        linkedin?: { __typename?: 'UserMetaSocialLinkedinType'; profile_url?: string | null } | null;
      } | null;
      feedback_sample?: { __typename?: 'UserMetaFeedbackSampleType'; recording_url?: string | null } | null;
    } | null;
    settings?: {
      __typename?: 'UserSettingsType';
      billing?: {
        __typename?: 'UserSettingsBillingType';
        preferred?: string | null;
        paypal?: string | null;
        giftpay?: string | null;
        paypal_details?: {
          __typename?: 'PayPalDetails';
          verification_details?: { __typename?: 'VerificationDetails'; verified_account?: boolean | null } | null;
        } | null;
      } | null;
      remote?: {
        __typename?: 'UserSettingsRemoteType';
        setup_check?: number | null;
        network_quality?: number | null;
      } | null;
    } | null;
    ParticipantIncentives?: Array<{ __typename?: 'Transactions'; total_amount?: number | null } | null> | null;
    rating?: { __typename?: 'ParticipantRating'; ratings?: number | null; score?: number | null } | null;
    devices?: Array<{
      __typename?: 'ViewerDeviceDetails';
      id: string;
      os: Os;
      app_version: string;
      last_login: number;
      token?: string | null;
    } | null> | null;
  } | null;
};

export type ResearcherFragmentFragment = {
  __typename?: 'User';
  _id: string;
  created?: number | null;
  email?: string | null;
  displayName?: string | null;
  timezone?: string | null;
  blacklist?: boolean | null;
  researcher?: {
    __typename?: 'UserResearcher';
    ux_length?: UserMetaUxLength | null;
    ux_length_as_of?: number | null;
    methods?: Array<ResearcherMethods | null> | null;
    admin_notes?: string | null;
    certification?: {
      __typename?: 'ResearcherCertification';
      status?: ResearcherCertificationStatus | null;
      video_ask_submission_url?: string | null;
    } | null;
    projects?: {
      __typename?: 'ProjectsConnection';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'Project';
        _id: string;
        name?: string | null;
        askable_plus?: { __typename?: 'ProjectAskablePlusDetails'; due_date?: number | null } | null;
      } | null> | null;
    } | null;
  } | null;
  contact?: {
    __typename?: 'UserContactType';
    phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
  } | null;
  meta?: {
    __typename?: 'UserMetaType';
    social?: {
      __typename?: 'UserMetaSocialType';
      linkedin?: { __typename?: 'UserMetaSocialLinkedinType'; profile_url?: string | null } | null;
    } | null;
    identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
  } | null;
};

export type GetResearcherQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetResearcherQuery = {
  __typename?: 'Query';
  userByID?: {
    __typename?: 'User';
    _id: string;
    created?: number | null;
    email?: string | null;
    displayName?: string | null;
    timezone?: string | null;
    blacklist?: boolean | null;
    researcher?: {
      __typename?: 'UserResearcher';
      ux_length?: UserMetaUxLength | null;
      ux_length_as_of?: number | null;
      methods?: Array<ResearcherMethods | null> | null;
      admin_notes?: string | null;
      certification?: {
        __typename?: 'ResearcherCertification';
        status?: ResearcherCertificationStatus | null;
        video_ask_submission_url?: string | null;
      } | null;
      projects?: {
        __typename?: 'ProjectsConnection';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'Project';
          _id: string;
          name?: string | null;
          askable_plus?: { __typename?: 'ProjectAskablePlusDetails'; due_date?: number | null } | null;
        } | null> | null;
      } | null;
    } | null;
    contact?: {
      __typename?: 'UserContactType';
      phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
    } | null;
    meta?: {
      __typename?: 'UserMetaType';
      social?: {
        __typename?: 'UserMetaSocialType';
        linkedin?: { __typename?: 'UserMetaSocialLinkedinType'; profile_url?: string | null } | null;
      } | null;
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
    } | null;
  } | null;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'Query';
  userByID?: {
    __typename?: 'User';
    type?: {
      __typename?: 'UserTypeType';
      researcher?: boolean | null;
      participant?: boolean | null;
      client?: boolean | null;
    } | null;
  } | null;
};

export type GetViewerQueryVariables = Exact<{ [key: string]: never }>;

export type GetViewerQuery = {
  __typename?: 'Query';
  userMe?: {
    __typename?: 'User';
    _id: string;
    email?: string | null;
    picture?: string | null;
    meta?: {
      __typename?: 'UserMetaType';
      identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
    } | null;
  } | null;
};

export type ParticipantsConnectionQueryVariables = Exact<{ [key: string]: never }>;

export type ParticipantsConnectionQuery = {
  __typename?: 'Query';
  users: {
    __typename?: 'UserConnection';
    nodes?: Array<{
      __typename?: 'User';
      email?: string | null;
      _id: string;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null> | null;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  };
};

export type ResearcherConnectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ResearcherConnectionQuery = {
  __typename?: 'Query';
  userConnection?: {
    __typename?: 'UserConnection';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'User';
      email?: string | null;
      created?: number | null;
      blacklist?: boolean | null;
      _id: string;
      researcher?: {
        __typename?: 'UserResearcher';
        ux_length_as_of?: number | null;
        ux_length?: UserMetaUxLength | null;
        admin_notes?: string | null;
        methods?: Array<ResearcherMethods | null> | null;
        certification?: {
          __typename?: 'ResearcherCertification';
          status?: ResearcherCertificationStatus | null;
          video_ask_submission_url?: string | null;
        } | null;
        projects?: {
          __typename?: 'ProjectsConnection';
          totalCount: number;
          nodes?: Array<{
            __typename?: 'Project';
            _id: string;
            askable_plus?: { __typename?: 'ProjectAskablePlusDetails'; due_date?: number | null } | null;
          } | null> | null;
        } | null;
      } | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
      meta?: {
        __typename?: 'UserMetaType';
        social?: {
          __typename?: 'UserMetaSocialType';
          linkedin?: { __typename?: 'UserMetaSocialLinkedinType'; profile_url?: string | null } | null;
        } | null;
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
    } | null> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type UserSubmissionLatestAnalysisQueryVariables = Exact<{
  _user_id: Scalars['ObjectID']['input'];
}>;

export type UserSubmissionLatestAnalysisQuery = {
  __typename?: 'Query';
  userSubmissionLatestAnalysis?: {
    __typename?: 'UserSubmissionAnalysis';
    _id: any;
    _booking_ids: Array<any>;
    created: number;
    prompt: {
      __typename?: 'UserSubmissionAnalysisPrompt';
      user: string;
      system: string;
      variables?: { __typename?: 'UserSubmissionAnalysisPromptVariables'; submissions?: string | null } | null;
    };
    response: {
      __typename?: 'UserSubmissionAnalysisResponse';
      inconsistency_reasoning: string;
      inconsistency_verdict: number;
    };
    rating?: {
      __typename?: 'UserSubmissionAnalysisRating';
      _admin_user_id: any;
      date: number;
      inconsistency_verdict_accurate: boolean;
      inconsistency_reasoning_accurate: boolean;
      user_was_dishonest?: boolean | null;
      comments?: string | null;
    } | null;
  } | null;
};

export type AdminWigByIdQueryVariables = Exact<{
  _id: Scalars['ID']['input'];
}>;

export type AdminWigByIdQuery = {
  __typename?: 'Query';
  adminWigById?: {
    __typename?: 'AdminWig';
    _id?: string | null;
    period_name?: string | null;
    period_start?: number | null;
    period_end?: number | null;
    period_type?: AdminWigPeriodTypes | null;
    operational_office?: OperationalOffices | null;
    goals?: Array<{
      __typename?: 'AdminWigGoal';
      key?: string | null;
      value?: number | null;
      meta?: { __typename?: 'AdminWigGoalMeta'; name?: string | null; prefix?: string | null } | null;
    } | null> | null;
  } | null;
};

export type AdminWigsQueryVariables = Exact<{
  search?: InputMaybe<AdminWigInput>;
}>;

export type AdminWigsQuery = {
  __typename?: 'Query';
  adminWigs?: Array<{
    __typename?: 'AdminWig';
    _id?: string | null;
    period_name?: string | null;
    period_start?: number | null;
    period_end?: number | null;
    period_type?: AdminWigPeriodTypes | null;
    operational_office?: OperationalOffices | null;
    goals?: Array<{
      __typename?: 'AdminWigGoal';
      key?: string | null;
      value?: number | null;
      meta?: { __typename?: 'AdminWigGoalMeta'; name?: string | null; prefix?: string | null } | null;
    } | null> | null;
  } | null> | null;
};

export type WigMetaQueryVariables = Exact<{ [key: string]: never }>;

export type WigMetaQuery = {
  __typename?: 'Query';
  settingsByKey?: { __typename?: 'Settings'; _id: string; key?: SettingsKey | null; value_raw?: any | null } | null;
};

export type Admin_BookingsListCurrentSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type Admin_BookingsListCurrentSubscriptionSubscription = {
  __typename?: 'Subscription';
  bookings?: {
    __typename?: 'Booking';
    _id: string;
    created?: number | null;
    updated?: number | null;
    name?: string | null;
    status?: number | null;
    approved_date?: number | null;
    confirmed_date?: number | null;
    type?: number | null;
    total_participants?: number | null;
    team_booking_index?: number | null;
    _admin_id?: string | null;
    admin_user?: {
      __typename?: 'User';
      _id: string;
      picture?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
      } | null;
    } | null;
    config?: {
      __typename?: 'BookingConfig';
      demo?: boolean | null;
      credits_per_participant?: number | null;
      participant_category?: number | null;
      hidden?: boolean | null;
      location?: {
        __typename?: 'Location';
        country?: any | null;
        state?: string | null;
        region?: string | null;
        city?: string | null;
      } | null;
      in_context?: { __typename?: 'InContextBookingConfig'; location_type?: number | null } | null;
      contact?: {
        __typename?: 'BookingConfigContact';
        name?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      session?: { __typename?: 'SessionBookingConfig'; type?: number | null; duration?: number | null } | null;
      incentives?: Array<{
        __typename?: 'IncentiveBookingConfig';
        currency_code?: string | null;
        currency_symbol?: string | null;
        country_code?: any | null;
        value?: number | null;
      } | null> | null;
      options?: { __typename?: 'OptionsBookingConfig'; review_submission?: boolean | null } | null;
      recruitment?: { __typename?: 'RecruitmentBookingConfig'; byo?: boolean | null } | null;
      online_task?: { __typename?: 'OnlineTaskBookingConfig'; type?: number | null; tool?: string | null } | null;
      longitudinal_study?: {
        __typename?: 'LongitudinalStudyBookingConfig';
        period?: { __typename?: 'LongitudinalPeriodConfig'; time?: number | null; frequency?: number | null } | null;
      } | null;
      project?: {
        __typename?: 'ProjectBookingConfig';
        project_type?: number | null;
        allowed_credits?: number | null;
      } | null;
      criteria?: {
        __typename?: 'CriteriaBookingConfig';
        locations?: {
          __typename?: 'LocationCriteria';
          bounds?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
          countries?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
          states?: Array<{ __typename?: 'Location'; country?: any | null } | null> | null;
        } | null;
      } | null;
    } | null;
    session?: Array<{
      __typename?: 'BookingSession';
      _id?: string | null;
      start?: number | null;
      end?: number | null;
    } | null> | null;
    admin?: { __typename?: 'BookingAdminType'; emoji?: string | null; mission_critical?: boolean | null } | null;
    team?: {
      __typename?: 'Teams';
      _id: string;
      name?: string | null;
      operational_office?: OperationalOffices | null;
      settings?: {
        __typename?: 'TeamSettingsType';
        ignore_tracking?: boolean | null;
        billing?: {
          __typename?: 'TeamSettingsBilling';
          company_name?: string | null;
          subscription?: {
            __typename?: 'TeamSettingsBillingSubscription';
            credit?: { __typename?: 'TeamSettingsBillingSubscriptionCredit'; remaining?: number | null } | null;
          } | null;
        } | null;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      _id: string;
      email?: string | null;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null; lastname?: string | null } | null;
      } | null;
      contact?: {
        __typename?: 'UserContactType';
        phone?: { __typename?: 'UserContactPhoneType'; mobile?: string | null } | null;
      } | null;
    } | null;
    credit_activity?: Array<{
      __typename?: 'CreditActivity';
      created?: number | null;
      amount?: number | null;
      accounting_type?: number | null;
      type?: number | null;
      comment?: string | null;
    } | null> | null;
    AutomationScore?: {
      __typename?: 'BookingAutomationScore';
      admin_actions?: number | null;
      score?: number | null;
    } | null;
    UserStatusCount?: {
      __typename?: 'UserStatusCount';
      completed?: number | null;
      confirmed?: number | null;
      scheduled?: number | null;
      waitlisted?: number | null;
      invited?: number | null;
      in_progress?: number | null;
      cancel_admin?: number | null;
      cancel_client?: number | null;
      cancel_participant?: number | null;
      cancel_noshow?: number | null;
      cancel_system?: number | null;
      cancel_reschedule?: number | null;
      available_eligible?: number | null;
      available_ineligible?: number | null;
      unavailable_eligible?: number | null;
      unavailable_ineligible?: number | null;
    } | null;
    ParticipantRatings?: Array<{ __typename?: 'BookingSubmissionRating'; overall?: number | null } | null> | null;
    project?: { __typename?: 'Project'; _id: string; name?: string | null; type?: number | null } | null;
  } | null;
};

export type Admin_UserStatusCountSubscriptionVariables = Exact<{ [key: string]: never }>;

export type Admin_UserStatusCountSubscription = {
  __typename?: 'Subscription';
  bookingUserStatus?: {
    __typename?: 'Booking';
    _id: string;
    UserStatusCount?: {
      __typename?: 'UserStatusCount';
      completed?: number | null;
      confirmed?: number | null;
      scheduled?: number | null;
      waitlisted?: number | null;
      invited?: number | null;
      in_progress?: number | null;
      cancel_admin?: number | null;
      cancel_client?: number | null;
      cancel_participant?: number | null;
      cancel_noshow?: number | null;
      cancel_system?: number | null;
      cancel_reschedule?: number | null;
      available_eligible?: number | null;
      available_ineligible?: number | null;
      unavailable_eligible?: number | null;
      unavailable_ineligible?: number | null;
    } | null;
  } | null;
};

export type Admin_MessagesSeenSubscriptionSubscriptionVariables = Exact<{
  _messages_id: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type Admin_MessagesSeenSubscriptionSubscription = {
  __typename?: 'Subscription';
  messagesSeenSubscription?: Array<{ __typename?: 'Messages'; _id: string; seen?: number | null } | null> | null;
};

export type Admin_MessagesSubscriptionSubscriptionVariables = Exact<{
  filter: MessageSearchInput;
}>;

export type Admin_MessagesSubscriptionSubscription = {
  __typename?: 'Subscription';
  messagesSubscription?: {
    __typename?: 'Messages';
    _id: string;
    type?: number | null;
    tag?: string | null;
    body?: string | null;
    seen?: number | null;
    created?: number | null;
    _to_user_id?: string | null;
    _from_user_id?: string | null;
    direction?: number | null;
    UserFrom?: {
      __typename?: 'User';
      _id: string;
      meta?: {
        __typename?: 'UserMetaType';
        identity?: { __typename?: 'UserMetaIdentityType'; firstname?: string | null } | null;
      } | null;
    } | null;
    context?: { __typename?: 'MessagesContextType'; _booking_id?: string | null; _user_id?: string | null } | null;
  } | null;
};

export const UserSubmissionAnalysisFieldsFragmentDoc = gql`
  fragment UserSubmissionAnalysisFields on UserSubmissionAnalysis {
    _id
    _booking_ids
    created
    prompt {
      variables {
        submissions
      }
      user
      system
    }
    response {
      inconsistency_reasoning
      inconsistency_verdict
    }
    rating {
      _admin_user_id
      date
      inconsistency_verdict_accurate
      inconsistency_reasoning_accurate
      user_was_dishonest
      comments
    }
  }
`;
export const UserStatusCountFieldsFragmentDoc = gql`
  fragment userStatusCountFields on UserStatusCount {
    completed
    confirmed
    scheduled
    waitlisted
    invited
    in_progress
    cancel_admin
    cancel_client
    cancel_participant
    cancel_noshow
    cancel_system
    cancel_reschedule
    available_eligible
    available_ineligible
    unavailable_eligible
    unavailable_ineligible
  }
`;
export const BookingPageBookingFieldsFragmentDoc = gql`
  fragment BookingPageBookingFields on Booking {
    _id
    status
    _owner_id
    _admin_id
    admin_user {
      _id
      picture
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
    name
    type
    total_participants
    team_booking_index
    _team_id
    settings {
      translation
    }
    CurrencyData {
      currency_code
      currency_symbol
      country_code
    }
    admin {
      emoji
      comments {
        _id
        _user_id
        created
        comment
        User {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
      mission_critical
      mission_critical_reason
    }
    config {
      min_participant_rating
      credits_per_participant
      participant_category
      timezone
      continuous_discovery
      hidden
      countries
      location {
        country
        state
        region
        postal_code
        city
        name
        level
        street1
        street2
        latitude
        longitude
      }
      session {
        type
        duration
        duration
        time_limit
        slot_min
        slot_max
      }
      incentive {
        currency_symbol
        value
      }
      incentives {
        currency_code
        currency_symbol
        country_code
        value
      }
      incentives {
        value
        currency_code
        currency_symbol
        country_code
      }
      criteria {
        locations {
          countries {
            name
            country
            currency_code
            currency_symbol
          }
          bounds {
            name
            country
            currency_code
            currency_symbol
          }
          states {
            name
            country
            currency_code
            currency_symbol
          }
        }
        meta_identity_locales {
          locale
        }
      }
      contact {
        email
        name
        phone
        UserOrganiser {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
          contact {
            phone {
              country_code
              mobile
            }
          }
        }
        UserFacilitator {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
          contact {
            phone {
              country_code
              mobile
            }
          }
        }
      }
      options {
        review_submission
      }
      online_task {
        type
        tool
      }
      longitudinal_study {
        period {
          time
          frequency
        }
      }
      project {
        project_type
        allowed_credits
      }
      block_participants {
        unverified_paypal
        giftpay
      }
    }
    session {
      _id
      start
      end
      status
    }
    user {
      _id
      email
      contact {
        phone {
          mobile
        }
      }
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
    team {
      _id
      name
      operational_office
      users {
        _id
        User {
          _id
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
    }
    UserStatusCount {
      ...userStatusCountFields
    }
    booking_participant(participant_status: [1, 4]) {
      _id
      _session_id
      _user_id
      cancel
      status
    }
    credit_activity {
      _id
      type
      accounting_type
      amount
      comment
      created
      Transaction {
        _id
        total_amount
        currency
        comment
        method
        invoice {
          amount_due
          purchase_order
          reference
          xero_id
        }
      }
      User {
        _id
        email
        type {
          client
          admin
        }
        meta {
          identity {
            firstname
            lastname
          }
        }
      }
    }
    history {
      completed_date
    }
  }
  ${UserStatusCountFieldsFragmentDoc}
`;
export const BookingsListCurrentFieldsFragmentDoc = gql`
  fragment bookingsListCurrentFields on Booking {
    _id
    created
    updated
    name
    status
    approved_date
    confirmed_date
    type
    total_participants
    team_booking_index
    _admin_id
    admin_user {
      _id
      picture
      meta {
        identity {
          firstname
        }
      }
    }
    config {
      demo
      credits_per_participant
      participant_category
      hidden
      location {
        country
        state
        region
        city
      }
      in_context {
        location_type
      }
      contact {
        name
        email
        phone
      }
      session {
        type
        duration
      }
      incentives {
        currency_code
        currency_symbol
        country_code
        value
      }
      options {
        review_submission
      }
      recruitment {
        byo
      }
      online_task {
        type
        tool
      }
      longitudinal_study {
        period {
          time
          frequency
        }
      }
      project {
        project_type
        allowed_credits
      }
      criteria {
        locations {
          bounds {
            country
          }
          countries {
            country
          }
          states {
            country
          }
        }
      }
    }
    session {
      _id
      start
      end
    }
    admin {
      emoji
      mission_critical
    }
    team {
      _id
      name
      operational_office
      settings {
        ignore_tracking
        billing {
          company_name
          subscription {
            credit {
              remaining
            }
          }
        }
      }
    }
    user {
      _id
      email
      meta {
        identity {
          firstname
          lastname
        }
      }
      contact {
        phone {
          mobile
        }
      }
    }
    credit_activity {
      created
      amount
      accounting_type
      type
      comment
    }
    AutomationScore {
      admin_actions
      score
    }
    UserStatusCount {
      ...userStatusCountFields
    }
    ParticipantRatings {
      overall
    }
    project {
      _id
      name
      type
    }
  }
  ${UserStatusCountFieldsFragmentDoc}
`;
export const ESignatureTemplateFieldsFragmentDoc = gql`
  fragment eSignatureTemplateFields on ESignatureTemplate {
    _id
    global
    active
    _team_id
    _organisation_id
    provider {
      name
      reference
    }
    name
    description
    recipients
    created
    updated
  }
`;
export const MessagesFieldsFragmentDoc = gql`
  fragment messagesFields on Messages {
    _id
    type
    tag
    body
    seen
    created
    _to_user_id
    _from_user_id
    direction
    created
    UserFrom {
      _id
      meta {
        identity {
          firstname
        }
      }
    }
    context {
      _booking_id
      _user_id
    }
  }
`;
export const TeamsListFieldsFragmentDoc = gql`
  fragment TeamsListFields on Teams {
    _id
    name
    created
    updated
    settings {
      ignore_tracking
      billing {
        company_name
        subscription {
          end
          credit {
            remaining
          }
        }
      }
    }
    Organisation {
      _id
      name
    }
    operational_office
    ParentTeam {
      _id
      name
      settings {
        ignore_tracking
      }
    }
  }
`;
export const ParticipantFieldsFragmentDoc = gql`
  fragment participantFields on User {
    _id
    email
    status
    blacklist
    created
    updated
    contact {
      phone {
        mobile
        verification {
          mobile
        }
      }
    }
    location {
      city
      state
      postal_code
      country
      longitude
      latitude
      timezone
    }
    meta {
      identity {
        firstname
        lastname
        gender
        birthday {
          timestamp
        }
        languages {
          english {
            speak {
              native
              fluent
              conversational
              beginner
            }
          }
        }
      }
      family {
        status {
          divorced
          married
          relationship
          single
          widowed
        }
      }
      education {
        apprenticeship
        highschool
        postgraduate
        some_university
        undergraduate
      }
      work {
        _industry_id
        _subindustry_id
        employment_type
        status {
          fulltime
          fulltime_student
          homeduties
          parttime
          parttime_student
          retired
          unemployed
        }
        business {
          size
          turnover
        }
      }
      social {
        linkedin {
          profile_url
        }
      }
      feedback_sample {
        recording_url
      }
    }
    settings {
      billing {
        preferred
        paypal
        paypal_details {
          verification_details {
            verified_account
          }
        }
        giftpay
      }
      remote {
        setup_check
        network_quality
      }
    }
    LastLogin
    ParticipantIncentives {
      total_amount
    }
    rating {
      ratings
      score
    }
    devices {
      id
      os
      app_version
      last_login
      token
    }
  }
`;
export const WigFieldsFragmentDoc = gql`
  fragment WigFields on AdminWig {
    _id
    period_name
    period_start
    period_end
    period_type
    operational_office
    goals {
      key
      value
      meta {
        name
        prefix
      }
    }
  }
`;
export const CurrentWigFieldsFragmentDoc = gql`
  fragment CurrentWigFields on AdminWig {
    _id
    period_name
    period_start
    period_end
    period_type
    operational_office
    goals {
      key
      value
      progress
      ghost
      meta {
        name
        prefix
        emoji
        bar_colour
      }
    }
  }
`;
export const ProjectFieldsFragmentDoc = gql`
  fragment ProjectFields on Project {
    _id
    status
    name
    owner {
      _id
      email
      contact {
        phone {
          mobile
        }
      }
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
    team {
      _id
      name
    }
    progress {
      current_status
    }
    users {
      _id
      status
      User {
        type {
          researcher
          client
        }
      }
    }
    created
    pricing {
      booking_credits
      researcher_hours
      total_credits
      total_participants
      detail {
        researcher_hours
        booking_credits
      }
    }
    askable_plus {
      research_type {
        discovery {
          quota
          _booking_id
        }
        competitive_analysis {
          quota
          _booking_id
        }
        survey {
          quota
          _booking_id
        }
        longitudinal {
          quota
          _booking_id
        }
        usability {
          quota
          _booking_id
        }
        continuous_ai_moderated {
          _booking_id
          quota
        }
        continuous_researcher_moderated {
          _booking_id
          quota
        }
      }
      due_date
      description
      objective
      researcher_hourly_rate
      meetings {
        briefing {
          meeting_date
          client_confirmed
          researcher_confirmed
        }
      }
      audience {
        description
      }
    }
    admin {
      mission_critical
      mission_critical_reason
    }
  }
`;
export const ProjectNotesFieldsFragmentDoc = gql`
  fragment ProjectNotesFields on Project {
    admin {
      notes {
        _id
        note
        user {
          displayName
        }
        created
      }
    }
  }
`;
export const ResearcherFragmentFragmentDoc = gql`
  fragment ResearcherFragment on User {
    _id
    created
    email
    displayName
    researcher {
      certification {
        status
        video_ask_submission_url
      }
      ux_length
      ux_length_as_of
      methods
      admin_notes
      projects {
        totalCount
        nodes {
          _id
          name
          askable_plus {
            due_date
          }
        }
      }
    }
    contact {
      phone {
        mobile
      }
    }
    timezone
    blacklist
    meta {
      social {
        linkedin {
          profile_url
        }
      }
      identity {
        firstname
        lastname
      }
    }
  }
`;
export const ApproveProjectDocument = gql`
  mutation approveProject($projectId: ID!) {
    updateProject(_project_id: $projectId, project: { status: 1 }) {
      _id
    }
  }
`;

export function useApproveProjectMutation() {
  return Urql.useMutation<ApproveProjectMutation, ApproveProjectMutationVariables>(ApproveProjectDocument);
}
export const CreateResearcherBatchDocument = gql`
  mutation createResearcherBatch($input: BatchInput!) {
    createBatch(batch: $input)
  }
`;

export function useCreateResearcherBatchMutation() {
  return Urql.useMutation<CreateResearcherBatchMutation, CreateResearcherBatchMutationVariables>(
    CreateResearcherBatchDocument,
  );
}
export const InviteResearcherToProjectDocument = gql`
  mutation inviteResearcherToProject($projectId: ID!, $researcherId: ID!) {
    inviteResearcherToProject(_project_id: $projectId, _researcher_id: $researcherId) {
      _id
    }
  }
`;

export function useInviteResearcherToProjectMutation() {
  return Urql.useMutation<InviteResearcherToProjectMutation, InviteResearcherToProjectMutationVariables>(
    InviteResearcherToProjectDocument,
  );
}
export const ProjectApplicantsDocument = gql`
  query projectApplicants($id: ID) {
    projectByID(id: $id) {
      users {
        status
        _id
        added_date
        invited_at
        accepted_at
        User {
          email
          displayName
          researcher {
            certification {
              status
            }
          }
        }
      }
    }
  }
`;

export function useProjectApplicantsQuery(options?: Omit<Urql.UseQueryArgs<ProjectApplicantsQueryVariables>, 'query'>) {
  return Urql.useQuery<ProjectApplicantsQuery, ProjectApplicantsQueryVariables>({
    query: ProjectApplicantsDocument,
    ...options,
  });
}
export const AdminDeleteUserDocument = gql`
  mutation AdminDeleteUser($id: ID!) {
    deleteUser(id: $id) {
      _id
      displayName
    }
  }
`;

export function useAdminDeleteUserMutation() {
  return Urql.useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(AdminDeleteUserDocument);
}
export const Admin_LoginDocument = gql`
  mutation admin_login($email: String, $password: String, $googleToken: String) {
    login(email: $email, password: $password, googleToken: $googleToken) {
      _id
      access_token
      roles
      email
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
  }
`;

export function useAdmin_LoginMutation() {
  return Urql.useMutation<Admin_LoginMutation, Admin_LoginMutationVariables>(Admin_LoginDocument);
}
export const Admin_ApproveBookingDocument = gql`
  mutation admin_approveBooking(
    $booking: ID!
    $incentives: [BookingInputConfigIncentive!]!
    $settings: ApproveBookingSettingsInput!
  ) {
    approveBooking(booking_id: $booking, incentives: $incentives, settings: $settings) {
      ...BookingPageBookingFields
    }
  }
  ${BookingPageBookingFieldsFragmentDoc}
`;

export function useAdmin_ApproveBookingMutation() {
  return Urql.useMutation<Admin_ApproveBookingMutation, Admin_ApproveBookingMutationVariables>(
    Admin_ApproveBookingDocument,
  );
}
export const Admin_BookingPageCommentDocument = gql`
  mutation admin_bookingPageComment($booking: ID!, $comment: String!) {
    addAdminComment(booking_id: $booking, comment: $comment) {
      ...BookingPageBookingFields
    }
  }
  ${BookingPageBookingFieldsFragmentDoc}
`;

export function useAdmin_BookingPageCommentMutation() {
  return Urql.useMutation<Admin_BookingPageCommentMutation, Admin_BookingPageCommentMutationVariables>(
    Admin_BookingPageCommentDocument,
  );
}
export const Admin_BookingPageRejectBookingDocument = gql`
  mutation admin_bookingPageRejectBooking($booking: ID!) {
    rejectBooking(booking_id: $booking) {
      ...BookingPageBookingFields
    }
  }
  ${BookingPageBookingFieldsFragmentDoc}
`;

export function useAdmin_BookingPageRejectBookingMutation() {
  return Urql.useMutation<Admin_BookingPageRejectBookingMutation, Admin_BookingPageRejectBookingMutationVariables>(
    Admin_BookingPageRejectBookingDocument,
  );
}
export const CreateSessionDocument = gql`
  mutation createSession($booking_id: ID!, $session: [BookingInputSession]!) {
    createSession(booking_id: $booking_id, session: $session) {
      _id
      name
      status
      config {
        online_task {
          type
        }
      }
      session {
        _id
        start
        end
        slot_min
        slot_max
        status
      }
    }
  }
`;

export function useCreateSessionMutation() {
  return Urql.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument);
}
export const MoveBookingToAnotherTeamDocument = gql`
  mutation moveBookingToAnotherTeam($booking_id: ID!, $new_team_id: ID!, $move_credit_activity: Boolean) {
    moveBookingToAnotherTeam(
      booking_id: $booking_id
      new_team_id: $new_team_id
      move_credit_activity: $move_credit_activity
    ) {
      _id
    }
  }
`;

export function useMoveBookingToAnotherTeamMutation() {
  return Urql.useMutation<MoveBookingToAnotherTeamMutation, MoveBookingToAnotherTeamMutationVariables>(
    MoveBookingToAnotherTeamDocument,
  );
}
export const UpdateBookingDocument = gql`
  mutation updateBooking($booking_id: ID!, $booking: BookingInput!) {
    updateBooking(booking_id: $booking_id, booking: $booking) {
      _id
      name
      status
      config {
        online_task {
          type
        }
      }
    }
  }
`;

export function useUpdateBookingMutation() {
  return Urql.useMutation<UpdateBookingMutation, UpdateBookingMutationVariables>(UpdateBookingDocument);
}
export const UpdateBookingAdminDocument = gql`
  mutation updateBookingAdmin($booking_id: ID!, $booking: BookingInput!) {
    updateBookingAdmin(booking_id: $booking_id, booking: $booking) {
      _id
      name
      status
      config {
        online_task {
          type
        }
      }
    }
  }
`;

export function useUpdateBookingAdminMutation() {
  return Urql.useMutation<UpdateBookingAdminMutation, UpdateBookingAdminMutationVariables>(UpdateBookingAdminDocument);
}
export const UpdateBookingAdminUserDocument = gql`
  mutation updateBookingAdminUser($booking_id: ID!, $booking: BookingInput!) {
    updateBookingAdmin(booking_id: $booking_id, booking: $booking) {
      admin_user {
        _id
        picture
        meta {
          identity {
            firstname
          }
        }
      }
    }
  }
`;

export function useUpdateBookingAdminUserMutation() {
  return Urql.useMutation<UpdateBookingAdminUserMutation, UpdateBookingAdminUserMutationVariables>(
    UpdateBookingAdminUserDocument,
  );
}
export const UpdateSessionDocument = gql`
  mutation updateSession($booking_id: ID!, $session_id: ID!, $session: BookingInputSession!) {
    updateSession(booking_id: $booking_id, session_id: $session_id, session: $session) {
      _id
      name
      status
      config {
        online_task {
          type
        }
      }
      session {
        _id
        start
        end
        slot_min
        slot_max
        status
      }
    }
  }
`;

export function useUpdateSessionMutation() {
  return Urql.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(UpdateSessionDocument);
}
export const ParticipantSubmissionsExportCsvDocument = gql`
  mutation participantSubmissionsExportCSV($_booking_id: ID!) {
    participantSubmissionsExportCSV(_booking_id: $_booking_id)
  }
`;

export function useParticipantSubmissionsExportCsvMutation() {
  return Urql.useMutation<ParticipantSubmissionsExportCsvMutation, ParticipantSubmissionsExportCsvMutationVariables>(
    ParticipantSubmissionsExportCsvDocument,
  );
}
export const Admin_ResetSubmissionRatingDocument = gql`
  mutation admin_ResetSubmissionRating($submissionId: ObjectID!) {
    resetParticipantRating(submission_id: $submissionId) {
      _id
      rating {
        overall
      }
    }
  }
`;

export function useAdmin_ResetSubmissionRatingMutation() {
  return Urql.useMutation<Admin_ResetSubmissionRatingMutation, Admin_ResetSubmissionRatingMutationVariables>(
    Admin_ResetSubmissionRatingDocument,
  );
}
export const Admin_SubmissionRateParticipantDocument = gql`
  mutation admin_SubmissionRateParticipant($input: RatingParticipantInput!) {
    submissionRateParticipant(input: $input) {
      _id
      rating {
        overall
      }
    }
  }
`;

export function useAdmin_SubmissionRateParticipantMutation() {
  return Urql.useMutation<Admin_SubmissionRateParticipantMutation, Admin_SubmissionRateParticipantMutationVariables>(
    Admin_SubmissionRateParticipantDocument,
  );
}
export const CreateUserSubmissionAnalysisDocument = gql`
  mutation createUserSubmissionAnalysis($_user_id: ObjectID!, $prompt: CreateUserSubmissionAnalysisPromptInput) {
    createUserSubmissionAnalysis(input: { _user_id: $_user_id, prompt_template: $prompt }) {
      ...UserSubmissionAnalysisFields
    }
  }
  ${UserSubmissionAnalysisFieldsFragmentDoc}
`;

export function useCreateUserSubmissionAnalysisMutation() {
  return Urql.useMutation<CreateUserSubmissionAnalysisMutation, CreateUserSubmissionAnalysisMutationVariables>(
    CreateUserSubmissionAnalysisDocument,
  );
}
export const CreateESignatureTemplateDocument = gql`
  mutation createESignatureTemplate($template: ESignatureTemplateInput!) {
    createESignatureTemplate(template: $template) {
      ...eSignatureTemplateFields
    }
  }
  ${ESignatureTemplateFieldsFragmentDoc}
`;

export function useCreateESignatureTemplateMutation() {
  return Urql.useMutation<CreateESignatureTemplateMutation, CreateESignatureTemplateMutationVariables>(
    CreateESignatureTemplateDocument,
  );
}
export const RequestESignatureTemplateDeleteDocument = gql`
  mutation requestESignatureTemplateDelete($_id: ID!) {
    requestESignatureTemplateDelete(_id: $_id) {
      ...eSignatureTemplateFields
    }
  }
  ${ESignatureTemplateFieldsFragmentDoc}
`;

export function useRequestESignatureTemplateDeleteMutation() {
  return Urql.useMutation<RequestESignatureTemplateDeleteMutation, RequestESignatureTemplateDeleteMutationVariables>(
    RequestESignatureTemplateDeleteDocument,
  );
}
export const UpdateESignatureTemplateDocument = gql`
  mutation updateESignatureTemplate($_id: ID!, $template: ESignatureTemplateInput!) {
    updateESignatureTemplate(_id: $_id, template: $template) {
      ...eSignatureTemplateFields
    }
  }
  ${ESignatureTemplateFieldsFragmentDoc}
`;

export function useUpdateESignatureTemplateMutation() {
  return Urql.useMutation<UpdateESignatureTemplateMutation, UpdateESignatureTemplateMutationVariables>(
    UpdateESignatureTemplateDocument,
  );
}
export const AdminCreateBatchDocument = gql`
  mutation adminCreateBatch($batch: BatchInput!) {
    createBatch(batch: $batch)
  }
`;

export function useAdminCreateBatchMutation() {
  return Urql.useMutation<AdminCreateBatchMutation, AdminCreateBatchMutationVariables>(AdminCreateBatchDocument);
}
export const CreateMessageDocument = gql`
  mutation createMessage($message: MessagesInput!) {
    createMessage(message: $message) {
      ...messagesFields
    }
  }
  ${MessagesFieldsFragmentDoc}
`;

export function useCreateMessageMutation() {
  return Urql.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument);
}
export const SyncOrganisationOnHubspotDocument = gql`
  mutation syncOrganisationOnHubspot($_id: ID!) {
    syncOrganisationOnHubspot(_id: $_id) {
      _id
    }
  }
`;

export function useSyncOrganisationOnHubspotMutation() {
  return Urql.useMutation<SyncOrganisationOnHubspotMutation, SyncOrganisationOnHubspotMutationVariables>(
    SyncOrganisationOnHubspotDocument,
  );
}
export const RemoveOrganisationFromTeamDocument = gql`
  mutation removeOrganisationFromTeam($_id: ID!) {
    removeOrganisationFromTeam(_id: $_id) {
      _id
      name
    }
  }
`;

export function useRemoveOrganisationFromTeamMutation() {
  return Urql.useMutation<RemoveOrganisationFromTeamMutation, RemoveOrganisationFromTeamMutationVariables>(
    RemoveOrganisationFromTeamDocument,
  );
}
export const AddProjectNoteDocument = gql`
  mutation AddProjectNote($input: AdminNotesInput!, $projectId: ID!) {
    addAdminProjectNote(input: $input, _project_id: $projectId) {
      _id
    }
  }
`;

export function useAddProjectNoteMutation() {
  return Urql.useMutation<AddProjectNoteMutation, AddProjectNoteMutationVariables>(AddProjectNoteDocument);
}
export const ForceCompleteBookingDocument = gql`
  mutation ForceCompleteBooking($projectId: ID!) {
    updateProject(_project_id: $projectId, project: { status: 5 }) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`;

export function useForceCompleteBookingMutation() {
  return Urql.useMutation<ForceCompleteBookingMutation, ForceCompleteBookingMutationVariables>(
    ForceCompleteBookingDocument,
  );
}
export const UpdateProjectDocument = gql`
  mutation UpdateProject($projectId: ID!, $input: ProjectInput!) {
    updateProject(_project_id: $projectId, project: $input) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`;

export function useUpdateProjectMutation() {
  return Urql.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument);
}
export const RateUserSubmissionAnalysisDocument = gql`
  mutation rateUserSubmissionAnalysis($_id: ObjectID!, $rating: RateUserSubmissionAnalysisInput!) {
    rateUserSubmissionAnalysis(_id: $_id, rating: $rating) {
      ...UserSubmissionAnalysisFields
    }
  }
  ${UserSubmissionAnalysisFieldsFragmentDoc}
`;

export function useRateUserSubmissionAnalysisMutation() {
  return Urql.useMutation<RateUserSubmissionAnalysisMutation, RateUserSubmissionAnalysisMutationVariables>(
    RateUserSubmissionAnalysisDocument,
  );
}
export const AssignOrganisationToTeamDocument = gql`
  mutation assignOrganisationToTeam($_id: ID!, $_organisation_id: ID!) {
    assignOrganisationToTeam(_id: $_id, _organisation_id: $_organisation_id) {
      _id
      name
      Organisation {
        _id
        name
      }
      settings {
        billing {
          subscription {
            end
          }
        }
      }
    }
  }
`;

export function useAssignOrganisationToTeamMutation() {
  return Urql.useMutation<AssignOrganisationToTeamMutation, AssignOrganisationToTeamMutationVariables>(
    AssignOrganisationToTeamDocument,
  );
}
export const UpdateTeamDocument = gql`
  mutation updateTeam($_id: ID!, $team: TeamInput!) {
    updateTeam(_id: $_id, team: $team) {
      _id
      name
      Organisation {
        name
        _id
      }
      settings {
        billing {
          subscription {
            end
          }
        }
      }
    }
  }
`;

export function useUpdateTeamMutation() {
  return Urql.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument);
}
export const AdminLoginAsResearcherDocument = gql`
  mutation AdminLoginAsResearcher($id: ID!) {
    adminLoginAsResearcher(id: $id) {
      url
    }
  }
`;

export function useAdminLoginAsResearcherMutation() {
  return Urql.useMutation<AdminLoginAsResearcherMutation, AdminLoginAsResearcherMutationVariables>(
    AdminLoginAsResearcherDocument,
  );
}
export const Admin_AdminLoginAsClientDocument = gql`
  mutation admin_adminLoginAsClient($_id: ID!) {
    adminLoginAsClient(_id: $_id) {
      url
      user {
        _id
        access_token
        type {
          client
          participant
        }
      }
    }
  }
`;

export function useAdmin_AdminLoginAsClientMutation() {
  return Urql.useMutation<Admin_AdminLoginAsClientMutation, Admin_AdminLoginAsClientMutationVariables>(
    Admin_AdminLoginAsClientDocument,
  );
}
export const Admin_DeleteUserDocument = gql`
  mutation admin_deleteUser($_id: ID!) {
    deleteUser(id: $_id) {
      _id
    }
  }
`;

export function useAdmin_DeleteUserMutation() {
  return Urql.useMutation<Admin_DeleteUserMutation, Admin_DeleteUserMutationVariables>(Admin_DeleteUserDocument);
}
export const Admin_UpdateResearcherDocument = gql`
  mutation admin_updateResearcher($_id: ID!, $user: UserInput!) {
    updateUser(id: $_id, user: $user) {
      ...ResearcherFragment
    }
  }
  ${ResearcherFragmentFragmentDoc}
`;

export function useAdmin_UpdateResearcherMutation() {
  return Urql.useMutation<Admin_UpdateResearcherMutation, Admin_UpdateResearcherMutationVariables>(
    Admin_UpdateResearcherDocument,
  );
}
export const Admin_UpdateUserDocument = gql`
  mutation admin_updateUser($_id: ID!, $user: UserInput!) {
    updateUser(id: $_id, user: $user) {
      _id
      updated
    }
  }
`;

export function useAdmin_UpdateUserMutation() {
  return Urql.useMutation<Admin_UpdateUserMutation, Admin_UpdateUserMutationVariables>(Admin_UpdateUserDocument);
}
export const Admin_VerifyUserPhoneDocument = gql`
  mutation admin_verifyUserPhone($id: ID!) {
    verifyUserPhone(_user_id: $id) {
      _id
      created
      updated
      contact {
        phone {
          mobile
          verification {
            mobile
            fax
          }
        }
      }
    }
  }
`;

export function useAdmin_VerifyUserPhoneMutation() {
  return Urql.useMutation<Admin_VerifyUserPhoneMutation, Admin_VerifyUserPhoneMutationVariables>(
    Admin_VerifyUserPhoneDocument,
  );
}
export const AdminSaveWigDocument = gql`
  mutation adminSaveWig($_id: ID, $input: AdminWigInput!) {
    adminSaveWig(_id: $_id, input: $input) {
      ...WigFields
    }
  }
  ${WigFieldsFragmentDoc}
`;

export function useAdminSaveWigMutation() {
  return Urql.useMutation<AdminSaveWigMutation, AdminSaveWigMutationVariables>(AdminSaveWigDocument);
}
export const Admin_BookingByIdDocument = gql`
  query admin_BookingByID($id: ID!) {
    bookingByID(id: $id) {
      ...BookingPageBookingFields
      history {
        completed_date
      }
      project {
        _id
        name
        type
      }
      admin {
        tags {
          nufp
        }
      }
      ParticipantSessionsCount {
        completed
        scheduled
        available
        invited
        waiting_list
        in_progress
        total
        total_eligible
        total_refunded
      }
    }
  }
  ${BookingPageBookingFieldsFragmentDoc}
`;

export function useAdmin_BookingByIdQuery(options: Omit<Urql.UseQueryArgs<Admin_BookingByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<Admin_BookingByIdQuery, Admin_BookingByIdQueryVariables>({
    query: Admin_BookingByIdDocument,
    ...options,
  });
}
export const Admin_BookingPageBookingDocument = gql`
  query admin_BookingPageBooking($id: ID!) {
    bookingByID(id: $id) {
      ...BookingPageBookingFields
    }
  }
  ${BookingPageBookingFieldsFragmentDoc}
`;

export function useAdmin_BookingPageBookingQuery(
  options: Omit<Urql.UseQueryArgs<Admin_BookingPageBookingQueryVariables>, 'query'>,
) {
  return Urql.useQuery<Admin_BookingPageBookingQuery, Admin_BookingPageBookingQueryVariables>({
    query: Admin_BookingPageBookingDocument,
    ...options,
  });
}
export const BookingSubmissionsForBookingIdDocument = gql`
  query bookingSubmissionsForBookingId($bookingId: ID!) {
    findAllBookingSubmissions(bookingId: $bookingId) {
      _id
      created
      user {
        _id
        email
        location {
          country
          city
        }
        meta {
          identity {
            firstname
            lastname
          }
        }
      }
      status
      rating {
        overall
      }
    }
  }
`;

export function useBookingSubmissionsForBookingIdQuery(
  options: Omit<Urql.UseQueryArgs<BookingSubmissionsForBookingIdQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BookingSubmissionsForBookingIdQuery, BookingSubmissionsForBookingIdQueryVariables>({
    query: BookingSubmissionsForBookingIdDocument,
    ...options,
  });
}
export const Admin_BookingsListCurrentDocument = gql`
  query admin_bookingsListCurrent($status: [Int]) {
    bookings(status: $status) {
      ...bookingsListCurrentFields
    }
  }
  ${BookingsListCurrentFieldsFragmentDoc}
`;

export function useAdmin_BookingsListCurrentQuery(
  options?: Omit<Urql.UseQueryArgs<Admin_BookingsListCurrentQueryVariables>, 'query'>,
) {
  return Urql.useQuery<Admin_BookingsListCurrentQuery, Admin_BookingsListCurrentQueryVariables>({
    query: Admin_BookingsListCurrentDocument,
    ...options,
  });
}
export const Admin_GetCouponsDocument = gql`
  query admin_getCoupons {
    coupons {
      _id
      code
      validity {
        start
        end
      }
      redeem_limit
      allow_reuse
      discount_type
      discount_value
      redeemed_by
      created
    }
  }
`;

export function useAdmin_GetCouponsQuery(options?: Omit<Urql.UseQueryArgs<Admin_GetCouponsQueryVariables>, 'query'>) {
  return Urql.useQuery<Admin_GetCouponsQuery, Admin_GetCouponsQueryVariables>({
    query: Admin_GetCouponsDocument,
    ...options,
  });
}
export const ESignatureTemplatesByTeamDocument = gql`
  query eSignatureTemplatesByTeam($_team_id: ID!) {
    eSignatureTemplatesByTeam(_team_id: $_team_id) {
      ...eSignatureTemplateFields
      AgreementBookings {
        _id
        status
        name
      }
    }
  }
  ${ESignatureTemplateFieldsFragmentDoc}
`;

export function useESignatureTemplatesByTeamQuery(
  options: Omit<Urql.UseQueryArgs<ESignatureTemplatesByTeamQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ESignatureTemplatesByTeamQuery, ESignatureTemplatesByTeamQueryVariables>({
    query: ESignatureTemplatesByTeamDocument,
    ...options,
  });
}
export const GetBookingESignatureTemplatesDocument = gql`
  query getBookingESignatureTemplates($_booking_id: ID!) {
    getBookingESignatureTemplates(_booking_id: $_booking_id) {
      ...eSignatureTemplateFields
    }
  }
  ${ESignatureTemplateFieldsFragmentDoc}
`;

export function useGetBookingESignatureTemplatesQuery(
  options: Omit<Urql.UseQueryArgs<GetBookingESignatureTemplatesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetBookingESignatureTemplatesQuery, GetBookingESignatureTemplatesQueryVariables>({
    query: GetBookingESignatureTemplatesDocument,
    ...options,
  });
}
export const Admin_AdminMessagesDocument = gql`
  query admin_adminMessages($_user_id: ID!) {
    userAdminMessages(_user_id: $_user_id) {
      ...messagesFields
    }
  }
  ${MessagesFieldsFragmentDoc}
`;

export function useAdmin_AdminMessagesQuery(
  options: Omit<Urql.UseQueryArgs<Admin_AdminMessagesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<Admin_AdminMessagesQuery, Admin_AdminMessagesQueryVariables>({
    query: Admin_AdminMessagesDocument,
    ...options,
  });
}
export const Admin_CountBatchMatchesDocument = gql`
  query admin_countBatchMatches($batch: BatchInput!) {
    countBatchMatches(batch: $batch)
  }
`;

export function useAdmin_CountBatchMatchesQuery(
  options: Omit<Urql.UseQueryArgs<Admin_CountBatchMatchesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<Admin_CountBatchMatchesQuery, Admin_CountBatchMatchesQueryVariables>({
    query: Admin_CountBatchMatchesDocument,
    ...options,
  });
}
export const Admin_BookingParticipantMessagesDocument = gql`
  query admin_bookingParticipantMessages($_booking_id: ID!, $_user_id: ID!) {
    bookingParticipantMessages(_booking_id: $_booking_id, _user_id: $_user_id) {
      _id
      type
      config {
        incentive {
          currency_symbol
          value
        }
        location {
          country
          city
          region
        }
        in_context {
          location_type
        }
      }
      messages {
        ...messagesFields
      }
      booking_participant {
        _id
        status
        cancel
        session {
          _id
          start
        }
        history {
          early_confirmation_request
          last_quantitative_application_time
          session_click_confirm_button
          session_completed
          session_completed_feedback_request
          session_completed_feedback_submitted
          session_confirmed_cancellation
          session_invitation_accepted
          session_invitation_declined
          session_invitation_notification
          session_invitation_notification_followup
          session_opportunity_application
          session_paid
          session_participant_cancelled_by_askable
          session_participant_cancelled_by_client
          session_quant_completed
          session_quant_invitation_notification
          session_reinvite_notification
          session_start_reminder_2_askable_incentive_message
          session_start_reminder_action_needed
          session_start_reminder_action_needed_followup
          session_start_reminder_confirmation_pending_askable_notification
          session_start_reminder_last_askable_warning
          session_start_reminder_no_action
        }
      }
    }
  }
  ${MessagesFieldsFragmentDoc}
`;

export function useAdmin_BookingParticipantMessagesQuery(
  options: Omit<Urql.UseQueryArgs<Admin_BookingParticipantMessagesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<Admin_BookingParticipantMessagesQuery, Admin_BookingParticipantMessagesQueryVariables>({
    query: Admin_BookingParticipantMessagesDocument,
    ...options,
  });
}
export const Admin_MessagesSeenDocument = gql`
  query admin_messagesSeen($_messages_id: [ID]!) {
    messagesSeen(_messages_id: $_messages_id) {
      _id
      seen
    }
  }
`;

export function useAdmin_MessagesSeenQuery(
  options: Omit<Urql.UseQueryArgs<Admin_MessagesSeenQueryVariables>, 'query'>,
) {
  return Urql.useQuery<Admin_MessagesSeenQuery, Admin_MessagesSeenQueryVariables>({
    query: Admin_MessagesSeenDocument,
    ...options,
  });
}
export const Admin_GetIndustryDataDocument = gql`
  query admin_getIndustryData {
    industryList {
      _id
      name
      subcategories {
        _id
        name
      }
    }
  }
`;

export function useAdmin_GetIndustryDataQuery(
  options?: Omit<Urql.UseQueryArgs<Admin_GetIndustryDataQueryVariables>, 'query'>,
) {
  return Urql.useQuery<Admin_GetIndustryDataQuery, Admin_GetIndustryDataQueryVariables>({
    query: Admin_GetIndustryDataDocument,
    ...options,
  });
}
export const OrganisationByIdDocument = gql`
  query organisationById($_id: ID!) {
    organisationById(_id: $_id) {
      _id
      name
      created
      admin {
        Owner {
          _id
          picture
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
      Teams {
        ...TeamsListFields
      }
    }
  }
  ${TeamsListFieldsFragmentDoc}
`;

export function useOrganisationByIdQuery(options: Omit<Urql.UseQueryArgs<OrganisationByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<OrganisationByIdQuery, OrganisationByIdQueryVariables>({
    query: OrganisationByIdDocument,
    ...options,
  });
}
export const OrganisationsDocument = gql`
  query organisations {
    organisations {
      _id
      name
      created
      admin {
        Owner {
          _id
          picture
          email
          meta {
            identity {
              firstname
              lastname
            }
          }
        }
      }
      Teams {
        _id
        name
      }
      settings {
        exclude_participants_from_teams
      }
      integrations {
        hubspot_company_id
      }
    }
  }
`;

export function useOrganisationsQuery(options?: Omit<Urql.UseQueryArgs<OrganisationsQueryVariables>, 'query'>) {
  return Urql.useQuery<OrganisationsQuery, OrganisationsQueryVariables>({ query: OrganisationsDocument, ...options });
}
export const AskablePlusProjectsListDocument = gql`
  query AskablePlusProjectsList {
    projectsConnection(filter: { type: 1 }, first: 9999) {
      totalCount
      nodes {
        _id
        status
        type
        name
        created
        progress {
          current_status
        }
        askable_plus {
          due_date
        }
        team {
          _id
          name
          operational_office
        }
      }
    }
  }
`;

export function useAskablePlusProjectsListQuery(
  options?: Omit<Urql.UseQueryArgs<AskablePlusProjectsListQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AskablePlusProjectsListQuery, AskablePlusProjectsListQueryVariables>({
    query: AskablePlusProjectsListDocument,
    ...options,
  });
}
export const AskablePlusProjectsDocument = gql`
  query askablePlusProjects {
    projectsConnection(filter: { type: 1, status: [active] }, first: 499) {
      nodes {
        _id
        status
        name
        created
        team {
          _id
          name
          operational_office
        }
        progress {
          current_status
          in_review {
            steps {
              key
              status
            }
          }
          research {
            steps {
              key
              status
            }
          }
          delivery {
            steps {
              key
              status
            }
          }
          kick_off {
            steps {
              key
              status
            }
          }
        }
        bookings {
          _id
          status
        }
        users {
          User {
            type {
              researcher
            }
          }
        }
        askable_plus {
          objective
          description
          due_date
          research_type {
            discovery {
              _booking_id
              quota
            }
            usability {
              _booking_id
              quota
            }
            competitive_analysis {
              _booking_id
              quota
            }
            longitudinal {
              _booking_id
              quota
            }
            survey {
              _booking_id
              quota
            }
            continuous_ai_moderated {
              _booking_id
              quota
            }
            continuous_researcher_moderated {
              _booking_id
              quota
            }
          }
        }
        admin {
          mission_critical
        }
      }
    }
  }
`;

export function useAskablePlusProjectsQuery(
  options?: Omit<Urql.UseQueryArgs<AskablePlusProjectsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AskablePlusProjectsQuery, AskablePlusProjectsQueryVariables>({
    query: AskablePlusProjectsDocument,
    ...options,
  });
}
export const ProjectByIdDocument = gql`
  query ProjectByID($id: ID!) {
    projectByID(id: $id) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`;

export function useProjectByIdQuery(options: Omit<Urql.UseQueryArgs<ProjectByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<ProjectByIdQuery, ProjectByIdQueryVariables>({ query: ProjectByIdDocument, ...options });
}
export const AdminProjectNotesDocument = gql`
  query AdminProjectNotes($id: ID!) {
    projectByID(id: $id) {
      ...ProjectNotesFields
    }
  }
  ${ProjectNotesFieldsFragmentDoc}
`;

export function useAdminProjectNotesQuery(options: Omit<Urql.UseQueryArgs<AdminProjectNotesQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminProjectNotesQuery, AdminProjectNotesQueryVariables>({
    query: AdminProjectNotesDocument,
    ...options,
  });
}
export const ProjectsForUserDocument = gql`
  query ProjectsForUser($id: ID!) {
    userByID(id: $id) {
      researcher {
        certification {
          status
        }
        projects {
          nodes {
            _id
            created
            name
            askable_plus {
              due_date
            }
            progress {
              current_status
            }
          }
        }
      }
    }
  }
`;

export function useProjectsForUserQuery(options: Omit<Urql.UseQueryArgs<ProjectsForUserQueryVariables>, 'query'>) {
  return Urql.useQuery<ProjectsForUserQuery, ProjectsForUserQueryVariables>({
    query: ProjectsForUserDocument,
    ...options,
  });
}
export const Admin_SearchDocument = gql`
  query admin_search($query: String!) {
    search(query: $query) {
      type
      _id
      labels
    }
  }
`;

export function useAdmin_SearchQuery(options: Omit<Urql.UseQueryArgs<Admin_SearchQueryVariables>, 'query'>) {
  return Urql.useQuery<Admin_SearchQuery, Admin_SearchQueryVariables>({ query: Admin_SearchDocument, ...options });
}
export const TeamByIdDocument = gql`
  query teamById($_id: ID!) {
    teamById(_id: $_id) {
      _id
      name
      created
      updated
      settings {
        ignore_tracking
        billing {
          company_name
          subscription {
            end
            credit {
              remaining
            }
          }
          invoice_due_days
        }
      }
      Organisation {
        _id
        name
      }
      operational_office
    }
  }
`;

export function useTeamByIdQuery(options: Omit<Urql.UseQueryArgs<TeamByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<TeamByIdQuery, TeamByIdQueryVariables>({ query: TeamByIdDocument, ...options });
}
export const TeamInvoiceHistoryDocument = gql`
  query teamInvoiceHistory($_id: ID!) {
    teamInvoiceHistory(_id: $_id) {
      _id
      status
      total_amount
      invoice {
        amount_due
      }
    }
  }
`;

export function useTeamInvoiceHistoryQuery(
  options: Omit<Urql.UseQueryArgs<TeamInvoiceHistoryQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TeamInvoiceHistoryQuery, TeamInvoiceHistoryQueryVariables>({
    query: TeamInvoiceHistoryDocument,
    ...options,
  });
}
export const TeamsDocument = gql`
  query teams($search: TeamsSearchInput) {
    teams(search: $search) {
      ...TeamsListFields
    }
  }
  ${TeamsListFieldsFragmentDoc}
`;

export function useTeamsQuery(options?: Omit<Urql.UseQueryArgs<TeamsQueryVariables>, 'query'>) {
  return Urql.useQuery<TeamsQuery, TeamsQueryVariables>({ query: TeamsDocument, ...options });
}
export const ClientsConnectionDocument = gql`
  query ClientsConnection($after: String) {
    userConnection(filter: { type: [client], status: [active] }, after: $after, first: 25) {
      totalCount
      nodes {
        _id
        email
        meta {
          identity {
            firstname
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function useClientsConnectionQuery(options?: Omit<Urql.UseQueryArgs<ClientsConnectionQueryVariables>, 'query'>) {
  return Urql.useQuery<ClientsConnectionQuery, ClientsConnectionQueryVariables>({
    query: ClientsConnectionDocument,
    ...options,
  });
}
export const AdminUsersDocument = gql`
  query adminUsers($customer_success: Boolean, $growth: Boolean, $product: Boolean) {
    adminUsers(customer_success: $customer_success, growth: $growth, product: $product) {
      _id
      picture
      meta {
        identity {
          firstname
        }
      }
      admin {
        operational_office
      }
    }
  }
`;

export function useAdminUsersQuery(options?: Omit<Urql.UseQueryArgs<AdminUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminUsersQuery, AdminUsersQueryVariables>({ query: AdminUsersDocument, ...options });
}
export const Admin_GetParticipantDetailsDocument = gql`
  query admin_getParticipantDetails($_id: ID!) {
    userByID(id: $_id) {
      ...participantFields
    }
  }
  ${ParticipantFieldsFragmentDoc}
`;

export function useAdmin_GetParticipantDetailsQuery(
  options: Omit<Urql.UseQueryArgs<Admin_GetParticipantDetailsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<Admin_GetParticipantDetailsQuery, Admin_GetParticipantDetailsQueryVariables>({
    query: Admin_GetParticipantDetailsDocument,
    ...options,
  });
}
export const GetResearcherDocument = gql`
  query GetResearcher($id: ID) {
    userByID(id: $id) {
      ...ResearcherFragment
    }
  }
  ${ResearcherFragmentFragmentDoc}
`;

export function useGetResearcherQuery(options?: Omit<Urql.UseQueryArgs<GetResearcherQueryVariables>, 'query'>) {
  return Urql.useQuery<GetResearcherQuery, GetResearcherQueryVariables>({ query: GetResearcherDocument, ...options });
}
export const GetUserByIdDocument = gql`
  query GetUserByID($id: ID!) {
    userByID(id: $id) {
      type {
        researcher
        participant
        client
      }
    }
  }
`;

export function useGetUserByIdQuery(options: Omit<Urql.UseQueryArgs<GetUserByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>({ query: GetUserByIdDocument, ...options });
}
export const GetViewerDocument = gql`
  query GetViewer {
    userMe {
      _id
      email
      picture
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
  }
`;

export function useGetViewerQuery(options?: Omit<Urql.UseQueryArgs<GetViewerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetViewerQuery, GetViewerQueryVariables>({ query: GetViewerDocument, ...options });
}
export const ParticipantsConnectionDocument = gql`
  query ParticipantsConnection {
    users(filter: { type: { participant: { eq: true } } }, first: 10) {
      nodes {
        email
        meta {
          identity {
            firstname
            lastname
          }
        }
        _id
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function useParticipantsConnectionQuery(
  options?: Omit<Urql.UseQueryArgs<ParticipantsConnectionQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ParticipantsConnectionQuery, ParticipantsConnectionQueryVariables>({
    query: ParticipantsConnectionDocument,
    ...options,
  });
}
export const ResearcherConnectionDocument = gql`
  query ResearcherConnection($after: String, $before: String, $first: Int) {
    userConnection(filter: { type: [researcher], status: [active] }, after: $after, before: $before, first: $first) {
      totalCount
      nodes {
        email
        created
        blacklist
        researcher {
          certification {
            status
            video_ask_submission_url
          }
          projects {
            totalCount
            nodes {
              _id
              askable_plus {
                due_date
              }
            }
          }
          ux_length_as_of
          ux_length
          admin_notes
          methods
        }
        contact {
          phone {
            mobile
          }
        }
        meta {
          social {
            linkedin {
              profile_url
            }
          }
          identity {
            firstname
            lastname
          }
        }
        _id
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export function useResearcherConnectionQuery(
  options?: Omit<Urql.UseQueryArgs<ResearcherConnectionQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ResearcherConnectionQuery, ResearcherConnectionQueryVariables>({
    query: ResearcherConnectionDocument,
    ...options,
  });
}
export const UserSubmissionLatestAnalysisDocument = gql`
  query userSubmissionLatestAnalysis($_user_id: ObjectID!) {
    userSubmissionLatestAnalysis(_user_id: $_user_id) {
      ...UserSubmissionAnalysisFields
    }
  }
  ${UserSubmissionAnalysisFieldsFragmentDoc}
`;

export function useUserSubmissionLatestAnalysisQuery(
  options: Omit<Urql.UseQueryArgs<UserSubmissionLatestAnalysisQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserSubmissionLatestAnalysisQuery, UserSubmissionLatestAnalysisQueryVariables>({
    query: UserSubmissionLatestAnalysisDocument,
    ...options,
  });
}
export const AdminWigByIdDocument = gql`
  query adminWigById($_id: ID!) {
    adminWigById(_id: $_id) {
      ...WigFields
    }
  }
  ${WigFieldsFragmentDoc}
`;

export function useAdminWigByIdQuery(options: Omit<Urql.UseQueryArgs<AdminWigByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminWigByIdQuery, AdminWigByIdQueryVariables>({ query: AdminWigByIdDocument, ...options });
}
export const AdminWigsDocument = gql`
  query adminWigs($search: AdminWigInput) {
    adminWigs(search: $search) {
      ...WigFields
    }
  }
  ${WigFieldsFragmentDoc}
`;

export function useAdminWigsQuery(options?: Omit<Urql.UseQueryArgs<AdminWigsQueryVariables>, 'query'>) {
  return Urql.useQuery<AdminWigsQuery, AdminWigsQueryVariables>({ query: AdminWigsDocument, ...options });
}
export const WigMetaDocument = gql`
  query wigMeta {
    settingsByKey(key: "admin_wig_meta") {
      _id
      key
      value_raw
    }
  }
`;

export function useWigMetaQuery(options?: Omit<Urql.UseQueryArgs<WigMetaQueryVariables>, 'query'>) {
  return Urql.useQuery<WigMetaQuery, WigMetaQueryVariables>({ query: WigMetaDocument, ...options });
}
export const Admin_BookingsListCurrentSubscriptionDocument = gql`
  subscription admin_bookingsListCurrentSubscription {
    bookings {
      ...bookingsListCurrentFields
    }
  }
  ${BookingsListCurrentFieldsFragmentDoc}
`;

export function useAdmin_BookingsListCurrentSubscriptionSubscription<
  TData = Admin_BookingsListCurrentSubscriptionSubscription,
>(
  options?: Omit<Urql.UseSubscriptionArgs<Admin_BookingsListCurrentSubscriptionSubscriptionVariables>, 'query'>,
  handler?: Urql.SubscriptionHandler<Admin_BookingsListCurrentSubscriptionSubscription, TData>,
) {
  return Urql.useSubscription<
    Admin_BookingsListCurrentSubscriptionSubscription,
    TData,
    Admin_BookingsListCurrentSubscriptionSubscriptionVariables
  >({ query: Admin_BookingsListCurrentSubscriptionDocument, ...options }, handler);
}
export const Admin_UserStatusCountDocument = gql`
  subscription admin_userStatusCount {
    bookingUserStatus {
      _id
      UserStatusCount {
        ...userStatusCountFields
      }
    }
  }
  ${UserStatusCountFieldsFragmentDoc}
`;

export function useAdmin_UserStatusCountSubscription<TData = Admin_UserStatusCountSubscription>(
  options?: Omit<Urql.UseSubscriptionArgs<Admin_UserStatusCountSubscriptionVariables>, 'query'>,
  handler?: Urql.SubscriptionHandler<Admin_UserStatusCountSubscription, TData>,
) {
  return Urql.useSubscription<Admin_UserStatusCountSubscription, TData, Admin_UserStatusCountSubscriptionVariables>(
    { query: Admin_UserStatusCountDocument, ...options },
    handler,
  );
}
export const Admin_MessagesSeenSubscriptionDocument = gql`
  subscription admin_messagesSeenSubscription($_messages_id: [ID]!) {
    messagesSeenSubscription(_messages_id: $_messages_id) {
      _id
      seen
    }
  }
`;

export function useAdmin_MessagesSeenSubscriptionSubscription<TData = Admin_MessagesSeenSubscriptionSubscription>(
  options: Omit<Urql.UseSubscriptionArgs<Admin_MessagesSeenSubscriptionSubscriptionVariables>, 'query'>,
  handler?: Urql.SubscriptionHandler<Admin_MessagesSeenSubscriptionSubscription, TData>,
) {
  return Urql.useSubscription<
    Admin_MessagesSeenSubscriptionSubscription,
    TData,
    Admin_MessagesSeenSubscriptionSubscriptionVariables
  >({ query: Admin_MessagesSeenSubscriptionDocument, ...options }, handler);
}
export const Admin_MessagesSubscriptionDocument = gql`
  subscription admin_messagesSubscription($filter: MessageSearchInput!) {
    messagesSubscription(filter: $filter) {
      ...messagesFields
    }
  }
  ${MessagesFieldsFragmentDoc}
`;

export function useAdmin_MessagesSubscriptionSubscription<TData = Admin_MessagesSubscriptionSubscription>(
  options: Omit<Urql.UseSubscriptionArgs<Admin_MessagesSubscriptionSubscriptionVariables>, 'query'>,
  handler?: Urql.SubscriptionHandler<Admin_MessagesSubscriptionSubscription, TData>,
) {
  return Urql.useSubscription<
    Admin_MessagesSubscriptionSubscription,
    TData,
    Admin_MessagesSubscriptionSubscriptionVariables
  >({ query: Admin_MessagesSubscriptionDocument, ...options }, handler);
}
