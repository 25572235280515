import { Tag, Text, Tooltip } from '@blueprintjs/core';
import classnames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import { AiOutlineCalendar } from 'react-icons/ai';
import { BsCheck2Square } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { HStack, VStack } from 'components/layout/Stack';
import { ProjectProgressItemStatus, ProjectProgressStatus } from 'generated/graphql';

import type { TagProps } from '@blueprintjs/core';
import type { Project, ProjectAskablePlusResearch } from 'generated/graphql';
import type { VFC } from 'react';

dayjs.extend(relativeTime);

const RESEARCH_TYPE_TITLE_MAP: { [key in keyof ProjectAskablePlusResearch]: string } = {
  survey: 'Survey',
  competitive_analysis: 'Competitive Analysis',
  discovery: 'Discovery',
  longitudinal: 'Longitudinal',
  usability: 'Usability',
};

const CURRENT_STEP_TITLE: { [key: string]: string } = {
  assign_researcher: 'Assign a researcher',
  schedule_brief: 'Schedule a briefing call',
  brief_scheduled: 'Briefing call scheduled',
  attend_brief: 'Attend the briefing call',
  review_guide: 'Review moderation guide & survey',
  research_progress: 'Research in progress',
  receive_deliverables: 'Receive deliverables',
  schedule_debrief: 'Schedule a debriefing call',
  debrief_scheduled: 'Debrief call scheduled',
  attend_debrief: 'Attend the debriefing call',
  project_completed: 'Project completed',
};

type CardProps = {
  project: Project;
  style?: any;
};

export const AskablePlusProjectCard: VFC<CardProps> = ({ project }) => {
  if (!project) {
    // Not likely to happen. But put a guard in to satisfy types
    return null;
  }

  const keyedBookings = keyBy(project.bookings, '_id');

  const INTENT_MAPPINGS: { [key: number]: TagProps['intent'] } = {
    0: 'none',
    1: 'success',
    3: 'warning',
    5: 'none',
  };

  const currentStep = project?.progress?.[
    project.progress?.current_status ?? ProjectProgressStatus.Delivery
  ]?.steps?.find(step => step?.status === ProjectProgressItemStatus.InProgress)?.key;
  const timeUntilProjectMinutes = moment(project.askable_plus?.due_date).diff(new Date(), 'minutes');

  return (
    <VStack className="tw-flex tw-flex-col tw-bg-gray-600 tw-rounded tw-p-3 tw-w-full tw-shadow-md" spacing={10}>
      <Link to={`/team/client/${project.team?._id}`}>
        <Text className="tw-text-zinc-400">{project.team?.name}</Text>
      </Link>

      <Link to={`/askable-plus/project/${project._id}`}>
        <Text className="tw-text-white">
          {project.admin?.mission_critical ? <span title="Mission critical">🚨</span> : null} {project.name}
        </Text>
      </Link>
      <HStack className="tw-justify-between">
        <HStack className="tw-items-center">
          <BsCheck2Square size={16} />
          <Text>{CURRENT_STEP_TITLE[currentStep ?? '']}</Text>
        </HStack>
        <HStack
          className={classnames('tw-items-center', {
            'tw-text-yellow-200': timeUntilProjectMinutes <= 60,
            'tw-text-red-200': timeUntilProjectMinutes <= 0,
          })}
        >
          <AiOutlineCalendar size={16} />
          <Tooltip content={dayjs(project.askable_plus?.due_date).format('MMM D, YYYY	')}>
            <Text className="tw-cursor-default">{dayjs(project.askable_plus?.due_date).fromNow()}</Text>
          </Tooltip>
        </HStack>
      </HStack>
      <HStack className="tw-mt-1 tw-flex-wrap">
        {Object.keys(project.askable_plus?.research_type ?? {}).map(key => {
          const typedKey = key as keyof ProjectAskablePlusResearch;
          const research = project.askable_plus?.research_type?.[typedKey];

          if (typeof research !== 'object' || !research?.quota) {
            return null;
          }

          const booking = keyedBookings[research?._booking_id || ''];

          return (
            <Link to={`/booking/${research._booking_id}`} key={key}>
              <Tag
                round
                interactive
                minimal={booking?.status === 0}
                intent={INTENT_MAPPINGS[booking?.status ?? 0] ?? 'none'}
                className="tw-my-1"
              >
                {RESEARCH_TYPE_TITLE_MAP[typedKey]}
              </Tag>
            </Link>
          );
        })}
      </HStack>
    </VStack>
  );
};
