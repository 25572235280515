/* eslint-disable max-lines */
import { Classes, Button, Checkbox, Drawer, DrawerSize, InputGroup, Tag } from '@blueprintjs/core';
import uniqBy from 'lodash/uniqBy';
import pluralize from 'pluralize';
import { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Accordion } from 'components/accordion/Accordion';
import { Select, SkeletonWrapper } from 'components/common';
import PhoneCountrySelect from 'components/common/phone-country-select.view';
import { Text } from 'components/ui/Text';
import { SMSTemplateInput } from 'containers/Project/components/SMSTemplateInput';
import {
  BatchCriteriaName,
  useAdmin_CountBatchMatchesQuery,
  useCreateResearcherBatchMutation,
} from 'generated/graphql';

import type { CountryData } from 'components/common/phone-country-select.view';
import type { BatchInput, ResearcherMethods } from 'generated/graphql';
import type { FC } from 'react';

type ProjectResearcherBatchProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
};

type AssignedProjectsOperators = '$gt' | '$lt';

type AssignedProjects = {
  operator: AssignedProjectsOperators;
  value: number;
};

type ResearcherBatchExclusions =
  | 'currently_on_project'
  | 'previous_recipient_on_this_project'
  | 'never_worked_with_team'
  | 'never_worked_with_organisation'
  | 'declined_within_x_days'
  | 'certified_within_x_days';

const exclusions: { label: string; value: ResearcherBatchExclusions }[] = [
  {
    label: 'Researchers currently on a project',
    value: 'currently_on_project',
  },
  {
    label: 'Previous batch recipients for this project',
    value: 'previous_recipient_on_this_project',
  },
  {
    label: 'Researchers who have never worked with this team',
    value: 'never_worked_with_team',
  },
  {
    label: 'Researchers who have never worked with this organisation',
    value: 'never_worked_with_organisation',
  },
];

const assignedProjectsFilter: { label: string; value: AssignedProjectsOperators }[] = [
  {
    label: 'Greater than',
    value: '$gt',
  },
  {
    label: 'Less than',
    value: '$lt',
  },
];

type ResearcherFilter = {
  value: number;
  type: 'applied_to_project_in_last_x_days' | 'signed_up_over_x_days';
};

const researcherFilter: { label: string; value: ResearcherFilter['type'] }[] = [
  { label: 'Applied to a project in the last', value: 'applied_to_project_in_last_x_days' },
  { label: 'Signed up over', value: 'signed_up_over_x_days' },
];

const DEFAULT_TEMPLATE = `
Hey {{user.meta.identity.firstname}},

We have a new Askable+ research opportunity that you may be interested in applying for. Please note, you will need to have a moderate level of availability during work hours.

This opportunity is paying {{project_incentive}}.

Apply here: {{recruitment_shorlink}} 
You will be notified if you are selected as the successful researcher.

Thanks,
Askable Team
`;

type FormValues = {
  /**
   * Only sends to askable staff for testing
   */
  askableStaffOnly: boolean;
  researchMethods: { [key in ResearcherMethods]: boolean };
  exclusions: { [key in ResearcherBatchExclusions]: boolean };
  researcherFilter: ResearcherFilter[];
  assignedProjects: AssignedProjects[];
  countryCodes: CountryData[];
  template: string;
};

export const ProjectResearcherBatch: FC<ProjectResearcherBatchProps> = ({ isOpen, onClose, projectId }) => {
  const BATCH_INPUT_DEFAULTS: Pick<
    BatchInput,
    '_project_id' | 'message_type' | 'notification_type' | 'sort_preference' | 'batch_tag' | 'batch_size'
  > = {
    _project_id: projectId,
    sort_preference: 'no-sorting',
    batch_size: 100,
    message_type: 'sms',
    batch_tag: 'researcher-recruitment',
    notification_type: 'promotional',
  };

  const [{ fetching }, createBatchout] = useCreateResearcherBatchMutation();

  const { handleSubmit, control, setValue, watch, reset } = useForm<FormValues>({
    defaultValues: {
      askableStaffOnly: false,
      researchMethods: {
        competitive_analysis: false,
        discovery: false,
        longitudinal: false,
        survey: false,
        usability: false,
      },
      assignedProjects: [],
      exclusions: {
        currently_on_project: true,
        previous_recipient_on_this_project: true,
      },
      countryCodes: [],
    },
  });

  const userCriteria = watch([
    'researchMethods',
    'exclusions',
    'askableStaffOnly',
    'assignedProjects',
    'researcherFilter',
    'countryCodes',
  ]);

  const normalizeUserCriteria = ({
    askableStaffOnly,
    ...fv
  }: Pick<
    FormValues,
    'researchMethods' | 'exclusions' | 'askableStaffOnly' | 'assignedProjects' | 'researcherFilter' | 'countryCodes'
  >) => {
    if (askableStaffOnly) {
      return [{ name: BatchCriteriaName.AskableStaffOnly, data: JSON.stringify(askableStaffOnly) }];
    }

    const researchMethodKeys = Object.keys(fv.researchMethods) as ResearcherMethods[];
    const researchMethods = researchMethodKeys.filter(a => fv.researchMethods[a]);

    const includeAssignedProjects = fv.assignedProjects?.some(a => a.value >= 1);
    const researcherExclusions = fv.researcherFilter?.some(a => a.value >= 1);

    const mappedResearcherExclusions = fv.researcherFilter?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.type]: curr.value,
      };
    }, {});

    const parsedExclusions = {
      ...fv.exclusions,
      ...(researcherExclusions ? mappedResearcherExclusions : {}),
    };

    return [
      ...(includeAssignedProjects
        ? [{ name: BatchCriteriaName.AssignedProjects, data: JSON.stringify(fv.assignedProjects) }]
        : []),
      { name: BatchCriteriaName.UserType, data: JSON.stringify('researcher') },
      // { name: 'completed_projects_filter', data: JSON.stringify(fv.completedProjects) },
      { name: BatchCriteriaName.Exclusions, data: JSON.stringify(parsedExclusions) },
      { name: BatchCriteriaName.ResearchMethods, data: JSON.stringify(researchMethods) },
      ...(fv.countryCodes.length > 0
        ? [
            {
              name: BatchCriteriaName.PhoneNumberCountryCodes,
              data: JSON.stringify(fv.countryCodes.map(({ country_code }) => country_code)),
            },
          ]
        : []),
    ];
  };

  const normalized = useMemo(() => {
    return normalizeUserCriteria({
      researchMethods: userCriteria[0],
      exclusions: userCriteria[1],
      askableStaffOnly: userCriteria[2],
      assignedProjects: userCriteria[3],
      researcherFilter: userCriteria[4],
      countryCodes: userCriteria[5],
    });
  }, [userCriteria]);

  const [{ data: batchCount, fetching: fetchingBatchCountAmount }] = useAdmin_CountBatchMatchesQuery({
    requestPolicy: 'network-only',
    variables: {
      batch: {
        ...BATCH_INPUT_DEFAULTS,
        user_criteria: normalized,
      },
    },
  });

  const { append, remove, fields } = useFieldArray({
    name: 'assignedProjects',
    control,
  });

  const {
    append: addResearcherFilter,
    remove: removeResearcherFilter,
    fields: researcherFields,
  } = useFieldArray({
    name: 'researcherFilter',
    control,
  });

  const addEmptyFilter = () => {
    append({ value: 0, operator: assignedProjectsFilter[0].value });
  };

  const { replace: setCountryCodes, fields: countryCodes } = useFieldArray({
    name: 'countryCodes',
    control,
  });

  const addEmptyResearcherFilter = () => {
    addResearcherFilter({ value: 0, type: researcherFilter[0].value });
  };

  const onFormSubmit = async (fv: FormValues) => {
    const normalizedUserCritera = normalizeUserCriteria(fv);

    try {
      const result = await createBatchout({
        input: {
          ...BATCH_INPUT_DEFAULTS,
          message_template: fv.template,
          user_criteria: normalizedUserCritera,
        },
      });

      reset();
      toast.success(
        `Batched out ${result.data?.createBatch} ${pluralize('message', result.data?.createBatch || 0)} to researchers.`,
      );
      onClose();
    } catch (e) {
      toast.error('Error. Check the console');
      console.error(e);
    }
  };

  useEffect(() => setValue('template', DEFAULT_TEMPLATE), []);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Batch SMS" size={DrawerSize.SMALL} className="!tw-w-[30rem]">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="tw-p-6">
          <div>
            <Text className="tw-font-semibold tw-mb-2">Filters</Text>
            <Accordion buttonLabel="Phone number country codes">
              <div className="tw-space-y-2">
                {countryCodes.length > 0 && (
                  <div className="tw-mb-1">
                    {countryCodes.map(country => (
                      <Tag
                        className="tw-mr-1 tw-mb-1"
                        minimal
                        key={country.region}
                        onRemove={() => {
                          setCountryCodes(countryCodes.filter(({ region }) => region !== country.region));
                        }}
                      >
                        {country.name} <em className={Classes.TEXT_MUTED}>({country.country_code})</em>
                      </Tag>
                    ))}
                  </div>
                )}
                <div>
                  <PhoneCountrySelect
                    text="Add code"
                    onChange={(country: CountryData) => {
                      setCountryCodes(uniqBy([...countryCodes, country], 'region'));
                    }}
                    buttonProps={{
                      minimal: true,
                      intent: 'primary',
                      rightIcon: 'add',
                    }}
                  />
                  {countryCodes.length > 0 && (
                    <Button
                      rightIcon="remove"
                      minimal
                      intent="danger"
                      onClick={() => {
                        setCountryCodes([]);
                      }}
                    >
                      Clear filters
                    </Button>
                  )}
                </div>
              </div>
            </Accordion>
            <Accordion buttonLabel="Assigned projects">
              <div className="tw-space-y-2">
                {fields.map((f, index) => {
                  return (
                    <Controller
                      control={control}
                      key={f.id}
                      name={`assignedProjects.${index}`}
                      render={({ field }) => {
                        const { onChange, ...allFields } = field;

                        return (
                          <div className="tw-flex tw-space-x-2">
                            <Select
                              options={assignedProjectsFilter}
                              onChange={e => onChange({ ...allFields.value, operator: e })}
                            />
                            <InputGroup
                              type="text"
                              value={allFields.value.value.toString()}
                              onChange={e => onChange({ ...allFields.value, value: e.target.value })}
                            />
                            <Button minimal icon="minus" intent="danger" onClick={() => remove(index)} />
                          </div>
                        );
                      }}
                    />
                  );
                })}
                <Button rightIcon="add" disabled={fields.length >= 2} minimal intent="primary" onClick={addEmptyFilter}>
                  Add filter
                </Button>
              </div>
            </Accordion>

            <Accordion buttonLabel="Exclusions">
              {exclusions.map(exc => {
                return (
                  <Controller
                    key={exc.value}
                    control={control}
                    name={`exclusions.${exc.value}`}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          label={exc.label}
                          {...field}
                          value={undefined}
                          checked={field.value}
                          defaultChecked={field.value}
                        />
                      );
                    }}
                  />
                );
              })}
              <div className="tw-space-y-2">
                {researcherFields.map((f, index) => {
                  return (
                    <Controller
                      control={control}
                      key={f.id}
                      name={`researcherFilter.${index}`}
                      render={({ field }) => {
                        const { onChange, ...allFields } = field;
                        return (
                          <div className="tw-flex tw-space-x-2 tw-items-center">
                            <div className="tw-w-1/3">
                              <Select
                                options={researcherFilter}
                                onChange={e => onChange({ ...allFields.value, type: e })}
                              />
                            </div>
                            <div className="tw-w-1/4">
                              <InputGroup
                                type="text"
                                value={allFields.value.value.toString()}
                                onChange={e => onChange({ ...allFields.value, value: e.target.value })}
                              />
                            </div>
                            <Text>
                              {allFields.value.type === 'applied_to_project_in_last_x_days' ? 'days' : 'days ago'}
                            </Text>
                            <Button
                              minimal
                              icon="minus"
                              intent="danger"
                              onClick={() => removeResearcherFilter(index)}
                            />
                          </div>
                        );
                      }}
                    />
                  );
                })}
                <Button rightIcon="add" minimal intent="primary" onClick={addEmptyResearcherFilter}>
                  Add
                </Button>
              </div>
            </Accordion>

            <div className="tw-mt-6">
              <Controller
                control={control}
                name="askableStaffOnly"
                render={({ field }) => {
                  return (
                    <Checkbox
                      label="Batch out to Askable staff. (Test purposes only)"
                      {...field}
                      checked={field.value}
                      value={undefined}
                      defaultChecked={false}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="tw-flex tw-items-center tw-space-x-2 tw-mt-4">
            <SkeletonWrapper active={fetchingBatchCountAmount}>
              <Text>
                {batchCount?.countBatchMatches?.toLocaleString() ?? 0} matched{' '}
                {pluralize('researcher', batchCount?.countBatchMatches ?? 0)}
              </Text>
            </SkeletonWrapper>
          </div>
        </div>

        <div className="tw-p-6 tw-space-y-2">
          <div className="tw-flex tw-justify-between tw-items-center">
            <Text className="tw-font-semibold">SMS template</Text>
          </div>

          <div className="tw-flex tw-flex-col tw-space-y-2">
            <SMSTemplateInput control={control} name="template" />
          </div>
        </div>

        <div className="tw-m-6 tw-py-6 tw-space-y-2">
          <Button intent="success" type="submit" loading={fetching}>
            Create batchout
          </Button>
        </div>
      </form>
    </Drawer>
  );
};
