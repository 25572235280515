import gql from 'graphql-tag';

import { userStatusCountFieldsFragment } from '../../fragments/booking/userStatusCountFields';

export default gql`
  subscription admin_userStatusCount {
    bookingUserStatus {
      _id
      UserStatusCount {
        ...userStatusCountFields
      }
    }
  }
  ${userStatusCountFieldsFragment}
`;
