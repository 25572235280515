import { Classes, Colors, Icon, IconSize } from '@blueprintjs/core';
import { createElement } from 'react';

import { ASKABLE_COLOURS } from 'lib/constants';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './custom-svg' or its correspon... Remove this comment to see the full error message
import customSVG from './custom-svg';

function getColour(color: any) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (ASKABLE_COLOURS[color]) return ASKABLE_COLOURS[color];
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (Colors[color]) return Colors[color];
  if (!color.match(/^#[0-9A-Fa-f]+$/)) {
    console.warn(`Unknown colour name '${color}', try one of these:`, { ...ASKABLE_COLOURS, ...Colors });
  }
  return color;
}

const CustomSVGIcon = (props: any) => {
  // copied from blueprint Icon render method
  const {
    icon,
    className,
    color,
    htmlTitle,
    iconSize = IconSize.STANDARD,
    intent,
    title = icon,
    tagName = 'span',
    ...htmlprops
  } = props;

  // choose which pixel grid is most appropriate for given icon size
  // render path elements, or nothing if icon name is unknown.
  const { paths = null, viewBox = '0 0 20 20' } = customSVG[icon] || {};

  const classes = [Classes.ICON, Classes.iconClass('blank'), Classes.intentClass(intent), 'custom-svg-icon', className];

  return createElement(
    tagName,
    {
      ...htmlprops,
      className: classes.join(' '),
      title: htmlTitle,
    },
    <svg fill={color} data-icon={icon} width={iconSize} height={iconSize} viewBox={viewBox}>
      {title && <desc>{title}</desc>}
      {paths &&
        paths.map(({ d, fillRule, ...path }: any) => (
          <path key={d || Math.random()} d={d} fillRule={fillRule || 'evenodd'} {...path} />
        ))}
    </svg>,
  );
};

// eslint-disable-next-line react/display-name
export default ({ icon, color, className = '', muted, ...iconProps }: any) => {
  const classes = className.replace(/^\s+|\s+$/g, '').split(' ');
  if (muted) {
    classes.push('muted');
  }

  const returnProps = {
    icon: icon || 'blank',
    color: color ? getColour(color) : undefined,
    className: classes.join(' '),
  };

  if (icon && icon.match && icon.match(/^askable-/)) {
    return <CustomSVGIcon {...iconProps} {...returnProps} />;
  }

  return <Icon {...iconProps} {...returnProps} />;
};
