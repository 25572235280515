import { getCountryData } from 'countries-list';
import getSymbolFromCurrency from 'currency-symbol-map';

import type { TCountryCode } from 'countries-list';
import type { Booking } from 'generated/graphql';

export const primaryCurrencyByCountry = (countryCode: TCountryCode) => {
  type CountryPrimaryCurrency = { code?: string; symbol?: string };
  const defaultReturn: CountryPrimaryCurrency = { code: undefined, symbol: undefined };
  if (!countryCode) {
    return defaultReturn;
  }
  const countryData = getCountryData(countryCode);
  if (!countryData?.currency) {
    return defaultReturn;
  }
  const [primaryCountryCurrency] = countryData.currency;
  return {
    code: primaryCountryCurrency,
    symbol: getSymbolFromCurrency(primaryCountryCurrency)!,
  } as CountryPrimaryCurrency;
};

export const getCountriesFromBooking = (booking: Booking) => {
  const locations = booking.config?.criteria?.locations;
  const allLocations = [
    ...(locations?.states ?? []),
    ...(locations?.bounds ?? []),
    ...(locations?.countries ?? []),
  ].filter(Boolean);

  return [...new Set(allLocations.map(location => location?.country).filter(Boolean))] as TCountryCode[];
};
