/* eslint-disable max-lines */
import { Query } from '@apollo/client/react/components';
import { Classes, H3, H5, H6, Divider, Callout, HTMLTable, Button, AnchorButton } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';

import { SkeletonWrapper, Spinner } from 'components/common';

import BookingBatchSidebarFilters from './booking-batch-sidebar-filters.view';

import type { QueryResult } from '@apollo/client';
import type { Admin_BookingByIdQuery } from 'generated/graphql';

const BookingBatchSidebarPreviousBatches = (props: any) => {
  // console.log(props);
  return (
    <div className="margin-top-2 margin-bottom-2">
      <Query
        query={gql`
          query admin_batchResults_sidebar($search: BatchResultInput) {
            batchResults(search: $search) {
              _id
              size
              created
              schedule_send
              clicks
              registrations
            }
          }
        `}
        variables={{
          search: {
            _booking_id: props.booking._id,
          },
        }}
        notifyOnNetworkStatusChange
      >
        {({ loading, error, data, refetch, networkStatus }: QueryResult<object>) => {
          if (networkStatus === 1) {
            return (
              <H6>
                Previous batches:{' '}
                <Button small minimal onClick={() => {}} className="padding-0 margin-right-1">
                  <Spinner withText inline tagName="span" />
                </Button>
              </H6>
            );
          }
          if (error) {
            console.error(error);
            return (
              <Callout intent="danger" icon="error" className="margin-bottom-1">
                Error fetching previous batchouts
              </Callout>
            );
          }
          if (_.get(data, 'batchResults.length', 0) === 0) {
            return <H6>No previous batchouts</H6>;
          }

          const bookingGroups = _.chain(data)
            .get('batchResults', [])
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'map' does not exist on type 'FunctionCha... Remove this comment to see the full error message
            .map((batch: any) => ({
              ...batch,
              date: batch.schedule_send || batch.created,
            }))
            .groupBy(({ date }: any) => (date && date > Date.now() ? 'scheduled' : 'sent'))
            .value();

          const tables = { scheduled: null, sent: null };

          if (bookingGroups.scheduled) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
            tables.scheduled = (
              <>
                <H6>
                  Scheduled batches:{' '}
                  {loading ? (
                    <Button small minimal onClick={() => {}} className="padding-0">
                      <Spinner withText inline tagName="span" />
                    </Button>
                  ) : (
                    <Button small minimal icon="refresh" onClick={() => refetch()} />
                  )}
                </H6>
                <HTMLTable
                  className="margin-top-1 margin-bottom-3 previous-batches scheduled-batches width-100"
                  striped
                >
                  <tbody>
                    {_.sortBy(bookingGroups.scheduled, 'schedule_send').map(batchResultItem => {
                      // const scheduled = moment.tz(batchResultItem.schedule_send);
                      const sendTimeLocal = moment.tz(
                        batchResultItem.schedule_send,
                        _.get(props.booking, 'config.timezone'),
                      );
                      return (
                        <tr key={`${batchResultItem._id || '-'}${batchResultItem.created}`}>
                          <td className="v-align-middle padding-left-2">
                            <span>
                              {batchResultItem.size.toLocaleString()} message{batchResultItem.size === 1 ? '' : 's'}
                            </span>
                            <br />
                            <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                              Sending {moment(batchResultItem.schedule_send).fromNow()}
                            </small>
                          </td>
                          <td className="v-align-middle padding-left-2">
                            <span>{sendTimeLocal.format('h:mm a D MMM YYYY')}</span>
                            <br />
                            <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                              {/* @ts-expect-error ts-migrate(2339) FIXME: Property '_z' does not exist on type 'Moment'. */}
                              {sendTimeLocal._z.name} (GMT {sendTimeLocal.format('Z')})
                            </small>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </HTMLTable>
              </>
            );
          }

          if (bookingGroups.sent) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'null'.
            tables.sent = (
              <>
                <H6>
                  Previous batches:{' '}
                  {loading ? (
                    <Button small minimal onClick={() => {}} className="padding-0">
                      <Spinner withText inline tagName="span" />
                    </Button>
                  ) : (
                    <Button small minimal icon="refresh" onClick={() => refetch()} />
                  )}
                </H6>
                <HTMLTable className="margin-top-1 margin-bottom-3 previous-batches width-100" striped>
                  <tbody>
                    <tr>
                      <th className="align-left">Sent</th>
                      <th className="padding-left-2">Messages</th>
                      <th className="padding-left-2">Signups</th>
                    </tr>
                    {_.orderBy(bookingGroups.sent, 'date', 'desc').map(batchResultItem => (
                      <tr key={`${batchResultItem._id || '-'}${batchResultItem.created}`}>
                        <td className="">
                          {moment(batchResultItem.date).fromNow()}

                          <br />
                          <small>{moment(batchResultItem.date).format('H:mm D MMM YY')}</small>
                        </td>
                        <td className="align-center v-align-middle padding-left-2">
                          {batchResultItem.size.toLocaleString()}
                        </td>
                        <td className="align-center v-align-middle padding-left-2">
                          {batchResultItem.registrations ? (
                            <>
                              <span>{batchResultItem.registrations.toLocaleString()}</span>
                              <small className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                                {' '}
                                ({Math.round((batchResultItem.registrations / batchResultItem.size) * 100)}%)
                              </small>
                            </>
                          ) : (
                            batchResultItem.registrations
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </HTMLTable>
                {props?.booking?._id && (
                  <div className="tw-flex-row tw-space-x-2">
                    <AnchorButton
                      href={`https://us3.datadoghq.com/dashboard/6xg-6hs-jn4/short-link-usage?fromUser=true&refresh_mode=sliding&view=spans&live=true&tpl_var_BookingId%5B0%5D=${props.booking._id}`}
                      intent="primary"
                      minimal
                      rightIcon="share"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link clicks
                    </AnchorButton>
                    <span className={`${Classes.UI_TEXT} ${Classes.TEXT_MUTED} tw-italic`}>
                      (Last 2 weeks of data available)
                    </span>
                  </div>
                )}
              </>
            );
          }

          return (
            <>
              {tables.scheduled}
              {tables.sent}
            </>
          );
        }}
      </Query>
    </div>
  );
};

const BookingBatchSidebarBody = ({ loading, booking }: any) => {
  if (loading) return null;

  if (_.get(booking, 'config.recruitment.byo')) {
    return (
      <>
        <p>This booking is recruiting from the researcher’s own database.</p>
        <p>
          <strong>Share link</strong>:&nbsp;
          <a href={_.get(booking, 'config.recruitment.ClientShareLink.url', '#')}>
            {_.get(booking, 'config.recruitment.ClientShareLink.short_url', '?')}
          </a>
        </p>
        <p>
          <strong>Hits</strong>: {_.get(booking, 'config.recruitment.ClientShareLink.hits', '?')}
        </p>
      </>
    );
  }

  return (
    <>
      <BookingBatchSidebarFilters booking={booking} />
      <Divider />
      <BookingBatchSidebarPreviousBatches booking={booking} />
    </>
  );
};

const BookingBatchSidebar = (props: any) => (
  <div id="messaging-booking-batch-sidebar" className="content-page extra-padding">
    <Query
      query={gql`
        query admin_bookingBatchMessage_bookingByID($id: ID) {
          bookingByID(id: $id) {
            _id
            name
            type
            total_participants
            _team_id
            config {
              timezone
              location {
                city
                state
                country
                latitude
                longitude
                postcode
              }
              countries
              criteria {
                meta_identity_birthday_year {
                  field
                  value
                  operator
                }
                meta_identity_gender {
                  field
                  value
                  operator
                }
                meta_identity_languages_english_speak {
                  field
                  value
                  operator
                }
                meta_identity_locales {
                  locale
                  value
                }
                meta_family_status {
                  field
                  value
                  operator
                }
                meta_education {
                  field
                  value
                  operator
                }
                meta_work_status {
                  field
                  value
                  operator
                }
                meta_work_industry {
                  _industry_id
                  subcategories {
                    _subcategory_id
                  }
                }
                exclude_participants_from_time
                exclude_participants_from_bookings
                locations {
                  states {
                    formatted_address
                    state
                    country
                    latitude
                    longitude
                    google_location_types
                  }
                  countries {
                    formatted_address
                    country
                    latitude
                    longitude
                    google_location_types
                  }
                  bounds {
                    formatted_address
                    country
                    latitude
                    longitude
                    google_location_types
                    google_location {
                      place_id
                      geometry {
                        latitude_ne
                        longitude_ne
                        latitude_sw
                        longitude_sw
                      }
                    }
                  }
                }
              }
              incentives {
                currency_symbol
                value
              }
              recruitment {
                byo
                ClientShareLink {
                  _id
                  hits
                  url
                  short_url
                }
              }
            }
            session {
              _id
              start
            }
            admin {
              mission_critical
              mission_critical_reason
            }
          }
        }
      `}
      variables={{
        id: props._id,
      }}
    >
      {({ loading, error, data }: QueryResult<Admin_BookingByIdQuery>) => {
        if (error) {
          console.error(error);
          return <div />;
        }
        const bookingSummary = {
          loaded: true,
          name: 'Some booking name',
          description: '10 participants - YEAH',
          dates: ['30th Feb', '17th Nic'],
        };

        if (loading) {
          bookingSummary.loaded = false;
        } else if (data?.bookingByID) {
          bookingSummary.name = data.bookingByID.name ?? 'invalid';
          bookingSummary.description = `${data.bookingByID.total_participants} participant${data.bookingByID.total_participants !== 1 ? 's' : ''} - ${
            _.get(data.bookingByID, 'config.location.city') || _.get(data.bookingByID, 'config.location.state')
          }`;
          bookingSummary.dates = _.find(_.get(data.bookingByID, 'session', []), session => session && session.start)
            ? _.uniq([
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                moment(_.minBy(data.bookingByID.session, session => session.start).start).format('Do MMM'),
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                moment(_.maxBy(data.bookingByID.session, session => session.start).start).format('Do MMM'),
              ])
            : [];
        } else {
          bookingSummary.loaded = false;
          bookingSummary.name = 'Booking not found';
          bookingSummary.description = '';
          bookingSummary.dates = [];
        }

        return (
          <>
            <H5>Batch SMS for:</H5>
            <H3>
              <SkeletonWrapper active={loading}>{bookingSummary.name}</SkeletonWrapper>
            </H3>
            <p className={[Classes.UI_TEXT, Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(' ')}>
              <SkeletonWrapper active={loading}>{bookingSummary.description}</SkeletonWrapper>
              <br />
              <SkeletonWrapper active={loading}>{bookingSummary.dates.join(' - ')}</SkeletonWrapper>
            </p>
            <Divider className="margin-bottom-2" />
            <BookingBatchSidebarBody loading={loading} booking={data?.bookingByID || {}} />
          </>
        );
      }}
    </Query>
  </div>
);

// export default compose(loginUserMutation)(Messaging);
export default BookingBatchSidebar;
