/* eslint-disable max-lines */
import { Intent, NonIdealState } from '@blueprintjs/core';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { DatePicker } from 'components/common/DatePicker';
import { InlineEdit } from 'components/common/InlineEdit';
import { ColumnFilter, EmptyRowItem, SelectColumnFilter, VirtualisedTable } from 'components/table/VirtualisedTable';
import {
  useTeamsQuery,
  useAssignOrganisationToTeamMutation,
  useUpdateTeamMutation,
  useRemoveOrganisationFromTeamMutation,
} from 'generated/graphql';
import { utils } from 'lib/utils';

import { AppConsumer, SpinnerPage } from '../common';

import { OrganisationSelect } from './organisation-select.view';

import type { Exact, Maybe, TeamInput, TeamsQuery } from 'generated/graphql';
import type { VFC } from 'react';
import type { ColumnWithLooseAccessor, FilterTypes } from 'react-table';

type CreditExpiryProps = {
  value?: Maybe<number>;
  editable?: boolean;
  onChange: (newEpoch: number | null) => void;
};

const CreditExpiry: VFC<CreditExpiryProps> = ({ value, onChange }) => {
  const [edited, setEdited] = useState(false);
  const [localValue, setLocalValue] = useState<Date | null>(value ? new Date(value) : null);

  const handleChange = (value: Date | null) => {
    setLocalValue(value);

    setEdited(true);
  };

  const handleSave = () => {
    if (localValue === null) {
      onChange(localValue);
      setEdited(false);
      return;
    }

    onChange(localValue?.valueOf());
    setEdited(false);
  };

  const handleCancelClick = () => {
    setLocalValue(value ? new Date(value) : null);
    setEdited(false);
  };

  const handleClearClick = () => {
    setEdited(true);

    setLocalValue(null);
  };

  return (
    <DatePicker
      selected={localValue}
      onChange={handleChange}
      customInput={
        <InlineEdit
          editable
          onClearClick={handleClearClick}
          onSaveClick={handleSave}
          onCancelClick={handleCancelClick}
          edited={edited}
        >
          {localValue === null ? <EmptyRowItem /> : utils.formatTimestampToDate(localValue?.valueOf(), 'D MMM YYYY')}
        </InlineEdit>
      }
    />
  );
};

type TeamListProps = {
  newToast: (p: any) => void;
  teams: TeamsQuery['teams'];
};

export const TeamsList: VFC<TeamListProps> = ({ teams: data, newToast }) => {
  const [, assignOrganisationToTeam] = useAssignOrganisationToTeamMutation();
  const [, removeOrganisationFromTeam] = useRemoveOrganisationFromTeamMutation();
  const [, updateTeam] = useUpdateTeamMutation();

  const filterTypes = useMemo<FilterTypes<any>>(
    () => ({
      fuzzyText: (rows, [id], filterValue) => {
        return matchSorter(rows, filterValue, {
          keys: [
            row => {
              return row.values[id];
            },
          ],
        });
      },
    }),
    [],
  );

  const handleTeamUpdate = async (variables?: Exact<{ _id: string; team: TeamInput }>) => {
    try {
      const result = await updateTeam(variables);
      newToast({ message: `${result?.data?.updateTeam?.name} updated successfully.`, intent: Intent.SUCCESS });
    } catch {
      newToast({
        message: 'An error has occured, refresh the page and try again. If the problem persists seek help',
        intent: Intent.DANGER,
      });
    }
  };

  const handleTeamOrgUpdate = async (variables?: Exact<{ _id: string; team: TeamInput }>) => {
    try {
      const result = await assignOrganisationToTeam({
        _id: variables?._id || '',
        _organisation_id: variables?.team._organisation_id || '',
      });
      newToast({
        message: `${result?.data?.assignOrganisationToTeam?.name} updated successfully.`,
        intent: Intent.SUCCESS,
      });
    } catch {
      newToast({
        message: 'An error has occured, refresh the page and try again. If the problem persists seek help',
        intent: Intent.DANGER,
      });
    }
  };

  const handleTeamOrgRemove = async (variables: Exact<{ _id: string }>) => {
    try {
      const result = await removeOrganisationFromTeam(variables);
      newToast({
        message: `${result?.data?.removeOrganisationFromTeam?.name} updated successfully.`,
        intent: Intent.SUCCESS,
      });
    } catch {
      newToast({
        message: 'An error has occured, refresh the page and try again. If the problem persists seek help',
        intent: Intent.DANGER,
      });
    }
  };

  const columns = useMemo<ColumnWithLooseAccessor<any>[]>(
    () => [
      {
        Header: 'Team ID',
        accessor: row => row?._id,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        Cell: ({ row }) => {
          const { original: team } = row;

          return team?._id;
        },
      },
      {
        Header: 'Team name',
        accessor: row => row?.name,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        Cell: ({ row }) => {
          const { original: team } = row;

          return (
            <Link
              className="link"
              to={`/team/${_.get(team, 'settings.ignore_tracking') ? 'internal' : 'client'}/${team?._id}`}
            >
              {team?.name}
            </Link>
          );
        },
      },
      {
        Header: 'Billing company',
        accessor: row => row?.settings?.billing?.company_name,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        Cell: ({ row }) => {
          const { original: team } = row;

          return team?.settings?.billing?.company_name ?? <EmptyRowItem />;
        },
      },
      {
        Header: 'Credit balance',
        accessor: row => row?.settings?.billing?.subscription?.credit?.remaining,
        Cell: ({ row }) => {
          const { original: team } = row;

          return team?.settings?.billing?.subscription?.credit?.remaining?.toLocaleString() ?? <EmptyRowItem />;
        },
      },
      {
        Header: 'Credit expiry',
        accessor: row => row?.settings?.billing?.subscription?.end,
        Cell: ({ row }) => {
          const { original: team } = row;

          return (
            <CreditExpiry
              onChange={end => {
                return handleTeamUpdate({
                  _id: team?._id,
                  team: {
                    settings: {
                      billing: {
                        subscription: {
                          end,
                        },
                      },
                    },
                  },
                });
              }}
              value={team?.settings?.billing?.subscription?.end ?? null}
            />
          );
        },
      },
      {
        Header: 'Organisation',
        accessor: row => row?.Organisation?.name,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        Cell: ({ row }) => {
          const { original: team } = row;

          return (
            <div className="organisation">
              <span className="margin--1">
                <OrganisationSelect
                  updateTeam={handleTeamOrgUpdate}
                  teamId={team?._id}
                  defaultOrg={team?.Organisation}
                  unlinkOrg={handleTeamOrgRemove}
                  orgLink={organisation => (
                    <Link to={`/team/organisation/${organisation?._id}`}>
                      {organisation?.name ?? organisation?._id}
                    </Link>
                  )}
                />
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Operational Office',
        accessor: row => row?.operational_office,
        filter: 'equals',
        Filter: SelectColumnFilter,
        Cell: ({ row }) => {
          const { original: team } = row;

          return team?.operational_office ?? <EmptyRowItem />;
        },
      },
      {
        Header: 'Parent team',
        accessor: row => row?.ParentTeam?.name,
        filter: 'fuzzyText',
        Filter: ColumnFilter,
        Cell: ({ row }) => {
          const { original: team } = row;

          if (!team?.ParentTeam?._id) return <EmptyRowItem />;
          return (
            <Link
              className="link"
              to={`/team/${_.get(team.ParentTeam, 'settings.ignore_tracking') ? 'internal' : 'client'}/${team?._id}`}
            >
              {team?.ParentTeam?.name}
            </Link>
          );
        },
      },
    ],
    [],
  );

  const initialSortBy = useMemo(() => {
    return [{ id: 'Team name', desc: false }];
  }, []);

  // @ts-expect-error: TODO: Virtualised Table types are bunk
  return <VirtualisedTable filterTypes={filterTypes} data={data} columns={columns} initialSortBy={initialSortBy} />;
};

type TeamsListProviderProps = {
  internal: boolean;
  context: {
    newToast: (p: any) => void;
  };
};
const TeamsListProvider: VFC<TeamsListProviderProps> = ({ internal, context }) => {
  const [{ data, fetching, error }] = useTeamsQuery({ variables: { search: { internal: !!internal } } });

  if (fetching) {
    return <SpinnerPage />;
  }

  if (error) {
    console.error(error);
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: "warning-sign"; title: string; inten... Remove this comment to see the full error message
    return <NonIdealState icon="warning-sign" title="Error loading teams" intent={Intent.DANGER} />;
  }

  if (!data?.teams?.length) {
    return <NonIdealState icon="warning-sign" title="No results" />;
  }

  return <TeamsList teams={data.teams ?? []} newToast={context.newToast} />;
};

export default AppConsumer(TeamsListProvider);
