import { Helmet } from 'react-helmet';

import type { FC } from 'react';

type Props = {
  title: string;
};

export const PageTitle: FC<Props> = ({ title }) => {
  const text = title ? `${title} | Askable admin` : 'Askable admin';

  return (
    <Helmet>
      <title>{text}</title>
    </Helmet>
  );
};
