import { Button, FormGroup, H5, InputGroup } from '@blueprintjs/core';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Select } from 'components/common';
import { AdminWigPeriodTypes, OperationalOffices, useAdminSaveWigMutation } from 'generated/graphql';
import { utils } from 'lib/utils';

type Props = {
  onClose: () => void;
};

export const monthValues = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

export const WigAddView = ({ onClose }: Props) => {
  const currentYear = new Date().getFullYear();
  const nextYear = new Date().getFullYear() + 1;
  const [operationalOffice, setOperationalOffice] = useState(OperationalOffices.Au);
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [year, setYear] = useState<string>(currentYear.toString());
  const [salesGoal, setSalesGoal] = useState<string>('0');
  const [usageGoal, setUsageGoal] = useState<string>('0');
  const [askablePlusGoal, setAskablePlusGoal] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(false);

  const [, adminSaveWig] = useAdminSaveWigMutation();
  const timezone = useMemo(() => {
    return utils.officeTimezone(operationalOffice);
  }, [operationalOffice]);

  return (
    <form
      id="create-wig"
      className="tw-p-10"
      onSubmit={event => {
        event.preventDefault();
        setLoading(true);

        adminSaveWig({
          input: {
            operational_office: operationalOffice as OperationalOffices,
            period_name: `${monthValues.find(item => item.value === month)?.label} ${year} - ${operationalOffice}`,
            period_type: AdminWigPeriodTypes.Month,
            period_start: utils.getStartDateTimestampForTimezone(month, year, timezone),
            period_end: utils.getEndDateTimestampForTimezone(month, year, timezone),
            goals: [
              {
                key: 'sales',
                value: parseInt(salesGoal),
              },
              {
                key: 'usage',
                value: parseInt(usageGoal),
              },
              {
                key: 'plus-usage',
                value: parseInt(askablePlusGoal),
              },
            ],
          },
        });
        setLoading(false);
        toast.success('WIG created');
        onClose();
      }}
    >
      <H5>Create a new WIG</H5>
      <FormGroup label="Date" className="tw-mt-8">
        <Select onChange={value => setMonth(parseInt(value!))} value={month.toString()} options={monthValues} />
        <Select
          className="tw-ml-2"
          onChange={value => setYear(value!)}
          value={year}
          options={[
            { label: currentYear.toString(), value: currentYear },
            { label: nextYear.toString(), value: nextYear },
          ]}
        />
      </FormGroup>
      <FormGroup label="Operational Office">
        <Select
          onChange={value => setOperationalOffice(value as OperationalOffices)}
          value={operationalOffice}
          options={Object.values(OperationalOffices).map(office => ({ label: office, value: office }))}
        />
      </FormGroup>
      <FormGroup label="Sales goal">
        <InputGroup
          id="sales-goal"
          value={salesGoal}
          type="number"
          onChange={e => setSalesGoal(e.target.value)}
          className="input-width-20"
        />
      </FormGroup>
      <FormGroup label="Usage goal">
        <InputGroup
          id="usage-goal"
          value={usageGoal}
          type="number"
          onChange={e => setUsageGoal(e.target.value)}
          className="input-width-20"
        />
      </FormGroup>
      <FormGroup label="Askable+ goal">
        <InputGroup
          id="askable-plus-goal"
          value={askablePlusGoal}
          type="number"
          onChange={e => setAskablePlusGoal(e.target.value)}
          className="input-width-20"
        />
      </FormGroup>
      <Button intent="success" type="submit" loading={loading}>
        Create
      </Button>
    </form>
  );
};
