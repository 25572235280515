import gql from 'graphql-tag';

import { bookingsListCurrentFieldsFragment } from '../../fragments/booking/bookingsListCurrentFields';

export default gql`
  query admin_bookingsListCurrent($status: [Int]) {
    bookings(status: $status) {
      ...bookingsListCurrentFields
    }
  }
  ${bookingsListCurrentFieldsFragment}
`;
