import gql from 'graphql-tag';

export default gql`
  mutation updateBookingAdminUser($booking_id: ID!, $booking: BookingInput!) {
    updateBookingAdmin(booking_id: $booking_id, booking: $booking) {
      admin_user {
        _id
        picture
        meta {
          identity {
            firstname
          }
        }
      }
    }
  }
`;
