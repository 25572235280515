export function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends { [key in keyof DirtyFields]?: unknown },
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev;

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
          : values[key],
    };
  }, {});

  return dirtyValues;
}

type ReturnType =
  | {
      type: 'string';
      parsedValue: string;
    }
  | {
      type: 'boolean';
      parsedValue: boolean;
    }
  | {
      type: 'number';
      parsedValue: number;
    };

export function getFieldType(v: string): ReturnType {
  if (typeof v === 'number') {
    return {
      type: 'number',
      parsedValue: v,
    };
  }

  if (v === 'true' || v === 'false' || typeof v === 'boolean') {
    return { type: 'boolean', parsedValue: v === 'true' ? true : v === 'false' ? false : v };
  }

  return {
    type: 'string',
    parsedValue: v,
  };
}
