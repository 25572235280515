import { Classes, ResizeSensor } from '@blueprintjs/core';
import { Fragment, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import ConfettiExplosion from 'react-confetti-explosion';

import DashboardSounds from 'components/dashboard-sounds/dashboard-sounds.view';

import WigBar from './wig-bar.view';

import type { FC } from 'react';

import './styles/sidebar-metrics.scss';

type Props = {
  standalone?: boolean;
};

export const SidebarMetrics: FC<Props> = ({ standalone }) => {
  const maxRatio = 0.36;
  const fontFactor = 0.0325;

  const [width, setWidth] = useState(0);
  const [salesTargetHit, setSalesTargetHit] = useState(false);
  const [usageTargetHit, setUsageTargetHit] = useState(false);

  const [exploding, setExploding] = useState(false);

  useEffect(() => {
    if (salesTargetHit && usageTargetHit) {
      setExploding(true);
      return;
    }
  }, [salesTargetHit, usageTargetHit]);

  const onUsageTargetHit = () => {
    setUsageTargetHit(true);
  };

  const onSalesTargetHit = () => {
    setSalesTargetHit(true);
  };

  if (standalone) {
    return (
      <Fragment>
        <ResizeSensor
          onResize={entries => {
            const lastEntry = entries.length ? entries[entries.length - 1] : entries;
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'Readonly<... Remove this comment to see the full error message
            setWidth(Math.min(lastEntry.contentRect.width, lastEntry.contentRect.height * maxRatio));
          }}
        >
          <div
            id="sidebar-metrics"
            className={`standalone ${Classes.DARK}`}
            style={{ fontSize: `${width * fontFactor}px` }}
          >
            {exploding && <ConfettiExplosion floorHeight={window.innerHeight} particleCount={100} />}
            <div className={`wigs-wrapper ${Classes.UI_TEXT} vertical`}>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ direction: string; goal: string; key: stri... Remove this comment to see the full error message */}
              <WigBar direction="vertical" goal="sales" key="sales" onTargetComplete={onSalesTargetHit} />
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ direction: string; goal: string; key: stri... Remove this comment to see the full error message */}
              <WigBar direction="vertical" goal="usage" key="usage" onTargetComplete={onUsageTargetHit} />
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ direction: string; goal: string; key: stri... Remove this comment to see the full error message */}
              <WigBar direction="vertical" goal="plus-usage" key="plus-usage" onTargetComplete={onUsageTargetHit} />
            </div>
            <Confetti gravity={0.06} numberOfPieces={400} run={exploding} />
            {exploding && <ConfettiExplosion particleCount={100} floorHeight={window.innerHeight} />}
          </div>
        </ResizeSensor>
        {(window.location.search || '').match(/with-sounds/) && (
          <div style={{ marginTop: '200px', position: 'absolute', top: '100%', width: '100%' }}>
            <DashboardSounds />
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <div
      id="sidebar-metrics"
      // className
    >
      <div className={`wigs-wrapper ${Classes.UI_TEXT} horizontal`}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ direction: string; goal: string; key: stri... Remove this comment to see the full error message */}
        <WigBar direction="horizontal" goal="sales" key="sales" />
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ direction: string; goal: string; key: stri... Remove this comment to see the full error message */}
        <WigBar direction="horizontal" goal="usage" key="usage" />
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ direction: string; goal: string; key: stri... Remove this comment to see the full error message */}
        <WigBar direction="horizontal" goal="plus-usage" key="plus-usage" />
      </div>
    </div>
  );
};
