import { Button, H5 } from '@blueprintjs/core';
import classNames from 'classnames';
import { crush } from 'radash';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { DatePicker } from 'components/common/DatePicker';
import { useUpdateProjectMutation } from 'generated/graphql';
import { getDirtyValues, getFieldType } from 'utils/form-utils';

import type { Project, ProjectInput } from 'generated/graphql';
import type { FC } from 'react';
import type { FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

type WhiteListedProjectInput =
  | 'askable_plus.due_date'
  | 'askable_plus.researcher_hourly_rate'
  | 'askable_plus.objective'
  | 'askable_plus.description'
  | 'askable_plus.audience.description';

type WhiteList = {
  [key in WhiteListedProjectInput]: {
    label: string;
    type: 'date' | 'string' | 'number' | 'textarea';
  };
};

const WHITE_LIST: WhiteList = {
  'askable_plus.due_date': {
    label: 'Due date',
    type: 'date',
  },
  'askable_plus.researcher_hourly_rate': {
    label: 'Researcher hourly rate',
    type: 'number',
  },
  'askable_plus.objective': {
    label: 'Key objectives',
    type: 'textarea',
  },
  'askable_plus.description': {
    label: 'Project context',
    type: 'textarea',
  },
  'askable_plus.audience.description': {
    label: 'Participant criteria',
    type: 'textarea',
  },
} as const;

const FORM_FIELD_ORDERS = Object.keys(WHITE_LIST) as WhiteListedProjectInput[];

type ProjectUpdateContainerProps = {
  onClose: () => void;
  projectId: string;
  project: Project;
};

type BookingObjectProps = Pick<ProjectUpdateContainerProps, 'project'> & {
  depth?: number;
  updatePath?: string;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<ProjectInput>;
  setValue: UseFormSetValue<ProjectInput>;
};

function getInputType(type: 'string' | 'number' | 'boolean'): 'checkbox' | 'text' | 'number' {
  switch (type) {
    case 'boolean':
      return 'checkbox';
    case 'number':
      return 'number';
    case 'string':
    default:
      return 'text';
  }
}

const ProjectUpdateForm: FC<BookingObjectProps> = ({ project, register, watch, setValue }) => {
  const crushedBooking: Record<WhiteListedProjectInput, any> = useMemo(() => {
    if (!project) {
      return {} as Record<WhiteListedProjectInput, any>;
    }
    return crush(project) as unknown as Record<WhiteListedProjectInput, any>;
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <>
      {FORM_FIELD_ORDERS.map((key: WhiteListedProjectInput) => {
        const whiteList = WHITE_LIST[key];

        if (!whiteList) {
          return null;
        }
        const value = crushedBooking?.[key];

        const { type, parsedValue } = getFieldType(value);
        const inputType = getInputType(type);

        const { className, ...additionalProps } = (() => {
          if (type === 'boolean') {
            return {
              defaultChecked: parsedValue,
              className: 'tw-w-auto',
            };
          }

          return {
            defaultValue: parsedValue,
            step: type === 'number' && !Number.isInteger(parsedValue) ? 'any' : undefined,
            className: 'tw-w-full',
          };
        })();

        const inputComp = (() => {
          console.log(inputType);
          switch (whiteList.type) {
            case 'date': {
              return (
                <DatePicker
                  selected={new Date(watch(key) || '')}
                  onChange={newDate => {
                    setValue(key, newDate?.valueOf(), { shouldDirty: true });
                  }}
                />
              );
            }

            case 'textarea':
              return (
                <textarea
                  id={key}
                  className={classNames('tw-w-full tw-text-slate-900 tw-border tw-rounded-md tw-p-1', className)}
                  {...additionalProps}
                  {...register(key)}
                />
              );

            default:
              return (
                <input
                  id={key}
                  type={inputType}
                  className={classNames('tw-w-full tw-text-slate-900 tw-border tw-rounded-md tw-p-1', className)}
                  {...additionalProps}
                  {...register(key, { valueAsNumber: inputType === 'number' })}
                />
              );
          }
        })();

        return (
          <div className="tw-py-2 tw-w-full" key={key}>
            <label htmlFor={key} className="tw-flex tw-flex-col tw-font-medium tw-mb-1">
              {whiteList.label}
            </label>
            {inputComp}
          </div>
        );
      })}
    </>
  );
};

export const ProjectUpdateContainer: FC<ProjectUpdateContainerProps> = ({ projectId, project, onClose }) => {
  const [{ fetching: updating }, updateAdminProject] = useUpdateProjectMutation();
  const { register, handleSubmit, formState, watch, setValue } = useForm<Partial<ProjectInput>>({
    defaultValues: (project as unknown as ProjectInput) || {},
  });

  const { isDirty, dirtyFields } = formState;
  const onSubmit = async (v: ProjectInput) => {
    try {
      await updateAdminProject({
        projectId,
        input: getDirtyValues(dirtyFields, v),
      });

      onClose();
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-h-screen tw-p-4">
      <H5>Update Project</H5>
      <div>
        <ProjectUpdateForm setValue={setValue} watch={watch} project={project} register={register} depth={0} />
        <Button loading={updating} disabled={!isDirty} type="submit" className="tw-my-4 tw-w-full">
          Save
        </Button>
      </div>
    </form>
  );
};
