/* eslint-disable max-lines */
import { Query } from '@apollo/client/react/components';
import { Classes, Button } from '@blueprintjs/core';
import gql from 'graphql-tag';
import _ from 'lodash';

import { AppConsumer, SkeletonWrapper, Icon } from 'components/common';
import BookingBatchSidebar from 'components/messaging/booking-batch/booking-batch-sidebar.view';
import {
  BOOKING_TYPE,
  BOOKING_SESSION_TYPE,
  BOOKING_PARTICIPANT_STATUS,
  BOOKING_PARTICIPANT_CANCEL,
} from 'lib/constants';

import type { Admin_BookingByIdQuery } from 'generated/graphql';

type Props = {
  _booking_id: string;
  context: any;
  setAppFrame: (a: { path: string; tab: string }) => void;
  booking: Admin_BookingByIdQuery['bookingByID'];
  loading: boolean;
};

export default AppConsumer(({ booking, loading, context, _booking_id }: Props) => {
  const participantCount = {
    completed: booking?.ParticipantSessionsCount?.completed ?? 0,
    completedConfirmed:
      (booking?.ParticipantSessionsCount?.completed ?? 0) + (booking?.ParticipantSessionsCount?.scheduled ?? 0),
    confirmed: booking?.ParticipantSessionsCount?.scheduled ?? 0,
    eligible: booking?.ParticipantSessionsCount?.total_eligible ?? 0,
    incomplete:
      (booking?.total_participants ?? 0) -
      (booking?.ParticipantSessionsCount?.completed ?? 0) +
      (booking?.ParticipantSessionsCount?.scheduled ?? 0) -
      (booking?.ParticipantSessionsCount?.total_refunded ?? 0),
    in_progress: booking?.ParticipantSessionsCount?.in_progress ?? 0,
    invited: (booking?.ParticipantSessionsCount?.invited ?? 0) + (booking?.ParticipantSessionsCount?.waiting_list ?? 0),
    quota: booking?.total_participants ?? 0,
    refunded: booking?.ParticipantSessionsCount?.total_refunded ?? 0,
    total: booking?.ParticipantSessionsCount?.total ?? 0,
  };

  const details = {
    confirmed_count: '',
    slots: null,
    participants: {
      available: `${participantCount.eligible} available`,
      completed: `${participantCount.completed} completed`,
      incomplete: `${participantCount.incomplete} incomplete`,
      invited: `${participantCount.invited} invited`,
      refunded: `${participantCount.refunded} refunded`,
    },
  };

  const makeDDLink = (bookingId: string) => () => {
    window.open(
      `https://us3.datadoghq.com/dashboard/6xg-6hs-jn4?tpl_var_BookingId[0]=${bookingId}`,
      '_blank',
      'noopener noreferrer',
    );
  };

  if (participantCount.total > participantCount.eligible) {
    details.participants.available = `${details.participants.available} + ${participantCount.total - participantCount.eligible} ineligible`;
  }

  if (
    booking?.booking_participant &&
    booking.type !== BOOKING_TYPE.ONLINE &&
    _.get(booking, 'config.session.type') === BOOKING_SESSION_TYPE.ONE_ON_ONE
  ) {
    const slotsRemaining = _.chain(booking)
      .get('session', [])
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'filter' does not exist on type 'Function... Remove this comment to see the full error message
      .filter((session: any) => session.start > Date.now() && (session.status || 1) === 1) // non-cancelled future sessions
      .filter(
        (session: any) =>
          !_.find(
            booking.booking_participant,
            participant =>
              participant?._session_id === session._id &&
              participant?.cancel === BOOKING_PARTICIPANT_CANCEL.NOT_CANCELLED &&
              [BOOKING_PARTICIPANT_STATUS.CONFIRMED, BOOKING_PARTICIPANT_STATUS.INVITED].indexOf(
                participant.status ?? -1,
              ) >= 0,
          ),
      )
      .value().length;
    switch (slotsRemaining) {
      case 0:
        // @ts-expect-error ts-migrate(2322) FIXME: Type '"No slots remaining"' is not assignable to t... Remove this comment to see the full error message
        details.slots = 'No slots remaining';
        break;
      case 1:
        // @ts-expect-error ts-migrate(2322) FIXME: Type '"1 slot remaining"' is not assignable to typ... Remove this comment to see the full error message
        details.slots = '1 slot remaining';
        break;
      default:
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
        details.slots = `${slotsRemaining} slots remaining`;
    }
  }

  switch (booking?.type) {
    case BOOKING_TYPE.FACE_TO_FACE:
    case BOOKING_TYPE.REMOTE:
    case BOOKING_TYPE.LONGITUDINAL:
      details.confirmed_count = [
        participantCount.completed + participantCount.confirmed,
        ' / ',
        participantCount.quota,
        ' confirmed or completed',
      ].join(' ');
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmed' does not exist on type '{ com... Remove this comment to see the full error message
      details.participants.confirmed = `${participantCount.confirmed} confirmed`;
      break;
    case BOOKING_TYPE.ONLINE:
      details.confirmed_count = [participantCount.completed, ' / ', participantCount.quota, ' completed'].join(' ');
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'in_progress' does not exist on type '{ c... Remove this comment to see the full error message
      details.participants.in_progress = `${participantCount.in_progress} in progress`;
      break;
    default:
  }

  return (
    <div className="section">
      <div className="detail-item margin-bottom-1">
        <div className="label">
          <SkeletonWrapper active={loading} length={18} tag="strong">
            {details.confirmed_count}
          </SkeletonWrapper>
        </div>
      </div>

      {details.slots && (
        <div className="detail-item margin-top--05 margin-bottom-1 tw-items-center">
          <div className="icon">
            <SkeletonWrapper active={loading}>
              <Icon icon="timeline-events" muted />
            </SkeletonWrapper>
          </div>
          <div className={`label ${Classes.TEXT_MUTED}`}>
            <SkeletonWrapper active={loading} length={11}>
              {details.slots}
            </SkeletonWrapper>
          </div>
        </div>
      )}

      {loading ? (
        <>
          <div className="detail-item margin-bottom-1">
            <div className="icon">
              <SkeletonWrapper active={loading}>
                <Icon icon="blank" />
              </SkeletonWrapper>
            </div>
            <span className="label">
              <SkeletonWrapper active={loading} length={9} />
            </span>
          </div>
          <div className="detail-item">
            <div className="icon">
              <SkeletonWrapper active={loading}>
                <Icon icon="blank" />
              </SkeletonWrapper>
            </div>
            <span className="label">
              <SkeletonWrapper active={loading} length={9} />
            </span>
          </div>
          <div className="detail-item">
            <div className="icon">
              <SkeletonWrapper active={loading}>
                <Icon icon="blank" />
              </SkeletonWrapper>
            </div>
            <span className="label">
              <SkeletonWrapper active={loading} length={9} />
            </span>
          </div>
          <div className="detail-item">
            <div className="icon">
              <SkeletonWrapper active={loading}>
                <Icon icon="blank" />
              </SkeletonWrapper>
            </div>
            <span className="label">
              <SkeletonWrapper active={loading} length={9} />
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="detail-item">
            <div className="icon">
              <Icon icon="person" muted />
            </div>
            <div className="label">{details.participants.completed}</div>
          </div>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmed' does not exist on type '{ com... Remove this comment to see the full error message */}
          {details.participants.confirmed && (
            <div className="detail-item">
              <div className="icon">
                <Icon icon="person" color="#009B19" />
              </div>
              <div className={`label ${Classes.TEXT_MUTED}`} style={{ color: '#009B19' }}>
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'confirmed' does not exist on type '{ com... Remove this comment to see the full error message */}
                {details.participants.confirmed}
              </div>
            </div>
          )}
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'in_progress' does not exist on type '{ c... Remove this comment to see the full error message */}
          {details.participants.in_progress && (
            <div className="detail-item">
              <div className="icon">
                <Icon icon="person" color="#F2C94C" />
              </div>
              <div className={`label ${Classes.TEXT_MUTED}`} style={{ color: '#F2C94C' }}>
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'in_progress' does not exist on type '{ c... Remove this comment to see the full error message */}
                {details.participants.in_progress}
              </div>
            </div>
          )}
          {details.participants.invited && (
            <div className="detail-item">
              <div className="icon">
                <Icon icon="person" color="#F2C94C" />
              </div>
              <div className={`label ${Classes.TEXT_MUTED}`} style={{ color: '#F2C94C' }}>
                {details.participants.invited}
              </div>
            </div>
          )}
          {participantCount.refunded > 0 && (
            <div className="detail-item">
              <div className="icon">
                <Icon icon="person" muted />
              </div>
              <div className={`label ${Classes.TEXT_MUTED}`}>{details.participants.refunded}</div>
            </div>
          )}
          {details.participants.available && (
            <div className="detail-item ">
              <div className="icon">
                <Icon icon="person" muted />
              </div>
              <div className={`label ${Classes.TEXT_MUTED}`}>{details.participants.available}</div>
            </div>
          )}
        </>
      )}

      <div className="detail-item margin-top-3">
        <Query
          query={gql`
            query admin_bookingPageBatchCount($_id: ID!) {
              batchResults(search: { _booking_id: $_id, tag: "recruitment" }) {
                _id
                size
              }
            }
          `}
          variables={{ _id: _booking_id }}
        >
          {(bookingPageBatchCount: any) => {
            if (loading || bookingPageBatchCount.loading) {
              return (
                <>
                  <div className="icon">
                    <SkeletonWrapper active={loading}>
                      <Icon icon="blank" />
                    </SkeletonWrapper>
                  </div>
                  <span className="label">
                    <SkeletonWrapper active={loading} length={11} />
                  </span>
                </>
              );
            }
            const totalBatches = _.chain(bookingPageBatchCount)
              .get('data.batchResults', [])
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'reduce' does not exist on type 'Function... Remove this comment to see the full error message
              .reduce((sum: any, result: any) => sum + result.size, 0)
              .value();

            return (
              <>
                <div className="icon">
                  <Icon icon="mobile-phone" muted />
                </div>
                <span className={`label ${Classes.TEXT_MUTED}`}>{totalBatches} batched</span>
                <Button minimal small intent="primary" rightIcon="share" onClick={makeDDLink(_booking_id)}>
                  Logs
                </Button>
                <Button
                  minimal
                  small
                  intent="primary"
                  rightIcon="arrow-right"
                  onClick={() => {
                    context.openSidebar({
                      children: <BookingBatchSidebar _id={_booking_id} />,
                    });
                  }}
                >
                  Batch
                </Button>
              </>
            );
          }}
        </Query>
      </div>
    </div>
  );
});
