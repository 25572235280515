import { H2 } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import './styles/not-found.scss';

export default function NotFound() {
  return (
    <div className="not-found tw-flex tw-h-full tw-w-full tw-justify-center">
      <div className="tw-z-30">
        <H2 className="tw-text-white tw-mt-64">Page not found</H2>
        <Link to="/booking/list/current" className="tw-text-white tw-underline">
          Return home
        </Link>
      </div>
      <div className="background" />
    </div>
  );
}
