import { Classes } from '@blueprintjs/core';
import { useParams } from 'react-router';

import WigBar from './wig-bar.view';

import type { FC } from 'react';

import './styles/sidebar-metrics.scss';

type Props = {
  goal: 'sales' | 'usage';
};

const WigPage: FC<Props> = ({ goal }) => {
  const params = useParams();
  const direction = params?.direction || 'horizontal';

  return (
    <div className={`wig-page ${Classes.DARK} ${direction}`}>
      <div className={`wigs-wrapper ${Classes.UI_TEXT} ${direction}`}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ direction: any; goal: any; }' is not assig... Remove this comment to see the full error message */}
        <WigBar direction={direction} goal={goal} />
      </div>
    </div>
  );
};

export default WigPage;
