import { ButtonGroup, Card } from '@blueprintjs/core';
import { useParams } from 'react-router';
import { useQuery } from 'urql';

import { PageTitle } from 'components/PageTitle';

import { DeleteUser } from '../components/DeleteUser';

import { ClientInformation } from './components/ClientInformation';
import { ClientTeams } from './components/ClientTeams';
import { LoginAsClient } from './components/LoginAsClient';
import { AdminClientUserQuery } from './data/ClientUser.query';

export const UserClientContainer = () => {
  const { id } = useParams<{ id: string }>();

  const [{ data, fetching }] = useQuery({
    query: AdminClientUserQuery,
    variables: {
      id: id!,
    },
  });

  if (fetching) {
    return null;
  }

  return (
    <div className="tw-overflow-scroll tw-flex tw-w-full tw-h-full">
      <div className="tw-min-w-[26rem] tw-max-w-[30rem] tw-border-r tw-border-gray-600">
        {data?.userByID?.displayName ? <PageTitle title={data.userByID.displayName} /> : null}
        <div className="tw-p-4">
          <Card className="tw-w-full tw-mb-6">
            <ClientInformation user={data?.userByID} />
          </Card>
          {data?.userByID?.displayName !== 'DELETED USER' && (
            <ButtonGroup className="tw-space-x-4">
              <DeleteUser id={id!} displayName={data?.userByID?.displayName ?? ''} />
              <LoginAsClient id={id!} />
            </ButtonGroup>
          )}
        </div>
      </div>
      <ClientTeams id={id!} />
    </div>
  );
};
