import { Outlet } from 'react-router';

import { PageTitle } from 'components/PageTitle';
import { AskableNavbar } from 'components/common';

import type { FC } from 'react';

import './teams.scss';

const Teams: FC = () => {
  const secondaryNav = [
    { title: 'Organisations', path: '/team/organisation' },
    { title: 'Client teams', path: '/team/client' },
    { title: 'Internal teams', path: '/team/internal' },
  ];

  return (
    <>
      <PageTitle title="Teams" />
      <AskableNavbar className="nav-secondary" items={secondaryNav} />
      <Outlet />
    </>
  );
};

export default Teams;
