import gql from 'graphql-tag';

export default gql`
  mutation admin_verifyUserPhone($id: ID!) {
    verifyUserPhone(_user_id: $id) {
      _id
      created
      updated
      contact {
        phone {
          mobile
          verification {
            mobile
            fax
          }
        }
      }
    }
  }
`;
