import gql from 'graphql-tag';

import { messagesFieldsFragment } from '../../fragments/messages/messagesFields';

export default gql`
  query admin_adminMessages($_user_id: ID!) {
    userAdminMessages(_user_id: $_user_id) {
      ...messagesFields
    }
  }
  ${messagesFieldsFragment}
`;
