import { Button, TextArea } from '@blueprintjs/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Text } from 'components/ui/Text';
import { useAddProjectNoteMutation, useAdminProjectNotesQuery } from 'generated/graphql';

import { formattedDate } from '../../../utils/date-utils';

import type { AdminProjectNotesQuery } from 'generated/graphql';
import type { FC } from 'react';

dayjs.extend(relativeTime);

type ProjectRightPanelProps = {
  projectId: string;
};

type NoteForm = {
  note: string;
};

type NoteType = NonNullable<NonNullable<NonNullable<AdminProjectNotesQuery['projectByID']>['admin']>['notes']>[number];

const ProjectNote: FC<{ note: NoteType }> = ({ note }) => {
  return (
    <div className="tw-border tw-w-full tw-rounded-sm tw-p-2 tw-border-gray-500">
      <p className="tw-w-full tw-mb-2">{note?.note}</p>

      <div className="tw-text-xs tw-text-gray-400 tw-space-y-1">
        <Text>
          {note?.user?.displayName} added this {dayjs(note?.created).fromNow()}
        </Text>
        <Text>{formattedDate({ date: note?.created, format: 'h:mma - ddd D MMM YYYY' })}</Text>
      </div>
    </div>
  );
};

export const ProjectRightPanel: FC<ProjectRightPanelProps> = ({ projectId }) => {
  const [newNote, setNewNote] = useState(false);
  const [, addNote] = useAddProjectNoteMutation();
  const { control, handleSubmit, setValue } = useForm<NoteForm>();
  const [{ data }] = useAdminProjectNotesQuery({
    variables: { id: projectId },
  });

  const handleNoteSubmit = async (fv: NoteForm) => {
    try {
      await addNote({
        projectId: projectId!,
        input: { note: fv.note },
      });

      setValue('note', '');
    } catch (e) {
      toast.error('Error adding new note. Check the console');

      console.error(e);
    }
  };

  return (
    <div className="tw-w-full tw-space-y-3">
      <Text className="tw-text-lg">Notes</Text>

      <div className="tw-max-h-[30rem] tw-space-y-3 tw-overflow-scroll">
        {data?.projectByID?.admin?.notes?.map((note: NoteType) => {
          return <ProjectNote key={note?._id} note={note} />;
        })}
      </div>

      {newNote && (
        <form onSubmit={handleSubmit(handleNoteSubmit)} className="tw-space-y-2">
          <Controller
            control={control}
            name="note"
            render={({ field }) => {
              return <TextArea fill {...field} />;
            }}
          />
          <div className="tw-space-x-1 tw-flex tw-justify-end">
            <Button small minimal intent="primary" onClick={() => setNewNote(false)}>
              Cancel
            </Button>
            <Button small minimal intent="primary" type="submit">
              Save
            </Button>
          </div>
        </form>
      )}

      {!newNote && (
        <Button rightIcon="plus" minimal small intent="primary" onClick={() => setNewNote(true)}>
          Add note
        </Button>
      )}
    </div>
  );
};
