import { Classes, Button, AnchorButton, FormGroup, InputGroup, TextArea, Callout } from '@blueprintjs/core';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Dialog } from 'components/common';
import { useCreateESignatureTemplateMutation, useUpdateESignatureTemplateMutation } from 'generated/graphql';
import { BOOKING_STATUS, ESIGNATURE_TEMPLATE_PROVIDER } from 'lib/constants';

import type { ESignatureTemplate } from 'generated/graphql';

const EditTemplateDialog = ({
  template,
  onClose,
  isNew,
}: {
  template: ESignatureTemplate | null;
  onClose: any;
  isNew?: boolean;
}) => {
  const templateId = template?._id || '';

  const [templateTitle, setTemplateTitle] = useState('');
  const [templateCC, setTemplateCC] = useState('');
  const [templateTemplate, setTemplateTemplate] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const createTemplate = useCreateESignatureTemplateMutation()[1];
  const updateTemplate = useUpdateESignatureTemplateMutation()[1];

  const { id: _team_id } = useParams();

  useEffect(() => {
    if (template || isNew) {
      setTemplateTitle(template?.name || '');
      setTemplateCC((template?.recipients || []).join(', '));
      setTemplateTemplate(template?.provider?.reference || '');
    }
  }, [templateId, isNew]);

  const activeBookings = useMemo(() => {
    return (template?.AgreementBookings || []).filter(booking => booking?.status === BOOKING_STATUS.ACTIVE);
  }, [templateId]);

  return (
    <Dialog
      isOpen={!!template}
      title={isNew ? 'Add new template' : `Edit ${template?.name}`}
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
    >
      <form
        onSubmit={async event => {
          event.preventDefault();
          if (isLoading) return false;
          setIsLoading(true);

          const saveTemplate: ESignatureTemplate = {
            type: 1, // TODO: hardcoded
            name: templateTitle.trim(),
            recipients: templateCC.split(/[\s,]+/),
            provider: { name: ESIGNATURE_TEMPLATE_PROVIDER.ANNATURE, reference: templateTemplate.trim() || null },
          };

          let error: any;

          if (isNew) {
            saveTemplate._team_id = _team_id;
            await createTemplate({ template: saveTemplate })
              .then(result => {
                if (result?.error) {
                  error = result.error;
                }
              })
              .catch(err => {
                error = err;
              });
          } else if (template?._id) {
            await updateTemplate({ _id: template._id, template: saveTemplate })
              .then(result => {
                if (result?.error) {
                  error = result.error;
                }
              })
              .catch(err => {
                error = err;
              });
          }

          setIsLoading(false);

          if (error) {
            console.error(error);
            window.alert(error?.message || 'Something went wrong - check the logs');
          } else {
            onClose();
          }
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Title"
            labelFor="text-title"
            labelInfo="(required)"
            // helperText="Helper text with details..."
          >
            <InputGroup
              id="text-title"
              value={templateTitle}
              onChange={e => setTemplateTitle(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup label="CC" labelFor="text-cc" helperText="Enter email addresses separated by comma">
            <TextArea
              id="text-cc"
              value={templateCC}
              fill
              growVertically
              onChange={e => setTemplateCC(e.target.value)}
            />
          </FormGroup>
          <FormGroup
            label="Template ID"
            labelFor="text-template"
            // helperText="Helper text with details..."
          >
            <InputGroup
              id="text-template"
              className={Classes.MONOSPACE_TEXT}
              value={templateTemplate}
              onChange={e => setTemplateTemplate(e.target.value)}
              rightElement={
                templateTemplate ? (
                  <AnchorButton
                    minimal
                    icon="share"
                    href={`https://dashboard.annature.com.au/create-template?templateId=${templateTemplate}`}
                    target="_blank"
                  />
                ) : undefined
              }
            />
          </FormGroup>
          {activeBookings.length > 0 && (
            <Callout intent="warning" className={Classes.RUNNING_TEXT}>
              <p>This template is in use in the following active booking(s):</p>
              <ul className={`${Classes.LIST} ${Classes.UI_TEXT}`}>
                {activeBookings.map(booking =>
                  booking ? (
                    <li key={booking?._id}>
                      <Link to={`/booking/${booking._id}`}>{booking.name}</Link>
                    </li>
                  ) : null,
                )}
              </ul>
            </Callout>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button intent="primary" type="submit" loading={isLoading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default EditTemplateDialog;
