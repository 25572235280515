import { H3 } from '@blueprintjs/core';

import { PageTitle } from 'components/PageTitle';

export const ParticipantContainer = () => {
  return (
    <>
      <PageTitle title="Participants" />
      <div style={{ padding: '10px' }}>
        <H3>Participants</H3>
        <div>Use search to find participant users.</div>
      </div>
    </>
  );
};
